import { MATCH_STATUS } from '@ntb-sport/constants';
import MatchTimer from '../MatchTimer/MatchTimer';
import { format } from 'date-fns';

interface MatchStatusProps {
  sportId: any;
  matchStatusId: any;
  timestamp: any;
  timeStartFirstHalf: any;
  timeStartSecondHalf: any;
  timeStartFirstHalfExtraTime: any;
  timeStartSecondHalfExtraTime: any;
}

export const getMatchStatus = ({
  sportId,
  matchStatusId,
  timestamp,
  timeStartFirstHalf,
  timeStartSecondHalf,
  timeStartFirstHalfExtraTime,
  timeStartSecondHalfExtraTime,
}: MatchStatusProps) => {
  const matchStatus = {
    [MATCH_STATUS.NOT_STARTED]: {
      type: 'default',
      component: <>{format(new Date(timestamp), 'HH:mm')}</>,
    },
    [MATCH_STATUS.ONGOING]: {
      type: 'default',
      component: <>Pågår</>,
    },
    [MATCH_STATUS.PLAYED]: {
      type: 'default',
      component: <>Slutt</>,
    },
    //TODO: Update MATCH_STATUS in utils
    6: {
      type: 'default',
      component: <>{format(new Date(timestamp), 'HH:mm')}</>,
    },
    [MATCH_STATUS.HALF_TIME]: {
      type: 'default',
      component: <>Pause</>,
    },
    [MATCH_STATUS.SECOND_HALF_TIME]: {
      type: 'default',
      component: <>Pause</>,
    },
    [MATCH_STATUS.PAUSE_BEFORE_EXTRA_TIME]: {
      type: 'default',
      component: <>Pause</>,
    },
    [MATCH_STATUS.HALF_TIME_EXTRA_TIME]: {
      type: 'default',
      component: <>Pause</>,
    },
    [MATCH_STATUS.POSTPONED]: {
      type: 'error',
      component: <>Utsatt</>,
    },
    [MATCH_STATUS.WILL_NOT_BE_PLAYED]: {
      type: 'error',
      component: <>Avlyst</>,
    },
    [MATCH_STATUS.CANCELLED]: {
      type: 'error',
      component: <>Strøket</>,
    },
    [MATCH_STATUS.ABANDONED]: {
      type: 'error',
      component: <>Avbrutt</>,
    },
    [MATCH_STATUS.PENALTY_SHOOTOUT]: {
      type: 'success',
      component: <>STR</>,
    },
    [MATCH_STATUS.FIRST_HALF]: {
      type: 'success',
      component:
        sportId === 1 ? (
          <MatchTimer
            matchStatusId={matchStatusId}
            timestamp={timestamp}
            startTime={timeStartFirstHalf}
          />
        ) : sportId === 2 ? (
          <>1. per</>
        ) : (
          <>1. omg</>
        ),
    },
    [MATCH_STATUS.SECOND_HALF]: {
      type: 'success',
      component:
        sportId === 1 ? (
          <MatchTimer
            matchStatusId={matchStatusId}
            timestamp={timestamp}
            startTime={timeStartSecondHalf}
          />
        ) : sportId === 2 ? (
          <>2. per</>
        ) : (
          <>2. omg</>
        ),
    },
    [MATCH_STATUS.THIRD_HALF]: {
      type: 'success',
      component: <>3. per</>,
    },
    [MATCH_STATUS.FIRST_HALF_EXTRA_TIME]: {
      type: 'success',
      component: (
        <MatchTimer
          matchStatusId={matchStatusId}
          timestamp={timestamp}
          startTime={timeStartFirstHalfExtraTime}
        />
      ),
    },
    [MATCH_STATUS.SECOND_HALF_EXTRA_TIME]: {
      type: 'success',
      component: (
        <MatchTimer
          matchStatusId={matchStatusId}
          timestamp={timestamp}
          startTime={timeStartSecondHalfExtraTime}
        />
      ),
    },
  };

  return matchStatus[matchStatusId];
};

const MatchStatus = ({
  sportId,
  matchStatusId,
  timestamp,
  timeStartFirstHalf,
  timeStartSecondHalf,
  timeStartFirstHalfExtraTime,
  timeStartSecondHalfExtraTime,
}: MatchStatusProps) => {
  const matchStatus = getMatchStatus({
    sportId,
    matchStatusId,
    timestamp,
    timeStartFirstHalf,
    timeStartSecondHalf,
    timeStartFirstHalfExtraTime,
    timeStartSecondHalfExtraTime,
  });

  if (!matchStatus) return null;

  return matchStatus?.component || null;
};

export default MatchStatus;
