import { QueryClient } from '@tanstack/react-query';

export {
  QueryErrorResetBoundary as ApiErrorResetBoundary,
  HydrationBoundary as ApiHydrationBoundary,
  QueryClientProvider as ApiProvider,
  useQueryClient as useApiClient,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense, lazy, useEffect, useState } from 'react';

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
);

export function createApiClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        throwOnError: true,
        retry: 0,
        staleTime: Infinity,
      },
    },
  });
}

export function ApiDevtools({
  initialIsOpen = false,
}: {
  initialIsOpen?: boolean;
}) {
  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    // @ts-expect-error
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <>
      <ReactQueryDevtools initialIsOpen />
      {showDevtools && (
        <Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </Suspense>
      )}
    </>
  );
}
