import { SPORT_GROUP } from '@ntb-sport/constants';
import { Fetch, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchAthleteType {
  apiBaseUrl: string;
  sportGroup: string;
  personId: string;
}

export const fetchAthlete = async ({
  apiBaseUrl,
  sportGroup,
  personId,
}: FetchAthleteType) => {
  const path =
    sportGroup === SPORT_GROUP.ICE_HOCKEY || sportGroup === SPORT_GROUP.HANDBALL
      ? 'nifPersons'
      : 'persons';

  const json = await Fetch({
    url: `${apiBaseUrl}/${path}/${personId}/`,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });

  return json;
};
