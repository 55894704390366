import { useEffect } from 'react';

export const useImportScript = ({
  url,
  enabled,
  onLoad,
}: {
  url: string;
  enabled: boolean;
  onLoad?: any;
}) => {
  useEffect(() => {
    if (!enabled) return;

    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      onLoad && onLoad();
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [url, enabled, onLoad]);
};

export default useImportScript;
