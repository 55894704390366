import { useQuery } from '@tanstack/react-query';

import { fetchMatch, FetchMatch } from './fetch';
import { getIsNifsSportGroup } from '@ntb-sport/helpers';
import { QUERY_KEYS } from '@ntb-sport/constants';

interface UseQueryNifsMatch {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: FetchMatch;
  options?: Record<string, unknown>;
}

export const useQueryNifsMatch = ({
  queryKey = QUERY_KEYS.MATCH,
  queryKeyParams,
  params,
  options,
}: UseQueryNifsMatch) => {
  const isNifsSportGroup = getIsNifsSportGroup(params?.sportGroup);

  const { data, isLoading, error } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchMatch(params),
    enabled:
      isNifsSportGroup && Boolean(params.sportGroup) && Boolean(params.matchId),
    ...options,
  });

  return { data, isLoading, error };
};
