import { Icon } from '../../chakra';

export const StandingsIcon = (props: any) => (
  <Icon {...props}>
    <g>
      <g>
        <g>
          <g>
            <rect fill="currentColor" x="5.8" y="2.1" width="15.9" height="3" />
            <rect fill="currentColor" x="5.8" y="9.9" width="15.9" height="3" />
            <rect
              fill="currentColor"
              x="5.8"
              y="17.8"
              width="15.9"
              height="3"
            />
          </g>
        </g>
        <g>
          <path
            fill="currentColor"
            d="M3.3,5.1c0,0.2-0.2,0.3-0.3,0.3H1.6c-0.2,0-0.3-0.2-0.3-0.3c0-0.2,0.2-0.3,0.3-0.3h0.3V2.6
				c-0.1,0-0.3,0-0.4-0.1C1.4,2.4,1.4,2.2,1.6,2l0.5-0.5c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0.1,0.2,0.2,0.2,0.3v3H3
				C3.1,4.8,3.3,4.9,3.3,5.1z"
          />
        </g>
        <g>
          <path
            fill="currentColor"
            d="M3.2,13.4H1.3c-0.2,0-0.3-0.2-0.3-0.3c0-0.7,0.4-1.1,0.6-1.3c0.1-0.1,0.3-0.2,0.5-0.3
				c0.5-0.2,0.7-0.4,0.7-0.8c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6c0,0.2-0.2,0.3-0.3,0.3S1,10.8,1,10.7
				C1,10,1.6,9.4,2.3,9.4s1.3,0.6,1.3,1.3c0,0.8-0.7,1.2-1.1,1.4c-0.2,0.1-0.3,0.2-0.4,0.2c0,0,0,0-0.1,0c0,0-0.2,0.1-0.2,0.3h1.4
				c0.2,0,0.3,0.2,0.3,0.3S3.4,13.4,3.2,13.4z"
          />
        </g>
      </g>
      <path
        fill="currentColor"
        d="M3.6,19.9c0,0.7-0.6,1.3-1.3,1.3S1,20.6,1,19.9c0-0.2,0.2-0.3,0.3-0.3c0.2,0,0.3,0.2,0.3,0.3
		c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.2-0.5-0.5-0.6H2.2c-0.2,0-0.3-0.2-0.3-0.3c0-0.2,0.2-0.3,0.3-0.3h0.1
		c0.2,0,0.3-0.2,0.3-0.4c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4c0,0.2-0.2,0.3-0.3,0.3s-0.3-0.1-0.3-0.3c0-0.6,0.5-1.1,1.1-1.1
		s1.1,0.5,1.1,1.1c0,0.3-0.1,0.5-0.2,0.7C3.4,19.2,3.6,19.5,3.6,19.9z"
      />
    </g>
  </Icon>
);
