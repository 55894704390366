import * as S from './ClubLogo.styled';
import CountryFlag from '../../common/CountryFlag/CountryFlag';
import { Skeleton, SkeletonCircle } from '../../chakra';

export interface ClubLogoProps {
  src: string;
  alt: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  name?: any;
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  isLoading?: boolean;
  displayFlag?: boolean;
  countryName?: string;
}

const getSkeletonSize = (size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
  switch (size) {
    case 'xs':
      return 'var(--ntb-space-6)';
    case 'sm':
      return 'var(--ntb-space-8)';
    case 'md':
      return 'var(--ntb-space-12)';
    case 'lg':
      return 'var(--ntb-space-16)';
    case 'xl':
      return 'var(--ntb-space-20)';
    default:
      return 'var(--ntb-space-8)';
  }
};

export function ClubLogo({
  src,
  alt,
  size,
  name,
  direction,
  isLoading,
  displayFlag = false,
  countryName = '',
}: ClubLogoProps) {
  const regExp = /U\d\d/;
  const regExpMatches = regExp.exec(countryName);
  const trimmedCountryName = countryName.substr(0, regExpMatches?.index).trim();

  return (
    <S.Container $direction={direction}>
      {(displayFlag || src) && (
        <S.ClubLogoContainer>
          <SkeletonCircle
            display="inline-flex"
            alignItems="center"
            isLoaded={!isLoading}
            width={getSkeletonSize(size)}
            height={getSkeletonSize(size)}
          >
            {displayFlag ? (
              <CountryFlag countryName={trimmedCountryName} size={size} />
            ) : src ? (
              <S.ClubLogo aria-hidden={true} src={src} alt={alt} $size={size} />
            ) : null}
          </SkeletonCircle>
        </S.ClubLogoContainer>
      )}
      <Skeleton isLoaded={!isLoading}>
        <S.ClubName $size={size}>{name}</S.ClubName>
      </Skeleton>
    </S.Container>
  );
}

export default ClubLogo;
