import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import { CSS_VARIABLES } from '../index';

export const TEAM_MATCH_RESULT_STYLES = [
  {
    name: 'Root',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_RESULT}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Header',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_RESULT}'] [data-element-id='${ELEMENT_IDS.HEADER}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Time (Regular)',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_RESULT}'] [data-element-id='${ELEMENT_IDS.TYPE_TIME}'] [data-status-id='normal']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Time (Overtime)',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_RESULT}'] [data-element-id='${ELEMENT_IDS.TYPE_TIME}'] [data-status-id='overtime']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
];
