import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import { IconButton } from '../../../../chakra';
import { RefObject } from 'react';
import { useBem } from '@ntb-sport/hooks';
import './month-picker.css';
interface MonthPickerProps {
  calendars: any;
  month: number;
  year: number;
  getBackProps: any;
  getForwardProps: any;
  monthRef: RefObject<HTMLDivElement>;
  translations: {
    previousDay: string;
    nextDay: string;
    previousMonth: string;
    nextMonth: string;
    months: string[];
    weekdays: string[];
    today: string;
  };
}

export const MonthPicker = ({
  calendars,
  month,
  year,
  translations,
  getBackProps,
  getForwardProps,
  monthRef,
}: MonthPickerProps) => {
  const bem = useBem(COMPONENT_IDS.DATE_SELECTOR);

  return (
    <div
      className={bem('month-picker')}
      ref={monthRef}
      tabIndex={0}
      aria-label="Velg måned"
    >
      <IconButton
        {...getBackProps({ calendars })}
        aria-label={translations.previousMonth}
        size="sm"
        icon={<ChevronLeftIcon />}
        className={bem('button')}
      />
      <span className={bem('month')} aria-live="polite">
        {translations.months[month]} {year}
      </span>
      <IconButton
        {...getForwardProps({ calendars })}
        data-element-id={ELEMENT_IDS.BUTTON}
        aria-label={translations.nextMonth}
        size="sm"
        icon={<ChevronRightIcon />}
        className={bem('button')}
      />
    </div>
  );
};
