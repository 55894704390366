import {
  extendTheme,
  type ThemeConfig,
  createMultiStyleConfigHelpers,
} from '@chakra-ui/react';
import { switchAnatomy } from '@chakra-ui/anatomy';

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: 'system',
  useSystemColorMode: true,
};

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  thumb: {
    bg: 'var(--base-switch-thumb-background-color)',
  },
  track: {
    bg: 'var(--base-switch-track-background-color)',
    _checked: {
      bg: 'var(--base-switch-track-background-color__active)',
    },
  },
});

const switchTheme = defineMultiStyleConfig({ baseStyle });

// 3. extend the theme
const theme = extendTheme({
  config,
  components: { Switch: switchTheme },
  styles: {
    global: () => ({
      body: {
        background: '',
      },
    }),
  },
});

export default theme;
