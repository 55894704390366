import { useEffect } from 'react';

export const useImportStyle = ({
  href,
  rel,
  enabled,
}: {
  href: string;
  rel: any;
  enabled: boolean;
}) => {
  useEffect(() => {
    if (!enabled) return;
    const style = document.createElement('link');
    style.href = href;
    if (rel) {
      style.rel = rel;
    }

    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, [href, enabled, rel]);
};

export default useImportStyle;
