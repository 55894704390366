import { Icon } from '../../chakra';
import { TransferIconsInterface } from './utils/TransferIconsInterface';

export const LoanMadePermanentIcon = (props: TransferIconsInterface) => (
  <Icon aria-hidden="true" w={props?.w} h={props?.h}>
    <svg viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="approval_delegation">
        <g mask="url(#mask0_394_7)">
          <path
            id="approval_delegation_2"
            d="M13.0189 9.125L8.77273 5.05572L9.62893 4.26395L13.0189 7.5127L19.8289 1.0152L20.6551 1.80697L13.0189 9.125ZM5.19343 17.8693L14.272 20.4082L21.4582 18.2807C21.435 17.9313 21.3246 17.6796 21.127 17.5255C20.9292 17.3714 20.7103 17.2943 20.4703 17.2943H14.7796C14.2704 17.2943 13.8097 17.2751 13.3975 17.2368C12.9851 17.1985 12.5627 17.1071 12.1303 16.9628L9.50203 16.1377L9.90823 15.0009L12.3382 15.8436C12.7244 15.9837 13.1628 16.0677 13.6534 16.0957C14.1442 16.1237 14.8165 16.1399 15.6703 16.1443C15.6703 15.7861 15.6015 15.4964 15.4639 15.2752C15.3263 15.054 15.129 14.9 14.872 14.8132L7.96753 12.3847C7.92133 12.3701 7.87893 12.3591 7.84033 12.3516C7.80193 12.3443 7.75963 12.3407 7.71343 12.3407H5.19343V17.8693ZM0.393433 20.5675V11.1907H7.69033C7.79973 11.1907 7.91283 11.2013 8.02963 11.2226C8.14663 11.2441 8.25743 11.274 8.36203 11.3123L15.2965 13.7471C15.7257 13.9033 16.0953 14.1842 16.4053 14.5898C16.7153 14.9952 16.8703 15.5133 16.8703 16.1443H20.4703C21.1935 16.1443 21.7474 16.3611 22.132 16.7946C22.5166 17.228 22.7089 17.7779 22.7089 18.4443V19.1521L14.332 21.6289L5.19343 19.0768V20.5675H0.393433ZM1.59343 19.4175H3.99343V12.3407H1.59343V19.4175Z"
            fill={props?.color ? props.color : 'currentColor'}
          ></path>
        </g>
      </g>
    </svg>
  </Icon>
);
