import Icon from '../Icon/Icon';

export const CyclingSportIcon = (props: any) => (
  <Icon viewBox="0 0 28.3 28.3" fill="currentcolor" {...props}>
    <g>
      <g>
        <g>
          <path
            d="M22.2,14.1c-0.4,0-0.9,0.1-1.3,0.2l-0.2-0.5c0.4,0,0.9-0.3,1-0.7c0.3-0.6,0-1.3-0.6-1.5l-0.2-0.1l0.1-0.2
				l0.9,0.6c0.2,0.1,0.4,0.1,0.5-0.1c0.1-0.2,0.1-0.4-0.1-0.5l-1.1-0.8c-0.2-0.1-0.4-0.1-0.5,0.1l-0.4,0.6L17.8,10l-0.8-2.7
				c0.3-0.2,0.4-0.6,0.5-1c0-0.8-0.6-1.4-1.4-1.4c-0.1,0-0.1,0-0.2,0c-4.3,0-7.2,2-7.8,2.4C7.5,7.7,7.2,8.4,7.4,9l1.8,4.9l-0.6,0.9
				c-0.7-0.4-1.6-0.7-2.5-0.7c-2.8,0-5.1,2.3-5.1,5.1c0,2.8,2.3,5.1,5.1,5.1c2.7,0,5-2.1,5.1-4.8h0.2l0.5,1.4
				c0.2,0.6,0.7,0.9,1.3,0.9c0.2,0,0.3,0,0.5-0.1c0.7-0.3,1.1-1.1,0.8-1.8l-0.4-1.2l4.6-5.5h0.7l0.5,0.2l0.3,0.9
				c-1.9,0.8-3.2,2.6-3.2,4.8c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1C27.3,16.4,25,14.1,22.2,14.1z M9.6,14.7l0.5,1.2
				c-0.2-0.3-0.5-0.5-0.8-0.7L9.6,14.7z M6.1,23.7c-2.4,0-4.4-2-4.4-4.4c0-2.5,2-4.4,4.4-4.4c0.8,0,1.5,0.2,2.2,0.6l-2.4,3.7
				c-0.1,0.1-0.1,0.2,0,0.4c0.1,0.1,0.2,0.2,0.3,0.2h4.4C10.4,21.9,8.5,23.7,6.1,23.7z M6.8,18.9l2.1-3.2c1,0.7,1.6,1.9,1.7,3.2H6.8
				z M12.1,12.6h-0.3l-0.3-0.9l0.9,0.5L12.1,12.6z M13.8,18.1l-0.7-1.8l1.8-3h2.9L13.8,18.1z M15.4,12.6l0.1-0.1
				c0.4-0.7,0.2-1.5-0.5-1.9l-3.4-2.1c0.8-0.3,1.9-0.6,3.1-0.8l1.1,3.4c0.1,0.3,0.3,0.6,0.6,0.7l1.6,0.7H15.4z M22.2,23.7
				c-2.5,0-4.4-2-4.4-4.4c0-1.8,1.1-3.4,2.7-4.1l1.4,4.2c0,0.1,0.2,0.2,0.3,0.2c0,0,0.1,0,0.1,0c0.2-0.1,0.3-0.3,0.2-0.4l-1.4-4.2
				c0.3-0.1,0.7-0.1,1-0.1c2.5,0,4.4,2,4.4,4.4C26.6,21.7,24.6,23.7,22.2,23.7z"
          />
        </g>
        <g>
          <path d="M21.7,6c0,1.1-0.9,2.1-2.1,2.1c-1.1,0-2.1-0.9-2.1-2.1c0-1.1,0.9-2.1,2.1-2.1C20.8,3.9,21.7,4.8,21.7,6z" />
        </g>
      </g>
    </g>
  </Icon>
);
