import { useBem } from '@ntb-sport/hooks';
import { COMPONENT_IDS } from '@ntb-sport/constants';
import './menu.css';
import React from 'react';
import classNames from 'classnames';

interface MenuProps {
  items: {
    component: React.ReactNode;
    active?: boolean;
  }[];
}

export const Menu = ({ items }: MenuProps) => {
  const bem = useBem(COMPONENT_IDS.MENU);

  return (
    <nav className={bem()}>
      <ul className={bem('list')}>
        {items?.map((item, index) => (
          <li
            key={index}
            className={classNames(bem('list-item'), {
              [bem('list-item--active')]: item?.active,
            })}
          >
            {item?.component}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Menu;
