import { MEDIA_QUERY } from '@ntb-sport/constants';

import styled, { css } from 'styled-components';

interface StyledProps {
  $type?: string;

  src?: any;
}

export const Event = styled.li<StyledProps>`
  position: relative;

  background-color: var(--background-color);
  color: var(--font-color);
`;

export const EventWrapper = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  padding: var(--ntb-space-1);

  column-gap: var(--ntb-space-1);
  border-left-width: var(--ntb-space-1);
  border-left-style: solid;
  border-left-color: var(--border-color);

  @container match-report ${MEDIA_QUERY.TABLET} {
    padding: var(--ntb-space-1) var(--ntb-space-1) var(--ntb-space-1)
      var(--ntb-space-2);
    column-gap: var(--ntb-space-2);
  }
`;

export const Icon = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  width: 34px;
  height: 34px;
  background-color: var(--background-color);
  border-radius: var(--ntb-radii-full);
  border-color: var(--border-color);
  border-width: 2px;
  border-style: solid;

  svg {
    color: var(--font-color);
    fill: var(--font-color);
  }

  @container match-report ${MEDIA_QUERY.DESKTOP} {
    width: 44px;
    height: 44px;
  }
`;

export const Commentary = styled.div`
  flex: 1;
  padding: 8px 5px;
`;

export const EventTitle = styled.div<StyledProps>`
  color: var(--font-color);

  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-semibold);
  white-space: break-spaces;
  margin: 0 0 var(--ntb-space-1) 0;

  @container match-report ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const Time = styled.span`
  margin-right: 5px;
`;

export const EventComment = styled.div<StyledProps>`
  color: var(--font-color);
  font-size: var(--ntb-fontSizes-xs);

  @container match-report ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const EventPerson = styled.span`
  a {
    color: inherit;
    text-decoration: underline;
  }
`;

export const EventTeam = styled.span`
  a {
    color: inherit;
    text-decoration: underline;
  }
`;

export const PlayerPreview = styled.button<StyledProps>`
  border: none;
  cursor: pointer;
  position: relative;
  background-image: ${(props) => `url("${props.src}")`};
  background-size: cover;
  min-height: 150px;
  width: 100%;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  @container match-report ${MEDIA_QUERY.TABLET} {
    min-height: 225px;
    width: 400px;
  }
`;

export const PlayIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 42px;
  width: 42px;
  border-radius: 50%;
`;
