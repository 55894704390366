import Icon from '../Icon/Icon';

export const HandballSportIcon = (props: any) => (
  <Icon viewBox="0 0 28.3 28.3" fill="currentcolor" {...props}>
    <g>
      <g>
        <g>
          <path
            d="M20.3,9.1c-0.1,1.3-1.3,2.2-2.6,2.1c-1.3-0.1-2.2-1.3-2.1-2.6c0.1-1.3,1.3-2.2,2.6-2.1
				C19.5,6.6,20.5,7.8,20.3,9.1z"
          />
        </g>
        <g>
          <path
            d="M21.7,15.8c-0.1,0-0.6,0.1-1.4,0.1c-1,0-2.3-0.2-3.6-1c-0.2,0.6-0.3,1.1-0.6,1.6c0.8,0.3,1.5,0.8,1.9,1.7
				c0.8,2.1-1.3,4.2-2.1,4.9c-0.3,0.3-0.6,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4c-0.5-0.6-0.5-1.4,0-2c1.1-1,1.3-1.6,1.4-1.8
				c-0.1-0.1-0.3-0.1-0.6-0.2c-2.9,4.3-7.3,5.4-7.5,5.4c-0.1,0-0.2,0-0.3,0c-0.6,0-1.2-0.4-1.4-1.1c-0.2-0.8,0.3-1.5,1.1-1.7
				c0.2-0.1,3.9-1,6.2-4.8c0.6-1,1.1-2.3,1.4-3.8c-1.8-1.1-4.8-3.5-4.5-7c0.1-0.6,0.6-1,1.2-1c0,0.1,0,0.2,0,0.3
				c-0.1,1,0.5,1.9,1.3,2.3c0.8,2.4,4.2,4,4.2,4c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0c0,0,0,0,0,0
				c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c1.5,2,4.1,1.5,4.2,1.5c0.6-0.1,1.2,0.3,1.4,0.9C22.8,15,22.4,15.6,21.7,15.8
				z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M15,5.7c-0.1,0.9-0.9,1.6-1.8,1.6c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6-0.1-0.9-0.3c-0.5-0.4-0.8-1-0.7-1.6
				c0-0.1,0-0.1,0-0.2c0.2-0.8,0.9-1.4,1.7-1.4c0.1,0,0.1,0,0.2,0C14.4,3.9,15.1,4.7,15,5.7z"
          />
        </g>
        <g>
          <path
            d="M15,5.7c-0.1,0.9-0.9,1.6-1.8,1.6c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6-0.1-0.9-0.3c-0.5-0.4-0.8-1-0.7-1.6
				c0-0.1,0-0.1,0-0.2c0.2-0.8,0.9-1.4,1.7-1.4c0.1,0,0.1,0,0.2,0C14.4,3.9,15.1,4.7,15,5.7z"
          />
          <path
            d="M15,5.7c-0.1,0.9-0.9,1.6-1.8,1.6c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6-0.1-0.9-0.3c-0.5-0.4-0.8-1-0.7-1.6
				c0-0.1,0-0.1,0-0.2c0.2-0.8,0.9-1.4,1.7-1.4c0.1,0,0.1,0,0.2,0C14.4,3.9,15.1,4.7,15,5.7z"
          />
        </g>
      </g>
    </g>
  </Icon>
);
