import React from 'react';
import Button from '../../chakra/Button/Button';

interface SubmitButtonProps {
  isLoading: boolean;
  label: string;
  loadingText?: string;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  isLoading,
  label,
  loadingText,
}) => {
  return (
    <Button
      type="submit"
      isLoading={isLoading}
      loadingText={loadingText || 'Laster...'}
      backgroundColor="var(--colors-submit-bg)"
      color="var(--colors-submit-font)"
      _hover={{
        backgroundColor: 'var(--colors-submit-bg__hover)',
        color: 'var(--colors-submit-font__hover)',
      }}
      variant="solid"
    >
      {label}
    </Button>
  );
};
