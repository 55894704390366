import * as S from './TeamStageStatistics.styled';

import { Fragment, useMemo } from 'react';
import { useSortBy, useTable } from 'react-table';

import { MatchesType } from '@ntb-sport/types';
import { CardIcon } from '../../icons';

import { Skeleton, Tooltip } from '../../chakra';

interface TeamStageStatisticsProps {
  data: any;
  isLoading?: boolean;
  ui?: any;
  statisticsType: any;
  matches: MatchesType;
  onClickPerson?: any;
  onClickTeam?: any;
  highlightFirstPlace?: boolean;
}

export const TeamStageStatistics = ({
  data,
  isLoading,
  ui,
  statisticsType,
  matches,
  onClickPerson,
  onClickTeam,
  highlightFirstPlace,
}: TeamStageStatisticsProps) => {
  const tableData = useMemo(() => data?.players || [], [data]);

  const columns = useMemo(() => {
    return [
      {
        accessor: 'place',
        isSortable: false,
        isVisible: true,
        Header: () => {
          return <S.RankHeader>#</S.RankHeader>;
        },
        Cell: (props: any) => {
          return <S.Rank $matches={matches}>{props.row.original.place}</S.Rank>;
        },
      },
      {
        Header: 'Spiller',
        accessor: 'player.name',
        isSortable: false,
        isVisible: true,
        maxWidth: 80,
        Cell: (props: any) => {
          const profileImage =
            props.row.original.player?.profilePictures &&
            props.row.original.player?.profilePictures.find(
              (image: any) =>
                image.imageTypeId === 18 ||
                image.imageTypeId === 17 ||
                image.imageTypeId === 19 ||
                image.imageTypeId === 113 ||
                image.imageTypeId === 1,
            );

          return (
            <S.Player
              $matches={matches}
              $isClickable={onClickPerson}
              onClick={() =>
                onClickPerson &&
                onClickPerson({
                  name: props.row.original.player.name,
                  id: props.row.original.player.id,
                  sportId: 1,
                })
              }
            >
              {matches?.tablet && (
                <S.ProfileImage>
                  {profileImage && <img src={profileImage?.url} />}
                </S.ProfileImage>
              )}

              {matches?.tablet
                ? props.row.original.player.name
                : props.row.original.player.shirtName ||
                  props.row.original.player.name}
            </S.Player>
          );
        },
      },
      {
        Header: 'Lag',
        accessor: 'player.teams[0].name',
        isSortable: false,
        isVisible: true,
        maxWidth: 80,
        Cell: (props: any) => {
          return (
            <S.Team
              $matches={matches}
              $isClickable={onClickTeam}
              onClick={() =>
                onClickTeam &&
                onClickTeam({
                  name: props.row.original.player.teams[0].name,
                  id: props.row.original.player.teams[0].id,
                  sportId: 1,
                })
              }
            >
              {props.row.original.player.teams[0]?.logo && (
                <S.TeamLogo>
                  <img src={props.row.original.player.teams[0]?.logo?.url} />
                </S.TeamLogo>
              )}
              {matches?.tablet
                ? props.row.original.player.teams[0].name
                : props.row.original.player.teams[0].shortName ||
                  props.row.original.player.teams[0].name}
            </S.Team>
          );
        },
      },
      {
        Header: 'Nasjonalitet',
        accessor: 'player.country.name',
        isSortable: false,
        isVisible:
          matches?.tablet && data?.stage?.tournament?.tournamentTypeId !== 1,
        Cell: (props: any) => {
          return (
            <S.Country $matches={matches}>
              <S.CountryFlag
                countryName={props.row.original.player?.country?.name}
              />

              {props.row.original.player.country.name}
            </S.Country>
          );
        },
      },
      {
        accessor: 'goals',
        isSortable: false,
        isVisible: statisticsType === 'goals',
        Header: () => {
          return <S.GoalsHeader>Mål</S.GoalsHeader>;
        },
        Cell: (props: any) => {
          return (
            <S.Goals $matches={matches}>{props.row.original.goals}</S.Goals>
          );
        },
      },
      {
        accessor: 'yellowCards',
        isSortable: false,
        isVisible: data?.sportId === 1 && statisticsType === 'cards',
        Header: () => {
          return <S.GoalsHeader>GK</S.GoalsHeader>;
        },
        Cell: (props: any) => {
          return (
            <S.Goals $matches={matches}>
              {props.row.original.yellowCards}
            </S.Goals>
          );
        },
      },
      {
        accessor: 'blueCards',
        isSortable: false,
        isVisible: data?.sportId === 3 && statisticsType === 'cards',
        Header: () => {
          return <S.GoalsHeader>BK</S.GoalsHeader>;
        },
        Cell: (props: any) => {
          return (
            <S.Goals $matches={matches}>{props.row.original.blueCards}</S.Goals>
          );
        },
      },
      {
        accessor: 'twoMinutes',
        isSortable: false,
        isVisible: data?.sportId === 3 && statisticsType === 'cards',
        Header: () => {
          return <S.GoalsHeader>2M</S.GoalsHeader>;
        },
        Cell: (props: any) => {
          return (
            <S.Goals $matches={matches}>
              {props.row.original.twoMinutes}
            </S.Goals>
          );
        },
      },
      {
        accessor: 'averageRating',
        isSortable: false,
        isVisible: statisticsType === 'ratings',
        Header: () => {
          return <S.GoalsHeader>Snitt</S.GoalsHeader>;
        },
        Cell: (props: any) => {
          return (
            <S.Goals $matches={matches}>
              {props.row.original?.averageRating?.toFixed(2)}
            </S.Goals>
          );
        },
      },
      {
        accessor: 'assists',
        isSortable: false,
        isVisible: statisticsType === 'assists',
        Header: () => {
          return <S.GoalsHeader>MG</S.GoalsHeader>;
        },

        Cell: (props: any) => {
          return (
            <S.Goals $matches={matches}>{props.row.original.assists}</S.Goals>
          );
        },
      },
      {
        accessor: 'redCards',
        isSortable: false,
        isVisible: data?.sportId === 1 && statisticsType === 'cards',
        Header: () => {
          return <S.GoalsHeader>RK</S.GoalsHeader>;
        },
        Cell: (props: any) => {
          return (
            <S.Goals $matches={matches}>{props.row.original.redCards}</S.Goals>
          );
        },
      },
      {
        accessor: 'indirectAssists',
        isSortable: false,
        isVisible: statisticsType === 'assists',
        Header: () => {
          return <S.GoalsHeader>IMG</S.GoalsHeader>;
        },
        Cell: (props: any) => {
          return (
            <S.Goals $matches={matches}>
              {props.row.original.indirectAssists}
            </S.Goals>
          );
        },
      },
    ];
  }, [data, matches, statisticsType, onClickPerson, onClickTeam]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: tableData,
        initialState: {
          hiddenColumns: columns.reduce((hiddenColumns: any, column: any) => {
            if (!column.isVisible) {
              hiddenColumns.push(column?.accessor);
            }
            return hiddenColumns;
          }, []),
        },
      },
      useSortBy,
    );

  if (isLoading) {
    return (
      <S.Container>
        <Skeleton style={{ height: '37px' }} />
        <Skeleton style={{ height: '37px' }} />
        <Skeleton style={{ height: '37px' }} />
        <Skeleton style={{ height: '37px' }} />
        <Skeleton style={{ height: '37px' }} />
        <Skeleton style={{ height: '37px' }} />
        <Skeleton style={{ height: '37px' }} />
        <Skeleton style={{ height: '37px' }} />
        <Skeleton style={{ height: '37px' }} />
        <Skeleton style={{ height: '37px' }} />
        <Skeleton style={{ height: '37px' }} />
      </S.Container>
    );
  }

  return (
    <S.Stats>
      {highlightFirstPlace &&
        data?.players?.slice(0, 1)?.map((stat: any) => {
          const profileImage = stat?.player?.profilePictures?.find(
            (image: any) =>
              image.imageTypeId === 18 ||
              image.imageTypeId === 17 ||
              image.imageTypeId === 19,
            // image.imageTypeId === 113 ||
            // image.imageTypeId === 1
          );
          return (
            <S.HighlightedStat $src={profileImage?.url} key={stat.id}>
              {/* <S.HighlightedBio> */}
              {/* <S.HighlightedRank>{`#${stat.place}`}</S.HighlightedRank> */}
              <S.HighlightedPlayerName>
                {`#${stat.place} ${stat?.player?.name}`}
              </S.HighlightedPlayerName>
              <S.HighlightedTeam>
                <S.TeamLogo>
                  <img src={stat.player.teams[0]?.logo?.url} />
                </S.TeamLogo>
                {stat.player.teams[0]?.name}
              </S.HighlightedTeam>
              <S.HighlightedStatCount>
                {statisticsType === 'cards' ? (
                  <>
                    {stat?.yellowCards}
                    <S.HighlightedStatCountLabel>
                      {'gule, '}
                    </S.HighlightedStatCountLabel>

                    {stat?.redCards}
                    <S.HighlightedStatCountLabel>
                      røde
                    </S.HighlightedStatCountLabel>
                  </>
                ) : (
                  <>
                    {stat?.[statisticsType]}
                    <S.HighlightedStatCountLabel>
                      {statisticsType === 'goals'
                        ? 'mål'
                        : statisticsType === 'assists'
                          ? 'målgivende'
                          : ''}
                    </S.HighlightedStatCountLabel>
                  </>
                )}
              </S.HighlightedStatCount>

              {/* </S.HighlightedBio> */}
              {/* <S.HighlightedProfileImage>
              {profileImage && (
                <img src={profileImage?.url} alt={stat?.player?.name} />
              )}
            </S.HighlightedProfileImage> */}
            </S.HighlightedStat>
          );
        })}
      {data?.players
        ?.slice(highlightFirstPlace ? 1 : 0, ui?.limit || -1)
        ?.map((stat: any, index: number) => {
          const profileImage = stat?.player?.profilePictures?.find(
            (image: any) => image.imageTypeId === 1,
          );
          return (
            <Fragment key={stat.id}>
              {index === 0 && (
                <S.Stat>
                  <div />
                  <S.StatCounts>
                    {statisticsType === 'cards' && (
                      <>
                        <S.StatCountHeader>
                          <Tooltip
                            hasArrow
                            label="Antall gule kort"
                            aria-label="Antall gule kort"
                          >
                            GK
                          </Tooltip>
                        </S.StatCountHeader>
                        <S.StatCountHeader>
                          <Tooltip
                            hasArrow
                            label="Antall røde kort"
                            aria-label="Antall røde kort"
                          >
                            RK
                          </Tooltip>
                        </S.StatCountHeader>
                      </>
                    )}
                    {statisticsType === 'goals' && (
                      <S.StatCountHeader>
                        <Tooltip
                          hasArrow
                          label="Antall mål"
                          aria-label="Antall mål"
                        >
                          Mål
                        </Tooltip>
                      </S.StatCountHeader>
                    )}
                    {statisticsType === 'assists' && (
                      <>
                        {data?.stage?.data?.indirectAssists && (
                          <S.StatCountHeader>
                            <Tooltip
                              hasArrow
                              label="Antall indirekte målgivende"
                              aria-label="Antall indirekte målgivende "
                            >
                              IMG
                            </Tooltip>
                          </S.StatCountHeader>
                        )}

                        <S.StatCountHeader>
                          <Tooltip
                            hasArrow
                            label="Antall målgivende"
                            aria-label="Antall målgivende "
                          >
                            MG
                          </Tooltip>
                        </S.StatCountHeader>
                      </>
                    )}
                  </S.StatCounts>
                </S.Stat>
              )}
              <S.Stat>
                <S.RankAndBio>
                  <S.Rank>
                    <S.RankLabel>#</S.RankLabel>
                    {stat.place}
                  </S.Rank>
                  <S.Player>
                    <S.ProfileImage>
                      {profileImage && (
                        <img src={profileImage?.url} alt={stat?.player?.name} />
                      )}
                    </S.ProfileImage>
                    <div>
                      <S.PlayerName>{stat?.player?.name}</S.PlayerName>
                      <S.TeamAndCountry>
                        <S.Team>
                          <S.TeamLogo>
                            <img src={stat.player.teams[0]?.logo?.url} />
                          </S.TeamLogo>
                          {stat.player.teams[0]?.name}
                        </S.Team>
                        {/* <S.Country $matches={matches}>
                      <S.CountryFlag countryName={stat.player?.country?.name} />
                      {stat.player.country.name}
                    </S.Country> */}
                      </S.TeamAndCountry>
                    </div>
                  </S.Player>
                </S.RankAndBio>
                <S.StatCounts>
                  {statisticsType === 'cards' && (
                    <>
                      <CardIcon
                        cardId="yellow-card"
                        fillColor="rgb(229, 223, 28)"
                        size="large"
                        count={stat?.yellowCards}
                      />
                      <CardIcon
                        cardId="red-card"
                        fillColor="rgb(235, 93, 64)"
                        size="large"
                        count={stat.redCards}
                      />
                    </>
                  )}
                  {statisticsType === 'goals' && (
                    <S.StatCount>{stat?.[statisticsType]}</S.StatCount>
                  )}
                  {statisticsType === 'assists' && (
                    <>
                      {data?.stage?.data?.indirectAssists && (
                        <S.StatCount>{stat?.indirectAssists}</S.StatCount>
                      )}
                      <S.StatCount>{stat?.[statisticsType]}</S.StatCount>
                    </>
                  )}
                </S.StatCounts>
              </S.Stat>
            </Fragment>
          );
        })}
    </S.Stats>
  );
};

export default TeamStageStatistics;
