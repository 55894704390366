import MediaPlayer from './MediaPlayer';

export interface NrkRadioPlayerProps {
  videoId: string;
  muted?: boolean;
  autoplay?: boolean;
  sourceId?: string;
  ga?: string;
  onClose: any;
}

export function NrkRadioPlayer({
  videoId,
  sourceId,
  ga,
  onClose,
}: NrkRadioPlayerProps) {
  return (
    <MediaPlayer onClose={onClose} isRadio={true}>
      <nrkno-player
        class="-player aspect-ratio aspect-ratio--16x9"
        type="player"
        sourceId={sourceId}
        ga={ga}
        src={videoId}
        autoplay
      ></nrkno-player>
    </MediaPlayer>
  );
}

export default NrkRadioPlayer;
