import { QUERY_KEYS } from '@ntb-sport/constants';

export function onEventParticipantsPost({
  msg,
  apiClient,
}: {
  msg: any;
  apiClient: any;
}) {
  const { additionalData } = msg;

  apiClient.refetchQueries({
    queryKey: [
      QUERY_KEYS.EVENT_PARTICIPANTS,
      { eventUUID: additionalData.event.uuid },
    ],
  });
}
