import styled from 'styled-components';

interface StyledProps {
  $isSelected?: any;
  $hasHighlightVideo?: boolean;
  src?: any;
  $title?: any;
  ref?: any;
}

export const ContainerQuery = styled.div`
  container-type: inline-size;
  container-name: match-lineup;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--ntb-space-6) 0;
  background-color: var(--background-color);
`;

export const TeamSelector = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(max-content, 150px));
  justify-content: center;
  margin-bottom: var(--ntb-space-4);
  padding: var(--ntb-space-1);
  background-color: var(--background-color);
  color: var(--font-color);
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props?.theme?.controls?.borderColor || 'transparent'};
  border-radius: ${(props) =>
    props?.theme?.controls?.borderRadius || 'var(--ntb-radii-md)'};
  grid-column-gap: var(--ntb-space-2);
`;

export const Team = styled.button<StyledProps>`
  background: none;
  border: none;
  padding: var(--ntb-space-1) var(--ntb-space-2);
  cursor: pointer;
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-medium);
  border-radius: var(--ntb-radii-md);

  background-color: ${({ $isSelected }) =>
    $isSelected
      ? 'var(--background-color__active)'
      : 'var(--background-color)'};
  color: ${({ $isSelected }) =>
    $isSelected ? 'var(--font-color__active)' : 'var(--font-color)'};

  &:hover {
    background-color: ${(props) =>
      !props.$isSelected && 'var(--background-color__hover)'};
    color: ${(props) => !props.$isSelected && 'var(--font-color__hover)'};
  }
`;

export const LineupNotAvailable = styled.div`
  padding: var(--ntb-space-6);
  font-size: var(--ntb-fontSizes-md);
`;

export const FootballTeams = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: var(--ntb-space-12);
`;

export const FootballTeam = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TeamName = styled.h2`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
  font-size: var(--ntb-fontSizes-xl);
  font-weight: var(--ntb-fontWeights-medium);
  margin-bottom: var(--ntb-space-2);
`;

export const TeamLogo = styled.img`
  width: 35px;
  height: 35px;
`;

export const Formation = styled.span`
  font-size: var(--ntb-fontSizes-lg);
  font-weight: var(--ntb-fontWeights-normal);
  color: var(--base-colors-blackAlpha-900);
  &:before {
    content: '(';
  }
  &:after {
    content: ')';
  }
`;
