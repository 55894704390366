import * as S from './TeamLineup.styled';

import FootballTeam from './FootballTeam/FootballTeam';
import HandballTeam from './HandballTeam/HandballTeam';
import IceHockeyTeam from './IcehockeyTeam/IcehockeyTeam';

import { useStateWithDep } from '@ntb-sport/hooks';
import { MatchesType, NifsMatchEvent, NifsTeam } from '@ntb-sport/types';
import { COMPONENT_IDS, ELEMENT_IDS, SPORT_ID } from '@ntb-sport/constants';

import ClubLogo from '../ClubLogo/ClubLogo';
import { Skeleton } from '../../chakra';

interface TeamBySportProps {
  sportId: number;
  team: NifsTeam;
  matchEvents: any;
  onClickPerson?: any;
  isNationalTeam?: any;
  matchTimestamp: string;
  displayNationality?: boolean;
}

const getTeamBySport = ({
  sportId,
  team,
  matchEvents,
  onClickPerson,
  isNationalTeam = false,
  displayNationality,
  matchTimestamp,
}: TeamBySportProps) => {
  const component = {
    [SPORT_ID.FOOTBALL]: (
      <FootballTeam
        team={team}
        matchEvents={matchEvents}
        onClickPerson={onClickPerson}
        matchTimestamp={matchTimestamp}
        isNationalTeam={isNationalTeam}
        displayNationality={displayNationality}
      />
    ),
    [SPORT_ID.ICE_HOCKEY]: (
      <IceHockeyTeam
        team={team}
        matchEvents={matchEvents}
        displayNationality={displayNationality}
      />
    ),
    [SPORT_ID.HANDBALL]: (
      <HandballTeam
        team={team}
        matchEvents={matchEvents}
        displayNationality={displayNationality}
      />
    ),
  };
  return component[sportId as keyof typeof component];
};

export const TeamLineup = ({
  sportId,
  homeTeam,
  awayTeam,
  matchEvents,
  isLineupConfirmed,
  onClickPerson,
  matches,
  tournament,
  isLoading,
  displayNationality,
  matchTimestamp,
}: {
  sportId: number;
  homeTeam: NifsTeam;
  awayTeam: NifsTeam;
  matchEvents: NifsMatchEvent;
  isLineupConfirmed: boolean;
  onClickPerson?: any;
  matches: MatchesType;
  tournament: any;
  isLoading: boolean;
  matchTimestamp: string;
  displayNationality?: boolean;
}) => {
  const [selectedTeam, setSelectedTeam] = useStateWithDep(homeTeam?.id);

  const onTeamClick = ({ id }: { id: number }) => {
    setSelectedTeam(id);
  };

  if (isLoading) return <Skeleton w="100%" h="600px" />;

  if (sportId === SPORT_ID.FOOTBALL && !isLineupConfirmed) {
    return (
      <S.LineupNotAvailable>
        Lagoppstilling ikke tilgjengelig
      </S.LineupNotAvailable>
    );
  }

  return (
    <S.ContainerQuery
      data-component-id={COMPONENT_IDS.TEAM_LINEUP}
      data-tournament-id={tournament?.id}
    >
      <S.Container>
        {!matches?.desktopS && (
          <S.TeamSelector data-element-id={ELEMENT_IDS.MENU}>
            <S.Team
              $isSelected={homeTeam?.id === selectedTeam ? true : false}
              onClick={() => onTeamClick({ id: homeTeam?.id })}
              data-element-id={ELEMENT_IDS.BUTTON}
            >
              {homeTeam?.name}
            </S.Team>
            <S.Team
              $isSelected={awayTeam?.id === selectedTeam ? true : false}
              onClick={() => onTeamClick({ id: awayTeam?.id })}
              data-element-id={ELEMENT_IDS.BUTTON}
            >
              {awayTeam?.name}
            </S.Team>
          </S.TeamSelector>
        )}

        {matches?.desktopS ? (
          <S.FootballTeams>
            <S.FootballTeam>
              <S.TeamName>
                <ClubLogo
                  src={homeTeam?.logo?.url}
                  alt=""
                  size="md"
                  name={homeTeam?.name}
                />

                {homeTeam?.formation && (
                  <S.Formation>{homeTeam?.formation}</S.Formation>
                )}
              </S.TeamName>
              {getTeamBySport({
                sportId,
                team: homeTeam,
                matchEvents,
                onClickPerson,
                displayNationality,
                matchTimestamp,
                isNationalTeam:
                  (sportId === SPORT_ID.FOOTBALL &&
                    (tournament?.tournamentTypeId === 1 ||
                      tournament?.tournamentTypeId === 5)) ||
                  (sportId === SPORT_ID.HANDBALL &&
                    tournament?.tournamentCategoryId >= 13 &&
                    tournament?.tournamentCategoryId <= 33),
              })}
            </S.FootballTeam>
            <S.FootballTeam>
              <S.TeamName>
                <ClubLogo
                  src={awayTeam?.logo?.url}
                  alt=""
                  size="md"
                  name={awayTeam?.name}
                />

                {awayTeam.formation && (
                  <S.Formation>{awayTeam.formation}</S.Formation>
                )}
              </S.TeamName>
              {getTeamBySport({
                sportId,
                team: awayTeam,
                matchEvents,
                onClickPerson,
                displayNationality,
                matchTimestamp,
                isNationalTeam:
                  (sportId === SPORT_ID.FOOTBALL &&
                    (tournament?.tournamentTypeId === 1 ||
                      tournament?.tournamentTypeId === 5)) ||
                  (sportId === SPORT_ID.HANDBALL &&
                    tournament?.tournamentCategoryId >= 13 &&
                    tournament?.tournamentCategoryId <= 33),
              })}
            </S.FootballTeam>
          </S.FootballTeams>
        ) : (
          <>
            <S.TeamName>
              <S.TeamLogo
                src={
                  selectedTeam === homeTeam.id
                    ? homeTeam?.logo?.url
                    : awayTeam?.logo?.url
                }
              />
              {selectedTeam === homeTeam.id ? homeTeam?.name : awayTeam?.name}
              {homeTeam?.formation && selectedTeam === homeTeam.id && (
                <S.Formation>{homeTeam?.formation}</S.Formation>
              )}
              {awayTeam?.formation && selectedTeam === awayTeam.id && (
                <S.Formation>{awayTeam?.formation}</S.Formation>
              )}
            </S.TeamName>
            {getTeamBySport({
              sportId,
              team: selectedTeam === homeTeam.id ? homeTeam : awayTeam,
              matchEvents,
              displayNationality,
              onClickPerson,
              matchTimestamp,
              isNationalTeam:
                (sportId === SPORT_ID.FOOTBALL &&
                  (tournament?.tournamentTypeId === 1 ||
                    tournament?.tournamentTypeId === 5)) ||
                (sportId === SPORT_ID.HANDBALL &&
                  tournament?.tournamentCategoryId >= 13 &&
                  tournament?.tournamentCategoryId <= 33),
            })}
          </>
        )}
      </S.Container>
    </S.ContainerQuery>
  );
};

export default TeamLineup;
