import {
  COMPONENT_IDS,
  DATA_PICKER_GROUPS,
  DATA_PROVIDERS,
  ELEMENT_IDS,
  SPORT_GROUP,
} from '@ntb-sport/constants';
import { WidgetConfig, SportsdataConfig } from '@ntb-sport/types';
import {
  DATA_TYPES,
  WIDGET_IDS,
  WIDGET_OPTIONS,
  WIDGET_VERSION,
} from '../common';

export const config: WidgetConfig = {
  id: WIDGET_IDS.TABLE,
  dataType: DATA_TYPES.NIFS,
  version: WIDGET_VERSION,
  displayName: 'Tabell (sportGruppe)',
  widgetNamespace: 'NtbWidgetTable',
  iframeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.TABLE}/iframe.html`,
  widgetUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.TABLE}/${WIDGET_IDS.TABLE}.js`,
  themeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/themes/default.css`,
  title: 'Tabell',
  summary: 'Tabell for turnering',
  sportGroups: [
    SPORT_GROUP.FOOTBALL,
    SPORT_GROUP.HANDBALL,
    SPORT_GROUP.ICE_HOCKEY,
  ],
  description: 'general description to be displayed on the documentation page',
  components: [COMPONENT_IDS.TEAM_STANDINGS],
  options: {
    [WIDGET_OPTIONS.TARGET_ID]: { isRequired: true },
    [WIDGET_OPTIONS.SPORT_GROUP]: { isRequired: true },
    [WIDGET_OPTIONS.CUSTOMER_ID]: { isRequired: true },
    [WIDGET_OPTIONS.STYLE_ID]: { isRequired: false },
    [WIDGET_OPTIONS.ID]: { isRequired: true },
    [WIDGET_OPTIONS.ON_ERROR]: { isRequired: false },
    [WIDGET_OPTIONS.ERROR_MESSAGE]: { isRequired: false },
  },
  uiOptions: {
    [COMPONENT_IDS.TEAM_STANDINGS]: [
      WIDGET_OPTIONS.TITLE,
      WIDGET_OPTIONS.SHOW_STAGE_NAME,
      WIDGET_OPTIONS.SHOULD_FOCUS,
    ],
  },
};

export const sportsdataConfig: SportsdataConfig = {
  dataPickerGroups: {
    [DATA_PICKER_GROUPS.NIFS_STAGE_GROUP]: {
      title: 'Stage',
      key: DATA_PICKER_GROUPS.NIFS_STAGE_GROUP,
      primaryId: DATA_PROVIDERS.NIFS_STAGES,
      isDefault: true,
      pickers: {
        [DATA_PROVIDERS.SPORT_GROUP]: { defaultValue: 'football' },
        [DATA_PROVIDERS.NIFS_COUNTRIES]: { defaultValue: 2 },
        [DATA_PROVIDERS.NIFS_TOURNAMENTS]: {},
        [DATA_PROVIDERS.NIFS_STAGES]: { isPrimaryId: true },
      },
    },
  },
  optionGroups: {
    [COMPONENT_IDS.TEAM_STANDINGS]: {
      title: 'Tabell',
      key: COMPONENT_IDS.TEAM_STANDINGS,
      options: {
        [WIDGET_OPTIONS.TITLE]: {
          key: WIDGET_OPTIONS.TITLE,
          defaultValue: 'Tabell:',
          label: 'Tittel',
          type: 'input',
        },
        [WIDGET_OPTIONS.SHOW_STAGE_NAME]: {
          key: WIDGET_OPTIONS.SHOW_STAGE_NAME,
          defaultValue: true,
          label: 'Vis turneringsnavn',
          type: 'checkbox',
        },
        [WIDGET_OPTIONS.SHOW_TABLE_INFO]: {
          key: WIDGET_OPTIONS.SHOW_TABLE_INFO,
          defaultValue: true,
          label: 'Vis info-knapp',
          type: 'checkbox',
        },
        [WIDGET_OPTIONS.SHOULD_FOCUS]: {
          key: WIDGET_OPTIONS.SHOULD_FOCUS,
          defaultValue: false,
          label: 'Sett fokus på tabell',
          type: 'checkbox',
        },
      },
    },
  },
};
