import { Fetch } from '../../fetch';

export interface FetchCustomerSetupType {
  apiBaseUrl: string;
}

export const fetchCustomerSetup = async ({
  apiBaseUrl,
}: FetchCustomerSetupType) => {
  const url = `${apiBaseUrl}/v2/customers/setup`;

  const json = await Fetch({
    url,
  });

  return json?.data;
};
