import { useQuery } from '@tanstack/react-query';

import { fetchNifsTournamentStages, FetchNifsTournamentStages } from './fetch';
import { QUERY_KEYS } from '@ntb-sport/constants';

interface UseQueryNifsTournamentStages {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: FetchNifsTournamentStages;
  options?: Record<string, unknown>;
}

export const useQueryNifsTournamentStages = ({
  queryKey = QUERY_KEYS.NIFS_TOURNAMENT_STAGES,
  queryKeyParams,
  params,
  options,
}: UseQueryNifsTournamentStages) => {
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchNifsTournamentStages(params),
    enabled: Boolean(params.sportGroup) && Boolean(params.tournamentId),
    ...options,
  });

  return { data, isLoading, error };
};
