import React from 'react';

import { useField } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
} from '../../chakra';

interface TextareaProps {
  label: string;
  name: string;
  placeholder?: string;
}

export const TextareaInput: React.FC<TextareaProps> = ({
  label,
  name,
  placeholder,
}) => {
  const [field, meta] = useField(name);

  return (
    <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
      <FormLabel>{label}</FormLabel>
      <Textarea
        {...field}
        placeholder={placeholder || ''}
        size="md"
        resize="none"
        borderColor="var(--colors-input-border)"
        backgroundColor="var(--colors-input-background)"
        color="var(--colors-input-text)"
        _placeholder={{
          textColor: 'var(--colors-input-placeholder-text)',
        }}
        _focus={{
          borderColor: 'var(--colors-input-border__focus)',
          boxShadow: '0 0 0 1px var(--colors-input-border__focus)',
        }}
        _hover={{
          borderColor: 'var(--colors-input-border__hover)',
        }}
        _invalid={{
          borderColor: 'var(--colors-input-border__invalid)',
          boxShadow: '0 0 0 1px var(--colors-input-border__invalid)',
        }}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export default TextareaInput;
