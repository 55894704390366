import { useBoolean } from '@ntb-sport/hooks';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import {
  ALL_IN_OUT,
  initialViewCount,
  TRANSFER_TYPE_FILTER_OPTIONS,
} from '../../utils/TransferHelpers';
export interface PlayerTransferInterface {
  date: string;
  person: {
    name: string;
    position: {
      position: string;
      x: number | null;
      y: number;
      type: string;
      uid: string;
      id: number;
    };
    birthDate: string;
    country: CountryCompact;
    profilePictures: {
      url: string;
      imageTypeId: number;
      type: string;
      uid: string;
      id: number;
    }[];
    shirtName: string;
    hiddenPersonInfo: boolean;
    type: string;
    uid: string;
    id: number;
  };
  fromTeam: TeamCompact;
  toTeam: TeamCompact;
  type: string;
  toDate?: string;
  uid: string;
  id: number;
  externalIds: null;
  sportId: number;
  isALoan?: boolean;
}

interface CountryCompact {
  name: string;
  shortName: string;
  type: string;
  uid: string;
  id: number;
  externalIds: null;
}

interface TeamCompact {
  name: string;
  country: CountryCompact;
  gender: string;
  logo: {
    url: string;
    imageTypeId: number;
    type: string;
    uid: string;
    id: number;
  };
  names: null;
  shortName: string | null;
  teamLevelInClub: number;
  type: string;
  uid: string;
  id: number;
  externalIds: null;
  sportId: number;
}

interface TransferContextType {
  searchValue: string;
  setSearchValue: any;
  filteredList: PlayerTransferInterface[];
  setFilteredList: any;
  itemList: PlayerTransferInterface[];
  isTableView: boolean;
  setIsTableView: any;
  visibleCount: number;
  setVisibleCount: any;
  selectedTypeFilterOption: string;
  setSelectedTypeFilterOption: any;
  resultsAmount: number;
  setResultsAmount: any;
  currentTeamTransferStatus: string | null;
  setCurrentTeamTransferStatus: any;
  selectedTeam: string;
  setSelectedTeam: any;
  tournamentPageStatus: string;
  setTournamentPageStatus: any;
}
const initialContextValue: TransferContextType = {
  searchValue: '',
  setSearchValue: () => {},
  filteredList: [],
  setFilteredList: () => {},
  itemList: [],
  isTableView: true,
  setIsTableView: () => {},
  visibleCount: 0,
  setVisibleCount: () => {},
  selectedTypeFilterOption: TRANSFER_TYPE_FILTER_OPTIONS.ALLE,
  setSelectedTypeFilterOption: () => {},
  resultsAmount: 0,
  setResultsAmount: () => {},
  currentTeamTransferStatus: ALL_IN_OUT.ALLE,
  setCurrentTeamTransferStatus: () => {},
  selectedTeam: '',
  setSelectedTeam: () => {},
  tournamentPageStatus: ALL_IN_OUT.ALLE,
  setTournamentPageStatus: () => {},
};
const TransferContext = createContext<TransferContextType>(initialContextValue);

interface TransferProviderProps {
  children: ReactNode;
  itemList: PlayerTransferInterface[];
}

export const TransferProvider: React.FC<TransferProviderProps> = ({
  children,
  itemList,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredList, setFilteredList] = useState<PlayerTransferInterface[]>(
    [],
  );
  const [isTableView, setIsTableView] = useBoolean(true);
  const [visibleCount, setVisibleCount] = useState<number>(initialViewCount);
  const [selectedTypeFilterOption, setSelectedTypeFilterOption] =
    useState<string>(TRANSFER_TYPE_FILTER_OPTIONS.ALLE);
  const [resultsAmount, setResultsAmount] = useState<number>(0);
  const [currentTeamTransferStatus, setCurrentTeamTransferStatus] = useState<
    string | null
  >(ALL_IN_OUT.ALLE);
  const [selectedTeam, setSelectedTeam] = useState<string>('');
  const [tournamentPageStatus, setTournamentPageStatus] = useState<string>(
    ALL_IN_OUT.ALLE,
  );

  const handleSearch = (searchValue: string) => {
    if (!searchValue) {
      setFilteredList(itemList);
      setResultsAmount(itemList.length);
      return;
    }
    const searchChecks = ['toTeam.name', 'fromTeam.name', 'person.name'];

    const searchResults = itemList.filter((transfer: PlayerTransferInterface) =>
      searchChecks.some((check: string) => {
        const value = check.split('.').reduce<any>((acc, key) => {
          if (acc && typeof acc === 'object' && key in acc) {
            return acc[key];
          }
          return undefined;
        }, transfer);

        if (typeof value === 'string') {
          return value.toLowerCase().includes(searchValue.toLowerCase());
        }
        return false;
      }),
    );

    if (searchResults.length !== 0 && searchValue !== '') {
      setResultsAmount(searchResults.length);
      setFilteredList(searchResults);
    } else {
      setResultsAmount(0);
      setFilteredList([]);
    }
  };

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setVisibleCount(
      itemList.length < initialViewCount ? itemList.length : initialViewCount,
    );
    setResultsAmount(itemList.length);
  }, [itemList]);

  return (
    <TransferContext.Provider
      value={{
        searchValue,
        setSearchValue,
        filteredList,
        setFilteredList,
        itemList,
        isTableView,
        setIsTableView,
        visibleCount,
        setVisibleCount,
        selectedTypeFilterOption,
        setSelectedTypeFilterOption,
        resultsAmount,
        setResultsAmount,
        currentTeamTransferStatus,
        setCurrentTeamTransferStatus,
        selectedTeam,
        setSelectedTeam,
        tournamentPageStatus,
        setTournamentPageStatus,
      }}
    >
      {children}
    </TransferContext.Provider>
  );
};

export default TransferContext;
