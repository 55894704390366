import { Icon } from '../../chakra';

export const JerseyIcon = (props: any) => (
  <Icon {...props} viewBox="0 0 200 200">
    <defs>
      <style>
        {`
      .${props.jerseyId}_cls-1 {
        fill: ${props.fillColor1 || '#fff'};
      }

      .${props.jerseyId}_cls-1, ${props.jerseyId}_cls-2 {
        stroke: ${props.strokeColor || '#000'};
        stroke-miterlimit: 10;
      }

      .${props.jerseyId}_cls-3 {
        clip-path: url(#clippath);
      }

      .${props.jerseyId}_cls-4, .${props.jerseyId}_cls-5 {
        stroke-width: 0px;
      }

      .${props.jerseyId}_cls-4, .${props.jerseyId}_cls-2 {
        fill: none;
      }

      .${props.jerseyId}_cls-5 {
        fill: ${props?.fillColor2 || 'transparent'};
      }`}
      </style>
      <clipPath id="clippath">
        <path
          className={`${props.jerseyId}_cls-4`}
          d="M197.3,50.2l-60.8-30.2c-6.3,8.7-20.2,14.8-36.4,14.8s-30.1-6.1-36.4-14.8L2.8,50.2c-2.5,1.2-3.5,4.2-2.2,6.7l17.9,35.8c1.2,2.5,4.2,3.5,6.7,2.2l17.7-8.7c3.3-1.6,7.2.8,7.2,4.5v79.3c0,5.5,4.5,10,10,10h80c5.5,0,10-4.5,10-10v-79.3c0-3.7,3.9-6.1,7.2-4.5l17.7,8.7c2.5,1.2,5.5.3,6.7-2.2l17.9-35.8c1.2-2.5.2-5.5-2.2-6.7Z"
        />
      </clipPath>
    </defs>
    <path
      className={`${props.jerseyId}_cls-1`}
      d="M197.3,50.2l-60.8-30.2c-6.3,8.7-20.2,14.8-36.4,14.8s-30.1-6.1-36.4-14.8L2.8,50.2c-2.5,1.2-3.5,4.2-2.2,6.7l17.9,35.8c1.2,2.5,4.2,3.5,6.7,2.2l17.7-8.7c3.3-1.6,7.2.8,7.2,4.5v79.3c0,5.5,4.5,10,10,10h80c5.5,0,10-4.5,10-10v-79.3c0-3.7,3.9-6.1,7.2-4.5l17.7,8.7c2.5,1.2,5.5.3,6.7-2.2l17.9-35.8c1.2-2.5.2-5.5-2.2-6.7Z"
    />
    <g className={`${props.jerseyId}_cls-3`}>
      <g>
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="101.5"
          cy="35.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="101.5"
          cy="55.9"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="101.5"
          cy="76.3"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="101.5"
          cy="96.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="101.5"
          cy="116.9"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="101.5"
          cy="137.3"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="101.5"
          cy="157.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="101.5"
          cy="176.1"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="79.4"
          cy="35.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="79.4"
          cy="55.9"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="79.4"
          cy="76.3"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="79.4"
          cy="96.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="79.4"
          cy="116.9"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="79.4"
          cy="137.3"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="79.4"
          cy="157.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="79.4"
          cy="176.1"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="57.3"
          cy="35.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="57.3"
          cy="55.9"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="57.3"
          cy="76.3"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="57.3"
          cy="96.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="32.5"
          cy="35.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="32.5"
          cy="55.9"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="32.5"
          cy="76.3"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="32.5"
          cy="96.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="7.6"
          cy="35.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="7.6"
          cy="55.9"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="7.6"
          cy="76.3"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="7.6"
          cy="96.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="57.3"
          cy="116.9"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="57.3"
          cy="137.3"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="57.3"
          cy="157.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="57.3"
          cy="176.1"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="120.6"
          cy="35.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="120.6"
          cy="55.9"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="120.6"
          cy="76.3"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="120.6"
          cy="96.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="120.6"
          cy="116.9"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="120.6"
          cy="137.3"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="120.6"
          cy="157.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="120.6"
          cy="176.1"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="142.7"
          cy="35.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="142.7"
          cy="55.9"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="142.7"
          cy="76.3"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="142.7"
          cy="96.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="167.5"
          cy="35.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="167.5"
          cy="55.9"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="167.5"
          cy="76.3"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="167.5"
          cy="96.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="192.4"
          cy="35.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="192.4"
          cy="55.9"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="192.4"
          cy="76.3"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="192.4"
          cy="96.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="142.7"
          cy="116.9"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="142.7"
          cy="137.3"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="142.7"
          cy="157.6"
          r="6.2"
        />
        <circle
          className={`${props.jerseyId}_cls-5`}
          cx="142.7"
          cy="176.1"
          r="6.2"
        />
      </g>
    </g>
    <path
      className={`${props.jerseyId}_cls-2`}
      d="M197.3,50.2l-60.8-30.2c-6.3,8.7-20.2,14.8-36.4,14.8s-30.1-6.1-36.4-14.8L2.8,50.2c-2.5,1.2-3.5,4.2-2.2,6.7l17.9,35.8c1.2,2.5,4.2,3.5,6.7,2.2l17.7-8.7c3.3-1.6,7.2.8,7.2,4.5v79.3c0,5.5,4.5,10,10,10h80c5.5,0,10-4.5,10-10v-79.3c0-3.7,3.9-6.1,7.2-4.5l17.7,8.7c2.5,1.2,5.5.3,6.7-2.2l17.9-35.8c1.2-2.5.2-5.5-2.2-6.7Z"
    />
  </Icon>
);
