import { LabelProperties } from 'neo-common-enums';
import { ELEMENT_IDS } from '@ntb-sport/constants';
import { useBem } from '@ntb-sport/hooks';
import './qualified.css';

const RESULT_IDS = {
  [LabelProperties.ResultId.TEMPORARY_LUCKY_LOOSER]: {
    name: '(LL)',
    type: ELEMENT_IDS.TYPE_SUCCESS,
  },
  [LabelProperties.ResultId.LUCKY_LOOSER]: {
    name: 'LL',
    type: ELEMENT_IDS.TYPE_SUCCESS,
  },
  [LabelProperties.ResultId.QUALIFIED_FOR_NEXT_ROUND]: {
    name: 'Q',
    type: ELEMENT_IDS.TYPE_SUCCESS,
  },
  [LabelProperties.ResultId.QUALIFIED_FOR_A_FINAL]: {
    name: 'QA',
    type: ELEMENT_IDS.TYPE_SUCCESS,
  },
  [LabelProperties.ResultId.QUALIFIED_FOR_B_FINAL]: {
    name: 'QB',
    type: ELEMENT_IDS.TYPE_SUCCESS,
  },
  125: {
    name: 'q',
    type: ELEMENT_IDS.TYPE_SUCCESS,
  },
};

export const Qualified = ({ resultId }: { resultId: number }) => {
  const bem = useBem('scope-results');

  const displayQualified =
    resultId === LabelProperties.ResultId.LUCKY_LOOSER ||
    resultId === LabelProperties.ResultId.TEMPORARY_LUCKY_LOOSER ||
    resultId === LabelProperties.ResultId.QUALIFIED_FOR_NEXT_ROUND ||
    resultId === LabelProperties.ResultId.QUALIFIED_FOR_A_FINAL ||
    resultId === LabelProperties.ResultId.QUALIFIED_FOR_B_FINAL ||
    resultId === 125;

  if (!displayQualified) return null;

  return (
    <div
      className={bem('qualified')}
      data-element-id={RESULT_IDS[resultId as keyof typeof RESULT_IDS]?.type}
    >
      {RESULT_IDS[resultId as keyof typeof RESULT_IDS]?.name}
    </div>
  );
};

export default Qualified;
