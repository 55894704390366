import { SPORT_GROUP } from '@ntb-sport/constants';
import { Fetch, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchMatch {
  sportGroup: string;
  apiBaseUrl: string;
  matchId: string | number;
}

export const fetchMatch = async ({
  sportGroup,
  apiBaseUrl,
  matchId,
}: FetchMatch) => {
  const path =
    sportGroup === SPORT_GROUP.ICE_HOCKEY || sportGroup === SPORT_GROUP.HANDBALL
      ? 'nifMatches'
      : 'matches';

  const url = `${apiBaseUrl}/${path}/${matchId}/`;

  const json = await Fetch({
    url,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });

  return json;
};
