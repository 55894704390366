import { ENTITIES } from '../utils';
import { onEventsDelete } from './delete';
import { onEventsPatch } from './patch';
import { onEventsPost } from './post';

export function onHandleUpdateEvents({
  msg,
  apiClient,
}: {
  msg: any;
  apiClient: any;
}) {
  const { entity, method } = msg;

  if (entity !== ENTITIES.EVENTS) return;

  if (method === 'POST') {
    onEventsPost({ msg, apiClient });
  } else if (method === 'PATCH') {
    onEventsPatch({ msg, apiClient });
  } else if (method === 'DELETE') {
    onEventsDelete({ msg, apiClient });
  } else {
    console.log('Unknown method', method);
    return;
  }
}
