import { NeoScopeResultType } from '@ntb-sport/types';
import './cycling.css';

import { Rank, Records, Qualified, Duration, Points } from '../Common';
import { Jerseys } from '../Common/Jerseys/Jerseys';
import Leader from '../Common/Leader/Leader';
import ResultId from '../Common/ResultId/ResultId';
import { useBem } from '@ntb-sport/hooks';
import EventParticipant from '../../../EventParticipant/EventParticipant';

interface CyclingProps {
  scopeResult: NeoScopeResultType;
  highlightedCountryUUID?: string;
  tourCompetitionUuid?: string;
  scopeCategoryId?: number;
  isMedalEvent?: boolean;
  scopeTypeId?: number;
}

export const Cycling = ({
  scopeResult,
  highlightedCountryUUID,
  tourCompetitionUuid,
  scopeCategoryId,
  isMedalEvent,
  scopeTypeId,
}: CyclingProps) => {
  const bem = useBem('scope-results');

  return (
    <>
      {scopeResult?.rank && (
        <Rank
          scopeResult={scopeResult}
          highlightedCountryUUID={highlightedCountryUUID}
          isMedalEvent={isMedalEvent}
          scopeTypeId={scopeTypeId}
        />
      )}
      <EventParticipant
        person={scopeResult?.participant?.person}
        team={scopeResult?.participant?.team}
        representing={scopeResult?.participant?.representing}
        bibNumber={scopeResult?.participant?.bibNumber}
        lineup={scopeResult?.participant?.lineup}
        lineupExchanger={scopeResult?.participant?.lineupExchanger}
        isPelotonParticipant={scopeResult?.participant?.isPelotonParticipant}
      />
      <Jerseys
        scopeResult={scopeResult}
        tourCompetitionUuid={tourCompetitionUuid}
      />

      <div className={bem('cycling-result')}>
        <Qualified resultId={scopeResult?.resultId} />
        <Records records={scopeResult?.records} />

        {scopeCategoryId ? (
          <Points points={scopeResult.points} resultId={scopeResult.resultId} />
        ) : Boolean(scopeResult.duration) ||
          Boolean(scopeResult.timeDifference) ? (
          <Duration
            duration={scopeResult.duration}
            timeDifference={scopeResult.timeDifference}
            resultId={scopeResult.resultId}
          />
        ) : (
          <>
            {scopeResult?.resultId ? (
              <ResultId resultId={scopeResult.resultId} />
            ) : (
              <Leader />
            )}
          </>
        )}
      </div>
    </>
  );
};
