import Icon from '../Icon/Icon';

export const PenaltyMissIcehockeyIcon = ({
  fillColor1 = '#000',
  fillColor2 = '##e51c30',
  ...restProps
}: {
  fillColor1?: string;
  fillColor2?: string;
}) => (
  <Icon {...restProps} className="penalty-miss-handball">
    <path
      style={{ fill: fillColor1 }}
      d="M21.15,14.19l-1.05-1.05c-0.1,0.03-0.2,0.06-0.3,0.06c-0.15,0-0.3-0.03-0.45-0.09l1.38,1.39h1.41v-2.98l-1,0.89
v1.78H21.15z"
    />
    <path
      style={{ fill: fillColor1 }}
      d="M3.92,12.63l1.6-1.6l0.92,0.92c0.14-0.09,0.29-0.19,0.45-0.27l-1.01-1.01l1.6-1.6l1.6,1.6l-0.31,0.31
c0.26-0.06,0.54-0.11,0.82-0.16c-0.05-0.13-0.09-0.26-0.09-0.4c0-0.07,0.03-0.14,0.04-0.2l-0.1,0.1L7.83,8.71l1.61-1.6l1.41,1.41
l0.35-0.36L9.79,6.75l1.54-1.53l-0.35-0.35L9.44,6.4L7.83,4.8l1.61-1.61l0.15,0.15C9.53,3.2,9.5,3.04,9.5,2.88
c0-0.32,0.14-0.63,0.38-0.86L9.9,2H4.1C3.28,2,2.51,2.29,1.9,2.78C1.77,2.87,1.64,2.99,1.53,3.11C0.96,3.7,0.6,4.49,0.56,5.36
C0.55,5.42,0.55,5.49,0.55,5.55v8.95h1.5l1.51-1.52l1.26,1.26c0.03-0.19,0.08-0.38,0.15-0.56L3.92,12.63z M5.52,10.32l-1.6-1.61
l1.6-1.6l1.61,1.6L5.52,10.32z M3.56,8.36l-1.6-1.61l1.61-1.6l1.6,1.6L3.56,8.36z M9.09,6.76l-1.61,1.6l-1.6-1.6l1.6-1.61L9.09,6.76
z M8.92,3L7.48,4.44L6.03,3H8.92z M7.13,4.8L5.52,6.4l-1.6-1.6l1.6-1.61L7.13,4.8z M4.11,3h0.9L3.56,4.44L2.61,3.49
C3.03,3.18,3.55,3,4.11,3z M1.55,5.55c0-0.67,0.26-1.28,0.69-1.73l0.97,0.97L1.61,6.4L1.55,6.35V5.55z M1.55,7.16l0.06-0.05l1.6,1.6
l-1.6,1.61l-0.06-0.06C1.55,10.26,1.55,7.16,1.55,7.16z M1.55,14.29v-3.21l0.06-0.05l1.6,1.6L1.55,14.29z M1.96,10.67l1.6-1.6
l1.61,1.6l-1.6,1.61L1.96,10.67z"
    />
    <path
      style={{ fill: fillColor1 }}
      d="M16.84,10.74l-0.96,0.96c0.16,0.09,0.31,0.18,0.45,0.27l0.88-0.88L16.84,10.74z"
    />
    <path
      style={{ fill: fillColor1 }}
      d="M21.15,5.55v0.82l-0.69-0.69L20.1,6.03l0.72,0.72l-0.59,0.59l0.35,0.35l0.55-0.55v1.1l1,1V5.55
c0-0.04,0-0.07-0.01-0.11c-0.01-0.41-0.09-0.8-0.23-1.16L21.1,5.07C21.13,5.22,21.15,5.39,21.15,5.55z"
    />
    <path
      style={{ fill: fillColor1 }}
      d="M18.8,12.7l-1.03,1.03c0.06,0.19,0.1,0.38,0.13,0.58l1.3-1.3c-0.08-0.05-0.17-0.09-0.25-0.16L18.8,12.7z"
    />
    <polygon
      style={{ fill: fillColor1 }}
      points="16.4,3 17.4,2 14.8,2 15.8,3 "
    />
    <path
      style={{ fill: fillColor1 }}
      d="M16.91,17.71V14.6c0-0.03-0.01-0.06-0.01-0.1c-0.02-0.38-0.17-0.73-0.42-1.05c-0.11-0.14-0.25-0.28-0.41-0.41
c-0.39-0.33-0.9-0.61-1.49-0.82c-0.03-0.01-0.07-0.03-0.1-0.04c-0.01,0-0.03-0.01-0.04-0.01c-0.12-0.04-0.25-0.08-0.38-0.12
l-0.8,0.8c-0.23,0.23-0.54,0.35-0.85,0.35c-0.3,0-0.59-0.11-0.82-0.33l-1.2-1.13c-0.01,0-0.01,0-0.02,0
c-0.06,0.01-0.13,0.01-0.19,0.02c-0.68,0.07-1.31,0.21-1.87,0.4s-1.03,0.43-1.42,0.71c-0.3,0.22-0.54,0.46-0.72,0.72
c-0.19,0.28-0.32,0.59-0.35,0.91c0,0.03-0.02,0.06-0.02,0.1c0,0,0,0,0,0.05v3.01c0,0.68,0.43,1.28,1.19,1.8
c0.08,0.05,0.14,0.11,0.22,0.16c0.15,0.1,0.3,0.15,0.45,0.25c0.17,0.07,0.34,0.15,0.51,0.22s0.36,0.13,0.59,0.18
c0.15,0.05,0.35,0.1,0.45,0.1c0.65,0.15,1.35,0.25,2.15,0.25c1.15,0,2.21-0.17,3.09-0.47c0.01,0,0.03-0.01,0.04-0.01
c0.03-0.01,0.05-0.02,0.08-0.03C15.99,19.58,16.91,18.72,16.91,17.71z M7.64,19.31c-0.2-0.1-0.35-0.2-0.45-0.3V16.5
c0.05,0,0.1,0.05,0.15,0.05c0.1,0.05,0.25,0.15,0.4,0.2L7.64,19.31z M9.2,19.86c-0.15-0.05-0.35-0.1-0.45-0.15v-2.56
c0.15,0.05,0.35,0.1,0.45,0.1V19.86z M10.65,20.06c-0.15,0-0.35-0.05-0.45-0.05c-0.05,0,0-1.9,0-2.1c0-0.15,0-0.35,0-0.45
c0.15,0,0.35,0.05,0.45,0.05V20.06z M11.6,20.06V17.5c0.8,0,1.65-0.15,2.46-0.35c0.9-0.25,1.75-0.65,2.41-1.35v1.85
C16.46,18.91,14.26,20.01,11.6,20.06z"
    />
    <polygon
      style={{ fill: fillColor2 }}
      points="17.8,6.6 21.6,2.9 19.8,1.3 16.1,5 12.4,1.3 10.7,2.9 14.4,6.6 10.7,10.4 12.4,12 16.1,8.3 19.8,12 
21.6,10.4 "
    />
  </Icon>
);
