import { MEDIA_QUERY } from '@ntb-sport/constants';
import styled from 'styled-components';
import { CountryFlag } from '../../../common/CountryFlag/CountryFlag';

export const PlayerCard = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-radius: var(--ntb-radii-md);
  position: relative;
  max-width: 450px;
  height: 31rem;
  margin: auto;
  color: white;
  box-shadow: var(--shadow-btn);
  background: linear-gradient(
    180deg,
    var(--base-colors-brand-dark-1000-background-color) 0%,
    var(--base-colors-brand-dark-900-background-color) 100%
  );

  @media ${MEDIA_QUERY.TABLET_S} {
    border-radius: var(--ntb-radii-sm);
    color: currentColor;
    width: 100%;
    max-width: 100%;
    height: 16rem;
    flex-direction: row;
    align-items: center;
    gap: var(--ntb-space-6);
    background: linear-gradient(
      380deg,
      var(--base-colors-brand-900-background-color) 0%,
      var(--base-colors-brand-400-background-color) 80%,
      var(--base-colors-brand-700-background-color) 100%
    );
  }
`;

export const PlayerCardImg = styled.div`
  object-fit: cover;
  object-position: center;
  background: var(--base-colors-brand-dark-800-background-color);
  min-height: 10%;
  height: 100%;
  margin-left: -0.5%;
  margin-top: -2px;
  border-radius: var(--ntb-radii-md) var(--ntb-radii-md) 0 0;
  min-width: 101%;
  filter: saturate(80%);
  mask-image: linear-gradient(
    to bottom,
    var(--base-colors-brand-dark-800-background-color) 40%,
    rgba(0, 0, 0, 0) 100%
  );

  @media ${MEDIA_QUERY.TABLET_S} {
    background: var(--base-colors-brand-dark-200-background-color);
    border-radius: var(--ntb-radii-sm) 0 0 var(--ntb-radii-sm);
    min-width: 20%;
    width: 40%;
    margin: 0;
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 65%,
      rgba(0, 0, 0, 0.7) 75%,
      rgba(0, 0, 0, 0.5) 80%,
      rgba(0, 0, 0, 0.2) 90%,
      rgba(0, 0, 0, 0) 96%
    );
  }
`;

export const PlayerCardInfo = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: var(--ntb-space-4);

  @media ${MEDIA_QUERY.TABLET_S} {
    padding: var(--ntb-space-4);
    gap: var(--ntb-space-8);
    justify-content: center;
  }
`;

export const Bio = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--ntb-space-3);
  width: 100%;

  @media ${MEDIA_QUERY.TABLET_S} {
    gap: var(--ntb-space-4);
  }
`;

export const Name = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  font-weight: var(--ntb-fontWeights-semibold);
  font-size: var(--ntb-fontSizes-2xl);
  line-height: 1.2;
  text-align: center;

  @media ${MEDIA_QUERY.TABLET_S} {
    font-weight: var(--ntb-fontWeights-regular);
    letter-spacing: 0.5px;
    gap: var(--ntb-space-5);
    justify-content: flex-start;
  }
`;

export const AdditionalInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 80%;
  margin: auto;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--ntb-space-1);
    font-weight: 300;
    font-size: var(--ntb-fontSizes-sm);

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--ntb-space-2);
    }

    p {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  span {
    font-weight: var(--ntb-fontWeights-regular);
    text-transform: uppercase;
    font-size: var(--ntb-fontSizes-xs);
    opacity: 50%;
  }

  div:first-child > p {
    display: flex;
    flex-direction: row;
    gap: var(--ntb-space-2);
  }

  @media ${MEDIA_QUERY.TABLET_S} {
    width: 100%;
    gap: var(--ntb-space-14);
    justify-content: flex-start;

    div > p {
      max-width: 100%;
    }
  }
`;

export const TransferContainer = styled.div`
  padding: var(--ntb-space-4) var(--ntb-space-3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: var(--ntb-space-6);

  @media ${MEDIA_QUERY.TABLET_S} {
    align-items: flex-start;
    padding: 0;
    gap: var(--ntb-space-8);
  }
`;

export const TransferBackground = styled.div`
  position: absolute;
  width: 100%;
  height: var(--ntb-space-12);
  background: linear-gradient(
    90deg,
    var(--base-colors-brand-dark-700-background-color) 0%,
    var(--base-colors-brand-dark-800-background-color) 100%
  );
  opacity: 0.8;
  right: 0;
  z-index: -1;

  @media ${MEDIA_QUERY.TABLET_S} {
    opacity: 0.7;
    background: linear-gradient(
      160deg,
      var(--base-colors-brand-dark-300-background-color) 0%,
      var(--base-colors-brand-dark-100-background-color) 30%,
      var(--base-colors-brand-dark-400-background-color) 100%
    );

    width: 80%;
    mask-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.5) 60%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;

export const TransferTeamDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--ntb-space-3);
  width: 100%;
  z-index: 1;
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-semibold);

  span {
    min-width: fit-content;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: var(--ntb-space-2);

    p {
      max-width: 100px;
      display: inline;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media ${MEDIA_QUERY.TABLET_S} {
    gap: var(--ntb-space-5);
    justify-content: flex-start;
    font-size: var(--ntb-fontSizes-lg);
    font-weight: var(--ntb-fontWeights-semibold);

    div {
      justify-content: flex-start;
      gap: var(--ntb-space-3);

      p {
        max-width: 200px;
        text-align: left;
      }
    }
  }
`;

export const TransferDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--ntb-space-8);
  font-weight: var(--ntb-fontWeights-light);
  font-size: var(--ntb-fontSizes-sm);

  svg {
    height: 100%;
  }

  p {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--ntb-space-2);
  }

  @media ${MEDIA_QUERY.TABLET_S} {
    align-self: flex-end;
    justify-content: flex-end;
    padding-right: var(--ntb-space-4);
    font-size: var(--ntb-fontSizes-md);
  }
`;

export const Flag = styled(CountryFlag).withConfig({
  shouldForwardProp: (prop) => ['countryName'].includes(prop),
})``;

export const TeamNationalFlag = styled(CountryFlag).withConfig({
  shouldForwardProp: (prop) => ['countryName'].includes(prop),
})`
  max-height: 10px;
  margin-left: -2px;
  object-fit: contain;

  @media ${MEDIA_QUERY.TABLET} {
    max-height: 11px;
    margin-left: -4px;
  }
`;

export const TeamLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--ntb-space-5);
  height: var(--ntb-space-5);
  max-width: var(--ntb-space-5);
  max-height: var(--ntb-space-5);
  object-fit: cover;
`;
