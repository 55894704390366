const Kit = ({
  size = 100,
  children,
  viewbox = '0 0 138.3 142.8',
  className,
  ...restProps
}: {
  size?: any;
  children?: any;
  viewbox?: any;
  className?: any;
}) => {
  return (
    <svg
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      xmlSpace="preserve"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewbox}
      width={size}
      height={size * 1.2}
      {...restProps}
    >
      {children}
    </svg>
  );
};

export default Kit;
