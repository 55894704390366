import React, { useEffect, useState } from 'react';
import { GiTrophy } from 'react-icons/gi';

import { COMPONENT_IDS } from '@ntb-sport/constants';

import { CountryFlag } from '../../common/CountryFlag/CountryFlag';

import * as S from './StageHero.styled';
import { Skeleton } from '../../chakra';

interface StageHeroProps {
  competition: any;
  isLoading?: boolean;
  showLogo: boolean | undefined;
}

export const StageHero: React.FC<StageHeroProps> = ({
  competition,
  isLoading = false,
  showLogo = true,
}) => {
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [isLoadingLogo, setIsLoadingLogo] = useState<boolean>(true);

  useEffect(() => {
    async function fetchLogo() {
      if (competition) {
        const response = await fetch(
          `https://api.nifs.no/images/tournaments/logo/${competition?.tournament?.id}/`,
        );

        if (response.status === 200) {
          setLogoUrl(response.url);
        }
        setIsLoadingLogo(false);
      }
    }
    fetchLogo();
  }, [competition]);

  if (isLoading) {
    return (
      <S.Container data-component-id={COMPONENT_IDS.STAGE_HERO}>
        <Skeleton height="100%" minH="86px" width="100%" />
      </S.Container>
    );
  }

  return (
    <S.Container
      data-component-id={COMPONENT_IDS.STAGE_HERO}
      data-tournament-id={competition?.id}
    >
      {showLogo && !isLoadingLogo && (
        <S.LogoContainer>
          {logoUrl ? (
            <S.Logo src={logoUrl} alt="Logo for turnering" />
          ) : (
            <GiTrophy size={80} fill="var(--logo-color)" />
          )}
        </S.LogoContainer>
      )}
      <S.Info $showLogo={showLogo}>
        <S.Name as="h1">{competition?.name}</S.Name>
        <S.Country>
          <CountryFlag countryName={competition?.tournament?.country?.name} />
          <S.CountryName>
            {competition?.tournament?.country?.name}
          </S.CountryName>
        </S.Country>
      </S.Info>
    </S.Container>
  );
};

export default StageHero;
