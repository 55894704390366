import { QUERY_KEYS } from '@ntb-sport/constants';
import { updateObjectInArray } from '../utils';

export function onEventParticipantsPatch({
  msg,
  apiClient,
}: {
  msg: any;
  apiClient: any;
}) {
  const { entityId, data, additionalData } = msg;

  const state = apiClient.getQueryData([
    QUERY_KEYS.EVENT_PARTICIPANTS,
    { eventUUID: additionalData.event.uuid },
  ]);

  const entityIndex = state?.findIndex((item: any) => item?.uuid === entityId);

  const updatedObject = data?.reduce((acc: any, item: any) => {
    const key = item?.path.substr(
      item.path.lastIndexOf('/') + 1,
      item.path.length,
    );
    const value = item.value;

    if (!acc.key) {
      acc[key] = value;
    }

    return acc;
  }, {});

  const newState = updateObjectInArray(state, {
    index: entityIndex,
    item: {
      ...state?.[entityIndex],
      ...updatedObject,
    },
  });

  apiClient.setQueryData(
    [QUERY_KEYS.EVENT_PARTICIPANTS, { eventUUID: additionalData.event.uuid }],
    newState,
  );
}
