import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import { CSS_VARIABLES } from '../index';

export const TEAM_MATCH_REPORT_STYLES = [
  {
    name: 'Header',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.HEADER}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Summary',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.SUMMARY}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Event (Default)',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.TYPE_DEFAULT}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Event (Default) Icon',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.TYPE_DEFAULT}'] [data-element-id='${ELEMENT_IDS.ICON}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Event (Default) Title',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.TYPE_DEFAULT}'] [data-element-id='${ELEMENT_IDS.TITLE}'] `,
    variables: [CSS_VARIABLES.FONT_COLOR],
  },
  {
    name: 'Event (Success)',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.TYPE_SUCCESS}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Event (Success) Icon',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.TYPE_SUCCESS}'] [data-element-id='${ELEMENT_IDS.ICON}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Event (Success) Title',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.TYPE_SUCCESS}'] [data-element-id='${ELEMENT_IDS.TITLE}'] `,
    variables: [CSS_VARIABLES.FONT_COLOR],
  },
  {
    name: 'Event (Success Minor)',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS['TYPE_SUCCESS-MINOR']}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Event (Success Minor) Icon',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS['TYPE_SUCCESS-MINOR']}'] [data-element-id='${ELEMENT_IDS.ICON}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Event (Success Minor) Title',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS['TYPE_SUCCESS-MINOR']}'] [data-element-id='${ELEMENT_IDS.TITLE}'] `,
    variables: [CSS_VARIABLES.FONT_COLOR],
  },
  {
    name: 'Event (Error)',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.TYPE_ERROR}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Event (Error) Icon',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.TYPE_ERROR}'] [data-element-id='${ELEMENT_IDS.ICON}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Event (Error) Title',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.TYPE_ERROR}'] [data-element-id='${ELEMENT_IDS.TITLE}'] `,
    variables: [CSS_VARIABLES.FONT_COLOR],
  },
  {
    name: 'Event (Error Minor)',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS['TYPE_ERROR-MINOR']}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Event (Error Minor) Icon',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS['TYPE_ERROR-MINOR']}'] [data-element-id='${ELEMENT_IDS.ICON}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Event (Error Minor) Title',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS['TYPE_ERROR-MINOR']}'] [data-element-id='${ELEMENT_IDS.TITLE}'] `,
    variables: [CSS_VARIABLES.FONT_COLOR],
  },
  {
    name: 'Event (Warning)',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.TYPE_WARNING}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Event (Warning) Icon',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.TYPE_WARNING}'] [data-element-id='${ELEMENT_IDS.ICON}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Event (Warning) Title',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.TYPE_WARNING}'] [data-element-id='${ELEMENT_IDS.TITLE}'] `,
    variables: [CSS_VARIABLES.FONT_COLOR],
  },
  {
    name: 'Event (Time)',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.TYPE_TIME}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Event (Time) Icon',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.TYPE_TIME}'] [data-element-id='${ELEMENT_IDS.ICON}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Event (Time) Title',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.TYPE_TIME}'] [data-element-id='${ELEMENT_IDS.TITLE}'] `,
    variables: [CSS_VARIABLES.FONT_COLOR],
  },
  {
    name: 'Event (Highlight)',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.TYPE_HIGHLIGHT}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Event (Highlight) Icon',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.TYPE_HIGHLIGHT}'] [data-element-id='${ELEMENT_IDS.ICON}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Event (Highlight) Title',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_REPORT}'] [data-element-id='${ELEMENT_IDS.TYPE_HIGHLIGHT}'] [data-element-id='${ELEMENT_IDS.TITLE}'] `,
    variables: [CSS_VARIABLES.FONT_COLOR],
  },
];
