import styled from 'styled-components';

interface StyledProps {
  $isHighlighted?: boolean;
}

export const Container = styled.div`
  padding: var(--ntb-space-8);
`;

export const Fixture = styled.div<StyledProps>`
  background-color: ${(props) =>
    props?.$isHighlighted ? 'rgb(171 123 183 / 80%)' : 'transparent'};
  padding: 4px 0;

  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-8);
`;

export const Result = styled.div`
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-bold);
  color: var(--base-colors-whiteAlpha-800);
`;

export const Time = styled.div`
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-medium);
  color: var(--base-colors-whiteAlpha-700);
`;

export const ClubLogo = styled.div`
  color: var(--base-colors-whiteAlpha-800);
`;

export const Stadium = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);

  color: var(--base-colors-whiteAlpha-800);
`;
