import Kit from './Kit';
import ShirtNumber from './ShirtNumber';

const TwoColorCheckeres = ({
  kitColor1 = '#f79025',
  kitColor2 = '#adadad',
  shirtNumber,
  shirtNumberColor,
  shirtNumberBackgroundColor,
  shirtNumberBackgroundOpacity,
  ...restProps
}: {
  size?: any;
  kitColor1: any;
  kitColor2: any;
  kitColor3: any;
  shirtNumber: any;
  shirtNumberColor: any;
  shirtNumberBackgroundColor: any;
  shirtNumberBackgroundOpacity: any;
}) => {
  const shadow = '#000000';

  return (
    <Kit {...restProps}>
      <g>
        <g>
          <g>
            <path
              style={{ fill: kitColor1 }}
              d="M136.51,62.57c-0.87,0.19-8.33,3.37-11.46,6.03c-3.12,2.68-9.03,5.68-9.71,6.04
                        c-0.71,0.35-1.14,0.13-1.82-0.58c-0.7-0.71-7.73-12.01-7.73-12.01c-0.44,0.96-0.72,1.98-0.9,2.96c-0.04,0.25-0.08,0.5-0.11,0.74
                        c-0.17,1.47-0.13,2.85-0.04,3.92c0.17,1.96-0.87,11.8-0.35,14.64c0.03,0.13,0.05,0.32,0.07,0.58c0,0.03,0.01,0.05,0.01,0.09
                        c0,0,0,0,0,0.01c0.23,2.56,0.33,10.1,0.43,17.25c0.01,0.36,0.03,0.72,0.03,1.08c0.09,6.64,0.17,12.7,0.33,13.79
                        c0.17,1.22,0.38,3.01,0.52,4.82l0.04,0.48c0,0.05,0.01,0.11,0.01,0.16c0.07,0.91,0.12,1.81,0.13,2.62
                        c0.03,0.7,0.03,1.33-0.01,1.85c-0.17,2.48,0,5.67,0,7.08c0,1.42-0.17,2.67-1.57,3.2c-0.92,0.35-6.79,1.96-16.91,2.79
                        c-5.06,0.42-11.2,0.64-18.32,0.4c-21.35,0.71-33.84-2.67-35.24-3.19c-1.38-0.54-1.57-1.78-1.57-3.2c0-1.42,0.19-4.61,0-7.08
                        c-0.09-1.3,0.01-3.27,0.16-5.21h0.01c0.15-1.78,0.36-3.54,0.52-4.73c0.16-1.08,0.24-7.1,0.33-13.7
                        c0.11-7.66,0.23-16.11,0.48-18.62c0.01-0.21,0.03-0.38,0.05-0.5c0.51-2.84-0.52-12.68-0.35-14.64c0.08-0.94,0.13-2.13,0.01-3.4
                        c-0.12-1.37-0.43-2.84-1.04-4.22c0,0-7.3,11.53-8,12.23c-0.7,0.72-0.87,0.71-1.57,0.36c-0.7-0.36-6.59-3.36-9.72-6.04
                        c-3.12-2.65-10.58-5.84-11.45-6.03c-0.87-0.16-0.33-2.48,0.17-3.36c0.52-0.88,11.45-20.75,13.02-23.05
                        c1.57-2.32,8.5-17.04,9.72-18.8c1.22-1.78,4.51-4.26,5.91-4.79c1.38-0.54,3.47-1.06,4.86-1.25c0.67-0.08,1.53-0.39,2.44-0.75
                        c0.98-0.39,2.02-0.86,2.93-1.19c1.37-0.51,6.04-1.7,9.44-2.54c1.51-0.37,2.79-0.68,3.4-0.83l2.09-3.19
                        c0.87-0.35,3.29-0.71,4.69-0.54c1.39,0.19,5.91,0.54,8.68,0.54s7.29-0.36,8.68-0.54c1.38-0.17,3.6,0.2,4.69,0.54l0.23,0.33
                        l1.82,2.81L84.6,6c2.26,0.54,11.12,2.67,12.86,3.37c0.95,0.39,2.06,0.88,3.09,1.27c0.86,0.33,1.66,0.59,2.28,0.67
                        c0.9,0.12,2.09,0.39,3.19,0.7c0.6,0.17,1.18,0.36,1.67,0.55c1.39,0.54,4.69,3.01,5.91,4.79c1.22,1.77,8.24,16.3,9.8,18.63
                        c1.57,2.3,12.41,22.34,12.94,23.22C136.85,60.1,137.36,62.41,136.51,62.57z"
            />
          </g>
          <g>
            <path
              style={{ fill: kitColor2 }}
              d="M68.85,121.94v18.51c-0.23,0.01-0.43,0.01-0.64,0.01h-9.75c-2.95-0.08-5.62-0.24-8.06-0.46v-18.07H68.85z"
            />
          </g>
          <g>
            <polygon
              style={{ fill: kitColor2 }}
              points="105.8,121.94 87.48,121.94 87.48,140.63 97.74,139.75 105.8,137.53 106.5,134.75 		"
            />
          </g>
          <g>
            <polygon
              style={{ fill: kitColor2 }}
              points="33.37,103.42 50.3,103.42 50.3,121.85 32.52,121.85 		"
            />
          </g>
          <g>
            <rect
              x="68.94"
              y="103.42"
              style={{ fill: kitColor2 }}
              width="18.44"
              height="18.43"
            />
          </g>
          <g>
            <rect
              x="50.39"
              y="84.88"
              style={{ fill: kitColor2 }}
              width="18.46"
              height="18.44"
            />
          </g>
          <g>
            <polygon
              style={{ fill: kitColor2 }}
              points="104.93,103.32 87.48,103.32 87.48,84.88 104.47,84.88 104.47,84.98 		"
            />
          </g>
          <g>
            <polygon
              style={{ fill: kitColor2 }}
              points="32.9,66.27 50.3,66.27 50.3,84.8 33.85,84.8 		"
            />
          </g>
          <g>
            <rect
              x="68.94"
              y="66.27"
              style={{ fill: kitColor2 }}
              width="18.44"
              height="18.54"
            />
          </g>
          <g>
            <rect
              x="50.39"
              y="47.7"
              style={{ fill: kitColor2 }}
              width="18.46"
              height="18.54"
            />
          </g>
          <g>
            <path
              style={{ fill: kitColor2 }}
              d="M106.01,47.7v14.64c-0.15-0.23-0.24-0.38-0.24-0.38c-0.55,1.22-0.86,2.5-0.99,3.75c0,0,0,0.01,0,0.03
                        c-0.03,0.16-0.04,0.33-0.05,0.5H87.48V47.7H106.01z"
            />
          </g>
          <g>
            <rect
              x="31.77"
              y="29.18"
              style={{ fill: kitColor2 }}
              width="18.54"
              height="18.43"
            />
          </g>
          <g>
            <rect
              x="68.94"
              y="29.18"
              style={{ fill: kitColor2 }}
              width="18.44"
              height="18.43"
            />
          </g>
          <g>
            <path
              style={{ fill: kitColor2 }}
              d="M68.85,18.43v10.66H50.39V10.65h2.95c0.23,2.64,1.06,5.69,2.97,7.54c0.12,0.13,4.07-3.82,4.07-3.82
                        S67.16,17.73,68.85,18.43z"
            />
          </g>
          <g>
            <path
              style={{ fill: kitColor2 }}
              d="M106.01,11.95v17.14H87.48V10.65h13.3c0.76,0.29,1.47,0.52,2.05,0.59
                        C103.72,11.36,104.92,11.62,106.01,11.95z"
            />
          </g>
          <g>
            <path
              style={{ fill: kitColor2 }}
              d="M50.3,6.75v3.82H37.74c1.03-0.42,2.16-0.91,3.12-1.27C42.22,8.77,46.9,7.58,50.3,6.75z"
            />
          </g>
          <path
            style={{ fill: kitColor2 }}
            d="M84.65,10.65c-0.44,2.65-1.55,5.65-3.66,7.37c-0.16,0.13-2.75-3.84-2.75-3.84s-8.2,4.1-9.11,4.37v-1.43
                    c0.04,0.04,0.07,0.05,0.07,0.05c2.18-1.66,4.46-3.22,6.81-4.62c0.67-0.4,1.35-0.78,2.04-1.14c1.14-0.59,2.33-1.24,3.21-2.19
                    c0.82-0.89,1.39-1.98,1.67-3.16c0.2-0.83,0.49-2.17-0.19-2.86c-0.01-0.01-0.01-0.03,0-0.04l1.82,2.81c0,0,0.03,0.04,0.03,0.05
                    C84.92,6.86,84.97,8.65,84.65,10.65z"
          />
          <g>
            <path
              style={{ fill: shadow }}
              d="M83.03,5.56c-0.29,1.96-1.65,4.29-4.19,5.46c-4.03,1.85-9.63,6.13-9.63,6.13s-5.6-4.29-9.64-6.13
                        c-2.52-1.18-3.88-3.48-4.19-5.44c1.21-0.32,4-0.59,5.24-0.43c1.39,0.17,5.83,0.54,8.6,0.54c2.77,0,7.19-0.36,8.58-0.54
                        C79.03,4.99,81.79,5.25,83.03,5.56z"
            />
            <path
              opacity="0.16"
              d="M136.56,62.35c0.86-0.18,0.35-2.48-0.17-3.37c-0.3-0.51-3.93-7.08-7.31-13.12
                        c-6.25,2.94-14.52-0.07-23.84-13.57l0.34,9.94l0.12,19.66c0,0,7.03,11.31,7.73,12.01c0.69,0.71,1.25,0.86,1.95,0.51
                        c0.68-0.36,6.58-3.37,9.71-6.03C128.23,65.72,135.69,62.53,136.56,62.35z"
            />
            <path
              opacity="0.16"
              d="M1.69,62.47c-0.86-0.18-0.34-2.48,0.18-3.37c0.29-0.51,3.92-7.08,7.31-13.12
                        c6.25,2.94,15.6-0.46,24.23-14.08l-0.57,13.53l-0.01,16.45c0,0-7.58,11.94-8.27,12.64c-0.7,0.71-1,0.35-1.69,0.01
                        c-0.69-0.36-6.59-3.37-9.72-6.03C10.03,65.84,2.56,62.65,1.69,62.47z"
            />
            <path
              opacity="0.16"
              d="M104.46,137.75c1.38-0.53,1.56-1.77,1.56-3.19c0-1.42-0.18-4.61,0-7.09c0.16-2.3-0.27-6.7-0.6-9.32
                        c-5.17,4.71-8.97,14.72-10.63,21.6C100.45,138.94,103.78,138.01,104.46,137.75z"
            />
            <path
              opacity="0.16"
              d="M33.73,137.69c-1.38-0.53-1.56-1.77-1.56-3.19c0-1.42,0.18-4.61,0-7.09c-0.15-2.3,0.27-6.7,0.61-9.32
                        c5.16,4.71,8.96,14.72,10.63,21.6C37.73,138.88,34.41,137.95,33.73,137.69z"
            />
            <g>
              <path
                d="M59.64,2.23c0.3,0,0.58,0.01,0.83,0.05c1.39,0.19,5.91,0.54,8.68,0.54s7.29-0.36,8.68-0.54
                            c0.24-0.03,0.51-0.04,0.79-0.04c1.33,0,3,0.3,3.9,0.58l0.23,0.33l1.82,2.81L84.6,6c2.26,0.54,11.12,2.67,12.86,3.38
                            c0.95,0.39,2.06,0.88,3.09,1.27c0.86,0.33,1.66,0.59,2.28,0.67c0.9,0.12,2.09,0.39,3.19,0.7c0.6,0.17,1.18,0.36,1.67,0.55
                            c1.39,0.54,4.69,3.01,5.91,4.79c1.22,1.77,8.24,16.3,9.8,18.63c1.57,2.3,12.41,22.34,12.94,23.22s1.03,3.2,0.17,3.36
                            c-0.87,0.19-8.33,3.37-11.46,6.03c-3.12,2.68-9.03,5.68-9.71,6.04c-0.22,0.11-0.42,0.16-0.6,0.16c-0.4,0-0.75-0.25-1.22-0.74
                            c-0.7-0.71-7.73-12.01-7.73-12.01c-0.44,0.96-0.72,1.98-0.9,2.96c-0.04,0.25-0.08,0.5-0.11,0.74c-0.17,1.47-0.13,2.85-0.04,3.92
                            c0.17,1.96-0.87,11.8-0.35,14.64c0.03,0.13,0.05,0.32,0.07,0.58c0,0.03,0.01,0.05,0.01,0.09c0,0,0,0,0,0.01
                            c0.23,2.56,0.33,10.1,0.43,17.25c0.01,0.36,0.03,0.72,0.03,1.08c0.09,6.64,0.17,12.7,0.33,13.79c0.17,1.22,0.38,3.01,0.52,4.82
                            l0.04,0.48c0,0.05,0.01,0.11,0.01,0.16c0.07,0.91,0.12,1.81,0.13,2.62c0.03,0.7,0.03,1.33-0.01,1.85c-0.17,2.48,0,5.67,0,7.08
                            c0,1.42-0.17,2.67-1.57,3.2c-0.92,0.35-6.79,1.96-16.91,2.79c-3.64,0.3-7.84,0.5-12.56,0.5c-1.84,0-3.76-0.03-5.77-0.1
                            c-2.02,0.07-3.96,0.1-5.81,0.1c-17.8,0-28.16-2.81-29.43-3.29c-1.38-0.54-1.57-1.78-1.57-3.2c0-1.42,0.19-4.61,0-7.08
                            c-0.09-1.3,0.01-3.27,0.16-5.21h0.01c0.15-1.78,0.36-3.54,0.52-4.73c0.16-1.08,0.24-7.1,0.33-13.7
                            c0.11-7.66,0.23-16.11,0.48-18.62c0.01-0.21,0.03-0.38,0.05-0.5c0.51-2.84-0.52-12.68-0.35-14.64c0.08-0.94,0.13-2.13,0.01-3.4
                            c-0.12-1.37-0.43-2.84-1.04-4.22c0,0-7.3,11.53-8,12.23c-0.41,0.42-0.64,0.59-0.89,0.59c-0.18,0-0.38-0.09-0.67-0.23
                            c-0.7-0.36-6.59-3.36-9.72-6.04c-3.12-2.65-10.58-5.84-11.45-6.03c-0.87-0.16-0.33-2.48,0.17-3.36
                            c0.52-0.88,11.45-20.75,13.02-23.05c1.57-2.32,8.5-17.04,9.72-18.8c1.22-1.78,4.51-4.26,5.91-4.79c1.38-0.54,3.47-1.06,4.86-1.25
                            c0.67-0.08,1.53-0.39,2.44-0.75c0.98-0.39,2.02-0.86,2.93-1.19c1.37-0.51,6.04-1.7,9.44-2.54c1.51-0.37,2.79-0.68,3.4-0.83
                            l2.09-3.19C56.5,2.53,58.26,2.23,59.64,2.23 M59.64,1.23c-1.43,0-3.33,0.29-4.23,0.65c-0.19,0.08-0.35,0.21-0.47,0.38l-1.87,2.86
                            c-0.69,0.17-1.77,0.43-3.02,0.74c-3.59,0.89-8.16,2.06-9.55,2.58c-0.56,0.21-1.18,0.46-1.77,0.71c-0.4,0.17-0.79,0.33-1.18,0.49
                            c-0.76,0.3-1.61,0.62-2.19,0.69c-1.55,0.21-3.69,0.76-5.1,1.31c-1.64,0.63-5.1,3.3-6.37,5.16c-0.62,0.9-2.41,4.51-4.49,8.69
                            c-2.09,4.2-4.45,8.96-5.24,10.12c-1.2,1.77-7.6,13.3-11.04,19.5c-1.05,1.9-1.88,3.4-2.01,3.6c-0.46,0.79-1.11,2.83-0.57,3.99
                            c0.22,0.47,0.6,0.77,1.08,0.86c0.86,0.2,8.04,3.3,10.98,5.81c2.69,2.3,7.3,4.82,9.81,6.11l0.1,0.05
                            c0.37,0.18,0.72,0.34,1.13,0.34c0.69,0,1.16-0.43,1.61-0.9c0.59-0.59,4.67-6.96,7-10.62c0.15,0.63,0.26,1.31,0.32,2.01
                            c0.09,0.98,0.09,2.04-0.01,3.23c-0.08,0.84,0.04,2.79,0.19,5.25c0.2,3.43,0.46,7.7,0.17,9.3c-0.03,0.14-0.05,0.34-0.07,0.61
                            c-0.26,2.53-0.38,11.1-0.48,18.66l-0.01,0.64c-0.08,5.87-0.17,11.94-0.31,12.92c-0.15,1.14-0.36,2.84-0.52,4.63
                            c-0.01,0.05-0.02,0.11-0.03,0.17c-0.19,2.56-0.24,4.21-0.16,5.36c0.13,1.73,0.07,3.87,0.03,5.43c-0.02,0.63-0.03,1.18-0.03,1.59
                            c0,1.06,0,3.28,2.21,4.13c1.9,0.71,12.48,3.35,29.79,3.35c1.91,0,3.86-0.03,5.81-0.1c1.93,0.06,3.87,0.1,5.77,0.1
                            c4.31,0,8.56-0.17,12.64-0.5c10.22-0.84,16.16-2.46,17.19-2.85c2.21-0.85,2.21-3.07,2.21-4.14c0-0.41-0.01-0.95-0.03-1.59
                            c-0.04-1.56-0.09-3.7,0.03-5.43c0.04-0.52,0.05-1.17,0.02-1.96c-0.01-0.7-0.05-1.54-0.13-2.64c0-0.05-0.01-0.1-0.01-0.15
                            c0-0.01,0-0.03,0-0.04l-0.04-0.48c-0.14-1.71-0.34-3.54-0.53-4.88c-0.15-1-0.23-7.11-0.32-13.02l-0.01-0.65
                            c0-0.36-0.01-0.73-0.03-1.11c-0.1-7.94-0.21-14.74-0.43-17.27c0-0.07-0.01-0.12-0.02-0.16c-0.01-0.24-0.04-0.46-0.08-0.66
                            c-0.29-1.59-0.03-5.87,0.17-9.32c0.15-2.44,0.26-4.37,0.19-5.22c-0.11-1.3-0.1-2.55,0.04-3.72c0.02-0.2,0.05-0.39,0.09-0.6
                            l0.02-0.1c0.05-0.28,0.11-0.54,0.17-0.81c2.25,3.6,6.15,9.8,6.75,10.41c0.52,0.54,1.11,1.04,1.93,1.04
                            c0.34,0,0.68-0.09,1.05-0.27l0.23-0.12c5.53-2.88,8.32-4.89,9.69-6.06c2.95-2.5,10.13-5.6,11.03-5.81
                            c0.45-0.08,0.83-0.39,1.05-0.87c0.51-1.13-0.09-3.16-0.57-3.98c-0.13-0.22-1.03-1.87-2.18-3.96c-4.65-8.5-9.7-17.71-10.8-19.32
                            c-0.78-1.16-3.15-5.84-5.25-9.97c-2.11-4.17-3.94-7.76-4.56-8.66c-1.27-1.86-4.72-4.53-6.37-5.16c-0.52-0.2-1.11-0.39-1.76-0.58
                            c-1.22-0.34-2.43-0.61-3.33-0.73c-0.33-0.04-0.96-0.18-2.05-0.61c-0.66-0.25-1.35-0.54-2.02-0.82c-0.37-0.15-0.72-0.3-1.06-0.44
                            c-1.81-0.74-10.45-2.81-12.61-3.33l-1.63-2.52l-0.24-0.35c-0.13-0.19-0.32-0.33-0.53-0.39c-1.01-0.31-2.77-0.62-4.19-0.62
                            c-0.33,0-0.64,0.02-0.92,0.05c-1.45,0.18-5.91,0.53-8.55,0.53c-2.72,0-7.17-0.34-8.55-0.53C60.32,1.25,60,1.23,59.64,1.23
                            L59.64,1.23z"
              />
            </g>
          </g>
        </g>
      </g>
      <ShirtNumber
        shirtNumber={shirtNumber}
        shirtNumberColor={shirtNumberColor}
        shirtNumberBackgroundColor={shirtNumberBackgroundColor}
        shirtNumberBackgroundOpacity={shirtNumberBackgroundOpacity}
      />
    </Kit>
  );
};

export default TwoColorCheckeres;
