import { useMutation } from '@tanstack/react-query';

import { postNrkVideo } from './post';
import { deleteNrkVideo } from './delete';

interface UseMutationNrkVideo {
  params: {
    apiBaseUrl: string;
    token: string;
  };
  options?: Record<string, unknown>;
}

export const useMutationPostNrkVideo = ({
  params,
  options,
}: UseMutationNrkVideo) => {
  const mutation = useMutation({
    mutationKey: ['postNrkVideo'],
    mutationFn: (data: any) => {
      return postNrkVideo({
        apiBaseUrl: params.apiBaseUrl,
        token: params.token,
        data: JSON.stringify(data),
      });
    },
    ...options,
  });

  return mutation;
};

export const useMutationDeleteNrkVideo = ({
  params,
  options,
}: UseMutationNrkVideo) => {
  const mutation = useMutation({
    mutationKey: ['deleteNrkVideo'],
    mutationFn: (videoId: number) => {
      return deleteNrkVideo({
        apiBaseUrl: params.apiBaseUrl,
        token: params.token,
        videoId,
      });
    },
    ...options,
  });

  return mutation;
};
