import {
  CardIcon,
  CornerIcon,
  FullTimeIcon,
  GoalHandballIcon,
  GoalIcehockeyIcon,
  GoalIcon,
  HalfTimeIcon,
  ImportantIcon,
  OffsideIcon,
  OvertimeIcon,
  PenaltyHandballIcon,
  PenaltyIcon,
  PenaltyMissHandballIcon,
  PenaltyMissIcehockeyIcon,
  PenaltyMissIcon,
  SecondCardIcon,
  SubstituteIcon,
  TwoMinuteSuspensionIcon,
  VarIcon,
  WhistleIcon,
} from '../../../icons';

import CornerEvent from './EventTypes/CornerEvent';
import DefaultEvent from './EventTypes/DefaultEvent';
import FreeKickEvent from './EventTypes/FreeKickEvent';
import GoalEvent from './EventTypes/GoalEvent';
import HighlightedEvent from './EventTypes/HighlightedEvent';
import { MATCH_EVENTS } from '@ntb-sport/constants';
import OffsideEvent from './EventTypes/OffsideEvent';
import PenaltyEvent from './EventTypes/PenaltyEvent';
import PenaltyMissEvent from './EventTypes/PenaltyMissEvent';
import PenaltyShootoutEvent from './EventTypes/PenaltyShootoutEvent';
import RedCardEvent from './EventTypes/RedCardEvent';
import SecondYellowCardEvent from './EventTypes/SecondYellowCardEvent';
import SubstitutionEvent from './EventTypes/SubstitutionEvent';
import TwoMinutesSuspensionEvent from './EventTypes/TwoMinutesSuspensionEvent';
import YellowCardEvent from './EventTypes/YellowCardEvent';

const getFootballEvents = ({ event }: { event: any }) => ({
  NotStarted: {
    type: 'default',
    text: 'Kampen har ikke startet',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.COMMENT]: {
    icon: event.important && <ImportantIcon />,
    type: event.important ? 'highlight' : 'default',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.VAR]: {
    icon: <VarIcon />,
    type: event.important ? 'highlight' : 'default',
    text: `VAR. ${event.comment}`,
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.OFFSIDE]: {
    icon: <OffsideIcon />,
    type: event.important ? 'highlight' : 'default',
    connectedText: '',

    component: OffsideEvent,
  },
  [MATCH_EVENTS.FREE_KICK]: {
    icon: <WhistleIcon />,
    type: event.important ? 'highlight' : 'default',
    connectedText: '',

    component: FreeKickEvent,
  },
  [MATCH_EVENTS.ADDED_TIME]: {
    icon: <OvertimeIcon />,
    type: event.important ? 'highlight' : 'default',
    connectedText: '',
    text: `${event.comment} minutter lagt til.`,

    component: DefaultEvent,
  },
  [MATCH_EVENTS.LINEUP_READY]: {
    // icon: <AccountGroup />,
    type: 'highlight',
    text: 'Lagoppstilling klar.',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.MATCH_KICK_OFF]: {
    icon: <FullTimeIcon />,
    type: 'time',
    text: `Avspark.`,
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.HALF_TIME]: {
    icon: <HalfTimeIcon />,
    type: 'time',
    text: `Pause (${event.score}).`,
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.START_SECOND_HALF]: {
    icon: <FullTimeIcon />,
    type: 'time',
    text: `Start andre omgang.`,
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.MATCH_ENDS]: {
    icon: <FullTimeIcon />,
    type: 'time',
    text: `Slutt (${event.score}).`,
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.SECOND_HALF_ENDS_EXTRA_TIME]: {
    icon: <FullTimeIcon />,
    type: 'time',
    text: `Slutt ordinær tid (${event.score}).`,
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.START_FIRST_HALF_EXTRA_TIME]: {
    icon: <FullTimeIcon />,
    type: 'time',
    text: 'Start første ekstraomgang.',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.HALF_TIME_EXTRA_TIME]: {
    icon: <FullTimeIcon />,
    type: 'time',
    text: `Pause ekstraomganger (${event.score}).`,
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.START_SECOND_HALF_EXTRA_TIME]: {
    icon: <FullTimeIcon />,
    type: 'time',
    text: 'Start andre ekstraomgang.',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.END_SECOND_HALF_EXTRA_TIME]: {
    icon: <FullTimeIcon />,
    type: 'time',
    text: `Slutt andre ekstraomgang (${event.score}).`,
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.TV2_VIDEO]: {
    icon: <ImportantIcon />,
    type: 'highlight',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.CORNER]: {
    icon: <CornerIcon />,
    type: event.important ? 'highlight' : 'default',
    connectedText: '',

    component: CornerEvent,
  },
  [MATCH_EVENTS.YELLOW_CARD]: {
    icon: <CardIcon cardId="yellow" fillColor="var(--base-colors-warning)" />,
    type: 'warning',
    connectedText: '',
    text: 'Gult kort',

    component: YellowCardEvent,
  },
  [MATCH_EVENTS.SECOND_YELLOW_CARD]: {
    icon: (
      <SecondCardIcon
        cardId="second-card"
        fillColor1="var(--base-colors-warning)"
        fillColor2="var(--base-colors-error)"
      />
    ),
    text: 'Rødt kort (to gule)',
    connectedText: '',
    type: 'error',

    component: SecondYellowCardEvent,
  },
  [MATCH_EVENTS.RED_CARD]: {
    icon: <CardIcon cardId="red" />,
    type: 'error',
    text: 'Rødt kort',
    connectedText: '',

    component: RedCardEvent,
  },
  [MATCH_EVENTS.PLAYER_SUBSTITUTED]: {
    icon: (
      <SubstituteIcon
        fillColor1="var(--base-colors-success)"
        fillColor2="var(--base-colors-error)"
      />
    ),
    type: 'default',
    connectedText: '',

    component: SubstitutionEvent,
  },
  [MATCH_EVENTS.GOAL]: {
    icon: <GoalIcon />,
    type: 'success-minor',
    text: 'Mål',
    connectedText: '',

    component: GoalEvent,
  },
  [MATCH_EVENTS.PENALTY_SHOOTOUT]: {
    icon: <FullTimeIcon />,
    type: 'time',
    text: 'Straffesparkkonkurranse',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.GOAL_PENALTY_SHOOTOUT]: {
    icon: <PenaltyIcon />,
    type: 'success-minor',
    text: 'Mål straffekonkurranse',
    connectedText: '',

    component: PenaltyShootoutEvent,
  },
  [MATCH_EVENTS.MISS_PENALTY_SHOOTOUT]: {
    icon: <PenaltyMissIcon />,
    type: 'error-minor',
    text: 'Bom straffekonkurranse',
    connectedText: '',

    component: PenaltyMissEvent,
  },
  [MATCH_EVENTS.OWN_GOAL]: {
    icon: <GoalIcon />,
    type: 'success-minor',
    text: 'Mål',
    connectedText: 'Selvmål av',

    component: GoalEvent,
  },
  [MATCH_EVENTS.PENALTY]: {
    icon: <PenaltyIcon />,
    type: 'highlight',
    connectedText: '',

    component: PenaltyEvent,
  },
  [MATCH_EVENTS.PENALTY_MISS]: {
    icon: <PenaltyMissIcon />,
    type: 'error-minor',
    text: 'Straffebom',
    connectedText: '',

    component: PenaltyMissEvent,
  },
  [MATCH_EVENTS.SECOND_HALF_TIME]: {
    icon: <FullTimeIcon />,
    type: 'time',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.DISALLOWED_GOAL]: {
    icon: <PenaltyMissIcon />,
    type: 'error-minor',
    text: 'Annullert mål',
    connectedText: '',

    component: HighlightedEvent,
  },
});

const getHandballEvents = ({ event }: { event: any }) => ({
  NotStarted: {
    type: 'default',
    text: 'Kampen har ikke startet',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.COMMENT]: {
    icon: event.important && <ImportantIcon />,
    type: event.important ? 'highlight' : 'default',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.LINEUP_READY]: {
    // icon: <AccountGroup />,
    type: 'highlight',
    text: 'Lagoppstilling klar.',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.MATCH_KICK_OFF]: {
    icon: <WhistleIcon />,
    type: 'time',
    text: `Start første omgang.`,
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.HALF_TIME]: {
    icon: <HalfTimeIcon />,
    type: 'time',
    text: `Pause (${event.score}).`,
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.START_SECOND_HALF]: {
    icon: <WhistleIcon />,
    type: 'time',
    text: `Start andre omgang.`,
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.MATCH_ENDS]: {
    icon: <FullTimeIcon />,
    type: 'time',
    text: `Slutt (${event.score}).`,
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.SECOND_HALF_ENDS_EXTRA_TIME]: {
    icon: <WhistleIcon />,
    type: 'time',
    text: 'Slutt ordinær tid.',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.START_FIRST_HALF_EXTRA_TIME]: {
    icon: <WhistleIcon />,
    type: 'time',
    text: 'Start første ekstraomgang.',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.HALF_TIME_EXTRA_TIME]: {
    icon: <WhistleIcon />,
    type: 'time',
    text: 'Pause ekstraomganger.',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.START_SECOND_HALF_EXTRA_TIME]: {
    icon: <WhistleIcon />,
    type: 'time',
    text: 'Start andre ekstraomgang.',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.END_SECOND_HALF_EXTRA_TIME]: {
    icon: <WhistleIcon />,
    type: 'time',
    text: 'Pause.',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.START_THIRD_HALF_EXTRA_TIME]: {
    icon: <WhistleIcon />,
    type: 'time',
    text: 'Start tredje ekstraomgang.',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.START_FOURTH_HALF_EXTRA_TIME]: {
    icon: <WhistleIcon />,
    type: 'time',
    text: 'Start fjerde ekstraomgang.',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.TV2_VIDEO]: {
    icon: <ImportantIcon />,
    type: 'highlight',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.CORNER]: {
    icon: <CornerIcon />,
    type: 'default',
    connectedText: '',

    component: CornerEvent,
  },
  [MATCH_EVENTS.YELLOW_CARD]: {
    icon: <CardIcon cardId="yellow" />,
    type: 'warning',
    connectedText: '',

    component: YellowCardEvent,
  },
  [MATCH_EVENTS.SECOND_YELLOW_CARD]: {
    icon: (
      <SecondCardIcon
        cardId="second-card"
        fillColor1="var(--base-colors-warning)"
        fillColor2="var(--base-colors-error)"
      />
    ),
    type: 'error',
    connectedText: '',

    component: SecondYellowCardEvent,
  },
  [MATCH_EVENTS.FIVE_MINUTES_SUSPENSION]: {
    icon: null,
    type: 'error',
    text: '5 minute suspension',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.TEN_MINUTES_SUSPENSION]: {
    icon: null,
    type: 'error',
    text: '10 minute suspension',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.MATCH_PENALTY]: {
    icon: null,
    text: 'Match Penalty',
    type: 'error',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.RED_CARD]: {
    icon: <CardIcon cardId="red" />,
    type: 'error',
    connectedText: '',

    component: RedCardEvent,
  },
  [MATCH_EVENTS.PLAYER_SUBSTITUTED]: {
    icon: (
      <SubstituteIcon
        fillColor1="var(--base-colors-error)"
        fillColor2="var(--base-colors-success)"
      />
    ),
    type: 'default',
    connectedText: '',

    component: SubstitutionEvent,
  },
  [MATCH_EVENTS.GOAL]: {
    icon: <GoalHandballIcon />,
    type: 'success',
    text: 'Mål',
    connectedText: '',

    component: GoalEvent,
  },
  [MATCH_EVENTS.PENALTY_SHOOTOUT]: {
    icon: <WhistleIcon />,
    type: 'time',
    text: 'Straffesparkkonkurranse',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.GOAL_PENALTY_SHOOTOUT]: {
    icon: <PenaltyHandballIcon />,
    type: 'success-minor',
    text: 'Mål straffekonkurranse',
    connectedText: '',

    component: PenaltyShootoutEvent,
  },
  [MATCH_EVENTS.MISS_PENALTY_SHOOTOUT]: {
    icon: <PenaltyMissHandballIcon />,
    type: 'error',
    text: 'Bom straffekonkurranse',
    connectedText: '',

    component: PenaltyShootoutEvent,
  },
  [MATCH_EVENTS.OWN_GOAL]: {
    icon: <GoalHandballIcon />,
    type: 'success',
    text: 'Mål',
    connectedText: 'Selvmål av',

    component: GoalEvent,
  },
  [MATCH_EVENTS.PENALTY]: {
    icon: <PenaltyHandballIcon />,
    type: 'success-minor',
    text: 'Straffe',
    connectedText: '',

    component: HighlightedEvent,
  },
  [MATCH_EVENTS.PENALTY_MISS]: {
    icon: <PenaltyMissHandballIcon />,
    type: 'error-minor',
    text: 'Straffebom',
    connectedText: '',

    component: HighlightedEvent,
  },
  [MATCH_EVENTS.SECOND_HALF_TIME]: {
    icon: <WhistleIcon />,
    type: 'time',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.DISALLOWED_GOAL]: {
    icon: <PenaltyMissHandballIcon />,
    type: 'error-minor',
    text: 'Annullert mål',
    connectedText: '',

    component: HighlightedEvent,
  },
  [MATCH_EVENTS.TWO_MINUTES_SUSPENSION]: {
    icon: <TwoMinuteSuspensionIcon />,
    type: 'error-minor',
    text: 'To minutter',
    connectedText: '',

    component: TwoMinutesSuspensionEvent,
  },
});

const getIceHockeyEvents = ({ event }: { event: any }) => ({
  [MATCH_EVENTS.COMMENT]: {
    type: 'default',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.MATCH_KICK_OFF]: {
    icon: <WhistleIcon />,
    type: 'time',
    text: 'Start første periode.',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.GOAL]: {
    icon: <GoalIcehockeyIcon />,
    type: 'success',
    text: 'Mål',
    connectedText: '',

    component: GoalEvent,
  },
  [MATCH_EVENTS.TWO_MINUTES_SUSPENSION]: {
    icon: <TwoMinuteSuspensionIcon />,
    type: 'error-minor',
    text: 'To minutter',
    connectedText: '',

    component: TwoMinutesSuspensionEvent,
  },
  [MATCH_EVENTS.HALF_TIME]: {
    icon: <HalfTimeIcon />,
    type: 'time',
    text: `Pause (${event.score}).`,
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.START_SECOND_HALF]: {
    icon: <WhistleIcon />,
    type: 'time',
    text: 'Start andre periode.',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.SECOND_HALF_TIME]: {
    icon: <HalfTimeIcon />,
    type: 'time',
    text: `Pause (${event.score}).`,
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.START_THIRD_HALF]: {
    icon: <WhistleIcon />,
    type: 'time',
    text: 'Start tredje periode.',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.MATCH_ENDS]: {
    icon: <WhistleIcon />,
    type: 'time',
    text: `Slutt (${event.score}).`,
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.PENALTY]: {
    icon: <PenaltyIcon />,
    type: 'success-minor',
    text: 'Straffe',
    connectedText: '',

    component: HighlightedEvent,
  },
  [MATCH_EVENTS.PENALTY_MISS]: {
    icon: <PenaltyMissIcehockeyIcon />,
    type: 'error-minor',
    text: 'Straffebom',
    connectedText: '',

    component: HighlightedEvent,
  },
  [MATCH_EVENTS.RED_CARD]: {
    icon: <CardIcon cardId="red" />,
    type: 'error',
    connectedText: '',

    component: RedCardEvent,
  },
  [MATCH_EVENTS.SECOND_HALF_ENDS_EXTRA_TIME]: {
    icon: <FullTimeIcon />,
    type: 'time',
    text: 'Slutt ordinær tid.',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.START_FIRST_HALF_EXTRA_TIME]: {
    icon: <WhistleIcon />,
    type: 'time',
    text: 'Start første overtidsperiode. ',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.FIVE_MINUTES_SUSPENSION]: {
    icon: <ImportantIcon />,
    type: 'error-minor',
    text: 'Fem minutter ',
    connectedText: '',

    component: TwoMinutesSuspensionEvent,
  },
  [MATCH_EVENTS.TEN_MINUTES_SUSPENSION]: {
    icon: <ImportantIcon />,
    type: 'error-minor',
    text: 'Ti minutter ',
    connectedText: '',

    component: TwoMinutesSuspensionEvent,
  },
  [MATCH_EVENTS.PENALTY_SHOOTOUT]: {
    icon: <WhistleIcon />,
    type: 'time',
    text: 'Straffeslagkonkurranse. ',
    connectedText: '',

    component: DefaultEvent,
  },
  [MATCH_EVENTS.GOAL_PENALTY_SHOOTOUT]: {
    icon: <GoalIcehockeyIcon />,
    type: 'success',
    text: 'Mål straffekonkurranse',
    connectedText: '',

    component: GoalEvent,
  },
  [MATCH_EVENTS.MISS_PENALTY_SHOOTOUT]: {
    icon: <PenaltyMissIcehockeyIcon />,
    type: 'error-minor',
    text: 'Bom straffekonkurranse',
    connectedText: '',

    component: HighlightedEvent,
  },
  [MATCH_EVENTS.MATCH_PENALTY]: {
    icon: <TwoMinuteSuspensionIcon />,
    type: 'error-minor',
    text: 'Matchstraff',
    connectedText: '',

    component: HighlightedEvent,
  },
  [MATCH_EVENTS.HALF_TIME_EXTRA_TIME]: {
    icon: <WhistleIcon />,
    type: 'time',
    text: 'Pause',
    connectedText: '',

    component: DefaultEvent,
  },
});

export const getMatchEvent = ({ event }: { event: any }) => {
  const events =
    event.sportId === 1
      ? getFootballEvents({ event })
      : event.sportId === 2
        ? getIceHockeyEvents({ event })
        : getHandballEvents({ event });

  return events[event.matchEventTypeId];
};

export default getMatchEvent;
