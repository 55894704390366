import { Icon } from '../../chakra';

export const IndirectAssistIcon = (props: any) => (
  <Icon>
    <style type="text/css">
      {`
      .indirectAssistIcon-st0{
        fill:none;
        stroke:#fff;
        stroke-width:0;
        stroke-linecap:round;
        stroke-linejoin:round;
      }
	  .indirectAssistIcon-st1{fill:none;}
    `}
    </style>
    <g>
      <path
        d="M11.2,1.9H9.3c-0.2,0-0.4,0.2-0.4,0.4v11.1c0,0.2,0.2,0.4,0.4,0.4h1.8c0.2,0,0.4-0.2,0.4-0.4V2.3
		C11.6,2.1,11.4,1.9,11.2,1.9z"
      />
      <g>
        <path
          className="indirectAssistIcon-st1"
          d="M3.4,14c-1.2-1.3-1.4-3-1.5-3.8c-0.2,0-0.4,0-0.5-0.1c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.3-0.1,0.4
			c-0.1,0.5,0,2.1,0.3,3.6c0.3,2,0.8,2.6,1.2,3c0.2,0,0.5-0.1,0.7-0.1C3.1,16.6,3,15.3,3.4,14z"
        />
        <path
          className="indirectAssistIcon-st1"
          d="M3.6,18.2c-0.2,0-0.3,0.1-0.5,0.1c0.3,0.6,1,1.6,2.3,2.3c0.5,0.3,1,0.6,1.4,0.8c0.5,0.3,1,0.5,1.5,0.7
			c-0.5-0.6-0.8-1.4-1-1.8C6.6,20.2,4.9,19.8,3.6,18.2z"
        />
        <path
          className="indirectAssistIcon-st1"
          d="M4.1,5.6C4,5.3,4,4.9,4,4.5C3.9,4.7,3.7,4.8,3.5,5.1C3.4,5.2,3.3,5.3,3.3,5.4C2.5,6.3,1.5,8,1.4,9
			c0.1,0,0.3,0.1,0.5,0.2C2.3,7.2,3.6,6.1,4.1,5.6z"
        />
        <path
          className="indirectAssistIcon-st1"
          d="M14.5,18.4c-0.5,0.1-1.4,0.2-2.4,0.2c-0.5,0-1.3,0-2-0.2c-0.3,0.4-1.2,1.3-1.8,1.7c0.1,0.2,0.2,0.5,0.3,0.8
			c0.2,0.5,0.6,1,1.1,1.1c0.4,0.1,3.1,0.5,4.7,0c0,0,1.2-0.2,1.8-1.7C15.8,20.1,15.2,19.6,14.5,18.4z"
        />
        <path
          className="indirectAssistIcon-st1"
          d="M17.1,20.7c-0.2,0.4-0.4,0.7-0.7,1.1c0.2-0.1,0.4-0.2,0.6-0.3c0.5-0.3,1.2-0.6,1.9-1.2
			c-0.2,0.1-0.4,0.2-0.5,0.2C17.9,20.6,17.4,20.7,17.1,20.7z"
        />
        <path
          d="M21.1,17.7c0.1-0.4,0.3-1.1,0.3-1.8h-0.5c-0.9,0-1.9-0.6-2.2-1.7l-0.2-0.6c-0.3,0-0.6,0-0.8,0c0,0,0,0,0-0.1l-0.2,0.6
			c-0.2,0.8-0.8,1.4-1.5,1.6c-0.2,0.3-0.4,0.7-0.6,1c-0.2,0.2-0.4,0.7-0.7,0.8c-0.5,0.1-2.6,0.5-4.4,0C9.8,17,9.4,16.4,9,15.8
			c-1.2-0.2-2.1-1.2-2.1-2.4v-0.1c-1.2,0-2.4-0.1-2.9-0.1c-1.2-1.2-1.3-3.3-1.3-3.6c0.4-2.2,2.1-3.3,2.3-3.4
			c0.7-0.3,1.3-0.4,1.8-0.3v-1c-0.5,0-1.1,0-1.7,0.3c-0.1-0.5,0-1.2,0-1.3c0.1-0.4,0.4-0.7,0.6-1.1c0.3-0.3,0.7-0.6,1.1-0.7
			C7,1.8,7,1.5,7.2,1.2C3.1,3,0.2,7,0.2,11.8c0,6.6,5.3,11.7,11.7,11.7c5.1,0,9.4-3.2,11-7.7h-0.8C21.8,16.7,21.4,17.3,21.1,17.7z
			 M3.3,5.4c0-0.1,0.1-0.2,0.2-0.3C3.7,4.8,3.9,4.7,4,4.5c0,0.3,0,0.7,0.1,1.1C3.6,6.1,2.3,7.2,1.9,9.2C1.7,9.1,1.5,9,1.4,9
			C1.5,8,2.5,6.3,3.3,5.4z M1.4,14.5C1.1,13,1,11.4,1.1,10.9c0-0.1,0-0.3,0.1-0.4c0.1-0.2,0.1-0.3,0.2-0.4c0.1,0.1,0.3,0.1,0.5,0.1
			C2,11,2.2,12.7,3.4,14c-0.4,1.4-0.3,2.6-0.1,3.4c-0.2,0-0.5,0.1-0.7,0.1C2.2,17.1,1.7,16.5,1.4,14.5z M6.8,21.5
			c-0.4-0.2-0.8-0.5-1.4-0.8c-1.4-0.7-2-1.7-2.3-2.3c0.2,0,0.3-0.1,0.5-0.1c1.3,1.6,3,2,3.7,2.2c0.1,0.4,0.4,1.2,1,1.8
			C7.7,22,7.3,21.8,6.8,21.5z M14.3,22c-1.6,0.5-4.2,0.1-4.7,0c-0.4-0.1-0.8-0.5-1.1-1.1c-0.1-0.3-0.2-0.6-0.3-0.8
			c0.6-0.4,1.5-1.3,1.8-1.7c0.7,0.2,1.5,0.2,2,0.2c1.1,0,1.9-0.1,2.4-0.2c0.7,1.2,1.3,1.7,1.6,1.9C15.4,21.8,14.3,22,14.3,22z
			 M17,21.5c-0.2,0.1-0.4,0.2-0.6,0.3c0.3-0.3,0.5-0.6,0.7-1.1c0.3,0,0.7-0.1,1.3-0.2c0.1,0,0.3-0.1,0.5-0.2
			C18.2,20.8,17.6,21.1,17,21.5z"
        />
      </g>
      <path
        d="M20,2.1c0-0.1-0.2-0.2-0.3-0.2h-3.2c-0.1,0-0.2,0.1-0.3,0.2l-3.5,11.4c-0.1,0.2,0.1,0.3,0.3,0.3h2.2c0.1,0,0.3-0.1,0.3-0.2
		l0.7-2.6c0-0.1,0.2-0.2,0.3-0.2h3.1c0.1,0,0.3,0.1,0.3,0.2l0.9,2.6c0,0.1,0.2,0.2,0.3,0.2h2.4c0.2,0,0.3-0.2,0.3-0.3L20,2.1z
		 M19,8.8h-2c-0.2,0-0.3-0.2-0.3-0.3L18,3.9c0,0,0,0,0,0s0,0,0,0l1.3,4.5C19.3,8.6,19.2,8.8,19,8.8z"
      />
    </g>
  </Icon>
);

export default IndirectAssistIcon;
