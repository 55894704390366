import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useContext, useEffect } from 'react';
import { NifsCustomerContext } from '@ntb-sport/customer';
import { CUSTOMERS, CUSTOMER_IDS } from 'libs/customer/src/lib/Customers';

const CORE_ANALYTICS = {
  '/video': 'ntbsport:videoSide',
  '/video/[videoId]': 'ntbsport:videoSide',
  '/[sportGroup]/[sportId]/[date]': 'ntbsport:kalender',
  '/[sportGroup]/[sportId]/[date]/[matchId]': 'ntbsport:Kampreferat',
  '/[sportGroup]/[sportId]/[date]/[matchId]/lagoppstilling':
    'ntbsport:Lagoppstilling',
  '/[sportGroup]/[sportId]/[date]/[matchId]/statistikk':
    'ntbsport:Kampstatistikk',
  '/[sportGroup]/[sportId]/[date]/[matchId]/tabell': 'ntbsport:Tabell',
  '/[sportGroup]/[sportId]/[date]/[matchId]/topplister':
    'ntbsport:Spillerstatistikk',
  '/[sportGroup]/[sportId]/[date]/turneringer': 'ntbsport:Turneringer',
  '/[sportGroup]/[sportId]/[date]/turneringer/[tournamentId]/[seasonId]/[stageId]/kamper':
    'ntbsport:Kamper',
  '/[sportGroup]/[sportId]/[date]/turneringer/[tournamentId]/[seasonId]/[stageId]/resultater':
    'ntbsport:Kamper',
  '/[sportGroup]/[sportId]/[date]/turneringer/[tournamentId]/[seasonId]/[stageId]/tabell':
    'ntbsport:Tabell',
  '/[sportGroup]/[sportId]/[date]/turneringer/[tournamentId]/[seasonId]/[stageId]/statistikk':
    'ntbsport:Spillerstatistikk',
  '/[sportGroup]/[sportId]/[date]/lag': 'ntbsport:Lag',
  '/[sportGroup]/[sportId]/[date]/lag/[teamId]/kamper': 'ntbsport:Lagkamper',
  '/[sportGroup]/[sportId]/[date]/lag/[teamId]/resultater':
    'ntbsport:Lagkamper',
  '/[sportGroup]/[sportId]/[date]/lag/[teamId]/tabell': 'ntbsport:LagTabell',
  '/[sportGroup]/[sportId]/[date]/lag/[teamId]/stall': 'ntbsport:Spillerstall',
  '/[sportGroup]/[sportId]/[date]/lag/[teamId]/fakta': 'ntbsport:LagFakta',
  '/[sportGroup]/[sportId]/[date]/utovere': 'ntbsport:Spillersøk',
  '/[sportGroup]/[sportId]/[date]/utovere/[athleteId]':
    'ntbsport:Spillerprofil',
};

export const CustomerScripts = () => {
  const router = useRouter();
  const pathname = router.pathname;

  const { nifsCustomer } = useContext(NifsCustomerContext);

  //Render nrk masthead
  useEffect(() => {
    if (nifsCustomer?.customer?.id === CUSTOMER_IDS.NRK) {
      const masthead = document?.getElementsByClassName('nrk-masthead');

      if (!masthead?.length) {
        const app = document.getElementById('app');

        app?.insertAdjacentHTML(
          'afterbegin',
          window.nrknoMastheadRender?.Masthead({ dark: true }),
        );
        window.nrknoMastheadRender?.initialize();
      }
    }
  }, [nifsCustomer]);

  useEffect(() => {
    if (window.coreAnalytics && CORE_ANALYTICS[pathname]) {
      console.log('CoreAnalytics, pageview: ', CORE_ANALYTICS[pathname]);
      window.coreAnalytics('pageview', {
        contentId: CORE_ANALYTICS[pathname],
      });
    }
  }, [pathname]);

  const customer = CUSTOMERS?.find(
    (customer) => customer?.id === nifsCustomer?.customer?.id,
  );

  return (
    <>
      <Head>
        {customer?.livecenter?.icons?.map((icon) => (
          <link rel={icon?.rel} href={icon?.href} key={icon?.href} />
        ))}
        <link
          rel="stylesheet"
          type="text/css"
          href="https://static.nrk.no/dh/module/fotball-banner/static//expanded-3628a62c4dba1c20dc98.css"
        ></link>
      </Head>

      {customer?.livecenter?.styles?.map((style) => (
        <link
          rel={style?.rel}
          href={style?.href}
          id={style.id}
          key={style.id}
        />
      ))}

      {nifsCustomer?.customer?.id === CUSTOMER_IDS.NRK && (
        <Script
          id="core-analytics"
          strategy="afterInteractive"
          src="https://static.nrk.no/core-analytics/major/6/core-analytics.min.js"
          key="core-analytics"
          onLoad={() => {
            if (window.coreAnalytics) {
              window.coreAnalytics('initialize', {
                ga: 'UA-3413696-1',
                trackerName: 'ntbsport',
              });
              console.log('CoreAnalytics initialized');

              if (CORE_ANALYTICS[pathname]) {
                window.coreAnalytics('pageview', {
                  contentId: CORE_ANALYTICS[pathname],
                });
                console.log(
                  'CoreAnalytics, pageview: ',
                  CORE_ANALYTICS[pathname],
                );
              }
            }
          }}
        />
      )}

      {nifsCustomer?.customer?.id === CUSTOMER_IDS.NRK && (
        <Script
          strategy="afterInteractive"
          src="https://cl-eu2.k5a.io/618112c1d5dcb06e487079f2.module.js"
        />
      )}
    </>
  );
};
