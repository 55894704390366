import { UUIDs } from 'neo-common-enums';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface GenderProps {
  gender: string;
}
export interface StyledProps {
  $gender: string;
}

const StyledGender = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props?.$gender === 'gender-male' || props?.$gender === 'men'
      ? '#0059B3'
      : '#E51C30'};
  width: var(--ntb-space-4);
  height: var(--ntb-space-4);
  border-radius: 3px;
  font-size: var(--ntb-fontSizes-2xs);
  font-weight: var(--ntb-fontWeights-bold);
  color: #fff;
`;

export function Gender({ gender }: GenderProps) {
  return gender === UUIDs.Gender.MIXED ? (
    <>
      <StyledGender
        aria-label={
          UUIDs.Gender.MALE ? 'Menn' : UUIDs.Gender.FEMALE ? 'Kvinner' : ''
        }
        $gender={UUIDs.Gender.MALE}
      >
        <span aria-hidden="true">M</span>
      </StyledGender>
      <StyledGender aria-label="kvinner" $gender={UUIDs.Gender.FEMALE}>
        K
      </StyledGender>
    </>
  ) : (
    <StyledGender
      role="none"
      aria-label={
        gender === 'gender-male' || gender === 'men' ? 'Menn' : 'Kvinner'
      }
      $gender={gender}
    >
      <span aria-hidden="true">
        {gender === 'gender-male' || gender === 'men' ? 'M' : 'K'}
      </span>
    </StyledGender>
  );
}

export default Gender;
