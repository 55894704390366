import { useQuery } from '@tanstack/react-query';

import { FetchStageStatisticsType, fetchStageStatistics } from './fetch';
import { QUERY_KEYS } from '@ntb-sport/constants';

interface UseQueryStageStatistics {
  queryKey?: string;
  queryKeyParams: Record<string, string | number | null>;
  params: FetchStageStatisticsType;
  options?: Record<string, unknown>;
}

export const useQueryStageStatistics = ({
  queryKey = QUERY_KEYS.STAGE_STATISTICS,
  queryKeyParams,
  params,
  options,
}: UseQueryStageStatistics) => {
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchStageStatistics(params),
    enabled:
      Boolean(params.sportGroup) &&
      Boolean(params.stageId) &&
      Boolean(params.type),
    ...options,
  });

  return { data, isLoading, error, isFetching };
};
