import { NeoParticipantType } from '@ntb-sport/types';

import { COMPONENT_IDS } from '@ntb-sport/constants';
import { Loader } from './Loader/Loader';
import { Participants } from './Participants/Participants';
import './event-participants.css';
import { useBem } from '@ntb-sport/hooks';
import { Header } from './Header/Header';
import { Pairs } from './Pairs/Pairs';

interface EventParticipantsProps {
  eventParticipants: NeoParticipantType[];
  isLoading?: boolean;
  highlightedCountry?: string;
  sportGroup?: string;
  competitionId?: string;
}

export const EventParticipants = ({
  eventParticipants,
  isLoading,
  highlightedCountry = 'country-norway',
  sportGroup,
  competitionId,
}: EventParticipantsProps) => {
  const bem = useBem(COMPONENT_IDS.EVENT_PARTICIPANTS);

  const groupByPair = eventParticipants?.reduce(
    (eventParticipants: any, eventParticipant: NeoParticipantType) => {
      if (
        Object.prototype.hasOwnProperty.call(eventParticipant, 'pair') &&
        !eventParticipants[eventParticipant.pair]
      ) {
        eventParticipants[eventParticipant.pair] = [];
      }

      if (Object.prototype.hasOwnProperty.call(eventParticipant, 'pair')) {
        eventParticipants[eventParticipant.pair].push(eventParticipant);
      }
      return eventParticipants;
    },
    {},
  );

  return (
    <div className={bem()} data-competition-id={competitionId}>
      <Header sportGroup={sportGroup} />
      {isLoading ? (
        <Loader />
      ) : (!eventParticipants || !eventParticipants?.length) && !isLoading ? (
        <span className={bem('not-ready')}>Startlisten er ikke klar.</span>
      ) : groupByPair && Object.keys(groupByPair)?.length ? (
        <Pairs
          groupByPair={groupByPair}
          highlightedCountry={highlightedCountry}
        />
      ) : (
        <Participants
          eventParticipants={eventParticipants}
          highlightedCountry={highlightedCountry}
        />
      )}
    </div>
  );
};

export default EventParticipants;
