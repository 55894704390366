import { NeoScopeResultType } from '@ntb-sport/types';
import { BronzeIcon, GoldIcon, SilverIcon } from '@ntb-sport/ui';
import { LabelProperties } from 'neo-common-enums';
import './rank.css';
import { useBem } from '@ntb-sport/hooks';
import classnames from 'classnames';

function getRank(scopeTypeId?: number, isMedalEvent?: boolean, rank?: number) {
  if (
    isMedalEvent &&
    scopeTypeId === LabelProperties.ScopeTypeId.FINISH &&
    rank &&
    rank === 1
  ) {
    return <GoldIcon>{rank}</GoldIcon>;
  } else if (
    isMedalEvent &&
    scopeTypeId === LabelProperties.ScopeTypeId.FINISH &&
    rank &&
    rank === 2
  ) {
    return <SilverIcon>{rank}</SilverIcon>;
  }
  if (
    isMedalEvent &&
    scopeTypeId === LabelProperties.ScopeTypeId.FINISH &&
    rank &&
    rank === 3
  ) {
    return <BronzeIcon>{rank}</BronzeIcon>;
  } else {
    return rank;
  }
}

interface RankProps {
  scopeResult: NeoScopeResultType;
  highlightedCountryUUID?: string;
  isMedalEvent?: boolean;
  scopeTypeId?: number;
}

export const Rank = ({
  scopeResult,
  highlightedCountryUUID,
  isMedalEvent,
  scopeTypeId,
}: RankProps) => {
  const highlightCountry =
    scopeResult?.participant?.team?.uuid === highlightedCountryUUID ||
    scopeResult?.participant?.person?.countries?.[0]?.uuid ===
      highlightedCountryUUID;

  const bem = useBem('scope-results');

  return (
    <div
      className={classnames(
        bem('rank'),
        highlightCountry && bem('rank', 'highlight'),
        isMedalEvent && bem('rank', 'medal-event'),
      )}
    >
      {getRank(scopeTypeId, isMedalEvent, scopeResult?.rank)}
    </div>
  );
};
