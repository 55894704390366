import { NeoScopeResultType } from '@ntb-sport/types';
import { Result } from '../../Result/Result';
import { LabelProperties, UUIDs } from 'neo-common-enums';

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import { useBem, useStateWithDep } from '@ntb-sport/hooks';
import { Button, Flex } from '../../../../chakra';
import './individual.css';

export interface IndividualProps {
  scopeResults: NeoScopeResultType[];
  sportUUID: string;
  highlightedCountryUUID?: string;
  allShotSeries: any;
  scopeNumber: number;
  isRelay: boolean;
  labels?: string[];
  disciplineUUID?: string;
  maxRank?: number;
  scopeTypeId: number;
  limit: number;
  tourCompetitionUuid?: string;
  scopeCategoryId?: number;
  isMedalEvent?: boolean;
}

export const Individual = ({
  scopeResults,
  sportUUID,
  highlightedCountryUUID,
  allShotSeries,
  scopeNumber,
  isRelay,
  labels,
  disciplineUUID,
  maxRank,
  scopeTypeId,
  limit,
  tourCompetitionUuid,
  scopeCategoryId,
  isMedalEvent,
}: IndividualProps) => {
  const { t } = useTranslation();

  const bem = useBem('scope-results');

  const [limitState, setLimitState] = useStateWithDep(limit);

  const displayToggle = limit < scopeResults.length;

  const additionalParticipantsFromHighlightedCountry =
    limitState < scopeResults.length - 1
      ? scopeResults?.filter(
          (scopeResult: NeoScopeResultType) =>
            (scopeResult?.rank > limitState ||
              scopeResult?.resultId ===
                LabelProperties.ResultId.DID_NOT_FINISH ||
              scopeResult?.resultId === LabelProperties.ResultId.DISQUALIFIED ||
              scopeResult?.resultId ===
                LabelProperties.ResultId.DID_NOT_START) &&
            (scopeResult?.participant?.team?.country?.uuid ===
              highlightedCountryUUID ||
              scopeResult?.participant?.person?.countries?.[0]?.uuid ===
                highlightedCountryUUID),
        )
      : [];

  return (
    <>
      {scopeResults
        ?.filter((scopeResult, index) =>
          sportUUID === UUIDs.Sport.CROSS_COUNTRY_SKIING &&
          scopeTypeId !== LabelProperties.ScopeTypeId.FINISH
            ? index <= limitState &&
              scopeResult?.resultId !== LabelProperties.ResultId.DID_NOT_START
            : index <= limitState,
        )
        .sort((a, b) => a?.rank - b?.rank)
        .map((scopeResult: any, index: number) => {
          if (index === maxRank) {
            return (
              <li className={bem('max-rank')}>
                {`Vi følger de ${maxRank} første live. Fullstendig resultatliste
              kommer når konkurransen er ferdig.`}
              </li>
            );
          }
          return (
            <Result
              key={scopeResult.uuid + index}
              scopeResult={scopeResult}
              sportUUID={sportUUID}
              highlightedCountryUUID={highlightedCountryUUID}
              allShotSeries={allShotSeries}
              scopeNumber={scopeNumber}
              isRelay={isRelay}
              labels={labels}
              disciplineUUID={disciplineUUID}
              tourCompetitionUuid={tourCompetitionUuid}
              scopeCategoryId={scopeCategoryId}
              scopeTypeId={scopeTypeId}
              isMedalEvent={isMedalEvent}
            />
          );
        })}
      {additionalParticipantsFromHighlightedCountry?.length > 0 && (
        <>
          {`${t('otherAthletesFrom')} Norge`}
          {additionalParticipantsFromHighlightedCountry?.map(
            (scopeResult: any) => (
              <Result
                key={scopeResult.uuid}
                scopeResult={scopeResult}
                sportUUID={sportUUID}
                highlightedCountryUUID={highlightedCountryUUID}
                allShotSeries={allShotSeries}
                scopeNumber={scopeNumber}
                isRelay={isRelay}
                labels={labels}
                disciplineUUID={disciplineUUID}
                tourCompetitionUuid={tourCompetitionUuid}
                scopeCategoryId={scopeCategoryId}
                isMedalEvent={isMedalEvent}
              />
            ),
          )}
        </>
      )}
      {displayToggle && (
        <Flex justifyContent="center">
          <Button
            size="sm"
            onClick={() =>
              setLimitState(
                limitState < scopeResults.length ? scopeResults.length : limit,
              )
            }
            bg="var(--header-button-background-color)"
            borderRadius="var(--ntb-radii-md)"
            color="var(--header-button-font-color)"
            _hover={{
              bg: 'var(--header-button-background-color__hover)',
              color: 'var(--header-button-font-color__hover)',
            }}
            rightIcon={
              limitState < scopeResults.length ? (
                <ChevronDownIcon />
              ) : (
                <ChevronUpIcon />
              )
            }
            //isLoading={isLoading}
          >
            {limitState < scopeResults.length ? t('showAll') : t('showLess')}
          </Button>
        </Flex>
      )}
    </>
  );
};
