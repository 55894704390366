import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import { CSS_VARIABLES } from '../index';

export const TEAM_LINEUP_STYLES = [
  {
    name: 'Root',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_LINEUP}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Menu',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_LINEUP}'] [data-element-id='${ELEMENT_IDS.MENU}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Button',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_LINEUP}'] [data-element-id='${ELEMENT_IDS.BUTTON}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
      CSS_VARIABLES.FONT_COLOR__HOVER,
      CSS_VARIABLES.BORDER_COLOR__HOVER,
      CSS_VARIABLES.BACKGROUND_COLOR__HOVER,
      CSS_VARIABLES.FONT_COLOR__ACTIVE,
      CSS_VARIABLES.BORDER_COLOR__ACTIVE,
      CSS_VARIABLES.BACKGROUND_COLOR__ACTIVE,
    ],
  },
  {
    name: 'List item',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_LINEUP}'] [data-element-id='${ELEMENT_IDS.LIST_ITEM}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR__HOVER,
    ],
  },
];
