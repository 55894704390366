import { useQuery } from '@tanstack/react-query';

import { fetchNifsMatches, FetchNifsMatches } from './fetch';
import { getIsNifsSportGroup } from '@ntb-sport/helpers';
import { QUERY_KEYS } from '@ntb-sport/constants';

interface UseQueryNifsMatches {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: FetchNifsMatches;
  options?: Record<string, unknown>;
}

export const useQueryNifsMatches = ({
  queryKey = QUERY_KEYS.NIFS_MATCHES,
  queryKeyParams,
  params,
  options,
}: UseQueryNifsMatches) => {
  const isNifsSportGroup = getIsNifsSportGroup(params?.sportGroup);

  const { data, isLoading, error } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchNifsMatches(params),
    enabled:
      isNifsSportGroup && Boolean(params.sportGroup) && Boolean(params.date),
    ...options,
  });

  return { data, isLoading, error };
};
