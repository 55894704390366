import { NeoScopeResultType } from '@ntb-sport/types';
import './ski-jmping.css';

import { Rank, Records, Qualified, Distance, Points } from '../Common';
import StylePoints from './StylePoints/StylePoints';
import { useBem } from '@ntb-sport/hooks';
import EventParticipant from '../../../EventParticipant/EventParticipant';

interface SkiJumpingProps {
  scopeResult: NeoScopeResultType;
  highlightedCountryUUID?: string;
  isMedalEvent?: boolean;
  scopeTypeId?: number;
}

export const SkiJumping = ({
  scopeResult,
  highlightedCountryUUID,
  isMedalEvent,
  scopeTypeId,
}: SkiJumpingProps) => {
  const bem = useBem('scope-results');

  return (
    <>
      <Rank
        scopeResult={scopeResult}
        highlightedCountryUUID={highlightedCountryUUID}
        isMedalEvent={isMedalEvent}
        scopeTypeId={scopeTypeId}
      />
      <EventParticipant
        person={scopeResult?.participant?.person}
        team={scopeResult?.participant?.team}
        representing={scopeResult?.participant?.representing}
        bibNumber={scopeResult?.participant?.bibNumber}
        lineup={scopeResult?.participant?.lineup}
        lineupExchanger={scopeResult?.participant?.lineupExchanger}
        isPelotonParticipant={scopeResult?.participant?.isPelotonParticipant}
      />

      <div className={bem('ski-jumping-result')}>
        <Qualified resultId={scopeResult?.resultId} />
        <Records records={scopeResult?.records} />

        <Distance
          distance={scopeResult.distance}
          resultId={scopeResult.resultId}
        />
        <Points points={scopeResult.points} resultId={scopeResult.resultId} />

        <StylePoints stylePoints={scopeResult?.stylePoints} />
      </div>
    </>
  );
};
