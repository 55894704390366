import Icon from '../Icon/Icon';

export const IceHockeySportIcon = (props: any) => (
  <Icon viewBox="0 0 28.3 28.3" fill="currentcolor" {...props}>
    <g>
      <g>
        <g>
          <circle cx="15.8" cy="6.4" r="2.4" />
        </g>
        <g>
          <path
            d="M24.2,21.7c0,0.2-0.1,0.3-0.3,0.4l-3.7,0.5c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.3-0.2l-4.6-6.7h0
				c-1.2-0.1-2.2-0.6-2.9-1.5c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4-0.1-0.6-0.1
				c0,0.3,0,0.7,0.1,1c1.8,0.6,3,2.1,3.6,4.4c0.5,1.8,0.4,3.4,0.4,3.5c0,0.1,0,0.2,0,0.3h1.6c0.4,0,0.7,0.3,0.7,0.7
				c0,0.4-0.3,0.7-0.7,0.7h-4.9c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7h0.6c0-0.1-0.1-0.3,0-0.4c0,0,0-0.1,0-0.3
				c0-0.8-0.1-3-1.2-4.2c-0.5,1.9-1.8,3.6-2.6,4.5h1.2c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7H4.8c-0.4,0-0.7-0.3-0.7-0.7
				c0-0.4,0.3-0.7,0.7-0.7h0.6c-0.1-0.5,0-0.9,0.4-1.3c0.8-0.8,3-3.5,2.6-5.2c-0.3-1.5-0.4-2.6-0.1-3.7c-0.5-0.5-0.6-1-0.6-1.3
				c-0.1-1.8,2.1-2.8,4.1-3.5c0.2-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.3,0c0.6,0.1,1.1,0.7,1,1.3c-0.1,0.8-0.2,3.1,0.6,4.2
				c0.3,0.4,0.7,0.6,1.2,0.6c0.6,0,1.1,0.6,1.1,1.2c0,0.4-0.2,0.7-0.5,0.9l3.6,5.3l4.1-0.9c0.1,0,0.2,0,0.3,0.1
				c0.1,0.1,0.1,0.2,0.1,0.3L24.2,21.7z"
          />
        </g>
      </g>
    </g>
  </Icon>
);
