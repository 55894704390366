import { NeoScopeResultType } from '@ntb-sport/types';
import ShotSeries from './ShotSeries/ShotSeries';
import { Rank, Records, Qualified, Duration } from '../Common';
import { useBem } from '@ntb-sport/hooks';
import './biathlon.css';
import EventParticipant from '../../../EventParticipant/EventParticipant';

interface BiathlonProps {
  scopeResult: NeoScopeResultType;
  highlightedCountryUUID?: string;
  allShotSeries: any;
  scopeNumber: number;
  isRelay: boolean;
  isMedalEvent?: boolean;
  scopeTypeId?: number;
}

export const Biathlon = ({
  scopeResult,
  highlightedCountryUUID,
  allShotSeries,
  scopeNumber,
  isRelay,
  isMedalEvent,
  scopeTypeId,
}: BiathlonProps) => {
  const bem = useBem('scope-results');

  return (
    <>
      <Rank
        scopeResult={scopeResult}
        highlightedCountryUUID={highlightedCountryUUID}
        isMedalEvent={isMedalEvent}
        scopeTypeId={scopeTypeId}
      />
      <EventParticipant
        person={scopeResult?.participant?.person}
        team={scopeResult?.participant?.team}
        representing={scopeResult?.participant?.representing}
        bibNumber={scopeResult?.participant?.bibNumber}
        lineup={scopeResult?.participant?.lineup}
        lineupExchanger={scopeResult?.participant?.lineupExchanger}
        isPelotonParticipant={scopeResult?.participant?.isPelotonParticipant}
      />

      <div className={bem('biathlon-result')}>
        <Qualified resultId={scopeResult?.resultId} />
        <Records records={scopeResult?.records} />

        <ShotSeries
          allShotSeries={allShotSeries}
          isRelay={isRelay}
          scopeNumber={scopeNumber}
          participantUUID={scopeResult?.participant?.uuid}
        />

        <Duration
          duration={scopeResult.duration}
          timeDifference={scopeResult.timeDifference}
          resultId={scopeResult.resultId}
        />
      </div>
    </>
  );
};
