import {
  COMPONENT_IDS,
  DATA_PICKER_GROUPS,
  DATA_PROVIDERS,
  SPORT_GROUP,
} from '@ntb-sport/constants';
import { WidgetConfig, SportsdataConfig } from '@ntb-sport/types';
import {
  DATA_TYPES,
  WIDGET_IDS,
  WIDGET_OPTIONS,
  WIDGET_VERSION,
} from '../common';

export const config: WidgetConfig = {
  id: WIDGET_IDS.SEASON_FIXTURES,
  dataType: DATA_TYPES.NIFS,
  version: WIDGET_VERSION,
  displayName: 'Terminliste turnering',
  widgetNamespace: 'NtbWidgetSeasonFixtures',
  iframeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.SEASON_FIXTURES}/iframe.html`,
  widgetUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.SEASON_FIXTURES}/${WIDGET_IDS.SEASON_FIXTURES}.js`,
  themeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/themes/default.css`,
  title: 'Terminliste turnering',
  summary: 'Terminliste og resultater for en gitt turnering',
  sportGroups: [
    SPORT_GROUP.FOOTBALL,
    SPORT_GROUP.HANDBALL,
    SPORT_GROUP.ICE_HOCKEY,
  ],
  description: 'general description to be displayed on the documentation page',
  components: [
    COMPONENT_IDS.TEAM_FIXTURES,
    COMPONENT_IDS.TEAM_FIXTURES_MATCH,
    COMPONENT_IDS.TEAM_FIXTURES_TEAM,
    COMPONENT_IDS.TEAM_STANDINGS,
  ],
  options: {
    [WIDGET_OPTIONS.CUSTOMER_ID]: { isRequired: true },
    [WIDGET_OPTIONS.ERROR_MESSAGE]: { isRequired: false },
    [WIDGET_OPTIONS.ID]: { isRequired: true },
    [WIDGET_OPTIONS.ON_ERROR]: { isRequired: false },
    [WIDGET_OPTIONS.SPORT_GROUP]: { isRequired: true },
    [WIDGET_OPTIONS.STYLE_ID]: { isRequired: false },
    [WIDGET_OPTIONS.TARGET_ID]: { isRequired: true },
    [WIDGET_OPTIONS.ROUND]: { isRequired: true },
  },
  uiOptions: {
    [COMPONENT_IDS.TEAM_FIXTURES]: [
      WIDGET_OPTIONS.GROUP_FIXTURES_BY,
      WIDGET_OPTIONS.SHOW_TABLE,
      WIDGET_OPTIONS.TITLE,
    ],
    [COMPONENT_IDS.TEAM_STANDINGS]: [
      WIDGET_OPTIONS.TITLE,
      WIDGET_OPTIONS.SHOW_STAGE_NAME,
    ],
  },
};

export const sportsdataConfig: SportsdataConfig = {
  dataPickerGroups: {
    [DATA_PICKER_GROUPS.NIFS_STAGE_GROUP]: {
      title: 'Stage',
      key: DATA_PICKER_GROUPS.NIFS_STAGE_GROUP,
      primaryId: DATA_PROVIDERS.NIFS_STAGES,
      isDefault: true,
      pickers: {
        [DATA_PROVIDERS.SPORT_GROUP]: { defaultValue: 'football' },
        [DATA_PROVIDERS.NIFS_COUNTRIES]: { defaultValue: 1 },
        [DATA_PROVIDERS.NIFS_TOURNAMENTS]: {},
        [DATA_PROVIDERS.NIFS_STAGES]: { isPrimaryId: true },
      },
    },
  },
  unique: {
    [WIDGET_OPTIONS.ROUND]: {
      key: WIDGET_OPTIONS.ROUND,
      defaultValue: 'active',
      label: 'Runde',
      type: 'input',
    },
  },
  optionGroups: {
    [COMPONENT_IDS.TEAM_FIXTURES]: {
      title: 'Terminliste',
      key: COMPONENT_IDS.TEAM_FIXTURES,
      options: {
        [WIDGET_OPTIONS.GROUP_FIXTURES_BY]: {
          key: WIDGET_OPTIONS.GROUP_FIXTURES_BY,
          defaultValue: 'date',
          label: 'Grupper kamper etter',
          type: 'radioGroup',
          options: [
            { label: 'Dato', value: 'date' },
            { label: 'Type', value: 'matchTypeId' },
          ],
        },
        [WIDGET_OPTIONS.SHOW_TABLE]: {
          key: WIDGET_OPTIONS.SHOW_TABLE,
          label: 'Vis tabell',
          type: 'checkbox',
          defaultValue: true,
          connectedComponent: COMPONENT_IDS.TEAM_STANDINGS,
        },
      },
    },
    [COMPONENT_IDS.TEAM_STANDINGS]: {
      title: 'Tabell',
      key: COMPONENT_IDS.TEAM_STANDINGS,
      options: {
        [WIDGET_OPTIONS.TITLE]: {
          key: WIDGET_OPTIONS.TITLE,
          defaultValue: 'Tabell:',
          label: 'Tittel',
          type: 'input',
        },
        [WIDGET_OPTIONS.SHOW_STAGE_NAME]: {
          key: WIDGET_OPTIONS.SHOW_STAGE_NAME,
          defaultValue: true,
          label: 'Vis turneringsnavn',
          type: 'checkbox',
        },
      },
    },
  },
};
