import { Icon } from '../../chakra';
import { TransferIconsInterface } from './utils/TransferIconsInterface';

export const TransferForwardIcon = (props: TransferIconsInterface) => (
  <Icon aria-hidden="true" w={props?.w} h={props?.h}>
    <svg viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="forward_arrows">
        <g id="arrow_forward_ios">
          <g mask="url(#mask0_316_165)">
            <path
              id="arrow_forward_ios_2"
              d="M13.3372 14.2052L12.6282 13.4962L18.1243 8.00003L12.6282 2.50386L13.3372 1.79486L19.5423 8.00003L13.3372 14.2052Z"
              fill={props?.color ? props.color : 'currentColor'}
            ></path>
          </g>
        </g>
        <g id="arrow_forward_ios_3">
          <g mask="url(#mask1_316_165)">
            <path
              id="arrow_forward_ios_4"
              d="M8.00282 12.6539L7.47107 12.1221L11.5932 8L7.47107 3.87788L8.00282 3.34613L12.6567 8L8.00282 12.6539Z"
              fill={props?.color ? props.color : 'currentColor'}
              fillOpacity="0.6"
            ></path>
          </g>
        </g>
        <g id="arrow_forward_ios_5">
          <g mask="url(#mask2_316_165)">
            <path
              id="arrow_forward_ios_6"
              d="M2.66859 11.1026L2.31409 10.7481L5.06217 7.99998L2.31409 5.2519L2.66859 4.8974L5.77117 7.99998L2.66859 11.1026Z"
              fill={props?.color ? props.color : 'currentColor'}
              fillOpacity="0.3"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </Icon>
);
