import {
  SPORTEDIT_API_BASE_URL,
  useQuerySportEditCustomer,
} from '@ntb-sport/api';
import { Skeleton } from '@chakra-ui/react';

import { createContext, ReactNode } from 'react';

export const SportEditCustomerContext = createContext({});

export const SportEditCustomerProvider = ({
  apiBaseUrl = SPORTEDIT_API_BASE_URL,
  children,
}: {
  apiBaseUrl?: string;
  children: ReactNode;
}) => {
  const { data: customerSetup, isLoading: isLoadingCustomerSetup } =
    useQuerySportEditCustomer({ params: { apiBaseUrl } });

  if (isLoadingCustomerSetup) {
    return <Skeleton w="100wv" h="100vh" />;
  }

  return (
    <SportEditCustomerContext.Provider value={{ customer: customerSetup }}>
      {children}
    </SportEditCustomerContext.Provider>
  );
};

export default SportEditCustomerProvider;
