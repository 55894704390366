import { ENTITIES } from '../utils';
import { onScopesDelete } from './delete';
import { onScopesPatch } from './patch';
import { onScopesPost } from './post';

export function onHandleUpdateScopes({
  msg,
  apiClient,
}: {
  msg: any;
  apiClient: any;
}) {
  const { entity, method } = msg;

  if (entity !== ENTITIES.SCOPES) return;

  if (method === 'POST') {
    onScopesPost({ msg, apiClient });
  } else if (method === 'PATCH') {
    onScopesPatch({ msg, apiClient });
  } else if (method === 'DELETE') {
    onScopesDelete({ msg, apiClient });
  } else {
    console.log('Unknown method', method);
    return;
  }
}
