import * as S from './TeamStandings.styled';

import TeamStandingsDescriptionFootball from './Football/TeamStandingsDescriptionFootball';
import TeamStandingsDescriptionIceHockey from './Icehockey/TeamStandingsDescriptionIceHockey';
import TeamStandingsFootball from './Football/TeamStandingsFootball';
import TeamStandingsHandball from './Handball/TeamStandingsHandball';
import TeamStandingsIceHockey from './Icehockey/TeamStandingsIceHockey';
import { useState, useEffect, useRef } from 'react';
import { MatchesType } from '@ntb-sport/types';

import { InfoIcon, SmallCloseIcon, WarningIcon } from '@chakra-ui/icons';
import { COMPONENT_IDS, ELEMENT_IDS, SPORT_ID } from '@ntb-sport/constants';
import { Button, Skeleton } from '../../chakra';

interface TeamStandingsProps {
  data?: any;
  sportId?: number;
  isLoading?: boolean;
  teamLink?: any;
  matches: MatchesType;
  highlightedTeams?: number[];
  showStageName?: boolean;
  showTableInfo?: boolean;
  shouldFocus?: boolean;
  title?: string;
  onClickStageName?: any;
}

interface TableSortingRule {
  id: number;
  norwegianText: string;
}

interface Team {
  lastSixMatches: string;
  place: number;
}

interface TableDividingLine {
  typeId: number;
}

export const TeamStandings = ({
  data,
  sportId,
  isLoading,
  teamLink,
  matches,
  highlightedTeams,
  showStageName = true,
  showTableInfo = true,
  shouldFocus = false,
  title,
  onClickStageName,
}: TeamStandingsProps) => {
  const [showTableInfoBtn, setShowTableInfoBtn] = useState(false);

  const tableHeadingRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (tableHeadingRef?.current && shouldFocus) {
      tableHeadingRef.current.focus();
    }
  }, [data]);

  if (isLoading) {
    return <Skeleton w="100%" h="100%" minH="600px" />;
  }

  if (!data?.teams) {
    return <S.NoStandings>Tabell ikke tilgjengelig</S.NoStandings>;
  }

  const stageName =
    showStageName && title
      ? `${title} ${data?.stage?.fullName || data?.stage?.name}`
      : showStageName && !title
        ? data?.stage?.fullName || data?.stage?.name
        : title && !showStageName
          ? title
          : '';

  const hasAveragePoints =
    data?.tableSortingRules &&
    Boolean(
      data.tableSortingRules.find((rule: TableSortingRule) => rule.id === 40),
    );
  const hasPointPercentage =
    data?.tableSortingRules &&
    Boolean(
      data.tableSortingRules.find((rule: TableSortingRule) => rule.id === 41),
    );

  const hasFormData =
    data?.teams &&
    data?.teams.find((team: Team) => typeof team.lastSixMatches === 'string')
      ? true
      : false;

  const promotionLine =
    data?.tableDividingLines &&
    data?.tableDividingLines.find(
      (item: TableDividingLine) => item.typeId === 1 || item.typeId === 5,
    );
  const promotionLineTeamId =
    promotionLine &&
    data?.teams?.find((team: Team) => team.place === promotionLine.toPlace)?.id;

  const promotionPlayoffLine =
    data?.tableDividingLines &&
    data?.tableDividingLines.find(
      (item: TableDividingLine) => item.typeId === 3 || item.typeId === 6,
    );
  const promotionPlayoffLineTeamId =
    promotionPlayoffLine &&
    data?.teams?.find(
      (team: Team) => team.place === promotionPlayoffLine.toPlace,
    )?.id;

  const relegationLine =
    data?.tableDividingLines &&
    data?.tableDividingLines.find(
      (item: TableDividingLine) => item.typeId === 2,
    );
  const relegationLineTeamId =
    relegationLine &&
    data?.teams?.find((team: Team) => team.place === relegationLine.fromPlace)
      ?.id;

  const relegationPlayoffLine =
    data?.tableDividingLines &&
    data?.tableDividingLines.find(
      (item: TableDividingLine) => item.typeId === 4,
    );
  const relegationPlayoffLineTeamId =
    relegationPlayoffLine &&
    data?.teams?.find(
      (team: Team) => team.place === relegationPlayoffLine.fromPlace,
    )?.id;

  return (
    <S.ContainerQuery
      data-component-id={COMPONENT_IDS.TEAM_STANDINGS}
      data-tournament-id={data?.stage?.tournament?.id}
    >
      <S.Container>
        {(showStageName || title || showTableInfo) && (
          <S.Header data-element-id={ELEMENT_IDS.HEADER}>
            {(showStageName || title) && (
              <S.StageName
                $isClickable={onClickStageName}
                onClick={() => onClickStageName && onClickStageName()}
                ref={tableHeadingRef}
                tabIndex={0}
                onKeyDown={(e) =>
                  e.key === 'Enter' && onClickStageName && onClickStageName()
                }
                aria-label={
                  `Tabell for ${stageName}` +
                  (onClickStageName
                    ? '. Klikk for å gå til turneringsside'
                    : '')
                }
              >
                {stageName}
              </S.StageName>
            )}

            {showTableInfo && (
              <Button
                data-element-id={ELEMENT_IDS.BUTTON}
                ml="auto"
                size="sm"
                minW="80px"
                onClick={() =>
                  !isLoading && setShowTableInfoBtn(!showTableInfoBtn)
                }
                bg="var(--background-color)"
                borderRadius="var(--ntb-radii-md)"
                color="var(--font-color)"
                _hover={{
                  bg: 'var(--background-color__hover)',
                  color: 'var(--font-color__hover)',
                }}
                _active={{
                  bg: 'var(--background-color__active)',
                  color: 'var(--font-color__active)',
                }}
                rightIcon={
                  showTableInfoBtn ? (
                    <SmallCloseIcon color="currentcolor" />
                  ) : (
                    <InfoIcon color="currentcolor" />
                  )
                }
                isLoading={isLoading}
              >
                {showTableInfoBtn ? 'Lukk' : 'Info'}
              </Button>
            )}

            {showTableInfoBtn && (
              <S.TableInfo>
                <div>
                  <h3>Beskrivelse</h3>
                  <S.TableDescription>
                    {sportId === SPORT_ID.FOOTBALL && (
                      <TeamStandingsDescriptionFootball
                        hasAveragePoints={hasAveragePoints}
                      />
                    )}
                    {sportId === SPORT_ID.ICE_HOCKEY && (
                      <TeamStandingsDescriptionIceHockey
                        hasAveragePoints={hasAveragePoints}
                        hasPointPercentage={hasPointPercentage}
                        matches={matches}
                      />
                    )}
                    {sportId === SPORT_ID.HANDBALL && (
                      <TeamStandingsDescriptionFootball
                        hasAveragePoints={hasAveragePoints}
                      />
                    )}
                  </S.TableDescription>
                </div>

                {data?.tableSortingRules && (
                  <S.SortingRules>
                    <h3>Sorteringsregler</h3>
                    <ol>
                      {data?.tableSortingRules.map((rule: TableSortingRule) => (
                        <li key={rule?.id}>{rule?.norwegianText}</li>
                      ))}
                    </ol>
                  </S.SortingRules>
                )}
              </S.TableInfo>
            )}
          </S.Header>
        )}
        <S.TableContainer>
          {sportId === SPORT_ID.FOOTBALL && (
            <TeamStandingsFootball
              data={data}
              isLoading={isLoading}
              hasFormData={hasFormData}
              hasAveragePoints={hasAveragePoints}
              relegationLineTeamId={relegationLineTeamId}
              relegationPlayoffLineTeamId={relegationPlayoffLineTeamId}
              promotionLineTeamId={promotionLineTeamId}
              promotionPlayoffLineTeamId={promotionPlayoffLineTeamId}
              teamLink={teamLink}
              matches={matches}
              highlightedTeams={highlightedTeams}
            />
          )}
          {sportId === SPORT_ID.ICE_HOCKEY && (
            <TeamStandingsIceHockey
              data={data}
              isLoading={isLoading}
              matches={matches}
              hasAveragePoints={hasAveragePoints}
              hasPointPercentage={hasPointPercentage}
              relegationLineTeamId={relegationLineTeamId}
              relegationPlayoffLineTeamId={relegationPlayoffLineTeamId}
              promotionLineTeamId={promotionLineTeamId}
              promotionPlayoffLineTeamId={promotionPlayoffLineTeamId}
              teamLink={teamLink}
            />
          )}
          {sportId === SPORT_ID.HANDBALL && (
            <TeamStandingsHandball
              data={data}
              isLoading={isLoading}
              matches={matches}
              hasAveragePoints={hasAveragePoints}
              relegationLineTeamId={relegationLineTeamId}
              relegationPlayoffLineTeamId={relegationPlayoffLineTeamId}
              promotionLineTeamId={promotionLineTeamId}
              promotionPlayoffLineTeamId={promotionPlayoffLineTeamId}
              teamLink={teamLink}
            />
          )}
          {data?.stage?.comment && (
            <S.Comment>
              {isLoading ? (
                <Skeleton />
              ) : (
                <>
                  <WarningIcon color="var(--icon-color)" boxSize={5} />
                  {data?.stage?.comment}
                </>
              )}
            </S.Comment>
          )}
        </S.TableContainer>
      </S.Container>
    </S.ContainerQuery>
  );
};
