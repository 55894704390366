import Icon from '../Icon/Icon';

export const VarIcon = (props: any) => (
  <Icon
    {...props}
    className="var"
    viewBox="0 0 22.7 22.7"
    enableBackground="new 0 0 22.7 22.7"
  >
    <g id="Layer_1"></g>
    <g id="Layer_3"></g>
    <g id="Layer_4">
      <g>
        <g>
          <g>
            <path
              d="M19.6,3.772H3.1c-1.43,0-2.59,1.16-2.59,2.59v8.64c0,1.43,1.16,2.59,2.59,2.59h4.94v1.34h6.62v-1.34h4.94
					c1.43,0,2.59-1.16,2.59-2.59v-8.64C22.19,4.932,21.03,3.772,19.6,3.772z M21.19,15.002c0,0.87-0.71,1.59-1.59,1.59H3.1
					c-0.88,0-1.59-0.72-1.59-1.59v-8.64c0-0.88,0.71-1.59,1.59-1.59h16.5c0.88,0,1.59,0.71,1.59,1.59V15.002z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M3.852,7.893l1.548,4.582L6.952,7.86c0.081-0.242,0.141-0.411,0.182-0.506c0.04-0.095,0.107-0.18,0.2-0.256
					c0.093-0.076,0.221-0.114,0.382-0.114c0.118,0,0.228,0.03,0.329,0.089c0.101,0.059,0.18,0.138,0.238,0.235
					C8.34,7.406,8.368,7.505,8.368,7.604c0,0.068-0.009,0.142-0.028,0.221S8.298,7.982,8.27,8.059
					C8.242,8.135,8.215,8.213,8.187,8.294L6.532,12.76c-0.059,0.171-0.118,0.333-0.177,0.487c-0.059,0.154-0.127,0.289-0.205,0.406
					c-0.078,0.117-0.181,0.212-0.31,0.287s-0.287,0.112-0.473,0.112s-0.344-0.037-0.473-0.11c-0.129-0.073-0.233-0.169-0.312-0.289
					c-0.079-0.12-0.148-0.256-0.207-0.408c-0.059-0.152-0.118-0.314-0.177-0.485L2.57,8.331C2.542,8.25,2.513,8.171,2.484,8.093
					c-0.03-0.078-0.054-0.162-0.075-0.252c-0.02-0.09-0.03-0.166-0.03-0.228c0-0.158,0.064-0.303,0.191-0.434
					c0.127-0.131,0.287-0.196,0.48-0.196c0.236,0,0.403,0.072,0.501,0.217C3.649,7.345,3.749,7.576,3.852,7.893z"
            />
          </g>
          <g>
            <path
              d="M12.722,13.161l-0.326-0.858H9.618l-0.326,0.876c-0.127,0.342-0.236,0.573-0.326,0.692
					c-0.09,0.12-0.238,0.179-0.443,0.179c-0.174,0-0.328-0.064-0.461-0.191c-0.134-0.127-0.2-0.272-0.2-0.434
					c0-0.093,0.016-0.19,0.047-0.289c0.031-0.099,0.082-0.238,0.154-0.415l1.748-4.438c0.05-0.127,0.11-0.28,0.18-0.459
					c0.07-0.179,0.145-0.327,0.224-0.445c0.079-0.118,0.183-0.214,0.312-0.287c0.129-0.073,0.287-0.11,0.477-0.11
					c0.193,0,0.354,0.037,0.483,0.11c0.129,0.073,0.233,0.167,0.312,0.282c0.079,0.115,0.146,0.239,0.2,0.371
					c0.054,0.132,0.123,0.308,0.207,0.529l1.785,4.41c0.14,0.336,0.21,0.58,0.21,0.732c0,0.158-0.065,0.304-0.197,0.436
					c-0.132,0.132-0.292,0.198-0.479,0.198c-0.109,0-0.202-0.019-0.28-0.058c-0.078-0.039-0.143-0.092-0.196-0.158
					c-0.053-0.067-0.109-0.169-0.169-0.308C12.819,13.388,12.766,13.266,12.722,13.161z M9.981,11.263h2.042l-1.03-2.82
					L9.981,11.263z"
            />
          </g>
          <g>
            <path
              d="M16.504,11.012h-0.485v2.196c0,0.289-0.064,0.502-0.191,0.639c-0.127,0.137-0.294,0.205-0.499,0.205
					c-0.221,0-0.392-0.071-0.513-0.214c-0.121-0.143-0.182-0.353-0.182-0.629V7.949c0-0.298,0.067-0.514,0.2-0.648
					c0.134-0.134,0.35-0.2,0.648-0.2h2.252c0.311,0,0.576,0.013,0.797,0.04c0.221,0.026,0.42,0.08,0.597,0.161
					c0.214,0.09,0.404,0.219,0.569,0.387c0.165,0.168,0.29,0.363,0.375,0.585C20.157,8.495,20.2,8.731,20.2,8.979
					c0,0.51-0.144,0.917-0.431,1.221c-0.287,0.305-0.723,0.521-1.308,0.648c0.246,0.131,0.48,0.323,0.704,0.578
					c0.224,0.255,0.423,0.526,0.599,0.813c0.176,0.287,0.312,0.547,0.41,0.778c0.098,0.232,0.147,0.391,0.147,0.478
					c0,0.09-0.029,0.179-0.086,0.268c-0.057,0.089-0.136,0.158-0.235,0.21c-0.099,0.051-0.214,0.077-0.345,0.077
					c-0.155,0-0.286-0.037-0.392-0.11c-0.106-0.073-0.197-0.165-0.273-0.277c-0.076-0.112-0.179-0.277-0.31-0.494l-0.555-0.923
					c-0.199-0.339-0.377-0.597-0.534-0.774s-0.316-0.298-0.478-0.364S16.749,11.012,16.504,11.012z M17.296,8.14h-1.277v1.879h1.24
					c0.333,0,0.612-0.029,0.839-0.086c0.227-0.057,0.4-0.155,0.52-0.294c0.12-0.138,0.179-0.329,0.179-0.571
					c0-0.19-0.048-0.357-0.145-0.501c-0.096-0.145-0.23-0.253-0.401-0.324C18.09,8.174,17.771,8.14,17.296,8.14z"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="Layer_5"></g>
  </Icon>
);
