import Icon from '../Icon/Icon';

export const AimIcon = (props: any) => (
  <Icon viewbox="0 0 48 48" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1 24H6" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M24 41.9844V46.9984"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M42 24H47" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M24 1V6" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="24.0009" cy="24.0009" r="2.01459" />
  </Icon>
);

export default AimIcon;
