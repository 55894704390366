import Icon from '../Icon/Icon';

export const FootballSportIcon = (props: any) => (
  <Icon viewBox="0 0 28.3 28.3" fill="currentcolor" {...props}>
    <g>
      <g>
        <g>
          <path d="M16.7,5.2c-0.1,1.3-1.3,2.2-2.6,2.1S11.9,6,12,4.7s1.3-2.2,2.6-2.1S16.8,3.9,16.7,5.2z" />
        </g>
        <g>
          <circle cx="22.5" cy="23.4" r="2.4" />
        </g>
        <g>
          <path
            d="M10.2,14.8c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.5,1.4-2.5,0.6-5c-0.1,0-0.1,0.1-0.2,0.1c-0.6,0.5-0.7,2.4-0.5,3.6
				c0.1,0.6-0.3,1.2-0.8,1.3c-0.1,0-0.2,0-0.3,0c-0.6,0-1.1-0.4-1.2-1c0,0-0.1-1-0.1-2.1c0-1.7,0.5-2.9,1.3-3.6
				c0.6-0.5,1.6-1,3.2-0.5c0.5,0.1,0.9,0.4,1.1,0.9c0.3,0.9,0.5,1.8,0.5,2.6c0.1,0.2,0.3,0.4,0.5,0.5c0.6,0.3,1.7-0.1,1.9-0.2
				c0.6-0.3,1.3-0.1,1.6,0.4c0.3,0.6,0.1,1.3-0.4,1.6c-0.2,0.1-1.2,0.7-2.5,0.7c-0.4,0-0.9-0.1-1.3-0.2c-0.1,0.6-0.3,1.1-0.5,1.5
				c5.1,2.6,6,8.8,6,9.1c0.1,0.8-0.4,1.5-1.2,1.6c-0.1,0-0.1,0-0.2,0c-0.7,0-1.3-0.5-1.4-1.2c0,0-0.2-1.6-1-3.2
				c-0.9-1.8-2.1-3-3.6-3.8c-1,1.5-2.1,2.4-3.4,2.7c-0.3,0.1-0.5,0.1-0.8,0.1c-1.6,0-2.9-1-3.5-1.5c-0.1-0.1-0.1-0.1-0.2-0.1
				c-0.6-0.5-0.7-1.3-0.3-2c0.5-0.6,1.3-0.7,2-0.3c0.1,0.1,0.1,0.1,0.2,0.2c0.5,0.4,1.3,1,2,0.9c0.6-0.1,1.4-0.9,2-2.2L10.2,14.8z"
          />
        </g>
      </g>
    </g>
  </Icon>
);
