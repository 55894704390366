import Kit from './Kit';
import ShirtNumber from './ShirtNumber';

const TwoColorCollarInvertedArmsAndEdges = ({
  kitColor1 = '#f79025',
  kitColor2 = '#adadad',
  shirtNumber,
  shirtNumberColor,
  shirtNumberBackgroundColor,
  shirtNumberBackgroundOpacity,
  ...restProps
}: {
  size?: any;
  kitColor1: any;
  kitColor2: any;
  kitColor3: any;
  shirtNumber: any;
  shirtNumberColor: any;
  shirtNumberBackgroundColor: any;
  shirtNumberBackgroundOpacity: any;
}) => {
  const shadow = '#000000';

  return (
    <Kit {...restProps}>
      <g>
        <g>
          <path
            style={{ fill: kitColor1 }}
            d="M69.15,2.82c2.77,0,7.29-0.36,8.68-0.53c1.38-0.17,2.27,0.2,4.68,0.53l2.08,3.19
                    c0.92,0.53,11.11,2.66,12.85,3.37s4,1.77,5.38,1.94c1.39,0.19,3.47,0.72,4.86,1.24c1.38,0.54,4.69,3.02,5.9,4.8
                    c1.21,1.77,8.24,16.3,9.8,18.62c1.57,2.3,12.42,22.34,12.94,23.23c0.52,0.88,1.03,3.19,0.17,3.36c-0.87,0.19-8.33,3.37-11.46,6.03
                    c-3.13,2.67-9.03,5.68-9.71,6.03c-0.71,0.35-1.14,0.14-1.83-0.58c-0.69-0.7-7.73-12.01-7.73-12.01c-1.21,2.66-1.21,5.67-1.04,7.62
                    c0.18,1.96-0.87,11.8-0.35,14.63c0.52,2.84,0.52,30.33,0.87,32.82c0.35,2.48,0.86,7.44,0.69,9.93c-0.18,2.48,0,5.67,0,7.09
                    c0,1.43-0.18,2.67-1.56,3.2c-1.39,0.53-13.89,3.9-35.24,3.19c-21.35,0.71-33.85-2.66-35.23-3.19c-1.38-0.53-1.56-1.77-1.56-3.2
                    c0-1.41,0.18-4.61,0-7.09c-0.18-2.49,0.35-7.45,0.69-9.93c0.35-2.49,0.35-29.98,0.87-32.82c0.51-2.83-0.52-12.68-0.35-14.63
                    c0.18-1.95,0.18-4.96-1.04-7.62c0,0-7.3,11.53-7.99,12.24c-0.69,0.71-0.87,0.71-1.56,0.35c-0.7-0.35-6.59-3.36-9.73-6.03
                    c-3.12-2.66-10.59-5.84-11.45-6.03c-0.87-0.17-0.35-2.48,0.17-3.36C2.5,58.33,13.43,38.47,15,36.16c1.56-2.32,8.5-17.04,9.72-18.8
                    c1.22-1.78,4.51-4.26,5.91-4.8c1.39-0.53,3.46-1.05,4.86-1.24c1.39-0.17,3.63-1.28,5.38-1.94c1.97-0.74,10.83-2.9,12.84-3.37
                    l2.08-3.19c0.87-0.35,3.3-0.71,4.69-0.53C61.86,2.46,66.38,2.82,69.15,2.82z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M136.09,58.66c-6.16-12.32-13.51-24.08-19.67-36.4c-3.32-6.64-5.99-9.99-13.89-11.17
                    c-2.93-0.44-4.39-1.94-8.58-2.89c-3.09-0.7-5.76-1.9-8.85-2.64l-1.98-3.32l-4.43-0.83l-9.52,0.83L59.4,1.41l-4.14,0.83l-1.78,3.32
                    c-3.08,0.72-5.81,1.67-9.19,2.6c-4.63,1.26-6.27,2.76-9.04,3.2c-5.65,0.9-8.33,2.68-10.58,6.07c-0.9,1.35-1.66,2.99-2.61,4.87
                    C15.9,34.64,8.13,46.89,1.97,59.21c-0.33,0.58-1.13,2.42-0.48,3.09c0.01,0.01,0.03,0.03,0.04,0.03c-0.37-0.64,0.05-2.38,0.48-3.12
                    c0.11-0.19,0.46-0.92,0.46-0.92s0.15,1.53,1,1.71c0.87,0.17,8.34,3.36,11.46,6.03c3.13,2.65,9.03,5.68,9.71,6.03
                    c0.6,0.31,1.29,0.01,1.46-0.07c3.28-4.93,5.96-9.19,6.37-9.84c0.2,0.35,0.38,0.78,0.51,1.13c0,0.03,0,0.04,0.01,0.05
                    c0.17,0.7,0.39,1.73,0.51,3.17c0.2,2.28-0.15,2.91,0.19,7.69c0.17,2.37,0.25,3.55,0.28,5.41c0.03,1.59-0.29,6.88-0.47,13.29
                    c-0.25,9.9-0.44,22.03-0.66,24.15c-0.23,2.24-0.4,4.49-0.52,6.74c-0.11,1.9-0.24,3.82-0.17,5.72c0.04,1.53,0,3.07,0.08,4.59
                    c0.05,1.07,0.08,2.57,1.17,3.16c1.43,0.79,14.38,3.99,35.75,3.29c3.39,0.11,6.78,0.13,10.17,0.05c2.87-0.07,5.75-0.21,8.6-0.44
                    c2.33-0.19,4.65-0.43,6.96-0.75c1.74-0.24,3.5-0.52,5.22-0.87c1.38-0.28,2.76-0.6,4.11-1.03c0.33-0.11,0.67-0.24,0.94-0.5
                    c0.62-0.58,0.78-1.57,0.87-2.37c0.09-0.98,0.08-1.97,0.11-2.96c0.13-5.49-0.35-10.34-0.82-14.02c-0.36-2.88-0.59-30.64-0.76-35
                    c-0.04-0.9-0.19-1.29-0.21-1.98c-0.07-1.78,0.44-5.75,0.56-8.64c0.09-2.1-0.23-3.11-0.09-4.9c0.07-0.95,0.86-4.58,0.95-5.04
                    l0.01-0.04c0,0,1.71,2.8,4.08,6.5c0.78,1.22,1.51,2.36,2.32,3.6c0.28,0.11,0.82,0.25,1.31,0.01c0.68-0.36,6.58-3.37,9.71-6.04
                    c3.12-2.65,10.81-5.96,11.67-6.15c0.87-0.17,1-1.7,1-1.7s0.36,0.74,0.47,0.92c0.04,0.07,0.08,0.15,0.12,0.23
                    C136.32,59.09,136.17,58.81,136.09,58.66z M60.47,5.16c1.39,0.17,5.81,0.54,8.58,0.54c2.79,0,7.21-0.36,8.6-0.54
                    c1.26-0.16,4.02,0.25,5.2,0.59c-0.35,1.92-1.7,4.12-4.15,5.28c-4.03,1.85-9.64,6.13-9.64,6.13s-5.6-4.29-9.63-6.13
                    c-2.52-1.18-3.87-3.47-4.18-5.42C56.52,5.28,59.27,5,60.47,5.16z M103.93,64.06c-0.01,0.01-0.03,0.03-0.03,0.03
                    c-0.82,0.95-1.62,2.2-1.81,3.79c-0.31,4.26-0.92,9.99-0.67,12.91c0.35,4.03,0.55,33.36,0.91,36.86c0.35,3.5,0.84,10.46,0.58,13.94
                    c-0.04,0.44-0.07,0.88-0.08,1.31c-4.14,1.07-15.8,3.32-33.68,2.73c-18.03,0.6-29.73-1.7-33.79-2.76c-0.03-0.52-0.07-1.04-0.11-1.58
                    c-0.27-3.48,0.24-10.45,0.59-13.94c0.35-3.5,0.48-31.73,0.7-35.77c0.2-3.79-0.51-10.73-0.62-13.98c-0.05-1.54-0.82-2.88-1.61-3.84
                    l-0.01-0.01c-0.25-0.7-1.07-1.61-1.77-2.29c0.01-2.79,0.04-14.41,0.08-19.81c0.04-5.62,0.64-17.8,4.26-21.17
                    c4.3-4,8.53-7.12,15.37-8.92c0.36-0.09,0.72-0.17,1.1-0.25c0.31,2.48,1.15,5.2,2.91,6.91c0.13,0.12,4.08-3.83,4.08-3.83
                    s7.85,3.91,8.76,4.18c0.91-0.27,9.09-4.37,9.09-4.37s2.6,3.96,2.76,3.84c2-1.63,3.11-4.43,3.58-6.99c0.46,0.09,0.91,0.2,1.37,0.32
                    c6.86,1.79,11.08,4.92,15.37,8.92c3.63,3.37,4.23,15.55,4.26,21.17c0.04,5.37,0.19,17.04,0.23,19.93
                    C104.91,62.48,104.07,63.83,103.93,64.06z"
          />
          <g>
            <path
              style={{ fill: shadow }}
              d="M82.85,5.75c-0.35,1.92-1.7,4.12-4.15,5.28c-4.03,1.85-9.64,6.13-9.64,6.13s-5.6-4.29-9.63-6.13
                        c-2.52-1.18-3.87-3.47-4.18-5.42c1.27-0.32,4.02-0.6,5.22-0.44c1.39,0.17,5.81,0.54,8.58,0.54c2.79,0,7.21-0.36,8.6-0.54
                        C78.91,5,81.67,5.41,82.85,5.75z"
            />
            <path
              opacity="0.16"
              d="M136.63,62.51c0.86-0.18,0.35-2.48-0.17-3.37c-0.3-0.51-3.93-7.08-7.31-13.12
                        c-6.25,2.94-14.52-0.07-23.84-13.57l0.34,9.94l0.12,19.66c0,0,7.03,11.31,7.73,12.01c0.69,0.71,1.25,0.86,1.95,0.51
                        c0.68-0.36,6.58-3.37,9.71-6.03C128.3,65.88,135.76,62.69,136.63,62.51z"
            />
            <path
              opacity="0.16"
              d="M1.39,62.64c-0.86-0.18-0.34-2.48,0.18-3.37c0.29-0.51,3.92-7.08,7.31-13.12
                        c6.25,2.94,15.6-0.46,24.23-14.08L32.54,45.6l-0.01,16.45c0,0-7.58,11.94-8.27,12.64c-0.7,0.71-1,0.35-1.69,0.01
                        c-0.69-0.36-6.59-3.37-9.72-6.03C9.73,66.01,2.26,62.82,1.39,62.64z"
            />
            <path
              opacity="0.16"
              d="M104.16,137.92c1.38-0.53,1.56-1.77,1.56-3.19c0-1.42-0.18-4.61,0-7.09c0.16-2.3-0.27-6.7-0.6-9.32
                        c-5.17,4.71-8.97,14.72-10.63,21.6C100.16,139.11,103.48,138.18,104.16,137.92z"
            />
            <path
              opacity="0.16"
              d="M33.43,137.86c-1.38-0.53-1.56-1.77-1.56-3.19c0-1.42,0.18-4.61,0-7.09c-0.15-2.3,0.27-6.7,0.61-9.32
                        c5.16,4.71,8.96,14.72,10.63,21.6C37.44,139.05,34.11,138.12,33.43,137.86z"
            />
            <g>
              <g>
                <path
                  d="M59.63,2.24c0.3,0,0.59,0.01,0.84,0.05c1.39,0.18,5.91,0.53,8.68,0.53s7.29-0.36,8.68-0.53
                                c0.24-0.03,0.46-0.04,0.68-0.04c1.05,0,2.01,0.3,4,0.58l2.08,3.19c0.92,0.53,11.11,2.66,12.85,3.37c1.74,0.71,4,1.77,5.38,1.94
                                c1.39,0.19,3.47,0.72,4.86,1.24c1.38,0.54,4.69,3.02,5.9,4.8c1.21,1.77,8.24,16.3,9.8,18.62c1.57,2.3,12.42,22.34,12.94,23.23
                                c0.52,0.88,1.03,3.19,0.17,3.36c-0.87,0.19-8.33,3.37-11.46,6.03c-3.13,2.67-9.03,5.68-9.71,6.03
                                c-0.22,0.11-0.42,0.17-0.61,0.17c-0.4,0-0.75-0.25-1.22-0.74c-0.69-0.7-7.73-12.01-7.73-12.01c-1.21,2.66-1.21,5.67-1.04,7.62
                                c0.18,1.96-0.87,11.8-0.35,14.63c0.52,2.84,0.52,30.33,0.87,32.82c0.35,2.48,0.86,7.44,0.69,9.93c-0.18,2.48,0,5.67,0,7.09
                                c0,1.43-0.18,2.67-1.56,3.2c-1.26,0.48-11.64,3.29-29.44,3.29c-1.85,0-3.78-0.03-5.8-0.1c-2.01,0.07-3.94,0.1-5.8,0.1
                                c-17.81,0-28.18-2.81-29.44-3.29c-1.38-0.53-1.56-1.77-1.56-3.2c0-1.41,0.18-4.61,0-7.09c-0.18-2.49,0.35-7.45,0.69-9.93
                                c0.35-2.49,0.35-29.98,0.87-32.82c0.51-2.83-0.52-12.68-0.35-14.63c0.18-1.95,0.18-4.96-1.04-7.62c0,0-7.3,11.53-7.99,12.24
                                c-0.4,0.42-0.63,0.59-0.89,0.59c-0.18,0-0.38-0.09-0.67-0.23c-0.7-0.35-6.59-3.36-9.73-6.03c-3.12-2.66-10.59-5.84-11.45-6.03
                                c-0.87-0.17-0.35-2.48,0.17-3.36C2.5,58.33,13.43,38.47,15,36.16c1.56-2.32,8.5-17.04,9.72-18.8c1.22-1.78,4.51-4.26,5.91-4.8
                                c1.39-0.53,3.46-1.05,4.86-1.24c1.39-0.17,3.63-1.28,5.38-1.94c1.97-0.74,10.83-2.9,12.84-3.37l2.08-3.19
                                C56.5,2.53,58.26,2.24,59.63,2.24 M59.63,1.24L59.63,1.24c-1.44,0-3.34,0.29-4.22,0.65c-0.19,0.08-0.35,0.21-0.46,0.38
                                l-1.86,2.86C50.73,5.69,42.49,7.7,40.51,8.44c-0.54,0.2-1.13,0.45-1.7,0.69c-1.22,0.51-2.61,1.09-3.45,1.2
                                c-1.52,0.21-3.66,0.76-5.09,1.3c-1.65,0.64-5.1,3.31-6.38,5.17c-0.62,0.9-2.42,4.52-4.5,8.72c-2.08,4.19-4.44,8.93-5.22,10.09
                                C12.96,37.39,6.48,49.06,3,55.33c-1.03,1.85-1.77,3.18-1.89,3.39c-0.45,0.78-1.11,2.8-0.57,3.97c0.21,0.47,0.6,0.78,1.08,0.87
                                c0.67,0.16,7.95,3.22,10.99,5.81c3.22,2.75,9.25,5.82,9.93,6.17c0.35,0.18,0.7,0.34,1.12,0.34c0.69,0,1.15-0.42,1.61-0.89
                                c0.59-0.6,4.68-6.98,7-10.64c0.46,1.89,0.44,3.81,0.31,5.24c-0.08,0.85,0.04,2.8,0.19,5.27c0.2,3.42,0.46,7.69,0.17,9.28
                                c-0.28,1.54-0.41,8.92-0.55,19.19c-0.09,6.21-0.18,12.63-0.32,13.67c-0.38,2.7-0.88,7.63-0.7,10.13
                                c0.12,1.74,0.07,3.87,0.03,5.44c-0.02,0.63-0.03,1.18-0.03,1.58c0,1.07,0,3.29,2.2,4.13c1.75,0.67,12.53,3.36,29.8,3.36
                                c1.9,0,3.85-0.03,5.8-0.1c1.94,0.06,3.89,0.1,5.8,0.1c17.24,0,28.05-2.69,29.8-3.35c2.2-0.84,2.2-3.07,2.2-4.13
                                c0-0.4-0.01-0.95-0.03-1.58c-0.04-1.56-0.1-3.7,0.03-5.44c0.17-2.52-0.32-7.44-0.7-10.13c-0.15-1.05-0.24-7.48-0.32-13.7
                                c-0.14-10.25-0.27-17.62-0.55-19.16c-0.3-1.6-0.04-5.89,0.17-9.33c0.15-2.44,0.26-4.37,0.19-5.21
                                c-0.13-1.43-0.15-3.34,0.31-5.22c2.25,3.6,6.16,9.8,6.75,10.4c0.52,0.54,1.12,1.04,1.93,1.04c0.34,0,0.69-0.09,1.05-0.27
                                l0.08-0.04c2.02-1.04,7.01-3.72,9.84-6.13c2.95-2.5,10.12-5.6,11.02-5.81c0.46-0.09,0.84-0.4,1.05-0.87
                                c0.51-1.13-0.09-3.15-0.57-3.97c-0.12-0.21-0.94-1.71-1.98-3.61c-3.42-6.26-9.79-17.91-11-19.68c-0.78-1.16-3.16-5.85-5.26-9.99
                                c-2.11-4.15-3.93-7.74-4.54-8.64c-1.36-1.99-4.88-4.58-6.36-5.16c-1.45-0.55-3.58-1.09-5.09-1.3c-0.97-0.12-2.58-0.8-4-1.4
                                c-0.4-0.17-0.78-0.33-1.14-0.48c-0.93-0.38-3.76-1.05-6.75-1.77c-2.12-0.51-4.96-1.18-5.8-1.48l-1.92-2.94
                                c-0.16-0.24-0.41-0.41-0.7-0.44c-0.79-0.11-1.41-0.22-1.96-0.32c-0.82-0.15-1.47-0.27-2.18-0.27c-0.27,0-0.53,0.02-0.8,0.05
                                c-1.45,0.18-5.91,0.53-8.55,0.53c-2.64,0-7.13-0.34-8.55-0.53C60.31,1.25,59.99,1.24,59.63,1.24L59.63,1.24z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <ShirtNumber
        shirtNumber={shirtNumber}
        shirtNumberColor={shirtNumberColor}
        shirtNumberBackgroundColor={shirtNumberBackgroundColor}
        shirtNumberBackgroundOpacity={shirtNumberBackgroundOpacity}
      />
    </Kit>
  );
};

export default TwoColorCollarInvertedArmsAndEdges;
