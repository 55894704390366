const Field = ({
  backgroundColor,
  lineColor,
  stripeColor,
}: {
  backgroundColor: string;
  lineColor: string;
  stripeColor: string;
}) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 425.8 626"
      // style={{ enableBackground: 'new 0 0 425.8 626' }}
      xmlSpace="preserve"
    >
      <style type="text/css">
        {`
    .st0{fill:${backgroundColor};}
    .st1{fill:none;}
    .st2{clip-path:url(#SVGID_4_);fill:${stripeColor};}
    .st3{clip-path:url(#SVGID_6_);fill:${stripeColor};}
    .st4{clip-path:url(#SVGID_8_);fill:${stripeColor};}
    .st5{clip-path:url(#SVGID_10_);fill:${stripeColor};}
    .st6{clip-path:url(#SVGID_12_);fill:${stripeColor};}
    .st7{clip-path:url(#SVGID_14_);fill:${stripeColor};}
    .st8{clip-path:url(#SVGID_16_);fill:${stripeColor};}
    .st9{clip-path:url(#SVGID_18_);fill:${stripeColor};}
    .st10{clip-path:url(#SVGID_20_);fill:${stripeColor};}
    .st11{clip-path:url(#SVGID_22_);fill:none;stroke:${lineColor};stroke-width:2.766;stroke-miterlimit:10;}
    .st12{clip-path:url(#SVGID_24_);fill:none;stroke:${lineColor};stroke-width:2.766;stroke-miterlimit:10;}
    .st13{clip-path:url(#SVGID_28_);fill:none;stroke:${lineColor};stroke-width:2.766;stroke-miterlimit:10;}
    .st14{clip-path:url(#SVGID_32_);fill:none;stroke:${lineColor};stroke-width:2.766;stroke-miterlimit:10;}
    .st15{clip-path:url(#SVGID_34_);fill:none;stroke:${lineColor};stroke-width:2.766;stroke-miterlimit:10;}
    .st16{clip-path:url(#SVGID_36_);fill:${lineColor};}
    .st17{clip-path:url(#SVGID_38_);fill:none;stroke:${lineColor};stroke-width:2.766;stroke-miterlimit:10;}
    .st18{clip-path:url(#SVGID_40_);fill:none;stroke:${lineColor};stroke-width:2.766;stroke-miterlimit:10;}
    .st19{clip-path:url(#SVGID_42_);fill:none;stroke:${lineColor};stroke-width:2.766;stroke-miterlimit:10;}
    .st20{fill:${lineColor};}
    .st21{fill:none;stroke:${lineColor};stroke-width:2.7661;stroke-miterlimit:10.0003;}
    .st22{fill:none;stroke:${lineColor};stroke-width:2.766;stroke-miterlimit:10;}`}
      </style>
      <g id="Layer_1">
        <g>
          <g>
            <rect className="st0" width="425.8" height="626" />
          </g>
        </g>
      </g>
      <g id="Layer_2">
        <g>
          <defs>
            <rect
              id="SVGID_1_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
          </clipPath>
        </g>
        <rect
          x="-127.1"
          y="-150.9"
          className="st1"
          width="1255.1"
          height="841.9"
        />
        <g>
          <defs>
            <rect
              id="SVGID_3_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_4_">
            <use xlinkHref="#SVGID_3_" style={{ overflow: 'visible' }} />
          </clipPath>
          <rect className="st2" width="425.8" height="36.9" />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_5_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_6_">
            <use xlinkHref="#SVGID_5_" style={{ overflow: 'visible' }} />
          </clipPath>
          <rect y="73.7" className="st3" width="425.8" height="36.9" />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_7_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_8_">
            <use xlinkHref="#SVGID_7_" style={{ overflow: 'visible' }} />
          </clipPath>
          <rect y="147.5" className="st4" width="425.8" height="36.9" />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_9_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_10_">
            <use xlinkHref="#SVGID_9_" style={{ overflow: 'visible' }} />
          </clipPath>
          <rect y="221.2" className="st5" width="425.8" height="36.9" />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_11_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_12_">
            <use xlinkHref="#SVGID_11_" style={{ overflow: 'visible' }} />
          </clipPath>
          <rect y="294.9" className="st6" width="425.8" height="36.9" />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_13_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_14_">
            <use xlinkHref="#SVGID_13_" style={{ overflow: 'visible' }} />
          </clipPath>
          <rect y="368.6" className="st7" width="425.8" height="36.9" />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_15_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_16_">
            <use xlinkHref="#SVGID_15_" style={{ overflow: 'visible' }} />
          </clipPath>
          <rect y="442.4" className="st8" width="425.8" height="36.9" />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_17_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_18_">
            <use xlinkHref="#SVGID_17_" style={{ overflow: 'visible' }} />
          </clipPath>
          <rect y="516.1" className="st9" width="425.8" height="36.9" />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_19_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_20_">
            <use xlinkHref="#SVGID_19_" style={{ overflow: 'visible' }} />
          </clipPath>
          <rect y="589.1" className="st10" width="425.8" height="36.9" />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_21_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_22_">
            <use xlinkHref="#SVGID_21_" style={{ overflow: 'visible' }} />
          </clipPath>
          <polyline
            className="st11"
            points="11.1,0 11.1,614.9 414.9,614.9 414.9,0 		"
          />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_23_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_24_">
            <use xlinkHref="#SVGID_23_" style={{ overflow: 'visible' }} />
          </clipPath>
          <line className="st12" x1="11.1" y1="221.2" x2="414.9" y2="221.2" />
        </g>
        <rect
          x="-127.1"
          y="-150.9"
          className="st1"
          width="1255.1"
          height="841.9"
        />
        <g>
          <defs>
            <rect
              id="SVGID_25_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_26_">
            <use xlinkHref="#SVGID_25_" style={{ overflow: 'visible' }} />
          </clipPath>
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_27_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_28_">
            <use xlinkHref="#SVGID_27_" style={{ overflow: 'visible' }} />
          </clipPath>
          <path
            className="st13"
            d="M168.1,511.6c11.3-11.9,27.2-19.3,44.9-19.3c17.8,0,33.8,7.5,45.1,19.5"
          />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_29_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_30_">
            <use xlinkHref="#SVGID_29_" style={{ overflow: 'visible' }} />
          </clipPath>
        </g>

        <rect
          x="-129.9"
          y="-262.5"
          transform="matrix(0.9239 -0.3827 0.3827 0.9239 -22.759 202.5279)"
          className="st1"
          width="1255.2"
          height="841.9"
        />
        <g>
          <defs>
            <rect
              id="SVGID_31_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_32_">
            <use xlinkHref="#SVGID_31_" style={{ overflow: 'visible' }} />
          </clipPath>
          <polyline
            className="st14"
            points="82.6,614.7 82.6,511.5 344.2,511.5 344.2,614.1 		"
          />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_33_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_34_">
            <use xlinkHref="#SVGID_33_" style={{ overflow: 'visible' }} />
          </clipPath>
          <polyline
            className="st15"
            points="151.8,614.7 151.8,579.5 274,579.5 274,613.6 		"
          />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_35_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_36_">
            <use xlinkHref="#SVGID_35_" style={{ overflow: 'visible' }} />
          </clipPath>
          <path
            className="st16"
            d="M216.2,542.6c0,1.7-1.4,3.1-3.1,3.1c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1
			C214.8,539.4,216.2,540.8,216.2,542.6"
          />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_37_"
              x="-132.6"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_38_">
            <use xlinkHref="#SVGID_37_" style={{ overflow: 'visible' }} />
          </clipPath>
          <circle className="st17" cx="213" cy="542.6" r="3.1" />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_39_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_40_">
            <use xlinkHref="#SVGID_39_" style={{ overflow: 'visible' }} />
          </clipPath>
          <path className="st18" d="M11.1,605.2c5.8,0,10.5,4.3,10.5,9.7" />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_41_"
              x="-127.1"
              y="-150.9"
              width="1255.1"
              height="841.9"
            />
          </defs>
          <clipPath id="SVGID_42_">
            <use xlinkHref="#SVGID_41_" style={{ overflow: 'visible' }} />
          </clipPath>
          <path className="st19" d="M415.3,605.2c-5.8,0-10.5,4.3-10.5,9.7" />
        </g>
        <g>
          <path
            className="st20"
            d="M215.9,221.2c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9c0-1.6,1.3-2.9,2.9-2.9
			C214.6,218.3,215.9,219.6,215.9,221.2"
          />
          <ellipse className="st21" cx="213" cy="221.2" rx="3.1" ry="3.1" />
        </g>
        <path
          className="st22"
          d="M270.7,221.2c0,31.9-25.8,57.7-57.7,57.7c-31.9,0-57.7-25.8-57.7-57.7s25.8-57.7,57.7-57.7
		C244.9,163.5,270.7,189.3,270.7,221.2z"
        />
      </g>
    </svg>
  );
};

export default Field;
