import { NrkRadioIcon, NrkTvIcon } from '../../icons';

import styled from 'styled-components';

export const Theme = styled.div``;

export const Button = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  /* margin: 0 auto var(--ntb-space-2) auto; */
  cursor: pointer;
  font-size: var(--ntb-fontSizes-xs);
  color: var(--color);
  background: var(--background-color);
  border-radius: var(--ntb-radii-md);

  transition: background 300ms;
  transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);

  &:hover {
    color: var(--color__hover);
    background: var(--background-color__hover);

    svg {
      fill: var(--color__hover);
    }
  }
`;

export const VideoButton = styled(Button)`
  display: flex;
  align-items: center;
  height: 34px;
  column-gap: var(--ntb-space-1);
  padding: var(--ntb-space-2) var(--ntb-space-4);
`;
export const RadioButton = styled(Button)`
  padding: 0;
  height: 34px;
  align-items: stretch;
`;
export const RadioButtonContent = styled.div`
  display: flex;
  align-items: center;

  column-gap: var(--ntb-space-1);
  padding: var(--ntb-space-1) var(--ntb-space-4) var(--ntb-space-1)
    var(--ntb-space-4);
`;

export const RadioButtonIcon = styled.div`
  display: flex;
  align-items: center;
  padding: var(--ntb-space-1) var(--ntb-space-2);
  //border-left: 1px solid;
  //border-color: var(--icon-border-color);
  background: var(--icon-background-color);
  border-radius: 0 var(--ntb-radii-md) var(--ntb-radii-md) 0;
`;

export const StreamLogo = styled(NrkTvIcon)`
  width: 40px;
  /* margin-left: var(--ntb-space-0-5); */

  fill: var(--icon-color);
`;

export const RadioLogo = styled(NrkRadioIcon)`
  width: 54px;
  /* margin-left: var(--ntb-space-1); */

  fill: var(--icon-color);
`;
