import { SporteditRecordsType } from '@ntb-sport/types';
import './records.css';
import { useBem } from '@ntb-sport/hooks';

interface RecordsProps {
  records: any;
}

interface RecordProps {
  record: SporteditRecordsType;
}

const Record = ({ record }: RecordProps) => {
  const bem = useBem('scope-results');
  return (
    <li className={bem('record')}>
      {`${record?.equalsCurrentRecord ? '=' : ''}${
        record?.recordType?.abbreviation
      }`}
    </li>
  );
};

export const Records = ({ records }: RecordsProps) => {
  const bem = useBem('scope-results');
  const displayRecords = records?.length > 0;

  if (!displayRecords) return null;

  return (
    <ul className={bem('records')}>
      {records?.map((record: any) => (
        <Record key={record?.recordType?.uuid} record={record} />
      ))}
    </ul>
  );
};

export default Records;
