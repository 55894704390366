import styled from 'styled-components';

export const Link = styled.a`
  background: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: var(--ntb-space-1);
  padding: var(--ntb-space-2);
`;
