import Icon from '../Icon/Icon';

export const CrossCountrySkiingSportIcon = (props: any) => (
  <Icon viewBox="0 0 28.3 28.3" {...props}>
    <g>
      <g>
        <path
          d="M24.5,21h-5.9c0-0.1,0-0.2,0-0.3c-0.2-1.9-1.6-5.4-5-6.3c0.1-1.1,0.5-2.1,1.2-3.1c1,1.3,2.5,2.5,4.3,2.5
			c0.3,0,0.6,0,1-0.1v6.5c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3-0.2,0.3-0.3v-6.7c0,0,0.1,0,0.1-0.1c0.6-0.3,0.8-1,0.6-1.5
			c-0.3-0.6-1-0.8-1.5-0.6c-2.2,1-4.1-2.6-4.1-2.6c-0.2-0.4-0.7-0.7-1.2-0.7c-0.1,0-0.1,0-0.2,0c-3.3,0.3-5.2,2-6.1,3.3
			c-0.5,0.7-0.8,1.3-1,1.8L3.6,9.7c-0.1-0.1-0.4-0.1-0.5,0C3,9.8,3,10,3.2,10.2l4,3.8c0,0.2-0.1,0.3-0.1,0.3c-0.1,0.6,0.3,1.2,1,1.3
			c0.1,0,0.1,0,0.2,0c0.6,0,1.1-0.4,1.2-1c0,0,0.3-1.9,2.2-3.2c-0.9,2-1,4-0.5,6.4c0,0.1,0,0.2,0,0.4c0,1-1,2.1-1.8,2.8H7.3
			c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h0.6c-0.1,0.3-0.1,0.7,0.1,1.1h-3c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7
			h17.3c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H10.7c0.3-0.3,0.8-0.6,1.2-1.1h12.6c0.4,0,0.7-0.3,0.7-0.7
			C25.2,21.4,24.9,21,24.5,21z M13,21c0.6-1,1-2.2,0.8-3.5c1.5,1,1.9,3.2,2,3.5H13z"
        />
        <path d="M14.5,5.7c0-1.3,1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4C15.5,8.1,14.5,7.1,14.5,5.7z" />
      </g>
    </g>
  </Icon>
);
