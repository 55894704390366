import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '@ntb-sport/constants';
import { fetchScopes, FetchScopesType } from './fetch';
import { getIsNeoSportGroup } from '@ntb-sport/helpers';
import {
  SocketContext,
  SocketContextType,
  useSportEditSocketHandleRooms,
} from '@ntb-sport/socket';
import { SPORT_GROUP } from '@ntb-sport/constants';
import { useContext } from 'react';

interface UseQueryScopesParams extends FetchScopesType {
  sportGroup: string;
}

interface UseQueryScopes {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: UseQueryScopesParams;
  options?: Record<string, unknown>;
  enableSocket?: boolean;
}

export const useQueryScopes = ({
  queryKey = QUERY_KEYS.SCOPES,
  queryKeyParams,
  params,
  options,
  enableSocket = false,
}: UseQueryScopes) => {
  const isSportEditSportGroup = getIsNeoSportGroup(params?.sportGroup);
  const { whitelistEvents } = useContext<SocketContextType>(SocketContext);

  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchScopes(params),
    enabled:
      isSportEditSportGroup &&
      Boolean(params.customerUUID) &&
      Boolean(params.token) &&
      Boolean(params.eventUUID),
    ...options,
  });

  useSportEditSocketHandleRooms({
    enabled:
      enableSocket &&
      Boolean(params.eventUUID) &&
      (params.sportGroup === SPORT_GROUP.CYCLING ||
        Boolean(
          whitelistEvents?.find(
            (event: { uuid: string }) => event.uuid === params.eventUUID,
          ),
        )),
    roomId: `/events/${params.eventUUID}/scopes`,
  });

  return { data, isLoading, error };
};
