import { Fetch, API_VERSION } from '../../fetch';

export interface FetchEvents {
  apiBaseUrl: string;
  version?: string;
  date: string;
  token: string;
  customerUUID: string;
}

export const fetchEvents = async ({
  apiBaseUrl,
  version = API_VERSION.V7,
  date,
  token,
  customerUUID,
}: FetchEvents) => {
  const url = `${apiBaseUrl}/${version}/events?date=${date}`;

  const json = await Fetch({
    url,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });

  return json.data;
};
