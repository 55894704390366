import { SPORT_GROUP } from '@ntb-sport/constants';
import { Fetch, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchTournamentType {
  apiBaseUrl: string;
  sportGroup: string;
  tournamentId: string | number;
}

export const fetchTournament = async ({
  apiBaseUrl,
  sportGroup,
  tournamentId,
}: FetchTournamentType) => {
  const competitionPath =
    sportGroup === SPORT_GROUP.ICE_HOCKEY || sportGroup === SPORT_GROUP.HANDBALL
      ? 'nifTournaments'
      : 'tournaments';

  const url = `${apiBaseUrl}/${competitionPath}/${tournamentId}/`;

  const json = await Fetch({
    url: `${url}`,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });

  return json;
};
