import { NORWEGIAN_SPORT_GROUP_TO_ENGLISH } from '@ntb-sport/constants';
import { SportGroup } from '@ntb-sport/types';

export const getEnglishSportGroupFromUrl = (sportgroup: string | string[]) => {
  const englishSportGroup =
    NORWEGIAN_SPORT_GROUP_TO_ENGLISH[
      sportgroup as keyof typeof NORWEGIAN_SPORT_GROUP_TO_ENGLISH
    ];

  return englishSportGroup as SportGroup;
};

export const checkIfAllowedToShowLiveReport = (
  liveFeed?: any,
  nifsCustomerId?: number,
): boolean => {
  if (!liveFeed || !nifsCustomerId) {
    return false;
  }

  return (
    !liveFeed?.allowedCustomers?.length ||
    Boolean(
      liveFeed.allowedCustomers.find(
        (customer: { id: number }) => customer.id === nifsCustomerId,
      ),
    )
  );
};
