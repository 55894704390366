import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import * as S from './TeamMatchStatistics.styled';
import { MatchesType } from '@ntb-sport/types';

import { InfoIcon } from '@chakra-ui/icons';
import { Tooltip } from '../../chakra';

const getColor = (percentage: number) => {
  if (percentage <= 25) {
    return {
      backgroundColor: 'var(--base-colors-error)',
      fontColor: 'var(--base-colors-blackAlpha-900)',
    };
  } else if (percentage <= 49) {
    return {
      backgroundColor: 'var(--base-colors-error-light)',
      fontColor: 'var(--base-colors-blackAlpha-900)',
    };
  } else if (percentage === 50) {
    return {
      backgroundColor: 'var(--background-color)',
      fontColor: 'var(--font-color)',
    };
  } else if (percentage <= 74) {
    return {
      backgroundColor: 'var(--base-colors-success-light)',
      fontColor: 'var(--base-colors-blackAlpha-900)',
    };
  } else if (percentage <= 100) {
    return {
      backgroundColor: 'var(--base-colors-success)',
      fontColor: 'var(--base-colors-blackAlpha-900)',
    };
  } else {
    return {
      backgroundColor: '',
      fontColor: '',
    };
  }
};

const getDefaultColor = () => {
  return {
    backgroundColor: 'var(--background-color)',
    fontColor: 'var(--font-color)',
  };
};

export const TeamMatchStatistics = ({
  homeTeamStatistics,
  awayTeamStatistics,
  matches,
  tournamentId,
}: {
  homeTeamStatistics?: any;
  awayTeamStatistics?: any;
  matches: MatchesType;
  tournamentId: number;
}) => {
  if (!homeTeamStatistics || !awayTeamStatistics) return null;

  const displayPossesion =
    Object.prototype.hasOwnProperty.call(homeTeamStatistics, 'possession') &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'possession');

  const displayXG =
    Object.prototype.hasOwnProperty.call(homeTeamStatistics, 'xg') &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'xg');

  const displayTotalShots =
    Object.prototype.hasOwnProperty.call(homeTeamStatistics, 'totalShots') &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'totalShots');

  const displayShotsOnGoal =
    Object.prototype.hasOwnProperty.call(homeTeamStatistics, 'shotsOnGoal') &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'shotsOnGoal');

  const displayBlockedShots =
    Object.prototype.hasOwnProperty.call(homeTeamStatistics, 'blockedShots') &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'blockedShots');

  const displayPostHits =
    Object.prototype.hasOwnProperty.call(homeTeamStatistics, 'postHits') &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'postHits');

  const displayChances =
    Object.prototype.hasOwnProperty.call(homeTeamStatistics, 'chances') &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'chances');

  const displaySaves =
    Object.prototype.hasOwnProperty.call(homeTeamStatistics, 'saves') &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'saves');

  const displayYellowCards =
    Object.prototype.hasOwnProperty.call(homeTeamStatistics, 'yellowCards') &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'yellowCards');

  const displayRedCards =
    Object.prototype.hasOwnProperty.call(homeTeamStatistics, 'redCards') &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'redCards');

  const displayFoulsCommitted =
    Object.prototype.hasOwnProperty.call(
      homeTeamStatistics,
      'foulsCommitted',
    ) &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'foulsCommitted');

  const displayCorners =
    Object.prototype.hasOwnProperty.call(homeTeamStatistics, 'corners') &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'corners');

  const displayFreeKicks =
    Object.prototype.hasOwnProperty.call(homeTeamStatistics, 'freeKicks') &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'freeKicks');

  const displayGoalKicks =
    Object.prototype.hasOwnProperty.call(homeTeamStatistics, 'goalKicks') &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'goalKicks');

  const displayThrowIns =
    Object.prototype.hasOwnProperty.call(homeTeamStatistics, 'throwIns') &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'throwIns');

  const displayOffsides =
    Object.prototype.hasOwnProperty.call(homeTeamStatistics, 'offsides') &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'offsides');

  const displayCrosses =
    Object.prototype.hasOwnProperty.call(homeTeamStatistics, 'crosses') &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'crosses');

  const displayCounterAttacks =
    Object.prototype.hasOwnProperty.call(
      homeTeamStatistics,
      'counterAttacks',
    ) &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'counterAttacks');

  const displayTreatments =
    Object.prototype.hasOwnProperty.call(homeTeamStatistics, 'treatments') &&
    Object.prototype.hasOwnProperty.call(awayTeamStatistics, 'treatments');

  return (
    <div
      data-component-id={COMPONENT_IDS.TEAM_MATCH_STATISTICS}
      data-tournament-id={tournamentId}
    >
      <S.Container>
        <S.MatchStatistics>
          <S.Groups $matches={matches}>
            {displayPossesion && (
              <S.Group $area="possession">
                <Statistic
                  text="Ballbesittelse"
                  suffix="%"
                  homeTeamStatistics={homeTeamStatistics.possession}
                  awayTeamStatistics={awayTeamStatistics.possession}
                />
              </S.Group>
            )}

            {(displayTotalShots ||
              displayShotsOnGoal ||
              displayChances ||
              displayBlockedShots ||
              displayPostHits ||
              displaySaves) && (
              <S.Group $area="finishes">
                <S.GroupHeader>Avslutninger</S.GroupHeader>

                {displayShotsOnGoal && (
                  <SimpleStatistic
                    type="percentage"
                    text="På mål"
                    homeTeamStatistics={homeTeamStatistics.shotsOnGoal}
                    awayTeamStatistics={awayTeamStatistics.shotsOnGoal}
                  />
                )}

                {displayPostHits && (
                  <SimpleStatistic
                    text="Stolpetreff"
                    type="percentage"
                    homeTeamStatistics={homeTeamStatistics.postHits}
                    awayTeamStatistics={awayTeamStatistics.postHits}
                  />
                )}

                {displayTotalShots && (
                  <SimpleStatistic
                    type="percentage"
                    text="Totalt"
                    homeTeamStatistics={homeTeamStatistics.totalShots}
                    awayTeamStatistics={awayTeamStatistics.totalShots}
                  />
                )}

                {displayXG && (
                  <SimpleStatistic
                    text="xG"
                    type="percentage"
                    homeTeamStatistics={homeTeamStatistics.xg}
                    awayTeamStatistics={awayTeamStatistics.xg}
                  />
                )}

                {displayChances && (
                  <SimpleStatistic
                    type="percentage"
                    text="Sjanser"
                    homeTeamStatistics={homeTeamStatistics.chances}
                    awayTeamStatistics={awayTeamStatistics.chances}
                  />
                )}

                {displaySaves && (
                  <SimpleStatistic
                    text="Redninger"
                    homeTeamStatistics={homeTeamStatistics.saves}
                    awayTeamStatistics={awayTeamStatistics.saves}
                  />
                )}

                {displayBlockedShots && (
                  <SimpleStatistic
                    text="Blokkerte"
                    homeTeamStatistics={homeTeamStatistics.blockedShots}
                    awayTeamStatistics={awayTeamStatistics.blockedShots}
                  />
                )}
              </S.Group>
            )}

            {(displayYellowCards ||
              displayRedCards ||
              displayFoulsCommitted) && (
              <S.Group $area="finishes">
                <S.GroupHeader>Kort og forseelser</S.GroupHeader>
                {displayYellowCards && (
                  <SimpleStatistic
                    text="Gule kort"
                    homeTeamStatistics={homeTeamStatistics.yellowCards}
                    awayTeamStatistics={awayTeamStatistics.yellowCards}
                  />
                )}
                {displayRedCards && (
                  <SimpleStatistic
                    text="Røde kort"
                    homeTeamStatistics={homeTeamStatistics.redCards}
                    awayTeamStatistics={awayTeamStatistics.redCards}
                  />
                )}
                {displayFoulsCommitted && (
                  <SimpleStatistic
                    text="Forseelser"
                    homeTeamStatistics={homeTeamStatistics.foulsCommitted}
                    awayTeamStatistics={awayTeamStatistics.foulsCommitted}
                  />
                )}
              </S.Group>
            )}

            {(displayCorners ||
              displayOffsides ||
              displayFreeKicks ||
              displayGoalKicks ||
              displayThrowIns) && (
              <S.Group $area="finishes">
                <S.GroupHeader>Dødballer</S.GroupHeader>
                {displayCorners && (
                  <SimpleStatistic
                    text="Hjørnespark"
                    homeTeamStatistics={homeTeamStatistics.corners}
                    awayTeamStatistics={awayTeamStatistics.corners}
                  />
                )}
                {displayOffsides && (
                  <SimpleStatistic
                    text="Offside"
                    homeTeamStatistics={homeTeamStatistics.offsides}
                    awayTeamStatistics={awayTeamStatistics.offsides}
                  />
                )}
                {displayFreeKicks && (
                  <SimpleStatistic
                    text="Frispark"
                    homeTeamStatistics={homeTeamStatistics.freeKicks}
                    awayTeamStatistics={awayTeamStatistics.freeKicks}
                  />
                )}
                {displayGoalKicks && (
                  <SimpleStatistic
                    text="Utspill fra mål"
                    homeTeamStatistics={homeTeamStatistics.goalKicks}
                    awayTeamStatistics={awayTeamStatistics.goalKicks}
                  />
                )}
                {displayThrowIns && (
                  <SimpleStatistic
                    text="Innkast"
                    homeTeamStatistics={homeTeamStatistics.throwIns}
                    awayTeamStatistics={awayTeamStatistics.throwIns}
                  />
                )}
              </S.Group>
            )}

            {(displayCounterAttacks || displayCrosses || displayTreatments) && (
              <S.Group $area="finishes">
                <S.GroupHeader>Annet</S.GroupHeader>
                {displayCounterAttacks && (
                  <SimpleStatistic
                    text="Kontringer"
                    homeTeamStatistics={homeTeamStatistics.counterAttacks}
                    awayTeamStatistics={awayTeamStatistics.counterAttacks}
                  />
                )}
                {displayCrosses && (
                  <SimpleStatistic
                    text="Innlegg"
                    homeTeamStatistics={homeTeamStatistics.crosses}
                    awayTeamStatistics={awayTeamStatistics.crosses}
                  />
                )}
                {displayTreatments && (
                  <SimpleStatistic
                    text="Behandlinger for skade"
                    homeTeamStatistics={homeTeamStatistics.treatments}
                    awayTeamStatistics={awayTeamStatistics.treatments}
                  />
                )}
              </S.Group>
            )}
          </S.Groups>
        </S.MatchStatistics>
      </S.Container>
    </div>
  );
};

const Statistic = ({
  text,
  suffix = '',
  type = 'percentage',
  homeTeamStatistics,
  awayTeamStatistics,
}: {
  text: any;
  suffix?: string;
  type?: string;
  homeTeamStatistics: any;
  awayTeamStatistics: any;
}) => {
  const totalValue = homeTeamStatistics + awayTeamStatistics;
  const homeTeamPercentage = (homeTeamStatistics / totalValue) * 100;
  const awayTeamPercentage = (awayTeamStatistics / totalValue) * 100;

  const homeTeamColors =
    type === 'percentage' ? getColor(homeTeamPercentage) : getDefaultColor();
  const awayTeamColors =
    type === 'percentage' ? getColor(awayTeamPercentage) : getDefaultColor();

  return (
    <div>
      <S.BarHeader aria-hidden="true">
        <span>{text}</span>
      </S.BarHeader>
      <S.BarContainer data-element-id={ELEMENT_IDS.CONTAINER}>
        <S.BarData
          $side="left"
          style={{
            backgroundColor: homeTeamColors?.backgroundColor,
            color: homeTeamColors?.fontColor,
            width: homeTeamStatistics + '%',
          }}
        >
          {`${homeTeamStatistics}${suffix}`}
        </S.BarData>

        <S.BarData
          $side="right"
          style={{
            backgroundColor: awayTeamColors?.backgroundColor,
            color: awayTeamColors?.fontColor,
            width: awayTeamStatistics + '%',
          }}
        >{`${awayTeamStatistics}${suffix}`}</S.BarData>
      </S.BarContainer>
    </div>
  );
};

const SimpleStatistic = ({
  text,
  type = 'positive',
  homeTeamStatistics,
  awayTeamStatistics,
}: {
  text: any;
  type?: 'negative' | 'positive' | 'percentage';
  homeTeamStatistics: any;
  awayTeamStatistics: any;
}) => {
  const totalValue = homeTeamStatistics + awayTeamStatistics;
  const homeTeamPercentage = Math.round(
    (homeTeamStatistics / totalValue) * 100,
  );
  const awayTeamPercentage = Math.round(
    (awayTeamStatistics / totalValue) * 100,
  );

  const homeTeamColors =
    type === 'percentage' ? getColor(homeTeamPercentage) : getDefaultColor();
  const awayTeamColors =
    type === 'percentage' ? getColor(awayTeamPercentage) : getDefaultColor();

  return (
    <S.SimpleStatistics
      aria-hidden="true"
      data-element-id={ELEMENT_IDS.CONTAINER}
    >
      <S.SimpleStatisticsData
        $side="left"
        $backgroundColor={homeTeamColors?.backgroundColor}
        $textColor={homeTeamColors?.fontColor}
      >{`${
        text === 'xG' ? homeTeamStatistics?.toFixed(2) : homeTeamStatistics
      }`}</S.SimpleStatisticsData>
      <S.SimpleStatisticsLabel>
        {text}
        {text === 'xG' && (
          <Tooltip
            label="Forventet antall mål basert på avslutninger."
            fontSize="sm"
          >
            <InfoIcon mt={0.5} />
          </Tooltip>
        )}
      </S.SimpleStatisticsLabel>
      <S.SimpleStatisticsData
        $side="right"
        $backgroundColor={awayTeamColors?.backgroundColor}
        $textColor={awayTeamColors?.fontColor}
      >{`${
        text === 'xG' ? awayTeamStatistics?.toFixed(2) : awayTeamStatistics
      }`}</S.SimpleStatisticsData>
    </S.SimpleStatistics>
  );
};

export default TeamMatchStatistics;
