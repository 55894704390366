import { useBem } from '@ntb-sport/hooks';
import './style-points.css';

import {
  SporteditStylePointsType,
  SporteditStylePointType,
} from '@ntb-sport/types';
import classNames from 'classnames';

export const StylePoints = ({
  stylePoints,
}: {
  stylePoints: SporteditStylePointsType;
}) => {
  const bem = useBem('scope-results');

  const highestJudgePoint =
    stylePoints &&
    Math.max(
      ...stylePoints.map((stylePoint: SporteditStylePointType) =>
        Number(stylePoint.points),
      ),
    );

  const lowestJudgePoint =
    stylePoints &&
    Math.min(
      ...stylePoints.map((stylePoint: SporteditStylePointType) =>
        Number(stylePoint.points),
      ),
    );

  const applicableStylePoints =
    stylePoints &&
    stylePoints.reduce(
      (
        stylePoints: SporteditStylePointsType,
        stylePoint: SporteditStylePointType,
      ) => {
        if (
          Number(stylePoint?.points) === highestJudgePoint &&
          !stylePoints?.find(
            (stylePoint: SporteditStylePointType) => stylePoint.highest,
          )
        ) {
          stylePoint = {
            ...stylePoint,
            highest: true,
            strike: true,
          };
        } else if (
          Number(stylePoint?.points) === lowestJudgePoint &&
          !stylePoints?.find(
            (stylePoint: SporteditStylePointType) => stylePoint?.lowest,
          )
        ) {
          stylePoint = {
            ...stylePoint,
            lowest: true,
            strike: true,
          };
        }
        stylePoints.push(stylePoint);
        return stylePoints;
      },
      [],
    );

  return (
    <div className={bem('style-points')}>
      {applicableStylePoints?.map((stylePoint: SporteditStylePointType) => (
        <div
          className={classNames(bem('style-point'), {
            [bem('style-point', 'strike')]: stylePoint?.strike,
          })}
          key={stylePoint.uuid}
        >
          {stylePoint?.points}
        </div>
      ))}
    </div>
  );
};

export default StylePoints;
