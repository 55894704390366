import Icon from '../Icon/Icon';

export const GoalIcon = (props: any) => (
  <Icon {...props}>
    <g>
      <path
        fill={props?.fillColor || 'currentColor'}
        d="M11.4,0.2c-6.2,0-11.2,4.8-11.2,11c0,6.2,5,11.1,11.1,11.1c6.2,0,11.1-5,11.1-11.1C22.5,5.1,17.5,0.2,11.4,0.2
z M19.4,4.8c1.3,1.6,1.7,3.4,1.9,4.3h-0.1c-0.2-0.9-0.7-2.5-1.9-3.4C19.4,5.4,19.4,5.1,19.4,4.8z M14,1.4L14,1.4L14,1.4L14,1.4
C14,1.4,14.1,1.4,14,1.4C14.1,1.4,14.1,1.4,14,1.4c2.4,0.5,3.7,1.7,3.8,1.9l0,0c0,0,0,0,0,0.1l0,0c0.7,0.6,0.7,1.4,0.6,1.8
c-0.6-0.2-1.6-0.3-2.8,0c-0.6-0.9-2-1.9-3.2-2.2c0-0.5-0.1-1.1-0.1-1.4C13.2,1.3,13.8,1.4,14,1.4z M4.9,3.7L4.9,3.7
c0.1-0.4,0.4-0.7,0.6-1l0,0c1.3-1.2,3.3-1.3,3.4-1.3l0,0l0,0c1.3-0.2,2.1,0,2.5,0.2c0.1,0.4,0.1,1,0.1,1.4C10.4,3.3,8.8,4.3,8.1,5
C7.5,4.7,6.2,4.4,4.9,4.9C4.8,4.4,4.9,3.8,4.9,3.7z M4.8,5.9c1.4-0.6,2.5-0.2,3-0.1c0.1,0.4,0.5,1.4,1.3,3.3
c-0.4,0.4-0.8,1.1-1.2,1.8c-0.4,0.7-0.8,1.4-1,1.7c-1.2,0-2.6-0.1-3.1-0.1c-1.1-1.1-1.2-3.1-1.2-3.4C3,7,4.6,6,4.8,5.9L4.8,5.9z
M1.3,8.5C1.4,7.6,2.4,6,3.1,5.1C3.1,5,3.2,4.9,3.3,4.8c0.2-0.3,0.4-0.4,0.5-0.5c0,0.3,0,0.7,0.1,1C3.4,5.8,2.2,6.8,1.8,8.7
C1.6,8.6,1.4,8.5,1.3,8.5L1.3,8.5L1.3,8.5z M1.3,13.7C1,12.3,0.9,10.8,1,10.3c0-0.1,0-0.3,0.1-0.4l0,0l0,0c0.1-0.2,0.1-0.3,0.2-0.4
c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0.8,0.3,2.4,1.4,3.6c-0.4,1.3-0.3,2.5-0.1,3.2c-0.2,0-0.5,0.1-0.7,0.1C2.1,16.2,1.6,15.6,1.3,13.7z
M6.4,20.3c-0.4-0.2-0.8-0.5-1.3-0.8c-1.3-0.7-1.9-1.6-2.2-2.2c0.2,0,0.3-0.1,0.5-0.1c1.2,1.5,2.8,1.9,3.5,2.1
C7,19.7,7.3,20.4,7.8,21C7.3,20.8,6.9,20.6,6.4,20.3z M13.5,20.8C13.4,20.9,13.4,20.9,13.5,20.8C13.4,20.9,13.4,20.9,13.5,20.8
c-1.5,0.5-4,0.1-4.4,0l0,0l0,0c-0.4-0.1-0.8-0.5-1-1C8,19.5,7.9,19.2,7.8,19c0.6-0.4,1.4-1.2,1.7-1.6c0.7,0.2,1.4,0.2,1.9,0.2
c1,0,1.8-0.1,2.3-0.2c0.7,1.1,1.2,1.6,1.5,1.8C14.6,20.6,13.5,20.8,13.5,20.8z M15.3,14.5c-0.2,0.4-0.5,0.9-0.8,1.3
c-0.2,0.2-0.4,0.7-0.7,0.8c-0.5,0.1-2.5,0.5-4.2,0C9,15.6,8,14.3,7.7,13.4v-0.1v-0.1l0,0c0.3-0.7,0.8-1.4,1.2-2
c0.4-0.7,0.8-1.3,1-1.5c0,0,0,0,0.1,0c1.3,0,2.7-0.2,4,0.2c0.8,1,1.9,2.5,2,3.4c0,0.1-0.1,0.2-0.1,0.3
C15.6,13.8,15.5,14.2,15.3,14.5z M16.1,20.3c-0.2,0.1-0.4,0.2-0.6,0.3c0.3-0.3,0.5-0.6,0.7-1c0.3,0,0.7-0.1,1.2-0.2
c0.1,0,0.3-0.1,0.5-0.2C17.2,19.7,16.6,20,16.1,20.3z M19.2,12.8c-0.3,0-1.3,0.1-2.5,0c-0.3-1.2-1.6-2.8-2-3.5
c0.5-1.1,1-2.6,1.2-3.2c1.4-0.4,2.6,0,2.8,0.1c1.4,0.9,1.7,3.2,1.8,3.4c0,0,0,0,0,0.1C20.6,11.5,19.6,12.5,19.2,12.8z M20,16.7
c0.2-0.7,0.5-1.9,0-3.3c0.5-0.5,1.4-1.6,1.4-3.4c0.1,0,0.1,0,0.2,0C22.3,12.7,20.6,15.9,20,16.7C20.1,16.7,20.1,16.7,20,16.7z"
      />
    </g>
  </Icon>
);
