import { useQuery } from '@tanstack/react-query';

import { fetchTeam, FetchTeamType } from './fetch';
import { QUERY_KEYS } from '@ntb-sport/constants';

interface UseQueryNifsTeam {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: FetchTeamType;
  options?: Record<string, unknown>;
}

export const useQueryNifsTeam = ({
  queryKey = QUERY_KEYS.NIFS_TEAM,
  queryKeyParams,
  params,
  options,
}: UseQueryNifsTeam) => {
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchTeam(params),
    enabled: Boolean(params.sportGroup) && Boolean(params.teamId),
    ...options,
  });

  return { data, isLoading, error, isFetching };
};
