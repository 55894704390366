import { Fetch } from '../../fetch';

interface FetchPostNrkVideoProps {
  data: any;
  apiBaseUrl: string;
  token: string;
}

export const postNrkVideo = async ({
  apiBaseUrl,
  data,
  token,
}: FetchPostNrkVideoProps) => {
  const url = `${apiBaseUrl}/nrkVideos/`;

  const json = await Fetch({
    url,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  });

  return json;
};
