import { Icon } from '../../chakra';

export const LocationIcon = (props: any) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      d="M8.03328 0.5C10.7043 0.5 12.8666 2.65715 12.8666 5.31438C12.8666 5.84156 12.6542 6.62882 12.2733 7.58829C11.8986 8.53175 11.3841 9.58631 10.8295 10.6269C9.79503 12.5676 8.63757 14.4291 8.03326 15.377C7.429 14.4295 6.27154 12.568 5.2371 10.6271C4.68247 9.58654 4.16794 8.53191 3.79332 7.58839C3.41234 6.62887 3.19995 5.84157 3.19995 5.31438C3.19995 2.65715 5.36223 0.5 8.03328 0.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 6C10.5 7.38071 9.38071 8.5 8 8.5C6.61929 8.5 5.5 7.38071 5.5 6C5.5 4.61929 6.61929 3.5 8 3.5C9.38071 3.5 10.5 4.61929 10.5 6Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default LocationIcon;
