import styled from 'styled-components';

import { MEDIA_QUERY } from '@ntb-sport/constants';

import {
  PenaltyShootoutGoalIcon,
  PenaltyShootoutMissIcon,
} from '../../../icons';

interface StyledProps {
  $side?: string;
}

export const Title = styled.div`
  text-align: center;
  margin-bottom: var(--ntb-space-4);
  font-size: var(--ntb-fontSizes-md);
  font-weight: var(--ntb-fontWeights-medium);
`;

export const Penalties = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--ntb-space-1);
`;

export const Penalty = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  justify-items: center;
  grid-column-gap: var(--ntb-space-2);
  grid-row-gap: var(--ntb-space-2);
`;

export const Side = styled.div<StyledProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.$side === 'hometeam' ? '1fr max-content' : 'max-content 1fr'};
  grid-column-gap: var(--ntb-space-1);
  justify-self: ${(props) => (props.$side === 'hometeam' ? 'end' : 'start')};
  align-items: center;
  font-size: var(--ntb-fontSizes-sm);
  width: auto;

  @container team-match-result-container ${MEDIA_QUERY.TABLET} {
    width: 130px;
  }
`;

export const PlayerName = styled.p<StyledProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  justify-self: ${(props) => (props.$side === 'hometeam' ? 'end' : 'start')};
`;

export const Number = styled.div`
  text-align: center;
  font-size: var(--ntb-fontSizes-sm);
`;

export const PenaltyShootoutGoal = styled(PenaltyShootoutGoalIcon)`
  fill: var(--base-colors-success);
`;

export const PenaltyShootoutMiss = styled(PenaltyShootoutMissIcon)`
  fill: var(--base-colors-error);
`;
