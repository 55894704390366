import { Fetch } from '../../fetch';

interface FetchSportEditCompetitionsType {
  apiBaseUrl: string;
  sportUuid: string;
  token: string;
  customerUUID: string;
}

export const fetchSportEditCompetitions = async ({
  apiBaseUrl,
  sportUuid,
  token,
  customerUUID,
}: FetchSportEditCompetitionsType) => {
  const json = await Fetch({
    url: `${apiBaseUrl}/sports/${sportUuid}/competitions`,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });

  return json?.data;
};
