import {
  COMPONENT_IDS,
  ELEMENT_IDS,
  MATCH_STATUS,
  MATCH_STATUS_ID,
} from '@ntb-sport/constants';
import { CSS_VARIABLES } from '../index';

export const TEAM_CALENDAR_STYLES = [
  {
    name: 'Turnerning',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_CALENDAR}'] [data-element-id='${ELEMENT_IDS.TOURNAMENT}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Tournament Logo',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_CALENDAR}'] [data-element-id='${ELEMENT_IDS.TOURNAMENT}'] [data-element-id='${ELEMENT_IDS.LOGO}']`,
    variables: [CSS_VARIABLES.FONT_COLOR, CSS_VARIABLES.BACKGROUND_COLOR],
  },
  {
    name: 'Tournament Button',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_CALENDAR}'] [data-element-id='${ELEMENT_IDS.TOURNAMENT}'] [data-element-id='${ELEMENT_IDS.BUTTON}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
      CSS_VARIABLES.FONT_COLOR__HOVER,
      CSS_VARIABLES.BORDER_COLOR__HOVER,
      CSS_VARIABLES.BACKGROUND_COLOR__HOVER,
      CSS_VARIABLES.FONT_COLOR__ACTIVE,
      CSS_VARIABLES.BORDER_COLOR__ACTIVE,
      CSS_VARIABLES.BACKGROUND_COLOR__ACTIVE,
    ],
  },
  {
    name: 'Stage',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_CALENDAR}'] [data-element-id='${ELEMENT_IDS.STAGE}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Stage Button',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_CALENDAR}'] [data-element-id='${ELEMENT_IDS.STAGE}'] [data-element-id='${ELEMENT_IDS.BUTTON}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
      CSS_VARIABLES.FONT_COLOR__HOVER,
      CSS_VARIABLES.BORDER_COLOR__HOVER,
      CSS_VARIABLES.BACKGROUND_COLOR__HOVER,
      CSS_VARIABLES.FONT_COLOR__ACTIVE,
      CSS_VARIABLES.BORDER_COLOR__ACTIVE,
      CSS_VARIABLES.BACKGROUND_COLOR__ACTIVE,
    ],
  },
  {
    name: 'Match',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_CALENDAR}'] [data-element-id='${ELEMENT_IDS.MATCH}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR__HOVER,
    ],
  },
  {
    name: 'Match (Goal)',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_CALENDAR}'] [data-element-id='${ELEMENT_IDS.MATCH}'] [data-status-id='goal']`,
    variables: [CSS_VARIABLES.BACKGROUND_COLOR],
  },
  {
    name: 'Match (Dissalowed Goal)',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_CALENDAR}'] [data-element-id='${ELEMENT_IDS.MATCH}'] [data-status-id='dissalowed-goal']`,
    variables: [CSS_VARIABLES.BACKGROUND_COLOR],
  },
  {
    name: 'Match (Not Started)',
    selector: `[data-style-id='default'] [data-component-id='${
      COMPONENT_IDS.TEAM_CALENDAR
    }'] [data-element-id='${ELEMENT_IDS.MATCH}'] [data-element-id='${
      ELEMENT_IDS.SCORE
    }'][data-status-id='${MATCH_STATUS_ID[MATCH_STATUS.NOT_STARTED]}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Match (Played)',
    selector: `[data-style-id='default'] [data-component-id='${
      COMPONENT_IDS.TEAM_CALENDAR
    }'] [data-element-id='${ELEMENT_IDS.MATCH}'] [data-element-id='${
      ELEMENT_IDS.SCORE
    }'][data-status-id='${MATCH_STATUS_ID[MATCH_STATUS.PLAYED]}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Match (In Progress)',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_CALENDAR}'] [data-element-id='${ELEMENT_IDS.MATCH}'] [data-element-id='${ELEMENT_IDS.SCORE}'][data-status-id='in-progress']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Match (Cancelled)',
    selector: `[data-style-id='default'] [data-component-id='${
      COMPONENT_IDS.TEAM_CALENDAR
    }'] [data-element-id='${ELEMENT_IDS.MATCH}'] [data-element-id='${
      ELEMENT_IDS.SCORE
    }'][data-status-id='${MATCH_STATUS_ID[MATCH_STATUS.CANCELLED]}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Match (Will not be played)',
    selector: `[data-style-id='default'] [data-component-id='${
      COMPONENT_IDS.TEAM_CALENDAR
    }'] [data-element-id='${ELEMENT_IDS.MATCH}'] [data-element-id='${
      ELEMENT_IDS.SCORE
    }'][data-status-id='${MATCH_STATUS_ID[MATCH_STATUS.WILL_NOT_BE_PLAYED]}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Match (Postponed)',
    selector: `[data-style-id='default'] [data-component-id='${
      COMPONENT_IDS.TEAM_CALENDAR
    }'] [data-element-id='${ELEMENT_IDS.MATCH}'] [data-element-id='${
      ELEMENT_IDS.SCORE
    }'][data-status-id='${MATCH_STATUS_ID[MATCH_STATUS.POSTPONED]}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Match Button',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_CALENDAR}'] [data-element-id='${ELEMENT_IDS.MATCH}'] [data-element-id='${ELEMENT_IDS.BUTTON}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
      CSS_VARIABLES.FONT_COLOR__HOVER,
      CSS_VARIABLES.BORDER_COLOR__HOVER,
      CSS_VARIABLES.BACKGROUND_COLOR__HOVER,
      CSS_VARIABLES.FONT_COLOR__ACTIVE,
      CSS_VARIABLES.BORDER_COLOR__ACTIVE,
      CSS_VARIABLES.BACKGROUND_COLOR__ACTIVE,
    ],
  },
];
