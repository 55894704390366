export const CUSTOMER_FEEDBACK_STYLES = [
  '--colors-error-message-text',
  '--colors-form-background',
  '--colors-form-text',
  '--colors-input-background',
  '--colors-input-border',
  '--colors-input-border__focus',
  '--colors-input-border__hover',
  '--colors-input-border__invalid',
  '--colors-input-placeholder-text',
  '--colors-input-text',
  '--colors-open-close-icon-color',
  '--colors-radio-background__checked',
  '--colors-radio-border__checked',
  '--colors-radio-center__checked',
  '--colors-submit-bg',
  '--colors-submit-bg__hover',
  '--colors-submit-font',
  '--colors-submit-font__hover',
  '--padding-form-body',
  '--radio-button-spacing',
];