import Icon from '../Icon/Icon';

export const BiathlonSportIcon = (props: any) => (
  <Icon viewBox="0 0 28.3 28.3" {...props}>
    <g>
      <g>
        <g>
          <circle cx="15.2" cy="5.9" r="2.4" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M24,21.2h-5.9c0-0.1,0-0.2,0-0.3c-0.2-1.9-1.6-5.5-5-6.3c0.1-1.1,0.3-2.2,0.6-3c0.2,0.2,0.5,0.3,0.7,0.4
				c0.6,0.3,1.3,0.4,2.1,0.4c0.9,0,2-0.2,3.1-0.6v7.8c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3-0.2,0.3-0.3v-8.1c0,0,0.1,0,0.1,0
				c0.6-0.3,0.9-0.9,0.6-1.5c-0.3-0.6-0.9-0.9-1.5-0.6c-1.7,0.7-3.1,0.9-4.1,0.5c-0.7-0.3-1-0.9-1-0.9L14,8.7l-0.1-0.1
				c0,0-0.1-0.1-0.2-0.1c-0.6-0.4-1.5-0.3-1.9,0.3c0,0-0.1,0.1-0.1,0.1l-0.3,2.2c0,0,0,0.1,0,0.1l-0.5,1.1c0,0,0,0,0,0.1
				c0.2,0.2,0.2,0.4,0.1,0.6l-0.3,0.6c0,0,0,0.1,0,0.1c-0.1,0.2-0.3,0.6-0.5,1c0,1.1,0.1,2.3,0.3,3.3c0.3,1.2-0.8,2.4-1.7,3.2H6.7
				c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h0.6c-0.1,0.3-0.1,0.7,0.1,1.1H4.3c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7
				h17.3c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H10.2c0.3-0.3,0.8-0.6,1.2-1.1H24c0.4,0,0.7-0.3,0.7-0.7
				C24.7,21.5,24.4,21.2,24,21.2z M12.5,21.2c0.6-1,1-2.2,0.8-3.5c1.5,1,1.9,3.2,2,3.5H12.5z"
          />
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              d="M12.8,3.7C12.8,3.7,12.8,3.7,12.8,3.7l-1.6,3.6c0.2,0.1,0.3,0.4,0.3,0.6l-0.4,2.4L10.9,11c0,0,0,0,0,0
					l-0.4,0.8l-0.1,0.3c-0.1,0.1,0,0.3,0,0.4c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1l-0.3,0.6c0,0,0,0,0,0c0,0,0,0,0,0
					c-0.4,0.7-0.9,2.1-1,2.5c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0l-1-0.4c-0.1,0-0.1-0.1-0.1-0.2c0.1-0.2,0.3-0.7,0.7-1.5
					c0.4-1-0.2-1.8-0.4-2c0,0,0-0.1,0-0.1l0.5-1.1c0,0,0,0,0.1-0.1l0.3-0.1c0,0,0.1,0,0.1-0.1l2.8-6.3L12,4.1
					c-0.1-0.1-0.2-0.2-0.1-0.3L12,3.3c0-0.1,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0l0.3,0.1C12.8,3.4,12.8,3.6,12.8,3.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                d="M10.9,11C10.9,11,10.9,11,10.9,11l-0.4,0.9l-0.1,0.3c-0.1,0.1,0,0.3,0,0.4c0,0.1,0.1,0.1,0.1,0.2
						c0,0,0,0.1,0,0.1l-0.3,0.6c0,0,0,0,0,0c0,0,0,0,0,0c-0.4,0.7-0.9,2.1-1,2.5c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0l-1-0.4
						c-0.1,0-0.1-0.1-0.1-0.2c0.1-0.2,0.3-0.7,0.7-1.5c0.4-1-0.2-1.8-0.4-2c0,0,0-0.1,0-0.1l0.5-1.1c0,0,0,0,0.1-0.1l0.3-0.1
						c0,0,0.1,0,0.1-0.1l2.8-6.3L12,4.1c-0.1-0.1-0.2-0.2-0.1-0.3L12,3.3c0-0.1,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0l0.3,0.1
						c0.1,0.1,0.2,0.2,0.1,0.3c0,0,0,0,0,0l-1.6,3.6c0.2,0.1,0.3,0.4,0.3,0.6l-0.4,2.4L10.9,11z"
              />
              <path
                d="M12.8,3.7C12.8,3.7,12.8,3.7,12.8,3.7l-1.6,3.6c0.2,0.1,0.3,0.4,0.3,0.6l-0.4,2.4L10.9,11c0,0,0,0,0,0
						l-0.4,0.8l-0.1,0.3c-0.1,0.1,0,0.3,0,0.4c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1l-0.3,0.6c0,0,0,0,0,0c0,0,0,0,0,0
						c-0.4,0.7-0.9,2.1-1,2.5c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0l-1-0.4c-0.1,0-0.1-0.1-0.1-0.2c0.1-0.2,0.3-0.7,0.7-1.5
						c0.4-1-0.2-1.8-0.4-2c0,0,0-0.1,0-0.1l0.5-1.1c0,0,0,0,0.1-0.1l0.3-0.1c0,0,0.1,0,0.1-0.1l2.8-6.3L12,4.1
						c-0.1-0.1-0.2-0.2-0.1-0.3L12,3.3c0-0.1,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0l0.3,0.1C12.8,3.4,12.8,3.6,12.8,3.7z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
