import { useQuery } from '@tanstack/react-query';

import { fetchNifsFairPlay, FetchNifsFairPlay } from './fetch';
import { QUERY_KEYS } from '@ntb-sport/constants';

interface UseQueryNifsFairPlay {
  queryKey?: string;
  queryKeyParams?: Record<string, string | number | null>;
  params: FetchNifsFairPlay;
  options?: Record<string, unknown>;
}

export const useQueryNifsFairPlay = ({
  queryKey = QUERY_KEYS.NIFS_FAIR_PLAY_TABLE,
  queryKeyParams,
  params,
  options,
}: UseQueryNifsFairPlay) => {
  const { data, isLoading, error } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchNifsFairPlay(params),
    enabled: Boolean(params.districtId),
    ...options,
  });

  return { data, isLoading, error };
};
