import { QUERY_KEYS } from '@ntb-sport/constants';

export function onEventParticipantsDelete({
  msg,
  apiClient,
}: {
  msg: any;
  apiClient: any;
}) {
  const { entityId, additionalData } = msg;

  const state = apiClient.getQueryData([
    QUERY_KEYS.EVENT_PARTICIPANTS,
    { eventUUID: additionalData.event.uuid },
  ]);

  const newState = state?.filter((item: any) => item.uuid !== entityId);

  apiClient.setQueryData(
    [QUERY_KEYS.EVENT_PARTICIPANTS, { eventUUID: additionalData.event.uuid }],
    newState,
  );
}
