import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '@ntb-sport/constants';
import { fetchCompetitionSeasons, FetchCompetitionSeasons } from './fetch';

interface UseQueryCompetitionSeasons {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: FetchCompetitionSeasons;
  options?: Record<string, unknown>;
}

export const useQueryCompetitionSeasons = ({
  queryKey = QUERY_KEYS.COMPETITION_SEASONS,
  queryKeyParams,
  params,
  options,
}: UseQueryCompetitionSeasons) => {
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchCompetitionSeasons(params),
    enabled:
      Boolean(params.customerUUID) &&
      Boolean(params.token) &&
      Boolean(params.competitionUUID),
    ...options,
  });

  return { data, isLoading, error };
};
