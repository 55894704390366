import { useEffect } from 'react';

export const useLoadScript = ({
  url,
  id,
  isAsync = true,
  domNode,
  onLoad,
}: {
  url: string;
  id: string;
  isAsync?: boolean;
  domNode: any;
  onLoad?: any;
}) => {
  useEffect(() => {
    if (!domNode.current) {
      return;
    }

    const script = document.createElement('script');
    script.src = url;
    script.id = id;
    script.async = isAsync;
    domNode.current.appendChild(script);

    script.onload = () => {
      onLoad && onLoad();
    };

    return () => {
      if (domNode.current) {
        domNode.current.removeChild(script);
      }
    };
  }, [url, domNode, onLoad]);
};

export default useLoadScript;
