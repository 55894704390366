import isNameIncludedInSearchQuery from './isNameIncludedInSearchQuery';

export default function stageIncludesSearchQuery(
  stage: any,
  searchQuery: string,
) {
  if (!searchQuery) return true;
  const matchNames = Object.values(stage.matches).map(
    (match: any) => match.name,
  );

  const matchHomeTeamShortNames = Object.values(stage.matches).map(
    (match: any) => match.homeTeam.shortName,
  );

  const matchAwayTeamShortNames = Object.values(stage.matches).map(
    (match: any) => match.awayTeam.shortName,
  );

  return (
    isNameIncludedInSearchQuery(stage.tournament.name, searchQuery) ||
    isNameIncludedInSearchQuery(stage.tournament.country.name, searchQuery) ||
    isNameIncludedInSearchQuery(stage.groupName, searchQuery) ||
    matchNames?.some((name: string) =>
      isNameIncludedInSearchQuery(name, searchQuery),
    ) ||
    matchHomeTeamShortNames?.some((name: string) =>
      isNameIncludedInSearchQuery(name, searchQuery),
    ) ||
    matchAwayTeamShortNames?.some((name: string) =>
      isNameIncludedInSearchQuery(name, searchQuery),
    )
  );
}
