import { useQueries, useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '@ntb-sport/constants';
import { fetchScopeResults, FetchScopeResultsType } from './fetch';
import { getIsNeoSportGroup } from '@ntb-sport/helpers';
import {
  SocketContext,
  SocketContextType,
  useSportEditSocketHandleRooms,
} from '@ntb-sport/socket';
import { SPORT_GROUP } from '@ntb-sport/constants';
import { API_VERSION } from '../../fetch';
import { useContext } from 'react';

interface UseQueryScopeResultsParams extends FetchScopeResultsType {
  sportGroup: string;
  eventUUID: string;
}

interface UseQueryScopeResults {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: UseQueryScopeResultsParams;
  options?: Record<string, unknown>;
  enableSocket?: boolean;
}

export const useQueryScopeResults = ({
  queryKey = QUERY_KEYS.SCOPE_RESULTS,
  queryKeyParams,
  params,
  options,
  enableSocket = false,
}: UseQueryScopeResults) => {
  const isNeoSportGroup = getIsNeoSportGroup(params.sportGroup);

  const { whitelistEvents } = useContext<SocketContextType>(SocketContext);

  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchScopeResults(params),
    enabled:
      isNeoSportGroup &&
      Boolean(params.customerUUID) &&
      Boolean(params.token) &&
      Boolean(params.scopeUUID),
    ...options,
  });

  useSportEditSocketHandleRooms({
    enabled:
      enableSocket &&
      Boolean(params.scopeUUID) &&
      (params.sportGroup === SPORT_GROUP.CYCLING ||
        Boolean(
          whitelistEvents?.find(
            (event: { uuid: string }) => event.uuid === params.eventUUID,
          ),
        )),
    roomId: `/${params?.version || API_VERSION.V6}/scopes/${params.scopeUUID}/results`,
  });

  return { data, isLoading, error };
};

interface UseQueryAllScopeResultsParams {
  scopes: any;
  apiBaseUrl: string;
  customerUUID: string;
  token: string;
}

interface UseQueryAllScopeResults {
  queryKey?: string;
  queryKeyParams?: Record<string, unknown>;
  params: UseQueryAllScopeResultsParams;
  options?: Record<string, unknown>;
}

export const useQueryAllScopeResults = ({
  queryKey: _queryKey,
  queryKeyParams: _queryKeyParams,
  params,
  options,
}: UseQueryAllScopeResults) => {
  const queries = useQueries({
    queries:
      params.scopes?.map((scope: any) => {
        return {
          queryKey: [QUERY_KEYS.SCOPE_RESULTS, { scopeUUID: scope.uuid }],
          queryFn: () =>
            fetchScopeResults({ ...params, scopeUUID: scope.uuid }),
          ...options,
        };
      }) ?? [],
  });

  return queries;
};
