import styled from 'styled-components';

const Container = styled.div`
  padding: 12px;
`;

const StyledCredit = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const StyledSVG = styled.svg`
  max-width: 30px !important;
  max-height: 30px;
`;

const Version = styled.div`
  display: block;
  text-align: right;
  font-size: var(--ntb-fonsSizes-xs);
  color: rgba(0, 0, 0, 0.54);
`;

export const Credit = ({ version }: { version: string }) => {
  return (
    <Container>
      <StyledCredit>
        <StyledSVG
          id="Hovedlogo"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1259 628"
        >
          <title>NTB_logo_positiv_RGB</title>
          <path
            fill="#323232"
            d="M1086.06,417.36h-96.7V314.91h96.7c39.34,0,52.45,26.23,52.45,50.8s-13.11,51.65-52.45,51.65M989,109.89h90.33c39.81,0,52.21,24.86,52.21,48.1s-12.4,48.09-52.21,48.09H989Zm204.84,147.2c16.3-11.12,57.76-44.46,57.76-104.48C1251.6,61.46,1193.07,0,1083.54,0H870.39V527.46h224.24c94,0,164.37-54.85,164.37-155.56,0-74.07-43.66-101.51-65.16-114.81"
          />
          <polygon
            fill="#323232"
            points="276.8 270.54 102.68 0 0 0 0 527.46 124.27 527.46 124.27 256.91 298.44 527.46 401.04 527.46 401.04 0 276.8 0 276.8 270.54"
          />
          <polygon
            fill="#323232"
            points="441.35 110.03 576.7 110.03 636.6 54.53 636.6 154.88 576.96 212.58 576.96 628 636.99 572.58 636.99 527.41 695.99 527.41 695.99 110.03 831.69 110.03 831.69 0.01 441.35 0.01 441.35 110.03"
          />
        </StyledSVG>
      </StyledCredit>
      <Version>{version}</Version>
    </Container>
  );
};

export default Credit;
