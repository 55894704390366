import { Fragment } from 'react';

const ShirtNumber = ({
  shirtNumber,
  shirtNumberColor = '#000',
  shirtNumberBackgroundColor = '#fff',
  shirtNumberBackgroundOpacity = '1',
}: {
  shirtNumber?: any;
  shirtNumberColor?: any;
  shirtNumberBackgroundColor?: any;
  shirtNumberBackgroundOpacity?: any;
}) => {
  if (!shirtNumber) return null;

  const style_font = {
    fontFamily: 'Open Sans',
    fill: shirtNumberColor,
    fontSize: '44px',
    letterSpacing: '-1px',
    fontWeight: 700,
  };

  return (
    <Fragment>
      <circle
        opacity={shirtNumberBackgroundOpacity}
        fill={shirtNumberBackgroundColor}
        cx="69.15"
        cy="59.95"
        r="30"
      />
      <text x="50%" y="50%" textAnchor="middle" dy=".1em" style={style_font}>
        {shirtNumber}
      </text>
    </Fragment>
  );
};

export default ShirtNumber;
