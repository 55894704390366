import * as S from './BackgroundImage.styled';

import { useSpring } from 'react-spring';
import { useState } from 'react';

const BackgroundImage = ({
  src,
  id,
  isLoading,
}: {
  src: string;
  id: number;
  isLoading?: boolean;
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

  const fade = useSpring({
    opacity: isImageLoaded ? 1 : 0,
  });

  const imageSrc =
    src && id !== 99999999
      ? src
      : 'https://livecenter.nifs.no/assets/images/stadium_test.jpg';

  const setImageLoaded = () => {
    setIsImageLoaded(true);
    setImageUrl(imageSrc);
  };

  if (isLoading) return null;

  return (
    <S.Container>
      <img
        id="hero-image"
        style={{ display: 'none' }}
        onLoad={setImageLoaded}
        src={imageSrc}
        alt=""
      />

      {imageUrl && (
        <S.BackgroundImage
          id="hero-image-background"
          $backgroundImage={imageUrl}
          style={fade}
        />
      )}
    </S.Container>
  );
};

export default BackgroundImage;
