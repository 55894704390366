import { NifsCountryType } from '@ntb-sport/types';
import { Fetch, NIFS_API_BASE_URL, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchNifsCountries {
  apiBaseUrl: string;
}

export const fetchNifsCountries = async ({
  apiBaseUrl = NIFS_API_BASE_URL,
}: FetchNifsCountries): Promise<NifsCountryType> => {
  const url = `${apiBaseUrl}/countries/?hasStages=1`;

  const json = await Fetch({
    url,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });

  return json;
};
