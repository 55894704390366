import Icon from '../Icon/Icon';

export const WhistleIcon = (props: any) => (
  <Icon {...props}>
    <g>
      <g>
        <path
          d="M5.1,7.1C5,7.3,4.8,7.4,4.6,7.4c-0.1,0-0.2,0-0.2-0.1L0.8,5.5C0.5,5.4,0.4,5,0.5,4.7C0.7,4.6,1,4.4,1.3,4.6
			l3.6,1.8C5.2,6.6,5.3,6.9,5.1,7.1z"
        />
        <path
          d="M8.3,5.4C8.2,5.4,8.2,5.4,8.3,5.4c-0.4,0-0.6-0.2-0.7-0.5L7,0.9C6.9,0.6,7.2,0.3,7.5,0.3
			C7.8,0.2,8,0.4,8.1,0.7l0.7,4C8.8,5,8.6,5.3,8.3,5.4z"
        />
        <path
          d="M6.5,5.1c0.2,0.3,0.2,0.6-0.1,0.8C6.3,6,6.2,6,6.1,6C5.9,6,5.7,5.9,5.7,5.8L3.3,2.5C3.2,2.3,3.2,1.9,3.5,1.7
			C3.7,1.5,4,1.6,4.2,1.8L6.5,5.1z"
        />
      </g>
      <g>
        <path
          d="M22.1,3.2l-1.1-2c-0.2-0.3-0.6-0.5-0.9-0.3L9.4,6.6L6.1,8.3c0,0,0,0-0.1,0S5.8,8.4,5.8,8.4
			c-1.7,0.9-3,2.4-3.5,4.2c-0.6,1.6-0.5,3.3,0.2,5C2,17.9,1.6,18.4,1.4,19c-0.2,0.7-0.2,1.4,0.2,2c0.3,0.7,0.9,1.1,1.5,1.3
			c0.3,0.1,0.6,0.1,0.8,0.1c0.4,0,0.9-0.1,1.2-0.3c0.3-0.2,0.6-0.4,0.8-0.7c1.1,0.6,2.2,0.8,3.3,0.8s2.4-0.3,3.4-0.9
			c1.7-1,3.1-2.5,3.6-4.3c0.6-1.9,0.4-3.8-0.5-5.6l0,0l-0.6-1L15,10.1c-0.1-0.2-0.1-0.4,0.1-0.5L22.2,4C22.2,3.8,22.3,3.5,22.1,3.2z
			 M9.4,8l1,1.9c0,0,0,0,0,0.1l0,0c0,0.1,0,0.1,0,0.1l0,0c0,0.1-0.1,0.1-0.1,0.1l-1.7,0.9c-0.1,0.1-0.3,0-0.3-0.1l-1-1.9L9.4,8z
			 M4.5,21c-0.3,0.2-0.7,0.2-1.1,0.1s-0.7-0.4-0.8-0.7c-0.2-0.3-0.2-0.7-0.1-1.1c0.1-0.3,0.2-0.5,0.4-0.6c0.5,0.8,1.1,1.3,1.7,1.9
			c0,0,0.1,0,0.1,0.1C4.7,20.9,4.6,21,4.5,21z M14.2,8.6c-0.7,0.6-0.9,1.4-0.4,2.2c0,0,0.3,0.4,0.3,0.5l0.6,1
			c0.8,1.4,0.9,3.1,0.4,4.6c-0.5,1.5-1.5,2.8-3,3.5c-1.9,1-4.2,1-6-0.2c-1-0.6-1.6-1.3-2.1-2.3c0,0-0.1-0.1-0.1-0.2
			c-0.7-1.4-0.9-3.1-0.3-4.5C4,11.7,5,10.6,6.2,9.8l1,1.9c0.3,0.5,0.8,0.9,1.3,0.9c0.3,0,0.5-0.1,0.7-0.2c0.5-0.3,1-0.5,1.4-0.8
			c0.2-0.1,0.5-0.3,0.7-0.5c0.1-0.1,0.2-0.2,0.2-0.3c0.5-0.8,0.1-1.4-0.3-2.1c-0.2-0.5-0.5-0.9-0.7-1.3l9.7-5.1l0.5,1L14.2,8.6z"
        />
      </g>
    </g>
  </Icon>
);
