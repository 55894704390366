import { Icon } from '../../chakra';

export const StandingsIcon2 = (props: any) => (
  <Icon {...props}>
    <g>
      <g>
        <path
          fill="currentColor"
          d="M22.2,1.9c0-0.4-0.4-0.8-0.8-0.8H1.3c-0.4,0-0.8,0.4-0.8,0.8v6.3l0,0l0,0v6.3l0,0l0,0v6.3
			c0,0.4,0.4,0.8,0.8,0.8h20.1c0.4,0,0.8-0.4,0.8-0.8v-6.3l0,0l0,0V8.2l0,0l0,0V1.9z M6.7,20H2.1v-4.6h4.6V20z M6.7,13.7H2.1V9.1
			h4.6V13.7z M6.7,7.4H2.1V2.7h4.6V7.4z M20.6,20H8.3v-4.6h12.3C20.6,15.4,20.6,20,20.6,20z M20.6,13.7H8.3V9.1h12.3
			C20.6,9.1,20.6,13.7,20.6,13.7z M20.6,7.4H8.3V2.7h12.3C20.6,2.7,20.6,7.4,20.6,7.4z"
        />
      </g>
    </g>
  </Icon>
);
