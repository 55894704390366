import * as S from './Tournaments.styled';

import { Stages } from '../Stages/Stages';
import { Header } from './Header/Header';

export interface TeamCalendarProps {
  tournaments: any;
  onMatchClick?: any;
  debouncedSearchQuery: any;
  customerConcernId: any;
  onClickSubscribeMatch?: any;
  onClickStandings?: any;
  subscribedMatches?: number[];
  sportGroup: string;
  filterSubscribedMatches?: boolean;
}

export const Tournaments = ({
  tournaments,
  sportGroup,
  onClickStandings,
  debouncedSearchQuery,
  customerConcernId,
  onMatchClick,
  onClickSubscribeMatch,
  subscribedMatches,
  filterSubscribedMatches,
}: TeamCalendarProps) => {
  const isMatchSubscribed = (
    tournament: {
      id: any;
      stages: any;
    },
    subscribedMatches: any[] | undefined,
  ) => {
    return subscribedMatches?.some(
      (subscribedMatch: any) =>
        subscribedMatch.tournamentId === tournament.id &&
        Object.values(tournament.stages).some((stage: any) =>
          Object.values(stage.matches).some(
            (match: any) => subscribedMatch.id === match.id,
          ),
        ),
    );
  };
  if (!tournaments?.length && debouncedSearchQuery)
    return <S.NoHit>{`Ingen treff på "${debouncedSearchQuery}"`}</S.NoHit>;

  if (
    filterSubscribedMatches &&
    tournaments.filter((tournament: any) =>
      isMatchSubscribed(tournament, subscribedMatches),
    ).length === 0
  )
    return <S.NoHit>{`Ingen lagrede kamper`}</S.NoHit>;

  return (
    <S.Tournaments>
      {tournaments
        ?.filter((tournament: any) =>
          filterSubscribedMatches
            ? isMatchSubscribed(tournament, subscribedMatches)
            : tournament,
        )
        ?.map((tournament: any) => {
          return (
            <S.Tournament
              key={tournament.id}
              data-tournament-id={tournament.id}
            >
              <Header
                tournament={tournament}
                sportGroup={sportGroup}
                onClickStandings={onClickStandings}
                debouncedSearchQuery={debouncedSearchQuery}
              />
              <Stages
                stages={tournament.stages}
                debouncedSearchQuery={debouncedSearchQuery}
                onMatchClick={onMatchClick}
                onClickSubscribeMatch={onClickSubscribeMatch}
                subscribedMatches={subscribedMatches}
                customerConcernId={customerConcernId}
                onClickStandings={onClickStandings}
                filterSubscribedMatches={filterSubscribedMatches}
              />
            </S.Tournament>
          );
        })}
    </S.Tournaments>
  );
};

export default Tournaments;
