import * as S from './TransferMenuStyled.styled';
import { useBoolean } from '@ntb-sport/hooks';
import { useContext, useEffect, useRef } from 'react';
import {
  ALL_IN_OUT,
  TRANSFER_TYPE_FILTER_OPTIONS,
} from '../utils/TransferHelpers';
import { ELEMENT_IDS } from '@ntb-sport/constants';
import TransferContext from './libs/TransferMenuContext';

import {
  FilterTransferTypes,
  Results,
  TransferSearch,
  TransferViewToggle,
} from './libs/TransferMenuComponents';

export const TransferTeamMenu = ({
  isTournamentPage,
}: {
  isTournamentPage: boolean;
}) => {
  const {
    searchValue,
    setSearchValue,
    resultsAmount,
    isTableView,
    setIsTableView,
    visibleCount,
    currentTeamTransferStatus,
    setCurrentTeamTransferStatus,
    selectedTypeFilterOption,
    setSelectedTypeFilterOption,
  } = useContext(TransferContext);

  const [isTransferTypeOpen, setIsTransferTypeOpen] = useBoolean(false);

  const typesFormRef = useRef<HTMLFieldSetElement>(null);
  const typesBtnRef = useRef<HTMLButtonElement>(null);
  const typeFilterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        typesFormRef.current &&
        !typesFormRef.current.contains(event?.target as Node) &&
        typesBtnRef.current &&
        !typesBtnRef?.current?.contains(event?.target as Node)
      ) {
        setIsTransferTypeOpen.off();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isTransferTypeOpen]);

  return (
    <S.TransferMenu
      data-element-id={ELEMENT_IDS.MENU}
      aria-label="Overgangsmeny"
    >
      <S.TeamMenuContent>
        <S.TopRightDiv>
          <S.TypeFilter
            $isTournamentPage={!isTournamentPage}
            $isTransferTypeOpen={isTransferTypeOpen}
            $isNotAll={
              selectedTypeFilterOption !== TRANSFER_TYPE_FILTER_OPTIONS.ALLE
            }
            ref={typeFilterRef}
          >
            {FilterTransferTypes(
              selectedTypeFilterOption,
              isTransferTypeOpen,
              typesBtnRef,
              setIsTransferTypeOpen,
              typesFormRef,
              setSelectedTypeFilterOption,
            )}
          </S.TypeFilter>
          {TransferSearch(setSearchValue, searchValue)}
        </S.TopRightDiv>
        <S.BottomLeftDiv>
          <S.AllInOut>
            {Object.values(ALL_IN_OUT).map((status: string) => (
              <S.AllInOutBtn
                $isSelected={currentTeamTransferStatus === status}
                key={status}
                onClick={() => setCurrentTeamTransferStatus(status)}
              >
                {status}
              </S.AllInOutBtn>
            ))}
          </S.AllInOut>
          <S.ResultsSelectViewDiv>
            {Results(resultsAmount, visibleCount)}
            {TransferViewToggle(isTableView, setIsTableView)}
          </S.ResultsSelectViewDiv>
        </S.BottomLeftDiv>
      </S.TeamMenuContent>
    </S.TransferMenu>
  );
};
