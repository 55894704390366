import { Fetch, SPORTEDIT_API_BASE_URL, API_VERSION } from '../../fetch';

export interface FetchEventParticipantsByCountryAndDateProps {
  apiBaseUrl: string;
  countryUUID: string;
  token: string;
  customerUUID: string;
  date: string;
}

export const fetchEventParticipantsByCountryAndDate = async ({
  apiBaseUrl = SPORTEDIT_API_BASE_URL,
  countryUUID,
  token,
  customerUUID,
  date,
}: FetchEventParticipantsByCountryAndDateProps) => {
  const json = await Fetch({
    url: `${apiBaseUrl}/countries/${countryUUID}/participants?date=${date}`,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });

  return json.data;
};
