import { ELEMENT_IDS } from '@ntb-sport/constants';
import {
  EventComment,
  EventPerson,
  EventTeam,
  EventTitle,
  Time,
} from '../Event.styled';
import { EventTypeProps } from '../Event';
import { getEventTime } from '../getEventTime';

export const FreeKickEvent = ({
  event,
  showComments,
  personLink,
  teamLink,
}: EventTypeProps) => (
  <div>
    <EventTitle as="h3" data-element-id={ELEMENT_IDS.TITLE}>
      {Object.prototype.hasOwnProperty.call(event, 'time') && (
        <Time>{getEventTime(event)}</Time>
      )}
      {'Frispark til '}
      {event?.team && (
        <>
          {teamLink ? (
            <EventTeam>
              {teamLink({
                id: event?.team?.id,
                name: event?.team?.name,
                sportId: event?.sportId,
              })}
            </EventTeam>
          ) : (
            <EventTeam>{event?.team?.name}</EventTeam>
          )}
          {'.'}
        </>
      )}
      {event?.person && (
        <>
          {' Tas av '}
          {personLink && !event?.person?.hiddenPersonInfo ? (
            <EventPerson>
              {personLink({
                id: event?.person?.id,
                name: event?.person?.name,
                sportId: event?.sportId,
              })}
            </EventPerson>
          ) : (
            <EventPerson>{event?.person?.name}</EventPerson>
          )}

          {'.'}
        </>
      )}
    </EventTitle>

    {showComments && <EventComment>{event?.comment}</EventComment>}
  </div>
);

export default FreeKickEvent;
