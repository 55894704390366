import { NeoParticipantType } from '@ntb-sport/types';
import { Participant } from '../Participant/Participant';
import { useBem } from '@ntb-sport/hooks';
import { COMPONENT_IDS } from '@ntb-sport/constants';
import './participants.css';

interface ParticipantsProps {
  eventParticipants: NeoParticipantType[];
  highlightedCountry: string;
}

export const Participants = ({
  eventParticipants,
  highlightedCountry,
}: ParticipantsProps) => {
  const bem = useBem(COMPONENT_IDS.EVENT_PARTICIPANTS);

  return (
    <ul className={bem('list')}>
      {eventParticipants
        ?.sort((a: NeoParticipantType, b: NeoParticipantType) => {
          if (a?.startingOrder || b?.startingOrder) {
            return a?.startingOrder < b?.startingOrder ? -1 : 0;
          } else {
            return a?.bibNumber < b?.bibNumber ? -1 : 0;
          }
        })
        ?.map((eventParticipant: NeoParticipantType) => (
          <Participant
            key={eventParticipant?.uuid}
            eventParticipant={eventParticipant}
            highlightedCountry={highlightedCountry}
          />
        ))}
    </ul>
  );
};
