import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '@ntb-sport/constants';
import { fetchEventParticipants, FetchEventParticipantsType } from './fetch';
import {
  SocketContext,
  SocketContextType,
  useSportEditSocketHandleRooms,
} from '@ntb-sport/socket';
import { SPORT_GROUP } from '@ntb-sport/constants';
import { API_VERSION } from '../../fetch';
import { useContext } from 'react';

interface UseQueryEventParticipantsParams extends FetchEventParticipantsType {
  sportGroup: string;
}

interface UseQueryEventParticipants {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: UseQueryEventParticipantsParams;
  options?: Record<string, unknown>;
  enableSocket?: boolean;
}

export const useQueryEventParticipants = ({
  queryKey = QUERY_KEYS.EVENT_PARTICIPANTS,
  queryKeyParams,
  params,
  options,
  enableSocket = false,
}: UseQueryEventParticipants) => {
  const { whitelistEvents } = useContext<SocketContextType>(SocketContext);

  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchEventParticipants(params),
    enabled:
      Boolean(params.customerUUID) &&
      Boolean(params.token) &&
      Boolean(params.eventUUID),
    ...options,
  });

  useSportEditSocketHandleRooms({
    enabled:
      enableSocket &&
      Boolean(params.eventUUID) &&
      (params.sportGroup === SPORT_GROUP.CYCLING ||
        Boolean(
          whitelistEvents?.find(
            (event: { uuid: string }) => event.uuid === params.eventUUID,
          ),
        )),
    roomId: `/${params?.version || API_VERSION.V4}/events/${params.eventUUID}/participants`,
  });

  return { data, isLoading, error };
};
