import * as S from './Events.styled';

import { MATCH_EVENTS, MATCH_STATUS } from '@ntb-sport/constants';

import Event from '../Event/Event';

const excludedEvents = [
  MATCH_EVENTS.SUBSTITUTE_ON,
  MATCH_EVENTS.ASSIST,
  MATCH_EVENTS.INDIRECT_ASSIST,
  MATCH_EVENTS.PENALTY_GOAL,
  MATCH_EVENTS.PENALTY_PENALTIES_SHOOTOUT,
  MATCH_EVENTS.PENALTY_OFFENCE,
  MATCH_EVENTS.PENALTY_FOULED,
  MATCH_EVENTS.PENALTY_SAVE,
  MATCH_EVENTS.SUMMARY,
];

export const Events = ({
  events,
  matchStatusId,
  showComments,
  personLink,
  teamLink,
  onClickHighlightVideo,
  customerConcernId,
}: {
  events: any;
  matchStatusId: any;
  showComments: boolean;
  personLink: any;
  teamLink: any;
  onClickHighlightVideo: any;
  customerConcernId: any;
}) => (
  <S.Events>
    {matchStatusId === MATCH_STATUS.NOT_STARTED && (
      <Event event={{ matchEventTypeId: 'NotStarted' }} showComments={true} />
    )}
    {events &&
      events
        .filter(
          (event: any) => !excludedEvents.includes(event.matchEventTypeId),
        )
        .map((event: any) => {
          if (event.matchEventTypeId === MATCH_EVENTS.PLAYER_SUBSTITUTED) {
            const connectedEvent = events.find(
              (evt: any) =>
                evt.sorting === event.sorting &&
                evt.matchEventTypeId === MATCH_EVENTS.SUBSTITUTE_ON,
            );

            event = {
              ...event,
              connectedEvent,
            };
          } else if (event.matchEventTypeId === MATCH_EVENTS.GOAL) {
            const connectedEvent = events.find(
              (evt: any) =>
                evt.sorting === event.sorting &&
                (evt.matchEventTypeId === MATCH_EVENTS.ASSIST ||
                  evt.matchEventTypeId === MATCH_EVENTS.INDIRECT_ASSIST),
            );
            event = {
              ...event,
              connectedEvent,
            };
          } else if (
            event.matchEventTypeId === MATCH_EVENTS.GOAL_PENALTY_SHOOTOUT
          ) {
            const connectedEvent = events.find(
              (evt: any) =>
                evt.sorting === event.sorting &&
                evt.matchEventTypeId ===
                  MATCH_EVENTS.PENALTY_PENALTIES_SHOOTOUT,
            );
            event = {
              ...event,
              connectedEvent,
            };
          } else if (event.matchEventTypeId === MATCH_EVENTS.OWN_GOAL) {
            const connectedEvent = events.find(
              (evt: any) =>
                evt.sorting === event.sorting &&
                (evt.matchEventTypeId === MATCH_EVENTS.ASSIST ||
                  evt.matchEventTypeId === MATCH_EVENTS.INDIRECT_ASSIST),
            );
            event = {
              ...event,
              connectedEvent,
            };
          } else if (event.matchEventTypeId === MATCH_EVENTS.PENALTY) {
            const connectedEvents = events.filter(
              (evt: any) =>
                evt.sorting === event.sorting &&
                (evt.matchEventTypeId === MATCH_EVENTS.PENALTY_OFFENCE ||
                  evt.matchEventTypeId === MATCH_EVENTS.PENALTY_FOULED),
            );
            event = {
              ...event,
              connectedEvents,
            };
          } else if (
            event.matchEventTypeId === MATCH_EVENTS.PENALTY_MISS ||
            event.matchEventTypeId === MATCH_EVENTS.MISS_PENALTY_SHOOTOUT
          ) {
            const connectedEvent = events.filter(
              (evt: any) =>
                evt.sorting === event.sorting &&
                evt.matchEventTypeId === MATCH_EVENTS.PENALTY_SAVE,
            );
            event = {
              ...event,
              connectedEvent,
            };
          }

          return (
            <Event
              key={event.id}
              event={event}
              personLink={personLink}
              teamLink={teamLink}
              onClickHighlightVideo={onClickHighlightVideo}
              customerConcernId={customerConcernId}
              showComments={showComments}
            />
          );
        })}
  </S.Events>
);

export default Events;
