import styled, { css } from 'styled-components';

import { MatchesType } from '@ntb-sport/types';

interface StyledProps {
  $matches?: MatchesType;
  $gap?: string;
  $justify?: string;
  $align?: string;
  displayMode?: string;
  displayBorder?: boolean;
}

export const Container = styled.div<StyledProps>`
  width: 100%;
  background-color: var(--background-color, #fff);
  color: var(--font-color);
`;

export const FlexContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: row;

  width: 100%;

  justify-content: ${(props) =>
    props.$justify ? props.$justify : 'flex-start)'};
  align-items: ${(props) => (props.$align ? props.$align : 'flex-start)')};

  row-gap: ${(props) => (props.$gap ? props.$gap : 'var(--ntb-space-4)')};
  column-gap: ${(props) => (props.$gap ? props.$gap : 'var(--ntb-space-8)')};

  background-color: var(--background-color, #fff);
  color: var(--font-color);
`;

export const Header = styled.div<StyledProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--ntb-space-3);
  box-shadow: inset 0px -1px 0px var(--header-shadow-color);

  background-color: var(--header-background-color);
  color: var(--header-font-color);

  ${(props) =>
    props?.$matches?.mobileL &&
    css`
      padding: var(--ntb-space-3);
    `};

  ${(props) =>
    props?.$matches?.tablet &&
    css`
      padding: var(--ntb-space-3);
    `};
`;

export const DistrictName = styled.h2<StyledProps>`
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-medium);
  margin: 0;
  color: var(--header-font-color);

  ${(props) =>
    props?.$matches?.mobileL &&
    css`
      font-size: var(--ntb-fontSizes-sm);
    `};

  ${(props) =>
    props?.$matches?.tablet &&
    css`
      font-size: var(--ntb-fontSizes-lg);
    `};
`;

export const TableInfo = styled.div<StyledProps>`
  position: absolute;
  z-index: 999;
  background-color: var(--table-info-background-color, #fff);
  color: var(--table-info-font-color);
  left: var(--ntb-space-2);
  right: var(--ntb-space-2);
  top: var(--ntb-space-14);
  padding: var(--ntb-space-4);
  box-shadow: -2px 0 15px 0px rgba(0, 0, 0, 0.2);
  border-radius: var(--ntb-radii-md);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: var(--ntb-space-4);
  column-gap: var(--ntb-space-8);

  ${(props) =>
    props?.$matches?.tablet &&
    css`
      flex-direction: column;
      left: auto;
    `};

  h3 {
    margin-bottom: var(--ntb-space-2);
    font-size: var(--ntb-fontSizes-sm);
    font-weight: var(--ntb-fontWeights-semibold);
  }
`;

export const TableDescription = styled.dl<StyledProps>`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: var(--ntb-space-4);
  grid-row-gap: var(--ntb-space-1);
  font-size: var(--ntb-fontSizes-xs);

  > dt {
    font-weight: var(--ntb-fontWeights-medium);
  }

  dd {
    margin: 0;
  }

  dl {
    display: grid;
    grid-template-columns: 10px 1fr;
    grid-column-gap: 10px;
  }
`;

export const SortingRules = styled.div<StyledProps>`
  h3 {
    margin-bottom: var(--ntb-space-2);
    font-size: var(--ntb-fontSizes-sm);
    font-weight: var(--ntb-fontWeights-semibold);
  }

  ol {
    margin: 0;
    padding: 0 0 0 15px;
    font-size: var(--ntb-fontSizes-xs);

    li {
      margin: 0 0 var(--ntb-space-1) 0;

      &::marker {
        font-weight: var(--ntb-fontWeights-semibold);
      }
    }
  }
`;

export const TableContainer = styled.div<StyledProps>``;

export const NoStandings = styled.div`
  font-size: var(--ntb-fontSizes-md);
  text-align: center;
  padding: var(--ntb-space-6);
`;

export const HeaderLogo = styled.img<StyledProps>`
  max-width: 75px;
  max-height: 30px;
`;
