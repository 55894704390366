const iconSizes = {
  xsmall: {
    width: 12,
    height: 12,
  },
  small: {
    width: 18,
    height: 18,
  },
  medium: {
    width: 24,
    height: 24,
  },
  large: {
    width: 30,
    height: 30,
  },
  xlarge: {
    width: 36,
    height: 36,
  },
};

export const DefaultIcon = ({
  size,
  children,
  viewbox = '0 0 22.7 22.7',
  className,
  ...restProps
}: {
  size?: any;
  children: any;
  viewbox?: any;
  className?: string;
}) => {
  const width =
    size && iconSizes[size as keyof typeof iconSizes]
      ? iconSizes[size as keyof typeof iconSizes].width
      : iconSizes['medium'].width;
  const height =
    size && iconSizes[size as keyof typeof iconSizes]
      ? iconSizes[size as keyof typeof iconSizes].height
      : iconSizes['medium'].height;

  return (
    <svg
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      xmlSpace="preserve"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewbox}
      width={width}
      height={height}
      fill="currentColor"
      {...restProps}
    >
      {children}
    </svg>
  );
};

export default DefaultIcon;
