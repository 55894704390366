import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '@ntb-sport/constants';
import { fetchSports, FetchSports } from './fetch';

interface UseQuerySports {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: FetchSports;
  options?: Record<string, unknown>;
}

export const useQuerySports = ({
  queryKey = QUERY_KEYS.SPORTS,
  queryKeyParams,
  params,
  options,
}: UseQuerySports) => {
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchSports(params),
    enabled: Boolean(params.customerUUID) && Boolean(params.token),
    ...options,
  });

  return { data, isLoading, error };
};
