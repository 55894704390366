import { Fetch, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchTransfersType {
  apiBaseUrl: string;
  stageId?: string | number;
  teamId?: string | number;
}

const headers = {
  'Nifs-Identificator': NIFS_IDENTIFICATOR,
};

export const fetchTransfersTournament = async ({
  apiBaseUrl,
  stageId,
}: FetchTransfersType) => {
  const json = await Fetch({
    url: `${apiBaseUrl}/stages/${stageId}/playerTransfers/?includeLoans=1&includeFutureTransfers=1/`,
    headers,
  });
  return json;
};

export const fetchTransfersTeam = async ({
  apiBaseUrl,
  teamId,
}: FetchTransfersType) => {
  const json = await Promise.all([
    Fetch({
      url: `${apiBaseUrl}/playerTransfers/?toTeamId=${teamId}&includeLoans=1&includeFutureTransfers=1`,
      headers,
    }),
    Fetch({
      url: `${apiBaseUrl}/playerTransfers/?fromTeamId=${teamId}&includeLoans=1&includeFutureTransfers=1`,
      headers,
    }),
  ]);
  return json;
};
