import { useCallback, useRef, useState } from 'react';

export const useDrag = () => {
  const [clicked, setClicked] = useState(false);
  const [dragging, setDragging] = useState(false);
  const position = useRef(0);

  const dragStart = useCallback((ev: any) => {
    position.current;
    position.current = ev.clientX;
    setClicked(true);
  }, []);

  const dragStop = useCallback(
    () =>
      window.requestAnimationFrame(() => {
        setDragging(false);
        setClicked(false);
      }),
    []
  );
  const touchStart = useCallback((ev: any) => {
    position.current;
    position.current = ev?.changedTouches?.[0]?.pageX;
    setClicked(true);
  }, []);

  const dragMove = (ev: any, cb: any) => {
    const newDiff = position.current - ev.clientX;

    const movedEnough = Math.abs(newDiff) > 5;

    if (clicked && movedEnough) {
      setDragging(true);
    }

    if (dragging && movedEnough) {
      position.current = ev.clientX;
      cb(newDiff);
    }
  };
  
  const touchMove = (ev: any, cb: any) => {
    const newDiff = position.current - ev?.changedTouches?.[0]?.pageX;

    const movedEnough = Math.abs(newDiff) > 5;

    if (clicked && movedEnough) {
      setDragging(true);
    }

    if (dragging && movedEnough) {
      position.current = ev?.changedTouches?.[0]?.pageX;
      cb(newDiff);
    }
  };

  return {
    dragStart,
    dragStop,
    dragMove,
    dragging,
    position,
    setDragging,
    touchStart,
    touchMove,
  };
};

export default useDrag;
