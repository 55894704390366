import styled, { css } from 'styled-components';
import { ArrowIcon } from '../../icons';
import { CountryFlag as Flag } from '../../common/CountryFlag/CountryFlag';
import { MatchesType } from '@ntb-sport/types';

interface StyledProps {
  $matches?: MatchesType;
  $isClickable?: boolean;
  $src?: string;
}

export const Container = styled.div``;

export const Stats = styled.div`
  display: flex;
  flex-direction: column;
  /* row-gap: var(--ntb-space-2); */
`;

export const HighlightedStat = styled.div<StyledProps>`
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: space-between;
  padding: var(--ntb-space-4);
  background-color: rgba(10, 35, 67, 0.85);
  background: ${(props) =>
    `linear-gradient(90deg, rgba(10, 35, 67, 1), rgba(10, 35, 67, 0.7), rgba(10, 35, 67, 0.1)), url(${props?.$src})`};
  background-position: bottom right;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const Stat = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--ntb-space-2) var(--ntb-space-4);
  border-bottom: 1px solid var(--base-colors-border);
`;

export const RankAndBio = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-4);
`;

export const TeamAndCountry = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
`;

export const PlayerName = styled.div`
  font-size: var(--ntb-fontSizes-md);
  font-weight: var(--ntb-fontWeights-medium);
`;

export const HighlightedPlayerName = styled.div`
  font-size: var(--ntb-fontSizes-2xl);
  font-weight: var(--ntb-fontWeights-medium);
  color: var(--base-colors-whiteAlpha-900);
`;

export const HighlightedBio = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--ntb-space-4);
`;

export const StatCounts = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
`;

export const StatCountHeader = styled.div`
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-semibold);
  min-width: 30px;
  text-align: center;
`;

export const StatCount = styled.div`
  font-size: var(--ntb-fontSizes-md);
  font-weight: var(--ntb-fontWeights-semibold);
  background: #384a63;
  padding: 6px;
  border-radius: 99999px;
  color: white;
  width: 30px;
  display: flex;
  justify-content: center;
  height: 30px;
  align-items: center;
`;

export const HighlightedStatCount = styled.div`
  display: flex;
  column-gap: var(--ntb-space-1);
  align-items: baseline;
  /* padding: var(--ntb-space-4); */
  font-size: var(--ntb-fontSizes-5xl);
  font-weight: var(--ntb-fontWeights-medium);
  color: #a0b7d7;
`;

export const HighlightedStatCountLabel = styled.span`
  /* padding: var(--ntb-space-4); */
  font-size: var(--ntb-fontSizes-3xl);
  font-weight: var(--ntb-fontWeights-medium);
  color: #a0b7d7;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TH = styled.th<StyledProps>`
  padding: ${(props) => (props?.$matches?.tablet ? '8px' : '6px')};
  text-align: left;
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-semibold);
`;

export const TD = styled.td<StyledProps>`
  padding: ${(props) => (props?.$matches?.tablet ? '8px' : '6px')};
  box-shadow: inset 0px -1px 0px rgb(0 0 0 / 5%);
  align-items: center;
`;

export const RankHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

export const Rank = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-medium);
`;

export const RankLabel = styled.span<StyledProps>`
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-light);
`;

export const HighlightedRank = styled.div<StyledProps>`
  font-size: var(--ntb-fontSizes-2xl);
  font-weight: var(--ntb-fontWeights-medium);
  color: var(--base-colors-whiteAlpha-900);
`;

export const Player = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);

  ${(props) =>
    props?.$isClickable &&
    css`
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `}
`;

export const ProfileImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid var(--base-colors-border);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    object-fit: cover;
    max-width: 100%;
    border-radius: 50%;
  }
`;

export const HighlightedProfileImage = styled.div`
  width: 45%;
  max-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    object-fit: cover;
    max-width: 100%;
  }
`;

export const Team = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-light);
  ${(props) =>
    props?.$isClickable &&
    css`
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `}
`;

export const HighlightedTeam = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
  font-size: var(--ntb-fontSizes-lg);
  font-weight: var(--ntb-fontWeighs-normal);
  color: var(--base-colors-whiteAlpha-900);
`;

export const TeamLogo = styled.div`
  width: 20px;
  height: 20px;

  img {
    max-width: 100%;
  }
`;

export const Country = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-light);
`;

export const CountryFlag = styled(Flag)`
  min-width: 25px;
  max-height: 20px;
`;

export const SortArrows = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

export const SortArrowUp = styled(ArrowIcon)`
  fill: ${(props) => props.theme.fontColor};
  width: 8px;
  height: 8px;
  transform: rotate(-180deg);
`;

export const SortArrowDown = styled(ArrowIcon)`
  fill: ${(props) => props.theme.fontColor};
  width: 8px;
  height: 8px;
  transform: rotate(0deg);
`;

export const GoalsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Goals = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) =>
    props.$matches?.tablet
      ? 'var(--ntb-fontSizes-sm)'
      : 'var(--ntb-fontSizes-xs)'};
  font-weight: var(--ntb-fontWeights-medium);
`;
