import { createContext } from 'react';
import { NifsSocket } from './NifsSocket';
import { NeoSocket } from './NeoSocket';
import { SportEditSocket } from './SportEditSocket';

export interface SocketContextType {
  sockets: {
    nifsSocket?: NifsSocket;
    neoSocket?: NeoSocket;
    sportEditSocket?: SportEditSocket;
  };
  whitelistEvents?: { uuid: string }[];
}

interface SocketProviderProps {
  sockets: {
    nifsSocket?: NifsSocket;
    neoSocket?: NeoSocket;
    sportEditSocket?: SportEditSocket;
  };
  whitelistEvents?: { uuid: string }[];
  children: React.ReactNode;
}

export const SocketContext = createContext({} as SocketContextType);

export const SocketProvider = ({
  sockets,
  whitelistEvents,
  children,
}: SocketProviderProps) => {
  return (
    <SocketContext.Provider value={{ sockets, whitelistEvents }}>
      {children}
    </SocketContext.Provider>
  );
};
