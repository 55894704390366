import { useBem } from '@ntb-sport/hooks';
// import * as S from './Weekdays.styled';
import { COMPONENT_IDS } from '@ntb-sport/constants';
import './weekdays.css';

interface WeekdaysProps {
  weekdays: string[];
  month: number;
  year: number;
}

export const Weekdays = ({ weekdays, month, year }: WeekdaysProps) => {
  const bem = useBem(COMPONENT_IDS.DATE_SELECTOR);

  return (
    <div className={bem('weekdays')} aria-label="Ukedager">
      {weekdays.map((weekday) => (
        <div className={bem('weekday')} key={`${month}${year}${weekday}`}>
          {weekday}
        </div>
      ))}
    </div>
  );
};
