import { useBem } from '@ntb-sport/hooks';
import CountryFlag from '../../common/CountryFlag/CountryFlag';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import './event-participant.css';

import {
  NeoLineupExchangerType,
  NeoPersonType,
  NeoRepresentingType,
  NeoTeamType,
} from '@ntb-sport/types';
import classnames from 'classnames';

interface EventParticipantType {
  team?: NeoTeamType;
  person?: NeoPersonType;
  lineup?: any;
  lineupExchanger?: NeoLineupExchangerType;
  representing?: NeoRepresentingType;
  bibNumber?: number;
  displayBibNumber?: boolean;
  displayFullName?: boolean;
  displayInline?: boolean;
  isPelotonParticipant?: boolean;
}

export const EventParticipant = ({
  team,
  person,
  lineup,
  lineupExchanger,
  representing,
  bibNumber,
  displayBibNumber = true,
  displayFullName = false,
  displayInline = false,
  isPelotonParticipant,
}: EventParticipantType) => {
  const bem = useBem('event-participant');

  const country =
    team?.countries?.[0] ||
    team?.country ||
    person?.country ||
    person?.countries?.[0];
  const countryName = !isPelotonParticipant && country?.name;
  const countryCode = person?.countries?.[0]?.names?.find(
    (name: { nameTypeId: number }) => name?.nameTypeId === 25,
  )?.name;
  const teamCountryCode = team?.country?.names?.find(
    (name: { name: string; nameTypeId: number }) => name?.nameTypeId === 25,
  )?.name;

  return (
    <div className={bem()}>
      <CountryFlag countryCode={countryCode || teamCountryCode} size="xs" />
      <div
        className={classnames(bem('container'), {
          [bem('container', 'inline')]: displayInline,
        })}
      >
        <div className={bem('names')}>
          {displayBibNumber && bibNumber && (
            <span className={bem('bib-number')}>{bibNumber}</span>
          )}
          {displayFullName ? (
            <span className={bem('primary-name')}>{person?.name}</span>
          ) : (
            <>
              <span className={bem('primary-name')}>
                {person?.lastName || person?.name}
              </span>
              {person?.firstName && (
                <span className={bem('secondary-name')}>
                  {person?.firstName}
                </span>
              )}
            </>
          )}
        </div>

        <div
          className={classnames(bem('tertiary-name'), {
            [bem('tertiary-name', 'inline')]: displayInline,
          })}
        >
          {representing
            ? representing?.name
            : lineup
              ? lineup?.person?.name ||
                lineup?.map((participant: any, index: number) => (
                  <div className={bem('lineup')} key={index}>
                    {participant?.number
                      ? `${participant?.number}. ${participant?.person?.name}`
                      : participant?.person?.name}
                  </div>
                ))
              : team
                ? team.name
                : countryName}
          {lineupExchanger && (
            <div className={bem('exchanger')}>
              <ArrowForwardIcon /> {lineupExchanger?.person?.name}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventParticipant;
