import { EventTypeProps } from '../Event';
import {
  EventComment,
  EventPerson,
  EventTeam,
  EventTitle,
  Time,
} from '../Event.styled';

import { ELEMENT_IDS, MATCH_EVENTS } from '@ntb-sport/constants';
import { getEventTime } from '../getEventTime';

export const PenaltyEvent = ({
  event,
  showComments,
  personLink,
  teamLink,
}: EventTypeProps) => {
  const offenceEvent = event?.connectedEvents.find(
    (event: any) => event.matchEventTypeId === MATCH_EVENTS.PENALTY_OFFENCE,
  );
  const fouledEvent = event?.connectedEvents.find(
    (event: any) => event.matchEventTypeId === MATCH_EVENTS.PENALTY_FOULED,
  );

  return (
    <div>
      <EventTitle as="h3" data-element-id={ELEMENT_IDS.TITLE}>
        {Object.prototype.hasOwnProperty.call(event, 'time') && (
          <Time>{getEventTime(event)}</Time>
        )}
        {`Straffe til `}

        {teamLink ? (
          <EventTeam>
            {teamLink({
              id: event?.team?.id,
              name: event?.team?.name,
              sportId: event?.sportId,
            })}
          </EventTeam>
        ) : (
          <EventTeam>{event?.team?.name}</EventTeam>
        )}
        {`. `}

        {fouledEvent && (
          <>
            {personLink && !event?.person?.hiddenPersonInfo ? (
              <EventPerson>
                {personLink({
                  id: fouledEvent?.person?.id,
                  name: fouledEvent?.person?.name,
                  sportId: fouledEvent?.sportId,
                })}
              </EventPerson>
            ) : (
              <EventPerson>{fouledEvent?.person?.name}</EventPerson>
            )}
            {` blir tildelt straffesparket. `}
          </>
        )}

        {offenceEvent && (
          <>
            {personLink ? (
              <EventPerson>
                {personLink({
                  id: offenceEvent?.person?.id,
                  name: offenceEvent?.person?.name,
                  sportId: offenceEvent?.sportId,
                })}
              </EventPerson>
            ) : (
              <EventPerson>{offenceEvent?.person?.name}</EventPerson>
            )}
            {` forårsaker straffen. `}
          </>
        )}

        {event.person && (
          <>
            {'Straffen tas av '}
            {personLink && !event?.person?.hiddenPersonInfo ? (
              <EventPerson>
                {personLink({
                  id: event?.person?.id,
                  name: event?.person?.name,
                  sportId: event?.sportId,
                })}
              </EventPerson>
            ) : (
              <EventPerson>{event?.person?.name}</EventPerson>
            )}
            {'.'}
          </>
        )}
      </EventTitle>

      {showComments && <EventComment>{event?.comment}</EventComment>}
    </div>
  );
};

export default PenaltyEvent;
