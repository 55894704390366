import {
  COMPONENT_IDS,
  DATA_PICKER_GROUPS,
  DATA_PROVIDERS,
  SPORT_GROUP,
} from '@ntb-sport/constants';
import { WidgetConfig, SportsdataConfig } from '@ntb-sport/types';
import {
  DATA_TYPES,
  WIDGET_IDS,
  WIDGET_OPTIONS,
  WIDGET_VERSION,
} from '../common';

export const config: WidgetConfig = {
  id: WIDGET_IDS.EXAMPLE,
  dataType: DATA_TYPES.NIFS,
  version: WIDGET_VERSION,
  displayName: 'Example Widget',
  widgetNamespace: 'NtbWidgetExample',
  iframeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.EXAMPLE}/iframe.html`,
  widgetUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.EXAMPLE}/${WIDGET_IDS.EXAMPLE}.js`,
  themeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/themes/default.css`,
  title: 'Example Widget',
  summary: 'Brukes som eksempel for dokumentasjonssiden',
  sportGroups: [SPORT_GROUP.FOOTBALL, SPORT_GROUP.HANDBALL],
  description: 'general description to be displayed on the documentation page',
  components: [],
  options: {
    [WIDGET_OPTIONS.CUSTOMER_ID]: { isRequired: true },
    [WIDGET_OPTIONS.ERROR_MESSAGE]: { isRequired: false },
    [WIDGET_OPTIONS.ID]: { isRequired: true },
    [WIDGET_OPTIONS.ON_ERROR]: { isRequired: false },
    [WIDGET_OPTIONS.SPORT_GROUP]: { isRequired: true },
    [WIDGET_OPTIONS.STYLE_ID]: { isRequired: false },
    [WIDGET_OPTIONS.TARGET_ID]: { isRequired: true },
    [WIDGET_OPTIONS.ROUND]: { isRequired: true },
  },
  uiOptions: {
    [COMPONENT_IDS.EXAMPLE_COMPONENT_ONE]: [
      WIDGET_OPTIONS.TITLE,
      WIDGET_OPTIONS.SHOW_STAGE_NAME,
    ],
    [COMPONENT_IDS.EXAMPLE_COMPONENT_TWO]: [WIDGET_OPTIONS.HIGHLIGHTED_TEAMS],
  },
};

export const sportsdataConfig: SportsdataConfig = {
  dataPickerGroups: {
    [DATA_PICKER_GROUPS.NIFS_STAGE_GROUP]: {
      title: 'Stage',
      key: DATA_PICKER_GROUPS.NIFS_STAGE_GROUP,
      primaryId: DATA_PROVIDERS.NIFS_STAGES,
      isDefault: true,
      pickers: {
        [DATA_PROVIDERS.SPORT_GROUP]: { defaultValue: 'football' },
        [DATA_PROVIDERS.NIFS_COUNTRIES]: { defaultValue: 2 },
        [DATA_PROVIDERS.NIFS_TOURNAMENTS]: {},
        [DATA_PROVIDERS.NIFS_STAGES]: { isPrimaryId: true },
      },
    },
  },
  unique: {
    [WIDGET_OPTIONS.ROUND]: {
      key: WIDGET_OPTIONS.ROUND,
      defaultValue: '7',
      label: 'Round',
      type: 'input',
    },
  },
  optionGroups: {
    [COMPONENT_IDS.EXAMPLE_COMPONENT_ONE]: {
      title: 'Header',
      key: COMPONENT_IDS.EXAMPLE_COMPONENT_ONE,
      options: {
        [WIDGET_OPTIONS.TITLE]: {
          key: WIDGET_OPTIONS.TITLE,
          defaultValue: 'Premier League',
          label: 'Title',
          type: 'input',
        },
        [WIDGET_OPTIONS.SHOW_STAGE_NAME]: {
          key: WIDGET_OPTIONS.SHOW_STAGE_NAME,
          defaultValue: true,
          label: 'Vis logo',
          type: 'checkbox',
        },
      },
    },
    [COMPONENT_IDS.EXAMPLE_COMPONENT_TWO]: {
      title: 'Fixture list',
      key: COMPONENT_IDS.EXAMPLE_COMPONENT_TWO,
      options: {
        [WIDGET_OPTIONS.HIGHLIGHTED_TEAMS]: {
          key: WIDGET_OPTIONS.HIGHLIGHTED_TEAMS,
          defaultValue: '',
          label: 'Highlight team',
          type: 'input',
        },
      },
    },
  },
};
