import { Icon } from '../../chakra';

export const SubstituteIcon = (props: any) => (
  <Icon>
    <g>
      <g>
        <g>
          <path
            fill={props?.fillColor1 || 'currentColor'}
            d="M12.7,18.9c-7.4,0-9.3-5.4-9.8-7.1H0.9c-0.2,0-0.3-0.1-0.4-0.3s-0.1-0.3,0-0.5L5,4c0.1-0.2,0.3-0.3,0.4-0.3
  S5.8,3.9,5.9,4l4.6,7c0.1,0.2,0.1,0.3,0,0.5c-0.1,0.2-0.3,0.3-0.4,0.3h-2c0.3,5.4,4.5,6,4.7,6c0.3,0,0.4,0.3,0.4,0.5
  C13.3,18.6,13,18.9,12.7,18.9z M1.8,10.8h1.7c0.3,0,0.4,0.2,0.5,0.4c0,0.3,0.8,4.8,5.7,6.2c-1.3-1-2.5-3-2.5-6.1
  c0-0.3,0.3-0.5,0.5-0.5h1.7L5.5,5.3L1.8,10.8z"
          />
        </g>
        <g>
          <path
            fill={props?.fillColor2 || 'currentColor'}
            d="M17.1,18.9c-0.2,0-0.3-0.1-0.4-0.3l-4.6-7c-0.1-0.2-0.1-0.3,0-0.5s0.3-0.3,0.4-0.3h2c-0.3-5.4-4.5-6-4.7-6
  c-0.3,0-0.4-0.3-0.4-0.5c0-0.3,0.3-0.4,0.5-0.4c7.4,0,9.3,5.4,9.8,7.1h2.2c0.2,0,0.3,0.1,0.4,0.3s0.1,0.3,0,0.5l-4.6,7
  C17.5,18.8,17.3,18.9,17.1,18.9z M13.3,11.9l3.8,5.6l3.8-5.6h-1.7c-0.3,0-0.4-0.2-0.5-0.4c0-0.3-0.8-4.8-5.7-6.2
  c1.3,1,2.5,3,2.5,6.1c0,0.3-0.3,0.5-0.5,0.5C15,11.9,13.3,11.9,13.3,11.9z"
          />
        </g>
      </g>
    </g>
  </Icon>
);
