import * as S from './Switch.styled';

import { ThemeProvider } from 'styled-components';
import defaultTheme from './Switch.theme';
import { useStateWithDep } from '@ntb-sport/hooks';

export const SwitchOld = ({
  on,
  onToggle,
  label,
  size,
  theme,
  matches,
  ...props
}: {
  on: any;
  onToggle: any;
  label: any;
  size: any;
  theme: any;
  matches: any;
}) => {
  const [isOn, setIsOn] = useStateWithDep(on);

  const toggle = () => {
    setIsOn(!isOn);
    onToggle && onToggle(!isOn);
  };

  return (
    <ThemeProvider theme={{ ...defaultTheme, ...theme }}>
      <S.Switch isOn={isOn} onClick={toggle} aria-expanded={on} {...props}>
        {label && <span>{label}</span>}
        <S.Toggle isOn={isOn} aria-hidden="true" size={size} />
      </S.Switch>
    </ThemeProvider>
  );
};
