import {
  Row,
  Cell,
  DividerCell,
  DividerRow,
  FlexWrapper,
  HeaderCell,
  SortArrowDown,
  SortArrowUp,
  SortArrows,
  StyledTable,
} from './Table.styled';
import { useSortBy, useTable } from 'react-table';

import { Fragment } from 'react';

import { ELEMENT_IDS } from '@ntb-sport/constants';
import { Skeleton } from '../../chakra';

const Table = ({
  columns,
  data,
  isLoading,
  relegationLineTeamId,
  relegationPlayoffLineTeamId,
  promotionLineTeamId,
  promotionPlayoffLineTeamId,
  splitPlace,
  splitRound,
  hasFormData,
  highlightedTeams,
}: {
  columns: any;
  data: any;
  isLoading: any;
  relegationLineTeamId: any;
  relegationPlayoffLineTeamId?: any;
  promotionLineTeamId?: any;
  promotionPlayoffLineTeamId?: any;
  splitPlace?: any;
  splitRound?: any;
  hasFormData?: any;
  highlightedTeams?: number[];
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          hiddenColumns: columns.reduce((hiddenColumns: any, column: any) => {
            if (!column.isVisible) {
              hiddenColumns.push(column.accessor);
            }
            return hiddenColumns;
          }, []),
        },
      },
      useSortBy,
    );

  return (
    <StyledTable {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <HeaderCell
                key={column.id}
                $isSorted={column.isSorted}
                {...column.styles}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <FlexWrapper
                    {...column.styles}
                    aria-label={column?.meta?.ariaLabel}
                  >
                    <p aria-hidden={true}>{column.render('Header')}</p>
                    {column.isSortable && (
                      <SortArrows aria-hidden={true}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortArrowUp />
                          ) : (
                            <SortArrowDown />
                          )
                        ) : (
                          <>
                            <SortArrowUp />
                            <SortArrowDown />
                          </>
                        )}
                      </SortArrows>
                    )}
                  </FlexWrapper>
                )}
              </HeaderCell>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any) => {
          prepareRow(row);
          if (row.index === splitPlace) {
            return (
              <Fragment key={row.id}>
                <DividerRow>
                  <DividerCell colSpan={hasFormData ? 13 : 12}>
                    Serien deles etter {splitRound} runder.
                  </DividerCell>
                </DividerRow>
                <Row {...row.getRowProps()} data-element-id={ELEMENT_IDS.ROW}>
                  {row.cells.map((cell: any) => (
                    <Cell
                      key={cell.id}
                      aria-label={cell.column?.meta?.ariaLabel}
                      $isSorted={cell.column.isSorted}
                      $displayDividerBottom={
                        cell.row.original.id === promotionLineTeamId ||
                        cell.row.original.id === promotionPlayoffLineTeamId
                      }
                      $displayDividerTop={
                        cell.row.original.id === relegationLineTeamId ||
                        cell.row.original.id === relegationPlayoffLineTeamId
                      }
                      {...cell.column.styles}
                      {...cell.getCellProps()}
                    >
                      {isLoading ? <Skeleton /> : cell.render('Cell')}
                    </Cell>
                  ))}
                </Row>
              </Fragment>
            );
          }
          return (
            <Row
              data-element-id={ELEMENT_IDS.ROW}
              key={row.id}
              $highlightedTeam={highlightedTeams?.includes(row?.original?.id)}
              $displayDividerBottom={
                row.original.id === promotionLineTeamId ||
                row.original.id === promotionPlayoffLineTeamId
              }
              $displayDividerTop={
                row.original.id === relegationLineTeamId ||
                row.original.id === relegationPlayoffLineTeamId
              }
              {...row.getRowProps()}
            >
              {row.cells.map((cell: any) => (
                <Cell
                  key={cell.id}
                  aria-label={cell.column?.meta?.ariaLabel}
                  $isSorted={cell.column.isSorted}
                  $displayDividerBottom={
                    cell.row.original.id === promotionLineTeamId ||
                    cell.row.original.id === promotionPlayoffLineTeamId
                  }
                  $displayDividerTop={
                    cell.row.original.id === relegationLineTeamId ||
                    cell.row.original.id === relegationPlayoffLineTeamId
                  }
                  $highlightedTeam={highlightedTeams?.includes(
                    row?.original?.id,
                  )}
                  {...cell.column.styles}
                  {...cell.getCellProps()}
                >
                  {isLoading ? <Skeleton /> : cell.render('Cell')}
                </Cell>
              ))}
            </Row>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

export default Table;
