import * as S from './MediaPlayer.styled';
import AudioAnimation from './AudioAnimation';
import Draggable from 'react-draggable';
import { useEffect, useRef, useState } from 'react';

interface MediaPlayerProps {
  onClose: () => void;
  children: any;
  isRadio: boolean;
}

export const MediaPlayer = ({
  children,
  onClose,
  isRadio,
}: MediaPlayerProps) => {
  const mediaPlayerRef = useRef<any>();
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();

  useEffect(() => {
    setHeight(mediaPlayerRef?.current?.clientHeight);
    setWidth(mediaPlayerRef?.current?.clientWidth);
  }, []);

  return isRadio ? (
    <>
      <S.Controller onClick={onClose} data-style-id="nrk">
        <AudioAnimation />
        NRK Sport Live
        <S.CloseMediaPlayerIcon />
      </S.Controller>
      <S.MediaPlayerContainer $isRadio={isRadio}>
        <S.Player>{children}</S.Player>
      </S.MediaPlayerContainer>
    </>
  ) : (
    <Draggable handle=".handle" bounds="body">
      <S.MediaPlayerContainer
        $isRadio={isRadio}
        ref={mediaPlayerRef}
        $offsetHeight={height}
        $offsetWidth={width}
        data-style-id="nrk"
      >
        <S.Controllers>
          <S.MoveMediaPlayer className="handle">
            <S.MoveMediaPlayerIcon size="24" />
          </S.MoveMediaPlayer>
          <S.CloseMediaPlayer onClick={onClose}>
            <S.CloseMediaPlayerIcon />
          </S.CloseMediaPlayer>
        </S.Controllers>
        <S.Player>{children}</S.Player>
      </S.MediaPlayerContainer>
    </Draggable>
  );
};

export default MediaPlayer;
