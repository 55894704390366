import { MEDIA_QUERY } from '@ntb-sport/constants';

import styled from 'styled-components';

export const Container = styled.div`
  container-type: inline-size;
  container-name: team-match-result-container;
  position: relative;
  background: var(--background-color);
  color: var(--font-color);
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  padding: var(--ntb-space-2) 0;
  box-shadow: inset 0 -1px 0 var(--border-color);

  @container team-match-result-container ${MEDIA_QUERY.TABLET} {
    padding: var(--ntb-space-4) 0;
  }
`;

export const Teams = styled.div`
  display: grid;
  grid-template-areas:
    'hometeam result result awayteam'
    'homeevents homeevents awayevents awayevents'
    'shootout shootout shootout shootout'
    'statistics statistics statistics statistics';
  grid-template-columns: 2fr 1fr 1fr 2fr;
  grid-column-gap: var(--ntb-space-4);
  grid-row-gap: var(--ntb-space-6);
  justify-content: center;
  align-items: center;

  @container team-match-result-container ${MEDIA_QUERY.MOBILE_L} {
    grid-template-areas:
      'hometeam result awayteam'
      'homeevents . awayevents'
      'shootout shootout shootout'
      'statistics statistics statistics';
    grid-template-columns: repeat(3, 1fr);
  }

  @container team-match-result-container ${MEDIA_QUERY.TABLET} {
    grid-template-areas:
      'hometeam result awayteam'
      'homeevents shootout awayevents'
      'statistics statistics statistics';
    grid-template-columns: repeat(3, 1fr);
    max-width: 800px;
    margin: 0 auto;
  }
`;

export const PenaltyShootoutContainer = styled.div`
  grid-area: shootout;
`;

export const MatchComment = styled.div`
  text-align: center;
  color: var(--font-color);
  font-size: var(--ntb-fontSizes-sm);

  @container team-match-result-container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-md);
  }
`;

export const MatchStreams = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: var(--ntb-space-4);
  margin-bottom: var(--ntb-space-4);
`;
