import Icon from '../Icon/Icon';

export const GoalHandballIcon = (props: any) => (
  <Icon {...props}>
    <g>
      <path
        d="M11.3,0.1C5.1,0.1,0.1,5,0.1,11.2s5.1,11.2,11.2,11.2c6.3,0,11.2-5.1,11.2-11.2S17.5,0.1,11.3,0.1z M19.5,4.7
		c1.3,1.6,1.8,3.4,2,4.3h-0.1c-0.2-0.9-0.7-2.5-2-3.4C19.5,5.3,19.5,5,19.5,4.7z M20.5,9.6C20.5,9.6,20.5,9.7,20.5,9.6
		c0.2,1.9-0.8,2.9-1.2,3.2l0,0c-0.3,0-1.3,0.1-2.5,0c-0.3-1.2-1.6-2.8-2.1-3.5c0.5-1.1,1-2.6,1.2-3.2c1.4-0.4,2.6,0,2.8,0.1
		C20,7.1,20.5,9.4,20.5,9.6z M7.6,13.2c0.7-1.3,1.8-3.1,2.1-3.6c0.6,0,2.8-0.1,4,0.2c0.8,1,2,2.5,2.1,3.4c-0.3,0.8-1.2,2.6-2.1,3.3
		c-0.5,0.1-2.5,0.5-4.2,0C8.8,15.6,7.8,14,7.6,13.2z M8.7,17c-0.7,0.8-1.4,1.4-1.7,1.5c-0.4-0.1-2.1-0.5-3.2-2
		c-0.1-0.3-0.4-1.7,0-2.9c0.5,0,1.6,0.1,2.4,0.1c0.1,0,0.3,0,0.4,0C7.1,14.7,8.2,16.3,8.7,17z M14,1.3C14.1,1.3,14.1,1.3,14,1.3
		C14.1,1.3,14.1,1.3,14,1.3c2.4,0.5,3.7,1.9,3.8,2l0,0l0,0l0,0c0.7,0.6,0.7,1.4,0.6,1.9c-0.6-0.2-1.7-0.3-2.8,0
		c-0.6-0.9-2.1-2-3.2-2.2c0-0.5-0.1-1.1-0.1-1.4C13.3,1.1,14,1.3,14,1.3z M12,3.8c0.8,0,2.4,1.3,3,2.1c-0.2,0.5-0.7,2-1.1,3
		c-1.3-0.3-3.1-0.2-3.9-0.2C9.5,7.4,8.9,6,8.8,5.5C9.3,5,11.2,3.8,12,3.8L12,3.8z M4.8,3.7c0.8-2,3.6-2.2,3.9-2.2l0,0h0.1
		c1.3-0.2,2.1,0,2.5,0.2c0.1,0.4,0.1,1,0.1,1.4c-1.1,0.3-2.7,1.3-3.4,2C7.5,4.6,6.2,4.4,4.8,4.8C4.7,4.4,4.8,3.8,4.8,3.7z M6.3,5.5
		c0.7,0,1.2,0.2,1.5,0.3C7.9,6.2,8.3,7.1,9,9.1C8.4,9.9,7.3,12,6.8,12.8c-1.2,0-2.6-0.1-3.1-0.1c-1.1-1.1-1.2-3.1-1.2-3.4
		C2.9,7.1,4.5,6,4.6,5.9C5.3,5.6,5.8,5.5,6.3,5.5z M3,5c0.1-0.1,0.2-0.2,0.3-0.4c0.2-0.2,0.4-0.3,0.5-0.4c0,0.3,0,0.7,0.1,1
		C3.4,5.7,2.1,6.8,1.7,8.7C1.5,8.6,1.3,8.6,1.2,8.5C1.3,7.6,2.3,5.9,3,5z M1.2,13.7c-0.3-1.5-0.4-2.9-0.3-3.4c0-0.1,0-0.3,0.1-0.4
		l0,0l0,0c0.1-0.2,0.1-0.3,0.2-0.4c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0.8,0.3,2.4,1.4,3.6c-0.4,1.3-0.3,2.5-0.1,3.2
		c-0.2,0-0.5,0.1-0.7,0.1C2,16.3,1.5,15.7,1.2,13.7z M6.4,20.4c-0.4-0.2-0.8-0.5-1.3-0.8c-1.3-0.7-2-1.7-2.2-2.2
		c0.2,0,0.3-0.1,0.5-0.1c1.2,1.5,2.8,2,3.5,2.1c0.1,0.4,0.4,1.1,0.9,1.7C7.3,20.9,6.9,20.7,6.4,20.4z M13.5,20.9L13.5,20.9
		C13.4,20.9,13.4,21,13.5,20.9c-1.5,0.5-4,0.1-4.4,0l0,0l0,0c-0.4-0.1-0.8-0.5-1-1c-0.2-0.3-0.3-0.6-0.4-0.8
		c0.6-0.4,1.4-1.2,1.8-1.7c0.7,0.2,1.4,0.2,2,0.2c1,0,1.9-0.1,2.3-0.2c0.7,1.1,1.2,1.6,1.5,1.9C14.6,20.7,13.6,20.9,13.5,20.9z
		 M16.2,20.4c-0.2,0.1-0.4,0.2-0.6,0.3c0.3-0.3,0.5-0.6,0.7-1c0.3,0,0.7-0.1,1.2-0.2c0.1,0,0.3-0.1,0.4-0.2
		C17.2,19.8,16.7,20.1,16.2,20.4z M19.1,16.8c-1,2-2.7,2-3,2c-0.2-0.2-0.9-1-1.4-1.7c1-0.9,1.7-2.5,2-3.2c1.2,0.1,2.1,0,2.5,0
		C19.7,15.2,19.1,16.7,19.1,16.8z M20.1,16.8c0.2-0.7,0.5-2,0-3.3c0.5-0.5,1.4-1.7,1.4-3.4c0.1,0,0.1,0,0.2,0
		C22.4,12.8,20.7,16,20.1,16.8C20.2,16.8,20.1,16.8,20.1,16.8z"
      />
    </g>
  </Icon>
);
