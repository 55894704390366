import * as S from './PenaltyShootout.styled';

import { MATCH_EVENTS } from '@ntb-sport/constants';

const PenaltyShootout = ({
  matchEvents,
  homeTeamId,
  awayTeamId,
}: {
  matchEvents: any;
  homeTeamId: any;
  awayTeamId: any;
}) => {
  if (!matchEvents || !matchEvents.length) {
    return null;
  }

  const penalties = matchEvents
    .filter(
      (event: any) =>
        event.matchEventTypeId === MATCH_EVENTS.GOAL_PENALTY_SHOOTOUT ||
        event.matchEventTypeId === MATCH_EVENTS.MISS_PENALTY_SHOOTOUT,
    )
    ?.sort((a: any, b: any) => (a?.sorting < b?.sorting ? -1 : 0))
    ?.reduce((acc: any, penalty: any, index: number) => {
      const isNewGroup = index % 2 === 0;
      const key = Math.round(index / 2) + 1;

      if (isNewGroup) {
        acc[key] = {
          group: index / 2 + 1,
          penalties: [penalty],
        };
      }

      if (!isNewGroup) {
        acc[key - 1]?.penalties.push(penalty);
      }

      return acc;
    }, {});

  if (!Object.keys(penalties)?.length) return null;

  return (
    <>
      <S.Title>Straffesparkkonkurranse</S.Title>

      <S.Penalties>
        {Object.values(penalties)?.map((item: any) => {
          const homeTeamPenalty = item?.penalties?.find(
            (penalty: any) => penalty?.team?.id === homeTeamId,
          );
          const awayTeamPenalty = item?.penalties?.find(
            (penalty: any) => penalty?.team?.id === awayTeamId,
          );
          return (
            <S.Penalty key={item?.group}>
              <S.Side $side="hometeam">
                <S.PlayerName $side="hometeam">
                  {homeTeamPenalty?.person?.shirtName ||
                    homeTeamPenalty?.person?.name}
                </S.PlayerName>
                {homeTeamPenalty?.matchEventTypeId === 30 && (
                  <S.PenaltyShootoutGoal aria-hidden="true" size="small" />
                )}
                {homeTeamPenalty?.matchEventTypeId === 31 && (
                  <S.PenaltyShootoutMiss aria-hidden="true" size="small" />
                )}
              </S.Side>
              <S.Number>{`${item?.group}. str`}</S.Number>
              <S.Side $side="awayTeam">
                {awayTeamPenalty?.matchEventTypeId === 30 && (
                  <S.PenaltyShootoutGoal aria-hidden="true" size="small" />
                )}
                {awayTeamPenalty?.matchEventTypeId === 31 && (
                  <S.PenaltyShootoutMiss aria-hidden="true" size="small" />
                )}
                <S.PlayerName>
                  {awayTeamPenalty?.person?.shirtName ||
                    awayTeamPenalty?.person?.name}
                </S.PlayerName>
              </S.Side>
            </S.Penalty>
          );
        })}
      </S.Penalties>
    </>
  );
};

export default PenaltyShootout;
