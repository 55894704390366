import { SPORT_GROUP } from '@ntb-sport/constants';
import { Fetch, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchAthletesType {
  apiBaseUrl: string;
  sportGroup: string;
  query: string;
  customerId: number;
}

export const fetchAthletes = async ({
  apiBaseUrl,
  sportGroup,
  query,
  customerId,
}: FetchAthletesType) => {
  const path =
    sportGroup === SPORT_GROUP.ICE_HOCKEY || sportGroup === SPORT_GROUP.HANDBALL
      ? 'nifPersons'
      : 'persons';

  const json = await Fetch({
    url: `${apiBaseUrl}/${path}/?search=${query}&customerId=${customerId}&quick=1`,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });

  return json;
};
