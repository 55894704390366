import { MenuButton as ChakraMenuButton } from '@chakra-ui/react';
import { forwardRef } from 'react';

export const MenuButton = forwardRef(function MenuButton(props: any, ref: any) {
  return (
    <ChakraMenuButton {...props} ref={ref}>
      {props.children}
    </ChakraMenuButton>
  );
});

export default MenuButton;
