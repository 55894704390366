import { useBem } from '@ntb-sport/hooks';
import './shot-series.css';

import { NeoShotSeriesType } from '@ntb-sport/types';
import classNames from 'classnames';
import { AimIcon, BulletIcon } from '../../../../../icons';

interface ShotSeriesProps {
  allShotSeries: NeoShotSeriesType[];
  scopeNumber: number;
  participantUUID?: string;
  isRelay: boolean;
}

interface TotalType {
  hits: number;
  misses: number;
  numberOfShots: number;
  additionalShots: number;
}

const ShotSeries = ({
  allShotSeries,
  scopeNumber,
  participantUUID,
  isRelay,
}: ShotSeriesProps) => {
  const bem = useBem('scope-results');

  const total = allShotSeries
    ?.filter(
      (shotseries: NeoShotSeriesType) =>
        shotseries?.eventParticipantUUID === participantUUID &&
        shotseries?.number <= scopeNumber,
    )
    ?.reduce(
      (total: TotalType, shotseries: NeoShotSeriesType) => {
        total.hits += shotseries?.hits;
        total.misses += shotseries?.misses;
        total.numberOfShots += shotseries?.numberOfShots;

        if (shotseries?.additionalShots) {
          total.additionalShots += shotseries?.additionalShots;
        }

        return total;
      },
      {
        hits: 0,
        misses: 0,
        numberOfShots: 0,
        additionalShots: 0,
      },
    );

  const totalMisses = isRelay
    ? total?.misses - total?.additionalShots
    : total?.misses;

  return (
    <div
      className={classNames(bem('shot-series'), {
        [bem('shot-series', 'relay')]: isRelay,
      })}
    >
      <div
        className={classNames(bem('shots'), {
          [bem('shots', 'error')]: totalMisses > 0,
        })}
      >
        {totalMisses}
        <AimIcon
          className={classNames(bem('icon'), {
            [bem('icon', 'error')]: totalMisses > 0,
          })}
        />
      </div>

      {isRelay && (
        <div
          className={classNames(bem('shots'), {
            [bem('shots', 'error')]: total?.additionalShots > 0,
          })}
        >
          {total?.additionalShots}
          <BulletIcon
            className={classNames(bem('icon'), {
              [bem('icon', 'error')]: total?.additionalShots > 0,
            })}
          />
        </div>
      )}
    </div>
  );
};

export default ShotSeries;
