import { RepeatIcon } from '@chakra-ui/icons';

import { ApiErrorResetBoundary } from '@ntb-sport/api';

import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import {
  Button,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '../../chakra';

const FallbackErrorComponent = ({
  error,
  onError,
  errorMessage = 'Beklager, det har oppstått en feil.',
  resetErrorBoundary,
}: {
  error: Error;
  onError?: ({
    error,
    errorMessage,
  }: {
    error: Error;
    errorMessage: string;
  }) => void;
  errorMessage?: string;
  resetErrorBoundary: () => void;
}) => {
  onError && onError({ error, errorMessage });
  return (
    <Alert
      status="error"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        {errorMessage}
      </AlertTitle>
      <AlertDescription maxWidth="sm" mb={4}>
        Vi er ikke helt sikre på hva som har skjedd, men noe har gått galt.
      </AlertDescription>
      <Button
        colorScheme="red"
        onClick={() => resetErrorBoundary()}
        leftIcon={<RepeatIcon />}
      >
        Prøv igjen
      </Button>
    </Alert>
  );
};

interface ErrorBoundaryProps {
  children: any;
  errorComponent?: any;
  onError?: () => void;
  errorMessage?: string;
}

export const ErrorBoundary = ({
  children,
  errorComponent,
  errorMessage,
  onError,
}: ErrorBoundaryProps) => (
  <ApiErrorResetBoundary>
    {({ reset }: { reset: any }) => {
      return (
        <ReactErrorBoundary
          onReset={reset}
          fallbackRender={({ error, resetErrorBoundary }) =>
            errorComponent && error ? (
              errorComponent({ error, resetErrorBoundary })
            ) : (
              <FallbackErrorComponent
                error={error}
                errorMessage={errorMessage}
                resetErrorBoundary={resetErrorBoundary}
                onError={onError}
              />
            )
          }
        >
          {children}
        </ReactErrorBoundary>
      );
    }}
  </ApiErrorResetBoundary>
);
