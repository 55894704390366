import { EventType } from '../types';
import { SubEventGroup } from '../SubEventGroup/SubEventGroup';

import { useBem } from '@ntb-sport/hooks';
import { COMPONENT_IDS } from '@ntb-sport/constants';
import './sub-events.css';

interface SubEventsProps {
  events: EventType[];
}

export const SubEvents = ({ events }: SubEventsProps) => {
  const bem = useBem(COMPONENT_IDS.SPORTEDIT_CALENDAR);
  return (
    <ul className={bem('sub-events')}>
      {Object.values(events)
        ?.sort((a: EventType, b: EventType) =>
          a?.timestamp < b?.timestamp ? -1 : 0,
        )
        ?.map((eventGroup: EventType) => {
          return (
            <SubEventGroup key={eventGroup?.uuid} eventGroup={eventGroup} />
          );
        })}
    </ul>
  );
};
