import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '@ntb-sport/constants';
import { fetchMedalTable, FetchMedalTableProps } from './fetch';

interface UseQueryMedalTableParams extends FetchMedalTableProps {
  sportGroup: string;
}

interface UseQueryMedalTable {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: UseQueryMedalTableParams;
  options?: Record<string, unknown>;
}

export const useQueryMedalTable = ({
  queryKey = QUERY_KEYS.MEDAL_TABLE,
  queryKeyParams,
  params,
  options,
}: UseQueryMedalTable) => {
  // const isSportEditSportGroup = getIsNeoSportGroup(params.sportGroup);

  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchMedalTable(params),
    enabled:
      // isSportEditSportGroup &&
      Boolean(params.medalTableUUID) &&
      Boolean(params.customerUUID) &&
      Boolean(params.token),
    ...options,
  });

  return { data, isLoading, error };
};
