import { MatchesType, NifsTeam } from '@ntb-sport/types';
import * as S from './Team.styled';
import ClubLogo from '../../ClubLogo/ClubLogo';

interface TeamProps {
  team: NifsTeam;
  isHomeTeam?: boolean;
  teamLink: any;
  matches: MatchesType;
  isLoading?: boolean;
}

const Team = ({
  team,
  isHomeTeam,
  teamLink,
  matches,
  isLoading,
}: TeamProps) => {
  return (
    <S.Team $isHomeTeam={isHomeTeam}>
      <S.TeamLogoWrapper>
        {teamLink ? (
          teamLink({
            id: team?.id,
            children: (
              <ClubLogo
                src={team?.logo?.url}
                alt={team?.name}
                size={matches.desktopS ? 'xl' : matches.mobileM ? 'lg' : 'md'}
                name={
                  matches.mobileM ? team?.name : team?.shortName || team?.name
                }
                direction="column"
                isLoading={isLoading}
              />
            ),
          })
        ) : (
          <ClubLogo
            src={team?.logo?.url}
            alt={team?.name}
            size={matches.desktopS ? 'xl' : matches.mobileM ? 'lg' : 'md'}
            name={matches.mobileM ? team?.name : team?.shortName || team?.name}
            direction="column"
            isLoading={isLoading}
          />
        )}
      </S.TeamLogoWrapper>
    </S.Team>
  );
};

export default Team;
