import {
  COMPONENT_IDS,
  DATA_PICKER_GROUPS,
  DATA_PROVIDERS,
  SPORT_GROUP,
} from '@ntb-sport/constants';
import { WidgetConfig, SportsdataConfig } from '@ntb-sport/types';
import {
  DATA_TYPES,
  WIDGET_IDS,
  WIDGET_OPTIONS,
  WIDGET_VERSION,
} from '../common';

export const config: WidgetConfig = {
  id: WIDGET_IDS.TEAM_FIXTURES,
  dataType: DATA_TYPES.NIFS,
  version: WIDGET_VERSION,
  displayName: 'Terminliste lag',
  widgetNamespace: 'NtbWidgetTeamFixtures',
  iframeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.TEAM_FIXTURES}/iframe.html`,
  widgetUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.TEAM_FIXTURES}/${WIDGET_IDS.TEAM_FIXTURES}.js`,
  themeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/themes/default.css`,
  title: 'Terminliste lag',
  summary: 'Terminliste og resultater for et gitt lag',
  sportGroups: [
    SPORT_GROUP.FOOTBALL,
    SPORT_GROUP.HANDBALL,
    SPORT_GROUP.ICE_HOCKEY,
  ],
  description: 'general description to be displayed on the documentation page',
  components: [
    COMPONENT_IDS.TEAM_FIXTURES,
    COMPONENT_IDS.TEAM_FIXTURES_MATCH,
    COMPONENT_IDS.TEAM_FIXTURES_TEAM,
  ],
  options: {
    [WIDGET_OPTIONS.TARGET_ID]: { isRequired: true },
    [WIDGET_OPTIONS.SPORT_GROUP]: { isRequired: true },
    [WIDGET_OPTIONS.CUSTOMER_ID]: { isRequired: true },
    [WIDGET_OPTIONS.STYLE_ID]: { isRequired: false },
    [WIDGET_OPTIONS.ID]: { isRequired: true },
    [WIDGET_OPTIONS.ON_ERROR]: { isRequired: false },
    [WIDGET_OPTIONS.ERROR_MESSAGE]: { isRequired: false },
  },
  uiOptions: {},
};

export const sportsdataConfig: SportsdataConfig = {
  dataPickerGroups: {
    [DATA_PICKER_GROUPS.NIFS_TEAM_GROUP]: {
      title: 'Kamp',
      key: DATA_PICKER_GROUPS.NIFS_TEAM_GROUP,
      primaryId: DATA_PROVIDERS.NIFS_TEAM,
      isDefault: true,
      pickers: {
        [DATA_PROVIDERS.SPORT_GROUP]: { defaultValue: 'football' },
        [DATA_PROVIDERS.NIFS_TEAM]: { isPrimaryId: true },
      },
    },
  },
  optionGroups: {},
};
