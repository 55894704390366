import Kit from './Kit';
import ShirtNumber from './ShirtNumber';

const TwoColorGradientEdges = ({
  kitColor1 = '#f79025',
  kitColor2 = '#adadad',
  shirtNumber,
  shirtNumberColor,
  shirtNumberBackgroundColor,
  shirtNumberBackgroundOpacity,
  ...restProps
}: {
  size?: any;
  kitColor1: any;
  kitColor2: any;
  kitColor3: any;
  shirtNumber: any;
  shirtNumberColor: any;
  shirtNumberBackgroundColor: any;
  shirtNumberBackgroundOpacity: any;
}) => {
  const shadow = '#000000';
  const LineGradientDiv = shirtNumber + kitColor1 + kitColor2;
  const LineGradientDiv_URL =
    'url(#' + shirtNumber + kitColor1 + kitColor2 + ')';

  return (
    <Kit {...restProps}>
      <g>
        <g>
          <path
            style={{ fill: kitColor2 }}
            d="M69.15,2.82c2.77,0,7.29-0.36,8.68-0.53c1.38-0.17,3.61,0.2,4.68,0.53l2.08,3.19
                    c2.26,0.53,11.11,2.66,12.85,3.37s4,1.77,5.38,1.94c1.39,0.19,3.47,0.72,4.86,1.24c1.38,0.54,4.69,3.02,5.9,4.8
                    c1.21,1.77,8.24,16.3,9.8,18.62c1.57,2.3,12.42,22.34,12.94,23.23c0.52,0.88,1.03,3.19,0.17,3.36c-0.87,0.19-8.33,3.37-11.46,6.03
                    c-3.13,2.67-9.03,5.68-9.71,6.03c-0.71,0.35-1.14,0.14-1.83-0.58c-0.69-0.7-7.73-12.01-7.73-12.01c-1.21,2.66-1.21,5.67-1.04,7.62
                    c0.18,1.96-0.87,11.8-0.35,14.63c0.52,2.84,0.52,30.33,0.87,32.82c0.35,2.48,0.86,7.44,0.69,9.93c-0.18,2.48,0,5.67,0,7.09
                    c0,1.43-0.18,2.67-1.56,3.2c-1.39,0.53-13.89,3.9-35.24,3.19c-21.35,0.71-33.85-2.66-35.23-3.19c-1.38-0.53-1.56-1.77-1.56-3.2
                    c0-1.41,0.18-4.61,0-7.09c-0.18-2.49,0.35-7.45,0.69-9.93c0.35-2.49,0.35-29.98,0.87-32.82c0.51-2.83-0.52-12.68-0.35-14.63
                    c0.18-1.95,0.18-4.96-1.04-7.62c0,0-7.3,11.53-7.99,12.24c-0.69,0.71-0.87,0.71-1.56,0.35c-0.7-0.35-6.59-3.36-9.73-6.03
                    c-3.12-2.66-10.59-5.84-11.45-6.03c-0.87-0.17-0.35-2.48,0.17-3.36C2.5,58.33,13.43,38.47,15,36.16c1.56-2.32,8.5-17.04,9.72-18.8
                    c1.22-1.78,4.51-4.26,5.91-4.8c1.39-0.53,3.46-1.05,4.86-1.24c1.39-0.17,3.63-1.28,5.38-1.94c1.97-0.74,10.83-2.9,12.84-3.37
                    l2.08-3.19c0.87-0.35,3.3-0.71,4.69-0.53C61.86,2.46,66.38,2.82,69.15,2.82z"
          />
          <linearGradient
            id={LineGradientDiv}
            gradientUnits="userSpaceOnUse"
            x1="69.1573"
            y1="140.6333"
            x2="69.1573"
            y2="11.0415"
          >
            <stop offset="0" style={{ stopColor: kitColor1, stopOpacity: 0 }} />
            <stop offset="1" style={{ stopColor: kitColor1 }} />
          </linearGradient>
          <path
            fill={LineGradientDiv_URL}
            d="M105.95,127.05c-0.17,2.48,0,5.68,0,7.1c0,1.42-0.17,2.67-1.57,3.19
                    c-1.38,0.54-13.89,3.91-35.24,3.2c-21.35,0.71-33.84-2.67-35.24-3.2c-1.38-0.52-1.55-1.77-1.55-3.19c0-1.42,0.17-4.62,0-7.1
                    c-0.17-2.48,0.35-7.45,0.68-9.92c0.35-2.49,0.35-29.97,0.87-32.81c0.52-2.84-0.52-12.68-0.33-14.64c0.17-1.96,0.17-4.96-1.04-7.62
                    c0,0,0.04-14.25,0.08-20.41c0.04-5.62,0.64-17.8,4.26-21.17c4.3-4,8.53-7.12,15.39-8.92c0.36-0.09,0.72-0.19,1.1-0.25
                    c0.29,2.48,1.15,5.21,2.91,6.91c0.12,0.12,4.07-3.83,4.07-3.83s7.86,3.91,8.76,4.18c0.91-0.27,9.11-4.37,9.11-4.37
                    s2.58,3.96,2.75,3.84c2.01-1.62,3.12-4.45,3.59-6.99c0.46,0.11,0.91,0.2,1.35,0.32c6.86,1.79,11.08,4.92,15.39,8.93
                    c3.62,3.36,4.22,15.54,4.26,21.16c0.04,6.16,0.24,20.6,0.24,20.6c-1.22,2.67-1.22,5.67-1.04,7.62c0.17,1.96-0.87,11.8-0.35,14.64
                    c0.52,2.84,0.52,30.32,0.87,32.81C105.61,119.6,106.13,124.57,105.95,127.05z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M2.48,57.73c0.01,0.16,0.2,2.11,1,2.28c0.87,0.17,8.33,3.36,11.45,6.03c3.13,2.65,9.03,5.68,9.72,6.03
                    c0.63,0.32,1.82-0.03,1.95-0.08l-1.98,3.17h-2.16l-8.43-4.96l-4.51-3.32l-8.37-4.14L0.8,60.15L2.48,57.73z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M111.79,72.3c0.29,0.09,1.26,0.08,1.69-0.14c0.68-0.36,6.59-3.37,9.71-6.04
                    c3.13-2.65,10.81-5.96,11.68-6.15c0.74-0.15,1.25-1.72,1.29-2.05l1.7,3.72l-1.19,1.58l-8.54,4.07l-8.94,5.93l-4.83,2.25
                    L111.79,72.3z"
          />
          <g>
            <path
              style={{ fill: shadow }}
              d="M82.86,5.7c-0.01,0.13-0.04,0.27-0.08,0.4c-0.66,2.97-3.17,4.39-5.65,5.72c-0.55,0.29-1.1,0.62-1.63,0.95
                        c-0.82,0.5-1.63,1.02-2.38,1.53c-1.38,0.91-2.7,1.9-4.04,2.87c-0.12,0.07-0.93-0.69-1.05-0.78c-2.19-1.68-4.52-3.17-6.93-4.52
                        c-1.92-1.08-4.11-2.06-5.16-4.12c-0.04-0.08-0.08-0.17-0.12-0.25c-0.06-0.13-0.64-1.71-0.52-1.74c1.17-0.33,3.92-0.74,5.18-0.59
                        c1.39,0.19,5.83,0.54,8.6,0.54c1.81,0,3.62-0.11,5.42-0.24C77.31,5.25,80.08,4.9,82.86,5.7z"
            />
            <path
              opacity="0.16"
              d="M136.63,62.51c0.86-0.18,0.35-2.48-0.17-3.37c-0.3-0.51-3.93-7.08-7.31-13.12
                        c-6.25,2.94-14.52-0.07-23.84-13.57l0.34,9.94l0.12,19.66c0,0,7.03,11.31,7.73,12.01c0.69,0.71,1.25,0.86,1.95,0.51
                        c0.68-0.36,6.58-3.37,9.71-6.03C128.3,65.88,135.76,62.69,136.63,62.51z"
            />
            <path
              opacity="0.16"
              d="M1.39,62.64c-0.86-0.18-0.34-2.48,0.18-3.37c0.29-0.51,3.92-7.08,7.31-13.12
                        c6.25,2.94,15.6-0.46,24.23-14.08L32.54,45.6l-0.01,16.45c0,0-7.58,11.94-8.27,12.64c-0.7,0.71-1,0.35-1.69,0.01
                        c-0.69-0.36-6.59-3.37-9.72-6.03C9.73,66.01,2.26,62.82,1.39,62.64z"
            />
            <path
              opacity="0.16"
              d="M104.53,137.92c1.38-0.53,1.56-1.77,1.56-3.19c0-1.42-0.18-4.61,0-7.09c0.16-2.3-0.27-6.7-0.6-9.32
                        c-5.17,4.71-8.97,14.72-10.63,21.6C100.53,139.11,103.85,138.18,104.53,137.92z"
            />
            <path
              opacity="0.16"
              d="M33.8,137.85c-1.38-0.53-1.56-1.77-1.56-3.19c0-1.42,0.18-4.61,0-7.09c-0.15-2.3,0.27-6.7,0.61-9.32
                        c5.16,4.71,8.96,14.72,10.63,21.6C37.8,139.04,34.48,138.11,33.8,137.85z"
            />
            <g>
              <path
                d="M59.63,2.24c0.3,0,0.59,0.01,0.84,0.05c1.39,0.18,5.91,0.53,8.68,0.53s7.29-0.36,8.68-0.53c0.24-0.03,0.5-0.04,0.78-0.04
                            c1.33,0,3.01,0.3,3.9,0.58l2.08,3.19c2.26,0.53,11.11,2.66,12.85,3.37c1.74,0.71,4,1.77,5.38,1.94c1.39,0.19,3.47,0.72,4.86,1.24
                            c1.38,0.54,4.69,3.02,5.9,4.8c1.21,1.77,8.24,16.3,9.8,18.62c1.57,2.3,12.42,22.34,12.94,23.23c0.52,0.88,1.03,3.19,0.17,3.36
                            c-0.87,0.19-8.33,3.37-11.46,6.03c-3.13,2.67-9.03,5.68-9.71,6.03c-0.22,0.11-0.42,0.17-0.61,0.17c-0.4,0-0.75-0.25-1.22-0.74
                            c-0.69-0.7-7.73-12.01-7.73-12.01c-1.21,2.66-1.21,5.67-1.04,7.62c0.18,1.96-0.87,11.8-0.35,14.64
                            c0.52,2.84,0.52,30.33,0.87,32.82c0.35,2.48,0.86,7.44,0.69,9.93c-0.18,2.48,0,5.67,0,7.09c0,1.43-0.18,2.67-1.56,3.2
                            c-1.26,0.48-11.64,3.29-29.44,3.29c-1.85,0-3.78-0.03-5.8-0.1c-2.01,0.07-3.94,0.1-5.8,0.1c-17.81,0-28.18-2.81-29.44-3.29
                            c-1.38-0.53-1.56-1.77-1.56-3.2c0-1.41,0.18-4.61,0-7.09c-0.18-2.49,0.35-7.45,0.69-9.93c0.35-2.49,0.35-29.98,0.87-32.82
                            c0.51-2.83-0.52-12.68-0.35-14.64c0.18-1.95,0.18-4.96-1.04-7.62c0,0-7.3,11.53-7.99,12.24c-0.4,0.42-0.63,0.59-0.89,0.59
                            c-0.18,0-0.38-0.09-0.67-0.23c-0.7-0.35-6.59-3.36-9.73-6.03c-3.12-2.66-10.59-5.84-11.45-6.03c-0.87-0.17-0.35-2.48,0.17-3.36
                            C2.5,58.33,13.43,38.47,15,36.16c1.56-2.32,8.5-17.04,9.72-18.8c1.22-1.78,4.51-4.26,5.91-4.8c1.39-0.53,3.46-1.05,4.86-1.24
                            c1.39-0.17,3.63-1.28,5.38-1.94c1.97-0.74,10.83-2.9,12.84-3.37l2.08-3.19C56.5,2.53,58.26,2.24,59.63,2.24 M59.63,1.24
                            L59.63,1.24c-1.44,0-3.34,0.29-4.22,0.65c-0.19,0.08-0.35,0.21-0.46,0.38l-1.86,2.86C50.73,5.69,42.49,7.7,40.51,8.44
                            c-0.54,0.2-1.13,0.45-1.7,0.69c-1.22,0.51-2.61,1.09-3.45,1.2c-1.52,0.21-3.66,0.76-5.09,1.3c-1.65,0.64-5.1,3.31-6.38,5.17
                            c-0.62,0.9-2.42,4.52-4.5,8.72c-2.08,4.19-4.44,8.93-5.22,10.09C12.96,37.39,6.48,49.06,3,55.33c-1.03,1.85-1.77,3.18-1.89,3.39
                            c-0.45,0.78-1.11,2.8-0.57,3.97c0.21,0.47,0.6,0.78,1.08,0.87c0.67,0.16,7.95,3.22,10.99,5.81c3.22,2.75,9.25,5.82,9.93,6.17
                            c0.35,0.18,0.7,0.34,1.12,0.34c0.69,0,1.15-0.42,1.61-0.89c0.59-0.6,4.68-6.98,7-10.64c0.46,1.89,0.44,3.81,0.31,5.24
                            c-0.08,0.85,0.04,2.8,0.19,5.27c0.2,3.42,0.46,7.69,0.17,9.28c-0.28,1.54-0.41,8.92-0.55,19.19c-0.09,6.21-0.18,12.63-0.32,13.67
                            c-0.38,2.7-0.88,7.63-0.7,10.13c0.12,1.74,0.07,3.87,0.03,5.44c-0.02,0.63-0.03,1.18-0.03,1.58c0,1.07,0,3.29,2.2,4.13
                            c1.75,0.67,12.53,3.36,29.8,3.36c1.9,0,3.85-0.03,5.8-0.1c1.94,0.06,3.89,0.1,5.8,0.1c17.24,0,28.05-2.69,29.8-3.35
                            c2.2-0.84,2.2-3.07,2.2-4.13c0-0.4-0.01-0.95-0.03-1.58c-0.04-1.56-0.1-3.7,0.03-5.44c0.17-2.52-0.32-7.44-0.7-10.13
                            c-0.15-1.05-0.24-7.48-0.32-13.7c-0.14-10.25-0.27-17.62-0.55-19.16c-0.3-1.6-0.04-5.89,0.17-9.33c0.15-2.44,0.26-4.37,0.19-5.21
                            c-0.13-1.43-0.15-3.34,0.31-5.22c2.25,3.6,6.16,9.8,6.75,10.4c0.52,0.54,1.12,1.04,1.93,1.04c0.34,0,0.69-0.09,1.05-0.27
                            l0.08-0.04c2.02-1.04,7.01-3.72,9.83-6.13c2.95-2.5,10.12-5.6,11.02-5.81c0.46-0.09,0.84-0.4,1.05-0.87
                            c0.51-1.13-0.09-3.15-0.57-3.97c-0.12-0.21-0.94-1.71-1.98-3.61c-3.42-6.26-9.79-17.91-11-19.68c-0.78-1.16-3.16-5.85-5.26-9.99
                            c-2.11-4.15-3.93-7.74-4.54-8.64c-1.36-1.99-4.88-4.58-6.36-5.16c-1.45-0.55-3.58-1.09-5.09-1.3c-0.97-0.12-2.58-0.8-4-1.4
                            c-0.39-0.17-0.78-0.33-1.14-0.48C95.98,7.7,87.19,5.59,85.22,5.13l-1.86-2.86c-0.13-0.2-0.32-0.34-0.54-0.41
                            c-1.01-0.31-2.77-0.62-4.2-0.62c-0.33,0-0.63,0.02-0.9,0.05c-1.45,0.18-5.91,0.53-8.55,0.53c-2.64,0-7.13-0.34-8.55-0.53
                            C60.31,1.25,59.99,1.24,59.63,1.24L59.63,1.24z"
              />
            </g>
          </g>
        </g>
      </g>
      <ShirtNumber
        shirtNumber={shirtNumber}
        shirtNumberColor={shirtNumberColor}
        shirtNumberBackgroundColor={shirtNumberBackgroundColor}
        shirtNumberBackgroundOpacity={shirtNumberBackgroundOpacity}
      />
    </Kit>
  );
};

export default TwoColorGradientEdges;
