import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '@ntb-sport/constants';
import { fetchEvents, FetchEvents } from './fetch';
import { getIsNeoSportGroup } from '@ntb-sport/helpers';

interface UseQueryEventsParams extends FetchEvents {
  sportGroup: string;
}
interface UseQueryEvents {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: UseQueryEventsParams;
  options?: Record<string, unknown>;
}

export const useQueryEvents = ({
  queryKey = QUERY_KEYS.EVENTS,
  queryKeyParams,
  params,
  options,
}: UseQueryEvents) => {
  const isSportEditSportGroup = getIsNeoSportGroup(params.sportGroup);

  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchEvents(params),
    enabled:
      isSportEditSportGroup &&
      Boolean(params.customerUUID) &&
      Boolean(params.token) &&
      Boolean(params.date),
    ...options,
  });

  return { data, isLoading, error };
};
