import { LabelProperties, UUIDs } from 'neo-common-enums';
import { getChildEventsGroupedByRoundType } from './getChildEventsGroupedByRoundType';
import { getFirstChildEventTimestamp } from './getFirstChildEventTimestamp';

const getPriorityBySport = {
  [UUIDs.Sport.BIATHLON]: 1,
  [UUIDs.Sport.CROSS_COUNTRY_SKIING]: 2,
  [UUIDs.Sport.ALPINE]: 3,
  [UUIDs.Sport.SKI_JUMPING]: 4,
  [UUIDs.Sport.NORDIC_COMBINED]: 5,
  [UUIDs.Sport.SPEED_SKATING]: 6,
  [UUIDs.Sport.SHORT_TRACK_SPEED_SKATING]: 7,
  [UUIDs.Sport.SNOWBOARDING]: 8,
  [UUIDs.Sport.FREESTYLE_SKIING]: 9,
  [UUIDs.Sport.CURLING]: 10,
  [UUIDs.Sport.FIGURE_SKATING]: 11,
};

export const getEventsGroupedBySport = ({
  events,
  date,
  eventParticipantsByCountryAndDate,
}: {
  events: any;
  date: string;
  eventParticipantsByCountryAndDate: any;
}) => {
  if (!events) return null;

  const eventsGroupedBySport = events?.reduce((events: any, event: any) => {
    const sport = event?.seasons?.[0]?.competition?.sport;
    const competition = event?.seasons?.[0]?.competition;
    const host = event?.host;
    const tour = event?.tour;
    const childEvents = event?.childEvents;

    const childEventsGroupedByRoundType = getChildEventsGroupedByRoundType({
      childEvents,
      sport,
      date,
      eventParticipantsByCountryAndDate,
    });

    if (
      event?.eventStatusId !== LabelProperties.EventStatus.WILL_NOT_BE_HELD &&
      !event?.hidden &&
      !events[sport?.uuid]
    ) {
      events[sport?.uuid] = {
        name: sport?.name,
        uuid: sport?.uuid,
        priority:
          getPriorityBySport[sport?.uuid as keyof typeof getPriorityBySport],
        competitions: {},
      };
    }

    if (
      event?.eventStatusId !== LabelProperties.EventStatus.WILL_NOT_BE_HELD &&
      !event?.hidden &&
      !events[sport?.uuid]?.competitions[competition?.uuid]
    ) {
      events[sport?.uuid].competitions[competition.uuid] = {
        ...competition,
        host,
        tour,
        events: [],
      };
    }

    if (
      event?.eventStatusId !== LabelProperties.EventStatus.WILL_NOT_BE_HELD &&
      !event?.hidden
    ) {
      const firstChildEventTimestamp = getFirstChildEventTimestamp({
        childEvents,
        date,
      });

      const hasAthletesFromCountry = Boolean(
        eventParticipantsByCountryAndDate?.find(
          (e: any) => e?.uuid === event?.uuid,
        )?.eventParticipants?.length,
      );

      events[sport?.uuid]?.competitions?.[competition?.uuid]?.events.push({
        ...event,
        childEventsGroupedByRoundType,
        hasAthletesFromCountry,
        timestamp: event?.timestamp || firstChildEventTimestamp,
      });
    }

    return events;
  }, {});

  return eventsGroupedBySport;
};
