import Kit from './Kit';
import ShirtNumber from './ShirtNumber';

const TwoColorWithArmsCollarArmsBottom = ({
  kitColor1 = '#f79025',
  kitColor2 = '#adadad',
  kitColor3 = '#b5b5b5',
  shirtNumber,
  shirtNumberColor,
  shirtNumberBackgroundColor,
  shirtNumberBackgroundOpacity,
  ...restProps
}: {
  size?: any;
  kitColor1: any;
  kitColor2: any;
  kitColor3: any;
  shirtNumber: any;
  shirtNumberColor: any;
  shirtNumberBackgroundColor: any;
  shirtNumberBackgroundOpacity: any;
}) => {
  const shadow = '#000000';

  return (
    <Kit {...restProps}>
      <g>
        <path
          style={{ fill: kitColor1 }}
          d="M69.2,2.8c2.8,0,7.3-0.4,8.7-0.5c1.4-0.2,3.6,0.2,4.7,0.5L84.6,6c2.3,0.5,11.1,2.7,12.8,3.4s4,1.8,5.4,1.9
				c1.4,0.2,3.5,0.7,4.9,1.2c1.4,0.5,4.7,3,5.9,4.8c1.2,1.8,8.2,16.3,9.8,18.6c1.6,2.3,12.4,22.3,12.9,23.2c0.5,0.9,1,3.2,0.2,3.4
				c-0.9,0.2-8.3,3.4-11.5,6c-3.1,2.7-9,5.7-9.7,6c-0.7,0.3-1.1,0.1-1.8-0.6c-0.7-0.7-7.7-12-7.7-12c-1.2,2.7-1.2,5.7-1,7.6
				c0.2,2-0.9,11.8-0.3,14.6c0.5,2.8,0.5,30.3,0.9,32.8c0.3,2.5,0.9,7.4,0.7,9.9c-0.2,2.5,0,5.7,0,7.1c0,1.4-0.2,2.7-1.6,3.2
				c-1.4,0.5-13.9,3.9-35.2,3.2c-21.3,0.7-33.8-2.7-35.2-3.2s-1.6-1.8-1.6-3.2c0-1.4,0.2-4.6,0-7.1c-0.2-2.5,0.3-7.4,0.7-9.9
				c0.3-2.5,0.3-30,0.9-32.8c0.5-2.8-0.5-12.7-0.3-14.6c0.2-1.9,0.2-5-1-7.6c0,0-7.3,11.5-8,12.2S23.7,75,23,74.6
				c-0.7-0.3-6.6-3.4-9.7-6c-3.1-2.7-10.6-5.8-11.4-6c-0.9-0.2-0.4-2.5,0.2-3.4c0.5-0.9,11.5-20.7,13-23.1c1.6-2.3,8.5-17,9.7-18.8
				c1.2-1.8,4.5-4.3,5.9-4.8c1.4-0.5,3.5-1.1,4.9-1.2c1.4-0.2,3.6-1.3,5.4-1.9c2-0.7,10.8-2.9,12.8-3.4l2.1-3.2
				c0.9-0.3,3.3-0.7,4.7-0.5C61.9,2.5,66.4,2.8,69.2,2.8z"
        />
        <path
          style={{ fill: kitColor2 }}
          d="M69.1,140.5c-21.3,0.7-33.8-2.7-35.2-3.2s-1.6-1.8-1.6-3.2c0-1.4,0.2-4.6,0-7.1c-0.2-2.5,0.3-7.4,0.7-9.9
				c0.3-2.5,0.3-30,0.9-32.8c0.5-2.8-0.5-12.7-0.3-14.6c0.2-1.9,0.2-5-1-7.6c0,0-7.3,11.5-8,12.2S23.7,75,23,74.6
				c-0.7-0.3-6.6-3.4-9.7-6c-3.1-2.7-10.6-5.8-11.4-6c-0.9-0.2-0.4-2.5,0.2-3.4c0.5-0.9,11.5-20.7,13-23.1c1.6-2.3,8.5-17,9.7-18.8
				c1.2-1.8,4.5-4.3,5.9-4.8c1.4-0.5,3.5-1.1,4.9-1.2c1.4-0.2,3.6-1.3,5.4-1.9c2-0.7,10.8-2.9,12.8-3.4l2.1-3.2
				c0.9-0.3,3.3-0.7,4.7-0.5c1.4,0.2,5.9,0.5,8.7,0.5L69.1,140.5z"
        />
        <path
          style={{ fill: kitColor3 }}
          d="M84.9,5.5l-2.1-3.3l-4.2-0.8l-9.6,0.8l-8.8-0.8l-4.7,0.8l-2,3.1c-0.9,1.6-0.8,9.5,2.7,12.8
				c0.1,0.1,4.1-3.8,4.1-3.8s7.9,3.9,8.8,4.2c0.9-0.3,9.1-4.4,9.1-4.4s2.6,4,2.8,3.8C84.8,14.9,85.8,7.1,84.9,5.5z M78.7,11
				c-4,1.9-9.6,6.1-9.6,6.1s-5.6-4.3-9.6-6.1c-2.5-1.2-3.9-3.5-4.2-5.5c1.3-0.3,4.1-0.6,5.2-0.4c1.4,0.2,5.8,0.5,8.6,0.5
				s7.2-0.4,8.6-0.5C79,5,81.8,5.3,82.9,5.7C82.5,7.6,81.2,9.9,78.7,11z"
        />
        <g>
          <path
            style={{ fill: shadow }}
            d="M82.9,5.7c-0.3,1.9-1.7,4.2-4.2,5.4c-4,1.9-9.6,6.1-9.6,6.1s-5.6-4.3-9.6-6.1c-2.5-1.2-3.9-3.5-4.2-5.5
					c1.3-0.3,4.1-0.6,5.2-0.4c1.4,0.2,5.8,0.5,8.6,0.5s7.2-0.4,8.6-0.5C78.9,5,81.8,5.3,82.9,5.7z"
          />
          <path
            opacity="0.16"
            d="M104.4,137.8c1.4-0.5,1.6-1.8,1.6-3.2s-0.2-4.6,0-7.1c0.2-2.3-0.3-6.7-0.6-9.3c-5.2,4.7-9,14.7-10.6,21.6
					C100.4,139,103.7,138.1,104.4,137.8z"
          />
          <path
            opacity="0.16"
            d="M33.7,137.7c-1.4-0.5-1.6-1.8-1.6-3.2s0.2-4.6,0-7.1c-0.1-2.3,0.3-6.7,0.6-9.3c5.2,4.7,9,14.7,10.6,21.6
					C37.7,138.9,34.3,138,33.7,137.7z"
          />
          <g>
            <path
              d="M59.6,2.2c0.3,0,0.6,0,0.8,0c1.4,0.2,5.9,0.5,8.7,0.5s7.3-0.4,8.7-0.5c0.2,0,0.5,0,0.8,0c1.3,0,3,0.3,3.9,0.6L84.6,6
						c2.3,0.5,11.1,2.7,12.8,3.4c1.7,0.7,4,1.8,5.4,1.9c1.4,0.2,3.5,0.7,4.9,1.2c1.4,0.5,4.7,3,5.9,4.8c1.2,1.8,8.2,16.3,9.8,18.6
						c1.6,2.3,12.4,22.3,12.9,23.2c0.5,0.9,1,3.2,0.2,3.4c-0.9,0.2-8.3,3.4-11.5,6c-3.1,2.7-9,5.7-9.7,6c-0.2,0.1-0.4,0.2-0.6,0.2
						c-0.4,0-0.8-0.2-1.2-0.7c-0.7-0.7-7.7-12-7.7-12c-1.2,2.7-1.2,5.7-1,7.6c0.2,2-0.9,11.8-0.3,14.6c0.5,2.8,0.5,30.3,0.9,32.8
						c0.3,2.5,0.9,7.4,0.7,9.9c-0.2,2.5,0,5.7,0,7.1c0,1.4-0.2,2.7-1.6,3.2c-1.3,0.5-11.6,3.3-29.4,3.3c-1.8,0-3.8,0-5.8-0.1
						c-2,0.1-3.9,0.1-5.8,0.1c-17.8,0-28.2-2.8-29.4-3.3c-1.4-0.5-1.6-1.8-1.6-3.2c0-1.4,0.2-4.6,0-7.1c-0.2-2.5,0.3-7.4,0.7-9.9
						c0.3-2.5,0.3-30,0.9-32.8c0.5-2.8-0.5-12.7-0.3-14.6c0.2-1.9,0.2-5-1-7.6c0,0-7.3,11.5-8,12.2c-0.4,0.4-0.6,0.6-0.9,0.6
						c-0.2,0-0.4-0.1-0.7-0.2c-0.7-0.3-6.6-3.4-9.7-6c-3.1-2.7-10.6-5.8-11.4-6c-0.9-0.2-0.4-2.5,0.2-3.4C2.5,58.3,13.4,38.5,15,36.2
						c1.6-2.3,8.5-17,9.7-18.8c1.2-1.8,4.5-4.3,5.9-4.8c1.4-0.5,3.5-1.1,4.9-1.2c1.4-0.2,3.6-1.3,5.4-1.9c2-0.7,10.8-2.9,12.8-3.4
						l2.1-3.2C56.5,2.5,58.3,2.2,59.6,2.2 M59.6,1.2L59.6,1.2c-1.4,0-3.3,0.3-4.2,0.6C55.2,2,55.1,2.1,55,2.3l-1.9,2.9
						c-2.4,0.6-10.6,2.6-12.6,3.3c-0.5,0.2-1.1,0.4-1.7,0.7c-1.2,0.5-2.6,1.1-3.5,1.2c-1.5,0.2-3.7,0.8-5.1,1.3
						c-1.6,0.6-5.1,3.3-6.4,5.2c-0.6,0.9-2.4,4.5-4.5,8.7c-2.1,4.2-4.4,8.9-5.2,10.1C13,37.4,6.5,49.1,3,55.3c-1,1.8-1.8,3.2-1.9,3.4
						c-0.4,0.8-1.1,2.8-0.6,4c0.2,0.5,0.6,0.8,1.1,0.9c0.7,0.2,7.9,3.2,11,5.8c3.2,2.8,9.2,5.8,9.9,6.2c0.4,0.2,0.7,0.3,1.1,0.3
						c0.7,0,1.1-0.4,1.6-0.9c0.6-0.6,4.7-7,7-10.6c0.5,1.9,0.4,3.8,0.3,5.2c-0.1,0.8,0,2.8,0.2,5.3c0.2,3.4,0.5,7.7,0.2,9.3
						c-0.3,1.5-0.4,8.9-0.5,19.2c-0.1,6.2-0.2,12.6-0.3,13.7c-0.4,2.7-0.9,7.6-0.7,10.1c0.1,1.7,0.1,3.9,0,5.4c0,0.6,0,1.2,0,1.6
						c0,1.1,0,3.3,2.2,4.1c1.8,0.7,12.5,3.4,29.8,3.4c1.9,0,3.8,0,5.8-0.1c1.9,0.1,3.9,0.1,5.8,0.1c17.2,0,28-2.7,29.8-3.4
						c2.2-0.8,2.2-3.1,2.2-4.1c0-0.4,0-0.9,0-1.6c0-1.6-0.1-3.7,0-5.4c0.2-2.5-0.3-7.4-0.7-10.1c-0.2-1.1-0.2-7.5-0.3-13.7
						c-0.1-10.2-0.3-17.6-0.6-19.2c-0.3-1.6,0-5.9,0.2-9.3c0.2-2.4,0.3-4.4,0.2-5.2c-0.1-1.4-0.2-3.3,0.3-5.2
						c2.2,3.6,6.2,9.8,6.8,10.4c0.5,0.5,1.1,1,1.9,1c0.3,0,0.7-0.1,1.1-0.3l0.1,0c2-1,7-3.7,9.8-6.1c2.9-2.5,10.1-5.6,11-5.8
						c0.5-0.1,0.8-0.4,1.1-0.9c0.5-1.1-0.1-3.2-0.6-4c-0.1-0.2-0.9-1.7-2-3.6c-3.4-6.3-9.8-17.9-11-19.7c-0.8-1.2-3.2-5.9-5.3-10
						c-2.1-4.1-3.9-7.7-4.5-8.6c-1.4-2-4.9-4.6-6.4-5.2c-1.4-0.6-3.6-1.1-5.1-1.3c-1-0.1-2.6-0.8-4-1.4c-0.4-0.2-0.8-0.3-1.1-0.5
						C96,7.7,87.2,5.6,85.2,5.1l-1.9-2.9c-0.1-0.2-0.3-0.3-0.5-0.4c-1-0.3-2.8-0.6-4.2-0.6c-0.3,0-0.6,0-0.9,0
						c-1.4,0.2-5.9,0.5-8.6,0.5S62,1.5,60.6,1.3C60.3,1.2,60,1.2,59.6,1.2L59.6,1.2z"
            />
          </g>
        </g>
      </g>
      <g>
        <path
          style={{ fill: kitColor2 }}
          d="M142,59.2c0,0,0-0.1,0.1-0.1C142,59.2,142,59.2,142,59.2C141.9,59.2,141.9,59.2,142,59.2z"
        />
        <path
          style={{ fill: kitColor1 }}
          d="M33.3,11.7c0,0-0.8,50.3-0.8,50.3c0,0.5-0.9,1.4-1.1,1.8c-0.3,0.5-0.7,1.2-1.2,1.9c-0.2,0.3-0.4,0.7-0.7,1.1
				c-1.5,2.4-3,4.9-4.8,7.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2
				c-0.1,0.1-0.1,0.1-0.2,0.1c-0.2,0.1-0.5,0-0.9-0.2c-0.7-0.3-6.6-3.4-9.7-6c-3.1-2.7-10.6-5.8-11.5-6c-0.2,0-0.3-0.2-0.4-0.3
				c0,0,0-0.1-0.1-0.1c0-0.2-0.1-0.4-0.1-0.6c0-0.1,0-0.1,0-0.2c0-0.4,0.2-0.9,0.3-1.3c0-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.7-1,0.7-1.3
				c0,0,0,0,0-0.1c0.1-0.1,0.2-0.3,0.3-0.5c0,0,0-0.1,0.1-0.1C4.1,56,5,53.9,6,52.1c2.9-5.3,5.6-10.9,9-15.9c1.6-2.3,8.5-17,9.7-18.8
				c1.2-1.8,4.5-4.3,5.9-4.8c0.4-0.2,0.9-0.3,1.3-0.4c0.2-0.1,0.4-0.1,0.7-0.2C32.9,11.9,33.1,11.8,33.3,11.7z"
        />
        <g>
          <g>
            <path
              style={{ fill: kitColor2 }}
              d="M136.7,62.5c-0.1,0-0.1,0.1-0.2,0.1c-1,0.2-1.9,0.7-2.8,1.1c-2.6,1.1-5.1,2.4-7.5,4
						c-3.6,2.5-7.1,4.9-10.9,6.9c-0.6,0.3-1,0.2-1.5-0.3c0,0,0,0-0.1-0.1c-2.9-3.9-5.4-8.1-8-12.2c0,0-0.6-50.2-0.6-50.2
						c3.3,0.8,6.5,2.8,8.4,5.6c1.2,1.8,8.2,16.3,9.8,18.6c2.5,3.8,4.6,7.9,6.7,11.9c1.1,2.1,2.2,4.2,3.3,6.4c1.2,2.2,3.1,4.2,3.5,6.7
						C137,61.6,137,62.2,136.7,62.5z"
            />
          </g>
        </g>
      </g>
      <path
        style={{ fill: kitColor3 }}
        d="M26.2,72c0,0-0.8,0.4-1.5,0.1c-0.7-0.4-6.6-3.4-9.7-6c-3.1-2.7-10.6-5.8-11.5-6c-0.9-0.2-1-1.7-1-1.7
			S1.9,59,1.8,59.2c-0.5,0.9-1.1,3.3-0.2,3.5c0.9,0.2,8.4,3.4,11.5,6.1c3.1,2.7,9.2,5.7,9.9,6c0.7,0.3,1.1,0.3,1.7-0.4
			C25.3,73.6,26.2,72,26.2,72z"
      />
      <path
        style={{ fill: kitColor3 }}
        d="M112,72.1c0,0,0.8,0.4,1.5,0.1c0.7-0.4,6.6-3.4,9.7-6c3.1-2.7,10.8-6,11.7-6.2c0.9-0.2,1-1.7,1-1.7
			s0.4,0.7,0.5,0.9c0.5,0.9,1,3.2,0.2,3.4c-0.9,0.2-8.6,3.6-11.8,6.3c-3.1,2.7-8.4,5.3-9.1,5.7c-0.7,0.3-1.2,0.5-1.9-0.3
			C113.2,73.5,112,72.1,112,72.1z"
      />
      <path
        style={{ fill: shadow }}
        d="M136.5,62.4c0.9-0.2,0.4-2.5-0.2-3.4c-0.3-0.5-3.9-7.1-7.3-13.1c-6.2,2.9-14.5-0.1-23.8-13.6l0.3,9.9l0.1,19.7
			c0,0,7,11.3,7.7,12c0.7,0.7,1.2,0.9,1.9,0.5c0.7-0.4,6.6-3.4,9.7-6C128.2,65.8,135.6,62.6,136.5,62.4z"
      />
      <path
        style={{ fill: shadow }}
        d="M1.6,62.5c-0.9-0.2-0.3-2.5,0.2-3.4c0.3-0.5,3.9-7.1,7.3-13.1c6.2,2.9,15.6-0.5,24.2-14.1l-0.6,13.5l0,16.5
			c0,0-7.6,11.9-8.3,12.6c-0.7,0.7-1,0.3-1.7,0c-0.7-0.4-6.6-3.4-9.7-6C10,65.9,2.5,62.7,1.6,62.5z"
      />
      <ShirtNumber
        shirtNumber={shirtNumber}
        shirtNumberColor={shirtNumberColor}
        shirtNumberBackgroundColor={shirtNumberBackgroundColor}
        shirtNumberBackgroundOpacity={shirtNumberBackgroundOpacity}
      />
    </Kit>
  );
};

export default TwoColorWithArmsCollarArmsBottom;
