import Icon from '../Icon/Icon';

export const OffsideIcon = (props: any) => (
  <Icon
    {...props}
    className="offside"
    viewBox="0 0 22.7 22.7"
    enableBackground="new 0 0 22.7 22.7"
  >
    <g id="Layer_1">
      <g>
        <path
          d="M20.454,10.365l-2.55-5.41l-2.12-4.51c-0.08-0.16-0.24-0.27-0.43-0.28c-0.17-0.01-0.36,0.09-0.45,0.25
  c-0.01,0.02-1.17,1.82-6.31,1.11l-0.14-0.02h-0.05c-0.01,0-0.02,0-0.02,0c-1.7-0.2-3.11,0.17-4.17,1.09
  c-1.9,1.61-2.01,4.35-2.01,4.46c0,0.08,0.01,0.16,0.05,0.23l2.09,4.48l2.52,5.4l2.38,5.09c0.09,0.18,0.27,0.28,0.45,0.28
  c0.08,0,0.15-0.01,0.22-0.04c0.25-0.12,0.35-0.42,0.24-0.67l-2.32-4.96c0.04-0.49,0.29-2.43,1.66-3.59
  c0.86-0.74,2.04-1.04,3.47-0.87h0.01l0.15,0.02c0.88,0.12,1.66,0.18,2.34,0.18c3.94,0,4.93-1.71,4.97-1.79
  C20.514,10.675,20.514,10.505,20.454,10.365z M4.864,3.345c0.81-0.69,1.89-0.98,3.21-0.87c0,0,1.62,3.49,1.62,3.49
  c-1.046,0-2.128,0.242-3,0.84c-1.04,0.693-1.603,1.733-2.03,2.87c-0.03,0.08-0.06,0.16-0.08,0.24l-1.37-2.94
  C3.254,6.485,3.504,4.505,4.864,3.345z M13.354,11.455l-2.01-4.33c0.08,0.01,0.15,0.01,0.22,0.02c0.17,0.01,0.33,0.02,0.49,0.03
  c0.3,0.02,0.58,0.03,0.85,0.03c1.37,0,2.38-0.2,3.12-0.48c0.08-0.03,0.16-0.06,0.24-0.09c0.46-0.19,0.79-0.4,1.04-0.6l2.11,4.47
  C18.964,10.935,17.444,11.985,13.354,11.455z"
        />
      </g>
    </g>
    <g id="Layer_3"></g>
    <g id="Layer_4"></g>
    <g id="Layer_5"></g>
  </Icon>
);
