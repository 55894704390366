import { MatchesType } from '@ntb-sport/types';

import styled, { css } from 'styled-components';

interface StyledProps {
  $disbaled?: any;
  $highlight?: any;
  $matches?: MatchesType;
}

export const Header = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 24px 0 12px 0;
`;

export const Name = styled.h4`
  font-size: var(--ntb-fontSizes-md);
`;

export const LastUpdated = styled.p`
  font-size: var(--ntb-fontSizes-sm);
`;

export const NoResults = styled.p`
  text-align: center;
  font-size: var(--ntb-fontSizes-md);
`;

export const Standings = styled.div`
  padding: 12px;
`;

export const Standing = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 6px 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--border-color);
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
`;

export const Rank = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  font-size: var(--ntb-fontSizes-xs);
  background-color: var(--background-color);
  font-weight: var(--ntb-fontWeights-medium);
  color: var(--font-color);
  border-radius: var(--ntb-radii-md);

  ${(props) =>
    props?.$matches?.mobileL &&
    css`
      font-size: var(--ntb-fontSizes-sm);
      width: 25px;
      height: 25px;
    `}
`;

export const Right = styled.div<StyledProps>`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-semibold);

  ${(props) =>
    props?.$matches?.tablet &&
    css`
      font-size: var(--ntb-fontSizes-sm);
    `}
`;

export const NoStandings = styled.div<StyledProps>`
  font-size: var(--ntb-fontSizes-sm);
  text-align: center;

  ${(props) =>
    props?.$matches?.tablet &&
    css`
      font-size: var(--ntb-fontSizes-md);
    `}
`;
