import { MatchesType } from '@ntb-sport/types';
import { EventParticipant } from '../EventParticipant/EventParticipant';

import { LabelProperties } from 'neo-common-enums';

import * as S from './ScopeStandings.styled';
import { COMPONENT_IDS } from '@ntb-sport/constants';
import { Skeleton } from '../../chakra';

const RESULT_IDS = {
  [LabelProperties.ResultId.DID_NOT_START]: {
    name: 'DNS',
    type: 'error',
  },
  [LabelProperties.ResultId.DID_NOT_FINISH]: {
    name: 'DNF',
    type: 'error',
  },
  [LabelProperties.ResultId.DID_NOT_FINISH_FIRST_RUN]: {
    name: 'DNF',
    type: 'error',
  },
  [LabelProperties.ResultId.DID_NOT_FINISH_SECOND_RUN]: {
    name: 'DNF',
    type: 'error',
  },
  [LabelProperties.ResultId.DISQUALIFIED]: { name: 'DSQ', type: 'error' },
  [LabelProperties.ResultId.LAPPED]: { name: 'LPD', type: 'error' },
  [LabelProperties.ResultId.NOT_PERMITTED_TO_START]: {
    name: 'NPS',
    type: 'error',
  },
  [LabelProperties.ResultId.NO_RESULT]: {
    name: 'X',
    type: 'error',
  },
};

interface CombinedEventsStandingsResultsWithDataProps {
  scopeResults: any;

  matches: MatchesType;
  isLoadingScopeResults: boolean;
}

export const ScopeStandings = ({
  scopeResults,
  matches,
  isLoadingScopeResults,
}: CombinedEventsStandingsResultsWithDataProps) => {
  if (isLoadingScopeResults) {
    return (
      <>
        <Skeleton height="45px" mb={1} />
        <Skeleton height="45px" mb={1} />
        <Skeleton height="45px" mb={1} />
        <Skeleton height="45px" mb={1} />
        <Skeleton height="45px" mb={1} />
        <Skeleton height="45px" mb={1} />
        <Skeleton height="45px" mb={1} />
        <Skeleton height="45px" mb={1} />
      </>
    );
  }

  return (
    <div data-component-id={COMPONENT_IDS.COMBINED_EVENTS_STANDINGS}>
      <S.Standings>
        {!scopeResults?.data?.length && (
          <S.NoResults>Sammenlagtresultater ikke tilgjengelig</S.NoResults>
        )}
        {scopeResults?.data?.map((s: any) => (
          <S.Standing key={s?.participant?.person?.uuid || s?.team?.uuid}>
            <S.Left>
              <S.Rank
                $matches={matches}
                $highlight={
                  s?.participant?.person?.countries?.[0]?.uuid ===
                  'country-norway'
                }
              >
                {s.rank}
              </S.Rank>
              <EventParticipant
                person={{
                  ...s?.participant?.person,
                  countries: s?.participant?.person?.countries,
                  gender: s?.participant?.person?.gender,
                }}
                team={s?.team}
              />
            </S.Left>

            <S.Right $matches={matches}>
              {s?.points
                ? `${s?.points}p`
                : RESULT_IDS[s?.resultId as keyof typeof RESULT_IDS]?.name}
            </S.Right>
          </S.Standing>
        ))}
      </S.Standings>
    </div>
  );
};
