import { SPORT_GROUP } from '@ntb-sport/constants';
import { Fetch, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchTeamType {
  apiBaseUrl: string;
  sportGroup: string;
  teamId: string | number;
}

export const fetchTeam = async ({
  apiBaseUrl,
  sportGroup,
  teamId,
}: FetchTeamType) => {
  const path =
    sportGroup === SPORT_GROUP.ICE_HOCKEY || sportGroup === SPORT_GROUP.HANDBALL
      ? 'nifTeams'
      : 'teams';

  const json = await Fetch({
    url: `${apiBaseUrl}/${path}/${teamId}/`,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });
  return json;
};
