import ClubLogo from '../../nifs/ClubLogo/ClubLogo';
import { format } from 'date-fns';
import * as S from './ExampleComponentTwo.styled';
import { StadiumFootballIcon } from '../../icons';
import { MATCH_STATUS } from '@ntb-sport/constants';
import { Text, Skeleton, Flex, Grid } from '../../chakra';

interface ExampleComponentTwoProps {
  title: string;
  fixtures: any;
  isLoading: boolean;
  highlightedTeams?: string;
}

export const ExampleComponentTwo: React.FC<ExampleComponentTwoProps> = ({
  title,
  fixtures,
  isLoading,
  highlightedTeams,
}) => {
  if (isLoading) {
    return (
      <S.Container>
        <Skeleton height="40px" width="100%" mb={4} />
        <Skeleton height="40px" width="100%" mb={4} />
        <Skeleton height="40px" width="100%" mb={4} />
        <Skeleton height="40px" width="100%" mb={4} />
        <Skeleton height="40px" width="100%" mb={4} />
        <Skeleton height="40px" width="100%" mb={4} />
        <Skeleton height="40px" width="100%" mb={4} />
        <Skeleton height="40px" width="100%" mb={4} />
        <Skeleton height="40px" width="100%" mb={4} />
        <Skeleton height="40px" width="100%" mb={4} />
      </S.Container>
    );
  }

  return (
    <S.Container>
      <Flex flexDirection="column" rowGap={4}>
        {fixtures?.map((fixture: any) => (
          <S.Fixture
            $isHighlighted={fixture?.homeTeam?.id === Number(highlightedTeams)}
          >
            <Grid gridTemplateColumns="200px 60px 200px" gridColumnGap={4}>
              <S.ClubLogo>
                <ClubLogo
                  direction="row-reverse"
                  src={fixture?.homeTeam?.logo?.url}
                  name={fixture?.homeTeam?.name}
                  alt={fixture?.homeTeam?.name}
                />
              </S.ClubLogo>
              {fixture?.matchStatusId === MATCH_STATUS.PLAYED ? (
                <S.Result>{`${fixture?.result?.homeScore90} - ${fixture?.result?.awayScore90}`}</S.Result>
              ) : (
                <S.Time>{format(new Date(fixture?.timestamp), 'HH:mm')}</S.Time>
              )}

              <S.ClubLogo>
                <ClubLogo
                  src={fixture?.awayTeam?.logo?.url}
                  name={fixture?.awayTeam?.name}
                  alt={fixture?.awayTeam?.name}
                />
              </S.ClubLogo>
            </Grid>
            <S.Stadium>
              <StadiumFootballIcon />
              <Text>{fixture?.stadium?.name}</Text>
            </S.Stadium>
          </S.Fixture>
        ))}
      </Flex>
    </S.Container>
  );
};

export default ExampleComponentTwo;
