import { QUERY_KEYS } from '@ntb-sport/constants';

export function onScopeResultsDelete({
  msg,
  apiClient,
}: {
  msg: any;
  apiClient: any;
}) {
  const { entityId, additionalData } = msg;

  const scopeResults = apiClient.getQueryData([
    QUERY_KEYS.SCOPE_RESULTS,
    { scopeUUID: additionalData.scope.uuid },
  ]);

  const state = scopeResults.data;

  if (!state) return; //TODO: Remove this when join scope channel is implemented

  const newState = state?.filter(
    (scopeResult: any) => scopeResult.uuid !== entityId,
  );

  apiClient.setQueryData(
    [QUERY_KEYS.SCOPE_RESULTS, { scopeUUID: additionalData.scope.uuid }],
    { ...scopeResults, data: newState },
  );
}
