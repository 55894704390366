import { EventTypeProps } from '../Event';
import {
  EventComment,
  EventPerson,
  EventTeam,
  EventTitle,
  Time,
} from '../Event.styled';

import { ELEMENT_IDS, MATCH_EVENTS } from '@ntb-sport/constants';
import { getEventTime } from '../getEventTime';

export const PenaltyMissEvent = ({
  event,
  showComments,
  text,
  personLink,
  teamLink,
}: EventTypeProps) => {
  const saveEvent = event?.connectedEvent.find(
    (event: any) => event.matchEventTypeId === MATCH_EVENTS.PENALTY_SAVE,
  );

  return (
    <div>
      <EventTitle as="h3" data-element-id={ELEMENT_IDS.TITLE}>
        {Object.prototype.hasOwnProperty.call(event, 'time') && (
          <Time>{getEventTime(event)}</Time>
        )}
        {`${text}! `}
        {event.person && (
          <>
            {personLink && !event?.person?.hiddenPersonInfo ? (
              <EventPerson>
                {personLink({
                  id: event?.person?.id,
                  name: event?.person?.name,
                  sportId: event?.sportId,
                })}
              </EventPerson>
            ) : (
              <EventPerson>{event?.person?.name}</EventPerson>
            )}
            {', '}
          </>
        )}
        {teamLink ? (
          <EventTeam>
            {teamLink({
              id: event?.team?.id,
              name: event?.team?.name,
              sportId: event?.sportId,
            })}
          </EventTeam>
        ) : (
          <EventTeam>{event?.team?.name}</EventTeam>
        )}
        {` brenner straffen. `}
        {saveEvent && (
          <>
            {`Redning av `}
            {saveEvent.person && (
              <>
                {personLink && !event?.person?.hiddenPersonInfo ? (
                  <EventPerson>
                    {personLink({
                      id: saveEvent?.person?.id,
                      name: saveEvent?.person?.name,
                      sportId: saveEvent?.sportId,
                    })}
                  </EventPerson>
                ) : (
                  <EventPerson>{saveEvent?.person?.name}</EventPerson>
                )}
                {'.'}
              </>
            )}
          </>
        )}
      </EventTitle>

      {showComments && <EventComment>{event?.comment}</EventComment>}
    </div>
  );
};

export default PenaltyMissEvent;
