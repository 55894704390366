import {
  COMPONENT_IDS,
  DATA_PICKER_GROUPS,
  DATA_PROVIDERS,
  SPORT_GROUP,
} from '@ntb-sport/constants';
import { WidgetConfig, SportsdataConfig } from '@ntb-sport/types';
import {
  DATA_TYPES,
  WIDGET_IDS,
  WIDGET_OPTIONS,
  WIDGET_VERSION,
} from '../common';

export const config: WidgetConfig = {
  id: WIDGET_IDS.LIVE_FEED,
  dataType: DATA_TYPES.NONE,
  version: WIDGET_VERSION,
  displayName: 'Live Feed Widget',
  widgetNamespace: 'NtbWidgetLiveFeed',
  iframeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.LIVE_FEED}/iframe.html`,
  widgetUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.LIVE_FEED}/${WIDGET_IDS.LIVE_FEED}.js`,
  themeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/themes/default.css`,
  title: 'Live Feed Widget',
  summary: 'Brukes som eksempel for dokumentasjonssiden',
  sportGroups: [
    SPORT_GROUP.WINTERSPORTS,
    SPORT_GROUP.ATHLETICS,
    SPORT_GROUP.CYCLING,
    SPORT_GROUP.SUMMER_OLYMPICS,
  ],
  description: 'general description to be displayed on the documentation page',
  components: [],
  options: {
    [WIDGET_OPTIONS.CUSTOMER_ID]: { isRequired: true },
    [WIDGET_OPTIONS.ERROR_MESSAGE]: { isRequired: false },
    [WIDGET_OPTIONS.ID]: { isRequired: true },
    [WIDGET_OPTIONS.ON_ERROR]: { isRequired: false },
    // [WIDGET_OPTIONS.SPORT_GROUP]: { isRequired: true },
    [WIDGET_OPTIONS.STYLE_ID]: { isRequired: false },
    [WIDGET_OPTIONS.TARGET_ID]: { isRequired: true },
    [WIDGET_OPTIONS.TENANT_KEY]: { isRequired: true },
    // [WIDGET_OPTIONS.ROUND]: { isRequired: true },
  },
  uiOptions: {
    // [COMPONENT_IDS.EXAMPLE_COMPONENT_ONE]: [
    //   WIDGET_OPTIONS.TITLE,
    //   WIDGET_OPTIONS.SHOW_STAGE_NAME,
    // ],
    // [COMPONENT_IDS.EXAMPLE_COMPONENT_TWO]: [WIDGET_OPTIONS.HIGHLIGHTED_TEAMS],
  },
};

export const sportsdataConfig: SportsdataConfig = {
  dataPickerGroups: {
    [DATA_PICKER_GROUPS.NIFS_STAGE_GROUP]: {
      title: 'Stage',
      key: DATA_PICKER_GROUPS.NIFS_STAGE_GROUP,
      primaryId: DATA_PROVIDERS.CHANNEL_IDS,
      isDefault: true,
      pickers: {
        [DATA_PROVIDERS.SPORT_GROUP]: {
          defaultValue: SPORT_GROUP.WINTERSPORTS,
        },
        [DATA_PROVIDERS.CHANNEL_IDS]: { isPrimaryId: true },
      },
    },
  },
};
