import AbbreviationInfo from '../../../sportedit/AbbreviationInfo/AbbreviationInfo';
import { useBem } from '@ntb-sport/hooks';
import './header.css';

interface HeaderProps {
  sportGroup: string | undefined;
  scopeCategoryId?: number;
}

const SCOPE_CATEGORY_ID_TITLE = {
  1: '(Spurtpoeng)',
  2: '(Klatrepoeng)',
};

export const Header = ({ sportGroup, scopeCategoryId }: HeaderProps) => {
  const bem = useBem('scope-results');

  const title = scopeCategoryId
    ? `Resultater ${SCOPE_CATEGORY_ID_TITLE[scopeCategoryId as keyof typeof SCOPE_CATEGORY_ID_TITLE]}`
    : 'Resultater';

  return (
    <div className={bem('header')}>
      <h2>{title}</h2>

      {sportGroup !== 'cycling' && <AbbreviationInfo sportGroup={sportGroup} />}
    </div>
  );
};
