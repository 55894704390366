import { SPORT_GROUP } from '@ntb-sport/constants';
import { NifsPlayerStatistics } from '@ntb-sport/types';

import { Fetch, NIFS_API_BASE_URL, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchStageStatisticsType {
  apiBaseUrl: string;
  sportGroup: string;
  stageId: string | number;
  type: string;
}

export const fetchStageStatistics = async ({
  apiBaseUrl,
  sportGroup,
  stageId,
  type,
}: FetchStageStatisticsType): Promise<NifsPlayerStatistics> => {
  const path =
    sportGroup === SPORT_GROUP.ICE_HOCKEY || sportGroup === SPORT_GROUP.HANDBALL
      ? 'nifPlayerstatistics'
      : 'playerstatistics';

  const json = await Fetch({
    url: `${apiBaseUrl}/${path}/${stageId}/${type}/`,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });

  return json;
};
