import styled from 'styled-components';

import CountryFlag from '../../../../common/CountryFlag/CountryFlag';

export const TournamentHeader = styled.div`
  background: var(--background-color);
  color: var(--font-color);
  box-shadow: inset 0 -1px 0 var(--border-color);
`;

export const TournamentHeaderSection = styled.div`
  display: grid;
  align-items: center;
  grid-template-areas: 'logo name link';
  grid-template-columns: 40px auto auto;
  grid-column-gap: var(--ntb-space-2);
  padding: var(--ntb-space-2);
`;

export const LogoWrapper = styled.div`
  justify-self: center;
  grid-area: logo;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  color: var(--font-color);
  padding: var(--ntb-space-0-5);
  border-radius: var(--ntb-radii-md);
  width: 32px;
  height: 32px;
`;

export const Logo = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
`;

export const TournamentInfo = styled.div`
  grid-area: name;
  display: flex;
  flex-direction: column;
  column-gap: var(--ntb-space-0);
`;

export const TournamentNameAndGender = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
`;

export const TournamentName = styled.h2`
  font-size: var(--ntb-fontSizes-md);
  font-weight: var(--ntb-fontWeights-semibold);
`;

export const Country = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
`;

export const TournamentCountryFlag = styled(CountryFlag).withConfig({
  shouldForwardProp: (prop) => ['countryName'].includes(prop),
})`
  min-width: 16px;
  max-width: 16px;
`;

export const CountryName = styled.div`
  font-size: var(--ntb-fontSizes-xs);
`;

export const TournamentStandings = styled.button`
  grid-area: link;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: auto;
  column-gap: var(--ntb-space-0-5);
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-medium);
  padding: var(--ntb-space-1) var(--ntb-space-2);
  border-radius: var(--ntb-radii-md);
  background-color: var(--background-color);
  color: var(--font-color);

  span {
    line-height: 1;
  }

  &:hover {
    background-color: var(--background-color__hover);
    color: var(--font-color__hover);
  }
`;
