import EventParticipant from '../../EventParticipant/EventParticipant';
import { NeoParticipantType } from '@ntb-sport/types';

import { COMPONENT_IDS } from '@ntb-sport/constants';
import { useBem } from '@ntb-sport/hooks';
import './participant.css';
import classNames from 'classnames';

interface ParticipantProps {
  eventParticipant: NeoParticipantType;
  highlightedCountry: string;
}

export const Participant = ({
  eventParticipant,
  highlightedCountry,
}: ParticipantProps) => {
  const bem = useBem(COMPONENT_IDS.EVENT_PARTICIPANTS);

  const highlight =
    eventParticipant?.team?.country?.uuid === highlightedCountry ||
    eventParticipant?.person?.countries?.[0]?.uuid === highlightedCountry;

  return (
    <li className={bem('list-item')}>
      <div
        className={classNames(bem('rank'), {
          [bem('rank', 'highlight')]: highlight,
        })}
      >
        {eventParticipant?.pair ||
          eventParticipant?.startingOrder ||
          eventParticipant?.bibNumber}
      </div>

      <EventParticipant
        person={eventParticipant?.person}
        team={eventParticipant?.team}
        representing={eventParticipant?.representing}
        bibNumber={eventParticipant?.bibNumber}
        lineup={eventParticipant?.lineup}
      />

      <div className={bem('start-info')}>
        {eventParticipant?.startTimeDifference}
        {eventParticipant?.startPoints && `${eventParticipant?.startPoints}p`}
        {eventParticipant?.startingPoints &&
          eventParticipant?.rank &&
          `(${eventParticipant?.rank})`}
      </div>
    </li>
  );
};
