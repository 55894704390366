import { useBem } from '@ntb-sport/hooks';
import ResultId from '../ResultId/ResultId';
import './points.css';

interface PointsProps {
  points: number;
  resultId: number;
}

export const Points = ({ points, resultId }: PointsProps) => {
  const bem = useBem('scope-results');
  return (
    <span className={bem('points')}>
      <ResultId resultId={resultId} />
      {points && `${points}p`}
    </span>
  );
};
