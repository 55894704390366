import { SPORT_GROUP } from '@ntb-sport/constants';
import { Fetch, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchStageStandingsType {
  apiBaseUrl: string;
  sportGroup: string;
  stageId: string | number;
}

export const fetchStageStandings = async ({
  apiBaseUrl,
  sportGroup,
  stageId,
}: FetchStageStandingsType) => {
  const stagesPath =
    sportGroup === SPORT_GROUP.ICE_HOCKEY || sportGroup === SPORT_GROUP.HANDBALL
      ? 'nifStages'
      : 'stages';

  const url = `${apiBaseUrl}/${stagesPath}/${stageId}/table/`;

  const json = await Fetch({
    url: `${url}`,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });

  return json;
};
