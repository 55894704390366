import * as S from './Player.styled';

import {
  AssistIcon,
  CardIcon,
  GoalIcon,
  IndirectAssistIcon,
  PenaltyMissIcon,
  SecondCardIcon,
  SubstituteIcon,
} from '../../../../../icons';

import Kit from '../../../../Kit/Kit';
import { ELEMENT_IDS } from '@ntb-sport/constants';

const Person = ({
  person,
  kit,
  isCaptain,

  onClickPerson,
  displayNationality,
  isNationalTeam,
}: {
  person?: any;
  kit?: any;
  isCaptain?: any;

  onClickPerson?: any;
  displayNationality?: boolean;
  isNationalTeam: boolean;
}) => {
  const isKeeper = person.position && person.position.id === 1;

  const goalCount =
    person.personEvents.goals && person.personEvents.goals.length;
  const assistCount =
    person.personEvents.assists && person.personEvents.assists.length;
  const substituteOffTime =
    (person.personEvents.substituteOff &&
      person?.personEvents?.substituteOff?.[0]?.timeRounded) ||
    person?.personEvents?.substituteOff?.[0]?.time;
  const substituteOnTime =
    (person.personEvents.substituteOn &&
      person?.personEvents?.substituteOn?.[0]?.timeRounded) ||
    person?.personEvents?.substituteOn?.[0]?.time;
  const yellowCardTime =
    (person.personEvents.yellowCards &&
      person?.personEvents?.yellowCards?.[0]?.timeRounded) ||
    person?.personEvents?.yellowCards?.[0]?.time;
  const redCardTime =
    (person.personEvents.redCards &&
      person?.personEvents?.redCards?.[0]?.timeRounded) ||
    person?.personEvents?.redCards?.[0]?.time;

  const ariaShirtNumber = `, draktnummer ${person.shirtNumber}`;
  const ariaGoals = goalCount ? `, antall mål ${goalCount}` : '';
  const ariaAssists = assistCount ? `, antall målgivende ${assistCount}` : '';
  const ariaSubstituteOff = substituteOffTime
    ? `, byttet ut i spillerminutt ${substituteOffTime}`
    : '';
  const ariaSubstituteOn = substituteOnTime
    ? `, byttet inn i spillerminutt ${substituteOnTime}`
    : '';
  const ariaYellowCard = yellowCardTime
    ? `, gult kort i spillerminutt ${yellowCardTime}`
    : '';
  const ariaRedCard = redCardTime
    ? `, rødt kort i spillerminutt ${redCardTime}`
    : '';

  return (
    <S.Player
      data-element-id={ELEMENT_IDS.LIST_ITEM}
      $isClickable={onClickPerson && !person?.hiddenPersonInfo}
      onClick={() =>
        onClickPerson &&
        !person?.hiddenPersonInfo &&
        onClickPerson({
          name: person.shirtName,
          id: person.personId,
          sportId: 1,
        })
      }
      aria-label={`${person.shirtName}${ariaShirtNumber}${ariaGoals}${ariaAssists}${ariaSubstituteOff}${ariaSubstituteOn}${ariaYellowCard}${ariaRedCard}`}
    >
      <S.Bio>
        <Kit
          type={kit?.kitTypeId}
          size={35}
          shirtNumber={person?.shirtNumber}
          kitColor1={isKeeper ? 'grey' : kit && kit?.colorCodes?.[0]}
          kitColor2={isKeeper ? 'grey' : kit && kit?.colorCodes?.[1]}
        />

        <S.NameAndCountry>
          <S.PersonName>
            {person.name}
            {isCaptain && ' (K)'}
          </S.PersonName>

          <S.ClubAndCountry>
            {!isNationalTeam && displayNationality && (
              <S.Country>
                <S.CountryFlag
                  countryName={
                    person?.clubTeam?.country?.name || person?.country?.name
                  }
                />
                {!isNationalTeam && (
                  <S.CountryName>{person?.country?.name}</S.CountryName>
                )}
              </S.Country>
            )}
            {isNationalTeam && person?.clubTeam && (
              <>
                <S.Club>
                  <S.ClubLogo
                    src={person?.clubTeam?.logo?.url}
                    alt={person?.clubTeam?.name}
                  />
                  <S.ClubName>
                    {`${person?.clubTeam?.name}
                  ${person?.clubTeamContractTypeId === 2 ? '(På lån)' : ''}`}
                  </S.ClubName>
                </S.Club>

                {displayNationality && (
                  <S.Country>
                    <S.CountryFlag
                      countryName={
                        person?.clubTeam?.country?.name || person?.country?.name
                      }
                    />
                    {!isNationalTeam && (
                      <S.CountryName>{person?.country?.name}</S.CountryName>
                    )}
                  </S.Country>
                )}
              </>
            )}
          </S.ClubAndCountry>
        </S.NameAndCountry>
      </S.Bio>

      <S.Events>
        {person.personEvents.goals && (
          <S.Event>
            <GoalIcon size="small" />

            {person.personEvents.goals?.length > 1 && (
              <S.EventTime>
                <span>{`(x${person.personEvents.goals?.length})`}</span>
              </S.EventTime>
            )}
          </S.Event>
        )}
        {person.personEvents.assists && (
          <S.Event>
            <AssistIcon size="small" />
            {person.personEvents.assists?.length > 1 && (
              <S.EventTime>
                <span>{`(x${person.personEvents.assists?.length})`}</span>
              </S.EventTime>
            )}
          </S.Event>
        )}
        {person.personEvents.indirectAssists && (
          <S.Event>
            <IndirectAssistIcon size="small" />
            {person.personEvents.indirectAssists?.length > 1 && (
              <S.EventTime>
                <span>{`(x${person.personEvents.indirectAssists?.length})`}</span>
              </S.EventTime>
            )}
          </S.Event>
        )}
        {person.personEvents.ownGoals && (
          <S.Event>
            <GoalIcon size="small" fillColor="var(--base-colors-error)" />

            {person.personEvents.ownGoals?.length > 1 && (
              <S.EventTime>
                <span>{`(x${person.personEvents.ownGoals?.length})`}</span>
              </S.EventTime>
            )}
          </S.Event>
        )}
        {person.personEvents.penaltyMiss && (
          <S.Event>
            <PenaltyMissIcon fillColor2="var(--base-colors-error)" />

            {person.personEvents.penaltyMiss?.length > 1 && (
              <S.EventTime>
                <span>{`(${person.personEvents.penaltyMiss?.length})`}</span>
              </S.EventTime>
            )}
          </S.Event>
        )}
        {person.personEvents.yellowCards &&
          person.personEvents.yellowCards.map((event: any, index: any) => (
            <S.Event key={event.id}>
              {index === 0 ? (
                <CardIcon
                  size="small"
                  cardId="yellow-card"
                  fillColor="var(--base-colors-warning)"
                />
              ) : (
                <SecondCardIcon
                  fillColor1="var(--base-colors-warning)"
                  fillColor2="var(--base-colors-error)"
                  cardId="second-yellow"
                  size="small"
                />
              )}
            </S.Event>
          ))}
        {person.personEvents.redCards && (
          <S.Event>
            <CardIcon
              cardId="red-card"
              fillColor="var(--base-colors-error)"
              size="small"
            />
            <S.EventTime>
              {person.personEvents.redCards.map((event: any) => (
                <span key={event.id}>{`(${
                  event?.timeRounded || event?.time
                })`}</span>
              ))}
            </S.EventTime>
          </S.Event>
        )}

        {person.personEvents.substituteOff && (
          <S.Event>
            <SubstituteIcon
              size="small"
              fillColor1="var(--base-colors-success)"
              fillColor2="var(--base-colors-error)"
            />

            <S.EventTime>
              {person.personEvents.substituteOff.map((event: any) => (
                <span key={event.id}>{`(ut ${
                  event?.timeRounded || event?.time
                })`}</span>
              ))}
            </S.EventTime>
          </S.Event>
        )}
        {person.personEvents.substituteOn && (
          <S.Event>
            <SubstituteIcon
              size="small"
              fillColor1="var(--base-colors-success)"
              fillColor2="var(--base-colors-error)"
            />

            <S.EventTime>
              {person.personEvents.substituteOn.map((event: any) => (
                <span key={event.id}>{`(inn ${
                  event?.timeRounded || event?.time
                })`}</span>
              ))}
            </S.EventTime>
          </S.Event>
        )}
      </S.Events>
    </S.Player>
  );
};

export default Person;
