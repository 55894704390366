import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--base-colors-border);
  padding: var(--ntb-space-2);
`;

export const Content = styled.div`
  padding: var(--ntb-space-2);
`;

export const Toggle = styled.button`
  position: fixed;
  z-index: 100000;
  bottom: 12px;
  right: 82px;
  padding: var(--ntb-space-2);

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 9999px;
  width: 46px;
  height: 46px;
  background-color: orange;
  border: 2px solid #cd8c14;
`;

export const Aside = styled.aside`
  position: fixed;
  z-index: 9999;
  bottom: 0;
  right: 0;
  top: 0;
  background-color: var(--base-colors-whiteAlpha-1000);
  color: var(--base-colors-blackAlpha-900);
  border-left: var(--base-colors-border) 1px solid;
  width: 360px;

  dt {
    display: flex;
    align-items: center;
    font-weight: var(--ntb-fontWeights-medium);
    font-size: var(--ntb-fontSizes-sm);
  }

  dd {
    display: flex;
    align-items: center;
    column-gap: var(--ntb-space-1);
  }

  dl > div {
    display: flex;
    align-items: center;
    column-gap: var(--ntb-space-2);
    margin-bottom: var(--ntb-space-1);
  }
`;
