import {
  COMPONENT_IDS,
  DATA_PICKER_GROUPS,
  DATA_PROVIDERS,
  SPORT_GROUP,
} from '@ntb-sport/constants';
import { WidgetConfig, SportsdataConfig } from '@ntb-sport/types';
import {
  DATA_TYPES,
  WIDGET_IDS,
  WIDGET_OPTIONS,
  WIDGET_VERSION,
} from '../common';

export const config: WidgetConfig = {
  id: WIDGET_IDS.TOP_LIST,
  dataType: DATA_TYPES.NIFS,
  version: WIDGET_VERSION,
  displayName: 'Toppliste for stage',
  widgetNamespace: 'NtbWidgetTopList',
  iframeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.TOP_LIST}/iframe.html`,
  widgetUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.TOP_LIST}/${WIDGET_IDS.TOP_LIST}.js`,
  themeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/themes/default.css`,
  title: 'Toppliste for stage ',
  summary: 'Toppliste for stage',
  sportGroups: [
    SPORT_GROUP.FOOTBALL,
    SPORT_GROUP.HANDBALL,
    SPORT_GROUP.ICE_HOCKEY,
  ],
  description: 'general description to be displayed on the documentation page',
  components: [
    COMPONENT_IDS.STAGE_HERO,
    COMPONENT_IDS.TEAM_PLAYER_STATS,
    COMPONENT_IDS.TAB_MENU,
  ],
  options: {
    [WIDGET_OPTIONS.TARGET_ID]: { isRequired: true },
    [WIDGET_OPTIONS.SPORT_GROUP]: { isRequired: true },
    [WIDGET_OPTIONS.CUSTOMER_ID]: { isRequired: true },
    [WIDGET_OPTIONS.STYLE_ID]: { isRequired: false },
    [WIDGET_OPTIONS.ID]: { isRequired: true },
    [WIDGET_OPTIONS.ON_ERROR]: { isRequired: false },
    [WIDGET_OPTIONS.ERROR_MESSAGE]: { isRequired: false },
  },
  uiOptions: {
    [COMPONENT_IDS.TEAM_PLAYER_STATS]: [
      WIDGET_OPTIONS.HIGHLIGHT_FIRST,
      WIDGET_OPTIONS.SHOW_ADDITIONAL_ROWS,
      WIDGET_OPTIONS.SHOW_IMAGES,
      WIDGET_OPTIONS.TABLE_ROWS,
    ],
    [COMPONENT_IDS.STAGE_HERO]: [WIDGET_OPTIONS.SHOW_LOGO],
    [COMPONENT_IDS.TAB_MENU]: [WIDGET_OPTIONS.MENU_ITEMS],
  },
};

export const sportsdataConfig: SportsdataConfig = {
  dataPickerGroups: {
    [DATA_PICKER_GROUPS.NIFS_STAGE_GROUP]: {
      title: 'Stage',
      key: DATA_PICKER_GROUPS.NIFS_STAGE_GROUP,
      primaryId: DATA_PROVIDERS.NIFS_STAGES,
      isDefault: true,
      pickers: {
        [DATA_PROVIDERS.SPORT_GROUP]: { defaultValue: 'football' },
        [DATA_PROVIDERS.NIFS_COUNTRIES]: { defaultValue: 2 },
        [DATA_PROVIDERS.NIFS_TOURNAMENTS]: {},
        [DATA_PROVIDERS.NIFS_STAGES]: { isPrimaryId: true },
      },
    },
  },
  optionGroups: {
    [COMPONENT_IDS.TEAM_PLAYER_STATS]: {
      title: 'Statistics top list',
      key: COMPONENT_IDS.TEAM_PLAYER_STATS,
      options: {
        [WIDGET_OPTIONS.HIGHLIGHT_FIRST]: {
          key: WIDGET_OPTIONS.HIGHLIGHT_FIRST,
          defaultValue: false,
          label: 'Highlight first row',
          type: 'checkbox',
        },
        [WIDGET_OPTIONS.SHOW_ADDITIONAL_ROWS]: {
          key: WIDGET_OPTIONS.SHOW_ADDITIONAL_ROWS,
          defaultValue: true,
          label: 'Toggle show more button',
          type: 'checkbox',
        },
        [WIDGET_OPTIONS.SHOW_IMAGES]: {
          key: WIDGET_OPTIONS.SHOW_IMAGES,
          defaultValue: false,
          label: 'Show player images',
          type: 'checkbox',
        },
        [WIDGET_OPTIONS.TABLE_ROWS]: {
          key: WIDGET_OPTIONS.TABLE_ROWS,
          defaultValue: 10,
          label: 'Initial number of rows',
          type: 'numberInput',
        },
      },
    },
    [COMPONENT_IDS.STAGE_HERO]: {
      title: 'Stage hero',
      key: COMPONENT_IDS.STAGE_HERO,
      options: {
        [WIDGET_OPTIONS.SHOW_LOGO]: {
          key: WIDGET_OPTIONS.SHOW_LOGO,
          defaultValue: true,
          label: 'Show logo',
          type: 'checkbox',
        },
      },
    },
    /*  [COMPONENT_IDS.TAB_MENU]: {
      title: 'Tab menu',
      key: COMPONENT_IDS.TAB_MENU,
      options: {
        [WIDGET_OPTIONS.MENU_ITEMS]: {
          key: WIDGET_OPTIONS.MENU_ITEMS,
          defaultValue: ['goals','assists','cards'],
          label: 'Menu items',
          type: 'input',
        },
      },
    }, */
  },
};
