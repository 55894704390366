import styled from 'styled-components';

export const Untold = styled.a`
  background: var(--background-color);
  color: var(--font-color);
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: var(--ntb-space-1);
  padding: var(--ntb-space-2);
`;
