import { MATCH_STATUS, SPORT_GROUP } from '@ntb-sport/constants';
import {
  NifsCustomerStageType,
  NifsMatchesType,
  NifsMatchType,
} from '@ntb-sport/types';
import { UUIDs } from 'neo-common-enums';

export const getIsNifsSportGroup = (sportGroup: string | string[]) => {
  switch (sportGroup) {
    case SPORT_GROUP.FOOTBALL:
    case SPORT_GROUP.ICE_HOCKEY:
    case SPORT_GROUP.HANDBALL:
    case SPORT_GROUP.BANDY:
      return true;

    default:
      return false;
  }
};

export const getIsNeoSportGroup = (sportGroup: string | string[]) => {
  switch (sportGroup) {
    case SPORT_GROUP.WINTERSPORTS:
    case SPORT_GROUP.ATHLETICS:
    case SPORT_GROUP.CYCLING:
      return true;

    default:
      return false;
  }
};

export const getOngoingEvent = (event: any) => {
  const onGoingChildEvent = event?.childEvents?.find(
    (event: any) => event?.eventStatusId === UUIDs.EventStatus.ONGOING,
  );

  const onGoingEvent = event?.eventStatusId === UUIDs.EventStatus.ONGOING;

  return onGoingChildEvent || (onGoingEvent && event);
};

export const isMatchInProgress = (matchStatusId: number) => {
  switch (matchStatusId) {
    case MATCH_STATUS.ONGOING:
    case MATCH_STATUS.FIRST_HALF:
    case MATCH_STATUS.HALF_TIME:
    case MATCH_STATUS.SECOND_HALF:
    case MATCH_STATUS.SECOND_HALF_TIME:
    case MATCH_STATUS.PAUSE_BEFORE_EXTRA_TIME:
    case MATCH_STATUS.FIRST_HALF_EXTRA_TIME:
    case MATCH_STATUS.SECOND_HALF_TIME_EXTRA_TIME:
    case MATCH_STATUS.SECOND_HALF_EXTRA_TIME:
    case MATCH_STATUS.PENALTY_SHOOTOUT:
    case MATCH_STATUS.THIRD_HALF:
    case MATCH_STATUS.THIRD_HALF_EXTRA_TIME:
    case MATCH_STATUS.THIRD_HALF_TIME_EXTRA_TIME:
      return true;

    default:
      return false;
  }
};

export const filterMatchesNotPlayed = (fixtures: any) => {
  return fixtures?.filter((fixture: any) => {
    const fixtureDate = new Date(fixture?.timestamp).getTime();
    const currentDate = new Date().getTime();

    if (
      fixture?.matchStatusId !== MATCH_STATUS.PLAYED &&
      fixture?.matchStatusId !== MATCH_STATUS.CANCELLED &&
      fixture?.matchStatusId !== MATCH_STATUS.WILL_NOT_BE_PLAYED
    ) {
      return true;
    }
    if (
      (fixture?.matchStatusId === MATCH_STATUS.WILL_NOT_BE_PLAYED ||
        fixture?.matchStatusId === MATCH_STATUS.CANCELLED) &&
      fixtureDate > currentDate
    ) {
      return true;
    }
    return false;
  })
  
};

export const filterMatchesPlayed = (fixtures: any) => {
  return fixtures?.filter((fixture: any) => {
    const fixtureDate = new Date(fixture?.timestamp).getTime();
    const currentDate = new Date().getTime();

    if (fixture?.matchStatusId === MATCH_STATUS.PLAYED) {
      return true;
    }
    if (
      (fixture?.matchStatusId === MATCH_STATUS.WILL_NOT_BE_PLAYED ||
        fixture?.matchStatusId === MATCH_STATUS.CANCELLED) &&
      fixtureDate < currentDate
    ) {
      return true;
    }
    return false;
  });
};

export const getStagesWithMatches = (
  matches: NifsMatchesType | undefined,
  customerStages: any,
) => {
  if (!matches || !customerStages) return [];

  const stagesWithMatches = matches.reduce(
    (accumulator: any, match: NifsMatchType) => {
      const tournamentId = match?.stage?.tournament?.id;
      const stageId = match?.stage?.id;
      const matchId = match?.id;

      const customerTournamentId = customerStages.find(
        (cs: NifsCustomerStageType) =>
          cs?.stage?.tournament?.id === tournamentId,
      )?.stage?.tournament?.id;

      const customerStage = customerStages.find(
        (cs: NifsCustomerStageType) => cs?.stage?.id === stageId,
      );

      const customerStageId = customerStage?.stage?.id;
      const customerStagePriority = customerStage?.priority;

      if (
        customerTournamentId &&
        customerStageId &&
        !accumulator[customerTournamentId]
      ) {
        accumulator[customerTournamentId] = {
          ...match.stage.tournament,
          priority: customerStagePriority,
          stages: {},
        };
      } else if (
        accumulator[customerTournamentId] &&
        customerStagePriority < accumulator[customerTournamentId].priority
      ) {
        accumulator[customerTournamentId].priority = customerStagePriority;
      }

      if (
        customerTournamentId &&
        customerStageId &&
        !accumulator[customerTournamentId].stages[customerStageId]
      ) {
        accumulator[customerTournamentId].stages[customerStageId] = {
          ...match.stage,
          priority: customerStagePriority,
          matches: {},
        };
      }

      if (
        customerTournamentId &&
        customerStageId &&
        !accumulator[customerTournamentId].stages[customerStageId].matches[
          matchId
        ]
      ) {
        accumulator[customerTournamentId].stages[customerStageId].matches[
          matchId
        ] = {
          ...match,
        };
      }

      return accumulator;
    },
    {},
  );

  return stagesWithMatches;
};

export const isMatchNotStarted = (matchStatusId: number) => {
  switch (matchStatusId) {
    case MATCH_STATUS.NOT_STARTED:
    case MATCH_STATUS.POSTPONED:
    case MATCH_STATUS.WILL_NOT_BE_PLAYED:
    case MATCH_STATUS.CANCELLED:
      return true;

    default:
      return false;
  }
};

export function camelize(str: string) {
  let arr = str.split('-');
  let capital = arr.map((item, index) =>
    index
      ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
      : item.toLowerCase(),
  );
  let capitalString = capital.join('');

  return capitalString;
}

export const getTeamForm = ({
  teamId,
  winningTeamId,
}: {
  teamId: number;
  winningTeamId: number;
}) => {
  if (winningTeamId && teamId === winningTeamId) {
    return 'S';
  } else if (winningTeamId && teamId !== winningTeamId) {
    return 'T';
  } else {
    return 'U';
  }
};

export const getWinningTeamId = ({
  result,
  homeTeamId,
  awayTeamId,
  matchRelation,
}: {
  result: any;
  homeTeamId: any;
  awayTeamId: any;
  matchRelation: any;
}) => {
  if (homeTeamId === matchRelation?.winningTeamId) {
    return homeTeamId;
  } else if (awayTeamId === matchRelation?.winningTeamId) {
    return awayTeamId;
  } else if (
    result &&
    Object.prototype.hasOwnProperty.call(result, 'homeScorePenalties')
  ) {
    if (result?.homeScorePenalties > result?.awayScorePenalties)
      return homeTeamId;
    else return awayTeamId;
  } else if (
    result &&
    Object.prototype.hasOwnProperty.call(result, 'homeScore120')
  ) {
    if (result?.homeScore120 > result?.awayScore120) return homeTeamId;
    else return awayTeamId;
  } else if (result?.homeScore90 > result?.awayScore90) {
    return homeTeamId;
  } else if (result?.awayScore90 > result?.homeScore90) {
    return awayTeamId;
  } else {
    return null;
  }
};

export const groupMatches = ({ data, type }: { data: any; type: any }) => {
  return data.reduce((matches: any, match: any) => {
    let key = match?.round;

    if (!matches[key]) {
      matches[key] = {
        matchTypeId: match?.matchTypeId || `round${match.round}`,
        round: match?.round,
        date: match?.timestamp && match?.timestamp.split('T')[0],
        matches: [],
      };
    }

    matches[key].matches.push(match);

    return matches;
  }, {});
};

export const getNextMatch = (
  matches: NifsMatchType[],
  delay: number = 0,
): NifsMatchType => {
  // Get the current timestamp
  const currentTimestamp = new Date();
  currentTimestamp.setMinutes(currentTimestamp.getMinutes() - delay);

  // Filter events that are in the future
  const upcomingMatches = matches.filter(
    (match) => new Date(match.timestamp) > currentTimestamp,
  );

  // Sort the upcoming events by timestamp
  upcomingMatches.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
  );

  // The first element in the sorted array is the next upcoming event
  return upcomingMatches[0];
};
