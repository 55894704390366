import styled, { css } from 'styled-components';
import { MatchesType } from '@ntb-sport/types';

interface StyledProps {
  $matches?: MatchesType;
  $isHomeTeam?: boolean;
  $highlightWinningTeam?: boolean;
  $isWinningTeam?: boolean;
  $isClickable?: boolean;
  $isTeamWinning?: boolean;
}

export const Team = styled.div<StyledProps>`
  background-color: var(--background-color);
  grid-area: ${(props) => (props.$isHomeTeam ? 'homeTeam' : 'awayTeam')};
  display: flex;
  align-items: center;
  opacity: ${(props) =>
    props.$highlightWinningTeam && !props.$isWinningTeam && 0.4};
  flex-direction: column;
  column-gap: var(--ntb-space-2);
  row-gap: var(--ntb-space-1);

  ${(props) =>
    props?.$isClickable &&
    css`
      cursor: pointer;
    `}
`;

export const TeamLogoWrapper = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const TeamLogo = styled.img<StyledProps>`
  max-height: 30px;
  max-width: 30px;

  ${(props) =>
    props?.$matches?.tablet &&
    css`
      max-height: 30px;
      max-width: 30px;
    `};
`;

export const TeamName = styled.span<StyledProps>`
  color: var(--font-color);
  font-size: ${(props) =>
    props?.$matches?.desktop
      ? 'var(--ntb-fontSizes-md)'
      : 'var(--ntb-fontSizes-sm)'};
  text-decoration: ${(props) =>
    props.$highlightWinningTeam && !props.$isWinningTeam && 'line-through'};
  font-weight: ${(props) =>
    props.$highlightWinningTeam && props.$isTeamWinning
      ? 'var(--ntb-fontWeights-semibold)'
      : 'var(--ntb-fontWeights-medium)'};
  text-align: center;

  ${(props) =>
    props?.$isClickable &&
    css`
      &:hover {
        text-decoration: underline;
      }
    `}
`;
