import styled from 'styled-components';

export const StageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--ntb-space-2) var(--ntb-space-3);

  background: var(--background-color);
  color: var(--font-color);
  box-shadow: inset 0 -1px 0 var(--border-color);
`;

export const StageName = styled.span`
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-medium);

  &::first-letter {
    text-transform: capitalize;
  }
`;

export const StageStandings = styled.button`
  grid-area: link;
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
  justify-content: end;
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-medium);
  background-color: var(--background-color);
  color: var(--font-color);

  span {
    line-height: 1;
  }

  &:hover {
    background-color: var(--background-color__hover);
    color: var(--font-color__hover);
  }
`;
