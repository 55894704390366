import Kit from './Kit';
import ShirtNumber from './ShirtNumber';

const TwoColorDiagonalFullColor = ({
  kitColor1 = '#f79025',
  kitColor2 = '#adadad',
  shirtNumber,
  shirtNumberColor,
  shirtNumberBackgroundColor,
  shirtNumberBackgroundOpacity,
  ...restProps
}: {
  size?: any;
  kitColor1: any;
  kitColor2: any;
  kitColor3: any;
  shirtNumber: any;
  shirtNumberColor: any;
  shirtNumberBackgroundColor: any;
  shirtNumberBackgroundOpacity: any;
}) => {
  const shadow = '#000000';

  return (
    <Kit {...restProps}>
      <g>
        <g>
          <g>
            <path
              style={{ fill: kitColor1 }}
              d="M69.13,2.82c2.77,0,7.29-0.36,8.68-0.53c1.38-0.17,3.61,0.2,4.68,0.53l2.08,3.19
                        c2.26,0.53,11.11,2.66,12.85,3.37c1.74,0.71,4,1.77,5.38,1.94c1.39,0.19,3.47,0.72,4.86,1.24c1.38,0.54,4.69,3.02,5.9,4.8
                        c1.21,1.77,8.24,16.3,9.8,18.62c1.57,2.3,12.42,22.34,12.94,23.23c0.52,0.88,1.03,3.19,0.17,3.36c-0.87,0.19-8.33,3.37-11.46,6.03
                        c-3.13,2.67-9.03,5.68-9.71,6.03c-0.71,0.35-1.14,0.14-1.83-0.58c-0.69-0.7-7.73-12.01-7.73-12.01c-1.21,2.66-1.21,5.67-1.04,7.62
                        c0.18,1.96-0.87,11.8-0.35,14.63c0.52,2.84,0.52,30.33,0.87,32.82c0.35,2.48,0.86,7.44,0.69,9.93c-0.18,2.48,0,5.67,0,7.09
                        c0,1.43-0.18,2.67-1.56,3.2c-1.39,0.53-13.89,3.9-35.24,3.19c-21.35,0.71-33.85-2.66-35.23-3.19c-1.38-0.53-1.56-1.77-1.56-3.2
                        c0-1.41,0.18-4.61,0-7.09c-0.18-2.49,0.35-7.45,0.69-9.93c0.35-2.49,0.35-29.98,0.87-32.82c0.51-2.83-0.52-12.68-0.35-14.63
                        c0.18-1.95,0.18-4.96-1.04-7.62c0,0-7.3,11.53-7.99,12.24c-0.69,0.71-0.87,0.71-1.56,0.35c-0.7-0.35-6.65-3.64-10.14-5.83
                        c-4.04-2.53-9.75-5.42-11.03-6.23c-0.75-0.48-0.35-2.48,0.17-3.36c0.52-0.89,11.46-20.75,13.02-23.05
                        c1.56-2.32,8.5-17.04,9.72-18.8c1.22-1.78,4.51-4.26,5.91-4.8c1.39-0.53,3.46-1.05,4.86-1.24c1.39-0.17,3.63-1.28,5.38-1.94
                        c1.97-0.74,10.83-2.9,12.84-3.37l2.08-3.19c0.87-0.35,3.3-0.71,4.69-0.53C61.84,2.46,66.35,2.82,69.13,2.82z"
            />
          </g>
          <g>
            <path
              style={{ fill: kitColor2 }}
              d="M2.08,57.69c0.04,0.32,0.61,2.16,1.35,2.31c0.87,0.17,8.33,3.37,11.45,6.03c3.13,2.67,9.04,5.68,9.72,6.04
                        c0.63,0.32,1.99-0.03,2.13-0.08l-2.57,3.31l-2.56-0.64L1.16,62.99l-0.38-3.27L2.08,57.69z"
            />
          </g>
          <g>
            <path
              style={{ fill: kitColor2 }}
              d="M76.95,15.83c-0.96,0.6-7.79,5.14-7.79,15.23h-0.15c0-10.18-6.95-14.71-7.82-15.24
                        c3.55,1.71,7.11,3.6,7.89,3.84C69.86,19.42,73.4,17.53,76.95,15.83z"
            />
          </g>
          <g>
            <path
              style={{ fill: kitColor2 }}
              d="M105.92,127.06c-0.07,1.04-0.08,2.21-0.07,3.31c-0.15,0.9-0.52,1.62-1.49,2
                        c-1.39,0.54-13.89,3.9-35.24,3.2c-21.36,0.7-33.84-2.67-35.24-3.2c-0.99-0.37-1.35-1.11-1.49-2.02c0.01-1.1,0-2.25-0.08-3.28
                        c-0.17-2.49,0.35-7.45,0.7-9.92c0.35-2.49,0.35-29.99,0.87-32.83c0.51-2.83-0.52-12.67-0.35-14.64c0.17-1.94,0.17-4.96-1.03-7.62
                        c0,0,0.04-14.24,0.08-20.4c0.01-2.25,0.17-4.48,0.28-6.72c0.19-2.46,0.5-5.1,0.99-7.47c0.07-0.31,0.13-0.62,0.21-0.95l70.86,80.4
                        c0.01,0.32,0.01,0.64,0.03,0.96c0,4.16,0.23,8.3,0.64,12.44C105.83,122.53,106.06,124.82,105.92,127.06z"
            />
          </g>
          <g>
            <path
              style={{ fill: kitColor2 }}
              d="M123.37,35.99c-1.51-2.25-8.16-15.96-9.67-18.43c-0.05-0.08-0.09-0.15-0.13-0.2
                        c-0.08-0.11-0.16-0.23-0.25-0.33c-0.08-0.11-0.17-0.21-0.27-0.33c-1.46-1.67-4.17-3.64-5.38-4.12c-1.39-0.52-3.47-1.04-4.86-1.25
                        c-1.38-0.17-3.64-1.23-5.38-1.94c-1.18-0.48-5.69-1.63-9.15-2.48l-3.46-1.43l-1.73-3.23l-4.15-0.67l-8.3,0.67L59.24,1.57
                        l-3.74,0.67l-1.96,3.33c-0.39,0.91-0.47,3.44-0.2,5.72c0.31,2.49,1.15,5.21,2.91,6.92c0.13,0.12,4.08-3.83,4.08-3.83
                        s7.85,3.9,8.76,4.18c0.91-0.28,9.11-4.38,9.11-4.38s2.58,3.98,2.75,3.84c2-1.62,3.11-4.43,3.58-6.98c0.46,0.11,0.9,0.21,1.34,0.32
                        c6.86,1.78,11.08,4.92,15.37,8.92c3.63,3.37,4.23,15.54,4.26,21.17c0.04,6.16,0.24,20.58,0.25,20.61l2.62,4.25
                        c0.87,1.39,1.78,2.77,2.68,4.15c0.4,0.62,0.78,1.21,1.13,1.71c0.29,0.09,0.78,0.2,1.23-0.03c0.68-0.35,6.59-3.36,9.71-6.03
                        c3.13-2.67,10.81-5.97,11.68-6.15c0.75-0.15,0.95-1.34,0.99-1.66C133.57,54.27,124.77,38.05,123.37,35.99z M78.7,11.03
                        c-4.03,1.86-9.63,6.13-9.63,6.13s-5.6-4.27-9.64-6.13c-2.49-1.15-3.84-3.42-4.17-5.34c1.21-0.33,3.98-0.67,5.21-0.51
                        c1.39,0.17,5.83,0.52,8.6,0.52s7.19-0.35,8.58-0.52c1.3-0.17,4.11,0.23,5.21,0.56C82.52,7.65,81.17,9.89,78.7,11.03z"
            />
          </g>
          <g>
            <path
              style={{ fill: shadow }}
              d="M82.87,5.74c-0.35,1.92-1.7,4.15-4.17,5.29c-4.03,1.86-9.63,6.13-9.63,6.13s-5.6-4.27-9.64-6.13
                        c-2.49-1.15-3.84-3.42-4.17-5.34c1.21-0.33,3.98-0.67,5.21-0.51c1.39,0.17,5.83,0.52,8.6,0.52s7.19-0.35,8.58-0.52
                        C78.96,5,81.77,5.4,82.87,5.74z"
            />
            <path
              opacity="0.16"
              d="M136.6,62.51c0.86-0.18,0.35-2.48-0.17-3.37c-0.3-0.51-3.93-7.08-7.31-13.12
                        c-6.25,2.94-14.52-0.07-23.84-13.57l0.34,9.94l0.12,19.66c0,0,7.03,11.31,7.73,12.01c0.69,0.71,1.25,0.86,1.95,0.51
                        c0.68-0.36,6.58-3.37,9.71-6.03C128.28,65.88,135.73,62.69,136.6,62.51z"
            />
            <path
              opacity="0.16"
              d="M1.62,62.51c-0.86-0.18-0.34-2.48,0.18-3.37c0.29-0.51,3.92-7.08,7.31-13.12
                        c6.25,2.94,15.6-0.46,24.23-14.08l-0.57,13.53l-0.01,16.45c0,0-7.58,11.94-8.27,12.64c-0.7,0.71-1,0.35-1.69,0.01
                        c-0.69-0.36-7.2-3.45-10.68-5.63C8.19,66.49,2.49,62.69,1.62,62.51z"
            />
            <path
              opacity="0.16"
              d="M104.39,137.8c1.38-0.53,1.56-1.77,1.56-3.19c0-1.42-0.18-4.61,0-7.09c0.16-2.3-0.27-6.7-0.6-9.32
                        c-5.17,4.71-8.97,14.72-10.63,21.6C100.38,138.99,103.71,138.06,104.39,137.8z"
            />
            <path
              opacity="0.16"
              d="M33.66,137.73c-1.38-0.53-1.56-1.77-1.56-3.19c0-1.42,0.18-4.61,0-7.09c-0.15-2.3,0.27-6.7,0.61-9.32
                        c5.16,4.71,8.96,14.72,10.63,21.6C37.66,138.92,34.34,137.99,33.66,137.73z"
            />
            <g>
              <g>
                <path
                  d="M59.61,2.24c0.3,0,0.59,0.01,0.84,0.05c1.39,0.18,5.91,0.53,8.68,0.53s7.29-0.36,8.68-0.53
                                c0.24-0.03,0.5-0.04,0.78-0.04c1.33,0,3.01,0.3,3.9,0.58l2.08,3.19c2.26,0.53,11.11,2.66,12.85,3.37c1.74,0.71,4,1.77,5.38,1.94
                                c1.39,0.19,3.47,0.72,4.86,1.24c1.38,0.54,4.69,3.02,5.9,4.8c1.21,1.77,8.24,16.3,9.8,18.62c1.57,2.3,12.42,22.34,12.94,23.23
                                c0.52,0.88,1.03,3.19,0.17,3.36c-0.87,0.19-8.33,3.37-11.46,6.03c-3.13,2.67-9.03,5.68-9.71,6.03
                                c-0.22,0.11-0.42,0.17-0.61,0.17c-0.4,0-0.75-0.25-1.22-0.74c-0.69-0.7-7.73-12.01-7.73-12.01c-1.21,2.66-1.21,5.67-1.04,7.62
                                c0.18,1.96-0.87,11.8-0.35,14.64c0.52,2.84,0.52,30.33,0.87,32.82c0.35,2.48,0.86,7.44,0.69,9.93c-0.18,2.48,0,5.67,0,7.09
                                c0,1.43-0.18,2.67-1.56,3.2c-1.26,0.48-11.64,3.29-29.44,3.29c-1.85,0-3.78-0.03-5.8-0.1c-2.01,0.07-3.94,0.1-5.8,0.1
                                c-17.81,0-28.18-2.81-29.44-3.29c-1.38-0.53-1.56-1.77-1.56-3.2c0-1.41,0.18-4.61,0-7.09c-0.18-2.49,0.35-7.45,0.69-9.93
                                c0.35-2.49,0.35-29.98,0.87-32.82c0.51-2.83-0.52-12.68-0.35-14.64c0.18-1.95,0.18-4.96-1.04-7.62c0,0-7.3,11.53-7.99,12.24
                                c-0.4,0.42-0.63,0.59-0.89,0.59c-0.18,0-0.38-0.09-0.67-0.23c-0.7-0.35-6.65-3.64-10.14-5.83c-4.04-2.53-9.75-5.42-11.03-6.23
                                c-0.75-0.48-0.35-2.48,0.17-3.36c0.52-0.89,11.46-20.75,13.02-23.05c1.56-2.32,8.5-17.04,9.72-18.8
                                c1.22-1.78,4.51-4.26,5.91-4.8c1.39-0.53,3.46-1.05,4.86-1.24c1.39-0.17,3.63-1.28,5.38-1.94c1.97-0.74,10.83-2.9,12.84-3.37
                                l2.08-3.19C56.47,2.53,58.23,2.24,59.61,2.24 M59.61,1.24L59.61,1.24c-1.44,0-3.34,0.29-4.22,0.65
                                c-0.19,0.08-0.35,0.21-0.46,0.38l-1.86,2.86C50.7,5.69,42.47,7.7,40.48,8.44c-0.54,0.2-1.13,0.45-1.7,0.69
                                c-1.22,0.51-2.61,1.09-3.45,1.2c-1.52,0.21-3.66,0.76-5.09,1.3c-1.65,0.64-5.1,3.31-6.38,5.17c-0.62,0.9-2.42,4.52-4.5,8.72
                                c-2.08,4.19-4.44,8.93-5.22,10.09c-1.21,1.78-7.69,13.45-11.17,19.72c-1.03,1.85-1.77,3.18-1.89,3.39
                                c-0.58,0.99-1.35,3.75,0.16,4.71c0.51,0.32,1.56,0.89,3.01,1.68c2.33,1.26,5.52,2.99,8.02,4.56c3.51,2.2,9.47,5.5,10.22,5.87
                                c0.35,0.18,0.7,0.34,1.12,0.34c0.69,0,1.15-0.42,1.61-0.89c0.59-0.6,4.68-6.98,7-10.64c0.46,1.89,0.44,3.81,0.31,5.24
                                c-0.08,0.85,0.04,2.8,0.19,5.27c0.2,3.42,0.46,7.69,0.17,9.28c-0.28,1.54-0.41,8.92-0.55,19.19
                                c-0.09,6.21-0.18,12.63-0.32,13.67c-0.38,2.7-0.88,7.63-0.7,10.13c0.12,1.74,0.07,3.87,0.03,5.44
                                c-0.02,0.63-0.03,1.18-0.03,1.58c0,1.07,0,3.29,2.2,4.13c1.75,0.67,12.53,3.36,29.8,3.36c1.9,0,3.85-0.03,5.8-0.1
                                c1.94,0.06,3.89,0.1,5.8,0.1c17.24,0,28.05-2.69,29.8-3.35c2.2-0.84,2.2-3.07,2.2-4.13c0-0.4-0.01-0.95-0.03-1.58
                                c-0.04-1.56-0.1-3.7,0.03-5.44c0.17-2.52-0.32-7.44-0.7-10.13c-0.15-1.05-0.24-7.48-0.32-13.7
                                c-0.14-10.25-0.27-17.62-0.55-19.16c-0.3-1.6-0.04-5.89,0.17-9.33c0.15-2.44,0.26-4.37,0.19-5.21
                                c-0.13-1.43-0.15-3.34,0.31-5.22c2.25,3.6,6.16,9.8,6.75,10.4c0.52,0.54,1.12,1.04,1.93,1.04c0.34,0,0.69-0.09,1.05-0.27
                                l0.08-0.04c2.02-1.04,7.01-3.71,9.84-6.13c2.95-2.5,10.12-5.6,11.02-5.81c0.46-0.09,0.84-0.4,1.05-0.87
                                c0.51-1.13-0.09-3.15-0.57-3.97c-0.12-0.21-0.94-1.71-1.98-3.61c-3.42-6.26-9.79-17.91-11-19.68c-0.78-1.16-3.16-5.85-5.26-9.99
                                c-2.11-4.15-3.93-7.74-4.54-8.64c-1.36-1.99-4.88-4.58-6.36-5.16c-1.45-0.55-3.58-1.09-5.09-1.3c-0.97-0.12-2.58-0.8-4-1.4
                                c-0.4-0.17-0.78-0.33-1.14-0.48C95.95,7.7,87.16,5.59,85.19,5.13l-1.86-2.86c-0.13-0.2-0.32-0.34-0.54-0.41
                                c-1.01-0.31-2.77-0.62-4.2-0.62c-0.33,0-0.63,0.02-0.9,0.05c-1.45,0.18-5.91,0.53-8.55,0.53c-2.64,0-7.13-0.34-8.55-0.53
                                C60.29,1.25,59.96,1.24,59.61,1.24L59.61,1.24z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <ShirtNumber
        shirtNumber={shirtNumber}
        shirtNumberColor={shirtNumberColor}
        shirtNumberBackgroundColor={shirtNumberBackgroundColor}
        shirtNumberBackgroundOpacity={shirtNumberBackgroundOpacity}
      />
    </Kit>
  );
};

export default TwoColorDiagonalFullColor;
