import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import { CSS_VARIABLES } from '../index';

export const TOUR_STANDINGS_STYLES = [
  {
    name: 'Root',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TOUR_STANDINGS}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'List item',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TOUR_STANDINGS}'] [data-element-id='${ELEMENT_IDS.LIST_ITEM}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Default',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TOUR_STANDINGS}'] [data-element-id='${ELEMENT_IDS.TYPE_DEFAULT}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Highlight',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TOUR_STANDINGS}'] [data-element-id='${ELEMENT_IDS.TYPE_HIGHLIGHT}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
];
