import NrkVideoPlayer from '../../../common/MediaPlayer/NrkVideoPlayer';
import { LabelProperties } from 'neo-common-enums';

import * as S from './EventHeroVideo.styled';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  MenuChakra as Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '../../../chakra';

interface EventHeroVideoType {
  streams: any;
  videoId: string;
  onClick: (videoId: string) => void;
}

export const EventHeroVideo = ({
  streams,
  videoId,
  onClick,
}: EventHeroVideoType) => {
  return (
    <>
      {videoId && (
        <NrkVideoPlayer
          videoId={videoId}
          sourceId="ntbsport:Resultatliste"
          autoplay={false}
          ga="UA-3413696-1"
        />
      )}

      {Object.keys(streams?.[LabelProperties.BroadcastType.TV])?.length > 1 && (
        <S.Controller>
          <Menu>
            <MenuButton size="sm" as={Button} rightIcon={<ChevronDownIcon />}>
              {`${
                Object.values<any>(
                  streams?.[LabelProperties.BroadcastType.TV],
                )?.find((video: any) => video?.streamId === videoId)
                  ?.broadcastingChannel?.name
              }
             (${Object.values<any>(streams?.[LabelProperties.BroadcastType.TV])
               ?.find((video: any) => video?.streamId === videoId)
               ?.roundTypes?.sort((a: any, b: any) =>
                 a?.order < b?.order ? -1 : 0,
               )
               ?.map((roundType: any) => roundType?.groupName)
               ?.join(', ')})`}
            </MenuButton>
            <MenuList>
              {Object.values(streams?.[LabelProperties.BroadcastType.TV])?.map(
                (video: any) => (
                  <MenuItem
                    justifyContent="flex-start"
                    onClick={() => onClick(video?.streamId)}
                  >
                    {`${video?.broadcastingChannel?.name} (${video?.roundTypes
                      ?.sort((a: any, b: any) => (a?.order < b?.order ? -1 : 0))
                      ?.map((roundType: any) => roundType?.groupName)
                      ?.join(', ')})`}
                  </MenuItem>
                ),
              )}
            </MenuList>
          </Menu>
        </S.Controller>
      )}
    </>
  );
};
