import { useQuery } from '@tanstack/react-query';

import { fetchAthletes, FetchAthletesType } from './fetch';
import { QUERY_KEYS } from '@ntb-sport/constants';

interface UseQueryNifsPersons {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: FetchAthletesType;
  options?: Record<string, unknown>;
}

export const useQueryNifsPersons = ({
  queryKey = QUERY_KEYS.ATHLETES,
  queryKeyParams,
  params,
  options,
}: UseQueryNifsPersons) => {
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchAthletes(params),
    enabled: Boolean(params.sportGroup) && Boolean(params.query?.length >= 4),
    ...options,
  });

  return { data, isLoading, error, isFetching };
};
