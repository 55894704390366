import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '@ntb-sport/constants';
import { fetchCountryMedals, FetchCountryMedalsType } from './fetch';
import { getIsNeoSportGroup } from '@ntb-sport/helpers';

interface UseQueryCountryMedalsParams extends FetchCountryMedalsType {
  sportGroup: string;
}

interface UseQueryCountryMedals {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: UseQueryCountryMedalsParams;
  options?: Record<string, unknown>;
}

export const useQueryCountryMedals = ({
  queryKey = QUERY_KEYS.MEDAL_TABLE_COUNTRY,
  queryKeyParams,
  params,
  options,
}: UseQueryCountryMedals) => {
  const isNeoSportGroup = getIsNeoSportGroup(params.sportGroup);

  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchCountryMedals(params),
    enabled:
      isNeoSportGroup &&
      Boolean(params.customerUUID) &&
      Boolean(params.token) &&
      Boolean(params.medalTableUUID) &&
      Boolean(params.countryUUID),
    ...options,
  });

  return { data, isLoading, error };
};
