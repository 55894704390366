import styled from 'styled-components';

interface StyledProps {
  $isHomeTeam?: boolean;
}

export const Team = styled.div<StyledProps>`
  grid-area: ${(props) => (props.$isHomeTeam ? 'hometeam' : 'awayteam')};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--font-color);
`;

export const TeamLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 var(--ntb-space-2) 0;
`;
