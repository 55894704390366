import './styles.css';
import { ReactElement, ReactNode, useEffect } from 'react';
import type { AppProps } from 'next/app';
import type { NextPage } from 'next';
import { ChakraProvider } from '@chakra-ui/react';
import { SocketProvider } from '../components/SocketProvider/SocketProvider';
import { ThemeProvider } from '../components/ThemeProvider/ThemeProvider';
import { i18nInit } from '@ntb-sport/i18n';
import Script from 'next/script';
import * as gtag from '../gtag';
import { useRouter } from 'next/router';
import {
  ApiDevtools,
  ApiHydrationBoundary,
  ApiProvider,
  createApiClient,
} from '@ntb-sport/api';
import {
  CustomerDevTools,
  NifsCustomerProvider,
  SportEditCustomerProvider,
} from '@ntb-sport/customer';

import { CustomerScripts } from '../components/CustomerScripts/CustomerScripts';
import { getEnglishSportGroupFromUrl } from '@ntb-sport/helpers';
import { useSessionStorage } from '@ntb-sport/hooks';
import Head from 'next/head';
import theme from '../theme';
import { ErrorBoundary } from '@ntb-sport/ui';

const apiClient = createApiClient();

export type NextPageWithLayout<P = Record<string, never>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

// export function reportWebVitals(metric: NextWebVitalsMetric) {
//   switch (metric.name) {
//     case 'FCP':
//       console.group('Metrics FCP');
//       if (metric.value <= 2000)
//         console.log(`%c FCP ${metric.value}`, 'color: green');
//       else if (metric.value > 2000 && metric.value <= 4000)
//         console.log(`%c FCP ${metric.value}`, 'color: orange');
//       else console.log(`%c FCP ${metric.value}`, 'color: red');
//       console.groupEnd();
//       break;
//     case 'LCP':
//       console.group('Metrics LCP');
//       if (metric.value <= 2500)
//         console.log(`%c LCP ${metric.value}`, 'color: green');
//       else if (metric.value > 2500 && metric.value <= 4000)
//         console.log(`%c LCP ${metric.value}`, 'color: orange');
//       else console.log(`%c LCP ${metric.value}`, 'color: red');
//       console.groupEnd();
//       break;
//     case 'CLS':
//       console.group('Metrics CLS');
//       if (metric.value <= 0.1)
//         console.log(`%c CLS ${metric.value}`, 'color: green');
//       else if (metric.value > 0.1 && metric.value <= 0.25)
//         console.log(`%c CLS ${metric.value}`, 'color: orange');
//       else console.log(`%c CLS ${metric.value}`, 'color: red');
//       console.groupEnd();
//       break;
//     case 'FID':
//       console.group('Metrics FID');
//       if (metric.value <= 100)
//         console.log(`%c FID ${metric.value}`, 'color: green');
//       else if (metric.value > 100 && metric.value <= 200)
//         console.log(`%c FID ${metric.value}`, 'color: orange');
//       else console.log(`%c FID ${metric.value}`, 'color: red');
//       console.groupEnd();
//       break;
//     case 'TTFB':
//       console.group('Metrics TTFB');
//       if (metric.value <= 800)
//         console.log(`%c TTFB ${metric.value}`, 'color: green');
//       else if (metric.value > 800 && metric.value <= 1800)
//         console.log(`%c TTFB ${metric.value}`, 'color: orange');
//       else console.log(`%c TTFB ${metric.value}`, 'color: red');
//       console.groupEnd();
//       break;
//     case 'INP':
//       // handle INP results (note: INP is still an experimental metric)
//       console.group('Metrics INP');
//       if (metric.value <= 200)
//         console.log(`%c INP ${metric.value}`, 'color: green');
//       else if (metric.value > 200 && metric.value <= 500)
//         console.log(`%c INP ${metric.value}`, 'color: orange');
//       else console.log(`%c INP ${metric.value}`, 'color: red');
//       console.groupEnd();
//       break;
//     default:
//       break;
//   }
// }

function CustomApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();
  const { query } = router;

  const sportGroup = query.sportGroup as string;

  const [host, setHost] = useSessionStorage('host', undefined);

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    i18nInit();
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker.register('/sw.js').then(
          function (registration) {
            console.log(
              'Service Worker registration successful with scope: ',
              registration.scope,
            );
          },
          function (err) {
            console.log('Service Worker registration failed: ', err);
          },
        );
      });
    }
  }, []);

  useEffect(() => {
    const toastRegions = document.querySelectorAll(
      '.chakra-portal [role=region]',
    );
    toastRegions.forEach((region) => {
      region.setAttribute('aria-hidden', 'true');
    });
  }, [router]);
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://livecenter.norkon.net/scripts/ncposts/ncposts-6.1.1.min.css"
          rel="stylesheet"
          id="lc-css-link"
        />
      </Head>

      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />

      {/* TODO: Figure out why these does not work when loaded inside customerScripts FIXME: NRK */}
      <Script
        strategy="afterInteractive"
        src="https://static.nrk.no/nrkno-player/major/2/nrkno-player-entry.js"
      />

      <Script
        id="nrknoMasthead"
        src="https://static.nrk.no/nrkno-masthead/major/6/nrkno-masthead-render.min.js"
        strategy="beforeInteractive"
      ></Script>

      <Script src="https://livecenter.norkon.net/scripts/ncposts/ncposts-6.1.1.min.js"></Script>
      <ChakraProvider theme={theme}>
        <ErrorBoundary>
          <ApiProvider client={apiClient}>
            <ApiDevtools initialIsOpen={false} />

            <ApiHydrationBoundary state={pageProps.dehydratedState}>
              <NifsCustomerProvider
                apiBaseUrl={process.env.NIFS_API_BASE_URL}
                sportGroup={getEnglishSportGroupFromUrl(sportGroup)}
                site={host}
                active={true}
              >
                <SportEditCustomerProvider
                  apiBaseUrl={process.env.NEXT_PUBLIC_NEO_API_BASE_URL}
                >
                  <CustomerScripts />
                  <SocketProvider>
                    <ThemeProvider>
                      <CustomerDevTools
                        initialIsOpen={false}
                        onChange={setHost}
                      />
                      <div id="app" className="app">
                        {getLayout(<Component {...pageProps} />)}
                      </div>
                    </ThemeProvider>
                  </SocketProvider>
                </SportEditCustomerProvider>
              </NifsCustomerProvider>
            </ApiHydrationBoundary>
          </ApiProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </>
  );
}

export default CustomApp;
