import { Icon } from '../../chakra';

export const StatisticsIcon = (props: any) => (
  <Icon {...props}>
    <g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M22.6,20.9c0,0.3-0.3,0.6-0.6,0.6H0.7c-0.3,0-0.6-0.3-0.6-0.6V1.7c0.1-0.4,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6
				v18.7H4v-3.9h3.8v3.9h2.7v-8.1h3.8v8.1h2.8v-6.1H21v6.1h1.1C22.4,20.5,22.6,20.6,22.6,20.9z"
          />
          <path
            fill="currentColor"
            d="M6,14.8c1.5,0,2.8-1.2,2.8-2.8c0-0.6-0.2-1.2-0.6-1.7L11.2,7c0.4,0.2,0.8,0.3,1.3,0.3c0.7,0,1.4-0.3,1.9-0.7
				l2.2,1.6c-0.2,0.3-0.2,0.7-0.2,1.1c0,1.5,1.2,2.8,2.8,2.8c1.5,0,2.8-1.2,2.8-2.8c0-1.5-1.2-2.8-2.8-2.8c-0.7,0-1.4,0.3-1.9,0.7
				l-2.2-1.6c0.1-0.3,0.2-0.6,0.2-1c0-1.5-1.2-2.8-2.8-2.8C11,1.8,9.8,3,9.8,4.6c0,0.6,0.2,1.2,0.6,1.7L7.3,9.7
				C6.9,9.5,6.5,9.4,6,9.4c-1.5,0-2.8,1.2-2.8,2.8C3.3,13.7,4.5,14.8,6,14.8z M19,7.6c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6
				s-1.6-0.7-1.6-1.6c0-0.4,0.1-0.7,0.3-0.9C18,7.9,18.5,7.6,19,7.6z M12.5,3c0.9,0,1.6,0.7,1.6,1.6c0,0.4-0.1,0.7-0.3,0.9
				c-0.3,0.4-0.8,0.7-1.3,0.7c-0.4,0-0.7-0.1-1-0.4l0,0c-0.3-0.3-0.6-0.7-0.6-1.2C10.9,3.7,11.6,3,12.5,3z M6,10.5
				c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6S5.1,10.5,6,10.5z"
          />
        </g>
      </g>
    </g>
  </Icon>
);
