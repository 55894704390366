import styled, { css } from 'styled-components';

/* eslint-disable-next-line */
export interface SetupProps {
  fontSizes: any;
  fontWeights: any;
}

/* eslint-disable-next-line */
export interface Props {
  fontSize?: any;
  fontWeight?: any;
  lineHeight?: any;
}

// --chakra-fontSizes-3xs: 0.45rem;
//     --chakra-fontSizes-2xs: 0.625rem;
//     --chakra-fontSizes-xs: 0.75rem;
//     --chakra-fontSizes-sm: 0.875rem;
//     --chakra-fontSizes-md: 1rem;
//     --chakra-fontSizes-lg: 1.125rem;
//     --chakra-fontSizes-xl: 1.25rem;
//     --chakra-fontSizes-2xl: 1.5rem;
//     --chakra-fontSizes-3xl: 1.875rem;
//     --chakra-fontSizes-4xl: 2.25rem;
//     --chakra-fontSizes-5xl: 3rem;
//     --chakra-fontSizes-6xl: 3.75rem;
//     --chakra-fontSizes-7xl: 4.5rem;
//     --chakra-fontSizes-8xl: 6rem;
//     --chakra-fontSizes-9xl: 8rem;

const TYPOGRAPHY_CONFIG = {
  FONT_SIZES: {
    ExtraSmall: '0.625',
    Small: '0.75',
    Text: '0.875',
    Heading6: '1',
    Heading5: '1.25',
    Heading4: '1.5',
    Heading3: '1.875',
    Heading2: '2.25',
    Heading1: '3',
    Display3: '3.75',
    Display2: '4.5',
    Display1: '6',
  },
  FONT_WEIGHTS: {
    THIN: 100,
    LIGHT: 300,
    NORMAL: 400,
    MEDIUM: 500,
    SEMI_BOLD: 600,
    BOLD: 700,
  },
};

export const setupTypography = ({ fontSizes, fontWeights }: SetupProps) => {
  TYPOGRAPHY_CONFIG.FONT_SIZES = {
    ...TYPOGRAPHY_CONFIG.FONT_SIZES,
    ...fontSizes,
  };
  TYPOGRAPHY_CONFIG.FONT_WEIGHTS = {
    ...TYPOGRAPHY_CONFIG.FONT_WEIGHTS,
    ...fontWeights,
  };
};

export const FONT_SIZES = TYPOGRAPHY_CONFIG.FONT_SIZES;
export const FONT_WEIGHTS = TYPOGRAPHY_CONFIG.FONT_WEIGHTS;

export const StyledText = ({
  fontSize = TYPOGRAPHY_CONFIG.FONT_SIZES.Text,
  fontWeight = TYPOGRAPHY_CONFIG.FONT_WEIGHTS.NORMAL,
  lineHeight = 1.2,
}) => css`
  font-size: ${fontSize}rem;
  font-weight: ${fontWeight};
  line-height: ${lineHeight};
  letter-spacing: -0.3px;
`;

export const Display1 = styled.div<Props>`
  ${({
    fontSize = TYPOGRAPHY_CONFIG.FONT_SIZES.Display1,
    fontWeight = TYPOGRAPHY_CONFIG.FONT_WEIGHTS.LIGHT,
    lineHeight,
  }) =>
    StyledText({
      fontSize,
      fontWeight,
      lineHeight,
    })};
`;

export const Display2 = styled.div<Props>`
  ${({
    fontSize = TYPOGRAPHY_CONFIG.FONT_SIZES.Display2,
    fontWeight = TYPOGRAPHY_CONFIG.FONT_WEIGHTS.LIGHT,
    lineHeight,
  }) =>
    StyledText({
      fontSize,
      fontWeight,
      lineHeight,
    })};
`;

export const Display3 = styled.div<Props>`
  ${({
    fontSize = TYPOGRAPHY_CONFIG.FONT_SIZES.Display3,
    fontWeight = TYPOGRAPHY_CONFIG.FONT_WEIGHTS.LIGHT,
    lineHeight,
  }) =>
    StyledText({
      fontSize,
      fontWeight,
      lineHeight,
    })};
`;

export const Heading1 = styled.div<Props>`
  ${({
    fontSize = TYPOGRAPHY_CONFIG.FONT_SIZES.Heading1,
    fontWeight,
    lineHeight,
  }) => StyledText({ fontSize, fontWeight, lineHeight })};
`;

export const Heading2 = styled.div<Props>`
  ${({
    fontSize = TYPOGRAPHY_CONFIG.FONT_SIZES.Heading2,
    fontWeight,
    lineHeight,
  }) => StyledText({ fontSize, fontWeight, lineHeight })};
`;

export const Heading3 = styled.div<Props>`
  ${({
    fontSize = TYPOGRAPHY_CONFIG.FONT_SIZES.Heading3,
    fontWeight,
    lineHeight,
  }) => StyledText({ fontSize, fontWeight, lineHeight })};
`;

export const Heading4 = styled.div<Props>`
  ${({
    fontSize = TYPOGRAPHY_CONFIG.FONT_SIZES.Heading4,
    fontWeight,
    lineHeight,
  }) => StyledText({ fontSize, fontWeight, lineHeight })};
`;

export const Heading5 = styled.div<Props>`
  ${({
    fontSize = TYPOGRAPHY_CONFIG.FONT_SIZES.Heading5,
    fontWeight = TYPOGRAPHY_CONFIG.FONT_WEIGHTS.NORMAL,
    lineHeight,
  }) =>
    StyledText({
      fontSize,
      fontWeight,
      lineHeight,
    })};
`;

export const Heading6 = styled.div<Props>`
  ${({
    fontSize = TYPOGRAPHY_CONFIG.FONT_SIZES.Heading6,
    fontWeight = TYPOGRAPHY_CONFIG.FONT_WEIGHTS.SEMI_BOLD,
    lineHeight,
  }) =>
    StyledText({
      fontSize,
      fontWeight,
      lineHeight,
    })};
`;

// export const Text = styled.div<Props>`
//   ${({
//     fontSize = TYPOGRAPHY_CONFIG.FONT_SIZES.Text,
//     fontWeight = TYPOGRAPHY_CONFIG.FONT_WEIGHTS.NORMAL,
//     lineHeight = 1.75,
//   }) =>
//     StyledText({
//       fontSize,
//       fontWeight,
//       lineHeight,
//     })};
// `;

export const Small = styled.div<Props>`
  ${({
    fontSize = TYPOGRAPHY_CONFIG.FONT_SIZES.Small,
    fontWeight = TYPOGRAPHY_CONFIG.FONT_WEIGHTS.NORMAL,
    lineHeight,
  }) =>
    StyledText({
      fontSize,
      fontWeight,
      lineHeight,
    })};
`;

export const ExtraSmall = styled.div<Props>`
  ${({
    fontSize = TYPOGRAPHY_CONFIG.FONT_SIZES.ExtraSmall,
    fontWeight = TYPOGRAPHY_CONFIG.FONT_WEIGHTS.NORMAL,
    lineHeight,
  }) =>
    StyledText({
      fontSize,
      fontWeight,
      lineHeight,
    })};
`;
