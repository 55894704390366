import { COMPONENT_IDS } from '@ntb-sport/constants';
import { Skeleton, Container } from '../../chakra';
import { Header } from './Header/Header';
import { useBem } from '@ntb-sport/hooks';
import './season-standings.css';
import { Standing } from './Standing/Standing';

interface SeasonStandingsResultsProps {
  standings: any;
  isLoading: boolean;
  competitionId: string;
}

export const SeasonStandingsResults = ({
  standings,
  isLoading,
  competitionId,
}: SeasonStandingsResultsProps) => {
  const bem = useBem(COMPONENT_IDS.SEASON_STANDING);

  if (isLoading) {
    return (
      <Container maxW="4xl" pt={4}>
        {[...Array(8)].map((_element, index) => (
          <Skeleton key={index} height="45px" mb={1} />
        ))}
      </Container>
    );
  }

  return (
    <div className={bem()} data-competition-id={competitionId}>
      <Header standings={standings} />

      <ul className={bem('list')}>
        {!standings?.standings?.length && (
          <span className={bem('no-results')}>
            Sammenlagtresultater ikke tilgjengelig
          </span>
        )}
        {standings?.standings?.map((s: any, index: number) => (
          <Standing key={index} standing={s} />
        ))}
      </ul>
    </div>
  );
};

export default SeasonStandingsResults;
