import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import { CSS_VARIABLES } from '../index';

export const DATE_SELECTOR_STYLES = [
  {
    name: 'Header',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.DATE_SELECTOR}'] [data-element-id='${ELEMENT_IDS.HEADER}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Header',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.DATE_SELECTOR}'] [data-element-id='${ELEMENT_IDS.CONTAINER}']`,
    variables: [CSS_VARIABLES.BACKGROUND_COLOR],
  },
  {
    name: 'Button',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.DATE_SELECTOR}'] [data-element-id='${ELEMENT_IDS.BUTTON}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
      CSS_VARIABLES.FONT_COLOR__HOVER,
      CSS_VARIABLES.BORDER_COLOR__HOVER,
      CSS_VARIABLES.BACKGROUND_COLOR__HOVER,
      CSS_VARIABLES.FONT_COLOR__ACTIVE,
      CSS_VARIABLES.BORDER_COLOR__ACTIVE,
      CSS_VARIABLES.BACKGROUND_COLOR__ACTIVE,
    ],
  },
];
