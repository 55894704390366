import { COMPONENT_IDS } from '@ntb-sport/constants';
import { CSS_VARIABLES } from '../index';

export const MEDAL_TABLE_STYLES = [
  {
    name: 'Root',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.MEDAL_TABLE}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
];
