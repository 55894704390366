import Icon from '../Icon/Icon';

export const ImportantIcon = (props: any) => (
  <Icon {...props}>
    <g>
      <g>
        <g>
          <g>
            <path
              d="M11.3,14.2c-0.5,0-0.9-0.4-0.9-0.9V6.6c0-0.5,0.4-0.9,0.9-0.9c0.5,0.1,0.9,0.5,0.9,0.9v6.7
    C12.2,13.8,11.8,14.2,11.3,14.2z"
            />
          </g>
        </g>
        <g>
          <path
            d="M11.3,20.6c-5.1,0-9.3-4.2-9.3-9.3c0.1-5.1,4.2-9.2,9.3-9.2s9.3,4.2,9.3,9.3C20.6,16.5,16.5,20.6,11.3,20.6z
   M11.3,3.8c-4.2,0-7.6,3.4-7.6,7.6S7.1,19,11.3,19s7.6-3.4,7.6-7.6S15.5,3.8,11.3,3.8z"
          />
        </g>
        <circle cx="11.3" cy="16" r="0.9" />
      </g>
    </g>
  </Icon>
);
