import { Icon } from '../../chakra';

export const SummaryIcon = (props: any) => (
  <Icon {...props}>
    <g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M19.7,6.9c0-0.1-0.1-0.3-0.2-0.4L14,0.9c-0.1-0.1-0.3-0.2-0.6-0.2H6.2C4.4,0.7,3,2.2,3,3.9v14.9
				C3,20.6,4.5,22,6.2,22h10.2c1.8,0,3.2-1.5,3.2-3.2V7.1C19.7,7,19.7,7,19.7,6.9z M14.2,3.4l2.9,2.9h-1.2c-0.9,0-1.7-0.8-1.7-1.7
				C14.2,4.6,14.2,3.4,14.2,3.4z M18.1,18.8c0,0.9-0.8,1.7-1.7,1.7H6.2c-0.9,0-1.7-0.8-1.7-1.7V3.9C4.6,3,5.3,2.2,6.2,2.2h6.4v2.4
				c0,1.8,1.5,3.2,3.2,3.2H18L18.1,18.8L18.1,18.8z"
          />
        </g>
        <g>
          <path
            fill="currentColor"
            d="M13.1,10c0,0.4-0.3,0.8-0.8,0.8H7.4c-0.4,0-0.8-0.3-0.8-0.8S7,9.2,7.5,9.2h4.9C12.8,9.2,13.1,9.5,13.1,10z"
          />
        </g>
        <g>
          <path
            fill="currentColor"
            d="M16.4,13.2c0,0.4-0.3,0.8-0.8,0.8H7.5c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h8.1
				C16,12.4,16.4,12.8,16.4,13.2z"
          />
        </g>
        <g>
          <path
            fill="currentColor"
            d="M16.4,16.4c0,0.4-0.3,0.8-0.8,0.8H7.5c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8h8.1
				C16,15.7,16.4,16,16.4,16.4z"
          />
        </g>
      </g>
    </g>
  </Icon>
);
