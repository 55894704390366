import { Icon } from '../../chakra';

export const LineupIcon = (props: any) => (
  <Icon {...props}>
    <g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M11.3,9.7c-0.7,0-1.3,0.3-1.8,0.7V9.3c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8c0,0.3,0.2,0.4,0.4,0.4
				c0.3,0,0.4-0.2,0.4-0.4c0-1.4-1.2-2.5-2.5-2.5C10,6.8,8.9,8,8.9,9.3v2.9l0,0c0,1.3,1.2,2.5,2.5,2.5c1.4,0,2.5-1.2,2.5-2.5
				C13.9,10.8,12.7,9.7,11.3,9.7z M11.3,14c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8S12.3,14,11.3,14z"
          />
        </g>
      </g>
      <path
        fill="currentColor"
        d="M22.2,6.3l-4.4-3.6c-0.1-0.1-0.2-0.1-0.3-0.1l-3.3-0.8c-0.1,0-0.3,0-0.3,0.1s-0.2,0.2-0.3,0.3
		c-0.1,0.3-0.2,0.4-0.3,0.6c-0.4,0.8-1.3,1.3-2.1,1.3c-0.9,0-1.7-0.5-2.1-1.3C9.1,2.7,9,2.4,8.9,2.3c0-0.2-0.1-0.3-0.3-0.3
		C8.6,1.8,8.4,1.8,8.4,1.8L5.1,2.6C5,2.6,4.9,2.7,4.8,2.7L0.5,6.3C0.2,6.4,0.2,6.7,0.3,6.9l2.2,3.6c0.1,0.2,0.3,0.3,0.4,0.3h1.8v9.6
		c0,0.3,0.3,0.5,0.5,0.5h12.2c0.3,0,0.5-0.3,0.5-0.5v-9.7h1.8c0.2,0,0.3-0.1,0.4-0.3l2.2-3.6C22.4,6.7,22.4,6.4,22.2,6.3z M3.3,9.8
		l-1.8-3l3.4-2.7v5.7H3.3z M17,19.9H5.7V3.4l2.5-0.6C8.3,3,8.3,3.1,8.4,3.3C8.9,4.4,10.1,5,11.3,5c1.3,0,2.3-0.7,2.9-1.8
		c0.1-0.2,0.2-0.3,0.2-0.4l2.5,0.6L17,19.9L17,19.9z M19.4,9.8h-1.5V4.1l3.4,2.7L19.4,9.8z"
      />
    </g>
  </Icon>
);
