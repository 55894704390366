import styled from 'styled-components';

export const ContainerQuery = styled.div`
  container-name: container;
  container-type: inline-size;
  background: var(--background-color);
  position: relative;
`;

export const VideoContainerQuery = styled(ContainerQuery)`
  position: sticky;
  top: 0;
  z-index: 999;
`;

export const PlayerContainer = styled.div`
  position: relative;
  max-width: 440px;
  margin: 0 auto;
`;
