import { LabelProperties } from 'neo-common-enums';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { WarningIcon } from '@chakra-ui/icons';

import { COMPONENT_IDS } from '@ntb-sport/constants';
import { Skeleton } from '../../chakra';
import { useBem } from '@ntb-sport/hooks';
import './event-status.css';

const getEventStatus = ({
  timestamp,
  eventStatusId,
  t,
  bem,
}: {
  timestamp?: string;
  eventStatusId: number;
  t: any;
  bem: any;
}) => {
  const eventTimestamp = timestamp && new Date(timestamp);

  const EVENT_STATUS = {
    [LabelProperties.EventStatus.NOT_STARTED]: eventTimestamp
      ? `${format(eventTimestamp, 'dd.MM.yy')}, ${t('time')} ${format(
          eventTimestamp,
          'HH:mm',
        )} (${t('eventStatus.notStarted')})`
      : `${t('eventStatus.notStarted')}`,
    [LabelProperties.EventStatus.FINISHED]: eventTimestamp
      ? `${format(eventTimestamp, 'dd.MM.yy')}, ${t('time')} ${format(
          eventTimestamp,
          'HH:mm',
        )} (${t('eventStatus.ended')})`
      : `${t('eventStatus.ended')}`,
    [LabelProperties.EventStatus.ONGOING]: (
      <>
        {t('eventStatus.ongoing')}
        <div className={bem('pulse', 'success')} />
      </>
    ),
    [LabelProperties.EventStatus.ABANDONED]: t('eventStatus.abandoned'),
    [LabelProperties.EventStatus.CANCELLED]: t('eventStatus.cancelled'),
    [LabelProperties.EventStatus.POSTPONED]: t('eventStatus.postponed'),
    [LabelProperties.EventStatus.WILL_NOT_BE_HELD]: t(
      'eventStatus.willNotBeHeld',
    ),
    [LabelProperties.EventStatus.DATE_UNCONFIRMED]: t(
      'eventStatus.dateUnconfirmed',
    ),
    [LabelProperties.EventStatus.INTERRUPTED]: (
      <>
        {t('eventStatus.interrupted')}
        <div className={bem('pulse', 'error')} />
      </>
    ),
    [LabelProperties.EventStatus.START_DELAYED]: (
      <>
        {t('eventStatus.startDelayed')}
        <div className={bem('pulse', 'error')} />
      </>
    ),
  };
  return EVENT_STATUS[eventStatusId as keyof typeof EVENT_STATUS] || null;
};

interface EventStatusProps {
  name?: string;
  eventStatusId: number;
  timestamp?: string;
  isLoading?: boolean;
  eventInfo?: string;
  editorialText?: string;
  isCoveredLive?: boolean;
  competitionId: string;
}

export const EventStatus = ({
  name,
  eventStatusId,
  timestamp,
  isLoading,
  eventInfo,
  editorialText,
  isCoveredLive,
  competitionId,
}: EventStatusProps) => {
  const { t } = useTranslation();
  const bem = useBem(COMPONENT_IDS.EVENT_STATUS);

  if (isLoading) {
    return (
      <Skeleton marginLeft="auto" marginRight="auto" height={50} width={200} />
    );
  }

  return (
    <div className={bem()} data-competition-id={competitionId}>
      <div className={bem('container')}>
        {name && <h3 className={bem('name')}>{name}</h3>}

        <div className={bem('status')}>
          {getEventStatus({
            bem,
            timestamp,
            eventStatusId,
            t,
          })}
        </div>
      </div>
      {(eventInfo || editorialText) && (
        <div className={bem('container')}>
          <div className={bem('info')}>
            <WarningIcon />
            {eventInfo && (
              <div
                className={bem('comment')}
                dangerouslySetInnerHTML={{ __html: eventInfo }}
              />
            )}
            {editorialText && (
              <div
                className={bem('comment')}
                dangerouslySetInnerHTML={{ __html: editorialText }}
              />
            )}
          </div>
        </div>
      )}
      {!isCoveredLive &&
        (eventStatusId === LabelProperties.EventStatus.NOT_STARTED ||
          eventStatusId === LabelProperties.EventStatus.ONGOING) && (
          <div className={bem('comment')}>
            Resultater tilgjengelig rett i etterkant av konkurransen.
          </div>
        )}
    </div>
  );
};
