import styled from 'styled-components';

export const Team = styled.div`
  width: 100%;
`;

export const AverageAge = styled.div`
  font-size: var(--ntb-fontSizes-sm);
  text-align: center;
  padding: 5px;
`;

export const LineupNotAvailable = styled.div`
  padding: var(--ntb-space-6);
  font-size: var(--ntb-fontSizes-md);
`;
