import AbbreviationInfo from '../../../sportedit/AbbreviationInfo/AbbreviationInfo';
import { useBem } from '@ntb-sport/hooks';
import './header.css';
import { COMPONENT_IDS } from '@ntb-sport/constants';

interface HeaderProps {
  sportGroup: string | undefined;
  scopeCategoryId?: number;
}

export const Header = ({ sportGroup }: HeaderProps) => {
  const bem = useBem(COMPONENT_IDS.EVENT_PARTICIPANTS);

  return (
    <div className={bem('header')}>
      <h2>Startliste</h2>

      {sportGroup !== 'cycling' && <AbbreviationInfo sportGroup={sportGroup} />}
    </div>
  );
};
