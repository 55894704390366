export const TeamStandingsDescriptionFootball = ({
  hasAveragePoints,
}: {
  hasAveragePoints: boolean;
}) => (
  <>
    <dt>K</dt>
    <dd>Kamper</dd>
    <dt>S</dt>
    <dd>Seier</dd>
    <dt>U</dt>
    <dd>Uavgjort</dd>
    <dt>T</dt>
    <dd>Tap</dd>
    <dt>+</dt>
    <dd>Scorede mål</dd>
    <dt>-</dt>

    <dd>Innslupne mål</dd>
    <dt>+/-</dt>
    <dd>Målforskjell</dd>
    {hasAveragePoints && (
      <>
        <dt>PK</dt>
        <dd>Poengsnitt</dd>
      </>
    )}
    <dt>P</dt>
    <dd>Poeng</dd>
  </>
);

export default TeamStandingsDescriptionFootball;
