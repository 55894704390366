import {
  COMPONENT_IDS,
  DATA_PICKER_GROUPS,
  DATA_PROVIDERS,
  SPORT_GROUP,
} from '@ntb-sport/constants';
import { WidgetConfig, SportsdataConfig } from '@ntb-sport/types';
import {
  DATA_TYPES,
  WIDGET_IDS,
  WIDGET_OPTIONS,
  WIDGET_VERSION,
} from '../common';

export const config: WidgetConfig = {
  id: WIDGET_IDS.MEDAL_TABLE,
  dataType: DATA_TYPES.SPORTEDIT,
  version: WIDGET_VERSION,
  displayName: 'Medaljeoversikt',
  widgetNamespace: 'NtbWidgetMedalOverview',
  iframeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.MEDAL_TABLE}/iframe.html`,
  widgetUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.MEDAL_TABLE}/${WIDGET_IDS.MEDAL_TABLE}.js`,
  themeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/themes/default.css`,
  title: 'Medaljeoversikt',
  summary: 'Medaljeoversikt for gitt konkurranse eller mesterskap',
  sportGroups: [SPORT_GROUP.WINTERSPORTS, SPORT_GROUP.ATHLETICS],
  description: 'general description to be displayed on the documentation page',
  components: [COMPONENT_IDS.MEDAL_TABLE],
  options: {
    [WIDGET_OPTIONS.CUSTOMER_ID]: { isRequired: true },
    [WIDGET_OPTIONS.ERROR_MESSAGE]: { isRequired: false },
    [WIDGET_OPTIONS.ON_ERROR]: { isRequired: false },
    [WIDGET_OPTIONS.SPORT_GROUP]: { isRequired: true },
    [WIDGET_OPTIONS.STYLE_ID]: { isRequired: false },
    [WIDGET_OPTIONS.TARGET_ID]: { isRequired: true },
    [WIDGET_OPTIONS.ID]: { isRequired: true },
  },
  uiOptions: {},
};

export const sportsdataConfig: SportsdataConfig = {
  dataPickerGroups: {
    [DATA_PICKER_GROUPS.NEO_MEDAL_TABLE_GROUP]: {
      title: 'Mesterskap',
      key: DATA_PICKER_GROUPS.NEO_MEDAL_TABLE_GROUP,
      isDefault: true,
      primaryId: DATA_PROVIDERS.NEO_MEDAL_TABLES,
      pickers: {
        [DATA_PROVIDERS.NEO_MEDAL_TABLES]: { isPrimaryId: true },
      },
    },
  },
  optionGroups: {},
};
