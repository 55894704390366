import * as S from './TransferPlayers.styled';
import {
  CountrySelect,
  displayTransferType,
  formatTransferDate,
  handleTransferType,
  LinkWrapper,
  playerNationality,
  TO_FROM_TEAM,
  ToFromTeamType,
  TRANSFER_TYPES,
  transferPlayerImg,
} from '../utils/TransferHelpers';

import { TransferForwardIcon } from '../../../icons/TransferIcons/TransferForwardIcon';
import { UnknownTeamLogoIcon } from '../../../icons/TransferIcons/UnknownTeamLogoIcon';
import { PlayerTransferInterface } from '../TransferMenu/libs/TransferMenuContext';

interface TransferPlayerListItemTypes {
  transfer: PlayerTransferInterface;
  playersUrl?: string | null;
  gender?: string;
  linkToTeamUrl?: string;
  transferCountry?: string | null;
  LinkComponent: React.ComponentType<any> | null | undefined;
}

export const TransferPlayerListItem = ({
  transfer,
  gender,
  playersUrl,
  linkToTeamUrl,
  transferCountry,
  LinkComponent,
}: TransferPlayerListItemTypes) => {
  const checkLogo = (
    transfer: PlayerTransferInterface,
    toFrom: ToFromTeamType,
  ) => {
    return (transfer?.[toFrom]?.logo?.url &&
      transfer?.[toFrom]?.logo?.url !== '') ||
      undefined ? (
      <S.TeamLogo
        aria-hidden="true"
        as={'img'}
        src={transfer?.[toFrom].logo.url}
      />
    ) : (
      <S.TeamLogo>
        <UnknownTeamLogoIcon />
      </S.TeamLogo>
    );
  };

  return (
    <S.ListItem aria-labelledby="player-name">
      <S.TopBar>
        <S.PlayerDetails>
          {playersUrl && (
            <LinkWrapper LinkComponent={LinkComponent} href={playersUrl}>
              <S.PlayerImage
                as={'img'}
                alt={`Bilde av ${transfer?.person?.name}`}
                src={transferPlayerImg(transfer, gender)}
              />
            </LinkWrapper>
          )}
          <S.PlayerBio>
            {playersUrl ? (
              <LinkWrapper LinkComponent={LinkComponent} href={playersUrl}>
                <S.PlayerName id="player-name">
                  {transfer?.person?.name}
                </S.PlayerName>
              </LinkWrapper>
            ) : (
              <S.PlayerName>{transfer?.person?.name}</S.PlayerName>
            )}
            {transfer?.person?.country && (
              <S.PlayerNationality
                aria-labelledby={`Nasjonalitet-${transfer.person.country.name}`}
              >
                <S.Flag countryName={playerNationality(transfer)} />
                <p>{transfer.person.country.name}</p>
              </S.PlayerNationality>
            )}
          </S.PlayerBio>
        </S.PlayerDetails>
        <S.TransferInfo>
          <p>{transfer?.date && formatTransferDate(transfer?.date)}</p>
          {displayTransferType(transfer)}
        </S.TransferInfo>
      </S.TopBar>

      <S.TransferClubInfo>
        {transfer?.fromTeam?.id ? (
          <LinkWrapper
            LinkComponent={LinkComponent}
            href={`${linkToTeamUrl}${transfer.fromTeam.id}/kamper`}
          >
            <div>
              {checkLogo(transfer, TO_FROM_TEAM.FROM)}
              <p>
                {transfer?.fromTeam?.shortName
                  ? transfer.fromTeam.shortName
                  : transfer?.fromTeam?.name}
              </p>
              {CountrySelect(transfer, TO_FROM_TEAM.FROM, transferCountry) && (
                <S.TeamNationalFlag
                  countryName={transfer?.fromTeam?.country?.name}
                />
              )}
            </div>
          </LinkWrapper>
        ) : (
          <S.TeamLogo>
            <UnknownTeamLogoIcon />
            Ukjent
          </S.TeamLogo>
        )}

        <span aria-label="Til">
          <TransferForwardIcon w="6" h="6" />
        </span>
        {handleTransferType(transfer.type) ===
        TRANSFER_TYPES.TransferHungUpBoots ? (
          <div></div>
        ) : transfer?.toTeam?.id ? (
          <LinkWrapper
            LinkComponent={LinkComponent}
            href={`${linkToTeamUrl}${transfer?.toTeam.id}/kamper`}
          >
            <div>
              {checkLogo(transfer, TO_FROM_TEAM.TO)}
              <p>
                {transfer?.toTeam?.shortName
                  ? transfer?.toTeam?.shortName
                  : transfer?.toTeam?.name}
              </p>
              {CountrySelect(transfer, TO_FROM_TEAM.TO, transferCountry) && (
                <S.TeamNationalFlag
                  countryName={transfer?.toTeam?.country?.name}
                />
              )}
            </div>
          </LinkWrapper>
        ) : (
          <S.TeamLogo>
            <UnknownTeamLogoIcon />
            Ukjent
          </S.TeamLogo>
        )}
      </S.TransferClubInfo>
    </S.ListItem>
  );
};

export default TransferPlayerListItem;
