import {
  Placement,
  Club,
  ClubLogo,
  ClubName,
  ClubLogoWrapper,
} from '../Table.styled';

import React from 'react';
import Table from '../Table';
import { MatchesType, NifsFairPlayTableClub } from '@ntb-sport/types';

interface FairPlayStandingsFootballProps {
  data: NifsFairPlayTableClub[];
  isLoading: boolean;
  matches: MatchesType;
}

export const FairPlayStandingsFootball: React.FC<
  FairPlayStandingsFootballProps
> = ({ data, isLoading, matches }) => {
  const tableData = React.useMemo(() => data, [data]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'rank',
        isSortable: matches?.tablet,
        isVisible: true,
        styles: {
          textAlign: 'center',
          collapse: 'all',
        },
        Cell: (props: any) => {
          return (
            <Placement $matches={matches} rowTypeId={0}>
              {props.row.original.rank}
            </Placement>
          );
        },
      },
      {
        Header: 'Klubb',
        accessor: (original: any) => original.club.name,
        isSortable: true,
        isVisible: true,
        Cell: (props: any) => (
          <Club $matches={matches}>
            {props.row.original.teams?.[0] && (
              <ClubLogoWrapper $matches={matches}>
                <ClubLogo
                  src={`https://api.nifs.no/images/teams/logo/${props.row.original.teams[0].id}/`}
                  alt=""
                  loading="lazy"
                  $matches={matches}
                />
              </ClubLogoWrapper>
            )}
            <ClubName>
              {props.row.original.club && !matches?.tablet
                ? props.row.original.club.displayName ||
                  props.row.original.club.name
                : props.row.original.club.name}
            </ClubName>
          </Club>
        ),
        styles: {
          justifyContent: 'left',
        },
      },
      {
        Header: 'K',
        accessor: 'matches',
        isVisible: true,
        isSortable: matches?.tablet,
        styles: {
          textAlign: 'center',
          divider: true,
        },
      },
      {
        Header: 'L',
        accessor: (original: any) => original.teams?.length,
        isSortable: matches?.tablet,
        isVisible: true,
        styles: {
          textAlign: 'center',
          divider: true,
        },
      },
      {
        Header: 'GK',
        accessor: 'yellowCards',
        isSortable: matches?.tablet,
        isVisible: true,
        styles: {
          textAlign: 'center',
        },
      },
      {
        Header: 'RK',
        accessor: 'redCards',
        isVisible: true,
        isSortable: matches?.tablet,
        styles: {
          textAlign: 'center',
          divider: true,
        },
      },
      {
        Header: 'P',
        accessor: 'points',
        isSortable: matches?.tablet,
        isVisible: true,
        styles: {
          textAlign: 'center',
        },
      },
      {
        Header: 'PS',
        accessor: (original: any) => Number(original.pointsPerMatch).toFixed(2),
        isSortable: matches?.tablet,
        isVisible: true,
        styles: {
          textAlign: 'center',
          divider: !matches?.tablet,
        },
      },
    ];
  }, [matches]);

  return (
    <Table
      columns={columns}
      data={tableData}
      isLoading={isLoading}
      matches={matches}
    />
  );
};

export default FairPlayStandingsFootball;
