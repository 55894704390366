import { useQuery } from '@tanstack/react-query';

import { fetchCustomerSettings, FetchCustomerSettings } from './fetch';
import { QUERY_KEYS } from '@ntb-sport/constants';

interface UseQueryNifsCustomer {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: FetchCustomerSettings;
  options?: Record<string, unknown>;
}

export const useQueryNifsCustomer = ({
  queryKey = QUERY_KEYS.CUSTOMER_SETTINGS,
  queryKeyParams,
  params,
  options,
}: UseQueryNifsCustomer) => {
  const { data, isLoading, error } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchCustomerSettings(params),
    enabled: Boolean(params.sportGroup),
    ...options,
  });

  return { data, isLoading, error };
};
