import {
  SPORT_GROUP,
  SPORT_GROUP_TO_NIFS_SPORT_ID,
} from '@ntb-sport/constants';
import { NifsStageType } from '@ntb-sport/types';

import { Fetch, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchNifsTeams {
  apiBaseUrl: string;
  sportGroup: string;
  query: string;
  customerId: number;
}

export const fetchNifsTeams = async ({
  apiBaseUrl,
  sportGroup,
  query,
  customerId,
}: FetchNifsTeams): Promise<NifsStageType[]> => {
  const url =
    sportGroup === SPORT_GROUP.FOOTBALL
      ? `${apiBaseUrl}/teams/?search=${query}&customerId=${customerId}`
      : `${apiBaseUrl}/nifTeams/?search=${query}&customerId=${customerId}&sportId=${
          SPORT_GROUP_TO_NIFS_SPORT_ID[
            sportGroup as keyof typeof SPORT_GROUP_TO_NIFS_SPORT_ID
          ]
        }`;

  const json = await Fetch({
    url,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });

  return json;
};
