import styled from 'styled-components';

import { linearGradient } from 'polished';

import { MEDIA_QUERY } from '@ntb-sport/constants';

interface StyledProps {
  $side?: string;
  $backgroundColor?: string;
  $textColor?: string;
}

export const Team = styled.div<StyledProps>`
  justify-self: stretch;
  align-self: flex-start;
  grid-area: ${({ $side }) =>
    $side === 'hometeam' ? 'homeevents' : 'awayevents'};
  display: flex;
  padding: var(--ntb-space-1) var(--ntb-space-2);
  justify-content: ${({ $side }) =>
    $side === 'hometeam' ? 'flex-start' : 'flex-end'};

  ${(props) =>
    linearGradient({
      colorStops: [
        `transparent 0%`,
        `${props.$backgroundColor} 50%`,
        `${props.$backgroundColor} 100%`,
      ],
      toDirection: props.$side === 'hometeam' ? 'to left' : 'to right',
      fallback: 'transparent',
    })};

  color: ${(props) => props.$textColor};

  @container team-match-result-container (min-width: 740px) {
    ${(props) =>
      linearGradient({
        colorStops: [
          `transparent 0%`,
          `${props.$backgroundColor} 20%`,
          `${props.$backgroundColor} 80%`,
          `transparent 100%`,
        ],
        toDirection: props.$side === 'hometeam' ? 'to left' : 'to right',
        fallback: 'transparent',
      })};

    justify-content: center;
  }
`;

export const Events = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export const StyledEvent = styled.li`
  display: flex;
  align-items: center;
  row-gap: var(--ntb-space-2);
  column-gap: var(--ntb-space-1);
`;

export const EventType = styled.div`
  display: flex;
  align-items: center;
  justify-items: flex-end;
  min-width: 25px;
`;

export const Score = styled.span`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-semibold);

  @container team-match-result-container (min-width: 740px) {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const EventPerson = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  button {
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--ntb-fontSizes-xs);
  }

  @container team-match-result-container (min-width: 740px) {
    flex-direction: row;
    align-items: center;

    button {
      font-size: var(--ntb-fontSizes-sm);
    }
  }
`;

export const PersonName = styled.div`
  font-size: var(--ntb-fontSizes-xs);

  @container team-match-result-container (min-width: 740px) {
    font-size: var(--ntb-fontSizes-sm);
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const EventDescription = styled.div`
  font-size: var(--ntb-fontSizes-xs);
  white-space: nowrap;

  @container team-match-result-container (min-width: 740px) {
    font-size: var(--ntb-fontSizes-sm);
    margin-left: var(--ntb-space-1);
  }
`;

export const EventTime = styled.div`
  font-weight: var(--ntb-fontWeights-semibold);
  font-size: var(--ntb-fontSizes-xs);

  @container team-match-result-container (min-width: 740px) {
    font-size: var(--ntb-fontSizes-sm);
  }
`;
