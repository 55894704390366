import { useEffect, useState } from 'react';

import { MATCH_STATUS } from '@ntb-sport/constants';
import { differenceInMilliseconds } from 'date-fns';
import styled from 'styled-components';

interface MatchTimerProps {
  showSeconds?: any;
  startTime: any;
  timestamp: any;
  matchStatusId: number;
  isMainMatch?: any;
}

interface StyledProps {
  $isMainMatch?: any;
  $isOvertime?: any;
}

const StyledMatchTimer = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${(props) => (props.$isMainMatch ? '50px' : 'auto')};
  height: ${(props) => (props.$isMainMatch ? '50px' : 'auto')};
  border-style: ${(props) => (props.$isMainMatch ? 'solid' : 'none')};
  border-width: ${(props) => (props.$isMainMatch ? '2px' : '0')};
  border-color: ${(props) =>
    props.$isMainMatch
      ? props.$isOvertime
        ? '#e51c30'
        : '#23c36b'
      : 'transparent'};
  border-radius: 50%;
  color: ${(props) =>
    props.$isMainMatch ? '#fff' : 'var(--base-blackAlpha-700)'};
  margin-top: ${(props) => (props.$isMainMatch ? '-20px' : '0')};
  font-size: ${(props) =>
    props.$isMainMatch ? 'var(--ntb-fontSizes-sm)' : 'var(--ntb-fontSizes-xs)'};
`;

const convertMS = (milliseconds: number) => {
  let hours, minutes, seconds;
  seconds = Math.floor(milliseconds / 1000);
  minutes = Math.floor(seconds / 60);
  hours = Math.floor(minutes / 60);
  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;
  return {
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

const addMinutes = (matchStatusId: number) => {
  if (Number(matchStatusId) === MATCH_STATUS.FIRST_HALF) return 0;
  else if (Number(matchStatusId) === MATCH_STATUS.SECOND_HALF) return 45;
  else if (Number(matchStatusId) === MATCH_STATUS.FIRST_HALF_EXTRA_TIME)
    return 90;
  else if (Number(matchStatusId) === MATCH_STATUS.SECOND_HALF_EXTRA_TIME)
    return 105;
  else return 0;
};

const getIsOvertime = (matchStatusId: number, minutes: number) => {
  if (Number(matchStatusId) === MATCH_STATUS.FIRST_HALF && minutes >= 45)
    return true;
  else if (Number(matchStatusId) === MATCH_STATUS.SECOND_HALF && minutes >= 90)
    return true;
  else if (
    Number(matchStatusId) === MATCH_STATUS.FIRST_HALF_EXTRA_TIME &&
    minutes >= 105
  )
    return true;
  else if (
    Number(matchStatusId) === MATCH_STATUS.SECOND_HALF_EXTRA_TIME &&
    minutes >= 120
  )
    return true;
  else return false;
};

export const MatchTimer = ({
  showSeconds,
  startTime,
  timestamp,
  matchStatusId,
  isMainMatch,
}: MatchTimerProps) => {
  const [diffMS, setDiffMS] = useState(
    differenceInMilliseconds(new Date(), new Date(startTime || timestamp)),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setDiffMS(
        differenceInMilliseconds(new Date(), new Date(startTime || timestamp)),
      );
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [matchStatusId, startTime, timestamp]);

  const time = convertMS(diffMS);
  const minutes = time.hours * 60 + time.minutes + addMinutes(matchStatusId);
  const seconds = time.seconds;
  const isOverTime = getIsOvertime(matchStatusId, minutes);

  return (
    <StyledMatchTimer $isOvertime={isOverTime} $isMainMatch={isMainMatch}>
      {showSeconds
        ? `${minutes}:${('0' + seconds).slice(-2)}`
        : `${minutes + 1}'`}
    </StyledMatchTimer>
  );
};

export default MatchTimer;
