import { COMPONENT_IDS } from '@ntb-sport/constants';
import { useBem } from '@ntb-sport/hooks';
import classNames from 'classnames';
import './menu-item.css';

interface MenuItemProps {
  item: any;
  itemId: string;
  onClick: any;
  selectedScopeId: number;
}

const CLIMB_CATEGORY_NUMBER = {
  'climb-category-hors': 'HC',
  'climb-category-first': 1,
  'climb-category-second': 2,
  'climb-category-third': 3,
  'climb-category-fourth': 4,
};

export const MenuItem = ({ item, selectedScopeId, onClick }: MenuItemProps) => {
  const bem = useBem(COMPONENT_IDS.SCOPE_MENU);

  const onClickMenuItem = (item: any) => {
    onClick({
      id: item?.uuid,
      number: item?.number,
      scopeTypeId: item?.scopeTypeId,
      scopeCategoryId: item?.scopeCategoryId,
    });
  };

  return (
    <button
      className={classNames(bem('menu-item'), {
        [bem('menu-item', 'active')]: selectedScopeId === item?.uuid,
      })}
      onClick={() => onClickMenuItem(item)}
    >
      <span className={bem('menu-item-name')}>{item.name}</span>

      {item.scopeCategoryId && (
        <div
          className={classNames(bem('category'), {
            [bem('category', 'sprint')]: item.scopeCategoryId === 1,
            [bem('category', 'climb')]: item.scopeCategoryId === 2,
          })}
        >
          {item.scopeCategoryId === 1
            ? 'S'
            : item.scopeCategoryId === 2
              ? CLIMB_CATEGORY_NUMBER[
                  item.climbCategoryId as keyof typeof CLIMB_CATEGORY_NUMBER
                ]
              : ''}
        </div>
      )}
    </button>
  );
};
