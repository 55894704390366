import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import { CountryFlag } from '../../common/CountryFlag/CountryFlag';

import * as S from './CompetitionHero.styled';

import { GiTrophy } from 'react-icons/gi';

interface CompetitionHeroProps {
  competition: any;
}

export const CompetitionHero = ({ competition }: CompetitionHeroProps) => {
  return (
    <S.Container
      data-component-id={COMPONENT_IDS.COMPETITION_HERO}
      data-tournament-id={competition?.id}
    >
      <S.LogoContainer data-element-id={ELEMENT_IDS.LOGO}>
        {competition?.logo?.url ? (
          <S.Logo src={competition?.logo?.url} alt="" />
        ) : (
          <GiTrophy size={120} fill="var(--font-color)" />
        )}
      </S.LogoContainer>

      <S.Info>
        <S.Name as="h1">{competition?.name}</S.Name>
        <S.Country>
          <CountryFlag countryName={competition?.country?.name} />
          <S.CountryName>{competition?.country?.name}</S.CountryName>
        </S.Country>
      </S.Info>
    </S.Container>
  );
};

export default CompetitionHero;
