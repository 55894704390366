import { format, differenceInYears, isValid } from 'date-fns';
import { CountryFlag } from '../../common/CountryFlag/CountryFlag';
import * as S from './AthleteBio.styled';

export const positions = {
  1: 'Keeper',
  2: 'Forsvar',
  3: 'Høyreback',
  4: 'Venstreback',
  5: 'Midtforsvar',
  6: 'Midtbane',
  7: 'Høyre midtbane',
  8: 'Venstre midtbane',
  9: 'Sentral midtbane',
  10: 'Spiss',
  11: 'Ukjent',
  12: 'Ikke spiller',
  13: 'Angrep',
  14: 'Back',
  15: 'Ving',
  16: 'Sweeper',
  17: 'Høyre defensiv midtbane',
  18: 'Defensiv midtbane',
  19: 'Venstre defensiv midtbane',
  20: 'Høyreving',
  21: 'Venstreving',
  22: 'Høyre offensiv midtbane',
  23: 'Offensiv midtbane',
  24: 'Venstre offensiv midtbane	',
  25: 'Høyre indreløper',
  26: 'Venstre indreløper',
  27: 'Høyre midtforsvar',
  28: 'Venstre midtforsvar',
  29: 'Høyre deffnsiv indreløper',
  30: 'Venstre defensiv indreløper',
  31: 'Høyre offensiv indreløper',
  32: 'Venstre offensiv indreløper	',
  33: 'Høyre spiss',
  34: 'Venstre spiss',
  35: 'Høyre sentral midtbane',
  36: 'Venstre sentral midtbane',
};

export const AthleteBio = ({ athlete }: { athlete: any }) => {
  const fullName =
    athlete?.names?.find(
      (name: { useId: number; name: string }) => name?.useId === 4,
    )?.name ||
    (athlete?.lastName &&
      athlete?.firstName &&
      `${athlete?.firstName} ${athlete?.lastName}`);

  const hasValidDate = isValid(new Date(athlete?.birthDate));

  return (
    <>
      <S.Facts>
        {fullName && (
          <div>
            <S.FactLabel>Fullt navn:</S.FactLabel>
            <S.FactContent>{fullName}</S.FactContent>
          </div>
        )}
        {athlete?.country && (
          <div>
            <S.FactLabel>Nasjonalitet:</S.FactLabel>
            <S.FactContent>
              {athlete?.country?.name}
              <S.CountryFlagContainer>
                <CountryFlag countryName={athlete?.country?.name} />
              </S.CountryFlagContainer>
            </S.FactContent>
          </div>
        )}

        {athlete?.country2 && (
          <div>
            <S.FactLabel>Annet statsborgerskap:</S.FactLabel>
            <S.FactContent>
              {athlete?.country2?.name}
              <S.CountryFlagContainer>
                <CountryFlag countryName={athlete?.country2?.name} />
              </S.CountryFlagContainer>
            </S.FactContent>
          </div>
        )}

        <div>
          <S.FactLabel>Posisjon:</S.FactLabel>
          <S.FactContent>
            {' '}
            {positions[athlete?.position?.id as keyof typeof positions]}
          </S.FactContent>
        </div>

        {hasValidDate && athlete?.birthDate && (
          <div>
            <S.FactLabel>Født:</S.FactLabel>
            <S.FactContent>
              {format(new Date(athlete?.birthDate), 'dd.MM.yyyy')} (
              {differenceInYears(new Date(), new Date(athlete?.birthDate))} år)
            </S.FactContent>
          </div>
        )}

        {athlete?.birthPlace && (
          <div>
            <S.FactLabel>Fødested:</S.FactLabel>
            <S.FactContent>
              {`${athlete?.birthPlace?.name}, ${athlete?.birthPlace?.country?.name}`}
              <S.CountryFlagContainer>
                <CountryFlag countryName={athlete?.birthPlace?.country?.name} />
              </S.CountryFlagContainer>
            </S.FactContent>
          </div>
        )}

        {athlete?.height && (
          <div>
            <S.FactLabel>Høyde:</S.FactLabel>
            <S.FactContent>{athlete?.height} cm</S.FactContent>
          </div>
        )}
      </S.Facts>
    </>
  );
};

export default AthleteBio;
