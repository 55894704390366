import { CloseIcon } from '../../icons';
import styled, { css } from 'styled-components';
import { BiMove } from 'react-icons/bi';
import { MEDIA_QUERY } from '@ntb-sport/constants';

interface Styledprops {
  $isRadio?: boolean;
  $offsetHeight?: number;
  $offsetWidth?: number;
}

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
`;

export const Controller = styled.div`
  /* container-type: inline-size;
  container-name: controller; */
  position: fixed;
  z-index: 99999;
  bottom: 66px;
  right: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);

  background: var(--base-colors-brand-dark-800-background-color);
  color: #fff;
  padding: var(--ntb-space-1) var(--ntb-space-2);
  font-size: var(--ntb-fontSizes-md);
  font-weight: var(--ntb-fontWeights-semibold);

  /* @container controller ${MEDIA_QUERY.TABLET} {
    bottom: var(--ntb-space-6);
    right: var(--ntb-space-6);
  } */

  @media ${MEDIA_QUERY.TABLET} {
    bottom: var(--ntb-space-6);
    right: var(--ntb-space-6);
  }
`;

export const MediaPlayerContainer = styled.div<Styledprops>`
  position: fixed;
  z-index: 99999;
  top: ${(props) =>
    `calc(100vh - ${props?.$offsetHeight}px - var(--ntb-space-4))`};
  left: ${(props) =>
    `calc(100vw - ${props?.$offsetWidth}px - var(--ntb-space-4))`};
  width: 70vw;
  min-height: auto;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props?.$isRadio &&
    css`
      position: fixed !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
      clip: rect(1px, 1px, 1px, 1px);
      white-space: nowrap; /* added line */
    `}

  @media ${MEDIA_QUERY.TABLET} {
    top: ${(props) =>
      `calc(100vh - ${props?.$offsetHeight}px - var(--ntb-space-6))`};
    left: ${(props) =>
      `calc(100vw - ${props?.$offsetWidth}px - var(--ntb-space-6))`};
    width: 460px;
    min-height: 200px;
  }
`;

export const Controllers = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const Player = styled.div`
  background: #000;
  width: 100%;
  min-height: 200px;
`;

export const MoveMediaPlayer = styled.button`
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--base-colors-brand-dark-800-background-color);
  border: none;
  cursor: pointer;
  padding: 6px;
  border-radius: 3px 0 0 0;
  font-size: var(--ntb-fontSizes-md);
  color: #fff;
`;

export const CloseMediaPlayer = styled.button`
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--base-colors-brand-dark-800-background-color);
  border: none;
  cursor: pointer;
  padding: 6px;
  border-radius: 0 3px 0 0;
  font-size: var(--ntb-fontSizes-md);
  color: #fff;
`;

export const MoveMediaPlayerIcon = styled(BiMove)`
  fill: #fff;
`;

export const CloseMediaPlayerIcon = styled(CloseIcon)`
  fill: #fff;
`;
