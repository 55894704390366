import { Fetch } from '../../fetch';

export interface FetchAllShotSeriesType {
  apiBaseUrl: string;
  eventUUID: string;
  token: string;
  customerUUID: string;
}

export const fetchAllShotSeries = async ({
  apiBaseUrl,
  token,
  customerUUID,
  eventUUID,
}: FetchAllShotSeriesType) => {
  const json = await Fetch({
    url: `${apiBaseUrl}/events/${eventUUID}/allShotSeries`,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });

  return json?.data;
};
