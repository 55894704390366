import { Icon } from '../../chakra';

export const CompetitionIcon = (props: any) => (
  <Icon {...props}>
    <g>
      <g>
        <path
          fill="currentColor"
          d="M22.3,2.4c-0.1-0.4-0.5-0.7-0.9-0.7h-2.5c0-0.3,0-0.4,0-0.4c0-0.2-0.1-0.5-0.2-0.7c-0.2-0.2-0.4-0.3-0.6-0.3
			H4.6c-0.2,0-0.5,0.1-0.6,0.3C3.8,0.9,3.7,1.1,3.7,1.4c0,0,0,0.2,0,0.4H1.3C0.9,1.8,0.5,2,0.4,2.4c0,0.1-0.4,1.6,0.2,3.8
			C1.2,8,2.5,10.7,6.4,13c0.2,0.4,0.4,0.8,0.7,1.2c1,1.4,2.1,2.3,3.4,2.6c-0.1,0.5-0.4,1-0.7,1.3c-1.3,1.2-3.6,1.2-3.6,1.2
			c-0.3,0-0.7,0.2-0.8,0.5L4.7,21c-0.1,0.3-0.1,0.6,0,0.9c0.2,0.3,0.4,0.4,0.8,0.4h11.9c0.3,0,0.6-0.2,0.8-0.4
			c0.2-0.2,0.2-0.6,0-0.9l-0.7-1.3c-0.2-0.3-0.5-0.5-0.8-0.5c0,0-2.3,0.1-3.6-1.2c-0.4-0.4-0.6-0.8-0.7-1.3c1.3-0.3,2.4-1.2,3.4-2.6
			c0.2-0.4,0.5-0.8,0.7-1.2c3.8-2.3,5.2-5,5.8-6.8C22.7,4.1,22.3,2.5,22.3,2.4z M2.3,5.8C2,4.8,1.9,4,2,3.5H4c0.2,1.4,0.5,3.4,1,5.4
			C5,9.4,5.1,9.7,5.3,10.1C3.8,8.9,2.8,7.4,2.3,5.8z M14,20.5H8.6c0.8-0.2,1.5-0.6,2.2-1.3c0.2-0.2,0.3-0.3,0.5-0.5
			c0.1,0.2,0.3,0.4,0.5,0.5C12.4,20,13.2,20.3,14,20.5z M16.1,8.5c-0.4,1.4-0.8,2.6-1.3,3.6c0,0.1-0.1,0.1-0.1,0.2
			c-1.2,2.4-2.5,3-3.5,3c-1.1,0-2.3-0.6-3.5-3c0-0.1-0.1-0.1-0.1-0.2c-0.4-0.9-0.9-2-1.3-3.6c-0.6-2.5-0.9-5-1-6.4h11.7
			C17.1,3.5,16.8,6,16.1,8.5z M20.3,5.8c-0.5,1.6-1.4,3.1-2.9,4.3c0.1-0.4,0.3-0.9,0.4-1.3c0.5-2,0.8-4,1-5.4h1.9
			C20.7,4.1,20.6,4.8,20.3,5.8z"
        />
      </g>
    </g>
  </Icon>
);
