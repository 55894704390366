import Kit from './Kit';
import ShirtNumber from './ShirtNumber';

const TwoColorVerticalStripesFullLength = ({
  kitColor1 = '#f79025',
  kitColor2 = '#adadad',
  shirtNumber,
  shirtNumberColor,
  shirtNumberBackgroundColor,
  shirtNumberBackgroundOpacity,
  ...restProps
}: {
  size?: any;
  kitColor1: any;
  kitColor2: any;
  kitColor3: any;
  shirtNumber: any;
  shirtNumberColor: any;
  shirtNumberBackgroundColor: any;
  shirtNumberBackgroundOpacity: any;
}) => {
  const shadow = '#000000';

  return (
    <Kit {...restProps}>
      <g>
        <g>
          <path
            style={{ fill: kitColor2 }}
            d="M69.15,2.82c2.77,0,7.29-0.36,8.68-0.53c1.38-0.17,3.61,0.2,4.68,0.53l2.08,3.19
              c2.26,0.53,11.11,2.66,12.85,3.37c1.74,0.71,4,1.77,5.38,1.94c1.39,0.19,3.47,0.72,4.86,1.24c1.38,0.54,4.69,3.02,5.9,4.8
              c1.21,1.77,8.24,16.3,9.8,18.62c1.57,2.3,12.42,22.34,12.94,23.23c0.52,0.88,1.03,3.19,0.17,3.36c-0.87,0.19-8.33,3.37-11.46,6.03
              c-3.13,2.67-9.03,5.68-9.71,6.03c-0.71,0.35-1.14,0.14-1.83-0.58c-0.69-0.7-7.73-12.01-7.73-12.01c-1.21,2.66-1.21,5.67-1.04,7.62
              c0.18,1.96-0.87,11.8-0.35,14.63c0.52,2.84,0.52,30.33,0.87,32.82c0.35,2.48,0.86,7.44,0.69,9.93c-0.18,2.48,0,5.67,0,7.09
              c0,1.43-0.18,2.67-1.56,3.2c-1.39,0.53-13.89,3.9-35.24,3.19c-21.35,0.71-33.85-2.66-35.23-3.19c-1.38-0.53-1.56-1.77-1.56-3.2
              c0-1.41,0.18-4.61,0-7.09c-0.18-2.49,0.35-7.45,0.69-9.93c0.35-2.49,0.35-29.98,0.87-32.82c0.51-2.83-0.52-12.68-0.35-14.63
              c0.18-1.95,0.18-4.96-1.04-7.62c0,0-7.3,11.53-7.99,12.24c-0.69,0.71-0.87,0.71-1.56,0.35c-0.7-0.35-6.59-3.36-9.73-6.03
              c-3.12-2.66-10.59-5.84-11.45-6.03c-0.87-0.17-0.35-2.48,0.17-3.36C2.5,58.33,13.43,38.47,15,36.16c1.56-2.32,8.5-17.04,9.72-18.8
              c1.22-1.78,4.51-4.26,5.91-4.8c1.39-0.53,3.46-1.05,4.86-1.24c1.39-0.17,3.63-1.28,5.38-1.94c1.97-0.74,10.83-2.9,12.84-3.37
              l2.08-3.19c0.87-0.35,3.3-0.71,4.69-0.53C61.86,2.46,66.38,2.82,69.15,2.82z"
          />
          <path
            style={{ fill: kitColor1 }}
            d="M84.71,5.67l-1.66-3.43l-4.23-0.72L67.68,2.24l-8.06-0.72L55.4,2.48l-1.86,3.19
              c-0.42,1.03-0.32,2.6-0.31,3.7c0.04,2.81,0.67,5.89,2.42,8.16c0.19,0.24,0.4,0.47,0.62,0.68c0,0,4.07-3.83,4.08-3.83
              c0.03,0.01,0.05,0.04,0.11,0.05v126.16c2.71,0.05,5.6,0.04,8.69-0.05c2.81,0.09,5.46,0.11,7.97,0.08V14.74l1.11-0.55l2.75,3.84
              c2.09-1.77,3.08-4.42,3.56-7.03c0.17-0.99,0.29-1.98,0.28-2.97C84.82,7.37,84.97,6.29,84.71,5.67z M69.15,30.2h-0.16
              c0-10.18-6.94-14.71-7.82-15.23c3.55,1.7,7.11,3.6,7.9,3.83c0.78-0.23,4.33-2.12,7.86-3.82C75.97,15.58,69.15,20.12,69.15,30.2z
              M78.7,11.01c-4.03,1.86-9.64,6.15-9.64,6.15s-5.6-4.29-9.63-6.15c-2.45-1.14-3.8-3.35-4.15-5.26c2.41-0.7,4.63-0.6,7.08-0.4
              c2.22,0.19,4.46,0.33,6.7,0.33c3.01,0,5.97-0.37,8.96-0.55c1.37-0.08,3.76,0.25,4.85,0.55C82.53,7.61,81.18,9.86,78.7,11.01z"
          />
          <path
            style={{ fill: kitColor1 }}
            d="M43.37,15.34v123.96c-3.7-0.54-6.37-1.13-7.94-1.53c0.01-2.13,0-4.27-0.17-6.48
              c-0.27-3.48,0.24-10.45,0.59-13.94c0.35-3.5,0.48-31.73,0.7-35.77c0.2-3.79-0.51-10.73-0.62-13.98c-0.12-3.11-3.08-5.33-3.35-5.52
              c0.03,0.07,0.21,0.43,0.42,1.21c-0.12-0.43-0.27-0.84-0.46-1.25c0,0,0.04-14.24,0.08-20.4c0.04-5.64,0.64-17.8,4.26-21.17
              C38.97,18.53,41.02,16.8,43.37,15.34z"
          />
          <path
            style={{ fill: kitColor1 }}
            d="M105.77,62.04c0,0,0,0.01-0.01,0.04c0,0.03-0.01,0.05-0.04,0.08c-0.42,0.72-3.24,2.56-3.62,5.72
              c-0.31,4.26-0.92,9.99-0.67,12.91c0.35,4.03,0.55,33.36,0.91,36.86c0.35,3.5,0.84,10.46,0.58,13.94c-0.17,2.18-0.25,4.15-0.25,6.24
              c-1.7,0.43-4.54,1.03-8.45,1.57V14.79c2.58,1.55,4.81,3.4,7.06,5.49c3.63,3.37,4.23,15.55,4.26,21.17
              C105.57,47.62,105.77,62.04,105.77,62.04z"
          />
          <path
            style={{ fill: kitColor1 }}
            d="M13.23,68.99l9.03,6.09l2.13,0.28l2.15-3.41c-0.17,0.08-1.29,0.42-1.89,0.11
              c-0.68-0.36-6.58-3.37-9.71-6.04c-3.12-2.65-10.59-5.84-11.46-6.03c-0.78-0.16-1.07-1.52-1.1-1.77l-0.56,0.74l-1.08,3.23l0.89,0.91
              L13.23,68.99z"
          />
          <path
            style={{ fill: kitColor1 }}
            d="M111.52,72.29c0.29,0.08,1.53,0.08,1.96-0.12c0.68-0.36,6.58-3.37,9.71-6.04
              c3.12-2.65,10.81-5.96,11.67-6.15c0.72-0.15,1.37-1.67,1.42-2.02l1.26,2.8l-0.23,2.08l-10.44,5.2l-4.83,3.39l-6.78,3.88L113.42,75
              L111.52,72.29z"
          />
          <g>
            <path
              style={{ fill: shadow }}
              d="M82.87,5.68c-0.33,1.93-1.69,4.18-4.17,5.33c-4.03,1.86-9.64,6.15-9.64,6.15s-5.6-4.29-9.63-6.15
                c-2.45-1.14-3.8-3.35-4.15-5.26c2.41-0.7,4.63-0.6,7.08-0.4c2.22,0.19,4.46,0.33,6.7,0.33c3.01,0,5.97-0.37,8.96-0.55
                C79.38,5.05,81.78,5.39,82.87,5.68z"
            />
            <path
              opacity="0.16"
              d="M136.47,62.38c0.86-0.18,0.35-2.48-0.17-3.37c-0.3-0.51-3.93-7.08-7.31-13.12
                c-6.25,2.94-14.52-0.07-23.84-13.57l0.34,9.94l0.12,19.66c0,0,7.03,11.31,7.73,12.01c0.69,0.71,1.25,0.86,1.95,0.51
                c0.68-0.36,6.58-3.37,9.71-6.03C128.15,65.75,135.6,62.56,136.47,62.38z"
            />
            <path
              opacity="0.16"
              d="M1.61,62.5c-0.86-0.18-0.34-2.48,0.18-3.37c0.29-0.51,3.92-7.08,7.31-13.12
                c6.25,2.94,15.6-0.46,24.23-14.08l-0.57,13.53l-0.01,16.45c0,0-7.58,11.94-8.27,12.64c-0.7,0.71-1,0.35-1.69,0.01
                c-0.69-0.36-6.59-3.37-9.72-6.03C9.94,65.87,2.48,62.68,1.61,62.5z"
            />
            <path
              opacity="0.16"
              d="M104.37,137.79c1.38-0.53,1.56-1.77,1.56-3.19c0-1.42-0.18-4.61,0-7.09c0.16-2.3-0.27-6.7-0.6-9.32
                c-5.17,4.71-8.97,14.72-10.63,21.6C100.37,138.98,103.7,138.05,104.37,137.79z"
            />
            <path
              opacity="0.16"
              d="M33.65,137.72c-1.38-0.53-1.56-1.77-1.56-3.19c0-1.42,0.18-4.61,0-7.09c-0.15-2.3,0.27-6.7,0.61-9.32
                c5.16,4.71,8.96,14.72,10.63,21.6C37.65,138.91,34.32,137.98,33.65,137.72z"
            />
            <g>
              <path
                d="M59.63,2.24c0.3,0,0.59,0.01,0.84,0.05c1.39,0.18,5.91,0.53,8.68,0.53s7.29-0.36,8.68-0.53c0.24-0.03,0.5-0.04,0.78-0.04
                  c1.33,0,3.01,0.3,3.9,0.58l2.08,3.19c2.26,0.53,11.11,2.66,12.85,3.37c1.74,0.71,4,1.77,5.38,1.94c1.39,0.19,3.47,0.72,4.86,1.24
                  c1.38,0.54,4.69,3.02,5.9,4.8c1.21,1.77,8.24,16.3,9.8,18.62c1.57,2.3,12.42,22.34,12.94,23.23c0.52,0.88,1.03,3.19,0.17,3.36
                  c-0.87,0.19-8.33,3.37-11.46,6.03c-3.13,2.67-9.03,5.68-9.71,6.03c-0.22,0.11-0.42,0.17-0.61,0.17c-0.4,0-0.75-0.25-1.22-0.74
                  c-0.69-0.7-7.73-12.01-7.73-12.01c-1.21,2.66-1.21,5.67-1.04,7.62c0.18,1.96-0.87,11.8-0.35,14.63
                  c0.52,2.84,0.52,30.33,0.87,32.82c0.35,2.48,0.86,7.44,0.69,9.93c-0.18,2.48,0,5.67,0,7.09c0,1.43-0.18,2.67-1.56,3.2
                  c-1.26,0.48-11.64,3.29-29.44,3.29c-1.85,0-3.78-0.03-5.8-0.1c-2.01,0.07-3.94,0.1-5.8,0.1c-17.81,0-28.18-2.81-29.44-3.29
                  c-1.38-0.53-1.56-1.77-1.56-3.2c0-1.41,0.18-4.61,0-7.09c-0.18-2.49,0.35-7.45,0.69-9.93c0.35-2.49,0.35-29.98,0.87-32.82
                  c0.51-2.83-0.52-12.68-0.35-14.63c0.18-1.95,0.18-4.96-1.04-7.62c0,0-7.3,11.53-7.99,12.24c-0.4,0.42-0.63,0.59-0.89,0.59
                  c-0.18,0-0.38-0.09-0.67-0.23c-0.7-0.35-6.59-3.36-9.73-6.03c-3.12-2.66-10.59-5.84-11.45-6.03c-0.87-0.17-0.35-2.48,0.17-3.36
                  C2.5,58.33,13.43,38.47,15,36.16c1.56-2.32,8.5-17.04,9.72-18.8c1.22-1.78,4.51-4.26,5.91-4.8c1.39-0.53,3.46-1.05,4.86-1.24
                  c1.39-0.17,3.63-1.28,5.38-1.94c1.97-0.74,10.83-2.9,12.84-3.37l2.08-3.19C56.5,2.53,58.26,2.24,59.63,2.24 M59.63,1.24
                  c-1.44,0-3.34,0.29-4.22,0.65c-0.19,0.08-0.35,0.21-0.46,0.38l-1.86,2.86C50.73,5.69,42.49,7.7,40.51,8.44
                  c-0.54,0.2-1.13,0.45-1.7,0.69c-1.22,0.51-2.61,1.09-3.45,1.2c-1.52,0.21-3.66,0.76-5.09,1.3c-1.65,0.64-5.1,3.31-6.38,5.17
                  c-0.62,0.9-2.42,4.52-4.5,8.72c-2.08,4.19-4.44,8.93-5.22,10.09C12.96,37.39,6.48,49.06,3,55.33c-1.03,1.85-1.77,3.18-1.88,3.38
                  c-0.45,0.78-1.11,2.8-0.57,3.97c0.21,0.47,0.6,0.78,1.08,0.87c0.67,0.16,7.95,3.22,10.99,5.81c3.22,2.75,9.25,5.82,9.93,6.17
                  c0.35,0.18,0.7,0.34,1.12,0.34c0.69,0,1.15-0.42,1.61-0.89c0.59-0.6,4.68-6.98,7-10.64c0.46,1.89,0.44,3.81,0.31,5.24
                  c-0.08,0.85,0.04,2.8,0.19,5.27c0.2,3.42,0.46,7.69,0.17,9.28c-0.28,1.54-0.41,8.92-0.55,19.19c-0.09,6.21-0.18,12.63-0.32,13.67
                  c-0.38,2.7-0.88,7.63-0.7,10.13c0.12,1.74,0.07,3.87,0.03,5.44c-0.02,0.63-0.03,1.18-0.03,1.58c0,1.07,0,3.29,2.2,4.13
                  c1.75,0.67,12.53,3.36,29.8,3.36c1.9,0,3.85-0.03,5.8-0.1c1.94,0.06,3.89,0.1,5.8,0.1c17.24,0,28.05-2.69,29.8-3.35
                  c2.2-0.84,2.2-3.07,2.2-4.13c0-0.4-0.01-0.95-0.03-1.58c-0.04-1.56-0.1-3.7,0.03-5.44c0.17-2.52-0.32-7.44-0.7-10.13
                  c-0.15-1.05-0.24-7.48-0.32-13.7c-0.14-10.25-0.27-17.62-0.55-19.16c-0.3-1.6-0.04-5.89,0.17-9.33c0.15-2.44,0.26-4.37,0.19-5.21
                  c-0.13-1.43-0.15-3.34,0.31-5.22c2.25,3.6,6.16,9.8,6.75,10.4c0.52,0.54,1.12,1.04,1.93,1.04c0.34,0,0.69-0.09,1.05-0.27
                  l0.08-0.04c2.02-1.04,7.01-3.72,9.84-6.13c2.95-2.5,10.12-5.6,11.02-5.81c0.46-0.09,0.84-0.4,1.05-0.87
                  c0.51-1.13-0.09-3.15-0.57-3.97c-0.12-0.21-0.94-1.71-1.98-3.61c-3.42-6.26-9.79-17.91-11-19.68c-0.78-1.16-3.16-5.85-5.26-9.99
                  c-2.11-4.15-3.93-7.74-4.54-8.64c-1.36-1.99-4.88-4.58-6.36-5.16c-1.45-0.55-3.58-1.09-5.09-1.3c-0.97-0.12-2.58-0.8-4-1.4
                  c-0.4-0.17-0.78-0.33-1.14-0.48C95.97,7.7,87.19,5.59,85.22,5.13l-1.86-2.86c-0.13-0.2-0.32-0.34-0.54-0.41
                  c-1.01-0.31-2.77-0.62-4.2-0.62c-0.33,0-0.63,0.02-0.9,0.05c-1.45,0.18-5.91,0.53-8.55,0.53c-2.64,0-7.13-0.34-8.55-0.53
                  C60.31,1.25,59.99,1.24,59.63,1.24L59.63,1.24z"
              />
            </g>
          </g>
        </g>
      </g>
      <ShirtNumber
        shirtNumber={shirtNumber}
        shirtNumberColor={shirtNumberColor}
        shirtNumberBackgroundColor={shirtNumberBackgroundColor}
        shirtNumberBackgroundOpacity={shirtNumberBackgroundOpacity}
      />
    </Kit>
  );
};

export default TwoColorVerticalStripesFullLength;
