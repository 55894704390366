import { useQuery } from '@tanstack/react-query';

import { fetchStageStandings, FetchStageStandingsType } from './fetch';
import { QUERY_KEYS } from '@ntb-sport/constants';

interface UseQueryNifsStageMatches {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: FetchStageStandingsType;
  options?: Record<string, unknown>;
}

export const useQueryStageStandings = ({
  queryKey = QUERY_KEYS.STAGE_STANDINGS,
  queryKeyParams,
  params,
  options,
}: UseQueryNifsStageMatches) => {
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchStageStandings(params),
    enabled: Boolean(params.sportGroup) && Boolean(params.stageId),
    ...options,
  });

  return { data, isLoading, error };
};
