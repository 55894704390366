import { useQuery } from '@tanstack/react-query';

import {
  fetchNifsCountryTournaments,
  FetchNifsCountryTournaments,
} from './fetch';
import { QUERY_KEYS } from '@ntb-sport/constants';

interface UseQueryNifsCountryTournaments {
  queryKey?: string;
  queryKeyParams?: Record<string, unknown>;
  params: FetchNifsCountryTournaments;
  options?: Record<string, unknown>;
}

export const useQueryNifsCountryTournaments = ({
  queryKey = QUERY_KEYS.NIFS_TOURNAMENTS,
  queryKeyParams,
  params,
  options,
}: UseQueryNifsCountryTournaments) => {
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchNifsCountryTournaments(params),
    enabled: Boolean(params.sportGroup) && Boolean(params.countryId),
    ...options,
  });

  return { data, isLoading, error, isFetching };
};
