import Kit from './Kit';
import ShirtNumber from './ShirtNumber';

const TwoColorThinHorizontalStripesArms = ({
  kitColor1 = '#f79025',
  kitColor2 = '#adadad',
  shirtNumber,
  shirtNumberColor,
  shirtNumberBackgroundColor,
  shirtNumberBackgroundOpacity,
  ...restProps
}: {
  size?: any;
  kitColor1: any;
  kitColor2: any;
  kitColor3: any;
  shirtNumber: any;
  shirtNumberColor: any;
  shirtNumberBackgroundColor: any;
  shirtNumberBackgroundOpacity: any;
}) => {
  const shadow = '#000000';

  return (
    <Kit {...restProps}>
      <g>
        <g>
          <g>
            <path
              style={{ fill: kitColor1 }}
              d="M69.15,2.82c2.77,0,7.29-0.36,8.68-0.53c1.38-0.17,3.61,0.2,4.68,0.53l2.08,3.19
                        c2.26,0.53,11.11,2.66,12.85,3.37c1.74,0.71,4,1.77,5.38,1.94c1.39,0.19,3.47,0.72,4.86,1.24c1.38,0.54,4.69,3.02,5.9,4.8
                        c1.21,1.77,8.24,16.3,9.8,18.62c1.57,2.3,12.42,22.34,12.94,23.23c0.52,0.88,1.03,3.19,0.17,3.36c-0.87,0.19-8.33,3.37-11.46,6.03
                        c-3.13,2.67-9.03,5.68-9.71,6.03c-0.71,0.35-1.14,0.14-1.83-0.58c-0.69-0.7-7.73-12.01-7.73-12.01c-1.21,2.66-1.21,5.67-1.04,7.62
                        c0.18,1.96-0.87,11.8-0.35,14.63c0.52,2.84,0.52,30.33,0.87,32.82c0.35,2.48,0.86,7.44,0.69,9.93c-0.18,2.48,0,5.67,0,7.09
                        c0,1.43-0.18,2.67-1.56,3.2c-1.39,0.53-13.89,3.9-35.24,3.19c-21.35,0.71-33.85-2.66-35.23-3.19c-1.38-0.53-1.56-1.77-1.56-3.2
                        c0-1.41,0.18-4.61,0-7.09c-0.18-2.49,0.35-7.45,0.69-9.93c0.35-2.49,0.35-29.98,0.87-32.82c0.51-2.83-0.52-12.68-0.35-14.63
                        c0.18-1.95,0.18-4.96-1.04-7.62c0,0-7.3,11.53-7.99,12.24c-0.69,0.71-0.87,0.71-1.56,0.35c-0.7-0.35-6.59-3.36-9.73-6.03
                        c-3.12-2.66-10.59-5.84-11.45-6.03c-0.87-0.17-0.35-2.48,0.17-3.36C2.5,58.33,13.43,38.47,15,36.16c1.56-2.32,8.5-17.04,9.72-18.8
                        c1.22-1.78,4.51-4.26,5.91-4.8c1.39-0.53,3.46-1.05,4.86-1.24c1.39-0.17,3.63-1.28,5.38-1.94c1.97-0.74,10.83-2.9,12.84-3.37
                        l2.08-3.19c0.87-0.35,3.3-0.71,4.69-0.53C61.86,2.46,66.38,2.82,69.15,2.82z"
            />
          </g>
          <rect
            x="33.87"
            y="77.96"
            style={{ fill: kitColor2 }}
            width="70.33"
            height="2.01"
          />
          <rect
            x="33.4"
            y="93.09"
            style={{ fill: kitColor2 }}
            width="71.26"
            height="2.01"
          />
          <rect
            x="33.16"
            y="109.11"
            style={{ fill: kitColor2 }}
            width="71.75"
            height="2.01"
          />
          <rect
            x="32.21"
            y="124.24"
            style={{ fill: kitColor2 }}
            width="73.64"
            height="2.01"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M42.85,8.58c-2.83,0.87-5.41,2.33-8.31,2.91c-3.62,0.7-6.19,2.01-8.77,4.59c-0.44,0.44-0.83,0.88-1.1,1.29
                    c-0.63,0.91-2.77,5.25-4.9,9.56c-0.01,0.03-0.03,0.05-0.04,0.08c-3.15,5.95-6.51,11.77-9.8,17.64c-1.53,2.71-3.09,5.38-4.58,8.12
                    c-0.75,1.38-1.47,2.77-2.17,4.18c-0.43,0.87-0.91,1.71-1.29,2.61c-0.29,0.68-0.99,1.63-0.79,2.4c0.2,0.75,0.98,1.04,1.63,1.29
                    c3.91,1.45,7.66,3.9,11.02,6.35c2.89,2.14,5.88,3.92,9.08,5.54c1.11,0.58,1.65-0.47,2.2-1.27l7.54-11.71
                    c0.27,0.59,0.47,1.19,0.63,1.81h71.91c0.17-0.64,0.39-1.29,0.67-1.9l7.39,12.58c0.52,0.55,1.29,0.32,1.67,0.2
                    c0.29-0.01,0.66-0.16,1.14-0.44c1.23-0.74,3.24-1.69,4.85-2.69c2.76-1.75,5.34-3.79,8.38-5.61c1.7-1,3.78-1.96,5.38-2.64
                    c0.95-0.39,1.83-0.43,2.24-0.99c0.72-0.99-0.31-3.21-0.64-3.79c-6.16-12.32-13.53-24.08-19.69-36.4
                    c-3.32-6.64-5.97-9.99-13.89-11.17c-2.92-0.44-4.38-1.94-8.57-2.89L84.6,6l-2.12-3.19l-3.24-1.15L68.69,2.38l-9.88-0.46l-3.21,0.59
                    L53.57,5.6L42.85,8.58z M60.41,5.16c1.39,0.17,5.83,0.54,8.6,0.54c2.79,0,7.22-0.36,8.61-0.54c1.33-0.17,4.15,0.15,5.2,0.48
                    c-0.31,1.93-1.66,4.22-4.18,5.38c-3.08,1.42-7.07,4.25-8.79,5.5c-0.51,0.37-1.18,0.37-1.67,0c-1.71-1.26-5.72-4.08-8.8-5.5
                    c-2.49-1.17-3.86-3.43-4.18-5.37C56.24,5.31,59.08,4.99,60.41,5.16z M36.96,20.47c4.31-4,8.53-7.12,15.39-8.92
                    c0.32-0.08,0.64-0.16,0.96-0.23c0.29,2.48,1.14,5.18,2.89,6.88c0.09,0.09,2.22-1.98,3.36-3.11c0.43-0.43,1.08-0.54,1.63-0.27
                    c1.78,0.88,5.73,2.83,7.29,3.5c0.36,0.16,0.76,0.16,1.13,0c1.54-0.67,5.48-2.61,7.42-3.58c0.63-0.32,1.41-0.12,1.81,0.48
                    c0.78,1.17,1.96,2.88,2.06,2.8c2.01-1.63,3.12-4.46,3.59-7.02c0.51,0.11,1,0.21,1.49,0.35c6.86,1.79,11.09,4.92,15.39,8.92
                    c1.85,1.73,2.91,5.75,3.51,10.02h-71.4C34.08,26.11,35.14,22.17,36.96,20.47z M32.71,41.64c0.01-2.34,0.12-5.83,0.52-9.33h71.89
                    c0.37,3.44,0.48,6.84,0.51,9.15c0.01,1.31,0.03,3.01,0.04,4.86H32.68C32.69,44.55,32.71,42.91,32.71,41.64z M32.63,61.95
                    c0-0.94,0.03-7.66,0.04-13.62h73.03c0.07,5.97,0.16,12.7,0.17,13.62H32.63z"
          />
          <g>
            <path
              style={{ fill: shadow }}
              d="M82.82,5.64c-0.31,1.93-1.66,4.22-4.18,5.38c-3.08,1.42-7.07,4.25-8.79,5.5c-0.51,0.37-1.18,0.37-1.67,0
                        c-1.71-1.26-5.72-4.08-8.8-5.5c-2.49-1.17-3.86-3.43-4.18-5.37c1.03-0.35,3.87-0.67,5.21-0.5c1.39,0.17,5.83,0.54,8.6,0.54
                        c2.79,0,7.22-0.36,8.61-0.54C78.95,4.99,81.78,5.31,82.82,5.64z"
            />
            <path
              opacity="0.16"
              d="M136.43,62.39c0.86-0.18,0.35-2.48-0.17-3.37c-0.3-0.51-3.93-7.08-7.31-13.12
                        c-6.25,2.94-14.52-0.07-23.84-13.57l0.34,9.94l0.12,19.66c0,0,7.03,11.31,7.73,12.01c0.69,0.71,1.25,0.86,1.95,0.51
                        c0.68-0.36,6.58-3.37,9.71-6.03C128.1,65.76,135.56,62.57,136.43,62.39z"
            />
            <path
              opacity="0.16"
              d="M1.56,62.51c-0.86-0.18-0.34-2.48,0.18-3.37c0.29-0.51,3.92-7.08,7.31-13.12
                        c6.25,2.94,15.6-0.46,24.23-14.08l-0.57,13.53L32.7,61.92c0,0-7.58,11.94-8.27,12.64c-0.7,0.71-1,0.35-1.69,0.01
                        c-0.69-0.36-6.59-3.37-9.72-6.03C9.9,65.88,2.43,62.69,1.56,62.51z"
            />
            <path
              opacity="0.16"
              d="M104.33,137.8c1.38-0.53,1.56-1.77,1.56-3.19c0-1.42-0.18-4.61,0-7.09c0.16-2.3-0.27-6.7-0.6-9.32
                        c-5.17,4.71-8.97,14.72-10.63,21.6C100.32,138.99,103.65,138.06,104.33,137.8z"
            />
            <path
              opacity="0.16"
              d="M33.6,137.73c-1.38-0.53-1.56-1.77-1.56-3.19c0-1.42,0.18-4.61,0-7.09c-0.15-2.3,0.27-6.7,0.61-9.32
                        c5.16,4.71,8.96,14.72,10.63,21.6C37.6,138.92,34.28,137.99,33.6,137.73z"
            />
            <g>
              <path
                d="M59.63,2.24c0.3,0,0.59,0.01,0.84,0.05c1.39,0.18,5.91,0.53,8.68,0.53s7.29-0.36,8.68-0.53c0.24-0.03,0.5-0.04,0.78-0.04
                            c1.33,0,3.01,0.3,3.9,0.58l2.08,3.19c2.26,0.53,11.11,2.66,12.85,3.37c1.74,0.71,4,1.77,5.38,1.94c1.39,0.19,3.47,0.72,4.86,1.24
                            c1.38,0.54,4.69,3.02,5.9,4.8c1.21,1.77,8.24,16.3,9.8,18.62c1.57,2.3,12.42,22.34,12.94,23.23c0.52,0.88,1.03,3.19,0.17,3.36
                            c-0.87,0.19-8.33,3.37-11.46,6.03c-3.13,2.67-9.03,5.68-9.71,6.03c-0.22,0.11-0.42,0.17-0.61,0.17c-0.4,0-0.75-0.25-1.22-0.74
                            c-0.69-0.7-7.73-12.01-7.73-12.01c-1.21,2.66-1.21,5.67-1.04,7.62c0.18,1.96-0.87,11.8-0.35,14.63
                            c0.52,2.84,0.52,30.33,0.87,32.82c0.35,2.48,0.86,7.44,0.69,9.93c-0.18,2.48,0,5.67,0,7.09c0,1.43-0.18,2.67-1.56,3.2
                            c-1.26,0.48-11.64,3.29-29.44,3.29c-1.85,0-3.78-0.03-5.8-0.1c-2.01,0.07-3.94,0.1-5.8,0.1c-17.81,0-28.18-2.81-29.44-3.29
                            c-1.38-0.53-1.56-1.77-1.56-3.2c0-1.41,0.18-4.61,0-7.09c-0.18-2.49,0.35-7.45,0.69-9.93c0.35-2.49,0.35-29.98,0.87-32.82
                            c0.51-2.83-0.52-12.68-0.35-14.63c0.18-1.95,0.18-4.96-1.04-7.62c0,0-7.3,11.53-7.99,12.24c-0.4,0.42-0.63,0.59-0.89,0.59
                            c-0.18,0-0.38-0.09-0.67-0.23c-0.7-0.35-6.59-3.36-9.73-6.03c-3.12-2.66-10.59-5.84-11.45-6.03c-0.87-0.17-0.35-2.48,0.17-3.36
                            C2.5,58.33,13.43,38.47,15,36.16c1.56-2.32,8.5-17.04,9.72-18.8c1.22-1.78,4.51-4.26,5.91-4.8c1.69-0.64,3.49-0.93,5.24-1.37
                            c1.75-0.43,3.32-1.19,5-1.82c1.97-0.74,10.83-2.9,12.84-3.37l2.08-3.19C56.5,2.53,58.26,2.24,59.63,2.24 M59.63,1.24L59.63,1.24
                            c-1.44,0-3.34,0.29-4.22,0.65c-0.19,0.08-0.35,0.21-0.46,0.38l-1.86,2.86C50.73,5.69,42.49,7.7,40.51,8.44
                            C40,8.63,39.5,8.84,39,9.04c-1.14,0.46-2.22,0.9-3.38,1.19c-0.49,0.12-0.98,0.23-1.48,0.34c-1.28,0.28-2.6,0.58-3.88,1.06
                            c-1.65,0.64-5.1,3.31-6.38,5.17c-0.62,0.9-2.42,4.52-4.5,8.72c-2.08,4.19-4.44,8.93-5.22,10.09C12.96,37.39,6.48,49.06,3,55.33
                            c-1.03,1.85-1.77,3.18-1.89,3.39c-0.45,0.78-1.11,2.8-0.57,3.97c0.21,0.47,0.6,0.78,1.08,0.87c0.67,0.16,7.95,3.22,10.99,5.81
                            c3.22,2.75,9.25,5.82,9.93,6.17c0.35,0.18,0.7,0.34,1.12,0.34c0.69,0,1.15-0.42,1.61-0.89c0.59-0.6,4.68-6.98,7-10.64
                            c0.46,1.89,0.44,3.81,0.31,5.24c-0.08,0.85,0.04,2.8,0.19,5.27c0.2,3.42,0.46,7.69,0.17,9.28c-0.28,1.54-0.41,8.92-0.55,19.19
                            c-0.09,6.21-0.18,12.63-0.32,13.67c-0.38,2.7-0.88,7.63-0.7,10.13c0.12,1.74,0.07,3.87,0.03,5.44c-0.02,0.63-0.03,1.18-0.03,1.58
                            c0,1.07,0,3.29,2.2,4.13c1.75,0.67,12.53,3.36,29.8,3.36c1.9,0,3.85-0.03,5.8-0.1c1.94,0.06,3.89,0.1,5.8,0.1
                            c17.24,0,28.05-2.69,29.8-3.35c2.2-0.84,2.2-3.07,2.2-4.13c0-0.4-0.01-0.95-0.03-1.58c-0.04-1.56-0.1-3.7,0.03-5.44
                            c0.17-2.52-0.32-7.44-0.7-10.13c-0.15-1.05-0.24-7.48-0.32-13.7c-0.14-10.25-0.27-17.62-0.55-19.16c-0.3-1.6-0.04-5.89,0.17-9.33
                            c0.15-2.44,0.26-4.37,0.19-5.21c-0.13-1.43-0.15-3.34,0.31-5.22c2.25,3.6,6.16,9.8,6.75,10.4c0.52,0.54,1.12,1.04,1.93,1.04
                            c0.34,0,0.69-0.09,1.05-0.27l0.08-0.04c2.02-1.04,7.01-3.72,9.84-6.13c2.95-2.5,10.12-5.6,11.02-5.81
                            c0.46-0.09,0.84-0.4,1.05-0.87c0.51-1.13-0.09-3.15-0.57-3.97c-0.12-0.21-0.94-1.71-1.98-3.61c-3.42-6.26-9.79-17.91-11-19.68
                            c-0.78-1.16-3.16-5.85-5.26-9.99c-2.11-4.15-3.93-7.74-4.54-8.64c-1.36-1.99-4.88-4.58-6.36-5.16c-1.45-0.55-3.58-1.09-5.09-1.3
                            c-0.97-0.12-2.58-0.8-4-1.4c-0.4-0.17-0.78-0.33-1.14-0.48C95.98,7.7,87.19,5.59,85.22,5.13l-1.86-2.86
                            c-0.13-0.2-0.32-0.34-0.54-0.41c-1.01-0.31-2.77-0.62-4.2-0.62c-0.33,0-0.63,0.02-0.9,0.05c-1.45,0.18-5.91,0.53-8.55,0.53
                            c-2.64,0-7.13-0.34-8.55-0.53C60.31,1.25,59.99,1.24,59.63,1.24L59.63,1.24z"
              />
            </g>
          </g>
        </g>
      </g>
      <ShirtNumber
        shirtNumber={shirtNumber}
        shirtNumberColor={shirtNumberColor}
        shirtNumberBackgroundColor={shirtNumberBackgroundColor}
        shirtNumberBackgroundOpacity={shirtNumberBackgroundOpacity}
      />
    </Kit>
  );
};

export default TwoColorThinHorizontalStripesArms;
