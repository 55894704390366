import { COMPONENT_IDS } from '@ntb-sport/constants';

// For each component-id import the component styles list
import { COMBINED_EVENTS_STANDINGS_STYLES } from './styles/sportedit/combined-events-standings';
import { CUSTOMER_FEEDBACK_STYLES } from './styles/common/customer-feedback';
import { EVENT_HERO_STYLES } from './styles/sportedit/event-hero';
import { EVENT_MAIN_MENU_STYLES } from './styles/sportedit/event-main-menu';
import { EVENT_MENU_STYLES } from './styles/sportedit/event-menu';
import { EVENT_PARTICIPANTS_STYLES } from './styles/sportedit/event-participants';
import { FAIR_PLAY_STANDINGS_STYLES } from './styles/nifs/fair-play-standings';
import { MEDAL_TABLE_STYLES } from './styles/sportedit/medal-table';
import { SCOPE_MENU_STYLES } from './styles/sportedit/scope-menu';
import { SCOPE_RESULT_STYLES } from './styles/sportedit/scope-result';
import { SEASON_STANDING_STYLES } from './styles/sportedit/season-standing';
import { SPORT_EDIT_CALENDAR_STYLES } from './styles/sportedit/sport-edit-calendar';
import { TEAM_CALENDAR_STYLES } from './styles/nifs/team-calendar';
import { TEAM_FIXURES_STYLES } from './styles/nifs/team-fixtures';
import { TEAM_FIXTURES_TEAM_STYLES } from './styles/nifs/team-fixtures-team';
import { TEAM_FIXTURES_MATCH_STYLES } from './styles/nifs/team-fixtures-match';
import { TEAM_LINEUP_STYLES } from './styles/nifs/team-lineup';
import { TEAM_MATCH_REPORT_STYLES } from './styles/nifs/team-match-report';
import { TEAM_MATCH_RESULT_STYLES } from './styles/nifs/team-match-result';
import { TEAM_PLAYER_STATS_STYLES } from './styles/nifs/team-player-stats';
import { TEAM_STANDINGS_STYLES } from './styles/nifs/team-standings';
import { TEAM_MATCH_STATISTICS_STYLES } from './styles/nifs/team-match-statistics';
import { TOUR_STANDINGS_STYLES } from './styles/sportedit/tour-standing';
import { DATE_SELECTOR_STYLES } from './styles/common/date-selector';

// Map list of styles to component id
export const STYLES_BY_COMPONENT_ID = {
  [COMPONENT_IDS.COMBINED_EVENTS_STANDINGS]: {
    name: 'Combined Events Standings',
    selectors: COMBINED_EVENTS_STANDINGS_STYLES,
  },
  [COMPONENT_IDS.CUSTOMER_FEEDBACK]: {
    name: 'Customer Feedback',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.CUSTOMER_FEEDBACK}']`,
    styles: CUSTOMER_FEEDBACK_STYLES,
  },
  [COMPONENT_IDS.EVENT_HERO]: {
    name: 'Event Hero',
    selectors: EVENT_HERO_STYLES,
  },
  [COMPONENT_IDS.EVENT_MAIN_MENU]: {
    name: 'Event Main Menu',
    selectors: EVENT_MAIN_MENU_STYLES,
  },
  [COMPONENT_IDS.EVENT_MENU]: {
    name: 'Event Menu',
    selectors: EVENT_MENU_STYLES,
  },
  [COMPONENT_IDS.EVENT_PARTICIPANTS]: {
    name: 'Event Participants',
    selectors: EVENT_PARTICIPANTS_STYLES,
  },
  [COMPONENT_IDS.FAIR_PLAY_STANDINGS]: {
    name: 'Fair Play Standings',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.FAIR_PLAY_STANDINGS}']`,
    styles: FAIR_PLAY_STANDINGS_STYLES,
  },
  [COMPONENT_IDS.MEDAL_TABLE]: {
    name: 'Medal Table',
    selectors: MEDAL_TABLE_STYLES,
  },
  [COMPONENT_IDS.SCOPE_MENU]: {
    name: 'Scope Menu',
    selectors: SCOPE_MENU_STYLES,
  },
  [COMPONENT_IDS.SCOPE_RESULTS]: {
    name: 'Scope Results',
    selectors: SCOPE_RESULT_STYLES,
  },
  [COMPONENT_IDS.SEASON_STANDING]: {
    name: 'Season Standing',
    selectors: SEASON_STANDING_STYLES,
  },
  [COMPONENT_IDS.SPORTEDIT_CALENDAR]: {
    name: 'SportEdit Calendar',
    selectors: SPORT_EDIT_CALENDAR_STYLES,
  },
  [COMPONENT_IDS.TEAM_CALENDAR]: {
    name: 'Team Calendar',
    selectors: TEAM_CALENDAR_STYLES,
  },
  [COMPONENT_IDS.TEAM_FIXTURES]: {
    name: 'Team Fixtures',
    selectors: TEAM_FIXURES_STYLES,
  },
  [COMPONENT_IDS.TEAM_FIXTURES_MATCH]: {
    name: 'Team Fixtures Match',
    selectors: TEAM_FIXTURES_MATCH_STYLES,
  },
  [COMPONENT_IDS.TEAM_FIXTURES_TEAM]: {
    name: 'Team Fixtures Team',
    selectors: TEAM_FIXTURES_TEAM_STYLES,
  },
  [COMPONENT_IDS.TEAM_LINEUP]: {
    name: 'Team Lineup',
    selectors: TEAM_LINEUP_STYLES,
  },
  [COMPONENT_IDS.TEAM_MATCH_REPORT]: {
    name: 'Team Match Report',
    selectors: TEAM_MATCH_REPORT_STYLES,
  },
  [COMPONENT_IDS.TEAM_MATCH_RESULT]: {
    name: 'Team Match Result',
    selectors: TEAM_MATCH_RESULT_STYLES,
  },
  [COMPONENT_IDS.TEAM_PLAYER_STATS]: {
    name: 'Team Player Stats',
    selectors: TEAM_PLAYER_STATS_STYLES,
  },
  [COMPONENT_IDS.TEAM_STANDINGS]: {
    name: 'Team Standings',
    selectors: TEAM_STANDINGS_STYLES,
  },
  [COMPONENT_IDS.TEAM_MATCH_STATISTICS]: {
    name: 'Team Match Statistics',
    selectors: TEAM_MATCH_STATISTICS_STYLES,
  },
  [COMPONENT_IDS.TOUR_STANDINGS]: {
    name: 'Tour Standings',
    selectors: TOUR_STANDINGS_STYLES,
  },
  [COMPONENT_IDS.DATE_SELECTOR]: {
    name: 'Date Selector',
    selectors: DATE_SELECTOR_STYLES,
  },
};
