import {
  COMPONENT_IDS,
  DATA_PICKER_GROUPS,
  DATA_PROVIDERS,
  SPORT_GROUP,
} from '@ntb-sport/constants';
import { WidgetConfig, SportsdataConfig } from '@ntb-sport/types';
import {
  DATA_TYPES,
  WIDGET_IDS,
  WIDGET_OPTIONS,
  WIDGET_VERSION,
} from '../common';

export const config: WidgetConfig = {
  id: WIDGET_IDS.FAIR_PLAY_TABLE,
  dataType: DATA_TYPES.NIFS,
  version: WIDGET_VERSION,
  displayName: 'Fairplay tabell',
  widgetNamespace: 'NtbWidgetFairPlayTable',
  iframeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.FAIR_PLAY_TABLE}/iframe.html`,
  widgetUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.FAIR_PLAY_TABLE}/${WIDGET_IDS.FAIR_PLAY_TABLE}.js`,
  themeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/themes/default.css`,
  title: 'Fairplay tabell',
  summary: 'Regional tabell for fairplay i fotball',
  sportGroups: [SPORT_GROUP.FOOTBALL],
  description: 'general description to be displayed on the documentation page',
  components: [COMPONENT_IDS.FAIR_PLAY_STANDINGS],
  options: {
    [WIDGET_OPTIONS.CUSTOMER_ID]: { isRequired: true },
    [WIDGET_OPTIONS.ERROR_MESSAGE]: { isRequired: false },
    [WIDGET_OPTIONS.ID]: { isRequired: true },
    [WIDGET_OPTIONS.ON_ERROR]: { isRequired: false },
    [WIDGET_OPTIONS.SPORT_GROUP]: { isRequired: true },
    [WIDGET_OPTIONS.STYLE_ID]: { isRequired: false },
    [WIDGET_OPTIONS.TARGET_ID]: { isRequired: true },
    [WIDGET_OPTIONS.MIN_NUMBER_OF_TEAMS]: { isRequired: false },
    [WIDGET_OPTIONS.REQUIRE_SENIOR_TEAM]: { isRequired: false },
  },
  uiOptions: {
    [COMPONENT_IDS.FAIR_PLAY_STANDINGS]: [
      WIDGET_OPTIONS.LOGO_URL,
      WIDGET_OPTIONS.SHOW_ADDITIONAL_ROWS,
      WIDGET_OPTIONS.SHOW_DISTRICT_NAME,
      WIDGET_OPTIONS.SHOW_SEARCH,
      WIDGET_OPTIONS.TITLE,
      WIDGET_OPTIONS.TABLE_ROWS,
    ],
  },
};

export const sportsdataConfig: SportsdataConfig = {
  dataPickerGroups: {
    [DATA_PICKER_GROUPS.NIFS_DISTRICT_GROUP]: {
      title: 'Distrikt',
      key: DATA_PICKER_GROUPS.NIFS_DISTRICT_GROUP,
      isDefault: true,
      primaryId: DATA_PROVIDERS.NIFS_DISTRICTS,
      pickers: {
        [DATA_PROVIDERS.SPORT_GROUP]: { defaultValue: 'football' },
        [DATA_PROVIDERS.NIFS_COUNTRIES]: { defaultValue: 1 },
        [DATA_PROVIDERS.NIFS_DISTRICTS]: {
          defaultValue: 13,
          isPrimaryId: true,
        },
      },
    },
  },
  unique: {
    [WIDGET_OPTIONS.MIN_NUMBER_OF_TEAMS]: {
      key: WIDGET_OPTIONS.MIN_NUMBER_OF_TEAMS,
      defaultValue: 10,
      label: 'Minimum lag per klubb',
      type: 'numberInput',
    },
    [WIDGET_OPTIONS.REQUIRE_SENIOR_TEAM]: {
      key: WIDGET_OPTIONS.REQUIRE_SENIOR_TEAM,
      defaultValue: false,
      label: 'Krav om seniorlag',
      type: 'checkbox',
    },
  },
  optionGroups: {
    [COMPONENT_IDS.FAIR_PLAY_STANDINGS]: {
      title: 'Fair-play tabell',
      key: COMPONENT_IDS.FAIR_PLAY_STANDINGS,
      options: {
        [WIDGET_OPTIONS.LOGO_URL]: {
          key: WIDGET_OPTIONS.LOGO_URL,
          defaultValue:
            'https://upload.wikimedia.org/wikipedia/commons/thumb/8/85/Coop_Norge_logo.svg/799px-Coop_Norge_logo.svg.png',
          label: 'Logo url',
          type: 'input',
        },

        [WIDGET_OPTIONS.SHOW_ADDITIONAL_ROWS]: {
          key: WIDGET_OPTIONS.SHOW_ADDITIONAL_ROWS,
          defaultValue: true,
          label: 'Vis valg for å se flere rader',
          type: 'checkbox',
        },
        [WIDGET_OPTIONS.SHOW_DISTRICT_NAME]: {
          key: WIDGET_OPTIONS.SHOW_DISTRICT_NAME,
          defaultValue: true,
          label: 'Vis distriktsnavn',
          type: 'checkbox',
        },
        [WIDGET_OPTIONS.SHOW_SEARCH]: {
          key: WIDGET_OPTIONS.SHOW_SEARCH,
          defaultValue: true,
          label: 'Vis søkefelt',
          type: 'checkbox',
        },
        [WIDGET_OPTIONS.TITLE]: {
          key: WIDGET_OPTIONS.TITLE,
          defaultValue: 'Fair-play tabell:',
          label: 'Tittel',
          type: 'input',
        },
        [WIDGET_OPTIONS.TABLE_ROWS]: {
          key: WIDGET_OPTIONS.TABLE_ROWS,
          defaultValue: 12,
          label: 'Antall rader',
          type: 'numberInput',
        },
      },
    },
  },
};
