import { NeoScopeResultType } from '@ntb-sport/types';
import {
  Rank,
  Records,
  Qualified,
  Duration,
  Points,
  Distance,
} from '../Common';
import { useBem } from '@ntb-sport/hooks';
import './default.css';
import EventParticipant from '../../../EventParticipant/EventParticipant';

export enum DefaultTypes {
  DURATION = 'duration',
  DISTANCE = 'distance',
  POINTS = 'points',
}
interface DefaultProps {
  scopeResult: NeoScopeResultType;
  highlightedCountryUUID?: string;
  type: DefaultTypes;
  isMedalEvent?: boolean;
  scopeTypeId?: number;
}

export const Default = ({
  scopeResult,
  highlightedCountryUUID,
  type,
  isMedalEvent,
  scopeTypeId,
}: DefaultProps) => {
  const bem = useBem('scope-results');

  return (
    <>
      <Rank
        scopeResult={scopeResult}
        highlightedCountryUUID={highlightedCountryUUID}
        isMedalEvent={isMedalEvent}
        scopeTypeId={scopeTypeId}
      />
      <EventParticipant
        person={scopeResult?.participant?.person}
        team={scopeResult?.participant?.team}
        representing={scopeResult?.participant?.representing}
        bibNumber={scopeResult?.participant?.bibNumber}
        lineup={scopeResult?.participant?.lineup}
        lineupExchanger={scopeResult?.participant?.lineupExchanger}
        isPelotonParticipant={scopeResult?.participant?.isPelotonParticipant}
      />

      <div className={bem('default-result')}>
        <Qualified resultId={scopeResult?.resultId} />
        <Records records={scopeResult?.records} />

        {type === DefaultTypes.DURATION && (
          <Duration
            duration={scopeResult.duration}
            timeDifference={scopeResult.timeDifference}
            resultId={scopeResult.resultId}
          />
        )}

        {type === DefaultTypes.POINTS && (
          <Points points={scopeResult.points} resultId={scopeResult.resultId} />
        )}

        {type === DefaultTypes.DISTANCE && (
          <Distance
            distance={scopeResult.distance}
            resultId={scopeResult.resultId}
          />
        )}
      </div>
    </>
  );
};
