import { MEDIA_QUERY } from '@ntb-sport/constants';
import styled from 'styled-components';

interface StyledProps {
  $isMainMatch?: boolean;
  $isOvertime?: boolean;
}

export const MatchTimer = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  background-color: var(--background-color);
  border-style: solid;
  border-width: 2px;
  border-color: var(--border-color);
  border-radius: 50%;
  color: var(--font-color);
  margin: 0 var(--ntb-space-3);

  font-size: ${(props) =>
    props.$isMainMatch ? 'var(--ntb-fontSizes-md)' : 'var(--ntb-fontSizes-xs)'};

  @container team-match-result-container ${MEDIA_QUERY.TABLET} {
    width: 48px;
    height: 48px;
  }
`;
