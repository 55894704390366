import AudioAnimation from './AudioAnimation';
import * as S from './MediaPlayerButton.styled';

interface MediaPlayerButtonProps {
  onClick: () => void;
  isRadio: boolean;
  isPlaying: boolean;
}

export const MediaPlayerButton = ({
  onClick,
  isRadio,
  isPlaying,
}: MediaPlayerButtonProps) => (
  <S.Theme id="ntb-media-button">
    {isRadio && (
      <S.RadioButton onClick={onClick}>
        <S.RadioButtonContent>
          {isPlaying ? <AudioAnimation /> : <span>Hør på </span>}
          <S.RadioLogo />
        </S.RadioButtonContent>
        <S.RadioButtonIcon>
          {isPlaying ? (
            <svg
              aria-hidden="true"
              width="1.500em"
              height="1.500em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 3H3v18h7V3zm11 0h-7v18h7V3z"
              ></path>
            </svg>
          ) : (
            <svg
              aria-hidden="true"
              width="1.500em"
              height="1.500em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M6 2h8l5.5 10L14 22H6l5.5-10L6 2z"
              ></path>
            </svg>
          )}
        </S.RadioButtonIcon>
      </S.RadioButton>
    )}
    {!isRadio && (
      <S.VideoButton onClick={onClick}>
        <span>Se på </span>
        <S.StreamLogo />
      </S.VideoButton>
    )}
  </S.Theme>
);

export default MediaPlayerButton;
