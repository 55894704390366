import { Icon } from '../../chakra';

export const StadiumFootballIcon = (props: any) => (
  <Icon {...props}>
    <g>
      <g>
        <path
          fill="currentColor"
          d="M1.1,13.8c0.4,0.5,0.9,1,1.4,1.5v1.1C1.7,15.8,1.1,14.9,1.1,13.8z M22.4,8.2c-0.3-2.4-3.3-3.9-5.4-4.5
			C14.3,3,11.2,3.2,8.8,4.4C7.2,5.1,5.7,6,4.1,6.7c-1,0.5-2.1,1-2.9,1.9c-1.1,1.2-1,2.7-1,4.3c0,0.6,0,1.3,0.2,1.9
			c0.4,1.3,1.5,2.4,2.5,3.1c4.5,3,10.5,1.1,14.7-1.4c1.7-1,4.5-2.4,4.8-4.6c0.1-0.5,0-1.1,0-1.7C22.4,9.5,22.5,8.8,22.4,8.2z
			 M20.7,6.7c0.3,0.3,0.7,0.9,0.9,1.4c-1.2-0.6-3.1-0.7-3.9-0.7c0-0.3-0.1-0.6-0.3-0.9C18.1,6.5,19.5,6.5,20.7,6.7
			C20.7,6.7,20.7,6.7,20.7,6.7z M19.3,5.6c0.4,0.3,0.9,0.6,1.1,0.9c-1-0.2-2.4-0.1-3.1-0.1C17.2,6.1,17,6,16.7,5.9
			c0.5-0.2,1.3-0.5,1.9-0.5C18.8,5.3,19.1,5.4,19.3,5.6z M10.3,12.4L10.3,12.4c0.9-0.3,1.7-0.9,2.4-1.3c1,0.9,1.7,2.4,2,3.1
			c-1.4,0.6-2.8,1.1-4.3,1.4C10.7,14.4,10.4,13,10.3,12.4z M12.4,10.4c-0.4-0.1-1,0-1.4,0.3l-0.3-0.2l2.4-1.3l0.8,0.4l0,0
			C13.4,9.9,13,10.2,12.4,10.4z M12.2,8.1c-0.5,0.3-1,0.5-1.5,0.8c-1.5,0.8-3,1.6-4.5,2.4c-0.6-0.3-1.1-1-0.6-1.7
			C6.2,8.8,7.4,8.4,8.3,8c1.1-0.6,2.3-1.2,3.4-1.8l0,0C13,5.5,14.5,5.6,16,6.4c0.3,0.2,0.8,0.5,0.9,1c0,0.2-0.1,0.3-0.1,0.4
			c-0.3,0.3-0.7,0.6-1.1,0.8l0,0C14.5,8,13.4,7.5,12.2,8.1z M10.2,11.2c-0.1,0.2-0.2,0.3-0.3,0.4c-0.6,0.2-1.3,0.3-2,0.2l1.7-1
			L10.2,11.2z M18.4,5.2c-0.7,0.1-1.4,0.4-1.8,0.6c-0.3-0.3-0.8-0.4-1.2-0.6l0.3-0.9C16.6,4.4,17.6,4.7,18.4,5.2z M13.1,4.1
			c0.8-0.1,1.6,0,2.3,0.1l-0.3,0.9c-0.5-0.3-1.4-0.3-2-0.3L13.1,4.1L13.1,4.1z M10.7,4.6c0.8-0.2,1.6-0.3,2.3-0.4v0.7
			c-0.9,0.1-1.7,0.5-2.4,0.9l0,0c-0.1-0.3-0.3-0.7-0.7-1C10.2,4.7,10.4,4.6,10.7,4.6z M6.7,6.3c1-0.5,1.9-1,3-1.5
			c0.3,0.3,0.6,0.6,0.7,1C9.7,6.1,9,6.5,8.4,6.9C8,6.7,7.4,6.5,6.7,6.3L6.7,6.3z M6.4,6.5C7.1,6.6,7.7,6.8,8.1,7
			c-0.5,0.4-1.2,0.7-1.8,1c-0.4-0.2-1.2-0.4-2-0.5L6.4,6.5z M3.9,7.7c0.8,0,1.6,0.3,2.2,0.4c-0.4,0.3-1,0.6-1.3,1
			C3.3,9.1,2.3,9.3,1.7,9.5C2.4,8.5,3.4,8,3.9,7.7z M1.5,9.6c0.6-0.2,1.7-0.4,3.2-0.3c-0.2,0.3-0.3,0.7-0.2,1
			c-0.8,0.1-2.5,0.3-3.5,1C1,10.8,1.1,10.2,1.5,9.6z M1.1,11.6c1-0.6,2.7-0.9,3.5-1c0.1,0.4,0.5,0.8,0.8,1c0.1,0.1,0.2,0.2,0.3,0.2
			c-0.5,0.1-1,0.5-1.5,0.8C3.6,13,3,13.3,2.6,13.8C1.9,13.2,1.3,12.4,1.1,11.6z M6.1,18.3c-0.2-0.1-0.4-0.1-0.6-0.2
			c-1-0.3-2-0.8-2.9-1.5v-1.1c0.9,0.6,1.8,1,2.9,1.4C5.7,17,6,17,6.1,17.1V18.3z M2.7,13.9c0.7-0.8,2.4-1.6,3.1-1.9l0,0
			c0.4,0.3,1,0.5,1.5,0.6c-0.3,0.6-0.9,1.9-1.1,3C5.1,15.3,3.8,14.8,2.7,13.9z M10.3,18.4c-1.3,0.2-2.7,0.2-4-0.1V17
			c0.5,0.1,1,0.2,1.5,0.2c0.3,0,1,0.1,1.6,0c0.3,0,0.6-0.1,0.9-0.1L10.3,18.4L10.3,18.4z M10.4,15.7c-1.3,0.2-2.7,0.2-4-0.1
			c0.2-1.1,0.8-2.4,1.1-3c0.9,0.2,1.7,0.2,2.6-0.1C10.4,13.5,10.5,14.6,10.4,15.7z M15,17c-1,0.5-2.2,0.9-3.3,1.2
			c-0.3,0.1-0.7,0.2-1,0.2v-1.3c1.7-0.3,3.1-0.7,4.4-1.3V17z M13,11.1L13,11.1L13,11.1L13,11.1c0.4-0.3,1-0.6,1.5-0.9
			c0.9,0.3,2.9,1.3,3.3,2.4c-0.9,0.5-1.8,1-2.8,1.6C14.7,13.5,14,12,13,11.1z M17.9,15.3c-0.9,0.5-1.7,1-2.7,1.5v-1.1
			c0.3-0.2,0.7-0.3,1-0.5c0.5-0.3,1.1-0.6,1.7-0.9V15.3z M14.6,10.1c0.7-0.3,1.3-0.8,2-1.2c1.5,0.2,3.1,0.6,4.1,1.7
			c-0.8,0.9-1.8,1.4-2.8,2C17.5,11.4,15.6,10.5,14.6,10.1z M21,13c-0.7,1-2,1.7-2.9,2.2v-1.1c0.4-0.3,0.8-0.5,1.2-0.8
			c0.5-0.3,1.1-0.7,1.7-1.1V13z M21.6,11.6c-0.1,0.3-0.2,0.7-0.3,1V12C21.4,11.9,21.4,11.8,21.6,11.6L21.6,11.6z M21.6,8.9
			c-0.1,0.5-0.3,1.1-0.7,1.5c-1-1-2.6-1.5-4-1.7c0.3-0.3,0.7-0.7,0.8-1.2c0.9,0,2.7,0.2,3.9,0.8C21.6,8.6,21.6,8.8,21.6,8.9z"
        />
      </g>
    </g>
  </Icon>
);

export default StadiumFootballIcon;
