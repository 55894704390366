import { ENTITIES } from '../utils';
import { onScopeResultsDelete } from './delete';
import { onScopeResultsPatch } from './patch';
import { onScopeResultsPost } from './post';

export function onHandleUpdateScopeResults({
  msg,
  apiClient,
}: {
  msg: any;
  apiClient: any;
}) {
  const { entity, method } = msg;

  if (entity !== ENTITIES.SCOPE_RESULTS) return;

  if (method === 'POST') {
    onScopeResultsPost({ msg, apiClient });
  } else if (method === 'PATCH') {
    onScopeResultsPatch({ msg, apiClient });
  } else if (method === 'DELETE') {
    onScopeResultsDelete({ msg, apiClient });
  } else {
    console.log('Unknown method', method);
    return;
  }
}
