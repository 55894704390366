import { Fetch } from '../../fetch';

export interface FetchSports {
  apiBaseUrl: string;
  customerUUID: string;
  token: string;
}

export const fetchSports = async ({
  apiBaseUrl,
  token,
  customerUUID,
}: FetchSports) => {
  const json = await Fetch({
    url: `${apiBaseUrl}/sports`,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });

  return json?.data;
};
