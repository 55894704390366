import { Skeleton } from '../../chakra';
import * as S from './AthleteHero.styled';

import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';

export const AthleteHero = ({
  athlete,
  isLoading,
}: {
  athlete?: any;
  isLoading?: boolean;
}) => {
  const currentTeam = athlete?.teams?.find((team: any) => team.active);

  const ownedByTeam = athlete?.teams?.find(
    (team: any) => team.contractTypeId === 8,
  );

  if (isLoading) return <Skeleton h={150} />;

  return (
    <div data-component-id={COMPONENT_IDS.ATHLETE_HERO}>
      <S.Container $teamBackgroundColor={currentTeam?.primaryColor}>
        <S.Hero>
          <S.HeroContent>
            {athlete?.profilePicture?.url && (
              <S.ProfileImageWrapper
                $teamBackgroundColor={currentTeam?.primaryColor}
                $teamTextColor={currentTeam?.textColor}
                data-element-id={ELEMENT_IDS.IMAGE}
              >
                <S.ProfileImage src={athlete?.profilePicture?.url} alt="" />
              </S.ProfileImageWrapper>
            )}

            <S.Bio>
              <S.Name as="h1" $teamTextColor={currentTeam?.textColor}>
                {athlete?.name}
              </S.Name>

              {(currentTeam || ownedByTeam) && (
                <S.Team>
                  {currentTeam.logo && (
                    <S.LogoWrapper
                      data-element-id={ELEMENT_IDS.IMAGE}
                      $teamBackgroundColor={currentTeam?.primaryColor}
                    >
                      <S.TeamLogo
                        src={currentTeam.logo.url}
                        alt={currentTeam.name}
                      />
                    </S.LogoWrapper>
                  )}
                  <S.Names>
                    {currentTeam && (
                      <S.CurrentTeamName
                        $teamTextColor={currentTeam?.textColor}
                      >
                        {currentTeam.name}
                      </S.CurrentTeamName>
                    )}
                    {ownedByTeam && (
                      <S.OwnedByTeam $teamTextColor={currentTeam?.textColor}>
                        {`(På lån fra ${ownedByTeam?.name})`}
                      </S.OwnedByTeam>
                    )}
                  </S.Names>
                </S.Team>
              )}
            </S.Bio>
          </S.HeroContent>
        </S.Hero>
      </S.Container>
    </div>
  );
};

export default AthleteHero;
