import { QUERY_KEYS, SPORT_GROUP } from '@ntb-sport/constants';
import { useContext, useEffect, useState } from 'react';
import { SocketContext, SocketContextType } from './SocketProvider';

interface SocketEvent {
  path: string;
  pathEntity: {
    entityType: string;
    path: string;
    pathKeys: {
      eventUuid?: string;
      scopeUuid?: string;
    };
  };
}

enum ENTITY_TYPE {
  EVENT = 'EVENT',
  SCOPE = 'SCOPE',
  TOUR = 'TOUR',
  COUNTRY = 'COUNTRY',
}

async function invalidateQueries({
  apiClient,
  event,
}: {
  apiClient: any;
  event: SocketEvent;
}) {
  const enityType = event?.pathEntity?.entityType?.toUpperCase();

  if (enityType === ENTITY_TYPE.EVENT) {
    let queryKey = QUERY_KEYS.EVENT;
    if (event?.path?.includes('results')) {
      queryKey = QUERY_KEYS.SCOPE_RESULTS;
    } else if (event?.path?.includes('participants')) {
      queryKey = QUERY_KEYS.EVENT_PARTICIPANTS;
    } else if (event?.path?.includes('scopes')) {
      queryKey = QUERY_KEYS.SCOPES;
    }

    const eventUUID = event?.pathEntity?.pathKeys?.eventUuid;

    return await apiClient.invalidateQueries({
      queryKey: [queryKey, { eventUUID: eventUUID }],
      refetchType: 'active',
    });
  } else if (enityType === ENTITY_TYPE.SCOPE) {
    let queryKey = QUERY_KEYS.SCOPES;

    if (event?.path?.includes('results')) {
      queryKey = QUERY_KEYS.SCOPE_RESULTS;
    } else if (event?.path?.includes('participants')) {
      queryKey = QUERY_KEYS.EVENT_PARTICIPANTS;
    }
    const scopeUUID = event?.pathEntity?.pathKeys?.scopeUuid;

    return await apiClient.invalidateQueries({
      queryKey: [queryKey, { scopeUUID: scopeUUID }],
      refetchType: 'active',
    });
  } else {
    return;
  }
}

export const useSportEditSocketHandleRooms = ({
  enabled,
  roomId,
}: {
  enabled: boolean;
  roomId: string;
}) => {
  const { sockets } = useContext<SocketContextType>(SocketContext);

  useEffect(() => {
    if (sockets?.sportEditSocket?.isConnected && enabled && roomId) {
      sockets?.sportEditSocket?.joinRoom(roomId);
    }

    return () => {
      sockets?.sportEditSocket?.leaveRoom(roomId);
    };
  }, [sockets?.sportEditSocket?.isConnected, roomId, enabled]);
};

interface SportEditSocketProps {
  baseUrl: string | undefined;
  customerId: string;
  token: string;
  apiClient: any;
}

export const useSportEditSocket = ({
  baseUrl,
  customerId,
  token,
  apiClient,
}: SportEditSocketProps) => {
  const [socket, setSocket] = useState<any>();

  useEffect(() => {
    if (customerId && token) {
      const newSportEditSocket = new SportEditSocket({
        socketBaseUrl: baseUrl,
        customerId,
        token,
        apiClient,
      });

      setSocket(newSportEditSocket);
    }
  }, [customerId, token]);

  return socket;
};

export class SportEditSocket {
  socket: any;
  isConnected: boolean = false;
  roomIds: string[];

  constructor({
    socketBaseUrl,
    customerId,
    token,
    apiClient,
  }: {
    socketBaseUrl: string | undefined;
    customerId: string;
    token: string;
    apiClient: any;
  }) {
    this.roomIds = [];
    this.socket = null;

    this.connect(socketBaseUrl, customerId, token, apiClient);
  }

  connect(
    socketBaseUrl: string | undefined,
    customerId: string,
    token: string,
    apiClient: any,
  ) {
    this.socket = new WebSocket(
      `${socketBaseUrl}?customerId=${customerId}&customerToken=${token}`,
    );

    this.socket.addEventListener('open', (event: any) => {
      this.isConnected = true;
      console.log(`%c SportEditSocket connected.`, 'color: green');
    });

    this.socket.addEventListener('message', (event: any) => {
      // console.log('Message from server:', JSON.parse(event.data));
      invalidateQueries({ apiClient, event: JSON.parse(event.data) });
    });

    this.socket.addEventListener('close', (event: any) => {
      this.isConnected = false;

      console.log(`%c SportEditSocket disconnected.`, 'color: red');
      setTimeout(() => {
        this.connect(socketBaseUrl, customerId, token, apiClient);

        this.roomIds.forEach((roomId) => {
          this.joinRoom(roomId);
        });
      }, 1000);
    });
  }

  joinRoom(roomId: string) {
    if (!this.roomIds.includes(roomId)) {
      console.log(`%c SportEditSocket joined room ${roomId}.`, 'color: green');
      this.socket.send(
        JSON.stringify({
          action: 'join',
          data: {
            roomId,
          },
        }),
      );

      this.roomIds.push(roomId);
    }
  }

  leaveRoom(roomId: string) {
    if (this.roomIds.includes(roomId)) {
      console.log(`%c SportEditSocket left room ${roomId}.`, 'color: red');
      this.socket.send(
        JSON.stringify({
          action: 'leave',
          data: {
            roomId,
          },
        }),
      );

      this.roomIds = this.roomIds.filter((id) => id !== roomId);
    }
  }
}
