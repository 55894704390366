import React from 'react';

import { useField } from 'formik';
import {
  FormControl,
  FormLabel,
  HStack,
  RadioGroup,
  Radio,
} from '../../chakra';

interface Option {
  value: string;
  label: string;
}

interface RadioGroupProps {
  defaultValue: string;
  groupLabel: string;
  name: string;
  options: Option[];
}

export const RadioGroupInput: React.FC<RadioGroupProps> = ({
  defaultValue,
  groupLabel,
  name,
  options = [],
}) => {
  const [field] = useField(name);

  return (
    <FormControl as="fieldset">
      <FormLabel role="group" as="legend">
        {groupLabel}
      </FormLabel>
      <RadioGroup defaultValue={defaultValue || ''} id={`${name}-radio-group`}>
        <HStack spacing="var(--radio-button-spacing)">
          {options.map(({ value, label }) => (
            <Radio {...field} key={value} value={value}>
              {label}
            </Radio>
          ))}
        </HStack>
      </RadioGroup>
    </FormControl>
  );
};
