import { useQuery } from '@tanstack/react-query';
import { fetchNifsCountries, FetchNifsCountries } from './fetch';
import { QUERY_KEYS } from '@ntb-sport/constants';

interface UseQueryNifsCountries {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: FetchNifsCountries;
  options?: Record<string, unknown>;
}

export const useQueryNifsCountries = ({
  queryKey = QUERY_KEYS.NIFS_COUNTRIES,
  queryKeyParams,
  params,
  options,
}: UseQueryNifsCountries) => {
  const { data, isLoading, error } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchNifsCountries(params),
    ...options,
  });

  return { data, isLoading, error };
};
