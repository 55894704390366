import styled from 'styled-components';

import { MEDIA_QUERY } from '@ntb-sport/constants';

interface StyledProps {
  $tournamentId?: number;
  $homeTeam?: boolean;
  $awayTeam?: boolean;
}

export const ResultWrapper = styled.div`
  grid-area: result;
`;

export const MatchStatus = styled.div`
  grid-area: status;
  text-align: center;

  font-size: var(--ntb-fontSizes-sm);
  margin-bottom: var(--ntb-space-2);
`;

export const Result = styled.div`
  display: grid;
  grid-template-areas:
    'status status status'
    'homescore divider awayscore'
    'eeo eeo eeo'
    'additionalscore additionalscore additionalscore';
  grid-template-columns: 1fr max-content 1fr;
  justify-items: center;
  align-items: center;
`;

export const Score = styled.div<StyledProps>`
  grid-area: ${(props) => (props?.$homeTeam ? 'homescore' : 'awayscore')};
  justify-self: ${(props) => (props?.$homeTeam ? 'end' : 'start')};
  font-weight: var(--ntb-fontWeights-semibold);
  font-size: var(--ntb-fontSizes-2xl);

  @container team-match-result-container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-3xl);
  }

  @container team-match-result-container ${MEDIA_QUERY.DESKTOP} {
    font-size: var(--ntb-fontSizes-4xl);
  }
`;

export const DividerWrapper = styled.div<StyledProps>`
  grid-area: divider;
  align-self: center;
`;

export const NotStarted = styled.div<StyledProps>`
  font-size: var(--ntb-fontSizes-2xl);
`;

export const ScoreDivider = styled.div`
  font-weight: var(--ntb-fontWeights-semibold);
  padding: 0 var(--ntb-space-2);
  font-size: var(--ntb-fontSizes-lg);

  @container team-match-result-container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-2xl);
  }
`;

export const ExtraTime = styled.div`
  grid-area: eeo;

  text-align: center;
  margin-bottom: var(--ntb-space-1);
  font-size: var(--ntb-fontSizes-xs);

  @container team-match-result-container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const AdditionalScores = styled.div`
  grid-area: additionalscore;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--ntb-space-1);
`;

export const AdditionalScore = styled.div`
  font-size: var(--ntb-fontSizes-xs);

  @container team-match-result-container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const PeriodResults = styled.div`
  padding: 6px 0;
`;

export const CombinedResult = styled.div`
  font-weight: var(--ntb-fontWeights-semibold);
  font-size: var(--ntb-fontSizes-xs);

  @container team-match-result-container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;
