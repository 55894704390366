import { Button as ChakraButton } from '@chakra-ui/react';
import { ELEMENT_IDS } from '@ntb-sport/constants';
import { forwardRef } from 'react';

export const Button = forwardRef(function Button(props: any, ref: any) {
  return (
    <ChakraButton {...props} ref={ref} data-element-id={ELEMENT_IDS.BUTTON}>
      {props.children}
    </ChakraButton>
  );
});

export default Button;
