import styled from 'styled-components';
import { MEDIA_QUERY } from '@ntb-sport/constants';

export const ContainerQuery = styled.div`
  container-type: inline-size;
  container-name: container;
  background-color: var(--background-color);
  color: var(--font-color);
`;

export const Section = styled.div`
  width: 100%;
  background-color: var(--background-color);
  color: var(--font-color);
  padding: var(--ntb-space-2) 0;
  box-shadow: inset 0px -1px 0px var(--border-color);

  &:last-child {
    box-shadow: none;
  }
`;

export const SectionTitle = styled.span`
  display: block;
  text-align: center;
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-medium);
  padding: var(--ntb-space-1);
  border-radius: var(--ntb-radii-md);

  &::first-letter {
    text-transform: capitalize;
  }

  @container container ${MEDIA_QUERY.LAPTOP} {
    font-size: var(--ntb-fontSizes-md);
  }
`;

export const Matches = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: var(--background-color);
  color: var(--font-color);
`;

export const NoResult = styled.p`
  padding: var(--ntb-space-2);
  font-size: var(--ntb-fontSizes-sm);
`;
