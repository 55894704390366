import { MEDIA_QUERY } from '@ntb-sport/constants';

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  container-type: inline-size;
  container-name: container;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--ntb-space-4);
  padding-bottom: 0;
  column-gap: var(--ntb-space-4);
  color: var(--font-color);
  box-shadow: var(--border-color) 0px -1px 0px inset;
  background-color: var(--background-color);
`;

export const LogoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background-color: var(--logo-background-color);
  border-style: solid;
  border-width: 2px;
  border-color: var(--logo-border-color);
  border-radius: var(--ntb-radii-full);
  padding: var(--ntb-space-1);

  @container container ${MEDIA_QUERY.LAPTOP} {
    width: 100px;
    height: 100px;
    padding: var(--ntb-space-2);
  }
`;

export const Logo = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
`;

export const Info = styled.div<{ $showLogo: boolean }>`
  margin-left: ${(props) =>
    props.$showLogo ? 'var(--ntb-space-4)' : 'var(--ntb-space-1)'};
  display: flex;
  flex-direction: column;
  row-gap: var(--ntb-space-0.5);
`;

export const Name = styled.h2`
  font-size: var(--ntb-fontSizes-2xl);
  font-weight: var(--ntb-fontWeights-semibold);

  @container container ${MEDIA_QUERY.LAPTOP} {
    font-size: var(--ntb-fontSizes-3xl);
  }
`;

export const Country = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);

  @container container ${MEDIA_QUERY.LAPTOP} {
    column-gap: var(--ntb-space-2);
  }

  img {
    min-width: var(--ntb-space-5);
    max-width: var(--ntb-space-5);

    @container container ${MEDIA_QUERY.LAPTOP} {
      min-width: var(--ntb-space-6);
      max-width: var(--ntb-space-6);
    }
  }
`;

export const CountryName = styled.div`
  font-size: var(--ntb-fontSizes-md);

  @container container ${MEDIA_QUERY.LAPTOP} {
    font-size: var(--ntb-fontSizes-lg);
  }
`;
