import * as S from './AudioAnimation.styled';

export const AudioAnimation = () => {
  return (
    <S.AudioAnimation>
      <S.AudioAnimationBarOne></S.AudioAnimationBarOne>
      <S.AudioAnimationBarTwo></S.AudioAnimationBarTwo>
      <S.AudioAnimationBarThree></S.AudioAnimationBarThree>
      <S.AudioAnimationBarFour></S.AudioAnimationBarFour>
      <S.AudioAnimationBarFive></S.AudioAnimationBarFive>
      <S.AudioAnimationBarSix></S.AudioAnimationBarSix>
    </S.AudioAnimation>
  );
};

export default AudioAnimation;
