import { getAlpha2Code } from './languages';
import * as S from './CountryFlag.styled';
export interface CountryFlagProps {
  countryCode?: string;
  countryName?: string;
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const CountryFlag = ({
  countryCode,
  countryName,
  className,
  size,
}: CountryFlagProps) => {
  const code = getAlpha2Code(countryName as string, 'nb') || countryCode;

  if (!code) return null;

  return (
    <S.CountryFlag
      aria-hidden={true}
      className={className}
      src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.5/flags/4x3/${code.toLowerCase()}.svg`}
      alt={countryName}
      $size={size}
    />
  );
};

export default CountryFlag;
