export const getEventTime = (event: any): string => {
  const normalizeSeconds =
    event.sportId !== 1
      ? event?.seconds?.toString().length === 1
        ? `0${event?.seconds}`
        : event?.seconds
      : null;

  const time =
    (event?.timeRounded || event?.time === 0 ? event?.time + 1 : event?.time) ??
    '';

  if (!time) {
    return '';
  }

  return (
    time +
    (event.hasOwnProperty('overtimeRounded')
      ? !event?.overtimeRounded || !event?.overtime
      : !event?.overtime &&
        !normalizeSeconds &&
        event?.overtimeRounded !== 1 &&
        `'`) +
    (event?.overtimeRounded || event?.overtime
      ? `+${event?.overtimeRounded || event?.overtime}'`
      : '') +
    (normalizeSeconds ? `:${normalizeSeconds}` : '')
  );
};
