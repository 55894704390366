import Icon from '../Icon/Icon';

export const PenaltyIcon = (props: any) => (
  <Icon {...props}>
    <g>
      <g>
        <g>
          <g>
            <g>
              <path
                d="M22.3,4.7c-0.1-0.9-0.5-1.7-1.1-2.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.5-1.3-0.7-2.1-0.7h-15
      C3,1.4,2.3,1.7,1.7,2.1c0,0.1-0.1,0.2-0.3,0.3c-0.5,0.5-1,1.4-1,2.3c0,0.1,0,0.2,0,0.3V14h1.5l1.7-1.7L5,13.7
      c0-0.1,0.1-0.3,0.2-0.4l0,0L4,12.1l1.5-1.8l0.9,0.9C6.4,11.1,6.5,11,6.5,11s0,0,0.1-0.1L5.7,10l1.7-1.6l1.2,1.2
      c0.1-0.1,0.3-0.1,0.4-0.2L7.5,8l1.8-1.6L11,8.1L9.9,9.2c0.2,0,0.5-0.1,0.6-0.1l0.7-0.7L12,9.1c0.2,0,0.5,0.1,0.6,0.1l-1.1-1.1
      l1.6-1.7l1.6,1.7l-1.4,1.4c0.1,0.1,0.3,0.1,0.4,0.2l1.2-1.2l1.6,1.7l-0.8,0.8c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.2
      l0.8-0.8l1.6,1.7l-1.2,1.2c0.1,0.1,0.1,0.3,0.2,0.4l1.4-1.4l1.7,1.7h1.5V4.9C22.4,4.9,22.3,4.8,22.3,4.7z M18.9,2.6
      c0.5,0,0.9,0.1,1.2,0.4l-0.9,0.9l-1.3-1.3H18.9z M20.9,6.2l-1.6,1.7l-1.6-1.7l1.6-1.6L20.9,6.2z M17.4,2.6L19,4.1l-1.6,1.6
      l-1.6-1.6l1.6-1.6C17.3,2.6,17.4,2.6,17.4,2.6z M16.5,2.6l-1.3,1.3L14,2.6H16.5z M12.6,2.6l-1.3,1.3L10,2.6H12.6z M8.6,2.6
      L7.4,3.9L6.2,2.6H8.6z M5.6,2.6l1.6,1.6L5.4,5.8L3.8,4.1l1.6-1.6C5.3,2.6,5.6,2.6,5.6,2.6z M5.2,6.2L3.5,7.8L1.8,6.2l1.6-1.6
      L5.2,6.2z M3.8,2.6h0.9L3.5,3.9L2.6,2.9C3,2.8,3.4,2.6,3.8,2.6z M1.5,4.9c0-0.6,0.3-1.3,0.7-1.7L3.1,4L1.5,5.8V4.9z M1.5,6.4
      l1.6,1.7L1.5,9.7C1.5,9.7,1.5,6.4,1.5,6.4z M1.5,13.7v-3.4l1.6,1.6L1.5,13.7z M3.5,11.7l-1.6-1.6l1.6-1.7l1.6,1.7L3.5,11.7z
       M5.4,9.7L3.8,8.1l1.6-1.6l1.7,1.6L5.4,9.7z M7.4,7.8L5.7,6.2l1.7-1.6L9,6.2L7.4,7.8z M7.7,4.1l1.6-1.6h0.3l1.6,1.6L9.4,5.8
      L7.7,4.1z M11.3,7.8L9.6,6.2l1.6-1.6L13,6.2L11.3,7.8z M11.7,4.1l1.6-1.6h0.3l1.6,1.6l-1.6,1.6L11.7,4.1z M13.6,6.2l1.6-1.6
      l1.6,1.6l-1.6,1.7L13.6,6.2z M15.5,8.1l1.6-1.7l1.6,1.7l-1.6,1.6L15.5,8.1z M17.5,10l1.6-1.6l1.6,1.6l-1.6,1.6L17.5,10z
       M21.2,13.7L19.5,12l1.6-1.7L21.2,13.7L21.2,13.7z M21.2,9.7l-1.7-1.6l1.6-1.7L21.2,9.7L21.2,9.7z M21.2,5.8l-1.7-1.6l0.9-0.9
      c0.5,0.5,0.7,1,0.7,1.7L21.2,5.8L21.2,5.8z"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            d="M11.4,10.4c-3,0-5.5,2.4-5.5,5.3c0,3,2.5,5.5,5.5,5.5s5.5-2.5,5.5-5.5C16.8,12.8,14.3,10.4,11.4,10.4z
   M15.3,12.6c0.6,0.7,0.8,1.6,0.9,2.1h-0.1c-0.1-0.4-0.4-1.2-0.9-1.7C15.3,12.9,15.3,12.8,15.3,12.6z M12.7,10.9
  C12.7,10.9,12.7,11,12.7,10.9C12.7,11,12.7,11,12.7,10.9C12.7,11,12.7,11,12.7,10.9C12.7,11,12.7,11,12.7,10.9
  C12.7,11,12.7,11,12.7,10.9c1.2,0.3,1.8,0.9,1.8,0.9l0,0l0,0l0,0c0.4,0.3,0.4,0.6,0.3,0.9c-0.3-0.1-0.8-0.2-1.4,0
  c-0.3-0.4-1-0.9-1.6-1.1c0-0.2,0-0.5-0.1-0.6C12.2,10.9,12.6,10.9,12.7,10.9z M8.2,12.1L8.2,12.1c0.1-0.2,0.2-0.4,0.3-0.5l0,0
  C9,11.1,10.1,11,10.1,11l0,0l0,0c0.6-0.1,1,0,1.2,0.1c0,0.2,0.1,0.5,0.1,0.6c-0.6,0.1-1.3,0.6-1.7,0.9c-0.3-0.1-0.9-0.3-1.6,0
  C8.1,12.4,8.2,12.2,8.2,12.1z M8.2,13.1c0.6-0.3,1.3-0.1,1.5,0c0.1,0.2,0.2,0.6,0.6,1.6c-0.2,0.2-0.4,0.6-0.6,0.9
  c-0.2,0.4-0.4,0.6-0.5,0.9c-0.6,0-1.3,0-1.5-0.1c-0.6-0.6-0.6-1.6-0.6-1.6C7.3,13.7,8.1,13.2,8.2,13.1L8.2,13.1z M6.4,14.4
  c0-0.4,0.6-1.2,0.8-1.6c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0,0.2,0,0.4,0.1,0.5c-0.3,0.2-0.9,0.7-1.1,1.7
  C6.5,14.5,6.5,14.4,6.4,14.4L6.4,14.4L6.4,14.4z M6.4,17c-0.2-0.7-0.2-1.5-0.2-1.6c0-0.1,0-0.1,0-0.2l0,0l0,0
  C6.3,15,6.3,15,6.3,15C6.4,15,6.5,15,6.6,15c0,0.4,0.2,1.2,0.6,1.7c-0.2,0.6-0.1,1.2-0.1,1.6c-0.1,0-0.2,0-0.4,0
  C6.8,18.3,6.6,17.9,6.4,17z M8.9,20.2c-0.2-0.1-0.4-0.2-0.6-0.4c-0.6-0.4-0.9-0.8-1.1-1.1c0.1,0,0.2,0,0.2,0c0.6,0.7,1.4,1,1.7,1
  c0.1,0.2,0.2,0.6,0.5,0.8C9.4,20.5,9.2,20.4,8.9,20.2z M12.4,20.5L12.4,20.5L12.4,20.5c-0.7,0.3-1.9,0-2.1,0l0,0l0,0
  c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.1-0.2-0.3-0.2-0.4c0.3-0.2,0.6-0.5,0.8-0.8c0.4,0.1,0.6,0.1,1,0.1c0.5,0,0.9-0.1,1.1-0.1
  c0.4,0.5,0.6,0.7,0.7,0.9C12.9,20.4,12.4,20.5,12.4,20.5z M13.3,17.3c-0.1,0.3-0.3,0.5-0.5,0.7c-0.1,0.1-0.2,0.2-0.2,0.3l0,0
  c-0.2,0.1-1.2,0.3-2,0c-0.2-0.3-0.4-0.5-0.6-0.8c-0.1-0.2-0.2-0.3-0.3-0.5c0-0.1-0.2-0.3-0.2-0.4c0.2-0.3,0.4-0.6,0.6-1
  c0.2-0.4,0.4-0.6,0.5-0.7l0,0c0.6,0,1.3-0.1,1.9,0.1c0.4,0.5,0.9,1.3,1,1.6c0,0,0,0,0,0.1C13.4,17,13.4,17.2,13.3,17.3z
   M13.7,20.2c-0.1,0.1-0.2,0.1-0.3,0.2c0.1-0.1,0.3-0.3,0.4-0.5c0.2,0,0.4,0,0.6-0.1c0.1,0,0.2-0.1,0.2-0.1
  C14.2,19.9,14,20.1,13.7,20.2z M15.2,16.5c-0.2,0-0.6,0-1.3,0c-0.2-0.6-0.7-1.4-1-1.6c0.2-0.6,0.5-1.3,0.6-1.6
  c0.6-0.2,1.3,0,1.4,0c0.6,0.5,0.9,1.6,0.9,1.6l0,0C15.9,15.9,15.4,16.4,15.2,16.5z M15.6,18.4c0.1-0.3,0.3-1,0-1.6
  c0.3-0.3,0.6-0.8,0.6-1.6h0.1C16.7,16.5,15.9,18.1,15.6,18.4L15.6,18.4z"
          />
        </g>
      </g>
    </g>
  </Icon>
);
