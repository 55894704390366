import io from 'socket.io-client';
import { useContext, useEffect, useState } from 'react';
import { QUERY_KEYS } from '@ntb-sport/constants';
import { SPORT_GROUP } from '@ntb-sport/constants';
import { SocketContext, SocketContextType } from './SocketProvider';

import { onHandleUpdateEvents } from './helpers/events';
import { onHandleUpdateScopes } from './helpers/scopes';
import { ENTITIES, updateObjectInArray } from './helpers/utils';
import { onHandleUpdateEventParticipants } from './helpers/eventParticipants';
import { onHandleUpdateScopeResults } from './helpers/scopeResults';

interface NeoSocketProps {
  baseUrl: string;
  sportGroup: string;
  customerId: string;
  token: string;
  apiClient: any;
}

export const useNeoSocket = ({
  baseUrl,
  sportGroup,
  customerId,
  token,
  apiClient,
}: NeoSocketProps) => {
  const [socket, setSocket] = useState<any>();

  useEffect(() => {
    if (customerId && token && sportGroup !== SPORT_GROUP.CYCLING) {
      const neoSocket = new NeoSocket({
        socketBaseUrl: `${baseUrl}/?customerId=${customerId}&token=${token}`,
        apiClient,
      });
      setSocket(neoSocket);
    }
  }, [customerId, token, sportGroup]);

  return socket;
};

export const useSocketJoinEvent = ({
  eventId,
  enabled,
}: {
  eventId: string;
  enabled: boolean;
}) => {
  const { sockets } = useContext<SocketContextType>(SocketContext);

  useEffect(() => {
    if (enabled && sockets?.neoSocket?.isConnected) {
      sockets?.neoSocket?.joinEvent({ eventId });

      return () => {
        sockets?.neoSocket?.leaveEvent({ eventId });
      };
    }
    return;
  }, [enabled, eventId, sockets?.neoSocket?.isConnected]);
};

export const useSocketJoinScope = ({
  scopeId,
  enabled,
}: {
  scopeId: string;
  enabled: boolean;
}) => {
  const {
    sockets: { neoSocket },
  } = useContext<SocketContextType>(SocketContext);
  useEffect(() => {
    if (enabled && neoSocket?.isConnected) {
      neoSocket?.joinScope({ scopeId });

      return () => {
        neoSocket?.leaveScope({ scopeId });
      };
    }
    return;
  }, [enabled, scopeId, neoSocket?.isConnected]);
};

export class NeoSocket {
  socket: any;
  eventIds: any;
  isConnected: boolean;

  constructor({
    socketBaseUrl,
    apiClient,
  }: {
    socketBaseUrl: string;
    apiClient: any;
  }) {
    this.socket = io(socketBaseUrl, {
      autoConnect: true,
      withCredentials: true,
      transports: ['websocket'],
    });
    this.isConnected = false;
    this.eventIds = [];

    this.onConnect(apiClient);
    this.onDisconnect();
  }

  connect() {
    this.socket.connect();
  }

  disconnect() {
    this.socket.disconnect();
  }

  joinAll() {
    this.socket.emit('join all');
  }

  joinScope({ scopeId }: { scopeId: string }) {
    this.socket.emit('join scope', scopeId);
    console.log(`%c Neosocket joined scope ${scopeId}.`, 'color: green');
  }

  leaveScope({ scopeId }: { scopeId: string }) {
    this.socket.emit('leave scope', scopeId);
    console.log(`%c Neosocket left scope ${scopeId}.`, 'color: red');
  }

  joinEvent({ eventId }: { eventId: string }) {
    if (!this.eventIds?.includes(eventId)) {
      this.eventIds.push(eventId);

      this.socket.emit('join event', eventId);
      console.log(`%c Neosocket joined event ${eventId}.`, 'color: green');
    }
  }

  leaveEvent({ eventId }: { eventId: string }) {
    this.eventIds = [];
    this.socket.emit('leave event', eventId);
    console.log(`%c Neosocket left event ${eventId}.`, 'color: red');
  }

  onConnect(apiClient: any) {
    this.socket.on('connect', () => {
      console.log(`%c Neosocket connected.`, 'color: green');
      this.isConnected = true;
      if (this.eventIds?.length) {
        this.eventIds.forEach((eventId: any) => {
          this.joinEvent({ eventId });
        });
      }

      this.onUpdateEvent({ apiClient });
      this.onUpdateScope({ apiClient });
    });
  }

  onDisconnect() {
    this.socket.on('disconnect', () => {
      this.isConnected = false;
      console.log(`%c Neosocket disconnected.`, 'color: red');
    });
  }

  onUpdateScope({ apiClient }: { apiClient: any }) {
    this.socket.on('join scope', (msg: any) => {
      const { entity, method, entityId, data, additionalData } = msg;
      if (entity === ENTITIES.SCOPE_RESULTS) {
        if (method === 'POST') {
          const state = apiClient.getQueryData([
            QUERY_KEYS.SCOPE_RESULTS,
            { scopeUUID: additionalData.scope.uuid },
          ]);

          const eventParticipantsState = apiClient.getQueryData([
            QUERY_KEYS.EVENT_PARTICIPANTS,
            { eventUUID: additionalData.event.uuid },
          ]);

          if (!state || !eventParticipantsState) return; //TODO: Remove this when join scope channel is implemented

          const participant = eventParticipantsState?.find(
            (participant: any) =>
              participant?.uuid === additionalData.eventParticipant.uuid,
          );

          const newState = [
            ...state,
            {
              uuid: entityId,
              isHighlighted: true,
              participant,
              ...data,
            },
          ];

          apiClient.setQueryData(
            [
              QUERY_KEYS.SCOPE_RESULTS,
              { scopeUUID: additionalData.scope.uuid },
            ],
            newState,
          );

          if (data?.shotSeries) {
            const allShotSeriesState = apiClient.getQueryData([
              QUERY_KEYS.ALL_SHOT_SERIES,
              { eventUUID: additionalData.event.uuid },
            ]);

            const shotSeriesEntityIndex = allShotSeriesState?.findIndex(
              (shotSeries: any) =>
                shotSeries?.eventParticipantUUID ===
                  additionalData.eventParticipant.uuid &&
                shotSeries?.scopeUUID === additionalData?.scope.uuid,
            );

            const newShotSeriesState =
              shotSeriesEntityIndex === -1
                ? [
                    ...allShotSeriesState,
                    {
                      eventParticipantUUID:
                        additionalData.eventParticipant.uuid,
                      scopeUUID: additionalData?.scope.uuid,
                      ...data.shotSeries,
                    },
                  ]
                : updateObjectInArray(allShotSeriesState, {
                    index: shotSeriesEntityIndex,
                    item: {
                      ...allShotSeriesState[shotSeriesEntityIndex],
                      ...data.shotSeries,
                    },
                  });

            apiClient.setQueryData(
              [
                QUERY_KEYS.ALL_SHOT_SERIES,
                { eventUUID: additionalData.event.uuid },
              ],
              newShotSeriesState,
            );
          }
        }
        if (method === 'PATCH') {
          const state = apiClient.getQueryData([
            QUERY_KEYS.SCOPE_RESULTS,
            { scopeUUID: additionalData.scope.uuid },
          ]);

          if (!state) return; //TODO: Remove this when join scope channel is implemented

          const entityIndex = state?.findIndex(
            (scopeResult: any) => scopeResult?.uuid === entityId,
          );

          const updatedObject = data?.reduce((acc: any, item: any) => {
            const key = item?.path.substr(
              item.path.lastIndexOf('/') + 1,
              item.path.length,
            );

            const value = item.value;

            if (!acc.key) {
              acc[key] = value;
            }

            return acc;
          }, {});

          const newState = updateObjectInArray(state, {
            index: entityIndex,
            item: {
              ...state[entityIndex],
              ...updatedObject,
            },
          });

          apiClient.setQueryData(
            [
              QUERY_KEYS.SCOPE_RESULTS,
              { scopeUUID: additionalData.scope.uuid },
            ],
            newState,
          );
        }

        if (method === 'DELETE') {
          const state = apiClient.getQueryData([
            QUERY_KEYS.SCOPE_RESULTS,
            { scopeUUID: additionalData.scope.uuid },
          ]);

          if (!state) return; //TODO: Remove this when join scope channel is implemented

          const newState = state?.filter(
            (scopeResult: any) => scopeResult.uuid !== entityId,
          );

          apiClient.setQueryData(
            [
              QUERY_KEYS.SCOPE_RESULTS,
              { scopeUUID: additionalData.scope.uuid },
            ],
            newState,
          );
        }
      }
    });
  }

  onUpdateEvent({ apiClient }: { apiClient: any }) {
    this.socket.on('join event', (msg: any) => {
      onHandleUpdateEvents({ msg, apiClient });
      onHandleUpdateScopes({ msg, apiClient });
      onHandleUpdateEventParticipants({ msg, apiClient });
      onHandleUpdateScopeResults({ msg, apiClient });
    });
  }
}
