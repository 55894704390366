import { ELEMENT_IDS } from '@ntb-sport/constants';
import * as S from './Summary.styled';

interface SummaryProps {
  highlightedEvents: any;
  onClick: ({ src }: { src: string }) => void;
}

export const Summary = ({ highlightedEvents, onClick }: SummaryProps) => {
  return (
    <S.Container
      $hasHighlightVideo={highlightedEvents?.matchHighlightVideo}
      data-element-id={ELEMENT_IDS.SUMMARY}
    >
      {highlightedEvents?.summary?.comment}
      {highlightedEvents?.matchHighlightVideo && (
        <S.PlayerPreview
          $title={`Sammendrag`}
          src={highlightedEvents?.matchHighlightVideo?.imageURL}
          onClick={() =>
            onClick({
              src: highlightedEvents?.matchHighlightVideo?.url,
            })
          }
        >
          <S.PlayIcon>
            <svg
              viewBox="0 0 24 24"
              width="1.500em"
              height="1.500em"
              aria-hidden="true"
              focusable="false"
            >
              <path
                fill="currentColor"
                d="M6 2h8l5.5 10L14 22H6l5.5-10L6 2z"
              ></path>
            </svg>
          </S.PlayIcon>
        </S.PlayerPreview>
      )}
    </S.Container>
  );
};
