import styled from 'styled-components';
import { MEDIA_QUERY } from '@ntb-sport/constants';

export const ContainerQuery = styled.div`
  container-type: inline-size;
  container-name: container;
`;

export const Container = styled.div`
  background-color: var(--background-color, #fff);
  color: var(--font-color);
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--ntb-space-4) var(--ntb-space-2);
  box-shadow: inset 0px -1px 0px var(--border-color);
  gap: var(--ntb-space-2);
  background-color: var(--background-color);
  color: var(--font-color);
  margin-bottom: var(--ntb-space-2);
`;

export const StageName = styled.h2<{ $isClickable?: boolean }>`
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-medium);
  text-decoration-color: var(--base-colors-default-medium);
  text-underline-offset: var(--ntb-space-1);
  margin: 0;
  color: var(--font-color);
  &:focus {
    outline: none;
  }
  ${({ $isClickable }) =>
    $isClickable &&
    `
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  `}

  @container container ${MEDIA_QUERY.MOBILE_L} {
    font-size: var(--ntb-fontSizes-md);
  }

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-lg);
  }
`;

export const ToggleTableInfo = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: var(--ntb-fontSizes-sm);
  color: var(--font-color);
`;

export const TableInfo = styled.div`
  position: absolute;
  z-index: 999;
  background-color: var(--base-colors-whiteAlpha-1000);
  color: var(--font-color);
  left: var(--ntb-space-2);
  right: var(--ntb-space-2);
  top: var(--ntb-space-14);
  padding: var(--ntb-space-4);
  box-shadow: -2px 0 15px 0px rgba(0, 0, 0, 0.2);
  border-radius: var(--ntb-radii-md);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: var(--ntb-space-4);
  column-gap: var(--ntb-space-8);

  @container container ${MEDIA_QUERY.TABLET} {
    flex-direction: row;
    left: auto;
  }

  h3 {
    margin-bottom: var(--ntb-space-2);
    font-size: var(--ntb-fontSizes-sm);
    font-weight: var(--ntb-fontWeights-semibold);
  }
`;

export const TableDescription = styled.dl`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: var(--ntb-space-4);
  grid-row-gap: var(--ntb-space-1);
  font-size: var(--ntb-fontSizes-xs);

  > dt {
    font-weight: var(--ntb-fontWeights-medium);
  }

  dd {
    margin: 0;
  }

  dl {
    display: grid;
    grid-template-columns: 10px 1fr;
    grid-column-gap: 10px;
  }
`;

export const SortingRules = styled.div`
  h3 {
    margin-bottom: var(--ntb-space-2);
    font-size: var(--ntb-fontSizes-sm);
    font-weight: var(--ntb-fontWeights-semibold);
  }

  ol {
    margin: 0;
    padding: 0 0 0 15px;
    font-size: var(--ntb-fontSizes-xs);

    li {
      margin: 0 0 var(--ntb-space-1) 0;

      &::marker {
        font-weight: var(--ntb-fontWeights-semibold);
      }
    }
  }
`;

export const TableContainer = styled.div``;

export const Comment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: var(--ntb-space-2);
  font-size: var(--ntb-fontSizes-xs);
  word-break: break-word;
  padding: 8px 4px;

  @container container ${MEDIA_QUERY.MOBILE_L} {
    padding: 12px 6px;
  }

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const NoStandings = styled.div`
  font-size: var(--ntb-fontSizes-md);
  text-align: center;
  padding: var(--ntb-space-6);
`;
