import * as S from './Header.styled';

import { ELEMENT_IDS, SPORT_GROUP, STAGE_TYPE } from '@ntb-sport/constants';
import { Highlight } from '../../../../chakra';
import { ChevronRightIcon } from '@chakra-ui/icons';
import Gender from '../../../../common/Gender/Gender';

import { GiTrophy } from 'react-icons/gi';
import { NifsStageType } from '@ntb-sport/types';

interface HeaderProps {
  tournament: any;
  debouncedSearchQuery: string;
  sportGroup: string;
  onClickStandings: any;
}

export const Header = ({
  tournament,
  debouncedSearchQuery,
  sportGroup,
  onClickStandings,
}: HeaderProps) => {
  const stages: NifsStageType[] = Object.values(tournament.stages);
  const stage: NifsStageType = stages?.[0];
  const isLeague =
    stage?.stageTypeId === STAGE_TYPE.LEAGUE ||
    stage?.stageTypeId === STAGE_TYPE.LEAGUE_WITH_PENALTIES;

  return (
    <S.TournamentHeader data-element-id={ELEMENT_IDS.TOURNAMENT}>
      <S.TournamentHeaderSection>
        <S.LogoWrapper data-element-id={ELEMENT_IDS.LOGO}>
          {tournament?.logo ? (
            <S.Logo
              aria-hidden={true}
              src={tournament?.logo?.url}
              loading="lazy"
            />
          ) : (
            <GiTrophy />
          )}
        </S.LogoWrapper>

        <S.TournamentInfo>
          <S.TournamentNameAndGender>
            <S.TournamentName>
              <Highlight
                query={debouncedSearchQuery}
                styles={{ bg: 'orange.300' }}
              >
                {tournament.name}
              </Highlight>
            </S.TournamentName>

            <Gender gender={tournament?.gender} />
          </S.TournamentNameAndGender>
          <S.Country>
            <S.TournamentCountryFlag countryName={tournament?.country?.name} />
            {sportGroup === SPORT_GROUP.FOOTBALL && (
              <S.CountryName>
                <Highlight
                  query={debouncedSearchQuery}
                  styles={{ bg: 'orange.300' }}
                >
                  {tournament?.country?.name}
                </Highlight>
              </S.CountryName>
            )}
            {sportGroup === SPORT_GROUP.HANDBALL &&
              tournament?.country?.name !== 'FIFA' &&
              tournament?.country?.name !== 'UEFA' && (
                <S.CountryName>{tournament?.country?.name}</S.CountryName>
              )}
            {sportGroup === SPORT_GROUP.ICE_HOCKEY &&
              tournament?.country?.name !== 'FIFA' &&
              tournament?.country?.name !== 'UEFA' && (
                <S.CountryName>{tournament?.country?.name}</S.CountryName>
              )}
          </S.Country>
        </S.TournamentInfo>

        {stages?.length === 1 && isLeague && onClickStandings && (
          <S.TournamentStandings
            data-element-id={ELEMENT_IDS.BUTTON}
            aria-label={`Åpne tabell for ${tournament.name}.`}
            onClick={() =>
              onClickStandings({
                tournamentId: tournament?.id,
                seasonId: stage?.yearEnd,
                stageId: stage?.id,
              })
            }
          >
            <span>Tabell</span>
            <ChevronRightIcon />
          </S.TournamentStandings>
        )}
      </S.TournamentHeaderSection>
    </S.TournamentHeader>
  );
};
