import { ChevronDownIcon } from '@chakra-ui/icons';

import { COMPONENT_IDS } from '@ntb-sport/constants';
import {
  Button,
  MenuChakra as Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '../../chakra';
import { useBem } from '@ntb-sport/hooks';

interface SeasonStandingsMenuProps {
  activeSeasonStandingUrl: any;
  allSeasonStandings: any;
  onClick: any;
  competitionId: string;
}

export const SeasonStandingsMenu = ({
  activeSeasonStandingUrl,
  allSeasonStandings,
  onClick,
  competitionId,
}: SeasonStandingsMenuProps) => {
  const bem = useBem(COMPONENT_IDS.SEASON_STANDING);

  return (
    activeSeasonStandingUrl &&
    allSeasonStandings?.length > 1 && (
      <div className={bem('menu')} data-competition-id={competitionId}>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            size="sm"
            className={bem('menu-button')}
          >
            {
              allSeasonStandings?.find(
                (standing: any) =>
                  standing?.links?.[0]?.href === activeSeasonStandingUrl,
              )?.name
            }
          </MenuButton>

          <MenuList>
            {allSeasonStandings
              ?.sort((a: any, b: any) => (a.name < b.name ? -1 : 0))
              ?.sort((a: any, b: any) => a?.type === 'overall' && -1)
              ?.map((standing: any) => (
                <MenuItem
                  key={standing.uuid}
                  onClick={() => onClick(standing?.links?.[0]?.href)}
                >
                  {standing?.name}
                </MenuItem>
              ))}
          </MenuList>
        </Menu>
      </div>
    )
  );
};
