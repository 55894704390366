import { WidgetConfig } from '@ntb-sport/types';
import {
  WIDGET_CONFIG,
  WIDGET_DOCUMENTATION_CONFIG,
  WIDGET_ID_TO_DISPLAY_NAME,
} from '@ntb-sport/widget-configuration';
import { camelize } from './nifs';

export const getRequiredOptions = (options: {
  [key: string]: { isRequired: boolean };
}) => {
  if (!options) {
    return [];
  }

  return Object.entries(options)?.reduce((acc: any, [key, value]: any) => {
    if (key !== 'targetId' && value?.isRequired) {
      acc.push(key);
    }
    return acc;
  }, []);
};

export const getConfigById = (id: string = ''): WidgetConfig | undefined => {
  return WIDGET_CONFIG?.[id];
};

export interface WidgetOption {
  content: string;
  isRequired: boolean;
  key: string;
  title: string;
  types: string[];
  documentationExample?: string;
  ui: boolean;
}

export const getMappedWidgetOptions = (options: {
  [key: string]: { isRequired: boolean };
}): WidgetOption[] => {
  return Object.entries(WIDGET_DOCUMENTATION_CONFIG)
    ?.filter(([key, _value]) => Object?.keys(options)?.includes(key))
    ?.map(([key, value]) => {
      const option = options[key];

      const item = {
        content: value.description,
        isRequired: option.isRequired,
        key,
        title: value.title,
        types: value.types,
        documentationExample: value.documentationExample,
        ui: value.ui,
      };

      return item;
    });
};

export const getMappedUiWidgetOptions = (ui: {
  [key: string]: string[];
}): { [key: string]: { options: WidgetOption[]; title: string } } => {
  return Object.entries(ui)?.reduce(
    (
      acc: { [key: string]: { options: WidgetOption[]; title: string } },
      [key, value],
    ) => {
      const groupName = camelize(key);

      if (!acc[groupName as keyof typeof acc]) {
        acc[groupName as keyof typeof acc] = {
          title: groupName,
          options: [],
        };
      }

      const entry = Object.entries(WIDGET_DOCUMENTATION_CONFIG)?.filter(
        ([k, v]) => value?.includes(k),
      );
      entry?.forEach(([k, value]) => {
        acc[groupName as keyof typeof acc].options.push({
          content: value.description,
          isRequired: value.required,
          key: k,
          title: value.title,
          types: value.types,
          documentationExample: value.documentationExample,
          ui: value.ui,
        });
      });

      return acc;
    },
    {},
  );
};

interface Option {
  isRequired: boolean;
  key: string;
}

export function sortOptions<T extends Option>(options: T[] = []): T[] {
  return options.sort(
    (a, b) =>
      Number(b.isRequired) - Number(a.isRequired) ||
      a.key.charCodeAt(0) - b.key.charCodeAt(0),
  );
}

export const getOptions = (options: {
  [key: string]: { isRequired: boolean };
}) => {
  if (!options) return [];

  const mappedOptions = getMappedWidgetOptions(options);
  const sortedOptions = sortOptions(mappedOptions);

  return sortedOptions;
};

export const getUiOptions = (ui: { [key: string]: string[] }) => {
  if (!ui) return {};

  const mappedOptions = getMappedUiWidgetOptions(ui);

  return mappedOptions;
};

export const getWidgetName = (id: string): string => {
  if (!id) return '';

  return WIDGET_ID_TO_DISPLAY_NAME[id];
};

export const objectToCodeString = (obj: object): string => {
  let string = JSON.stringify(obj, null, 2);
  string.replace(/\\"/g, '\uFFFF'); // U+ FFFF
  string = string.replace(/"([^"]+)":/g, '$1:').replace(/\uFFFF/g, '\\"');
  return string;
};

export const getWidgetOptions = (config: WidgetConfig): any => {
  return Object.values(config?.options)?.reduce((acc: any, option: any) => {
    if (Array.isArray(option)) {
      option.forEach((key: string) => {
        acc[key] = WIDGET_DOCUMENTATION_CONFIG[key];
      });
    } else {
      Object.entries(option).forEach(([key, value]: any) => {
        const keyName = camelize(key);
        const options = value.reduce((acc: any, i: any) => {
          acc[i] = WIDGET_DOCUMENTATION_CONFIG[i];
          return acc;
        }, {});
        acc[keyName] = options;
      });
    }
    return acc;
  }, {});
};

export const getInitializeWidgetObjectCodeExample = (
  config: WidgetConfig,
  rootIndentation: number = 0,
): object => {
  let space = '';

  for (let i = 0; i < rootIndentation; i++) {
    space += ' ';
  }

  const options = Object.keys(config?.options)?.reduce(
    (acc: any, option: any) => {
      acc[space + option] =
        WIDGET_DOCUMENTATION_CONFIG[option]?.documentationExample;

      return acc;
    },
    {},
  );

  const uiOptions = Object.entries(config?.uiOptions)?.reduce(
    (acc: any, [key, value]: any) => {
      if (!acc[space + 'ui']) {
        acc[space + 'ui'] = {};
      }
      const keyName = camelize(key);

      const options = value.reduce((acc: any, i: any) => {
        acc[space + i] = WIDGET_DOCUMENTATION_CONFIG[i]?.documentationExample;
        return acc;
      }, {});

      if (!acc[space + 'ui'][space + keyName]) {
        acc[space + 'ui'][space + keyName] = options;
      }

      return acc;
    },
    {},
  );

  return { ...options, ...uiOptions };
};

export const getFullCodeExample = (config: WidgetConfig): string => {
  const codeString = `
<script type="module" src="${config.widgetUrl}"></script>
<link rel="stylesheet" href="${config.themeUrl}"/>

<div data-target-id="${config?.id}"></div>

<script type="module">
  window.${config.widgetNamespace}.initializeWidget(${objectToCodeString(
    getInitializeWidgetObjectCodeExample(config, 2),
  ).replace(/}/g, '  }')});
</script>`;
  return codeString;
};

export const formatOptions = (
  options: WidgetOption[],
  widgetId: string,
  sportGroup: string,
) => {
  return options.reduce(
    (acc, option) => {
      if (option.key === 'targetId') {
        acc['  ' + option.key] = widgetId;
      } else if (option.key === 'sportGroup') {
        acc['  ' + option.key] = sportGroup || '';
      } else {
        acc['  ' + option.key] = option?.documentationExample || '';
      }

      return acc;
    },
    {} as { [key: string]: string },
  );
};
