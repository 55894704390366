import { useQuery } from '@tanstack/react-query';

import { FetchEventStandingsProps, fetchEventStandings } from './fetch';
import { getIsNeoSportGroup } from '@ntb-sport/helpers';
import { QUERY_KEYS } from '@ntb-sport/constants';

interface UseQueryEventStandingsParams extends FetchEventStandingsProps {
  sportGroup: string;
}

interface UseQueryEventStandingsProps {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: UseQueryEventStandingsParams;
  options?: Record<string, unknown>;
}

export const useQueryEventStandings = ({
  queryKey = QUERY_KEYS.EVENT_STANDINGS,
  queryKeyParams,
  params,
  options,
}: UseQueryEventStandingsProps) => {
  const isSportEditSportGroup = getIsNeoSportGroup(params?.sportGroup);

  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchEventStandings(params),
    enabled:
      isSportEditSportGroup &&
      Boolean(params.customerUUID) &&
      Boolean(params.token) &&
      Boolean(params.eventUUID),
    ...options,
  });

  return { data, isLoading, error };
};
