import { Icon } from '../../chakra';

export const CalendarIcon = (props: any) => (
  <Icon {...props}>
    <g>
      <path
        fill="currentColor"
        d="M16.51,7.99h3.08v2.33h-3.08V7.99z M16.51,11.81h3.08v2.33h-3.08V11.81z M12.03,7.99h3.08v2.33h-3.08V7.99z
		 M12.03,11.81h3.08v2.33h-3.08V11.81z M12.03,15.64h3.08v2.33h-3.08V15.64z M7.55,7.99h3.08v2.33H7.55V7.99z M7.55,11.81h3.08v2.33
		H7.55V11.81z M7.55,15.64h3.08v2.33H7.55V15.64z M3.07,11.81h3.08v2.33H3.07V11.81z M3.07,15.64h3.08v2.33H3.07V15.64z"
      />
      <path
        fill="currentColor"
        d="M19.36,2.88H3.34c-1.4,0-2.53,1.13-2.53,2.53v0.74v1v11.13c0,1.4,1.13,2.53,2.53,2.53h16.02
		c1.39,0,2.53-1.13,2.53-2.53V7.15v-1V5.41C21.89,4.02,20.75,2.88,19.36,2.88z M20.89,18.28c0,0.84-0.69,1.53-1.53,1.53H3.34
		c-0.84,0-1.53-0.69-1.53-1.53V7.15h19.08V18.28z"
      />
      <rect fill="currentColor" x="3.07" y="11.81" width="3.08" height="2.33" />
      <rect fill="currentColor" x="3.07" y="15.64" width="3.08" height="2.33" />
      <rect fill="currentColor" x="7.55" y="7.99" width="3.08" height="2.33" />
      <rect fill="currentColor" x="7.55" y="11.81" width="3.08" height="2.33" />
      <rect fill="currentColor" x="7.55" y="15.64" width="3.08" height="2.33" />
      <rect fill="currentColor" x="12.03" y="7.99" width="3.08" height="2.33" />
      <rect
        fill="currentColor"
        x="12.03"
        y="11.81"
        width="3.08"
        height="2.33"
      />
      <rect
        fill="currentColor"
        x="12.03"
        y="15.64"
        width="3.08"
        height="2.33"
      />
      <rect fill="currentColor" x="16.51" y="7.99" width="3.08" height="2.33" />
      <rect
        fill="currentColor"
        x="16.51"
        y="11.81"
        width="3.08"
        height="2.33"
      />
    </g>
  </Icon>
);

export default CalendarIcon;
