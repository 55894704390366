import { useBem } from '@ntb-sport/hooks';
import EventParticipant from '../../EventParticipant/EventParticipant';
import { COMPONENT_IDS } from '@ntb-sport/constants';
import classNames from 'classnames';
import './standing.css';

interface StandingProps {
  standing: any;
}

export const Standing = ({ standing }: StandingProps) => {
  const bem = useBem(COMPONENT_IDS.SEASON_STANDING);
  return (
    <li
      className={classNames(bem('list-item'), {
        [bem('list-item', 'highlight')]:
          standing?.countries?.[0]?.uuid === 'country-norway',
      })}
    >
      <div
        className={classNames(bem('rank'), {
          [bem('rank', 'highlight')]:
            standing?.countries?.[0]?.uuid === 'country-norway',
        })}
      >
        {standing.rank}
      </div>

      <EventParticipant
        person={{
          ...standing?.person,
          countries: standing?.countries,
          gender: standing?.gender,
        }}
        team={standing?.team}
      />

      {Boolean(standing.points) ? (
        <Points points={standing.points} />
      ) : (
        <Duration
          duration={standing.duration}
          timeDifference={standing.timeDifference}
        />
      )}
    </li>
  );
};

const Points = ({ points }: { points: number }) => {
  const bem = useBem(COMPONENT_IDS.SEASON_STANDING);
  return <div className={bem('info', 'points')}>{points}p</div>;
};

const Duration = ({
  duration,
  timeDifference,
}: {
  duration: string;
  timeDifference: string;
}) => {
  const bem = useBem(COMPONENT_IDS.SEASON_STANDING);

  if (!duration) {
    return (
      <div className={bem('info', 'time-difference')}>{timeDifference}</div>
    );
  }

  return <div className={bem('info', 'time-duration')}>{duration}</div>;
};
