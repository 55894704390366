import { MEDIA_QUERY } from '@ntb-sport/constants';
import styled from 'styled-components';

export const Transfers = styled.section`
  width: 100%;
  max-width: 100vw;
  background: var(--background-color);
  color: currentColor;
  padding: 0;
  margin: auto;
  min-height: 100vh;
`;

export const TransferList = styled.ul<{ $isTableView: boolean }>`
  max-width: ${(props) => (props.$isTableView ? '100%' : '85%')};
  list-style: none;
  display: flex;
  margin: auto;
  flex-direction: column;
  padding: var(--ntb-space-6) 0;
  position: relative;
  gap: ${(props) =>
    props.$isTableView ? 'var(--ntb-space-6)' : 'var(--ntb-space-8)'};

  @media ${MEDIA_QUERY.TABLET_S} {
    max-width: ${(props) => (props.$isTableView ? '80%' : '75%')};
    gap: ${(props) =>
      props.$isTableView ? 'var(--ntb-space-8)' : 'var(--ntb-space-10)'};
  }

  @media ${MEDIA_QUERY.LAPTOP} {
    gap: ${(props) =>
      props.$isTableView ? 'var(--ntb-space-10)' : 'var(--ntb-space-12)'};
    max-width: ${(props) => (props.$isTableView ? '85%' : '90%')};
  }

  @media ${MEDIA_QUERY.LAPTOP_M} {
    max-width: 700px;
  }
`;

export const ResultsInfo = styled.div`
  padding: var(--ntb-space-10) 0;
  width: 100%;
  text-align: center;

  @media ${MEDIA_QUERY.LAPTOP} {
    padding: var(--ntb-space-12) 0;
  }
`;

export const LoadResults = styled.div`
  display: flex;
  justify-content: center;
  gap: var(--ntb-space-4);
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: var(--ntb-fontSizes-sm);
  white-space: nowrap;
`;

export const LoadMoreButton = styled.button`
  background: transparent;
  border: var(--base-colors-brand-dark-700-background-color) solid 2px;
  border-radius: var(--ntb-radii-md);
  padding: var(--ntb-space-2) var(--ntb-space-4);
  transition: all 0.2s ease;

  &:hover {
    scale: 1.05;
  }
`;

export const LoadAllButton = styled(LoadMoreButton)`
  background: var(--base-colors-brand-dark-900-background-color);
  border-color: transparent;
  color: var(--always-light-background);
`;

export const Loader = styled.div`
  margin: var(--ntb-space-8) auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: var(--ntb-space-6);

  div {
    border-radius: var(--ntb-radii-md);
  }

  @media ${MEDIA_QUERY.TABLET_S} {
    width: 70%;
    margin: var(--ntb-space-12) auto;
  }
`;
