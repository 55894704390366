import Icon from '../Icon/Icon';

export const CornerIcon = (props: any) => (
  <Icon {...props}>
    <g>
      <g>
        <g>
          <path
            d="M19.4,20.9l-0.9-1.4l-0.6-0.9l-1.9-3V1.3c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9V15h-9
				c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h4.5c0.5,2,2.7,3.6,5.3,3.6c0.6,0,1.1-0.1,1.6-0.2l1.2,1.7c0.2,0.3,0.5,0.4,0.8,0.4
				c0.2,0,0.3,0,0.5-0.1C19.5,21.9,19.6,21.3,19.4,20.9z M15.1,18.5c-1.6,0-3-0.8-3.5-1.8h3l1.2,1.8C15.6,18.5,15.4,18.5,15.1,18.5z
				"
          />
        </g>
        <g>
          <path
            d="M13,1.5v5.8c0,0.3-0.1,0.6-0.4,0.7c-0.2,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.2,0-0.3-0.1L3.8,5.1
				C3.4,5,3.2,4.7,3.2,4.3c0-0.4,0.2-0.7,0.6-0.8l8.1-2.9c0.3-0.2,0.6-0.1,0.8,0.1C12.9,0.9,13,1.2,13,1.5z"
          />
        </g>
      </g>
    </g>
  </Icon>
);
