import isNameIncludedInSearchQuery from './isNameIncludedInSearchQuery';

export default function tournamentIncludesSearchQuery(
  tournament: any,
  searchQuery: string,
) {
  if (!searchQuery) return true;

  const matchNames = Object.values(tournament.stages).reduce(
    (acc: string[], stage: any) => {
      Object.values(stage.matches).forEach((match: any) =>
        acc.push(match.name),
      );
      return acc;
    },
    [],
  ) as string[];

  const matchHomeTeamShortNames = Object.values(tournament.stages).reduce(
    (acc: string[], stage: any) => {
      Object.values(stage.matches).forEach((match: any) =>
        acc.push(match.homeTeam.shortName),
      );
      return acc;
    },
    [],
  ) as string[];

  const matchAwayTeamShortNames = Object.values(tournament.stages).reduce(
    (acc: string[], stage: any) => {
      Object.values(stage.matches).forEach((match: any) =>
        acc.push(match.awayTeam.shortName),
      );
      return acc;
    },
    [],
  ) as string[];

  const stageNames = Object.values(tournament.stages).map(
    (stage: any) => stage.groupName,
  );

  return (
    isNameIncludedInSearchQuery(tournament.name, searchQuery) ||
    isNameIncludedInSearchQuery(tournament.country.name, searchQuery) ||
    stageNames?.some((name: string) =>
      isNameIncludedInSearchQuery(name, searchQuery),
    ) ||
    matchNames?.some((name: string) =>
      isNameIncludedInSearchQuery(name, searchQuery),
    ) ||
    matchHomeTeamShortNames?.some((name: string) =>
      isNameIncludedInSearchQuery(name, searchQuery),
    ) ||
    matchAwayTeamShortNames?.some((name: string) =>
      isNameIncludedInSearchQuery(name, searchQuery),
    )
  );
}
