import { format } from 'date-fns';
import { nb } from 'date-fns/locale';

import {
  COMPONENT_IDS,
  ELEMENT_IDS,
  MATCH_STATUS,
  MATCH_STATUS_ID,
  SPORT_ID,
} from '@ntb-sport/constants';
import {
  getWinningTeamId,
  getTeamForm,
  isMatchInProgress,
} from '@ntb-sport/helpers';

import { MatchInfoStadium } from '../MatchInfo/MatchInfo';
import * as S from './TeamFixturesMatch.styled';
import { TeamFixturesTeam } from '../TeamFixturesTeam/TeamFixturesTeam';

export interface TeamFixturesMatchProps {
  id: number;
  teamId?: number;
  isLoading?: boolean;
  onClickTeam?: any;
  onClickMatch?: any;
  matchStatusId: number;
  displayStageName?: boolean;
  highlightWinningTeam?: boolean;
  isTeamMatches?: boolean;
  groupBy?: string | null;
  timestamp: string;
  homeTeam: any;
  awayTeam: any;
  result?: any;
  matchRelation?: any;
  sportId: number;
  stage: any;

  showStadium?: boolean;
  stadium?: any;
  round?: number | boolean;
}

export const TeamFixturesMatch: React.FC<TeamFixturesMatchProps> = ({
  id,
  isLoading,
  isTeamMatches,
  onClickTeam,
  onClickMatch,
  teamId,

  highlightWinningTeam,
  displayStageName,
  groupBy,
  matchStatusId,
  timestamp,
  homeTeam,
  awayTeam,
  result,
  matchRelation,
  sportId,
  stage,
  showStadium,
  stadium,
  round,
}) => {
  const matchInProgress = isMatchInProgress(matchStatusId);

  const winningTeamId = getWinningTeamId({
    result,
    homeTeamId: homeTeam?.id,
    awayTeamId: awayTeam?.id,
    matchRelation,
  });

  const resultType = getTeamForm({
    teamId: Number(teamId),
    winningTeamId,
  });

  const matchStatus = isMatchInProgress(matchStatusId)
    ? 'in_progress'
    : MATCH_STATUS_ID[matchStatusId]?.toLowerCase();

  return (
    <S.Match
      data-component-id={COMPONENT_IDS.TEAM_FIXTURES_MATCH}
      $displayBorder={groupBy === 'matchType'}
    >
      <S.DateInfo>
        <S.DateAndName>
          {(isTeamMatches || displayStageName) && (
            <S.StageName>{stage?.name}</S.StageName>
          )}
          {groupBy !== 'date' ? (
            <S.MatchDate>
              {`${format(new Date(timestamp), 'EEEE d. MMMM yyyy', {
                locale: nb,
              })}`.toLowerCase()}
            </S.MatchDate>
          ) : (
            <>{round && <S.MatchDate>{`Runde ${round}`}</S.MatchDate>}</>
          )}
        </S.DateAndName>

        {matchRelation && matchRelation?.matchNumber === 1 && (
          <S.MatchNumber>{`Kamp 1 av 2`}</S.MatchNumber>
        )}
        {matchRelation && matchRelation?.matchNumber === 2 && (
          <S.MatchNumber>{`Kamp 2 av 2`}</S.MatchNumber>
        )}
      </S.DateInfo>

      <TeamFixturesTeam
        team={homeTeam}
        matchStatusId={matchStatusId}
        highlightWinningTeam={highlightWinningTeam}
        winningTeamId={winningTeamId}
        onClickTeam={onClickTeam}
        matchRelation={matchRelation}
        sportId={sportId}
        isLoading={isLoading}
        isHomeTeam={true}
      />

      {matchStatusId !== MATCH_STATUS.PLAYED && (
        <S.MatchStatusContainer
          data-element-id={
            (MATCH_STATUS.ABANDONED ||
              MATCH_STATUS.CANCELLED ||
              MATCH_STATUS.POSTPONED) &&
            ELEMENT_IDS.TYPE_ERROR
          }
        >
          <S.MatchStatus
            $matchStatusId={matchStatusId}
            $isMatchInProgress={matchInProgress}
            onClick={() =>
              onClickMatch &&
              onClickMatch({
                name: `${homeTeam.name} - ${awayTeam.name}`,
                timestamp,
                id,
                sportId,
              })
            }
          >
            {matchStatusId === MATCH_STATUS.NOT_STARTED &&
              format(new Date(timestamp), 'HH:mm')}
            {matchStatusId === MATCH_STATUS.DATE_NOT_SET &&
              'Dato ikke bekreftet'}
            {matchStatusId === MATCH_STATUS.ONGOING && 'Pågår'}
            {matchStatusId === MATCH_STATUS.FIRST_HALF && '1. omg'}
            {matchStatusId === MATCH_STATUS.SECOND_HALF && '2. omg'}
            {matchStatusId === MATCH_STATUS.FIRST_HALF_EXTRA_TIME &&
              '1. ekstraomg'}
            {matchStatusId === MATCH_STATUS.SECOND_HALF_EXTRA_TIME &&
              '2. ekstraomg'}
            {matchStatusId === MATCH_STATUS.PENALTY_SHOOTOUT && 'STR'}
            {matchStatusId === MATCH_STATUS.WILL_NOT_BE_PLAYED && 'Avlyst'}
            {matchStatusId === MATCH_STATUS.POSTPONED && 'Utsatt'}
            {matchStatusId === MATCH_STATUS.CANCELLED && 'Strøket'}
            {matchStatusId === MATCH_STATUS.ABANDONED && 'Avbrutt'}
          </S.MatchStatus>
        </S.MatchStatusContainer>
      )}

      <S.ResultWrapper>
        {sportId === SPORT_ID.ICE_HOCKEY ? (
          <>
            {result &&
              result?.homeScore90 !== null &&
              result?.awayScore90 !== null && (
                <S.Result
                  $isMatchInProgress={matchInProgress}
                  $isClickable={onClickTeam}
                  onClick={() =>
                    onClickMatch &&
                    onClickMatch({
                      name: `${homeTeam.name} - ${awayTeam.name}`,
                      id,
                      timestamp,
                      sportId,
                    })
                  }
                >
                  {`${result?.homeScore90} - ${result?.awayScore90}`}
                </S.Result>
              )}
            {result &&
              Object.prototype.hasOwnProperty.call(
                result,
                'homeScorePenalties',
              ) && <S.AOT>etter str</S.AOT>}
            {result &&
              Object.prototype.hasOwnProperty.call(result, 'homeScore120') &&
              !Object.prototype.hasOwnProperty.call(
                result,
                'homeScorePenalties',
              ) && <S.AOT>etter sd</S.AOT>}
          </>
        ) : (
          <>
            {result &&
              result?.homeScore90 !== null &&
              result?.awayScore90 !== null && (
                <S.ResultContainer data-element-id={ELEMENT_IDS.SCORE}>
                  <S.Result
                    $isTeamMatches={isTeamMatches}
                    // $isMatchInProgress={matchInProgress}
                    data-status-id={matchStatus}
                    $isClickable={onClickMatch}
                    onClick={() =>
                      onClickMatch &&
                      onClickMatch({
                        name: `${homeTeam.name} - ${awayTeam.name}`,
                        timestamp,
                        id,
                        sportId,
                      })
                    }
                  >
                    {`${result?.homeScore120 || result?.homeScore90} - ${
                      result?.awayScore120 || result?.awayScore90
                    }`}
                  </S.Result>
                  {isTeamMatches && (
                    <S.TeamResult $resultType={resultType}>
                      {resultType}
                    </S.TeamResult>
                  )}
                </S.ResultContainer>
              )}

            {result &&
              Object.prototype.hasOwnProperty.call(result, 'homeScore120') && (
                <S.AOT>eeo</S.AOT>
              )}
          </>
        )}
      </S.ResultWrapper>

      <TeamFixturesTeam
        isLoading={isLoading}
        isHomeTeam={false}
        winningTeamId={winningTeamId}
        onClickTeam={onClickTeam}
        highlightWinningTeam={highlightWinningTeam}
        matchStatusId={matchStatusId}
        team={awayTeam}
        matchRelation={matchRelation}
        sportId={sportId}
      />

      <S.AdditionalResults>
        {sportId !== 2 &&
          result &&
          Object.prototype.hasOwnProperty.call(result, 'homeScore120') && (
            <S.ResultFullTime>
              Full tid: {result.homeScore90} - {result.awayScore90}
            </S.ResultFullTime>
          )}
        {sportId !== 2 &&
          result &&
          Object.prototype.hasOwnProperty.call(
            result,
            'homeScorePenalties',
          ) && (
            <S.ResultPenalties>
              STR:{' '}
              {`${result.homeScorePenalties} - ${result.awayScorePenalties}`}
            </S.ResultPenalties>
          )}

        {matchRelation &&
          Object.prototype.hasOwnProperty.call(
            matchRelation,
            'aggregatedHomeGoals',
          ) &&
          matchRelation?.matchNumber === 2 && (
            <S.ResultCombined>
              <div>
                {`Sammenlagt: ${
                  matchRelation.aggregatedHomeGoalsExtraTime ||
                  matchRelation.aggregatedHomeGoals
                } - ${
                  matchRelation.aggregatedAwayGoalsExtraTime ||
                  matchRelation.aggregatedAwayGoals
                }`}
              </div>

              {(matchRelation.victoryTypeId === 2 ||
                matchRelation.victoryTypeId === 5) && (
                <div>
                  {matchRelation.winningTeamId === homeTeam.id &&
                    `${homeTeam.name} videre på bortemål`}
                  {matchRelation.winningTeamId === awayTeam.id &&
                    `${awayTeam.name} videre på bortemål`}
                </div>
              )}
            </S.ResultCombined>
          )}
      </S.AdditionalResults>
      {showStadium && stadium && (
        <S.StadiumWrapper>
          <MatchInfoStadium stadium={stadium} />
        </S.StadiumWrapper>
      )}
    </S.Match>
  );
};
