import { Fetch, SPORTEDIT_API_BASE_URL } from '../../fetch';

export interface FetchSportCompetitionsType {
  apiBaseUrl: string;
  sportUUID: string;
  token: string;
  customerUUID: string;
}

export const fetchSportCompetitions = async ({
  apiBaseUrl = SPORTEDIT_API_BASE_URL,
  sportUUID,
  token,
  customerUUID,
}: FetchSportCompetitionsType) => {
  const json = await Fetch({
    url: `${apiBaseUrl}/sports/${sportUUID}/competitions`,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });

  return json?.data;
};
