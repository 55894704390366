import Icon from '../Icon/Icon';

export const OvertimeIcon = (props: any) => (
  <Icon
    {...props}
    className="overtime"
    viewBox="0 0 22.7 22.7"
    enableBackground="new 0 0 22.7 22.7"
  >
    <g id="Layer_1"></g>
    <g id="Layer_3"></g>
    <g id="Layer_4"></g>
    <g id="Layer_5">
      <g>
        <path
          d="M11.354,1.042c-5.69,0-10.31,4.63-10.31,10.31s4.62,10.3,10.31,10.3c0.75,0,1.49-0.08,2.19-0.24
  c-0.07-0.17-0.11-0.35-0.11-0.54v-0.46c-0.67,0.16-1.36,0.24-2.08,0.24c-5.13,0-9.31-4.17-9.31-9.3s4.18-9.31,9.31-9.31
  s9.3,4.18,9.3,9.31c0,0.69-0.07,1.36-0.22,2h0.52c0.17,0,0.34,0.03,0.49,0.09c0.14-0.67,0.21-1.37,0.21-2.09
  C21.654,5.672,17.034,1.042,11.354,1.042z"
        />
      </g>
      <g>
        <path
          d="M12.664,11.352c0,0.72-0.59,1.31-1.31,1.31c-0.55,0-1.02-0.34-1.21-0.81h-5.64c-0.28,0-0.5-0.23-0.5-0.5
  c0-0.28,0.22-0.5,0.5-0.5h5.64c0.13-0.32,0.39-0.58,0.71-0.71v-3.33c0-0.28,0.22-0.5,0.5-0.5c0.27,0,0.5,0.22,0.5,0.5v3.33
  C12.324,10.342,12.664,10.812,12.664,11.352z"
        />
      </g>
      <g>
        <g>
          <path
            d="M21.194,14.412c-0.07-0.04-0.15-0.06-0.24-0.06h-3.1v-3.09c0-0.27-0.22-0.5-0.5-0.5h-2.42c-0.27,0-0.5,0.23-0.5,0.5v3.09
    h-3.08c-0.28,0-0.5,0.22-0.5,0.5v2.45c0,0.28,0.22,0.5,0.5,0.5h3.08v3.07c0,0.1,0.03,0.19,0.09,0.27c0,0,0,0,0,0.01
    c0.09,0.13,0.24,0.22,0.41,0.22h2.42c0.28,0,0.5-0.22,0.5-0.5v-3.07h3.1c0.28,0,0.5-0.22,0.5-0.5v-2.45
    C21.454,14.662,21.354,14.492,21.194,14.412z M20.454,16.802h-3.1c-0.28,0-0.5,0.23-0.5,0.5v3.07h-1.42v-3.07
    c0-0.27-0.22-0.5-0.5-0.5h-3.08v-1.45h3.08c0.28,0,0.5-0.22,0.5-0.5v-3.09h1.42v3.09c0,0.28,0.22,0.5,0.5,0.5h3.1V16.802z"
          />
        </g>
      </g>
    </g>
  </Icon>
);
