import styled from 'styled-components';

export const Facts = styled.dl`
  padding: var(--ntb-space-2);
  display: flex;
  flex-direction: column;
  row-gap: var(--ntb-space-1);

  > div {
    display: flex;
    align-items: center;
    column-gap: var(--ntb-space-1);
  }
`;

export const FactLabel = styled.dt`
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-medium);
`;

export const FactContent = styled.dd`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-normal);
`;

export const CountryFlagContainer = styled.div`
  display: flex;
`;
