import React, { Fragment } from 'react';

import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import { NifsPlayerStatistics } from '@ntb-sport/types';
import ShowMoreButton from './ShowMoreButton';

import * as S from './TeamPlayerStats.styled';
import { CardIcon } from '../../icons';
import { Skeleton, Tooltip } from '../../chakra';

export interface TeamPlayerStatsProps {
  data?: NifsPlayerStatistics;
  error?: unknown;
  handleRowsChange?: (rows: number) => void;
  highlightFirst?: boolean;
  initialRows: number;
  isLoading: boolean;
  tableRows: number;
  selectedType: string;
  showAdditionalRows?: boolean;
  showImages?: boolean;
  onClickPerson?: ({
    name,
    id,
    sportId,
  }: {
    name: string;
    id: number;
    sportId: number;
  }) => void;
  onClickTeam?: ({
    name,
    id,
    sportId,
  }: {
    name: string;
    id: number;
    sportId: number;
  }) => void;
}

export const TeamPlayerStats: React.FC<TeamPlayerStatsProps> = ({
  data,
  error,
  handleRowsChange,
  highlightFirst,
  initialRows,
  isLoading,
  tableRows,
  selectedType,
  showAdditionalRows,
  showImages,
  onClickPerson,
  onClickTeam,
}) => {
  if (isLoading) {
    return (
      <S.Container data-component-id={COMPONENT_IDS.TEAM_PLAYER_STATS}>
        <Skeleton w="100%" h="100%" minH="38px" mb={1} />
        {[...Array(initialRows)].map((_item, index) => (
          <Skeleton key={index} w="100%" h="100%" minH="62px" mb={1} />
        ))}
      </S.Container>
    );
  }

  if (error || !data) {
    // TODO: Pål. This was causing issues in customer-portal. Turned it off for now.
    // const { message } = error as { message?: string };
    return (
      <S.Container data-component-id={COMPONENT_IDS.TEAM_PLAYER_STATS}>
        <S.ErrorMessage>{'Something went wrong'}</S.ErrorMessage>
      </S.Container>
    );
  }

  return (
    <S.Stats
      data-component-id={COMPONENT_IDS.TEAM_PLAYER_STATS}
      data-tournament-id={data?.stage?.tournament?.id}
    >
      {highlightFirst &&
        data?.players?.slice(0, 1)?.map((stat: any) => {
          const profileImage = stat?.player?.profilePictures?.find(
            (image: any) =>
              image.imageTypeId === 18 ||
              image.imageTypeId === 17 ||
              image.imageTypeId === 19,
          );
          return (
            <S.HighlightedStat
              $src={profileImage?.url}
              key={stat.id}
              data-element-id={ELEMENT_IDS.TYPE_HIGHLIGHT}
            >
              <S.HighlightedContent data-element-id={ELEMENT_IDS.OVERLAY}>
                <S.HighlightedPlayerName
                  $isClickable={onClickPerson ? true : false}
                  onClick={() =>
                    onClickPerson &&
                    onClickPerson({
                      name: stat?.player?.name,
                      id: stat?.player?.id,
                      sportId: 1,
                    })
                  }
                >
                  {`#${stat.place} ${stat?.player?.name}`}
                </S.HighlightedPlayerName>
                <S.HighlightedTeam
                  $isClickable={onClickTeam ? true : false}
                  onClick={() =>
                    onClickTeam &&
                    onClickTeam({
                      name: stat?.player?.teams[0]?.name,
                      id: stat?.player?.teams[0]?.id,
                      sportId: 1,
                    })
                  }
                >
                  {showImages && (
                    <S.TeamLogo>
                      <img src={stat.player.teams[0]?.logo?.url} />
                    </S.TeamLogo>
                  )}
                  {stat.player.teams[0]?.name}
                </S.HighlightedTeam>
                <S.HighlightedStatCount>
                  {selectedType === 'cards' ? (
                    <>
                      {stat?.yellowCards}
                      <S.HighlightedStatCountLabel>
                        {stat?.yellowCards !== 1 ? ' gule, ' : ' gult, '}
                      </S.HighlightedStatCountLabel>
                      &nbsp;{stat?.redCards}
                      <S.HighlightedStatCountLabel>
                        {stat?.redCards !== 1 ? ' røde' : ' rødt'}
                      </S.HighlightedStatCountLabel>
                    </>
                  ) : (
                    <>
                      {stat?.[selectedType]}
                      <S.HighlightedStatCountLabel>
                        {selectedType === 'goals'
                          ? 'mål'
                          : selectedType === 'assists'
                            ? 'målgivende'
                            : ''}
                      </S.HighlightedStatCountLabel>
                    </>
                  )}
                </S.HighlightedStatCount>
              </S.HighlightedContent>
            </S.HighlightedStat>
          );
        })}
      {data?.players
        ?.slice(highlightFirst ? 1 : 0, tableRows || -1)
        ?.map((stat: any, index: number) => {
          const profileImage = stat?.player?.profilePictures?.find(
            (image: any) => image.imageTypeId === 1,
          );
          return (
            <Fragment key={stat.id}>
              {index === 0 && (
                <S.Stat data-element-id={ELEMENT_IDS.ROW}>
                  <div />
                  <S.StatCounts>
                    {selectedType === 'cards' && (
                      <>
                        <S.StatCountHeader>
                          <Tooltip
                            hasArrow
                            label="Antall gule kort"
                            aria-label="Antall gule kort"
                          >
                            GK
                          </Tooltip>
                        </S.StatCountHeader>
                        <S.StatCountHeader>
                          <Tooltip
                            hasArrow
                            label="Antall røde kort"
                            aria-label="Antall røde kort"
                          >
                            RK
                          </Tooltip>
                        </S.StatCountHeader>
                      </>
                    )}
                    {selectedType === 'goals' && (
                      <S.StatCountHeader>
                        <Tooltip
                          hasArrow
                          label="Antall mål"
                          aria-label="Antall mål"
                        >
                          Mål
                        </Tooltip>
                      </S.StatCountHeader>
                    )}
                    {selectedType === 'assists' && (
                      <>
                        {data?.stage?.data?.indirectAssists && (
                          <S.StatCountHeader>
                            <Tooltip
                              hasArrow
                              label="Antall indirekte målgivende"
                              aria-label="Antall indirekte målgivende "
                            >
                              IMG
                            </Tooltip>
                          </S.StatCountHeader>
                        )}

                        <S.StatCountHeader>
                          <Tooltip
                            hasArrow
                            label="Antall målgivende"
                            aria-label="Antall målgivende "
                          >
                            MG
                          </Tooltip>
                        </S.StatCountHeader>
                      </>
                    )}
                  </S.StatCounts>
                </S.Stat>
              )}
              <S.Stat data-element-id={ELEMENT_IDS.ROW}>
                <S.RankAndBio>
                  <S.Rank>
                    <S.RankLabel>#</S.RankLabel>
                    {stat.place}
                  </S.Rank>
                  <S.Player>
                    {showImages && (
                      <S.ProfileImage>
                        {profileImage && (
                          <img
                            src={profileImage?.url}
                            alt={stat?.player?.name}
                          />
                        )}
                      </S.ProfileImage>
                    )}
                    <div
                      style={{
                        marginLeft: showImages ? '0' : 'var(--ntb-space-5)',
                      }}
                    >
                      <S.PlayerName
                        $isClickable={onClickPerson ? true : false}
                        onClick={() =>
                          onClickPerson &&
                          onClickPerson({
                            name: stat?.player?.name,
                            id: stat?.player?.id,
                            sportId: 1,
                          })
                        }
                      >
                        {stat?.player?.name}
                      </S.PlayerName>
                      <S.TeamAndCountry>
                        <S.Team
                          $isClickable={onClickTeam ? true : false}
                          onClick={() =>
                            onClickTeam &&
                            onClickTeam({
                              name: stat?.player?.teams[0]?.name,
                              id: stat?.player?.teams[0]?.id,
                              sportId: 1,
                            })
                          }
                        >
                          <S.TeamLogo>
                            <img src={stat.player.teams[0]?.logo?.url} />
                          </S.TeamLogo>
                          {stat.player.teams[0]?.name}
                        </S.Team>
                      </S.TeamAndCountry>
                    </div>
                  </S.Player>
                </S.RankAndBio>
                <S.StatCounts>
                  {selectedType === 'cards' && (
                    <>
                      <CardIcon
                        cardId="yellow-card"
                        fillColor="var(--base-colors-warning)"
                        size="large"
                        count={stat?.yellowCards}
                      />
                      <CardIcon
                        cardId="red-card"
                        fillColor="var(--base-colors-error)"
                        size="large"
                        count={stat.redCards}
                      />
                    </>
                  )}
                  {selectedType === 'goals' && (
                    <S.StatCount>{stat?.[selectedType]}</S.StatCount>
                  )}
                  {selectedType === 'assists' && (
                    <>
                      {data?.stage?.data?.indirectAssists && (
                        <S.StatCount>{stat?.indirectAssists}</S.StatCount>
                      )}
                      <S.StatCount>{stat?.[selectedType]}</S.StatCount>
                    </>
                  )}
                </S.StatCounts>
              </S.Stat>
            </Fragment>
          );
        })}
      {showAdditionalRows && (
        <S.Container>
          <ShowMoreButton
            dataLength={data?.players?.length}
            initialLimit={initialRows}
            limit={tableRows}
            handleRowsChange={handleRowsChange}
          />
        </S.Container>
      )}
    </S.Stats>
  );
};

export default TeamPlayerStats;
