import { animated } from 'react-spring';
import styled from 'styled-components';

interface BackgroundImageProps {
  $backgroundImage: string;
  $isLoadingMatch?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`;

export const BackgroundImage = styled(animated.div)<BackgroundImageProps>`
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: ${({ $backgroundImage }) =>
    'url(' + $backgroundImage + ')'};
  background-size: cover;
  background-position: center center;
  z-index: 1;
  top: 0;
  left: 0;

  &:after {
    content: '';
    background: var(--background-color);
    transition: background 300ms ease-in-out;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;
