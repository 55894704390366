import { SPORT_GROUP, SPORT_ID } from '@ntb-sport/constants';
import { Fetch, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchCustomerSettings {
  apiBaseUrl: string;
  sportGroup: string;
  site?: string;
  active: boolean;
}

export const fetchCustomerSettings = async ({
  apiBaseUrl,
  sportGroup,
  site,
  active = true,
}: FetchCustomerSettings) => {
  const isActive = active ? '&active=true' : '';
  const hasSite = site ? `&site=${site}` : '';

  const sportId =
    sportGroup === SPORT_GROUP.ICE_HOCKEY
      ? SPORT_ID.ICE_HOCKEY
      : sportGroup === SPORT_GROUP.HANDBALL
        ? SPORT_ID.HANDBALL
        : SPORT_ID.FOOTBALL;

  const url = `${apiBaseUrl}/customers/setup/?sportId=${sportId}${isActive}${hasSite}`;

  const json = await Fetch({
    url,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });
  return json;
};
