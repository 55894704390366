import { useEffect, useState } from 'react';

import { InfoIcon, SmallCloseIcon } from '@chakra-ui/icons';

import {
  COMPONENT_IDS,
  FAIR_PLAY_SORTING_RULES,
  NIFS_DISTRICTS_ID_NAME_MAP,
  SPORT_GROUP,
} from '@ntb-sport/constants';
import { MatchesType, NifsFairPlayTableClub } from '@ntb-sport/types';
import { SearchField } from '../../common/SearchField/SearchField';

import * as S from './FairPlayStandings.styled';
import FairPlayStandingsDescriptionFootball from './Football/FairPlayStandingsDescriptionFootball';
import FairPlayStandingsFootball from './Football/FairPlayStandingsFootball';
import ShowMoreLess from './ShowMoreLess';
import Button from '../../chakra/Button/Button';
import { Skeleton } from '../../chakra';

const YEAR = 2023;
const DEFAULT_LIMIT = 10;

interface FairPlayStandingsProps {
  showAdditionalRows?: boolean;
  customerId: number;
  data?: NifsFairPlayTableClub[];
  showDistrictName?: boolean;
  displayTableInfo?: boolean;
  showSearch?: boolean;
  districtId: number;
  isLoading: boolean;
  logoUrl?: string | null;
  matches: MatchesType;
  minNumberOFTeams?: number;
  tableRows?: number;
  requireSeniorTeam: boolean;
  sportGroup?: string | null;
  title?: string | null;
}

interface TableSortingRule {
  id: number;
  norwegianText: string;
}

export const FairPlayStandings: React.FC<FairPlayStandingsProps> = ({
  showAdditionalRows,
  customerId,
  data: propsData,
  showDistrictName = true,
  displayTableInfo = true,
  showSearch = false,
  districtId,
  isLoading,
  logoUrl,
  matches,
  minNumberOFTeams = 0,
  tableRows,
  requireSeniorTeam,
  sportGroup,
  title,
}) => {
  const INITIAL_LIMIT = tableRows || DEFAULT_LIMIT;

  const [showTableInfo, setShowTableInfo] = useState<boolean>(false);
  const [data, setData] = useState<NifsFairPlayTableClub[] | null>(null);
  const [filteredData, setFilteredData] = useState<
    NifsFairPlayTableClub[] | null
  >(null);
  const [numTeamFilteredData, setNumTeamFilteredData] = useState<
    NifsFairPlayTableClub[]
  >([]);
  const [query, setQuery] = useState<string>('');
  const [limit, setLimit] = useState<number>(INITIAL_LIMIT);

  useEffect(() => {
    if (propsData) {
      setData(propsData);
      setFilteredData(propsData);
    }
  }, [propsData]);

  // Filter by querystring
  useEffect(() => {
    if (data) {
      if (query.length > 1) {
        const filtered = data.filter((clubRow) =>
          clubRow.club.name.toLowerCase().includes(query.toLowerCase()),
        );
        setFilteredData(filtered);
      } else {
        setFilteredData(data);
      }
    }
  }, [query, data]);

  // Filter by customer settings
  useEffect(() => {
    if (data) {
      if (minNumberOFTeams || requireSeniorTeam) {
        const filtered = data
          .filter((clubRow) => {
            if (requireSeniorTeam) {
              return (
                clubRow.teams.length >= minNumberOFTeams &&
                clubRow.hasSeniorTeam
              );
            } else {
              return clubRow.teams.length >= minNumberOFTeams;
            }
          })
          .map((clubRow, index) => {
            return {
              ...clubRow,
              rank: index + 1,
            };
          });

        setNumTeamFilteredData(filtered);
      }
    }
  }, [minNumberOFTeams, data]);

  if (isLoading) {
    return <Skeleton w="100%" h="600px" />;
  }

  if (!filteredData) {
    return <S.NoStandings>Tabell ikke tilgjengelig</S.NoStandings>;
  }

  const onQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    setQuery(newValue);
  };

  const onLimitChange = (type: string, limit: number, incr: number = 20) => {
    if (type === 'increment') {
      setLimit(limit + incr);
    } else if (type === 'reset') {
      setLimit(INITIAL_LIMIT);
    }
  };

  const tableTitle = `${title || 'Fair Play'}${
    showDistrictName ? `: ${NIFS_DISTRICTS_ID_NAME_MAP[districtId]}` : ''
  } ${YEAR}`;

  const dataToShow =
    (minNumberOFTeams || requireSeniorTeam) && query.length < 2
      ? numTeamFilteredData
      : filteredData;

  return (
    <S.Container
      $matches={matches}
      data-component-id={COMPONENT_IDS.FAIR_PLAY_STANDINGS}
      data-district-id={districtId}
    >
      {(showDistrictName || title || displayTableInfo) && (
        <S.Header $matches={matches}>
          {(showDistrictName || title) && (
            <S.FlexContainer $gap="16px">
              {logoUrl && <S.HeaderLogo src={logoUrl} alt="" loading="lazy" />}
              <S.DistrictName $matches={matches}>{tableTitle}</S.DistrictName>
            </S.FlexContainer>
          )}
          {displayTableInfo && (
            <Button
              ml="auto"
              size="sm"
              minW="80px"
              onClick={() => setShowTableInfo(!showTableInfo)}
              bg="var(--header-button-background-color)"
              borderRadius="var(--ntb-radii-md)"
              color="var(--header-button-font-color)"
              _hover={{
                bg: 'var(--header-button-background-color__hover)',
                color: 'var(--header-button-font-color__hover)',
              }}
              rightIcon={
                showTableInfo ? (
                  <SmallCloseIcon color="currentcolor" />
                ) : (
                  <InfoIcon color="currentcolor" />
                )
              }
            >
              {showTableInfo ? 'Lukk' : 'Info'}
            </Button>
          )}
          {showTableInfo && (
            <S.TableInfo $matches={matches}>
              <S.FlexContainer>
                <div>
                  <h3>Beskrivelse</h3>
                  <S.TableDescription $matches={matches}>
                    {sportGroup === SPORT_GROUP.FOOTBALL && (
                      <FairPlayStandingsDescriptionFootball />
                    )}
                  </S.TableDescription>
                </div>
                <S.SortingRules $matches={matches}>
                  <h3>Sorteringsregler</h3>
                  <ol>
                    {FAIR_PLAY_SORTING_RULES.map((rule: TableSortingRule) => (
                      <li key={rule?.id}>{rule?.norwegianText}</li>
                    ))}
                  </ol>
                </S.SortingRules>
              </S.FlexContainer>
              <div>
                <h3>Tilleggsinformasjon</h3>
                <S.TableDescription $matches={matches}>
                  <p>
                    Grunnlaget for tabellen er hentet fra klubbenes seriekamper
                    fra alderstrinn 13 år og opp til seniornivå. Gult kort gir 2
                    poeng og rødt kort gir 5 poeng. Klubber rangeres etter
                    laveste poengsnitt per kamp.
                  </p>
                </S.TableDescription>
              </div>
            </S.TableInfo>
          )}
        </S.Header>
      )}
      {showSearch && (
        <SearchField
          hasIcon={true}
          value={query}
          handleChange={onQueryChange}
          placeholder="Søk etter klubb"
        />
      )}
      <S.TableContainer $matches={matches}>
        {sportGroup === SPORT_GROUP.FOOTBALL && data && (
          <FairPlayStandingsFootball
            data={dataToShow.slice(0, limit)}
            isLoading={isLoading}
            matches={matches}
          />
        )}
      </S.TableContainer>
      {!dataToShow.length && <S.NoStandings>Ingen resultater</S.NoStandings>}
      {showAdditionalRows && dataToShow.length > INITIAL_LIMIT && (
        <S.FlexContainer $justify="center">
          <ShowMoreLess
            dataLength={dataToShow.length}
            initialLimit={INITIAL_LIMIT}
            limit={limit}
            onLimitChange={onLimitChange}
          />
        </S.FlexContainer>
      )}
    </S.Container>
  );
};
