import { Fetch, SPORTEDIT_API_BASE_URL } from '../../fetch';

import { SPORT_GROUP } from '@ntb-sport/constants';

export interface FetchDaysWithEventsInMonthType {
  apiBaseUrl: string;
  month: number;
  year: number;
  token: string;
  customerUUID: string;
  sportGroup: string;
}

export const fetchDaysWithEventsInMonth = async ({
  apiBaseUrl = SPORTEDIT_API_BASE_URL,
  month,
  year,
  token,
  customerUUID,
  sportGroup,
}: FetchDaysWithEventsInMonthType) => {
  const sportGroupQuery =
    sportGroup === SPORT_GROUP.ATHLETICS
      ? `&sportUUIDs=sport-athletics`
      : sportGroup === SPORT_GROUP.CYCLING
        ? `&sportUUIDs=sport-cycling`
        : '&sportsGroupUUID=sports-group-winter-sports';
  const url = `${apiBaseUrl}/daysWithEventsInMonth?month=${month}&year=${year}${sportGroupQuery}`;

  const json = await Fetch({
    url,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });

  if (json.data) return json.data;

  return json;
};
