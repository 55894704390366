import { Fetch, API_VERSION } from '../../fetch';

export interface FetchEventProps {
  apiBaseUrl: string;
  version?: string;
  eventUUID: string;
  token: string;
  customerUUID: string;
}

export const fetchEvent = async ({
  apiBaseUrl,
  version = API_VERSION.V3,
  eventUUID,
  token,
  customerUUID,
}: FetchEventProps) => {
  const json = await Fetch({
    url: `${apiBaseUrl}/${version}/events/${eventUUID}`,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });

  return json?.data;
};
