import { MEDIA_QUERY } from '@ntb-sport/constants';
import styled from 'styled-components';

export const TransferMenu = styled.section`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 3;
  background: var(--background-color);
  box-shadow: var(--shadow-bottom);
  margin-bottom: var(--ntb-space-6);

  @media ${MEDIA_QUERY.TABLET} {
    margin-bottom: var(--ntb-space-8);
  }

  @media ${MEDIA_QUERY.LAPTOP} {
    top: var(--ntb-space-10);
  }

  @media ${MEDIA_QUERY.LAPTOP_L} {
    top: var(--ntb-space-12);
  }
`;

export const TeamMenuContent = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: var(--ntb-space-4);
  gap: var(--ntb-space-6);
  min-height: var(--ntb-space-14);
  height: 100%;

  @media ${MEDIA_QUERY.TABLET} {
    padding: var(--ntb-space-6) var(--ntb-space-8);
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @media ${MEDIA_QUERY.LAPTOP} {
    margin: auto;
    max-width: 850px;
  }
`;

export const TopRightDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--ntb-space-2);
  width: 100%;
  box-sizing: border-box;
  height: var(--ntb-space-8);

  @media ${MEDIA_QUERY.TABLET} {
    flex-direction: row-reverse;
    gap: var(--ntb-space-2);
    width: max-content;
  }
`;
export const TypeFilter = styled.div<{
  $isTransferTypeOpen: boolean;
  $isTournamentPage?: boolean;
  $isNotAll?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  z-index: 1;
  position: relative;
  box-sizing: border-box;

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: var(--ntb-space-2);
    padding: 0 var(--ntb-space-2);
    box-shadow: var(--shadow-btn);
    border-radius: var(--ntb-radii-md);
    height: 100%;
    transition: border-color 0.1s ease;
    background: var(--semi-light-background);
    position: relative;
    box-sizing: border-box;

    outline: ${(props) =>
      props.$isNotAll
        ? 'solid 1px var(--base-colors-highlight-light)'
        : 'none'};
  }

  fieldset {
    position: absolute;
    top: var(--ntb-space-10);
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: var(--ntb-fontSizes-sm);
    gap: var(--ntb-space-6);
    padding: var(--ntb-space-4) var(--ntb-space-6);
    padding-top: var(--ntb-space-8);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-btn);
    transition: all 0.2s ease;
    background: var(--semi-light-background);

    div {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      height: 100%;
    }

    label {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: var(--ntb-space-1);
      cursor: pointer;
      text-transform: capitalize;
      width: 100%;
      height: 100%;
    }

    input {
      appearance: none;
      width: 100%;
      cursor: pointer;
      height: 100%;
      padding-bottom: var(--ntb-space-2);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -80%);
        width: 14px;
        aspect-ratio: 1/1;
        background-color: transparent;
        border: 0.5px solid currentColor;
        border-radius: 100%;
      }

      &:checked::after {
        background-color: currentColor;
      }
    }

    @media ${MEDIA_QUERY.TABLET} {
      right: ${(props) => (props.$isTournamentPage ? '0' : 'auto')};
      left: ${(props) => (props.$isTournamentPage ? 'auto' : '50%')};
      transform: ${(props) =>
        props.$isTournamentPage ? 'translateX(0)' : 'translateX(-50%)'};
    }

    @media ${MEDIA_QUERY.LAPTOP_L} {
      right: 50%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const Search = styled.div`
  position: relative;
  max-width: 250px;
  font-size: var(--ntb-fontSizes-sm);
  width: 100%;
  border-radius: var(--border-radius);
  min-width: 180px;
  height: 100%;
  box-shadow: var(--shadow-btn);

  input {
    color: var(--base-colors-brand-dark-900-background-color);
    padding-left: var(--ntb-space-8);
    border-radius: var(--border-radius);
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    background-color: var(--always-light-background);

    &::placeholder {
      color: var(--base-colors-brand-dark-700-background-color);
      font-size: var(--ntb-fontSizes-sm);
    }
  }

  span {
    position: absolute;
    color: var(--base-colors-brand-dark-900-background-color);
    left: var(--ntb-space-3);
    top: 50%;
    transform: translateY(-54%);
    aspect-ratio: 1/1;

    svg {
      width: var(--ntb-space-3);
    }
  }

  @media ${MEDIA_QUERY.TABLET} {
    input {
      font-size: var(--ntb-fontSizes-md);
    }
  }
`;

export const RemoveSearchBtn = styled.button<{ $isSearchValue: boolean }>`
  position: absolute;
  height: var(--ntb-space-5);
  aspect-ratio: 1/1;
  right: var(--ntb-space-2);
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.1s ease;
  opacity: ${(props) => (props.$isSearchValue ? 1 : 0)};
  cursor: ${(props) => (props.$isSearchValue ? 'pointer' : 'text')};

  svg {
    color: black;
    height: 100%;
    width: 100%;
  }
`;

export const BottomLeftDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  gap: var(--ntb-space-2);
  height: var(--ntb-space-8);

  @media ${MEDIA_QUERY.TABLET} {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const AllInOut = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  font-size: var(--ntb-fontSizes-xs);
  gap: var(--ntb-space-3);
  padding-left: var(--ntb-space-1);

  @media ${MEDIA_QUERY.TABLET} {
    margin-top: var(--ntb-space-2);
    gap: var(--ntb-space-4);
  }
`;

export const AllInOutBtn = styled.button<{ $isSelected?: boolean }>`
  height: var(--ntb-space-5);
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-transform: uppercase;
  font-weight: var(--ntb-fontWeights-regular);
  letter-spacing: 1px;
  padding-bottom: var(--ntb-space-3);
  font-size: ${(props) => props.$isSelected && 'var(--ntb-fontSizes-lg)'};
  width: var(--ntb-space-10);

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: var(--ntb-space-6);
    height: 2px;
    background-color: ${(props) =>
      props.$isSelected ? 'currentColor' : 'transparent'};
  }

  @media ${MEDIA_QUERY.LAPTOP} {
    transition: all 0.1s;
  }
`;

export const ResultsSelectViewDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-weight: var(--ntb-fontWeights-regular);
  font-size: var(--ntb-fontSizes-sm);
  width: 100%;
  height: 100%;
  gap: var(--ntb-space-3);
  white-space: nowrap;

  @media ${MEDIA_QUERY.TABLET} {
    gap: var(--ntb-space-4);
    font-size: var(--ntb-fontSizes-md);
  }
`;

export const Results = styled.p`
  width: 100px;
  text-align: right;
`;

export const SelectViewToggle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border-radius: var(--border-radius);
  background: var(--semi-light-background);
  height: 100%;
  width: var(--ntb-space-16);
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 2px;
`;

export const SelectViewBtn = styled.button<{ $isTableView?: boolean }>`
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;

  color: ${(props) =>
    props.$isTableView
      ? 'var(--base-colors-whiteAlpha-1000)'
      : 'var(--base-colors-blackAlpha-1000)'};

  svg {
    width: 100%;
    height: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    top: -15%;
    left: -15%;
    width: 130%;
    height: 130%;
    aspect-ratio: 1/1;
    background-color: transparent;
    z-index: -1;
    color: var(--base-colors-brand-dark-1000-background-color);
    transition: background-color 0.2s ease;
    border-radius: var(--ntb-radii-sm);
  }
  &::after {
    background-color: ${(props) =>
      props.$isTableView
        ? 'var(--base-colors-blackAlpha-1000)'
        : 'transparent'};
  }
`;

export const TournamentMenuContent = styled(TeamMenuContent)`
  flex-direction: column;
  gap: var(--ntb-space-4);

  @media ${MEDIA_QUERY.TABLET} {
    flex-direction: row;
    height: 100%;
  }
`;

export const TopLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: var(--ntb-space-2);
  flex-direction: row;
  align-items: center;
  font-size: var(--ntb-fontSizes-sm);
  height: var(--ntb-space-8);
`;

export const ActivateTeamFilterBtn = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--ntb-space-1);
  padding: 0 var(--ntb-space-3);
  height: 100%;
  box-shadow: var(--shadow-btn);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  background: var(--semi-light-background);

  span {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const TeamFilterActive = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--ntb-space-2);
  height: 100%;
  width: 100%;

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--ntb-space-1);
    box-shadow: var(--shadow-btn);
    border-radius: var(--border-radius);
    width: fit-content;
    height: 100%;
    aspect-ratio: 1/1;
    background: var(--semi-light-background);
    box-sizing: border-box;
  }
`;

export const SelectTeamForm = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: 100%;

  select {
    width: 100%;
    height: 100%;
    padding: 0 var(--ntb-space-3);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-btn);
    appearance: none;
    padding-right: var(--ntb-space-8);
    cursor: pointer;
    background: var(--semi-light-background);
    outline: none;
    font-size: var(--ntb-fontSizes-sm);
  }

  &::after {
    content: '▾';
    visibility: hidden;
    position: absolute;
    right: var(--ntb-space-3);
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

export const ViewTeamMenu = styled.fieldset`
  padding: var(--ntb-space-1) var(--ntb-space-4);
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: var(--ntb-fontSizes-sm);
  position: relative;
  gap: var(--ntb-space-6);
  text-transform: uppercase;
  font-weight: var(--ntb-fontWeights-regular);
  letter-spacing: 0.2px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  label {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  input {
    width: 120%;
    text-align: center;
    appearance: none;
    height: 2px;
    cursor: pointer;

    &:checked {
      background-color: currentColor;
    }
  }
`;

export const BottomRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--ntb-space-2);
  width: 100%;
  height: var(--ntb-space-8);

  @media ${MEDIA_QUERY.TABLET} {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: var(--ntb-space-4);
  }
`;

export const SortingAndResults = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: var(--ntb-fontWeights-regular);
  font-size: var(--ntb-fontSizes-sm);
  width: 100%;
  height: var(--ntb-space-8);
  gap: var(--ntb-space-3);

  p {
    text-align: left;
    width: 100px;
    white-space: nowrap;
  }

  @media ${MEDIA_QUERY.TABLET} {
    width: fit-content;
    justify-content: flex-end;
    font-size: var(--ntb-fontSizes-md);
  }
`;
