export interface MatchesType {
  mobileS?: boolean;
  mobileM?: boolean;
  mobileL?: boolean;
  mobileXL?: boolean;
  tablet?: boolean;
  tabletS?: boolean;
  desktopS?: boolean;
  desktop?: boolean;
  desktopLg?: boolean;
  desktopHd?: boolean;
}

export type TeamSports = 'fotball' | 'ishockey' | 'handball' | 'bandy';

export enum SportGroup {
  FOOTBALL = 'football',
  HANDBALL = 'handball',
  ICE_HOCKEY = 'icehockey',
  BANDY = 'bandy',
  ATHLETICS = 'athletics',
  WINTERSPORTS = 'wintersports',
}

// export enum NorwegianSportGroup {
//   FOTBALL = 'FOTBALL',
//   HANDBALL = 'HANDBALL',
//   ISHOCKEY = 'ISHOCKEY',
//   BANDY = 'BANDY',
//   FRIIDRETT = 'FRIIDRETT',
//   VINTERSPORT = 'VINTERSPORT',
// }

// export enum NorwegianSportGroupToEnglish {
//   FOTBALL = SportGroup.FOOTBALL,
//   HANDBALL = SportGroup.HANDBALL,
//   ISHOCKEY = SportGroup.ICE_HOCKEY,
//   BANDY = SportGroup.BANDY,
//   FRIIDRETT = SportGroup.ATHLETICS,
//   VINTERSPORT = SportGroup.WINTERSPORTS,
// }
