import { QUERY_KEYS } from '@ntb-sport/constants';

export function onEventsDelete({
  msg,
  apiClient,
}: {
  msg: any;
  apiClient: any;
}) {
  const { additionalData } = msg;

  apiClient.invalidateQueries({
    queryKey: [QUERY_KEYS.EVENT, { eventUUID: additionalData.event.uuid }],
  });
}
