import Icon from '../Icon/Icon';

export const AssistIcon = (props: any) => {
  return (
    <Icon {...props}>
      <g>
        <path
          d="M19.3,17.9c-1,0-1.9-0.7-2.2-1.6l-0.6-2h-1L15,16.2c-0.2,0.6-0.6,1.1-1.1,1.4c0.6,0.9,1,1.4,1.3,1.5
		c-0.6,1.4-1.7,1.6-1.7,1.6c-1.5,0.5-4,0.1-4.4,0c-0.4-0.1-0.8-0.5-1-1C8,19.5,7.9,19.2,7.8,19c0.5-0.3,1-0.8,1.4-1.3
		c-0.4-0.1-0.7-0.4-0.9-0.7c-0.4-0.6-0.6-1.3-0.4-2l0.2-0.7c-0.2-0.3-0.3-0.6-0.4-0.9v-0.2c0.3-0.7,0.8-1.4,1.2-2
		C9,11,9.1,10.8,9.2,10.6L12,1.5c0.2-0.5,0.5-1,0.9-1.2c-0.5-0.1-1-0.1-1.5-0.1C5.2,0.2,0.2,5,0.2,11.2s5,11.1,11.1,11.1
		c3.6,0,6.8-1.7,8.8-4.4H19.3z M4.9,3.7C5,3.3,5.3,3,5.5,2.7C6.2,2.1,7,1.8,7.7,1.6c0.7-0.2,1.2-0.2,1.2-0.2c0.4-0.1,0.7-0.1,1-0.1
		c0.7,0,1.2,0.1,1.5,0.3c0.1,0.4,0.1,1,0.1,1.4c-1.1,0.3-2.7,1.3-3.4,2C7.5,4.7,6.2,4.4,4.9,4.9C4.8,4.4,4.9,3.8,4.9,3.7z M4.8,5.9
		c1.4-0.6,2.5-0.2,3-0.1c0.1,0.4,0.5,1.4,1.3,3.3c-0.4,0.4-0.8,1.1-1.2,1.8c-0.4,0.7-0.8,1.4-1,1.7c-1.2,0-2.6-0.1-3.1-0.1
		c-1.1-1.1-1.2-3.1-1.2-3.4C3,7,4.6,6,4.8,5.9z M3.1,5.1c0-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.4-0.4,0.5-0.5c0,0.3,0,0.7,0.1,1
		C3.4,5.8,2.2,6.8,1.8,8.7C1.6,8.6,1.4,8.5,1.3,8.5C1.4,7.6,2.4,6,3.1,5.1z M1.3,13.7C1,12.3,0.9,10.8,1,10.3c0-0.1,0-0.3,0.1-0.4
		c0.1-0.2,0.1-0.3,0.2-0.4c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0.8,0.3,2.4,1.4,3.6c-0.4,1.3-0.3,2.5-0.1,3.2c-0.2,0-0.5,0.1-0.7,0.1
		C2.1,16.2,1.6,15.6,1.3,13.7z M6.4,20.3c-0.4-0.2-0.8-0.5-1.3-0.8c-1.3-0.7-1.9-1.6-2.2-2.2c0.2,0,0.3-0.1,0.5-0.1
		c1.2,1.5,2.8,1.9,3.5,2.1C7,19.7,7.3,20.4,7.8,21C7.3,20.8,6.9,20.6,6.4,20.3z M16.1,20.3c-0.2,0.1-0.4,0.2-0.6,0.3
		c0.3-0.3,0.5-0.6,0.7-1c0.3,0,0.7-0.1,1.2-0.2c0.1,0,0.3-0.1,0.5-0.2C17.2,19.7,16.6,20,16.1,20.3z"
        />
      </g>
      <g>
        <g>
          <path
            d="M19,15.7c0,0.1,0.2,0.2,0.3,0.2h2.8c0.2,0,0.4-0.2,0.3-0.4L18.2,2.1c0-0.1-0.2-0.2-0.3-0.2h-3.7
			c-0.1,0-0.2,0.1-0.3,0.2L9.8,15.5c-0.1,0.2,0.1,0.4,0.3,0.4h2.6c0.1,0,0.3-0.1,0.3-0.2l0.8-3.1c0-0.1,0.2-0.2,0.3-0.2h3.6
			c0.1,0,0.3,0.1,0.3,0.2L19,15.7z M15.9,4.3C15.9,4.3,16,4.3,15.9,4.3l1.5,5.3c0,0.2-0.1,0.4-0.3,0.4h-2.3c-0.2,0-0.3-0.2-0.3-0.4
			L15.9,4.3z"
          />
        </g>
      </g>
    </Icon>
  );
};

export default AssistIcon;
