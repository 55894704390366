import { useQuery } from '@tanstack/react-query';

import { fetchPsApi, FetchPsApi } from './fetch';

interface UseQueryNifsCountryTournaments {
  queryKey?: string;
  queryKeyParams?: Record<string, unknown>;
  params: FetchPsApi;
  options?: Record<string, unknown>;
}

export const useQueryPsApi = ({
  queryKey = 'psapi',
  queryKeyParams,
  params,
  options,
}: UseQueryNifsCountryTournaments) => {
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchPsApi(params),
    enabled: Boolean(params.url),
    ...options,
  });

  return { data, isLoading, error, isFetching };
};
