import { MatchesType } from '@ntb-sport/types';
import styled from 'styled-components';

interface StyledProps {
  $matches?: MatchesType;
}

export const Container = styled.div<StyledProps>`
  z-index: 3;
  position: sticky;
  top: ${(props) => (props?.$matches?.tablet ? '52px' : '0px')};
  background: var(--background-color);
  box-shadow: inset 0 -1px 0 var(--border-color);
`;

export const PlayerContainer = styled.div`
  aspect-ratio: 16 / 9;
  max-height: 30vh;
  margin: 0 auto;
  position: relative;
`;
