import Kit from './Kit';
import ShirtNumber from './ShirtNumber';

const TwoColorCheckeredDiagonal = ({
  kitColor1 = '#f79025',
  kitColor2 = '#adadad',
  shirtNumber,
  shirtNumberColor,
  shirtNumberBackgroundColor,
  shirtNumberBackgroundOpacity,
  ...restProps
}: {
  size?: any;
  kitColor1: any;
  kitColor2: any;
  kitColor3: any;
  shirtNumber: any;
  shirtNumberColor: any;
  shirtNumberBackgroundColor: any;
  shirtNumberBackgroundOpacity: any;
}) => {
  const shadow = '#000000';

  return (
    <Kit {...restProps}>
      <g>
        <g>
          <g>
            <path
              style={{ fill: kitColor1 }}
              d="M55.79,2.81c0.29-0.12,0.78-0.24,1.34-0.33c1.99-0.36,3.98-0.11,5.97,0.05c0.05,0,0.11,0,0.16,0.01
                        c2.35,0.17,4.71,0.33,7.07,0.25h0.12c0.05,0,0.11,0,0.16-0.01c1.59-0.07,3.18-0.18,4.77-0.3c1.58-0.12,3.19-0.38,4.77-0.14
                        c0.74,0.11,1.55,0.19,2.26,0.43c0.04,0.01,0.07,0.03,0.11,0.04l0.08,0.12l1.13,1.74l0.9,1.31c0.01,0-0.04-0.01,0,0
                        C84.78,6.02,85,6.1,85.21,6.16c1.58,0.37,4.55,1.08,7.19,1.77c2.26,0.58,4.31,1.15,5.05,1.45c1.73,0.71,3.99,1.77,5.37,1.94
                        c1.39,0.2,3.47,0.72,4.86,1.25c0.08,0.03,0.16,0.07,0.25,0.11c0.55,0.25,1.27,0.72,2.04,1.3c0.45,0.3,0.88,0.66,1.3,1
                        c0.52,0.43,1.02,0.88,1.45,1.33c0.35,0.38,0.64,0.72,0.87,1.06c0.48,0.7,1.88,3.42,3.5,6.59c2.44,4.81,5.36,10.65,6.31,12.04
                        c0.16,0.23,0.42,0.64,0.74,1.21c1.51,2.57,4.62,8.2,7.31,13.12c0.88,1.61,1.73,3.13,2.45,4.46c1.34,2.44,2.29,4.19,2.44,4.45
                        c0.52,0.87,1.03,3.19,0.17,3.36c-0.76,0.15-6.66,2.65-10.19,5.05c-0.47,0.33-0.9,0.66-1.27,0.96c-3.12,2.68-9.03,5.69-9.71,6.04
                        c-0.5,0.25-0.86,0.21-1.26-0.07c-0.07-0.05-0.15-0.12-0.23-0.19c-0.09-0.08-0.19-0.16-0.28-0.28c-0.01,0-0.04-0.03-0.05-0.04
                        c-0.23-0.23-1.18-1.67-2.34-3.47c-2.02-3.16-4.71-7.47-5.26-8.37c-0.08-0.12-0.12-0.17-0.12-0.17c-0.64,1.42-0.95,2.93-1.06,4.34
                        c-0.11,1.23-0.07,2.37,0.01,3.28c0.09,1.04-0.16,4.33-0.33,7.61c-0.16,2.87-0.25,5.71-0.01,7.03c0.07,0.37,0.13,1.18,0.17,2.29
                        c0.12,2.42,0.2,6.36,0.27,10.61c0.05,3.39,0.11,6.96,0.15,10.17c0.08,4.92,0.16,8.89,0.28,9.76c0.35,2.48,0.86,7.43,0.7,9.92
                        c-0.03,0.51-0.05,1.03-0.07,1.57c-0.04,2.13,0.07,4.38,0.07,5.52c0,1.42-0.17,2.67-1.57,3.2c-0.62,0.23-3.46,1.03-8.29,1.78
                        c-3.63,0.56-8.38,1.1-14.18,1.35c-1.49,0.07-3.05,0.11-4.69,0.13c-2.53,0.04-5.22,0.01-8.08-0.08c-2.72,0.09-5.3,0.12-7.73,0.08
                        c-1.63-0.01-3.19-0.05-4.67-0.12c-5.81-0.24-10.58-0.76-14.25-1.31c-5.02-0.78-7.96-1.59-8.58-1.83
                        c-0.32-0.12-0.56-0.28-0.76-0.46c-0.7-0.66-0.8-1.65-0.8-2.75c0-1.07,0.11-3.15,0.07-5.17c0-0.66-0.01-1.3-0.07-1.92
                        c-0.17-2.49,0.35-7.45,0.7-9.92c0.01-0.08,0.03-0.17,0.03-0.32c0.11-1.26,0.17-4.78,0.24-9.09c0.05-3.39,0.11-7.26,0.16-10.86
                        c0.07-3.9,0.13-7.5,0.24-9.9c0.05-1.3,0.12-2.24,0.2-2.65c0.24-1.38,0.13-4.41-0.03-7.39c-0.19-3.15-0.42-6.24-0.32-7.25
                        c0.08-0.83,0.12-1.85,0.04-2.96c-0.08-1.42-0.36-2.99-0.99-4.45c-0.01-0.08-0.05-0.15-0.08-0.21c0,0-2.97,4.7-5.3,8.28
                        c-1.33,2.04-2.44,3.7-2.69,3.96c-0.7,0.71-0.87,0.7-1.57,0.35c-0.7-0.35-6.59-3.36-9.72-6.04c-0.32-0.27-0.67-0.54-1.07-0.82
                        c-3.48-2.45-9.6-5.04-10.38-5.2c-0.04-0.01-0.08-0.03-0.12-0.04c-0.7-0.35-0.19-2.49,0.29-3.32c0.13-0.23,0.92-1.67,2.08-3.74
                        c0.84-1.53,1.88-3.39,2.99-5.36c2.77-5.01,5.95-10.67,7.38-13.04c0.24-0.42,0.44-0.74,0.58-0.92c0.96-1.42,3.94-7.5,6.39-12.4
                        c1.54-3.09,2.87-5.72,3.33-6.4c1.22-1.77,4.5-4.25,5.91-4.79c1.38-0.52,3.47-1.04,4.86-1.25c1.38-0.17,3.62-1.29,5.37-1.94
                        c0.86-0.32,3.01-0.91,5.34-1.51c2.92-0.75,6.12-1.53,7.38-1.82L55.79,2.81z"
            />
          </g>
          <path
            style={{ fill: kitColor2 }}
            d="M48.94,10.6L38.79,20.74l-8.05-8.05l-0.13-0.13h0.01c1.38-0.52,3.47-1.04,4.86-1.25
                    c1.38-0.17,3.62-1.29,5.37-1.94c0.86-0.32,3.01-0.91,5.34-1.51l0.13,0.13L48.94,10.6z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M28.59,30.96L18.43,41.1l-4.02-4.02c0.24-0.42,0.44-0.74,0.58-0.92c0.96-1.42,3.94-7.5,6.39-12.4
                    L28.59,30.96z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M8.23,51.32l-3.72,3.71v0.01l-0.46,0.44c0.84-1.53,1.88-3.39,2.99-5.36l0.13,0.13L8.23,51.32z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M53.58,6.04c-0.64,1.85-0.52,7.38,1.7,10.83l-6.27-6.27l4.37-4.37l0.19-0.2"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M63.71,16.17c-0.79-0.4-1.62-0.8-2.45-1.21c0.25,0.16,1.06,0.68,2.06,1.59l-4.18,4.18l-2.62-2.62
                    c0.78-0.62,3.9-3.74,3.9-3.74s1.58,0.79,3.42,1.67L63.71,16.17z"
          />

          <rect
            x="31.62"
            y="23.78"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -10.5286 36.4973)"
            style={{ fill: kitColor2 }}
            width="14.35"
            height="14.35"
          />

          <rect
            x="11.26"
            y="44.14"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -30.8853 28.0656)"
            style={{ fill: kitColor2 }}
            width="14.35"
            height="14.35"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M89.66,10.6L79.5,20.74l-4.34-4.34c0.99-0.88,1.75-1.35,1.93-1.46c-0.76,0.37-1.53,0.75-2.26,1.12
                    l-0.12-0.12c1.9-0.92,3.58-1.77,3.58-1.77s2.58,3.98,2.75,3.84c3.7-3.01,4.35-10.1,3.6-12.04c0-0.03,0.01,0.03,0,0l0,0l0,0l0,0
                    l0.58,0.17L89.66,10.6z"
          />

          <rect
            x="51.98"
            y="23.78"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.5663 50.8918)"
            style={{ fill: kitColor2 }}
            width="14.35"
            height="14.35"
          />

          <rect
            x="31.62"
            y="44.14"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -24.9229 42.4598)"
            style={{ fill: kitColor2 }}
            width="14.35"
            height="14.35"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M27.37,70.45c-1.33,2.04-2.44,3.7-2.69,3.96c-0.68,0.71-0.87,0.7-1.55,0.35c-0.7-0.35-6.59-3.36-9.74-6.04
                    c-0.36-0.31-0.76-0.62-1.22-0.92l0.01-0.03l6.25-6.25l8.8,8.8L27.37,70.45z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M107.94,12.67l-8.08,8.08L89.72,10.6l2.6-2.61h0.01l0.07-0.07c2.26,0.58,4.31,1.15,5.05,1.45
                    c1.73,0.71,3.99,1.77,5.37,1.94c1.39,0.2,3.47,0.72,4.86,1.25C107.76,12.59,107.84,12.63,107.94,12.67z"
          />

          <rect
            x="72.33"
            y="23.78"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 1.3961 65.2861)"
            style={{ fill: kitColor2 }}
            width="14.35"
            height="14.35"
          />

          <rect
            x="51.98"
            y="44.14"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -18.9606 56.8543)"
            style={{ fill: kitColor2 }}
            width="14.35"
            height="14.35"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M48.94,71.67L38.79,81.81l-4.77-4.77l-0.13-0.13c-0.19-3.15-0.42-6.24-0.32-7.25
                    c0.08-0.83,0.12-1.85,0.04-2.96l0.13-0.13l5.05-5.05L48.94,71.67z"
          />

          <rect
            x="92.69"
            y="23.78"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 7.3584 79.6804)"
            style={{ fill: kitColor2 }}
            width="14.35"
            height="14.35"
          />

          <rect
            x="72.33"
            y="44.14"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -12.9983 71.2484)"
            style={{ fill: kitColor2 }}
            width="14.35"
            height="14.35"
          />

          <rect
            x="51.98"
            y="64.5"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -33.3549 62.8164)"
            style={{ fill: kitColor2 }}
            width="14.35"
            height="14.35"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M48.94,92.03l-10.15,10.14l-5.18-5.18l-0.13-0.13c0.07-3.9,0.13-7.5,0.24-9.88v-0.01l0.15-0.13v-0.01
                    l4.93-4.93L48.94,92.03z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M124.17,37.15l-0.04,0.04l-3.91,3.91l-10.14-10.14l7-7.02l0.04-0.04c2.46,4.83,5.45,10.79,6.4,12.21
                    C123.67,36.32,123.89,36.68,124.17,37.15z"
          />

          <rect
            x="92.69"
            y="44.14"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -7.0359 85.6427)"
            style={{ fill: kitColor2 }}
            width="14.35"
            height="14.35"
          />

          <rect
            x="72.33"
            y="64.5"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -27.3926 77.211)"
            style={{ fill: kitColor2 }}
            width="14.35"
            height="14.35"
          />

          <rect
            x="51.98"
            y="84.85"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -47.7493 68.7787)"
            style={{ fill: kitColor2 }}
            width="14.35"
            height="14.35"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M48.94,112.39l-10.15,10.14l-5.58-5.57c0,0,0,0,0-0.01l-0.13-0.12c0,0,0,0,0-0.01
                    c0.11-1.26,0.17-4.78,0.24-9.08c0-0.01,0-0.01,0-0.01l0.13-0.12v-0.01l5.34-5.34L48.94,112.39z"
          />

          <rect
            x="113.05"
            y="44.14"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -1.0736 100.0371)"
            style={{ fill: kitColor2 }}
            width="14.35"
            height="14.35"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M104.55,77.12l-4.69,4.69L89.72,71.67l10.14-10.15l5,5c-0.11,1.23-0.07,2.37,0.01,3.28
                    C104.96,70.82,104.72,73.95,104.55,77.12z"
          />

          <rect
            x="72.33"
            y="84.85"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -41.787 83.1734)"
            style={{ fill: kitColor2 }}
            width="14.35"
            height="14.35"
          />

          <rect
            x="51.98"
            y="105.21"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -62.1436 74.7411)"
            style={{ fill: kitColor2 }}
            width="14.35"
            height="14.35"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M48.94,132.74l-6.44,6.43l-0.09,0.09c-4.87-0.76-7.73-1.57-8.36-1.79c-0.38-0.15-0.67-0.35-0.9-0.59
                    c-0.7-0.66-0.8-1.65-0.8-2.75c0-1.07,0.11-3.15,0.07-5.17l0.15-0.13l6.23-6.24L48.94,132.74z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M134.04,54.92l-3.6-3.6l1-1l0.04-0.04C132.41,51.95,133.29,53.55,134.04,54.92z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M126.46,67.76c-0.47,0.33-0.9,0.66-1.27,0.96c-3.13,2.68-9.04,5.69-9.72,6.04c-0.55,0.27-0.92,0.2-1.39-0.2
                    c-0.07-0.05-0.15-0.12-0.23-0.19c-0.09-0.08-0.19-0.16-0.28-0.28c-0.01,0-0.04-0.03-0.05-0.04c-0.23-0.23-1.18-1.67-2.34-3.47
                    l0.03-0.03v-0.01l9.03-9.03l6.09,6.11L126.46,67.76z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M104.95,97.08l-0.12,0.12l-4.97,4.97L89.72,92.03l10.14-10.15l4.7,4.7v0.01l0.13,0.12v0.01
                    C104.82,89.11,104.9,92.93,104.95,97.08z"
          />

          <rect
            x="72.33"
            y="105.21"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -56.1813 89.1358)"
            style={{ fill: kitColor2 }}
            width="14.35"
            height="14.35"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M69.3,132.74l-7.88,7.86l-0.12,0.13c-1.54-0.03-3.01-0.07-4.42-0.12l-7.88-7.87l10.14-10.15L69.3,132.74z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M105.37,117.02l-5.5,5.5l-10.14-10.14l10.14-10.15l5.12,5.12v0.01l0.13,0.13
                    C105.19,112.05,105.26,115.82,105.37,117.02z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M89.66,132.74l-7.74,7.73l-0.15,0.15c-1.41,0.05-2.88,0.09-4.41,0.12l-8-8l10.14-10.15L89.66,132.74z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M106.09,134.27c0,1.42-0.17,2.67-1.57,3.2c-0.62,0.23-3.44,1.03-8.29,1.78l-6.51-6.51l10.14-10.15
                    l6.16,6.16C105.98,130.88,106.09,133.13,106.09,134.27z"
          />
          <path
            fill="none"
            d="M84.63,5.98L84.63,5.98C84.64,6.01,84.63,5.96,84.63,5.98c0.75,1.94,0.1,9.02-3.6,12.04
                    c-0.16,0.13-2.75-3.84-2.75-3.84s-1.67,0.84-3.58,1.77c-2.33,1.15-5.02,2.45-5.52,2.6c-0.5-0.15-3.12-1.39-5.36-2.49
                    c-1.83-0.88-3.42-1.67-3.42-1.67s-3.12,3.12-3.9,3.74l-1.25-1.25c-2.22-3.46-2.34-8.99-1.7-10.83"
          />
          <g>
            <path
              style={{ fill: shadow }}
              d="M83.19,5.94c-0.28,1.96-1.63,4.3-4.19,5.48c-4.03,1.86-9.63,6.15-9.63,6.15s-5.6-4.29-9.64-6.15
                        c-2.53-1.18-3.9-3.51-4.19-5.46c1.37-0.28,4.08-0.54,5.24-0.39c1.39,0.17,5.83,0.52,8.6,0.52c2.77,0,7.21-0.35,8.58-0.52
                        C79.11,5.41,81.81,5.65,83.19,5.94z"
            />
            <path
              opacity="0.16"
              d="M136.63,62.51c0.86-0.18,0.35-2.48-0.17-3.37c-0.3-0.51-3.93-7.08-7.31-13.12
                        c-6.25,2.94-14.52-0.07-23.84-13.57l0.34,9.94l0.12,19.66c0,0,7.03,11.31,7.73,12.01c0.69,0.71,1.25,0.86,1.95,0.51
                        c0.68-0.36,6.58-3.37,9.71-6.03C128.31,65.87,135.76,62.68,136.63,62.51z"
            />
            <path
              opacity="0.16"
              d="M1.39,62.63c-0.86-0.18-0.34-2.48,0.18-3.37c0.29-0.51,3.92-7.08,7.31-13.12
                        c6.25,2.94,15.6-0.46,24.23-14.08L32.55,45.6l-0.01,16.45c0,0-7.58,11.94-8.27,12.64c-0.7,0.71-1,0.35-1.69,0.01
                        c-0.69-0.36-6.59-3.37-9.72-6.03C9.73,66,2.26,62.81,1.39,62.63z"
            />
            <path
              opacity="0.16"
              d="M104.69,138.2c1.38-0.53,1.56-1.77,1.56-3.19c0-1.42-0.18-4.61,0-7.09c0.16-2.3-0.27-6.7-0.6-9.32
                        c-5.17,4.71-8.97,14.72-10.63,21.6C100.68,139.39,104.01,138.46,104.69,138.2z"
            />
            <path
              opacity="0.16"
              d="M33.96,138.13c-1.38-0.53-1.56-1.77-1.56-3.19c0-1.42,0.18-4.61,0-7.09c-0.15-2.3,0.27-6.7,0.61-9.32
                        c5.16,4.71,8.96,14.72,10.63,21.6C37.96,139.32,34.63,138.39,33.96,138.13z"
            />
            <g>
              <path
                d="M78.73,2.24c0.47,0,0.94,0.03,1.41,0.1c0.74,0.11,1.55,0.19,2.26,0.43c0.04,0.01,0.07,0.03,0.11,0.04l0.08,0.12
                            l2.03,3.06c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0-0.02-0.01-0.02-0.01s0,0,0.02,0.01c0,0,0,0,0,0h0c0,0,0,0,0,0
                            c0,0,0,0,0,0c0,0,0,0,0,0C84.78,6.02,85,6.1,85.21,6.16c1.58,0.37,4.55,1.08,7.19,1.77c2.26,0.58,4.31,1.15,5.05,1.45
                            c1.73,0.71,3.99,1.77,5.37,1.94c1.39,0.2,3.47,0.72,4.86,1.25c0.08,0.03,0.16,0.07,0.25,0.11c0.55,0.25,1.27,0.72,2.04,1.3
                            c0.45,0.3,0.88,0.66,1.3,1c0.52,0.43,1.02,0.88,1.45,1.33c0.35,0.38,0.64,0.72,0.87,1.06c0.48,0.7,1.88,3.42,3.5,6.59
                            c2.44,4.81,5.36,10.65,6.31,12.04c0.16,0.23,0.42,0.64,0.74,1.21c1.51,2.57,4.62,8.2,7.31,13.12c0.88,1.61,1.73,3.13,2.45,4.46
                            c1.34,2.44,2.29,4.19,2.44,4.45c0.52,0.87,1.03,3.19,0.17,3.36c-0.76,0.15-6.66,2.65-10.19,5.05c-0.47,0.33-0.9,0.66-1.27,0.96
                            c-3.12,2.68-9.03,5.69-9.71,6.04c-0.22,0.11-0.42,0.17-0.6,0.17c-0.23,0-0.43-0.08-0.66-0.24c-0.07-0.05-0.15-0.12-0.23-0.19
                            c-0.09-0.08-0.19-0.16-0.28-0.28c-0.01,0-0.04-0.03-0.05-0.04c-0.23-0.23-1.18-1.67-2.34-3.47c-2.02-3.16-4.71-7.47-5.26-8.37
                            c-0.08-0.12-0.12-0.17-0.12-0.17c-0.64,1.42-0.95,2.93-1.06,4.34c-0.11,1.23-0.07,2.37,0.01,3.28c0.09,1.04-0.16,4.33-0.33,7.61
                            c-0.16,2.87-0.25,5.71-0.01,7.03c0.07,0.38,0.13,1.18,0.17,2.29c0.12,2.42,0.2,6.36,0.27,10.61c0.05,3.39,0.11,6.96,0.15,10.17
                            c0.08,4.92,0.16,8.89,0.28,9.76c0.35,2.48,0.86,7.43,0.7,9.92c-0.03,0.51-0.05,1.03-0.07,1.57c-0.04,2.13,0.07,4.38,0.07,5.52
                            c0,1.42-0.17,2.67-1.57,3.2c-0.62,0.23-3.46,1.03-8.29,1.78c-3.63,0.56-8.38,1.1-14.18,1.35c-1.49,0.07-3.05,0.11-4.69,0.13
                            c-0.76,0.01-1.53,0.02-2.32,0.02c-1.84,0-3.76-0.03-5.76-0.1c-1.9,0.07-3.74,0.1-5.51,0.1c-0.75,0-1.5-0.01-2.22-0.02
                            c-1.63-0.01-3.19-0.05-4.67-0.12c-5.81-0.24-10.58-0.76-14.25-1.31c-5.02-0.78-7.96-1.59-8.58-1.83
                            c-0.32-0.12-0.56-0.28-0.76-0.46c-0.7-0.66-0.8-1.65-0.8-2.75c0-1.07,0.11-3.15,0.07-5.17c0-0.66-0.01-1.3-0.07-1.92
                            c-0.17-2.49,0.35-7.45,0.7-9.92c0.01-0.08,0.03-0.17,0.03-0.32c0.11-1.26,0.17-4.78,0.24-9.09c0.05-3.39,0.11-7.26,0.16-10.86
                            c0.07-3.9,0.13-7.5,0.24-9.9c0.05-1.3,0.12-2.24,0.2-2.65c0.24-1.38,0.13-4.41-0.03-7.39c-0.19-3.15-0.42-6.24-0.32-7.25
                            c0.08-0.83,0.12-1.85,0.04-2.96c-0.08-1.42-0.36-2.99-0.99-4.45c-0.01-0.08-0.05-0.15-0.08-0.21c0,0-2.97,4.7-5.3,8.28
                            c-1.33,2.04-2.44,3.7-2.69,3.96c-0.4,0.41-0.63,0.58-0.89,0.58c-0.19,0-0.39-0.09-0.68-0.23c-0.7-0.35-6.59-3.36-9.72-6.04
                            c-0.32-0.27-0.67-0.54-1.07-0.82c-3.48-2.45-9.6-5.04-10.38-5.2c-0.04-0.01-0.08-0.03-0.12-0.04c-0.7-0.35-0.19-2.49,0.29-3.32
                            c0.13-0.23,0.92-1.67,2.08-3.74c0.84-1.53,1.88-3.39,2.99-5.36c2.77-5.01,5.95-10.67,7.38-13.04c0.24-0.42,0.44-0.74,0.58-0.92
                            c0.96-1.42,3.94-7.5,6.39-12.4c1.54-3.09,2.87-5.72,3.33-6.4c1.22-1.77,4.5-4.25,5.91-4.79c1.38-0.52,3.47-1.04,4.86-1.25
                            c1.38-0.17,3.62-1.29,5.37-1.94c0.86-0.32,3.01-0.91,5.34-1.51c2.92-0.75,6.12-1.53,7.38-1.82l2.21-3.23
                            c0.29-0.12,0.78-0.24,1.34-0.33c0.75-0.14,1.49-0.18,2.24-0.18c1.24,0,2.49,0.14,3.73,0.24c0.05,0,0.11,0,0.16,0.01
                            c1.94,0.14,3.89,0.27,5.83,0.27c0.41,0,0.83-0.01,1.24-0.02l0.28-0.01c1.59-0.07,3.18-0.18,4.77-0.3
                            C76.49,2.39,77.61,2.24,78.73,2.24 M78.73,1.24L78.73,1.24c-0.84,0-1.67,0.08-2.47,0.16c-0.32,0.03-0.64,0.06-0.97,0.09
                            c-1.82,0.14-3.32,0.24-4.73,0.3L70.28,1.8c-0.39,0.01-0.79,0.02-1.19,0.02c-1.83,0-3.67-0.12-5.7-0.27
                            c-0.1-0.02-0.18-0.02-0.25-0.02l-0.71-0.06c-1-0.09-2.03-0.18-3.07-0.18c-0.88,0-1.67,0.07-2.42,0.2
                            c-0.44,0.07-1.07,0.2-1.54,0.39c-0.18,0.07-0.34,0.2-0.45,0.36l-1.99,2.91c-1.51,0.36-4.41,1.07-7.02,1.74
                            c-2.83,0.73-4.66,1.25-5.45,1.55c-0.55,0.21-1.15,0.46-1.74,0.7c-1.21,0.51-2.59,1.08-3.41,1.18c-1.55,0.22-3.69,0.77-5.09,1.3
                            c-1.69,0.66-5.08,3.28-6.38,5.16c-0.48,0.7-1.66,3.02-3.41,6.52c-0.42,0.84-0.86,1.72-1.29,2.6c-2,4.02-4.26,8.57-5.03,9.69
                            c-0.18,0.25-0.44,0.68-0.61,0.98c-1.7,2.82-5.74,10.07-7.39,13.06C5.05,51.6,4.02,53.47,3.17,55c-0.63,1.12-1.15,2.06-1.51,2.72
                            c-0.3,0.54-0.49,0.89-0.55,0.99c-0.48,0.83-1,2.64-0.67,3.73c0.17,0.58,0.52,0.86,0.79,0.99c0.04,0.02,0.09,0.04,0.13,0.05
                            l0.06,0.02l0.06,0.02c0.04,0.01,0.07,0.02,0.11,0.03c0.7,0.16,6.63,2.66,10.01,5.03c0.36,0.25,0.69,0.5,1.01,0.77
                            c3.37,2.88,9.65,6.03,9.92,6.17c0.36,0.18,0.71,0.34,1.12,0.34c0.68,0,1.15-0.42,1.6-0.88c0.13-0.14,0.42-0.44,2.82-4.12
                            c1.43-2.19,3.11-4.82,4.19-6.52c0.18,0.76,0.3,1.56,0.35,2.42c0.07,0.93,0.05,1.86-0.04,2.81c-0.08,0.86,0.03,2.71,0.21,5.68
                            l0.11,1.72c0.13,2.42,0.28,5.81,0.04,7.16c-0.08,0.44-0.16,1.35-0.21,2.78c-0.11,2.39-0.17,5.91-0.24,9.92l-0.06,3.92
                            c-0.03,2.38-0.07,4.76-0.1,6.94c-0.06,3.86-0.13,7.73-0.24,9.02c0,0.03,0,0.06,0,0.08c0,0.07-0.01,0.12-0.01,0.16
                            c-0.38,2.72-0.88,7.65-0.71,10.16c0.05,0.52,0.06,1.08,0.06,1.85c0.03,1.3-0.01,2.61-0.04,3.67c-0.02,0.6-0.03,1.11-0.03,1.5
                            c0,1.26,0.14,2.55,1.12,3.47c0.35,0.31,0.7,0.52,1.1,0.66c0.6,0.23,3.5,1.07,8.78,1.89c4.47,0.67,9.3,1.11,14.36,1.32
                            c1.49,0.07,3.07,0.11,4.71,0.12c0.72,0.01,1.47,0.02,2.23,0.02c1.76,0,3.61-0.03,5.51-0.1c1.99,0.07,3.92,0.1,5.76,0.1
                            c0.79,0,1.57-0.01,2.34-0.02c1.82-0.03,3.36-0.07,4.72-0.13c5.08-0.22,9.89-0.68,14.29-1.36c4.96-0.77,7.86-1.6,8.48-1.83
                            c2.22-0.85,2.22-3.07,2.22-4.14c0-0.41-0.01-0.96-0.03-1.6c-0.03-1.13-0.06-2.54-0.04-3.9c0.01-0.52,0.04-1.03,0.07-1.53
                            c0.16-2.54-0.35-7.56-0.7-10.12c-0.13-0.93-0.22-6.6-0.27-9.64c-0.04-3.2-0.09-6.78-0.15-10.16c-0.06-3.57-0.14-8-0.27-10.64
                            c-0.04-1.1-0.11-1.96-0.19-2.42c-0.27-1.49-0.05-5.49,0.03-6.8c0.05-0.9,0.1-1.79,0.15-2.64c0.14-2.39,0.26-4.28,0.18-5.11
                            c-0.09-1.07-0.1-2.12-0.01-3.11c0.06-0.74,0.17-1.45,0.33-2.12c1.09,1.74,2.82,4.51,4.28,6.78c1.83,2.83,2.25,3.41,2.48,3.64
                            c0.02,0.02,0.04,0.04,0.07,0.06c0.12,0.14,0.23,0.23,0.32,0.31c0.1,0.08,0.18,0.15,0.25,0.21c0.02,0.01,0.03,0.03,0.05,0.04
                            c0.4,0.28,0.8,0.42,1.23,0.42c0.35,0,0.69-0.09,1.06-0.28c1.57-0.8,6.92-3.61,9.9-6.17c0.31-0.26,0.71-0.56,1.2-0.91
                            c3.46-2.34,9.16-4.74,9.81-4.88c0.48-0.1,0.86-0.41,1.07-0.89c0.51-1.15-0.1-3.16-0.58-3.97c-0.07-0.11-0.35-0.63-0.78-1.43
                            c-0.42-0.77-0.98-1.8-1.63-2.98c-0.51-0.94-1.08-1.98-1.69-3.07l-0.76-1.39c-1.6-2.93-5.52-10.08-7.33-13.15
                            c-0.33-0.58-0.6-1.01-0.78-1.28c-0.79-1.15-3.19-5.9-5.31-10.09l-0.92-1.82c-1.83-3.58-3.06-5.98-3.57-6.71
                            c-0.23-0.34-0.54-0.72-0.96-1.17c-0.47-0.49-1-0.97-1.55-1.42l-0.13-0.1c-0.39-0.32-0.79-0.65-1.23-0.94
                            c-0.6-0.45-1.45-1.05-2.19-1.39l-0.09-0.04c-0.09-0.04-0.18-0.08-0.26-0.11c-1.39-0.52-3.52-1.07-5.04-1.29
                            c-0.99-0.13-2.63-0.82-4.08-1.43c-0.36-0.15-0.72-0.31-1.05-0.44c-1.1-0.44-4.24-1.25-5.18-1.49c-2.86-0.74-6.13-1.52-7.21-1.77
                            c-0.06-0.01-0.12-0.03-0.18-0.05l-1.83-2.76l-0.08-0.12c-0.12-0.18-0.3-0.32-0.52-0.39c-0.03-0.01-0.07-0.03-0.11-0.04
                            c-0.66-0.22-1.37-0.32-2-0.41L80.3,1.35C79.81,1.28,79.3,1.24,78.73,1.24L78.73,1.24z"
              />
            </g>
          </g>
        </g>
      </g>
      <ShirtNumber
        shirtNumber={shirtNumber}
        shirtNumberColor={shirtNumberColor}
        shirtNumberBackgroundColor={shirtNumberBackgroundColor}
        shirtNumberBackgroundOpacity={shirtNumberBackgroundOpacity}
      />
    </Kit>
  );
};

export default TwoColorCheckeredDiagonal;
