import { MEDIA_QUERY } from '@ntb-sport/constants';
import styled from 'styled-components';

interface StyledProps {
  $side?: string;
}

export const HomeEvents = styled.div`
  justify-self: stretch;
  align-self: flex-start;
  grid-area: homeevents;
`;

export const AwayEvents = styled.div`
  justify-self: stretch;
  align-self: flex-start;
  grid-area: awayevents;
`;

export const EventWrapper = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  padding: ${(props) => (props.$side === 'home' ? '0 0 0 10px' : '0 10px 0 0')};
`;

export const Event = styled.div``;

export const EventPerson = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 10px;
  align-items: center;
  font-size: var(--ntb-fontSizes-xs);
  padding: 3px 0;

  @container team-match-result-container ${MEDIA_QUERY.MOBILE_L} {
    grid-column-gap: 40px;
  }

  @container team-match-result-container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const PersonName = styled.div`
  padding: 0;
  font-size: var(--ntb-fontSizes-xs);

  @container team-match-result-container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
