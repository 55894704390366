import * as S from './Bench.styled';
import Kit from '../../../../Kit/Kit';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

interface BenchProps {
  bench: any;
  kit: any;
  onClickPerson: any;
}

export const Bench = ({ bench, kit, onClickPerson }: BenchProps) => {
  const themeContext = useContext(ThemeContext);

  return (
    <S.BenchContainer>
      <S.BenchTitle as="h3">Innbyttere</S.BenchTitle>
      <S.Bench>
        {bench
          .filter((player: any) => player.position && player.position.id === 11)
          .map((person: any) => (
            <BenchPlayer
              key={person.id}
              person={person}
              kit={kit}
              theme={themeContext}
              onClickPerson={onClickPerson}
            />
          ))}
        {bench
          .filter((player: any) => !player.position)
          .sort((a: any, b: any) => a.shirtNumber - b.shirtNumber)
          .map((person: any) => (
            <BenchPlayer
              key={person.id}
              person={person}
              kit={kit}
              theme={themeContext}
              onClickPerson={onClickPerson}
            />
          ))}
      </S.Bench>
    </S.BenchContainer>
  );
};

const BenchPlayer = ({
  person,
  kit,
  theme,
  onClickPerson,
}: {
  person: any;
  kit: any;
  theme: any;
  onClickPerson: any;
}) => {
  const shirtName =
    person.shirtName.length > 9
      ? `${person.shirtName.substr(0, 9)}.`
      : person.shirtName;

  const isKeeper =
    person.position && (person.position.id === 1 || person.position.id === 11);

  const goalCount =
    person.personEvents.goals && person.personEvents.goals.length;
  const assistCount =
    person.personEvents.assists && person.personEvents.assists.length;
  const substituteOffTime =
    (person.personEvents.substituteOff &&
      person?.personEvents?.substituteOff?.[0]?.timeRounded) ||
    person?.personEvents?.substituteOff?.[0]?.time;
  const substituteOnTime =
    (person.personEvents.substituteOn &&
      person?.personEvents?.substituteOn?.[0]?.timeRounded) ||
    person?.personEvents?.substituteOn?.[0]?.time;
  const yellowCardTime =
    (person.personEvents.yellowCards &&
      person?.personEvents?.yellowCards?.[0]?.timeRounded) ||
    person?.personEvents?.yellowCards?.[0]?.time;
  const redCardTime =
    (person.personEvents.redCards &&
      person?.personEvents?.redCards?.[0]?.timeRounded) ||
    person?.personEvents?.redCards?.[0]?.time;

  const ariaShirtNumber = `, draktnummer ${person.shirtNumber}`;
  const ariaGoals = goalCount ? `, antall mål ${goalCount}` : '';
  const ariaAssists = assistCount ? `, antall målgivende ${assistCount}` : '';
  const ariaSubstituteOff = substituteOffTime
    ? `, byttet ut i spillerminutt ${substituteOffTime}`
    : '';
  const ariaSubstituteOn = substituteOnTime
    ? `, byttet inn i spillerminutt ${substituteOnTime}`
    : '';
  const ariaYellowCard = yellowCardTime
    ? `, gult kort i spillerminutt ${yellowCardTime}`
    : '';
  const ariaRedCard = redCardTime
    ? `, rødt kort i spillerminutt ${redCardTime}`
    : '';
  return (
    <S.BenchPerson
      $isClickable={onClickPerson}
      onClick={() =>
        onClickPerson &&
        onClickPerson({ name: person.name, id: person.personId, sportId: 1 })
      }
      aria-label={`${person.shirtName}${ariaShirtNumber}${ariaGoals}${ariaAssists}${ariaSubstituteOff}${ariaSubstituteOn}${ariaYellowCard}${ariaRedCard}`}
    >
      <Kit
        type={kit && kit.patternId}
        size={45}
        kitColor1={isKeeper ? 'grey' : kit && kit.colorCodes[0]}
        kitColor2={isKeeper ? 'grey' : kit && kit.colorCodes[1]}
        shirtNumber={person.shirtNumber}
      />
      <S.ShirtName>{shirtName}</S.ShirtName>

      {(person.personEvents.goals ||
        person.personEvents.assists ||
        person.personEvents.yellowCards ||
        (person.personEvents.yellowCards &&
          person.personEvents.yellowCards[1]) ||
        person.personEvents.redCards ||
        person.personEvents.substituteOn) && (
        <S.Events>
          {person.personEvents.goals && (
            <S.Event>
              <S.GoalIcon size="small" />
              {person.personEvents.goals.length > 1 && (
                <S.Time>x{person.personEvents.goals.length}</S.Time>
              )}
            </S.Event>
          )}

          {person.personEvents.assists && (
            <S.Event>
              <S.AssistIcon size="small" />
              {person.personEvents.assists.length > 1 && (
                <S.Time>x{person.personEvents.assists.length}</S.Time>
              )}
            </S.Event>
          )}

          {person.personEvents.yellowCards && (
            <S.Event>
              <S.YellowCardIcon
                size="small"
                cardId="yellow-card"
                fillColor="var(--base-colors-warning)"
              />
              <S.Time>
                {person.personEvents.yellowCards?.[0]?.timeRounded ||
                  person.personEvents.yellowCards?.[0]?.time}
                {"'"}
              </S.Time>
            </S.Event>
          )}

          {person.personEvents.yellowCards &&
            person.personEvents.yellowCards[1] && (
              <S.Event>
                <S.SecondYellowCardIcon
                  cardId="red"
                  size="small"
                  fillColor1="var(--base-colors-warning)"
                  fillColor2="var(--base-colors-error)"
                />
                <S.Time>
                  {person.personEvents.yellowCards?.[1]?.timeRounded ||
                    person.personEvents.yellowCards?.[1]?.time}
                  {"'"}
                </S.Time>
              </S.Event>
            )}

          {person.personEvents.redCards && (
            <S.Event>
              <S.RedCardIcon
                fillColor="var(--base-colors-error)"
                cardId="red-card"
                size="small"
              />
              <S.Time>
                {person.personEvents.redCards?.[0]?.timeRounded ||
                  person.personEvents.redCards?.[0]?.time}
                {"'"}
              </S.Time>
            </S.Event>
          )}

          {person.personEvents.substituteOn && (
            <S.Event>
              <S.SubstititeIcon
                size="small"
                fillColor1="var(--base-colors-success)"
                fillColor2="var(--base-colors-error)"
              />
              <S.Time>
                {person?.personEvents?.substituteOn?.[0]?.timeRounded ||
                  person?.personEvents?.substituteOn?.[0]?.time}
                {"'"}
              </S.Time>
            </S.Event>
          )}
        </S.Events>
      )}
    </S.BenchPerson>
  );
};

export default Bench;
