import { MEDIA_QUERY } from '@ntb-sport/constants';

import styled from 'styled-components';

interface StyledProps {
  $isInVideoPlayer?: boolean;
}

export const Container = styled.div<StyledProps>`
  display: flex;
  align-items: center;

  background: var(--background-color);
  color: var(--font-color);
  padding: var(--ntb-space-2) var(--ntb-space-2);
  position: relative;
`;

export const HeaderContainerQuery = styled.div`
  container-name: container;
  container-type: inline-size;
  width: 100%;
`;

export const Header = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: var(--ntb-space-2);
  row-gap: var(--ntb-space-2);
  cursor: pointer;
`;

export const ToggleButton = styled.button`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-semibold);

  svg {
    width: 1.4em;
    height: 1.4em;
  }

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
    font-weight: var(--ntb-fontWeights-medium);
  }
`;

export const AdditionalInfo = styled.div`
  container-name: container;
  container-type: inline-size;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 9999;
  background: inherit;
  padding: var(--ntb-space-2);
`;

export const DefinitionList = styled.dl<StyledProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--ntb-space-2);
  column-gap: var(--ntb-space-2);
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-semibold);

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
    font-weight: var(--ntb-fontWeights-medium);
  }
`;

export const Definition = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const DefinitionTerm = styled.dt`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  svg {
    fill: var(--header-font-color);
    margin-right: var(--ntb-space-1);
  }
`;

export const DefinitionDescription = styled.dd`
  margin: 0;
  &:first-letter {
    text-transform: capitalize;
  }
  > div {
    display: flex;
    column-gap: var(--ntb-space-2);
  }
`;

