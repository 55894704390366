import { useBem } from '@ntb-sport/hooks';
import { Participants } from '../Participants/Participants';
import './pairs.css';
import { COMPONENT_IDS } from '@ntb-sport/constants';

interface PairsProps {
  groupByPair: any;
  highlightedCountry: string;
}

export const Pairs = ({ groupByPair, highlightedCountry }: PairsProps) => {
  const bem = useBem(COMPONENT_IDS.EVENT_PARTICIPANTS);
  return (
    <>
      {Object.values(groupByPair)?.map(
        (eventParticipants: any, index: number) => (
          <div className={bem('pair')} key={index}>
            <h4 className={bem('pair-name')}>Par {index + 1}</h4>
            <Participants
              eventParticipants={eventParticipants}
              highlightedCountry={highlightedCountry}
            />
          </div>
        ),
      )}
    </>
  );
};
