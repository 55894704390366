import IconButton from '../../chakra/IconButton/IconButton';
import * as S from './TeamHero.styled';

import { CloseIcon } from '@chakra-ui/icons';

interface TeamHeroProps {
  team: any;
  onCloseTeamDrawer?: any;
}

export const TeamHero = ({ team, onCloseTeamDrawer }: TeamHeroProps) => {
  const teamBackgroundColor =
    team?.clubs && team?.clubs?.[0] && team?.clubs?.[0]?.primaryColor;
  const teamTextColor =
    team?.clubs && team?.clubs?.[0] && team?.clubs?.[0]?.textColor;

  const mainStaff = team?.staff?.find(
    (staff: any) => staff.employmentId === 2 || staff.employmentId === 3,
  );

  const mainStaffLabel =
    mainStaff && mainStaff.employmentId === 2
      ? 'Hovedtrener'
      : mainStaff && mainStaff.employmentId === 3
        ? 'Manager'
        : '';

  return (
    <S.Container $teamBackgroundColor={teamBackgroundColor}>
      <S.ContainerCenter>
        {team?.logo && (
          <S.LogoContainer $teamBackgroundColor={teamBackgroundColor}>
            <S.Logo src={team?.logo?.url} alt={team?.name} />
          </S.LogoContainer>
        )}

        <S.NameAndCountry>
          <S.Name $teamTextColor={teamTextColor}>{team?.name}</S.Name>
          {team?.country?.name !== 'UEFA' && team?.country?.name !== 'FIFA' && (
            <S.Country $teamTextColor={teamTextColor}>
              <S.Flag countryName={team?.country?.name} />
              {team?.country?.name}
            </S.Country>
          )}
          {mainStaff?.name && (
            <S.Staff $teamTextColor={teamTextColor}>
              {`${mainStaffLabel}: ${mainStaff?.name}`}
            </S.Staff>
          )}
        </S.NameAndCountry>
      </S.ContainerCenter>
      {onCloseTeamDrawer && (
        <IconButton
          size="sm"
          aria-label="Lukk lagside"
          onClick={onCloseTeamDrawer}
          position="absolute"
          top={2}
          right={2}
        >
          <CloseIcon />
        </IconButton>
      )}
    </S.Container>
  );
};

export default TeamHero;
