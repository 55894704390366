import { Fetch } from '../../fetch';

export interface FetchCountryMedalsType {
  apiBaseUrl: string;
  medalTableUUID: string;
  countryUUID: string;
  token: string;
  customerUUID: string;
}

export const fetchCountryMedals = async ({
  apiBaseUrl,
  medalTableUUID,
  countryUUID,
  token,
  customerUUID,
}: FetchCountryMedalsType) => {
  const json = await Fetch({
    url: `${apiBaseUrl}/medalTables/${medalTableUUID}/countries/${countryUUID}`,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });

  return json?.data;
};
