import { LoanMadePermanentIcon, PlayerTransferInterface } from '@ntb-sport/ui';
import { TransferIcon } from '../../../icons/TransferIcons/TransferIcon';
import { getAlpha2Code } from '../../../common/CountryFlag/languages';

export const initialViewCount = 30;

export const ALL_IN_OUT = {
  ALLE: 'alle',
  INN: 'inn',
  UT: 'ut',
};

export const TRANSFER_TYPE_FILTER_OPTIONS = {
  ALLE: 'Alle',
  OVERGANG: 'Overgang',
  LÅN: 'Lån',
};

interface TransferTypes {
  [key: string]: string;
}
export const TRANSFER_TYPES: TransferTypes = {
  Transfer: 'Overgang',
  TransferLoanMadePermanent: 'Lån gjort permanent',
  TransferLoan: 'Lån',
  TransferLoanReturn: 'Lån avsluttet',
  TransferHungUpBoots: 'Lagt opp',
};

export const handleTransferType = (type: string): string => {
  type = type.slice(11);

  return TRANSFER_TYPES[type] ?? '-';
};

export const playerNationality = (transfer: PlayerTransferInterface) => {
  return transfer?.person?.country ? transfer?.person?.country?.name : 'Ukjent';
};

export function calculateYearsBetween(date1: Date, date2: Date) {
  const startDate = new Date(date1);
  const endDate = new Date(date2);

  let years = endDate.getFullYear() - startDate.getFullYear();
  const monthDifference = endDate.getMonth() - startDate.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && endDate.getDate() < startDate.getDate())
  ) {
    years--;
  }
  return years;
}

export const transferPlayerImg = (
  transfer: PlayerTransferInterface,
  gender: string | undefined,
) => {
  gender ? gender : undefined;
  return transfer?.person?.profilePictures
    ? transfer?.person?.profilePictures[0]?.url
    : gender === 'women' || gender === 'woman'
      ? '/profile_default_women.png'
      : '/profile_default_men.png';
};

export const TransferPositions = {
  Keeper: 'Målvakt',
  Defender: 'Forsvar',
  Midfielder: 'Midtbane',
  Attacker: 'Angrep',
};

export const handlePositionType = (position: string | null): string | null => {
  if (!position) return null;
  if (position === 'Not a player') return '-';
  return TransferPositions[position as keyof typeof TransferPositions] || null;
};

export const displayTransferType = (transfer: PlayerTransferInterface) => {
  return (
    <p>
      {handleTransferType(transfer.type) ===
      TRANSFER_TYPES.TransferLoanMadePermanent ? (
        <LoanMadePermanentIcon />
      ) : (
        handleTransferType(transfer.type) === TRANSFER_TYPES.Transfer && (
          <TransferIcon />
        )
      )}

      {transfer?.toDate &&
      handleTransferType(transfer.type) === TRANSFER_TYPES.TransferLoan ? (
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 'var(--ntb-space-1)',
          }}
        >
          <span style={{ fontWeight: 'light' }}>På lån til</span>
          <span style={{ fontWeight: 'var(--ntb-fontWeights-medium)' }}>
            {transfer.toDate && formatTransferDate(transfer.toDate)}
          </span>
        </span>
      ) : (
        handleTransferType(transfer.type)
      )}
    </p>
  );
};

/**
 * TODO: Backend Bug: Missing team country, possibly because of isALoan or missing transferID
 * Checking if toTeam/fromTeam is the same as transferCountry(team or tournament country), or both teams are from the same country.
 * If not, return country name and display flag
 * @param transfer
 * @param toFrom
 * @param transferCountry
 * @returns {string | null}
 */
export const CountrySelect = (
  transfer: PlayerTransferInterface,
  toFrom: ToFromTeamType,
  transferCountry?: string | null,
) => {
  const fromCountryId = transfer?.fromTeam?.country?.id;
  const toCountryId = transfer?.toTeam?.country?.id;
  const transferCountryName = transfer?.[toFrom]?.country?.name;

  switch (true) {
    case fromCountryId && toCountryId && fromCountryId === toCountryId:
      return null;
    case Boolean(transferCountryName) &&
      transferCountry === getAlpha2Code(transferCountryName, 'nb'):
      return null;
    default:
      return transferCountryName || null;
  }
};

export const formatTransferDate = (date: string) => {
  return new Date(date).toLocaleDateString('no-NO', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export function LinkWrapper({
  children,
  LinkComponent,
  href,
}: {
  children: any;
  LinkComponent: React.ComponentType<any> | null | undefined;
  href: string;
}) {
  return LinkComponent ? (
    <LinkComponent href={href}>{children}</LinkComponent>
  ) : (
    <a href={href}>{children}</a>
  );
}

export type ToFromTeamType = (typeof TO_FROM_TEAM)[keyof typeof TO_FROM_TEAM];

export const TO_FROM_TEAM = {
  TO: 'toTeam' as const,
  FROM: 'fromTeam' as const,
};
