import { COMPONENT_IDS, SPORT_GROUP } from '@ntb-sport/constants';
import { WidgetConfig, SportsdataConfig } from '@ntb-sport/types';
import {
  DATA_TYPES,
  WIDGET_IDS,
  WIDGET_OPTIONS,
  WIDGET_VERSION,
} from '../common';

export const config: WidgetConfig = {
  id: WIDGET_IDS.CUSTOMER_FEEDBACK,
  dataType: DATA_TYPES.NIFS,
  version: WIDGET_VERSION,
  displayName: 'Tilbakemeldingsskjema',
  widgetNamespace: 'NtbWidgetCustomerFeedback',
  iframeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.CUSTOMER_FEEDBACK}/iframe.html`,
  widgetUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.CUSTOMER_FEEDBACK}/${WIDGET_IDS.CUSTOMER_FEEDBACK}.js`,
  themeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/themes/default.css`,
  title: 'Tilbakemeldingsskjema',
  summary: 'Tilbakemeldingsskjema med slack integrasjon',
  sportGroups: [SPORT_GROUP.FOOTBALL, SPORT_GROUP.HANDBALL],
  description: 'general description to be displayed on the documentation page',
  components: [COMPONENT_IDS.CUSTOMER_FEEDBACK],
  options: {
    [WIDGET_OPTIONS.CUSTOMER_ID]: { isRequired: true },
    [WIDGET_OPTIONS.ERROR_MESSAGE]: { isRequired: false },
    [WIDGET_OPTIONS.PRODUCT_ID]: { isRequired: true },
    [WIDGET_OPTIONS.ON_ERROR]: { isRequired: false },
    [WIDGET_OPTIONS.SPORT_GROUP]: { isRequired: false },
    [WIDGET_OPTIONS.STYLE_ID]: { isRequired: false },
    [WIDGET_OPTIONS.TARGET_ID]: { isRequired: true },
  },
  uiOptions: {},
};

export const sportsdataConfig: SportsdataConfig = {
  dataPickerGroups: {},
  optionGroups: {},
};
