import styled from 'styled-components';

export const Container = styled.div`
  padding: var(--ntb-space-4);
`;

export const Section = styled.div`
  margin-bottom: var(--ntb-space-8);
`;

export const SectionTitle = styled.h2`
  font-size: var(--ntb-fontSizes-xl);
  font-weight: var(--ntb-fontWeights-medium);
  margin-bottom: var(--ntb-space-2);
`;

export const Players = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
