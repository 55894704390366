import { NeoScopeResultType } from '@ntb-sport/types';
import { FaTshirt } from 'react-icons/fa';

import { JerseyIcon } from '../../../../../icons/index';
import { Tooltip } from '../../../../../chakra';
import './jerseys.css';
import { useBem } from '@ntb-sport/hooks';

export const GIRO_JERSEYS = {
  overall: <JerseyIcon jerseyId="overall" fillColor1="#FDBBC3" />,
  climb: <JerseyIcon jerseyId="climb" fillColor="#fff" fillColor2="#0079D8" />,
  sprint: <JerseyIcon jerseyId="sprint" fillColor1="#CB5C96" />,
  youth: <JerseyIcon jeyrseyId="youth" fillColor1="#ffffff" />,
  ['poengtrøya']: <JerseyIcon jerseyId="sprint" fillColor1="#CB5C96" />,
  ['klatretrøya']: (
    <JerseyIcon jerseyId="climb" fillColor="#fff" fillColor2="#0079D8" />
  ),
  ['ungdomstrøya']: <JerseyIcon jeyrseyId="youth" fillColor1="#ffffff" />,
  ['ledertrøya']: <JerseyIcon jerseyId="overall" fillColor1="#FDBBC3" />,
};

export const TDF_JERSEYS = {
  overall: <JerseyIcon jerseyId="overall" fillColor1="#EBD027" />,
  climb: <JerseyIcon jerseyId="climb" fillColor="#fff" fillColor2="#FF1523" />,
  sprint: <JerseyIcon jerseyId="sprint" fillColor1="#2E6A5C" />,
  youth: <JerseyIcon jeyrseyId="youth" fillColor1="#ffffff" />,
  ['poengtrøya']: <JerseyIcon jerseyId="sprint" fillColor1="#2E6A5C" />,
  ['klatretrøya']: (
    <JerseyIcon jerseyId="climb" fillColor="#fff" fillColor2="#FF1523" />
  ),
  ['ungdomstrøya']: <JerseyIcon jeyrseyId="youth" fillColor1="#ffffff" />,
  ['ledertrøya']: <JerseyIcon jerseyId="overall" fillColor1="#EBD027" />,
};

export const VUELTA_JERSEYS = {
  overall: <FaTshirt fill="#FB4144" />,
  climb: <FaTshirt fill="#024BE8" />,
  sprint: <FaTshirt fill="#003E5A" />,
  youth: <FaTshirt fill="#DDE9F5" />,
  ['poengtrøya']: <FaTshirt fill="#003E5A" />,
  ['klatretrøya']: <FaTshirt fill="#024BE8" />,
  ['ungdomstrøya']: <FaTshirt fill="#DDE9F5" />,
  ['ledertrøya']: <FaTshirt fill="#FB4144" />,
};

const JERSEY_TOOLTIPS = {
  overall: 'Ledertrøye',
  climb: 'Klatretrøye',
  sprint: 'Poengtrøye',
  youth: 'Ungdomstrøye',
  ['poengtrøya']: 'Poengtrøye',
  ['klatretrøya']: 'Klatretrøye',
  ['ungdomstrøya']: 'Ungdomstrøye',
  ['ledertrøya']: 'Ledertrøye',
};

function getJerseysByTourCompetitionUuid(
  tourCompetitionUuid?: string,
  type?: string,
) {
  if (tourCompetitionUuid === 'competition-cycling-giro-ditalia') {
    return (
      <Tooltip label={JERSEY_TOOLTIPS[type as keyof typeof JERSEY_TOOLTIPS]}>
        <span>{GIRO_JERSEYS[type as keyof typeof GIRO_JERSEYS] || type}</span>
      </Tooltip>
    );
  } else if (tourCompetitionUuid === 'competition-cycling-tour-de-france') {
    return (
      <Tooltip label={JERSEY_TOOLTIPS[type as keyof typeof JERSEY_TOOLTIPS]}>
        <span>{TDF_JERSEYS[type as keyof typeof TDF_JERSEYS] || type}</span>
      </Tooltip>
    );
  } else if (
    tourCompetitionUuid === 'competition-cycling-tour-vuelta-a-espana'
  ) {
    return (
      <Tooltip label={JERSEY_TOOLTIPS[type as keyof typeof JERSEY_TOOLTIPS]}>
        <span>
          {VUELTA_JERSEYS[type as keyof typeof VUELTA_JERSEYS] || type}
        </span>
      </Tooltip>
    );
  }
}

interface JerseysProps {
  scopeResult: NeoScopeResultType;
  tourCompetitionUuid?: string;
}

export const Jerseys = ({ scopeResult, tourCompetitionUuid }: JerseysProps) => {
  const bem = useBem('scope-results');

  return (
    <ul className={bem('jerseys')}>
      {scopeResult?.participant?.tourStandingLeader?.map(
        (tourStanding: any) => (
          <li className={bem('jersey')} key={tourStanding.standing.uuid}>
            {getJerseysByTourCompetitionUuid(
              tourCompetitionUuid,
              tourStanding.standing.type,
            )}
          </li>
        ),
      )}
    </ul>
  );
};

interface GroupedJerseysProps {
  jerseys: any;
  group: any;
  tourCompetitionUuid?: string;
}

const jerseySortingPriorityMap: {
  [key: string]: number;
} = {
  Ledertrøya: 1,
  Poengtrøya: 2,
  Klatretrøya: 3,
  Ungdomstrøya: 4,
};

export const GroupedJerseys = ({
  jerseys,
  group,
  tourCompetitionUuid,
}: GroupedJerseysProps) => {
  const bem = useBem('scope-results');

  return (
    <ul className={bem('jerseys')}>
      {jerseys?.[group.name]
        ?.sort(
          (a: string, b: string) =>
            jerseySortingPriorityMap[a] - jerseySortingPriorityMap[b],
        )
        ?.map((jersey: string) => (
          <li className={bem('jersey')} key={jersey}>
            {getJerseysByTourCompetitionUuid(
              tourCompetitionUuid,
              jersey.toLowerCase(),
            )}
          </li>
        ))}
    </ul>
  );
};
