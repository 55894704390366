export const FairPlayStandingsDescriptionFootball = () => (
  <>
    <dt>K</dt>
    <dd>Kamper</dd>
    <dt>L</dt>
    <dd>Antall lag</dd>
    <dt>GK</dt>
    <dd>Gule kort</dd>
    <dt>RK</dt>
    <dd>Røde kort</dd>
    <dt>P</dt>
    <dd>Poeng</dd>
    <dt>PS</dt>
    <dd>Poengsnitt</dd>
  </>
);

export default FairPlayStandingsDescriptionFootball;
