import { MatchesType } from '@ntb-sport/types';

const TeamStandingsDescriptionIceHockey = ({
  hasAveragePoints,
  hasPointPercentage,
  matches,
}: {
  hasAveragePoints: boolean;
  hasPointPercentage: boolean;
  matches?: MatchesType;
}) => (
  <>
    <dt>K</dt>
    <dd>Kamper</dd>
    <dt>S</dt>
    <dd>{matches?.tablet ? 'Seier' : 'Seier inkludert overtid og straffer'}</dd>
    {matches?.tablet && (
      <>
        <dt>SO</dt>
        <dd>Seier overtid</dd>
        <dt>SS</dt>
        <dd>Seier etter straffer</dd>
      </>
    )}
    <dt>T</dt>
    <dd>{matches?.tablet ? 'Tap' : 'Tap inkludert overtid og straffer'}</dd>
    {matches?.tablet && (
      <>
        <dt>TO</dt>
        <dd>Tap overtid</dd>
        <dt>TS</dt>
        <dd>Tap etter straffer</dd>
      </>
    )}
    {matches?.tablet && (
      <>
        <dt>+</dt>
        <dd>Scorede mål</dd>
        <dt>-</dt>
        <dd>Innslupne mål</dd>
      </>
    )}
    <dt>+/-</dt>
    <dd>Målforskjell</dd>
    {hasAveragePoints && (
      <>
        <dt>PS</dt>
        <dd>Poengsnitt</dd>
      </>
    )}
    {hasPointPercentage && (
      <>
        <dt>PP</dt>
        <dd>Poengprosent</dd>
      </>
    )}
    <dt>P</dt>
    <dd>Poeng</dd>
  </>
);

export default TeamStandingsDescriptionIceHockey;
