import './abbreviation-info.css';

import { useTranslation } from 'react-i18next';

import { InfoIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { COMPONENT_IDS, SPORT_GROUP } from '@ntb-sport/constants';
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
} from '../../chakra';
import { useBem, useDisclosure } from '@ntb-sport/hooks';

export interface AbbreviationInfoProps {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  sportGroup?: string;
}

export function AbbreviationInfo({
  size = 'xs',
  sportGroup,
}: AbbreviationInfoProps) {
  const { t } = useTranslation();
  const bem = useBem(COMPONENT_IDS.ABBREVIATION_INFO);

  const { onOpen, onClose, open } = useDisclosure();

  return (
    <div className={bem()}>
      <Popover
        isOpen={open}
        onOpen={onOpen}
        onClose={onClose}
        placement="bottom-end"
      >
        <PopoverTrigger>
          <Button
            size={size}
            className={bem('button')}
            rightIcon={
              open ? (
                <SmallCloseIcon color="currentcolor" />
              ) : (
                <InfoIcon color="currentcolor" />
              )
            }
          >
            {open ? t('close') : t('info')}
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />

          <PopoverBody>
            <dl className={bem('dl')}>
              <dt className={bem('dt')}>()</dt>
              <dd>{t('combinedScore')}</dd>
              <dt className={bem('dt', 'success')}>Q</dt>
              <dd>{t('qualified.Q')}</dd>
              {sportGroup === SPORT_GROUP.ATHLETICS && (
                <>
                  <dt className={bem('dt', 'success')}>q</dt>
                  <dd>{t('qualified.q')}</dd>
                </>
              )}
              <dt className={bem('dt', 'success')}>QA</dt>
              <dd>{t('qualified.QA')}</dd>
              <dt className={bem('dt', 'success')}>QB</dt>
              <dd>{t('qualified.QB')}</dd>
              <dt className={bem('dt', 'success')}>LL</dt>
              <dd>{t('qualified.LL')}</dd>
              <dt className={bem('dt', 'success')}>(LL)</dt>
              <dd>{t('qualified.TempLL')}</dd>
              <dt className={bem('dt', 'error')}>DNS</dt>
              <dd>{t('resultId.DNS')}</dd>
              <dt className={bem('dt', 'error')}>DNF</dt>
              <dd>{t('resultId.DNF')}</dd>
              <dt className={bem('dt', 'error')}>DSQ</dt>
              <dd>{t('resultId.DSQ')}</dd>
              <dt className={bem('dt', 'error')}>LPD</dt>
              <dd>{t('resultId.LPD')}</dd>
              <dt className={bem('dt', 'error')}>NPS</dt>
              <dd>{t('resultId.NPS')}</dd>
              <dt className={bem('dt', 'error')}>X</dt>
              <dd>{t('resultId.NO_RESULT')}</dd>
              {sportGroup !== SPORT_GROUP.WINTERSPORTS && (
                <>
                  <dt className={bem('dt', 'success')}>=</dt>
                  <dd>{t('records.TANGENT')}</dd>
                  <dt className={bem('dt', 'success')}>WL</dt>
                  <dd>{t('records.WL')}</dd>
                  <dt className={bem('dt', 'success')}>EL</dt>
                  <dd>{t('records.EL')}</dd>
                  <dt className={bem('dt', 'success')}>NL</dt>
                  <dd>{t('records.NL')}</dd>
                  <dt className={bem('dt', 'success')}>PB</dt>
                  <dd>{t('records.PB')}</dd>
                  <dt className={bem('dt', 'success')}>SB</dt>
                  <dd>{t('records.SB')}</dd>
                  <dt className={bem('dt', 'success')}>WR</dt>
                  <dd>{t('records.WR')}</dd>
                  <dt className={bem('dt', 'success')}>OR</dt>
                  <dd>{t('records.OR')}</dd>
                  <dt className={bem('dt', 'success')}>CR</dt>
                  <dd>{t('records.CR')}</dd>
                  <dt className={bem('dt', 'success')}>ER</dt>
                  <dd>{t('records.ER')}</dd>
                  <dt className={bem('dt', 'success')}>NR</dt>
                  <dd>{t('records.NR')}</dd>
                  <dt className={bem('dt', 'success')}>DLR</dt>
                  <dd>{t('records.DLR')}</dd>
                  <dt className={bem('dt', 'success')}>AR</dt>
                  <dd>{t('records.AR')}</dd>
                  <dt className={bem('dt', 'success')}>TR</dt>
                  <dd>{t('records.TR')}</dd>
                  <dt className={bem('dt', 'success')}>SR</dt>
                  <dd>{t('records.SR')}</dd>
                  <dt className={bem('dt', 'success')}>MR</dt>
                  <dd>{t('records.MR')}</dd>
                </>
              )}
            </dl>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default AbbreviationInfo;
