import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import { CSS_VARIABLES } from '../index';

export const TEAM_MATCH_STATISTICS_STYLES = [
  {
    name: 'Root',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_STATISTICS}']`,
    variables: [CSS_VARIABLES.FONT_COLOR],
  },
  {
    name: 'Container',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_MATCH_STATISTICS}'] [data-element-id='${ELEMENT_IDS.CONTAINER}']`,
    variables: [CSS_VARIABLES.FONT_COLOR, CSS_VARIABLES.BACKGROUND_COLOR],
  },
];
