import styled from 'styled-components';

export const HighlightedStats = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-bottom: 1px solid var(--base-colors-border);
`;

export const HighlightedStat = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid var(--base-colors-border);
  padding: var(--ntb-space-2) 0;
`;

export const HighlightedStatHeader = styled.div`
  font-size: var(--ntb-fontSizes-sm);
  color: var(--base-colors-blackAlpha-800);
`;

export const HighlightedStatBody = styled.div`
  font-size: var(--ntb-fontSizes-lg);
  font-weight: var(--ntb-fontWeights-medium);
`;

export const Stats = styled.dl``;
export const Stat = styled.div`
  display: flex;
  justify-content: space-between;
  padding: var(--ntb-space-2);
  border-bottom: 1px solid var(--base-colors-border);
  font-size: var(--ntb-fontSizes-sm);
`;

export const StatLabel = styled.dt``;
export const StatContent = styled.dd`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
`;

export const Image = styled.img`
  filter: brightness(80%);
`;
