import { useQuery } from '@tanstack/react-query';
import { NifsPersonWithStatistics } from '@ntb-sport/types';

import { fetchAthlete, FetchAthleteType } from './fetch';
import { QUERY_KEYS } from '@ntb-sport/constants';

interface UseQueryNifsPersonProps {
  queryKey?: string;
  queryKeyParams?: Record<string, unknown>;
  params: FetchAthleteType;
  options?: Record<string, unknown>;
}

interface UseQueryNifsPersonResponse {
  data?: NifsPersonWithStatistics;
  isLoading: boolean;
  error: any; // @TODO: figure out how to type properly
  isFetching: boolean;
}

export const useQueryNifsPerson = ({
  queryKey = QUERY_KEYS.ATHLETE,
  queryKeyParams,
  params,
  options,
}: UseQueryNifsPersonProps): UseQueryNifsPersonResponse => {
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchAthlete(params),
    enabled: Boolean(params.sportGroup) && Boolean(params.personId),
    ...options,
  });

  return { data, isLoading, error, isFetching };
};
