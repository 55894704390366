import { Fetch, API_VERSION } from '../../fetch';

export interface FetchTourStandingsProps {
  apiBaseUrl: string;
  version?: string;
  tourUUID: string;
  eventUUID: string;
  token: string;
  customerUUID: string;
}

export const fetchTourStandings = async ({
  apiBaseUrl,
  token,
  customerUUID,
  version = API_VERSION.V2,
  tourUUID,
  eventUUID,
}: FetchTourStandingsProps) => {
  const json = await Fetch({
    url: `${apiBaseUrl}/${version}/tours/${tourUUID}/events/${eventUUID}/results`,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });

  return json?.data;
};
