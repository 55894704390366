import {
  COMPONENT_IDS,
  DATA_PICKER_GROUPS,
  DATA_PROVIDERS,
  SPORT_GROUP,
} from '@ntb-sport/constants';
import { WidgetConfig, SportsdataConfig } from '@ntb-sport/types';
import {
  DATA_TYPES,
  WIDGET_IDS,
  WIDGET_OPTIONS,
  WIDGET_VERSION,
} from '../common';

export const config: WidgetConfig = {
  id: WIDGET_IDS.NEXT_MATCH,
  dataType: DATA_TYPES.NIFS,
  version: WIDGET_VERSION,
  displayName: 'Neste kamp',
  widgetNamespace: 'NtbWidgetNextMatch',
  iframeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.NEXT_MATCH}/iframe.html`,
  widgetUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.NEXT_MATCH}/${WIDGET_IDS.NEXT_MATCH}.js`,
  themeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/themes/default.css`,
  title: 'Neste kamp',
  summary: 'Informasjon om neste kamp',
  sportGroups: [
    SPORT_GROUP.FOOTBALL,
    SPORT_GROUP.HANDBALL,
    SPORT_GROUP.ICE_HOCKEY,
  ],
  description: 'general description to be displayed on the documentation page',
  components: [
    COMPONENT_IDS.TEAM_FIXTURES,
    COMPONENT_IDS.TEAM_FIXTURES_MATCH,
    COMPONENT_IDS.TEAM_FIXTURES_TEAM,
  ],
  options: {
    [WIDGET_OPTIONS.CUSTOMER_ID]: { isRequired: true },
    [WIDGET_OPTIONS.ERROR_MESSAGE]: { isRequired: false },
    [WIDGET_OPTIONS.ID]: { isRequired: true },
    [WIDGET_OPTIONS.ON_ERROR]: { isRequired: false },
    [WIDGET_OPTIONS.SPORT_GROUP]: { isRequired: true },
    [WIDGET_OPTIONS.STYLE_ID]: { isRequired: false },
    [WIDGET_OPTIONS.TARGET_ID]: { isRequired: true },
    [WIDGET_OPTIONS.MINUTES_DELAY]: { isRequired: false },
  },
  uiOptions: {
    [COMPONENT_IDS.TEAM_FIXTURES_MATCH]: [WIDGET_OPTIONS.SHOW_STADIUM],
  },
};

export const sportsdataConfig: SportsdataConfig = {
  dataPickerGroups: {
    [DATA_PICKER_GROUPS.NIFS_TEAM_GROUP]: {
      title: 'Lag',
      key: DATA_PICKER_GROUPS.NIFS_TEAM_GROUP,
      primaryId: DATA_PROVIDERS.NIFS_TEAM,
      isDefault: true,
      pickers: {
        [DATA_PROVIDERS.SPORT_GROUP]: { defaultValue: 'football' },
        [DATA_PROVIDERS.NIFS_TEAM]: { isPrimaryId: true },
      },
    },
  },
  unique: {
    [WIDGET_OPTIONS.MINUTES_DELAY]: {
      key: WIDGET_OPTIONS.MINUTES_DELAY,
      label: 'Minutter forsinkelse',
      type: 'numberInput',
      defaultValue: 0,
    },
  },
  optionGroups: {
    [COMPONENT_IDS.TEAM_FIXTURES_MATCH]: {
      title: 'Neste kamp',
      key: COMPONENT_IDS.TEAM_FIXTURES_MATCH,
      options: {
        [WIDGET_OPTIONS.SHOW_STADIUM]: {
          key: WIDGET_OPTIONS.SHOW_STADIUM,
          label: 'Vis stadion',
          type: 'checkbox',
          defaultValue: false,
        },
      },
    },
  },
};
