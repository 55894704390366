import { useQuery } from '@tanstack/react-query';

import { fetchAllShotSeries, FetchAllShotSeriesType } from './fetch';
import { getIsNeoSportGroup } from '@ntb-sport/helpers';
import { UUIDs } from 'neo-common-enums';
import { QUERY_KEYS } from '@ntb-sport/constants';

interface UseQueryAllShotSeriesParams extends FetchAllShotSeriesType {
  sportGroup: string;
  sportUUID: string;
}
interface UseQueryAllShotSeries {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: UseQueryAllShotSeriesParams;
  options?: Record<string, unknown>;
}

export const useQueryAllShotSeries = ({
  queryKey = QUERY_KEYS.ALL_SHOT_SERIES,
  queryKeyParams,
  params,
  options,
}: UseQueryAllShotSeries) => {
  const isNeoSportGroup = getIsNeoSportGroup(params.sportGroup);

  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchAllShotSeries(params),
    enabled:
      isNeoSportGroup &&
      Boolean(params.customerUUID) &&
      Boolean(params.token) &&
      Boolean(params.eventUUID) &&
      params.sportUUID === UUIDs.Sport.BIATHLON,
    ...options,
  });

  return { data, isLoading, error };
};
