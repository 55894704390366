import styled, { css } from 'styled-components';

import { MatchesType } from '@ntb-sport/types';

interface StyledProps {
  $matches?: MatchesType;
  $isClickable?: boolean;
  $src?: string;
}

export const Container = styled.div`
  width: 100%;
  background-color: var(--background-color);
  color: var(--font-color);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Stats = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--background-color);
  color: var(--font-color);
`;

export const HighlightedStat = styled.div<StyledProps>`
  position: relative;
  background-color: var(--background-color);

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-image: ${(props) => `url(${props?.$src})`};
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
    height: 170px;
    width: 170px;
  }
`;

export const HighlightedContent = styled.div`
  background-color: var(--background-color);
  color: var(--font-color);
  padding: var(--ntb-space-4);
  position: relative;
  z-index: 2;
`;

export const Stat = styled.div`
  width: 100%;
  display: flex;
  background-color: var(--background-color);
  align-items: center;
  justify-content: space-between;
  padding: var(--ntb-space-2) var(--ntb-space-4);
  border-bottom: 1px solid var(--border-color);
`;

export const RankAndBio = styled.div`
  display: flex;
  color: var(--font-color);
  align-items: center;
  column-gap: var(--ntb-space-4);
`;

export const TeamAndCountry = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
`;

export const PlayerName = styled.div<StyledProps>`
  font-size: var(--ntb-fontSizes-md);
  font-weight: var(--ntb-fontWeights-medium);

  ${(props) =>
    props?.$isClickable &&
    css`
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `}
`;

export const HighlightedPlayerName = styled.div<StyledProps>`
  font-size: var(--ntb-fontSizes-2xl);
  font-weight: var(--ntb-fontWeights-medium);
  ${(props) =>
    props?.$isClickable &&
    css`
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `}
`;

export const StatCounts = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
`;

export const StatCountHeader = styled.div`
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-semibold);
  min-width: 30px;
  text-align: center;
`;

export const StatCount = styled.div`
  font-size: var(--ntb-fontSizes-md);
  font-weight: var(--ntb-fontWeights-semibold);
  background: var(--background-color);
  padding: 6px;
  border-radius: 99999px;
  color: var(--font-color);
  width: 30px;
  display: flex;
  justify-content: center;
  height: 30px;
  align-items: center;
`;

export const HighlightedStatCount = styled.div`
  display: flex;
  column-gap: var(--ntb-space-1);
  align-items: baseline;
  font-size: var(--ntb-fontSizes-5xl);
  font-weight: var(--ntb-fontWeights-medium);
  color: var(--font-color);
`;

export const HighlightedStatCountLabel = styled.span`
  font-size: var(--ntb-fontSizes-3xl);
  font-weight: var(--ntb-fontWeights-medium);
  color: var(--font-color);
  padding-left: var(--ntb-space-1);
`;

export const Rank = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-medium);
`;

export const RankLabel = styled.span<StyledProps>`
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-light);
`;

export const Player = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);

  ${(props) =>
    props?.$isClickable &&
    css`
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `}
`;

export const ProfileImage = styled.div<StyledProps>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    object-fit: cover;
    max-width: 100%;
    border-radius: 50%;
  }
`;

export const Team = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-light);
  ${(props) =>
    props?.$isClickable &&
    css`
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `}
`;

export const HighlightedTeam = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
  font-size: var(--ntb-fontSizes-lg);
  font-weight: var(--ntb-fontWeighs-normal);
  color: var(--highlighted-bio-font-color);
  ${(props) =>
    props?.$isClickable &&
    css`
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `}
`;

export const TeamLogo = styled.div`
  width: 20px;
  height: 20px;

  img {
    max-width: 100%;
  }
`;

export const ErrorMessage = styled.p<StyledProps>`
  padding: var(--ntb-space-4);
`;
