import { Icon } from '../../chakra';

export const GoldIcon = (props: any) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <circle cx="16" cy="16" r="16" fill="#F2CA00" />
    <circle cx="16" cy="16" r="15" fill="#FFDF40" />
    <circle cx="16" cy="16" r="10" fill="#FFEA80" />
    <text
      x="50%"
      y="50%"
      className="text"
      fill="#000"
      dominantBaseline="middle"
      textAnchor="middle"
    >
      {props?.children}
    </text>
  </Icon>
);
