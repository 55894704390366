import { API_VERSION, Fetch } from '../../fetch';

export interface FetchScopeResultsType {
  apiBaseUrl: string;
  version?: string;
  scopeUUID: string;
  token: string;
  customerUUID?: string;
}

export const fetchScopeResults = async ({
  apiBaseUrl,
  version = API_VERSION.V6,
  scopeUUID,
  token,
  customerUUID,
}: FetchScopeResultsType) => {
  const json = await Fetch({
    url: `${apiBaseUrl}/${version}/scopes/${scopeUUID}/results`,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });

  return {
    meta: { scopeUUID },
    data: json.data,
  };
};
