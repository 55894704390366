export const FAIR_PLAY_STANDINGS_STYLES = [
  '--background-color',
  '--cell-font-size-desktop',
  '--cell-font-size-mobile',
  '--cell-font-size-tablet',
  '--colors-search-background',
  '--colors-search-border',
  '--colors-search-border__focus',
  '--colors-search-border__hover',
  '--colors-search-icon',
  '--colors-search-placeholder-text',
  '--colors-search-text',
  '--colors-table-cell-background__hover',
  '--colors-table-cell-text__hover',
  '--colors-table-head-text',
  '--font-color',
  '--header-background-color',
  '--header-font-color',
  '--header-button-background-color',
  '--header-button-background-color__hover',
  '--header-button-font-color',
  '--header-button-font-color__hover',
  '--search-font-size',
  '--search-placeholder-font-size',
];
