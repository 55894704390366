import { COMPONENT_IDS } from '@ntb-sport/constants';

import * as S from './TabMenu.styled';
import Button from '../../chakra/Button/Button';
import { Box, Skeleton } from '../../chakra';

export interface TabMenuProps {
  handleTypeChange: (type: string) => void;
  highlightFirst?: boolean;
  menuItems: string[];
  menuItemsLabelMap?: { [key: string]: string };
  selectedType: string;
  isLoading?: boolean;
}

export const TabMenu: React.FC<TabMenuProps> = ({
  handleTypeChange,
  highlightFirst = false,
  menuItems,
  menuItemsLabelMap,
  selectedType,
  isLoading = false,
}) => {
  if (isLoading) {
    return (
      <S.FlexContainer data-component-id={COMPONENT_IDS.TAB_MENU}>
        <Skeleton height="100%" minH="40px" width="100%" maxW="250px" />
      </S.FlexContainer>
    );
  }

  return (
    <S.FlexContainer
      data-component-id={COMPONENT_IDS.TAB_MENU}
      $highlightFirst={highlightFirst}
    >
      {menuItems.length > 1 && (
        <Box
          backgroundColor="var(--menu-background-color)"
          color="var(--font-color)"
          p={1}
          display="inline-flex"
          gap={2}
          borderRadius={6}
        >
          {menuItems.map((type) => (
            <Button
              key={type}
              size="sm"
              variant={type === selectedType ? 'solid' : 'ghost'}
              backgroundColor={
                type === selectedType
                  ? 'var(--menu-item-background-color__active)'
                  : 'var(--menu-item-background-color)'
              }
              color={
                type === selectedType
                  ? 'var(--menu-item-font-color__active)'
                  : 'var(--menu-item-font-color)'
              }
              _hover={{
                bg:
                  type === selectedType
                    ? 'var(--menu-item-background-color__active__hover)'
                    : 'var(--menu-item-background-color__hover)',
                color:
                  type === selectedType
                    ? 'var(--menu-item-font-color__active__hover)'
                    : 'var(--menu-item-font-color__hover)',
              }}
              onClick={() => handleTypeChange(type)}
            >
              {menuItemsLabelMap
                ? menuItemsLabelMap[type as keyof typeof menuItemsLabelMap]
                : type}
            </Button>
          ))}
        </Box>
      )}
    </S.FlexContainer>
  );
};

export default TabMenu;
