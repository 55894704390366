import { Button } from '../../../../chakra';
import {
  COMPONENT_IDS,
  SPORT_ID_TO_NORWEGIAN_NAME,
} from '@ntb-sport/constants';
import { format } from 'date-fns/format';

import { DateObj } from 'dayzed';

import { useEffect, useRef } from 'react';
import { useBem } from '@ntb-sport/hooks';
import './days.css';
import classNames from 'classnames';

interface DaysProps {
  weeks: Array<Array<DateObj | ''>>;
  month: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  year: number;
  daysWithEventsInMonth: any;
  isLoadingDaysWithEventsInMonth?: boolean;
  getDateProps: any;
  isOpen: boolean;
}

export const Days = ({
  weeks,
  month,
  year,
  daysWithEventsInMonth,
  isLoadingDaysWithEventsInMonth,
  getDateProps,
  isOpen,
}: DaysProps) => {
  const bem = useBem(COMPONENT_IDS.DATE_SELECTOR);

  return (
    <div className={bem('days')}>
      {weeks.map((week: any, weekIndex: number) =>
        week.map((dateObj: DateObj, index: number) => {
          const key = `${month}${year}${weekIndex}${index}`;
          if (!dateObj) {
            return <div key={key} />;
          }
          const { date, selected }: { date: Date; selected: boolean } = dateObj;

          const dayInfo = daysWithEventsInMonth?.find(
            (d: any) => d?.date === format(date, 'yyyy-MM-dd'),
          );
          const sports = dayInfo?.sports
            ?.reduce((acc: string[], item: { uuid: string }) => {
              acc.push(SPORT_ID_TO_NORWEGIAN_NAME[item?.uuid]);
              return acc;
            }, [])
            ?.toString();

          const isValidDate = daysWithEventsInMonth ? Boolean(dayInfo) : true;
          const focusRef = useRef<HTMLButtonElement>(null);

          useEffect(() => {
            focusRef?.current?.focus();
          }, [isOpen]);

          return (
            <Button
              {...getDateProps({ dateObj })}
              isActive={selected}
              ref={selected ? focusRef : null}
              tabIndex={selected ? -1 : 0}
              className={classNames(bem('day'), {
                [bem('day', 'selected')]: selected,
                [bem('day', 'disabled')]: !isValidDate,
              })}
              size="sm"
              isLoading={isLoadingDaysWithEventsInMonth}
              isDisabled={!isValidDate}
              key={key}
              aria-label={` ${new Date(date).toLocaleDateString('nb-NO', { day: '2-digit', month: 'long' })}`}
            >
              {date.getDate()}
            </Button>
          );
        }),
      )}
    </div>
  );
};
