import { QUERY_KEYS, SPORT_GROUP } from '@ntb-sport/constants';
import { NifsMatchType } from '@ntb-sport/types';
import { useQuery } from '@tanstack/react-query';

import { Fetch, NIFS_IDENTIFICATOR } from '../fetch';

//TODO: Merge this with stageMatches

interface FetchStageFixturesType {
  apiBaseUrl: string;
  sportGroup: string;
  stageId: string;
  round?: string | number;
}

export const fetchStageFixtures = async ({
  apiBaseUrl,
  sportGroup,
  stageId,
  round,
}: FetchStageFixturesType): Promise<NifsMatchType[]> => {
  const stagesPath =
    sportGroup === SPORT_GROUP.ICE_HOCKEY || sportGroup === SPORT_GROUP.HANDBALL
      ? 'nifStages'
      : 'stages';
  const matchesPath =
    sportGroup === SPORT_GROUP.ICE_HOCKEY || sportGroup === SPORT_GROUP.HANDBALL
      ? 'nifMatches'
      : 'matches';

  const url = `${apiBaseUrl}/${stagesPath}/${stageId}/${matchesPath}/${
    round === 'active' ? '?getActiveRound=1' : round ? `?round=${round}` : ''
  }`;

  const json = await Fetch({
    url: `${url}`,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });

  return json;
};

interface UseQueryNifsStageFixtures {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: FetchStageFixturesType;
  options?: Record<string, unknown>;
}

export const useQueryNifsStageFixtures = ({
  queryKey = QUERY_KEYS.STAGE_FIXTURES,
  queryKeyParams,
  params,
  options,
}: UseQueryNifsStageFixtures) => {
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchStageFixtures(params),
    enabled: Boolean(params.sportGroup) && Boolean(params.stageId),
    ...options,
  });

  return { data, isLoading, error };
};
