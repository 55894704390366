import { Icon } from '../../chakra';

export const BronzeIcon = (props: any) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      d="M16 0L27.3137 4.68629L32 16L27.3137 27.3137L16 32L4.68629 27.3137L0 16L4.68629 4.68629L16 0Z"
      fill="#CC8F52"
    />
    <path
      d="M16 0.93335L26.6066 5.32675L31 15.9333L26.6066 26.54L16 30.9333L5.3934 26.54L1 15.9333L5.3934 5.32675L16 0.93335Z"
      fill="#E6AC73"
    />
    <path
      d="M16 6L23.0711 8.92893L26 16L23.0711 23.0711L16 26L8.92893 23.0711L6 16L8.92893 8.92893L16 6Z"
      fill="#F2C291"
    />
    <text
      x="50%"
      y="50%"
      className="text"
      fill="#000"
      dominantBaseline="middle"
      textAnchor="middle"
    >
      {props?.children}
    </text>
  </Icon>
);
