import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';

import { ButtonGroup, Button } from '../../chakra';

interface ShowMoreLessProps {
  dataLength: number;
  initialLimit: number;
  limit: number;
  onLimitChange: (type: string, limit: number, incr?: number) => void;
}

const ShowMoreLess: React.FC<ShowMoreLessProps> = ({
  dataLength,
  initialLimit,
  limit,
  onLimitChange,
}) => {
  const showBoth = limit < dataLength && limit > initialLimit;

  return (
    <ButtonGroup spacing={3}>
      {limit < dataLength && (
        <Button
          size="xs"
          variant="outline"
          minW={showBoth ? '130px' : '180px'}
          onClick={() => onLimitChange('increment', limit)}
          bg="var(--header-button-font-color)"
          borderRadius="var(--ntb-radii-md)"
          borderColor="var(--header-button-background-color)"
          color="var(--header-button-background-color)"
          margin="0.5rem auto"
          _hover={{
            bg: 'var(--header-button-background-color__hover)',
            color: 'var(--header-button-font-color__hover)',
          }}
          rightIcon={<ArrowDownIcon />}
        >
          {'Vis mer'}
        </Button>
      )}
      {limit > initialLimit && (
        <Button
          size="xs"
          variant="outline"
          minW={showBoth ? '130px' : '180px'}
          onClick={() => onLimitChange('reset', limit)}
          bg="var(--header-button-font-color)"
          borderRadius="var(--ntb-radii-md)"
          borderColor="var(--header-button-background-color)"
          color="var(--header-button-background-color)"
          margin="0.5rem auto"
          _hover={{
            bg: 'var(--header-button-background-color__hover)',
            color: 'var(--header-button-font-color__hover)',
          }}
          rightIcon={<ArrowUpIcon />}
        >
          {'Vis mindre'}
        </Button>
      )}
    </ButtonGroup>
  );
};

export default ShowMoreLess;
