import { getEventStatus } from '../helpers/getEventStatus';
import { LabelProperties } from 'neo-common-enums';
import { NeoBroadcastType } from '@ntb-sport/types';
import { EventType } from '../types';
import { FaMedal } from 'react-icons/fa6';
import { useBem } from '@ntb-sport/hooks';
import { COMPONENT_IDS } from '@ntb-sport/constants';
import './sub-event-group.css';
import classNames from 'classnames';
import CountryFlag from '../../../common/CountryFlag/CountryFlag';

interface SubEventGroupProps {
  eventGroup: EventType;
}

export const SubEventGroup = ({ eventGroup }: SubEventGroupProps) => {
  const bem = useBem(COMPONENT_IDS.SPORTEDIT_CALENDAR);

  const eventStatus = getEventStatus({
    eventStatusId: eventGroup?.eventStatusId,
    timestamp: eventGroup?.timestamp,
  });

  return (
    <li className={bem('sub-event-group')}>
      <div
        className={classNames(bem('sub-event-group-status'), {
          [bem('sub-event-group-status', 'success')]:
            eventStatus?.type === 'success',
          [bem('sub-event-group-status', 'error')]:
            eventStatus?.type === 'error',
        })}
      >
        {eventStatus?.type === 'default' && eventStatus?.text}
      </div>
      <div className={bem('sub-event-group-container')}>
        <div className={bem('sub-event-group-name')}>{eventGroup?.name}</div>
        {eventStatus?.type !== 'default' && (
          <>
            {' - '}
            <div
              className={classNames(bem('sub-event-group-status'), {
                [bem('sub-event-group-status', 'success')]:
                  eventStatus?.type === 'success',
                [bem('sub-event-group-status', 'error')]:
                  eventStatus?.type === 'error',
              })}
            >
              {eventStatus?.text}
            </div>
          </>
        )}
        {eventGroup?.hasAthletesFromCountry && <CountryFlag countryCode="no" />}
        {eventGroup?.isMedalEvent && <FaMedal />}
      </div>

      <div className={bem('sub-event-group-broadcasts')}>
        {eventGroup?.broadcasts
          ?.filter(
            (broadcast: NeoBroadcastType) =>
              broadcast?.typeId === LabelProperties.BroadcastType.TV,
          )
          ?.map((broadcast: NeoBroadcastType) => (
            <div
              className={bem('sub-event-group-broadcast')}
              key={broadcast?.uuid}
            >
              {broadcast?.broadcastingChannel?.name}
            </div>
          ))}
      </div>
    </li>
  );
};
