import * as S from './VideoLink.styled';

interface VideoLinkProps {
  matchStreams: any;
  customerConcernId: number;
}

export const VideoLink = ({
  matchStreams,
  customerConcernId,
}: VideoLinkProps) => {
  const videoStream = matchStreams?.find(
    (matchStream: any) =>
      !matchStream?.radio &&
      (matchStream?.customer?.customerConcernId === customerConcernId ||
        matchStream?.customerConcernId === customerConcernId),
  );
  return (
    <S.Link href={videoStream?.link} target="_blank">
      {'Se kampen på '}
      <img
        width="22px"
        src="https://sportsdata-widgets.ntb.no/logo/direktesport.png"
      />
      {'Direktesport'}
    </S.Link>
  );
};
