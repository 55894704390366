import { ELEMENT_IDS } from '@ntb-sport/constants';
import { EventComment, Time } from '../Event.styled';
import { EventTypeProps } from '../Event';
import { getEventTime } from '../getEventTime';

export const DefaultEvent = ({ text, event }: EventTypeProps) => (
  <EventComment data-element-id={ELEMENT_IDS.TITLE}>
    {Object.prototype.hasOwnProperty.call(event, 'time') &&
      event.time !== null && <Time>{getEventTime(event)}</Time>}
    {text || event?.comment}
  </EventComment>
);

export default DefaultEvent;
