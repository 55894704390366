import { MEDIA_QUERY } from '@ntb-sport/constants';
import styled, { css } from 'styled-components';

export interface StyledCountryFlagProps {
  $size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const CountryFlag = styled.img<StyledCountryFlagProps>`
  display: flex;
  align-items: center;

  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);

  width: var(--ntb-space-4);

  ${({ $size }) =>
    $size === 'xs' &&
    css`
      width: var(--ntb-space-6);

      @container container ${MEDIA_QUERY.TABLET} {
        width: var(--ntb-space-8);
      }
    `};

  ${({ $size }) =>
    $size === 'sm' &&
    css`
      width: var(--ntb-space-8);

      @container container ${MEDIA_QUERY.TABLET} {
        width: var(--ntb-space-12);
      }
    `};

  ${({ $size }) =>
    $size === 'md' &&
    css`
      width: var(--ntb-space-12);

      @container container ${MEDIA_QUERY.TABLET} {
        width: var(--ntb-space-16);
      }
    `};

  ${({ $size }) =>
    $size === 'lg' &&
    css`
      width: var(--ntb-space-16);

      @container container ${MEDIA_QUERY.TABLET} {
        width: var(--ntb-space-20);
      }
    `};

  ${({ $size }) =>
    $size === 'xl' &&
    css`
      width: var(--ntb-space-20);

      @container container ${MEDIA_QUERY.TABLET} {
        width: var(--ntb-space-24);
      }
    `};
`;
