import { useEffect } from 'react';

export const useLoadStyle = ({
  href,
  id,
  rel,
}: {
  href: string;
  id: string;
  rel: string;
}) => {
  useEffect(() => {
    const style = document.createElement('link');
    style.href = href;
    style.rel = rel;
    style.id = id;

    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, [href, rel]);
};

export default useLoadStyle;
