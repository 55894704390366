import styled from 'styled-components';

interface ContainerProps {
  $highlightFirst?: boolean;
}

export const FlexContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-color);
  width: 100%;
  padding: ${(props) =>
    props?.$highlightFirst ? 'var(--ntb-space-4)' : 'var(--ntb-space-2)'};
`;
