import { MEDIA_QUERY } from '@ntb-sport/constants';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--background-color);
  box-shadow:
    inset 0px -1px 0px var(--border-color),
    inset 0px 1px 0px var(--border-color);

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  row-gap: var(--ntb-space-2);
  padding: var(--ntb-space-2);

  color: var(--font-color);

  @container match-report ${MEDIA_QUERY.MOBILE_L} {
    flex-direction: row;
  }
`;

export const Host = styled.div`
  font-size: var(--ntb-fontSizes-xs);
  strong {
    font-weight: var(--ntb-fontWeights-semibold);
  }
`;
