import {
  COMPONENT_IDS,
  DATA_PICKER_GROUPS,
  DATA_PROVIDERS,
  SPORT_GROUP,
} from '@ntb-sport/constants';
import { WidgetConfig, SportsdataConfig } from '@ntb-sport/types';
import {
  DATA_TYPES,
  WIDGET_IDS,
  WIDGET_OPTIONS,
  WIDGET_VERSION,
} from '../common';

export const config: WidgetConfig = {
  id: WIDGET_IDS.MATCH_LINEUP,
  dataType: DATA_TYPES.NIFS,
  version: WIDGET_VERSION,
  displayName: 'Tabell (sportsGroup)',
  widgetNamespace: 'NtbWidgetMatchLineup',
  iframeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.MATCH_LINEUP}/iframe.html`,
  widgetUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.MATCH_LINEUP}/${WIDGET_IDS.MATCH_LINEUP}.js`,
  themeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/themes/default.css`,
  title: 'Lagoppstilling/kamptropp',
  summary: 'Lagoppstilling formasjon/liste',
  sportGroups: [
    SPORT_GROUP.FOOTBALL,
    SPORT_GROUP.HANDBALL,
    SPORT_GROUP.ICE_HOCKEY,
  ],
  description: 'general description to be displayed on the documentation page',
  components: [COMPONENT_IDS.TEAM_LINEUP],
  options: {
    [WIDGET_OPTIONS.CUSTOMER_ID]: { isRequired: true },
    [WIDGET_OPTIONS.ERROR_MESSAGE]: { isRequired: false },
    [WIDGET_OPTIONS.ID]: { isRequired: true },
    [WIDGET_OPTIONS.ON_ERROR]: { isRequired: false },
    [WIDGET_OPTIONS.SPORT_GROUP]: { isRequired: true },
    [WIDGET_OPTIONS.STYLE_ID]: { isRequired: false },
    [WIDGET_OPTIONS.TARGET_ID]: { isRequired: true },
  },
  uiOptions: {
    [COMPONENT_IDS.TEAM_LINEUP]: [WIDGET_OPTIONS.SHOW_NATIONALITY],
  },
};

export const sportsdataConfig: SportsdataConfig = {
  dataPickerGroups: {
    [DATA_PICKER_GROUPS.DATE_GROUP]: {
      title: 'Dato',
      key: DATA_PICKER_GROUPS.DATE_GROUP,
      primaryId: DATA_PROVIDERS.NIFS_MATCHES,
      pickers: {
        [DATA_PROVIDERS.DATE_SELECTOR]: {},
        [DATA_PROVIDERS.NIFS_MATCHES]: { isPrimaryId: true },
      },
    },
    [DATA_PICKER_GROUPS.NIFS_MATCH_GROUP]: {
      title: 'Kamp',
      key: DATA_PICKER_GROUPS.NIFS_MATCH_GROUP,
      primaryId: DATA_PROVIDERS.NIFS_MATCHES,
      isDefault: true,
      pickers: {
        [DATA_PROVIDERS.SPORT_GROUP]: { defaultValue: 'football' },
        [DATA_PROVIDERS.NIFS_COUNTRIES]: { defaultValue: 1 },
        [DATA_PROVIDERS.NIFS_TOURNAMENTS]: {},
        [DATA_PROVIDERS.NIFS_STAGES]: {},
        [DATA_PROVIDERS.NIFS_MATCHES]: { isPrimaryId: true },
      },
    },
  },
  optionGroups: {
    [COMPONENT_IDS.TEAM_LINEUP]: {
      title: 'Lagoppstilling',
      key: COMPONENT_IDS.TEAM_LINEUP,
      options: {
        [WIDGET_OPTIONS.SHOW_NATIONALITY]: {
          key: WIDGET_OPTIONS.SHOW_NATIONALITY,
          defaultValue: true,
          label: 'Vis nasjonalitet',
          type: 'checkbox',
        },
      },
    },
  },
};
