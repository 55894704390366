import { useEffect, useState } from 'react';

import {
  ELEMENT_IDS,
  MATCH_STATUS,
  MATCH_STATUS_ID,
} from '@ntb-sport/constants';
import PropTypes from 'prop-types';
import { differenceInMilliseconds } from 'date-fns';
import * as S from './MatchTimer.styled';

const convertMS = (milliseconds: number) => {
  let hours, minutes, seconds;
  seconds = Math.floor(milliseconds / 1000);
  minutes = Math.floor(seconds / 60);
  hours = Math.floor(minutes / 60);
  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;
  return {
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

const addMinutes = (matchStatusId: number) => {
  if (Number(matchStatusId) === MATCH_STATUS.FIRST_HALF) return 0;
  else if (Number(matchStatusId) === MATCH_STATUS.SECOND_HALF) return 45;
  else if (Number(matchStatusId) === MATCH_STATUS.FIRST_HALF_EXTRA_TIME)
    return 90;
  else if (Number(matchStatusId) === MATCH_STATUS.SECOND_HALF_EXTRA_TIME)
    return 105;
  else return 0;
};

const getIsOvertime = (matchStatusId: number, minutes: number) => {
  if (Number(matchStatusId) === MATCH_STATUS.FIRST_HALF && minutes >= 45)
    return true;
  else if (Number(matchStatusId) === MATCH_STATUS.SECOND_HALF && minutes >= 90)
    return true;
  else if (
    Number(matchStatusId) === MATCH_STATUS.FIRST_HALF_EXTRA_TIME &&
    minutes >= 105
  )
    return true;
  else if (
    Number(matchStatusId) === MATCH_STATUS.SECOND_HALF_EXTRA_TIME &&
    minutes >= 120
  )
    return true;
  else return false;
};

const MatchTimer = ({
  showSeconds,
  timeStart,
  timestamp,
  matchStatusId,
  isMainMatch,
}: {
  showSeconds: any;
  timeStart: any;
  timestamp: any;
  matchStatusId: any;
  isMainMatch: any;
}) => {
  const [diffMS, setDiffMS] = useState(
    differenceInMilliseconds(new Date(), new Date(timeStart || timestamp)),
  );

  if (!timeStart) console.log('Missing timestart', timeStart);

  useEffect(() => {
    const interval = setInterval(() => {
      setDiffMS(
        differenceInMilliseconds(new Date(), new Date(timeStart || timestamp)),
      );
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [matchStatusId, timeStart, timestamp]);

  const time = convertMS(diffMS);
  const minutes = time.hours * 60 + time.minutes + addMinutes(matchStatusId);
  const seconds = time.seconds;
  const isOverTime = getIsOvertime(matchStatusId, minutes);

  return (
    <S.MatchTimer
      $isOvertime={isOverTime}
      $isMainMatch={isMainMatch}
      data-element-id={ELEMENT_IDS.TYPE_TIME}
      data-status-id={isOverTime ? 'overtime' : 'normal'}
    >
      {showSeconds
        ? `${minutes}:${('0' + seconds).slice(-2)}`
        : `${minutes + 1}'`}
    </S.MatchTimer>
  );
};

MatchTimer.propTypes = {
  showSeconds: PropTypes.bool,
  timeStart: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  timestamp: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  matchStatusId: PropTypes.number,
  isMainMatch: PropTypes.bool,
};

export default MatchTimer;
