import { MEDIA_QUERY } from '@ntb-sport/constants';
import styled from 'styled-components';

interface StyledProps {
  $hasHighlightVideo?: boolean;
  src?: any;
  $title?: any;
}

export const Container = styled.div<StyledProps>`
  padding: var(--ntb-space-4);
  font-size: var(--ntb-fontSizes-xs);
  background-color: var(--background-color);
  color: var(--font-color);
  box-shadow:
    inset 0px -1px 0px var(--border-color),
    inset 0px 1px 0px var(--border-color);

  @container match-report ${MEDIA_QUERY.TABLET} {
    display: ${(props) => props?.$hasHighlightVideo && 'grid'};
    grid-template-columns: ${(props) =>
      props?.$hasHighlightVideo && 'repeat(2, 1fr)'};
    grid-column-gap: ${(props) => props?.$hasHighlightVideo && '48px'};
    align-items: ${(props) => props?.$hasHighlightVideo && 'center'};

    font-size: var(--ntb-fontSizes-sm);
    padding: var(--ntb-space-8);
  }
`;

export const PlayerPreview = styled.button<StyledProps>`
  cursor: pointer;
  border: none;
  position: relative;
  background-image: ${(props) => `url("${props.src}")`};
  background-size: cover;

  min-height: 150px;
  width: 100%;
  max-width: 600px;

  margin-top: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    position: absolute;
    content: ${(props) => `"${props?.$title}"`};
    padding: 6px;
    background-color: #000;
    color: #fff;
    bottom: 6px;
    left: 6px;
  }

  @container match-report ${MEDIA_QUERY.TABLET} {
    min-height: 225px;
    margin-top: 0px;
  }
`;

export const PlayIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 42px;
  width: 42px;
  border-radius: 50%;
`;
