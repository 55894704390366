import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Link,
} from '../../chakra';

interface AlertMissingParamProps {
  paramName: string;
  docsHref?: string;
}

export const AlertMissingParam: React.FC<AlertMissingParamProps> = ({
  paramName,
  docsHref,
}) => {
  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle>Missing parameter!</AlertTitle>
      <AlertDescription>
        {`It seems you have not provided an ${paramName}. Please refer to the`}
        <Link href={docsHref || ''}> documentation</Link>.
      </AlertDescription>
    </Alert>
  );
};

export default AlertMissingParam;
