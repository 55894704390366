import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '@ntb-sport/constants';
import { fetchSeasonEvents, FetchSeasonEvents } from './fetch';

interface UseQuerySeasonEvents {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: FetchSeasonEvents;
  options?: Record<string, unknown>;
}

export const useQuerySeasonEvents = ({
  queryKey = QUERY_KEYS.SEASON_EVENTS,
  queryKeyParams,
  params,
  options,
}: UseQuerySeasonEvents) => {
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchSeasonEvents(params),
    enabled:
      Boolean(params.customerUUID) &&
      Boolean(params.token) &&
      Boolean(params.seasonUUID),
    ...options,
  });

  return { data, isLoading, error };
};
