import Icon from '../Icon/Icon';

export const GoalIcehockeyIcon = (props: any) => (
  <Icon {...props}>
    <g>
      <g>
        <g>
          <g>
            <path
              d="M11.3,2.5c-6.2,0-11,2.5-11.1,5.8c0,0,0,0,0,0.1v6c0,1.5,1,2.8,2.8,3.9c0.3,0.2,0.6,0.3,0.9,0.5
    c0.7,0.3,1.3,0.6,2.2,0.8c0.3,0.1,0.7,0.2,0.9,0.2c1.3,0.3,2.7,0.5,4.3,0.5c6.2,0,11.1-2.5,11.1-5.8V8.3
    C22.4,5,17.5,2.5,11.3,2.5z M3.9,17.7c-0.4-0.2-0.7-0.4-0.9-0.6v-5c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.5,0.3,0.8,0.4L3.9,17.7
    L3.9,17.7z M7,18.8c-0.3-0.1-0.7-0.2-0.9-0.3v-5.1l0,0l0,0l0,0c0.3,0.1,0.7,0.2,0.9,0.2V18.8z M9.9,19.2c-0.3,0-0.7-0.1-0.9-0.1
    c-0.1,0,0-3.8,0-4.2c0-0.3,0-0.7,0-0.9c0.3,0,0.7,0.1,0.9,0.1V19.2z M21.5,14.4c0,2.5-4.4,4.7-9.7,4.8v-5.1
    c1.6,0,3.3-0.3,4.9-0.7c1.8-0.5,3.5-1.3,4.8-2.7V14.4z"
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
