import {
  DEFAULT_SPORT_ID_BY_SPORT_GROUP,
  SPORT_GROUP,
} from '@ntb-sport/constants';
import { Fetch, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchTournamentsType {
  apiBaseUrl: string;
  sportGroup: string;
}

export const fetchTournaments = async ({
  apiBaseUrl,
  sportGroup,
}: FetchTournamentsType) => {
  const competitionPath =
    sportGroup === SPORT_GROUP.ICE_HOCKEY || sportGroup === SPORT_GROUP.HANDBALL
      ? 'nifTournaments'
      : 'tournaments';

  const url =
    sportGroup === SPORT_GROUP.FOOTBALL
      ? `${apiBaseUrl}/${competitionPath}/?inCustomerStages=1&withStagesAndTeams=1`
      : `${apiBaseUrl}/${competitionPath}/?inCustomerStages=1&sportId=${
          DEFAULT_SPORT_ID_BY_SPORT_GROUP[
            sportGroup as keyof typeof DEFAULT_SPORT_ID_BY_SPORT_GROUP
          ]
        }`;

  const json = await Fetch({
    url: `${url}`,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });
  return json;
};
