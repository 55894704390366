import styled, { css } from 'styled-components';

interface ContainerProps {
  $direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
}
interface ClubLogoProps {
  $size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  flex-direction: ${({ $direction }) => $direction || 'row'};
  column-gap: var(--ntb-space-2);
  row-gap: var(--ntb-space-2);
`;

export const ClubLogoContainer = styled.div`
  display: inline-flex;
`;

export const ClubLogo = styled.img<ClubLogoProps>`
  max-width: 100%;
  aspect-ratio: 1 / 1;

  ${({ $size }) =>
    $size === 'xs' &&
    css`
      max-width: var(--ntb-space-6);
    `};

  ${({ $size }) =>
    $size === 'sm' &&
    css`
      max-width: var(--ntb-space-8);
    `};

  ${({ $size }) =>
    $size === 'md' &&
    css`
      max-width: var(--ntb-space-12);
    `};

  ${({ $size }) =>
    $size === 'lg' &&
    css`
      max-width: var(--ntb-space-16);
    `};

  ${({ $size }) =>
    $size === 'xl' &&
    css`
      max-width: var(--ntb-space-20);
    `};
`;

export const ClubName = styled.span<ClubLogoProps>`
  font-weight: var(--ntb-fontWeights-medium);
  color: inherit;
  ${({ $size }) =>
    $size === 'xs' &&
    css`
      font-size: var(--ntb-fontSizes-sm);
    `};

  ${({ $size }) =>
    $size === 'sm' &&
    css`
      font-size: var(--ntb-fontSizes-md);
    `};

  ${({ $size }) =>
    $size === 'md' &&
    css`
      font-size: var(--ntb-fontSizes-lg);
    `};

  ${({ $size }) =>
    $size === 'lg' &&
    css`
      font-size: var(--ntb-fontSizes-xl);
    `};

  ${({ $size }) =>
    $size === 'xl' &&
    css`
      font-size: var(--ntb-fontSizes-2xl);
    `};
`;
