import { Skeleton } from '../../../chakra';
import './loader.css';
import { useBem } from '@ntb-sport/hooks';

export const Loader = () => {
  const bem = useBem('scope-results');
  return (
    <div className={bem('loader')}>
      {[...Array(10)].map((_element, index) => (
        <Skeleton key={index} height="45px" mb={1} />
      ))}
    </div>
  );
};
