import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import no from './no.json';
import en from './en.json';
import { UUIDs } from 'neo-common-enums';

export const SPORTEDIT_LANGUAGE_UUID_TO_LANGUAGE_CODES = {
  [UUIDs.Language.ENGLISH]: 'en',
  [UUIDs.Language.NORWEGIAN]: 'no',
  [UUIDs.Language.SWEDISH]: 'se',
};

const i18nInit = () =>
  i18n.use(initReactI18next).init({
    fallbackLng: 'no',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      no: {
        translation: {
          ...no,
        },
      },
      en: {
        translation: {
          ...en,
        },
      },
    },
  });

export { i18n, i18nInit };
