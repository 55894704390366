import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '@ntb-sport/constants';
import { fetchCustomerSetup, FetchCustomerSetupType } from './fetch';

interface UseQueryEvent {
  queryKey?: string;
  queryKeyParams?: Record<string, unknown>;
  params: FetchCustomerSetupType;
  options?: Record<string, unknown>;
}

export const useQuerySportEditCustomer = ({
  queryKey = QUERY_KEYS.CUSTOMER_SETUP,
  queryKeyParams,
  params,
  options,
}: UseQueryEvent) => {
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchCustomerSetup(params),
    ...options,
  });

  return { data, isLoading, error };
};
