import styled, { css } from 'styled-components';
import { MatchesType } from '@ntb-sport/types';

interface StyledProps {
  $side?: 'left' | 'right';
  $area?: 'possession' | 'finishes' | 'cards' | 'setpieces' | 'other';
  $type?: 'success' | 'failure';
  $matches?: MatchesType;
  $isHighestValue?: boolean;
  $backgroundColor?: any;
  $textColor?: string;
}

export const Container = styled.div`
  background-color: var(--background-color);
  color: var(--font-color);
  padding: var(--ntb-space-2);
`;

export const MatchStatistics = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

export const BarHeader = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: var(--ntb-space-1);
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-semibold);
`;

export const BarContainer = styled.div`
  display: flex;
  column-gap: var(--ntb-space-1);
`;

export const Bar = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.$side === 'left' ? 'flex-end' : 'flex-start'};
  height: 12px;
  width: 50%;
  border-radius: ${(props) =>
    props.$side === 'left'
      ? 'var(--ntb-radii-md) 0 0 var(--ntb-radii-md)'
      : '0 var(--ntb-radii-md) var(--ntb-radii-md) 0'};
`;

const teamStyles = (props: any) => {
  if (props.$side === 'left') {
    return css`
      padding-left: var(--ntb-space-1);
      font-size: var(--ntb-fontSizes-sm);
      font-weight: var(--ntb-fontWeights-semibold);
      border-radius: var(--ntb-radii-sm) 0 0 var(--ntb-radii-sm);
    `;
  } else if (props.$side === 'right') {
    return css`
      text-align: right;
      padding-right: var(--ntb-space-1);
      font-size: var(--ntb-fontSizes-sm);
      font-weight: var(--ntb-fontWeights-semibold);
      border-radius: 0 var(--ntb-radii-sm) var(--ntb-radii-sm) 0;
    `;
  } else {
    return null;
  }
};

export const BarData = styled.div<StyledProps>`
  height: 24px;
  ${teamStyles};
`;

export const Groups = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  row-gap: var(--ntb-space-2);
`;

export const Group = styled.div<StyledProps>`
  grid-area: ${(props) => props?.$area};
  padding: var(--ntb-space-2);
`;

export const GroupHeader = styled.h3<StyledProps>`
  display: flex;
  justify-content: center;
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-semibold);
  margin-bottom: var(--ntb-space-2);
`;

export const SimpleStatistics = styled.div<StyledProps>`
  display: grid;
  grid-template-areas: 'home label away' 'percentage percentage percentage';
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: var(--ntb-space-4);
  align-items: center;
  justify-items: center;
  margin-bottom: var(--ntb-space-2);
  font-size: var(--ntb-fontSizes-md);
  font-weight: var(--ntb-fontWeights-light);
`;

export const SimpleStatisticsLabel = styled.div<StyledProps>`
  grid-area: label;
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-light);
`;
export const SimpleStatisticsData = styled.div<StyledProps>`
  grid-area: ${(props) => (props?.$side === 'left' ? 'home' : 'away')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--ntb-space-2);
  min-width: var(--ntb-space-8);
  height: var(--ntb-space-8);
  border-radius: var(--ntb-radii-md);
  border-width: 1px;

  background-color: ${(props) => props?.$backgroundColor};
  border-color: transparent;
  color: ${(props) => props?.$textColor};
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-semibold);
  justify-self: ${(props) => (props?.$side === 'left' ? 'end' : 'start')};
`;
