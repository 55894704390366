import { Fetch } from '../../fetch';

interface FetchPostNrkVideoProps {
  videoId: string | number;
  apiBaseUrl: string;
  token: string;
}

export const deleteNrkVideo = async ({
  apiBaseUrl,
  videoId,
  token,
}: FetchPostNrkVideoProps) => {
  const url = `${apiBaseUrl}/nrkVideos/${videoId}`;

  const json = await Fetch({
    url,
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      type: 'nifs:nrkMatchEventVideo',
    }),
  });

  return json;
};
