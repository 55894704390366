import { Icon } from '../../chakra';

export const SilverIcon = (props: any) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path d="M16 0L29.8564 8V24L16 32L2.14359 24V8L16 0Z" fill="#B6BABF" />
    <path
      d="M16 1L28.9904 8.5V23.5L16 31L3.00962 23.5V8.5L16 1Z"
      fill="#CED3D9"
    />
    <path d="M16 6L24.6603 11V21L16 26L7.33975 21V11L16 6Z" fill="#DAE0E6" />
    <text
      x="50%"
      y="50%"
      className="text"
      fill="#000"
      dominantBaseline="middle"
      textAnchor="middle"
    >
      {props?.children}
    </text>
  </Icon>
);
