import { NifsMatchStreamType } from '@ntb-sport/types';
import RadioButton from '../../common/RadioButton/RadioButton';
import { NrkRadioPlayer } from '../../common/MediaPlayer/NrkRadioPlayer';
import { useState } from 'react';
import * as S from './RadioHero.styled';
import { COMPONENT_IDS } from '@ntb-sport/constants';

interface RadioHeroProps {
  matchStreams: NifsMatchStreamType[];
  customerConcernId: number;
  competitionId: string | number;
}

export const RadioHero = ({
  matchStreams,
  customerConcernId,
  competitionId,
}: RadioHeroProps) => {
  const radioStream = matchStreams?.find(
    (matchStream: any) =>
      matchStream?.radio &&
      (matchStream?.customer?.customerConcernId === customerConcernId ||
        matchStream?.customerConcernId === customerConcernId),
  );

  const [isPlaying, setIsPlaying] = useState(false);
  const [radioId, setRadioId] = useState<string | undefined>(undefined);

  const isNrk = customerConcernId === 13;

  const onClickRadioButton = (radioId: string) => {
    if (!isPlaying) {
      setIsPlaying(true);
      setRadioId(radioId);
    } else {
      setIsPlaying(false);
      setRadioId(undefined);
    }
  };

  if (!radioStream) return null;

  return (
    <>
      {isNrk && (
        <>
          <RadioButton
            isPlaying={Boolean(radioId)}
            onClick={() => onClickRadioButton(radioStream.link)}
            competitionId={competitionId}
          />

          {radioId && (
            <NrkRadioPlayer
              videoId={radioId}
              onClose={() => setRadioId(undefined)}
            />
          )}
        </>
      )}
      {!isNrk && (
        <S.Untold
          href="https://untold.app/premier-league"
          target="_blank"
          data-component-id={COMPONENT_IDS.RADIO_HERO}
        >
          {'Hør kampen på Untold'}
          <img
            width="16px"
            src="https://sportsdata-widgets.ntb.no/logo/untold.png"
          />
        </S.Untold>
      )}
    </>
  );
};
