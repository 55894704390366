import { StyleDocumentation } from '@ntb-sport/types';

// Description/documentation for each possible style
export const WIDGET_STYLE_DOCUMENTATION: StyleDocumentation = {
  '--background-color':
    'Specifies the background color of the component. All children will inherit the given background color, with the exception of children that has their own background-color property set specifically.',

  '--bench-font-color':
    'Sets the font color of the bench element. Applying this property will override the default color of text for the bench element.',

  '--border-color':
    'Specifies the border color of the component. This will override the border color inherited from the parent element.',

  '--button-background-color':
    'Specifies the background color of the button element. Applying this property will override the global background color for the button element.',
  '--button-background-color__hover':
    'Specifies the background color of the button element when hovered. Applying this property will override the global hover background color for the button element.',
  '--button-border-color':
    'Sets the border color of the button element. Applying this property will override the global border color for the button element.',
  '--button-border-color__hover':
    'Sets the border color of the button element when hovered. Applying this property will override the global hover border color for the button element.',
  '--button-font-color':
    'Sets the text color of the button element. Applying this property will override the global color of text for the button element.',
  '--button-font-color__hover':
    'Sets the text color of the button element when hovered. Applying this property will override the global hover color of text for the button element.',

  '--bar-background-color':
    'Specifies the background color of the bar elements. If not supplied the parent background color will be used.',
  '--bar-statistics-color':
    'Specifies the color of the statistics bar elements. If not supplied the parent color will be used.',

  '--clock-background-color':
    'Specifies the background color of the clock element. Applying this property will override the default background color for the clock element.',
  '--clock-overtime-background-color':
    'Specifies the background color of the clock element when in overtime. Applying this property will override the default overtime background color for the clock element.',

  '--colors-error-message-text':
    'Sets the color of the error message text. If not supplied the parent error text color will be used.',
  '--colors-form-background':
    'Specifies the background color of the form element. Applying this property will override the global background color for the form element.',
  '--colors-form-text':
    'Sets the color of text for the form element. Applying this property will override the global color of text for the form element.',
  '--colors-input-background':
    'Specifies the background color of the input field element. Applying this property will override the global background color for the input field element.',
  '--colors-input-border':
    'Specifies the border color of the input field element. Applying this property will override the global border color for the input field element.',
  '--colors-input-border__focus':
    'Specifies the border color of the input field element when focused. Applying this property will override the global focus border color for the input field element.',
  '--colors-input-border__hover':
    'Specifies the border color of the input field element when hovered. Applying this property will override the global hover border color for the input field element.',
  '--colors-input-border__invalid':
    'Specifies the border color of the input field element when invalid. Applying this property will override the global invalid border color for the input field element.',
  '--colors-input-placeholder-text':
    'Sets the color of the placeholder text of the input field element. Applying this property will override the global placeholder text color for the input field element.',
  '--colors-input-text':
    'Sets the color of the text inside the input field element. Applying this property will override the global text color for the input field element.',
  '--colors-open-close-icon-color':
    'Sets the color of the open/close button that toggles the visibility of the form element. Applying this property will override the default color of the open/close button.',
  '--colors-radio-background__checked':
    'Specifies the background color of the radio button element when checked. Applying this property will override the global background color for the radio button element when checked.',
  '--colors-radio-border__checked':
    'Specifies the border color of the radio button element when checked. Applying this property will override the global border color for the radio button element when checked.',
  '--colors-radio-center__checked':
    'Specifies the color of the center of the radio button element when checked. Applying this property will override the global color of the center of the radio button element when checked.',
  '--colors-search-background':
    'Specifies the background color of the search field element. If not supplied the parent background color will be used.',
  '--colors-search-border':
    'Specifies the border color of the search field element. If not supplied the parent border color will be used.',
  '--colors-search-border__hover':
    'Specifies the border color of the search field element when hovered. If not supplied the parent hover border color will be used.',
  '--colors-search-border__focus':
    'Specifies the border color of the search field element when focused. If not supplied the parent focus border color will be used.',
  '--colors-search-icon':
    'Sets the color of the search icon inside the search field element.',
  '--colors-search-placeholder-text':
    'Sets the color of the placeholder text of the search field element. If not supplied the parent placeholder text color will be used.',
  '--colors-submit-bg':
    'Specifies the background color of the submit button element. Applying this property will override the global background color for the submit button element.',
  '--colors-submit-bg__hover':
    'Specifies the background color of the submit button element when hovered. Applying this property will override the global hover background color for the submit button element.',
  '--colors-submit-font':
    'Sets the color of text for the submit button element. Applying this property will override the global color of text for the submit button element.',
  '--colors-submit-font__hover':
    'Sets the color of text for the submit button element when hovered. Applying this property will override the global hover color of text for the submit button element.',
  '--colors-table-cell-background__hover':
    'Specifies the background color of the table cell elements when hovered. If not supplied the parent hover background color will be used.',
  '--colors-table-cell-text__hover':
    'Sets the color of text for the table cell elements when hovered. If not supplied the parent hover color of text will be used.',
  '--colors-table-head-text':
    'Sets the color of text for the table head elements. Applying this property will override the global color of text for the table head elements.',
  '--colors-search-text':
    'Sets the color of the text inside the search field element. If not supplied the parent text color will be used.',

  '--column-border-color':
    'Specifies the border color of the column elements. If not supplied the parent border color will be used.',

  '--competition-header-background-color':
    'Specifies the background color of the competition header element. Applying this property will override the global background color for the competition header element.',
  '--competition-header-font-color':
    'Sets the color of text for the competition header element. Applying this property will override the global color of text for the competition header element.',

  '--date-background-color':
    'Specifies the background color of the date info element. Applying this property will override the default background color for the date info element.',
  '--date-font-color':
    'Sets the color of text for the date info element. Applying this property will override the default color of text for the date info element.',

  '--divider-line-bottom-color':
    'Specifies the color of the bottom divider line. Applying this property will override the global bottom divider line color.',
  '--divider-line-top-color':
    'Specifies the color of the top divider line. Applying this property will override the global top divider line color.',

  '--draw-color':
    'Specifies the color to highlight draws. Applying this property will override the global draw color.',

  '--error-color':
    'Specifies the color to highlight errors. Applying this property will override the global error color.',

  '--event-background-color':
    'Specifies the background color of the event elements. If not supplied the parent background color will be used.',
  '--event-background-color__hover':
    'Specifies the background color of the event elements when hovered. If not supplied the parent hover background color will be used.',
  '--event-font-color':
    'Sets the color of text for the event elements. If not supplied the parent color of text will be used.',
  '--event-border-color':
    'Specifies the border color of the event elements. If not supplied the parent border color will be used.',
  '--event-icon-border-color':
    'Specifies the border color of the event icon elements. If not supplied the parent border color will be used.',
  '--event-icon-background-color':
    'Specifies the background color of the event icon elements. If not supplied the parent background color will be used.',
  '--event-icon-color':
    'Specifies the color of the event icon elements. If not supplied the parent color will be used.',
  '--event-error-background-color':
    'Specifies the background color of the event elements when error. If not supplied the parent error background color will be used.',
  '--event-error-font-color': '',
  '--event-error-border-color': '',
  '--event-error-icon-border-color': '',
  '--event-error-icon-background-color': '',
  '--event-error-icon-color': '',
  '--event-error-title-font-color': '',
  '--event-error-minor-background-color': '',
  '--event-error-minor-font-color': '',
  '--event-error-minor-border-color': '',
  '--event-error-minor-icon-border-color': '',
  '--event-error-minor-icon-background-color': '',
  '--event-error-minor-icon-color': '',
  '--event-error-minor-title-font-color': '',
  '--event-highlight-background-color': '',
  '--event-highlight-border-color': '',
  '--event-highlight-icon-border-color': '',
  '--event-highlight-icon-background-color': '',
  '--event-highlight-icon-color': '',
  '--event-highlight-title-font-color': '',
  '--event-success-background-color': '',
  '--event-success-font-color': '',
  '--event-success-border-color': '',
  '--event-success-icon-border-color': '',
  '--event-success-icon-background-color': '',
  '--event-success-icon-color': '',
  '--event-success-title-font-color': '',
  '--event-success-minor-background-color': '',
  '--event-success-minor-font-color': '',
  '--event-success-minor-border-color': '',
  '--event-success-minor-icon-border-color': '',
  '--event-success-minor-icon-background-color': '',
  '--event-success-minor-icon-color': '',
  '--event-success-minor-title-font-color': '',
  '--event-time-background-color': '',
  '--event-time-border-color': '',
  '--event-time-icon-border-color': '',
  '--event-time-icon-background-color': '',
  '--event-time-icon-color': '',
  '--event-warning-background-color': '',
  '--event-warning-border-color': '',
  '--event-warning-icon-border-color': '',
  '--event-warning-icon-background-color': '',
  '--event-warning-icon-color': '',

  '--font-color':
    'Sets the color of text for the component. All children will inherit the given color of text, with the exception of children that has their own color property set specifically.',

  '--header-background-color':
    'Specifies the background color of the header element. Applying this property will override the global background color for the header element.',
  '--header-border-color':
    'Specifies the border color of the header element. Applying this property will override the global border color for the header element.',
  '--header-button-background-color':
    'Specifies the background color of the header button element. Applying this property will override the global background color for the header button element.',
  '--header-button-background-color__hover':
    'Specifies the background color of the header button element when hovered. Applying this property will override the global hover background color for the header button element.',
  '--header-button-font-color':
    'Sets the text color of the header button element. Applying this property will override the global color of text for the header button element.',
  '--header-button-font-color__hover':
    'Sets the text color of the header button element when hovered. Applying this property will override the global hover color of text for the header button element.',
  '--header-font-color':
    'Sets the color of text for the header element. Applying this property will override the global color of text for the header element.',
  '--header-modal-background-color':
    'Specifies the background color of the header modal element. Applying this property will override the global background color for the header modal element.',
  '--header-modal-font-color':
    'Sets the color of text for the header modal element. Applying this property will override the global color of text for the header modal element.',
  '--header-shadow-color':
    'Specifies the color of the shadow of the header element. Applying this property will override the global shadow color for the header element.',

  '--highlight-button-thumb-background-color':
    'Specifies the background color of the highlight button thumb element. Applying this property will override the default background color.',
  '--highlight-button-track-background-color':
    'Specifies the background color of the highlight button track element. Applying this property will override the default background color.',
  '--highlight-button-track-background-color__checked':
    'Specifies the background color of the highlight button track element when checked. Applying this property will override the default checked background color.',

  '--highlighted-bg-color':
    'Specifies the background color of the highlighted element. Applying this property will override the default background color for the highlighted element.',
  '--highlighted-bg-color-grad-1':
    'Specifies the first background gradient for the highlighet element.',
  '--highlighted-bg-color-grad-2':
    'Specifies the second background gradient for the highlighet element.',
  '--highlighted-bg-color-grad-3':
    'Specifies the third background gradient for the highlighet element.',
  '--highlighted-bio-font-color':
    'Sets the color of text for the bio elements of the highlighted element.',
  '--highlighted-stat-font-color':
    'Sets the color of text for the stat elements of the highlighted element.',

  '--international-cup-qualifier-color-1':
    'Specifies the color of the columns that represent the international cup qualifier positions for first level cups.',
  '--international-cup-qualifier-color-2':
    'Specifies the color of the columns that represent the international cup qualifier positions for second level cups.',

  '--logo-background-color':
    'Specifies the background color of the logo element. Applying this property will override the default background color for the logo element.',
  '--logo-border-color':
    'Specifies the border color of the logo element. Applying this property will override the default border color for the logo element.',
  '--logo-color':
    'Specifies the color of the logo element. Applying this property will override the default color for the logo element. This attribute only works for the fallback icon that is loaded when we dont have a tournament logo in our database.',

  '--loss-color':
    'Specifies the color to highlight losses. Applying this property will override the global loss color.',

  '--menu-arrow-background-color':
    'Specifies the background color of the menu arrow element. Applying this property will override the global background color for the menu arrow element.',
  '--menu-arrow-background-color__hover':
    'Specifies the background color of the menu arrow element when hovered. Applying this property will override the global hover background color for the menu arrow element.',
  '--menu-arrow-border-color':
    'Specifies the border color of the menu arrow element. Applying this property will override the global border color for the menu arrow element.',
  '--menu-arrow-border-color__hover':
    'Specifies the border color of the menu arrow element when hovered. Applying this property will override the global hover border color for the menu arrow element.',
  '--menu-arrow-font-color':
    'Sets the text color of the menu arrow element. Applying this property will override the global color of text for the menu arrow element.',
  '--menu-arrow-font-color__hover':
    'Sets the text color of the menu arrow element when hovered. Applying this property will override the global hover color of text for the menu arrow element.',
  '--menu-background-color':
    'Specifies the background color of the menu element. Applying this property will override the global background color for the menu element.',
  '--menu-background-color__active':
    'Specifies the background color of the menu element when active. Applying this property will override the global active background color for the menu element.',
  '--menu-background-color__hover':
    'Specifies the background color of the menu element when hovered. Applying this property will override the global hover background color for the menu element.',
  '--menu-border-color':
    'Specifies the border color of the menu element. Applying this property will override the global border color for the menu element.',
  '--menu-border-color__active':
    'Specifies the border color of the menu element when active. Applying this property will override the global active border color for the menu element.',
  '--menu-border-color__hover':
    'Specifies the border color of the menu element when hovered. Applying this property will override the global hover border color for the menu element.',
  '--menu-button-background-color':
    'Specifies the background color of the menu button element. Applying this property will override the global background color for the menu button element.',
  '--menu-button-background-color__hover':
    'Specifies the background color of the menu button element when hovered. Applying this property will override the global hover background color for the menu button element.',
  '--menu-button-font-color':
    'Sets the text color of the menu button element. Applying this property will override the global color of text for the menu button element.',
  '--menu-button-font-color__hover':
    'Sets the text color of the menu button element when hovered. Applying this property will override the global hover color of text for the menu button element.',
  '--menu-font-color':
    'Sets the color of text for the menu element. Applying this property will override the global color of text for the menu element.',
  '--menu-font-color__active':
    'Sets the color of text for the menu element when active. Applying this property will override the global active color of text for the menu element.',
  '--menu-font-color__hover':
    'Sets the color of text for the menu element when hovered. Applying this property will override the global hover color of text for the menu element.',

  '--menu-item-background-color':
    'Specifies the background color of the menu item elements. If not supplied the parent background color will be used.',
  '--menu-item-background-color__active':
    'Specifies the background color of the menu item elements when active. If not supplied the parent active background color will be used.',
  '--menu-item-background-color__active__hover':
    'Specifies the background color of the menu item elements when active and hovered. If not supplied the parent active hover background color will be used.',
  '--menu-item-background-color__hover':
    'Specifies the background color of the menu item elements when hovered. If not supplied the parent hover background color will be used.',
  '--menu-item-border-color':
    'Specifies the border color of the menu item elements. If not supplied the parent border color will be used.',
  '--menu-item-border-color__active':
    'Specifies the border color of the menu item elements when active. If not supplied the parent active border color will be used.',
  '--menu-item-border-color__hover':
    'Specifies the border color of the menu item elements when hovered. If not supplied the parent hover border color will be used.',
  '--menu-item-font-color':
    'Sets the color of text for the menu item elements. If not supplied the parent color of text will be used.',
  '--menu-item-font-color__active':
    'Sets the color of text for the menu item elements when active. If not supplied the parent active color of text will be used.',
  '--menu-item-font-color__active__hover':
    'Sets the color of text for the menu item elements when active and hovered. If not supplied the parent active hover color of text will be used.',
  '--menu-item-font-color__hover':
    'Sets the color of text for the menu item elements when hovered. If not supplied the parent hover color of text will be used.',

  '--ntb-background-color':
    'Specifies the background color of the component. All children will inherit the given background color, with the exception of children that has their own background-color property set specifically.',
  '--ntb-font-color':
    'Sets the color of text for the component. All children will inherit the given color of text, with the exception of children that has their own color property set specifically.',
  '--ntb-match-background-color':
    'Specifies the background color of the match element. Applying this property will override the default background color for the match element.',
  '--ntb-match-border-color':
    'Specifies the border color of the match element. Applying this property will override the default border color for the match element.',
  '--ntb-match-font-color':
    'Sets the color of text for the match element. Applying this property will override the default color of text for the match element.',
  '--highlighted-team-background-color':
    'Specifies the background color of the highlighted team element. Applying this property will override the default background color for the highlighted team element.',
  '--highlighted-team-border-color':
    'Specifies the border color of the highlighted team element. Applying this property will override the default border color for the highlighted team element.',
  '--highlighted-team-font-color':
    'Sets the color of text for the highlighted team element. Applying this property will override the default color of text for the highlighted team element.',

  '--matches-background-color':
    'Specifies the global background color of the match elements. Applying this property will override the default background color for the match elements.',
  '--matches-font-color':
    'Sets the global color of text for the match elements. Applying this property will override the default color of text for the match elements.',

  '--ntb-match-background-color__dissalowed-goal':
    'Specifies the background color of the match element when goal is dissalowed. Applying this property will override the default background color for the match element when goal is dissalowed.',
  '--ntb-match-background-color__goal':
    'Specifies the background color of the match element when goal is scored. Applying this property will override the default background color for the match element when goal is scored.',
  '--ntb-match-background-color__hover':
    'Specifies the background color of the match element when hovered. Applying this property will override the default hover background color for the match element.',
  '--ntb-match-matchscore-divider-color__in-progress':
    'Specifies the color of the match score divider element when match is in progress. Applying this property will override the default color of the match score divider element when in progress.',
  '--ntb-match-matchscore-divider-color__not-started':
    'Specifies the color of the match score divider element when match has not started. Applying this property will override the default color of the match score divider element when not started.',
  '--ntb-match-matchscore-divider-color__played':
    'Specifies the color of the match score divider element when match is finished. Applying this property will override the default color of the match score divider element when finished.',
  '--ntb-match-matchscore-font-color__in-progress':
    'Sets the color of text for the match score element when match is in progress. Applying this property will override the default color of text for the match score element when in progress.',
  '--ntb-match-matchscore-font-color__not-started':
    'Sets the color of text for the match score element when match has not started. Applying this property will override the default color of text for the match score element when not started.',
  '--ntb-match-matchscore-font-color__played':
    'Sets the color of text for the match score element when match is finished. Applying this property will override the default color of text for the match score element when finished.',
  '--ntb-match-settings-background-color':
    'Specifies the background color of the match settings element. Applying this property will override the default background color for the match settings element.',
  '--ntb-match-settings-background-color__hover':
    'Specifies the background color of the match settings element when hovered. Applying this property will override the default hover background color for the match settings element.',
  '--ntb-match-settings-border-color':
    'Specifies the border color of the match settings element. Applying this property will override the default border color for the match settings element.',
  '--ntb-match-settings-border-color__hover':
    'Specifies the border color of the match settings element when hovered. Applying this property will override the default hover border color for the match settings element.',
  '--ntb-match-settings-font-color':
    'Sets the color of text for the match settings element. Applying this property will override the default color of text for the match settings element.',
  '--ntb-match-settings-font-color__hover':
    'Sets the color of text for the match settings element when hovered. Applying this property will override the default hover color of text for the match settings element.',
  '--ntb-match-matchstatus-background-color__not-set':
    'Specifies the background color of the match status element when match date is not set. Applying this property will override the default background color for the match status element when not set.',
  '--ntb-match-matchstatus-background-color__not-started':
    'Specifies the background color of the match status element when match has not started. Applying this property will override the default background color for the match status element when not started.',
  '--ntb-match-matchstatus-font-color__not-set':
    'Sets the color of text for the match status element when match date is not set. Applying this property will override the default color of text for the match status element when not set.',
  '--ntb-match-matchstatus-font-color__not-started':
    'Sets the color of text for the match status element when match has not started. Applying this property will override the default color of text for the match status element when not started.',
  '--ntb-match-matchscore-background-color__in-progress':
    'Specifies the background color of the match score element when match is in progress. Applying this property will override the default background color for the match score element when in progress.',
  '--ntb-match-matchscore-background-color__not-started':
    'Specifies the background color of the match score element when match has not started. Applying this property will override the default background color for the match score element when not started.',
  '--ntb-match-matchscore-background-color__played':
    'Specifies the background color of the match score element when match is finished. Applying this property will override the default background color for the match score element when finished.',
  '--ntb-match-result-background-color__finished':
    'Specifies the background color of the match result element when match is finished. Applying this property will override the default background color for the match result element when finished.',
  '--ntb-match-result-background-color__in-progress':
    'Specifies the background color of the match result element when match is in progress. Applying this property will override the default background color for the match result element when in progress.',
  '--ntb-match-result-font-color__finished':
    'Sets the color of text for the match result element when match is finished. Applying this property will override the default color of text for the match result element when finished.',
  '--ntb-match-result-font-color__in-progress':
    'Sets the color of text for the match result element when match is in progress. Applying this property will override the default color of text for the match result element when in progress.',
  '--ntb-matches-background-color':
    'Specifies the global background color of the match elements. Applying this property will override the default background color for the match elements.',
  '--ntb-matches-font-color':
    'Sets the global color of text for the match elements. Applying this property will override the default color of text for the match elements.',
  '--ntb-section-background-color':
    'Specifies the background color of the section element. Applying this property will override the default background color for the section element.',
  '--ntb-section-border-color':
    'Specifies the border color of the section element. Applying this property will override the default border color for the section element.',
  '--ntb-section-font-color':
    'Sets the color of text for the section element. Applying this property will override the default color of text for the section element.',
  '--ntb-stage-header-background-color':
    'Specifies the background color of the stage header element. Applying this property will override the default background color for the stage header element.',
  '--ntb-stage-header-border-color':
    'Specifies the border color of the stage header element. Applying this property will override the default border color for the stage header element.',
  '--ntb-stage-header-font-color':
    'Sets the color of text for the stage header element. Applying this property will override the default color of text for the stage header element.',
  '--ntb-stage-header-link-background-color':
    'Specifies the background color of the stage header link element. Applying this property will override the default background color for the stage header link element.',
  '--ntb-stage-header-link-background-color__hover':
    'Specifies the background color of the stage header link element when hovered. Applying this property will override the default hover background color for the stage header link element.',
  '--ntb-stage-header-link-color':
    'Sets the color of text for the stage header link element. Applying this property will override the default color of text for the stage header link element.',
  '--ntb-stage-header-link-color__hover':
    'Sets the color of text for the stage header link element when hovered. Applying this property will override the default hover color of text for the stage header link element.',
  '--ntb-tournament-background-color':
    'Specifies the background color of the tournament element. Applying this property will override the default background color for the tournament element.',
  '--ntb-tournament-header-background-color':
    'Specifies the background color of the tournament header element. Applying this property will override the default background color for the tournament header element.',
  '--ntb-tournament-header-border-color':
    'Specifies the border color of the tournament header element. Applying this property will override the default border color for the tournament header element.',
  '--ntb-tournament-header-font-color':
    'Sets the color of text for the tournament header element. Applying this property will override the default color of text for the tournament header element.',
  '--ntb-tournament-header-link-background-color':
    'Specifies the background color of the tournament header link element. Applying this property will override the default background color for the tournament header link element.',
  '--ntb-tournament-header-link-color':
    'Sets the color of text for the tournament header link element. Applying this property will override the default color of text for the tournament header link element.',
  '--ntb-tournament-header-link-background-color__hover':
    'Specifies the background color of the tournament header link element when hovered. Applying this property will override the default hover background color for the tournament header link element.',
  '--ntb-tournament-header-link-color__hover':
    'Sets the color of text for the tournament header link element when hovered. Applying this property will override the default hover color of text for the tournament header link element.',
  '--ntb-tournament-header-logo-background-color':
    'Specifies the background color of the tournament header logo element. Applying this property will override the default background color for the tournament header logo element.',

  '--padding-form-body':
    'Specifies the padding of the form element. Adjust positional values with care as it may break the layout of the form.',
  '--pitch-background-color':
    'Specifies the background color of the pitch element. Applying this property will override the default background color for the pitch element.',
  '--pitch-font-color':
    'Sets the color of text for the pitch element. Applying this property will override the default color of text for the pitch element.',
  '--pitch-line-color':
    'Specifies the color of the lines on the pitch element. Applying this property will override the default color of lines for the pitch element.',
  '--pitch-stripe-color':
    'Specifies the color of the stripes on the pitch element. Applying this property will override the default color of stripes for the pitch element.',

  '--playoff-promotion-color':
    'Specifies the color of the columns that represent the playoffs for promotion ranks in a league',
  '--playoff-relegation-color':
    'Specifies the color of the columns that represent the playoffs for relegation ranks in a league',
  '--promotion-color':
    'Specifies the color of the columns that represent the direct promotion ranks in a league',

  '--radio-button-spacing':
    'Specifies the horisontal spacing between the radio button elements. Adjust positional values with care as it may break the layout of the form.',

  '--rank-background-color':
    'Specifies the background color of the rank element. Applying this property will override the default background color for the rank element.',
  '--rank-background-color__highlighted':
    'Specifies the background color of the rank element when highlighted. Applying this property will override the default highlighted background color for the rank element.',
  '--rank-font-color':
    'Sets the color of text for the rank element. Applying this property will override the default color of text for the rank element.',
  '--rank-font-color__highlighted':
    'Sets the color of text for the rank element when highlighted. Applying this property will override the default highlighted color of text for the rank element.',

  '--relegation-color':
    'Specifies the color of the columns that represent the direct relegation ranks in a league',

  '--result-background-color__finished':
    'Specifies the background color of the result elements when finished. If not supplied the parent finished background color will be used.',
  '--result-background-color__in-progress':
    'Specifies the background color of the result elements when in progress. If not supplied the parent in progress background color will be used.',
  '--result-background-color__not-set':
    'Specifies the background color of the result elements when not set. If not supplied the parent not set background color will be used.',
  '--result-background-color__not-started':
    'Specifies the background color of the result elements when not started. If not supplied the parent not started background color will be used.',
  '--result-font-color__finished':
    'Sets the color of text for the result elements when finished. If not supplied the parent finished color of text will be used.',
  '--result-font-color__in-progress':
    'Sets the color of text for the result elements when in progress. If not supplied the parent in progress color of text will be used.',
  '--result-font-color__not-set:':
    'Sets the color of text for the result elements when not set. If not supplied the parent not set color of text will be used.',
  '--result-font-color__not-started':
    'Sets the color of text for the result elements when not started. If not supplied the parent not started color of text will be used.',
  '--result-font-color':
    'Sets the color of text for the result elements. If not supplied the parent color of text will be used.',
  '--result-border-color':
    'Specifies the border color of the result elements. If not supplied the parent border color will be used.',
  '--result-highlight-color':
    'Specifies the color to highlight the result elements. If not supplied the parent highlight color will be used.',
  '--result-rank-background-color':
    'Specifies the background color of the rank elements that are children of the result element. If not supplied the parent background color will be used.',
  '--result-rank-background-color__highlighted':
    'Specifies the background color of the rank elements that are children of the result element when highlighted. If not supplied the parent highlighted background color will be used.',
  '--result-rank-font-color':
    'Sets the color of text for the rank elements that are children of the result element. If not supplied the parent color of text will be used.',
  '--result-rank-font-color__highlighted':
    'Sets the color of text for the rank elements that are children of the result element when highlighted. If not supplied the parent highlighted color of text will be used.',

  '--row-background-color':
    'Specifies the background color of the row elements. If not supplied the parent background color will be used.',
  '--row-background-color__hover':
    'Specifies the background color of the row elements when hovered. If not supplied the parent hover background color will be used.',
  '--row-border-color':
    'Specifies the border color of the row elements. If not supplied the parent border color will be used.',
  '--row-font-color':
    'Sets the color of text for the row elements. If not supplied the parent color of text will be used.',

  '--table-info-background-color':
    'Specifies the background color of the table info modal. Applying this property will override the default background color for the table info modal.',

  '--search-font-size':
    'Specifies the font size of the text inside the search field element. If not supplied the parent font size will be used.',
  '--search-placeholder-font-size':
    'Specifies the font size of the placeholder text of the search field element. If not supplied the parent placeholder font size will be used.',

  '--section-background-color':
    'Specifies the background color of the section element. Applying this property will override the default background color for the section element.',
  '--section-border-color':
    'Specifies the border color of the section element. Applying this property will override the default border color for the section element.',
  '--section-font-color':
    'Sets the color of text for the section element. Applying this property will override the default color of text for the section element.',
  '--starting-order-background-color':
    'Specifies the background color of the starting order element. Applying this property will override the default background color for the starting order element.',
  '--starting-order-background-color__highlighted':
    'Specifies the background color of the starting order element when highlighted. Applying this property will override the default highlighted background color for the starting order element.',
  '--starting-order-font-color':
    'Sets the color of text for the starting order element. Applying this property will override the default color of text for the starting order element.',
  '--starting-order-font-color__highlighted':
    'Sets the color of text for the starting order element when highlighted. Applying this property will override the default highlighted color of text for the starting order element.',

  '--stat-background-color':
    'Specifies the background color of the stat elements. If not supplied the parent background color will be used.',
  '--stat-cards-font-color':
    'Sets the color of text for the yellow and red card icons.',
  '--stat-font-color':
    'Sets the color of text for the stat elements. If not supplied the parent color of text will be used.',
  '--stat-red-card-bg': 'Specifies the background color of the red card icon.',
  '--stat-yellow-card-bg':
    'Specifies the background color of the yellow card icon.',

  '--statistics-background-color':
    'Specifies the background color of the statistics element. Applying this property will override the default background color for the statistics element.',
  '--statistics-font-color':
    'Sets the color of text for the statistics element. Applying this property will override the default color of text for the statistics element.',

  '--success-color':
    'Specifies the color to highlight success. Applying this property will override the global success color.',

  '--summary-background-color':
    'Specifies the background color of the summary match summary element. Applying this property will override the default background color.',
  '--summary-font-color':
    'Sets the color of text for the summary match summary element. Applying this property will override the default color of text.',

  '--team-result-bg__draw:':
    'Specifies the background color of the team result element when draw. If not supplied the parent draw background color will be used.',
  '--team-result-bg__loss':
    'Specifies the background color of the team result element when loss. If not supplied the parent loss background color will be used.',
  '--team-result-bg__win':
    'Specifies the background color of the team result element when win. If not supplied the parent win background color will be used.',
  '--team-result-font-color':
    'Sets the color of text for the team result element. If not supplied the parent color of text will be used.',

  '--toggle-background-color':
    'Specifies the background color of the toggle element. Applying this property will override the default background color for the toggle element.',
  '--toggle-background-color__hover':
    'Specifies the background color of the toggle element when hovered. Applying this property will override the default hover background color for the toggle element.',
  '--toggle-background-color__active':
    'Specifies the background color of the toggle element when active. Applying this property will override the default active background color for the toggle element.',
  '--toggle-border-color':
    'Specifies the border color of the toggle element. Applying this property will override the default border color for the toggle element.',
  '--toggle-border-color__active':
    'Specifies the border color of the toggle element when active. Applying this property will override the default active border color for the toggle element.',
  '--toggle-border-color__hover':
    'Specifies the border color of the toggle element when hovered. Applying this property will override the default hover border color for the toggle element.',
  '--toggle-font-color':
    'Sets the color of text for the toggle element. Applying this property will override the default color of text for the toggle element.',
  '--toggle-font-color__active':
    'Sets the color of text for the toggle element when active. Applying this property will override the default active color of text for the toggle element.',
  '--toggle-font-color__hover':
    'Sets the color of text for the toggle element when hovered. Applying this property will override the default hover color of text for the toggle element.',

  '--warning-color':
    'Specifies the color to highlight warnings. Applying this property will override the global warning color.',

  '--win-color':
    'Specifies the color to highlight wins. Applying this property will override the global win color.',

  '--calendar-background-color': '',
  '--calendar-font-color': '',
  '--calendar-day-background-color': '',
  '--calendar-day-border-color': '',
  '--calendar-day-font-color': '',
  '--calendar-day-background-color__hover': '',
  '--calendar-day-border-color__hover': '',
  '--calendar-day-font-color__hover': '',
  '--calendar-day-background-color__active': '',
  '--calendar-day-border-color__active': '',
  '--calendar-day-font-color__active': '',
};

export const CSS_VARIABLES = {
  BACKGROUND_COLOR: {
    name: '--background-color',
    description: WIDGET_STYLE_DOCUMENTATION['--background-color'],
  },
  BACKGROUND_COLOR__HOVER: {
    name: '--background-color__hover',
    description: WIDGET_STYLE_DOCUMENTATION['--background-color__hover'],
  },
  BACKGROUND_COLOR__ACTIVE: {
    name: '--background-color__active',
    description: WIDGET_STYLE_DOCUMENTATION['--background-color__active'],
  },
  FONT_COLOR: {
    name: '--font-color',
    description: WIDGET_STYLE_DOCUMENTATION['--font-color'],
  },
  FONT_COLOR__HOVER: {
    name: '--font-color__hover',
    description: WIDGET_STYLE_DOCUMENTATION['--font-color__hover'],
  },
  FONT_COLOR__ACTIVE: {
    name: '--font-color__active',
    description: WIDGET_STYLE_DOCUMENTATION['--font-color__active'],
  },
  BORDER_COLOR: {
    name: '--border-color',
    description: WIDGET_STYLE_DOCUMENTATION['--border-color'],
  },
  BORDER_COLOR__HOVER: {
    name: '--border-color__hover',
    description: WIDGET_STYLE_DOCUMENTATION['--border-color__hover'],
  },
  BORDER_COLOR__ACTIVE: {
    name: '--border-color__active',
    description: WIDGET_STYLE_DOCUMENTATION['--border-color__active'],
  },
  BACKGROUND_COLOR_OVERLAY: {
    name: '--background-color-overlay',
    description: WIDGET_STYLE_DOCUMENTATION['--background-color__active'],
  },
};
