import { Fetch } from '../../fetch';

export interface FetchSeasonStandingType {
  apiBaseUrl: string;
  url: string;
  token: string;
  customerUUID?: string;
}

export const fetchSeasonStanding = async ({
  apiBaseUrl,
  url,
  token,
  customerUUID,
}: FetchSeasonStandingType) => {
  const json = await Fetch({
    url: `${apiBaseUrl}${url}`,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });

  return json;
};
