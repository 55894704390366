import { SPORT_GROUP } from '@ntb-sport/constants';
import { NifsMatchType } from '@ntb-sport/types';

import { Fetch, NIFS_API_BASE_URL, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchNifsStageMatches {
  apiBaseUrl: string;
  sportGroup: string;
  stageId: number;
}

export const fetchNifsStageMatches = async ({
  apiBaseUrl = NIFS_API_BASE_URL,
  sportGroup,
  stageId,
}: FetchNifsStageMatches): Promise<NifsMatchType[]> => {
  const url =
    sportGroup === SPORT_GROUP.FOOTBALL
      ? `${apiBaseUrl}/stages/${stageId}/matches/`
      : `${apiBaseUrl}/nifStages/${stageId}/nifMatches/`;

  const json = await Fetch({
    url,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });

  return json;
};
