import styled from 'styled-components';
import { CountryFlag } from '../../common/CountryFlag/CountryFlag';

import { linearGradient, darken } from 'polished';
import { MEDIA_QUERY } from '@ntb-sport/constants';

interface StyledProps {
  $teamBackgroundColor?: string;
  $teamTextColor?: string;
}

export const Container = styled.div<StyledProps>`
  container-type: inline-size;
  container-name: container;
  position: relative;
  display: flex;
  align-items: center;

  padding: var(--ntb-space-4);
  background-color: ${(props) => props.theme.backgroundColor};
  background: ${(props) =>
    linearGradient({
      colorStops: [
        `${props?.$teamBackgroundColor || '#fff'} 0%`,
        `${darken(0.1, props?.$teamBackgroundColor || '#fff')} 100%`,
      ],
      toDirection: 'to bottom right',
      fallback: props?.$teamBackgroundColor,
    })};
  color: ${(props) => props.theme.fontColor};

  @container container ${MEDIA_QUERY.TABLET} {
    padding: 24px;
  }
`;

export const ContainerCenter = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-4);
  margin: 0 auto;
  width: 100%;
`;

export const LogoContainer = styled.div<StyledProps>`
  width: 60px;
  height: 60px;
  padding: var(--ntb-space-1);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  border-style: solid;
  border-width: 5px;
  border-color: ${(props) =>
    darken(0.1, props?.$teamBackgroundColor || '#fff')};

  @container container ${MEDIA_QUERY.TABLET} {
    width: 80px;
    height: 80px;
  }

  @container container ${MEDIA_QUERY.LAPTOP} {
    width: 100px;
    height: 100px;
  }
`;

export const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const NameAndCountry = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: var(--ntb-space-1);
`;
export const Name = styled.h1<StyledProps>`
  font-size: var(--ntb-fontSizes-lg);
  font-weight: var(--ntb-fontWeights-medium);
  line-height: 1.2;
  color: ${(props) =>
    props?.$teamTextColor || 'var(--base-colors-blackAlpha-900)'};

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-lg);
  }

  @container container ${MEDIA_QUERY.LAPTOP} {
    font-size: var(--ntb-fontSizes-3xl);
  }
`;

export const Country = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
  font-size: var(--ntb-fontSizes-sm);
  color: ${(props) =>
    props?.$teamTextColor || 'var(--base-colors-blackAlpha-900)'};

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-lg);
  }

  @container container ${MEDIA_QUERY.LAPTOP} {
    font-size: var(--ntb-fontSizes-xl);
  }
`;
export const Flag = styled(CountryFlag).withConfig({
  shouldForwardProp: (prop) => ['countryName'].includes(prop),
})<StyledProps>`
  min-width: 25px;
  max-width: 25px;

  @container container ${MEDIA_QUERY.LAPTOP} {
    min-width: 30px;
    max-width: 30px;
  }
`;

export const Staff = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  font-size: var(--ntb-fontSizes-sm);
  color: ${(props) => props?.$teamTextColor || '#000'};
`;
