import { useBem } from '@ntb-sport/hooks';
import ResultId from '../ResultId/ResultId';
import './duration.css';

interface DurationProps {
  duration: string | undefined;
  timeDifference: string | undefined;
  resultId: number;
}

export const Duration = ({
  duration,
  timeDifference,
  resultId,
}: DurationProps) => {
  const bem = useBem('scope-results');

  return (
    <div className={bem('duration')}>
      <ResultId resultId={resultId} />
      {duration && <span>{duration}</span>}
      {timeDifference && (
        <span className={bem('time-difference')}>{timeDifference}</span>
      )}
    </div>
  );
};
