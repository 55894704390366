import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import { CSS_VARIABLES } from '../index';

export const SPORT_EDIT_CALENDAR_STYLES = [
  {
    name: 'Sport',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.SPORTEDIT_CALENDAR}'] [data-element-id='${ELEMENT_IDS.SPORT}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Competition',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.SPORTEDIT_CALENDAR}'] [data-element-id='${ELEMENT_IDS.COMPETITION}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Event',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.SPORTEDIT_CALENDAR}'] [data-element-id='${ELEMENT_IDS.EVENT}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
];
