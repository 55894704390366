import { LabelProperties, UUIDs } from 'neo-common-enums';
import { Grouped } from './Grouped/Grouped';
import { Individual } from './Individual/Individual';
import { NeoScopeResultType } from '@ntb-sport/types';
import { useBem } from '@ntb-sport/hooks';
import './results.css';

export interface ResultsProps {
  scopeResults: NeoScopeResultType[];
  sportUUID: string;
  highlightedCountryUUID?: string;
  allShotSeries: any;
  scopeNumber: number;
  isRelay: boolean;
  labels?: string[];
  disciplineUUID?: string;
  maxRank?: number;
  scopeTypeId: number;
  limit: number;
  tourCompetitionUuid?: string;
  scopeCategoryId?: number;
  isMedalEvent?: boolean;
}

export const Results = ({
  scopeResults,
  sportUUID,
  highlightedCountryUUID,
  allShotSeries,
  scopeNumber,
  isRelay,
  labels,
  disciplineUUID,
  maxRank,
  scopeTypeId,
  limit,
  tourCompetitionUuid,
  scopeCategoryId,
  isMedalEvent,
}: ResultsProps) => {
  const bem = useBem('scope-results');

  const isGrouped =
    sportUUID === UUIDs.Sport.CYCLING &&
    scopeTypeId === LabelProperties.ScopeTypeId.CHECKPOINT &&
    !scopeCategoryId &&
    disciplineUUID !== 'discipline-cycling-time-trial';

  const jerseys = scopeResults
    ?.filter((scopeResult: any) => scopeResult.participant.isJerseyParticipant)
    .reduce((acc: any, scopeResult: any) => {
      if (!acc[scopeResult.group]) {
        acc[scopeResult.group] = [];
      }

      const jerseyType = scopeResult.participant.person.name;

      acc[scopeResult.group].push(jerseyType);
      return acc;
    }, {});

  return (
    <ul className={bem('list')}>
      {isGrouped ? (
        <Grouped
          scopeResults={scopeResults}
          sportUUID={sportUUID}
          highlightedCountryUUID={highlightedCountryUUID}
          allShotSeries={allShotSeries}
          scopeNumber={scopeNumber}
          isRelay={isRelay}
          labels={labels}
          disciplineUUID={disciplineUUID}
          maxRank={maxRank}
          scopeTypeId={scopeTypeId}
          jerseys={jerseys}
          tourCompetitionUuid={tourCompetitionUuid}
          isMedalEvent={isMedalEvent}
        />
      ) : (
        <Individual
          scopeResults={scopeResults}
          sportUUID={sportUUID}
          highlightedCountryUUID={highlightedCountryUUID}
          allShotSeries={allShotSeries}
          scopeNumber={scopeNumber}
          isRelay={isRelay}
          labels={labels}
          disciplineUUID={disciplineUUID}
          maxRank={maxRank}
          scopeTypeId={scopeTypeId}
          limit={limit}
          tourCompetitionUuid={tourCompetitionUuid}
          scopeCategoryId={scopeCategoryId}
          isMedalEvent={isMedalEvent}
        />
      )}
    </ul>
  );
};
