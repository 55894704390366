import { API_VERSION, Fetch } from '../../fetch';

export interface FetchEventParticipantsType {
  apiBaseUrl: string;
  version?: string;
  eventUUID: string;
  token: string;
  customerUUID: string;
}

export const fetchEventParticipants = async ({
  apiBaseUrl,
  version = API_VERSION.V4,
  eventUUID,
  token,
  customerUUID,
}: FetchEventParticipantsType) => {
  const json = await Fetch({
    url: `${apiBaseUrl}/${version}/events/${eventUUID}/participants/`,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });
  return json.data;
};
