import styled from 'styled-components';

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: var(--ntb-space-1);
  font-size: var(--ntb-fontSizes-sm);
  text-transform: capitalize;
`;
