import Icon from '../Icon/Icon';

export const HalfTimeIcon = (props: any) => (
  <Icon {...props}>
    <g>
      <g>
        <path
          d="M13.5,1.4V3h-1.4v1.1c-0.3,0-0.5,0-0.8,0s-0.6,0-0.9,0V3H9V1.4C9,1,9.3,0.6,9.8,0.6h2.8
C13.2,0.6,13.5,0.9,13.5,1.4z"
        />
        <path
          d="M18.5,6.8l-0.3,0.3C17.8,6.7,17.4,6.3,17,6l0.4-0.4c0.2-0.2,0.6-0.2,0.8,0L18.6,6C18.8,6.2,18.8,6.6,18.5,6.8
z"
        />
        <path d="M4.4,7.1L4.1,6.8C3.9,6.6,3.9,6.2,4.1,6l0.4-0.4c0.2-0.2,0.6-0.2,0.8,0L5.7,6C5.2,6.3,4.8,6.7,4.4,7.1z" />
        <path d="M11.4,7.2c3.4,0,6.1,2.7,6.1,6.1s-2.7,6.1-6.1,6.1s-6.2-2.6-6.2-6h6.1L11.4,7.2L11.4,7.2z" />
        <g>
          <path
            d="M11.3,22.1c-4.8,0-8.7-3.9-8.7-8.7s3.9-8.7,8.7-8.7S20,8.6,20,13.4S16.2,22.1,11.3,22.1z M11.3,6.2
  c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2S15.3,6.2,11.3,6.2z"
          />
        </g>
      </g>
    </g>
  </Icon>
);
