import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';

import { ELEMENT_IDS } from '@ntb-sport/constants';
import Button from '../../chakra/Button/Button';

interface ShowMoreLessProps {
  dataLength: number;
  handleRowsChange?: (rows: number) => void;
  initialLimit: number;
  limit: number;
}

const ShowMoreLess: React.FC<ShowMoreLessProps> = ({
  dataLength,
  limit,
  initialLimit,
  handleRowsChange,
}) => {
  const showMore = limit < dataLength;
  return (
    <Button
      data-element-id={ELEMENT_IDS.BUTTON}
      size="sm"
      variant="outline"
      padding="1rem 0"
      minW="180px"
      onClick={() =>
        handleRowsChange && handleRowsChange(showMore ? Infinity : initialLimit)
      }
      bg="var(--background-color)"
      borderRadius="var(--ntb-radii-md)"
      borderColor="var(--border-color)"
      color="var(--font-color)"
      margin="0.5rem auto"
      _hover={{
        bg: 'var(--background-color__hover)',
        borderColor: 'var(--border-color__hover)',
        color: 'var(--font-color__hover)',
      }}
      rightIcon={showMore ? <ArrowDownIcon /> : <ArrowUpIcon />}
    >
      {showMore ? 'Vis mer' : 'Vis mindre'}
    </Button>
  );
};

export default ShowMoreLess;
