import styled from 'styled-components';

export const Tournaments = styled.div`
  background: var(--background-color);
`;

export const Tournament = styled.div`
  background: var(--background-color);
`;

export const NoHit = styled.div`
  padding: var(--ntb-space-6);
  text-align: center;
`;
