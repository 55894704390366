import { DEFAULT_SPORT_ID_BY_SPORT_GROUP } from '@ntb-sport/constants';
import { NifsMatchType, SportGroup } from '@ntb-sport/types';

import { Fetch, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchNifsMatches {
  apiBaseUrl: string;
  sportGroup: SportGroup;
  date: string;
}

export const fetchNifsMatches = async ({
  apiBaseUrl,
  sportGroup,
  date,
}: FetchNifsMatches): Promise<NifsMatchType[]> => {
  const sportQuery =
    sportGroup === SportGroup.ICE_HOCKEY || sportGroup === SportGroup.HANDBALL
      ? `&sportId=${
          DEFAULT_SPORT_ID_BY_SPORT_GROUP[
            sportGroup as keyof typeof DEFAULT_SPORT_ID_BY_SPORT_GROUP
          ]
        }`
      : '';

  const url =
    sportGroup === SportGroup.FOOTBALL
      ? `${apiBaseUrl}/matches/?date=${date}&inCustomerStages=1`
      : `${apiBaseUrl}/nifMatches/?date=${date}&inCustomerStages=1${sportQuery}`;

  const json = await Fetch({
    url,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });

  return json;
};
