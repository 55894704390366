import { Fetch } from '../../fetch';

export interface FetchWhitelistEventsProps {
  apiBaseUrl: string;
  token: string;
  customerUUID: string;
}

export const fetchWhitelistEvents = async ({
  apiBaseUrl,
  token,
  customerUUID,
}: FetchWhitelistEventsProps) => {
  const json = await Fetch({
    url: `${apiBaseUrl}/whitelistEvents`,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });

  return json?.data;
};
