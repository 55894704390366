import Icon from '../Icon/Icon';

export const PenaltyMissHandballIcon = ({
  fillColor1 = '#000',
  fillColor2 = '##e51c30',
  ...restProps
}: {
  fillColor1?: string;
  fillColor2?: string;
}) => (
  <Icon {...restProps} className="penalty-miss-handball">
    <path
      style={{ fill: fillColor1 }}
      d="M3.92,12.63l1.6-1.6L6.48,12c0.11-0.13,0.23-0.25,0.35-0.37l-0.96-0.96l1.6-1.6l1.3,1.3
c0.17-0.07,0.35-0.13,0.52-0.19L7.83,8.71l1.61-1.6l1.41,1.41l0.35-0.36L9.79,6.75l1.54-1.53l-0.35-0.35L9.44,6.4L7.83,4.8
l1.61-1.61l0.14,0.15C9.53,3.2,9.5,3.04,9.5,2.88c0-0.32,0.14-0.63,0.38-0.86L9.9,2H4.11C3.28,2,2.51,2.29,1.9,2.78
C1.77,2.87,1.64,2.99,1.53,3.11C0.96,3.7,0.6,4.49,0.56,5.36C0.55,5.42,0.55,5.49,0.55,5.55v8.95h1.5l1.51-1.52l1.52,1.52h0.01
c0.05-0.18,0.11-0.35,0.18-0.52L3.92,12.63z M5.52,10.32l-1.6-1.61l1.6-1.6l1.61,1.6L5.52,10.32z M3.56,8.36l-1.6-1.61l1.61-1.6
l1.6,1.6L3.56,8.36z M9.09,6.76l-1.61,1.6l-1.6-1.6l1.6-1.61L9.09,6.76z M8.92,3L7.48,4.44L6.03,3H8.92z M7.13,4.8L5.52,6.4
l-1.6-1.6l1.6-1.61L7.13,4.8z M4.11,3h0.9L3.56,4.44L2.61,3.49C3.03,3.18,3.55,3,4.11,3z M1.55,5.55c0-0.67,0.26-1.28,0.69-1.73
l0.97,0.97L1.61,6.4L1.55,6.35V5.55z M1.55,7.16l0.06-0.05l1.6,1.6l-1.6,1.61l-0.06-0.06C1.55,10.26,1.55,7.16,1.55,7.16z
M1.55,14.29v-3.21l0.06-0.05l1.6,1.6L1.55,14.29z M1.96,10.67l1.6-1.6l1.61,1.6l-1.6,1.61L1.96,10.67z"
    />
    <path
      style={{ fill: fillColor1 }}
      d="M21.15,5.55v0.82l-0.69-0.69l-0.35,0.35l0.72,0.72l-0.59,0.59L20.6,7.7l0.56-0.56v1.11l1,1v-3.7
c0-0.04,0-0.07-0.01-0.11c-0.01-0.41-0.09-0.8-0.24-1.17l-0.8,0.78C21.13,5.22,21.15,5.39,21.15,5.55z"
    />
    <path
      style={{ fill: fillColor1 }}
      d="M16.84,10.74l-0.96,0.96c0.12,0.12,0.24,0.24,0.35,0.37l0.97-0.97L16.84,10.74z"
    />
    <path
      style={{ fill: fillColor1 }}
      d="M21.15,14.19l-1.05-1.05c-0.1,0.03-0.2,0.05-0.3,0.05c-0.15,0-0.3-0.03-0.44-0.09l1.38,1.39h1.41V11.5l-1,0.89
V14.19z"
    />
    <polygon points="16.4,3 17.4,2 14.8,2 15.8,3 " />
    <path
      style={{ fill: fillColor1 }}
      d="M18.8,12.7l-1.36,1.36c0.06,0.15,0.11,0.29,0.15,0.45h0.11l1.49-1.49c-0.09-0.05-0.18-0.09-0.25-0.17L18.8,12.7
z"
    />
    <path
      style={{ fill: fillColor1 }}
      d="M14.35,11.74l-0.29,0.29c0.3,0.2,0.47,0.38,0.49,0.4c0.35,0.3,0.35,0.69,0.3,0.94c-0.3-0.1-0.84-0.15-1.38,0
c-0.09-0.14-0.23-0.28-0.39-0.42c-0.15,0.11-0.31,0.17-0.48,0.2c0.23,0.19,0.45,0.39,0.57,0.56c-0.09,0.23-0.3,0.86-0.49,1.34
c-0.02,0.04-0.04,0.09-0.05,0.14c-0.64-0.15-1.53-0.1-1.92-0.1c-0.02-0.04-0.03-0.08-0.05-0.12c-0.24-0.61-0.5-1.23-0.54-1.46
c0.2-0.2,0.87-0.63,1.32-0.78l-0.31-0.3c-0.51,0.21-1.11,0.59-1.4,0.88c-0.25-0.25-0.89-0.35-1.58-0.15c-0.05-0.2,0-0.49,0-0.54
c0.39-0.99,1.78-1.09,1.92-1.09h0.05c0.02,0,0.03,0,0.05,0l-0.29-0.27c-0.06-0.05-0.09-0.12-0.13-0.18
c-2.27,0.67-3.92,2.74-3.92,5.24c0,3.06,2.52,5.52,5.52,5.52c3.11,0,5.52-2.52,5.52-5.52C16.87,14.42,15.87,12.72,14.35,11.74z
M7.4,13.08c0.1-0.1,0.2-0.15,0.25-0.2c0,0.15,0,0.35,0.05,0.49c-0.22,0.22-0.73,0.69-0.99,1.45C6.68,14.92,6.64,15,6.62,15.1
c-0.1-0.05-0.2-0.05-0.25-0.1c0.01-0.05,0.04-0.13,0.05-0.19c0.13-0.46,0.53-1.14,0.83-1.53C7.31,13.23,7.36,13.18,7.4,13.08z
M6.22,15.89c0-0.05,0-0.15,0.05-0.2c0.05-0.1,0.05-0.15,0.1-0.2c0.05,0.05,0.15,0.05,0.25,0.05c0.05,0.39,0.15,1.18,0.69,1.78
c-0.2,0.64-0.15,1.23-0.05,1.58c-0.1,0-0.25,0.05-0.35,0.05c-0.15-0.1-0.39-0.39-0.54-1.38C6.22,16.83,6.17,16.14,6.22,15.89z
M7.45,19.34c0.59,0.74,1.38,0.99,1.73,1.04c0.05,0.2,0.2,0.54,0.44,0.84c-0.25-0.1-0.44-0.2-0.69-0.35
c-0.2-0.1-0.39-0.25-0.64-0.39c-0.64-0.35-0.99-0.84-1.08-1.09C7.31,19.39,7.36,19.34,7.45,19.34z M7.65,17.52
c0.25,0,0.79,0.05,1.18,0.05c0.05,0,0.15,0,0.2,0c0.25,0.49,0.79,1.28,1.04,1.63c-0.35,0.39-0.69,0.69-0.84,0.74
c-0.2-0.05-1.04-0.25-1.58-0.99C7.6,18.8,7.45,18.11,7.65,17.52z M9.77,20.62c-0.1-0.15-0.15-0.3-0.2-0.39
c0.3-0.2,0.69-0.59,0.89-0.84c0.35,0.1,0.69,0.1,0.99,0.1c0.49,0,0.94-0.05,1.13-0.1c0.35,0.54,0.59,0.79,0.74,0.94
c-0.35,0.69-0.84,0.79-0.89,0.79c-0.74,0.25-1.97,0.05-2.17,0C10.07,21.07,9.87,20.87,9.77,20.62z M13.47,21.02
c0.15-0.15,0.25-0.3,0.35-0.49c0.15,0,0.35-0.05,0.59-0.1c0.02,0,0.06-0.01,0.1-0.03c-0.29,0.2-0.52,0.34-0.74,0.47
C13.67,20.92,13.57,20.97,13.47,21.02z M13.72,20.08c-0.1-0.1-0.44-0.49-0.69-0.84c0.49-0.44,0.84-1.23,0.99-1.58
c0.59,0.05,1.04,0,1.23,0c0.25,0.64-0.05,1.38-0.05,1.43C14.7,20.08,13.87,20.08,13.72,20.08z M15.69,19.1
c0.1-0.35,0.25-0.99,0-1.63c0.25-0.25,0.69-0.84,0.69-1.68c0.05,0,0.05,0,0.1,0C16.82,17.12,15.99,18.7,15.69,19.1z M16.38,15.25
h-0.05c0-0.01-0.01-0.03-0.01-0.04c-0.1-0.45-0.35-1.21-0.98-1.64c0.05-0.15,0.05-0.3,0.05-0.44c0.63,0.77,0.87,1.63,0.98,2.08
C16.37,15.22,16.38,15.24,16.38,15.25z M13.62,13.82c0.69-0.2,1.28,0,1.38,0.05c0.44,0.3,0.69,0.92,0.81,1.32
c0.05,0.18,0.08,0.32,0.08,0.36c0.1,0.94-0.39,1.43-0.59,1.58c-0.15,0-0.64,0.05-1.23,0c-0.15-0.59-0.79-1.38-1.04-1.73
c0.04-0.1,0.09-0.21,0.13-0.31C13.36,14.6,13.54,14.06,13.62,13.82z M12.53,15.64c0.39,0.49,0.99,1.23,1.04,1.68
c-0.15,0.39-0.59,1.28-1.04,1.63c-0.25,0.05-1.23,0.25-2.07,0c-0.35-0.44-0.84-1.23-0.94-1.63c0.35-0.64,0.89-1.53,1.04-1.78
C10.86,15.55,11.94,15.5,12.53,15.64z M8.88,13.52c0.35,0,0.59,0.1,0.74,0.15c0.04,0.17,0.2,0.55,0.47,1.29
c0.04,0.11,0.08,0.21,0.12,0.34c-0.3,0.39-0.84,1.43-1.09,1.82c-0.59,0-1.28-0.05-1.53-0.05C7.05,16.53,7,15.54,7,15.39
c0.04-0.21,0.1-0.39,0.17-0.56c0.3-0.71,0.82-1.08,0.86-1.12C8.39,13.57,8.64,13.52,8.88,13.52z"
    />
    <polygon
      style={{ fill: fillColor2 }}
      points="17.8,6.6 21.6,2.9 19.8,1.3 16.1,5 12.4,1.3 10.7,2.9 14.4,6.6 10.7,10.4 12.4,12 16.1,8.3 19.8,12 
21.6,10.4 "
    />
  </Icon>
);
