import { Icon } from '../../chakra';

export const NrkTvIcon = (props: any) => (
  <Icon viewBox="0 0 1000 214.60693" {...props}>
    <path
      fill="currentcolor"
      d="M 666.60896,4.098944 V 28.678858 H 731.9562 V 214.60694 H 759.082 V 28.678858 h 65.40804 V 4.098944 m 148.1937,0 L 915.99809,183.8851 860.4657,4.098944 H 832.3841 L 899.82816,214.60694 H 931.7644 L 1000,4.098944 M 545.35283,119.0962 c -4.41553,-7.48055 -4.6686,-12.07995 -0.41014,-19.301757 L 603.4471,4.032826 h -81.16385 c 0,0 -48.76288,79.678271 -55.42981,90.749381 -6.60295,11.056573 -6.31789,17.941133 0.27051,29.302973 6.66694,11.34731 55.1593,90.45574 55.1593,90.45574 h 81.16385 c 0,0 -57.45433,-94.26434 -58.09427,-95.44472 m -166.5411,95.44472 h 73.81334 V 4.032826 H 378.81173 M 332.26165,0 c -23.09232,0 -41.78193,18.865011 -41.78193,42.156128 0,23.2765 18.68961,42.199984 41.78193,42.199984 23.13033,0 41.89887,-18.923484 41.89887,-42.199984 C 374.16052,18.865011 355.39198,0 332.26165,0 M 211.62261,214.54092 h 73.76971 V 4.032826 H 211.62261 M 0,214.54092 H 73.63299 V 4.032826 H 0 M 166.80537,37.85379 C 163.02686,18.563659 146.153,4.032826 125.92821,4.032826 H 78.50337 L 126.158,214.54092 h 80.16322 z"
    />
  </Icon>
);

export default NrkTvIcon;
