import { Fetch } from '../../fetch';

export interface FetchSeasonStandingsProps {
  apiBaseUrl: string;
  url: string;
  token: string;
  customerUUID: string;
}

export const fetchSeasonStandings = async ({
  apiBaseUrl,
  token,
  customerUUID,
  url,
}: FetchSeasonStandingsProps) => {
  const json = await Fetch({
    url: `${apiBaseUrl}${url}`,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });

  return json;
};
