import { QUERY_KEYS } from '@ntb-sport/constants';
import { updateObjectInArray } from '../utils';

export function onScopeResultsPost({
  msg,
  apiClient,
}: {
  msg: any;
  apiClient: any;
}) {
  const { entityId, data, additionalData } = msg;

  const scopeResults = apiClient.getQueryData([
    QUERY_KEYS.SCOPE_RESULTS,
    { scopeUUID: additionalData.scope.uuid },
  ]);

  const state = scopeResults?.data;

  const eventParticipantsState = apiClient.getQueryData([
    QUERY_KEYS.EVENT_PARTICIPANTS,
    { eventUUID: additionalData.event.uuid },
  ]);

  if (!state || !eventParticipantsState) return; //TODO: Remove this when join scope channel is implemented

  if (state?.find((scopeResult: any) => scopeResult?.uuid === entityId)) return; //TODO: Maybe this fixes duplicate post bug?

  const participant = eventParticipantsState?.find(
    (participant: any) =>
      participant?.uuid === additionalData.eventParticipant.uuid,
  );

  const newState = [
    ...state,
    {
      uuid: entityId,
      isHighlighted: true,
      participant,
      ...data,
    },
  ];

  apiClient.setQueryData(
    [QUERY_KEYS.SCOPE_RESULTS, { scopeUUID: additionalData.scope.uuid }],
    { ...scopeResults, data: newState },
  );

  if (data?.shotSeries) {
    const allShotSeriesState = apiClient.getQueryData([
      QUERY_KEYS.ALL_SHOT_SERIES,
      { eventUUID: additionalData.event.uuid },
    ]);

    const shotSeriesEntityIndex = allShotSeriesState?.findIndex(
      (shotSeries: any) =>
        shotSeries?.eventParticipantUUID ===
          additionalData.eventParticipant.uuid &&
        shotSeries?.scopeUUID === additionalData?.scope.uuid,
    );

    const newShotSeriesState =
      shotSeriesEntityIndex === -1
        ? [
            ...allShotSeriesState,
            {
              eventParticipantUUID: additionalData.eventParticipant.uuid,
              scopeUUID: additionalData?.scope.uuid,
              ...data.shotSeries,
            },
          ]
        : updateObjectInArray(allShotSeriesState, {
            index: shotSeriesEntityIndex,
            item: {
              ...allShotSeriesState[shotSeriesEntityIndex],
              ...data.shotSeries,
            },
          });

    apiClient.setQueryData(
      [QUERY_KEYS.ALL_SHOT_SERIES, { eventUUID: additionalData.event.uuid }],
      newShotSeriesState,
    );
  }
}
