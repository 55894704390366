import { NeoScopeResultType } from '@ntb-sport/types';
import { useTranslation } from 'react-i18next';
import { COMPONENT_IDS } from '@ntb-sport/constants';
import { Results } from './Results/Results';
import { Header } from './Header/Header';
import { Loader } from './Loader/Loader';
import { useBem } from '@ntb-sport/hooks';
import './scope-results.css';

interface ScopeResultsProps {
  scopeResults: NeoScopeResultType[];
  isLoading?: boolean;
  displayTop3?: boolean;
  highlightedCountryUUID?: string;
  translations?: any;
  labels?: string[];
  disciplineUUID?: string;
  allShotSeries?: any;
  sportUUID: string;
  scopeNumber: number;
  isRelay: boolean;
  maxRank?: number;
  scopeTypeId: number;
  scopeCategoryId?: number;
  sportGroup?: string;
  tourCompetitionUuid?: string;
  competitionId?: string;
  isMedalEvent?: boolean;
}

export const ScopeResults = ({
  scopeResults,
  isLoading,
  highlightedCountryUUID = 'country-norway',
  labels,
  disciplineUUID,
  allShotSeries,
  scopeNumber,
  sportUUID,
  isRelay,
  maxRank,
  scopeTypeId,
  scopeCategoryId,
  sportGroup,
  tourCompetitionUuid,
  competitionId,
  isMedalEvent,
}: ScopeResultsProps) => {
  const { t } = useTranslation();
  const bem = useBem(COMPONENT_IDS.SCOPE_RESULTS);

  return (
    <div className={bem()} data-competition-id={competitionId}>
      {isLoading ? (
        <Loader />
      ) : (!scopeResults || !scopeResults?.length) && !isLoading ? (
        <span className={bem('no-results')}>{t('noResults')}</span>
      ) : (
        <>
          <Header sportGroup={sportGroup} scopeCategoryId={scopeCategoryId} />

          <Results
            scopeResults={scopeResults}
            sportUUID={sportUUID}
            highlightedCountryUUID={highlightedCountryUUID}
            allShotSeries={allShotSeries}
            scopeNumber={scopeNumber}
            isRelay={isRelay}
            labels={labels}
            disciplineUUID={disciplineUUID}
            maxRank={maxRank}
            scopeTypeId={scopeTypeId}
            scopeCategoryId={scopeCategoryId}
            limit={scopeResults.length}
            tourCompetitionUuid={tourCompetitionUuid}
            isMedalEvent={isMedalEvent}
          />
        </>
      )}
    </div>
  );
};
