import { format, isSameDay, isValid } from 'date-fns';
import { nb, fi, sv } from 'date-fns/locale';
import { LabelProperties, UUIDs } from 'neo-common-enums';
import {
  AlpineSportIcon,
  AthleticsIcon,
  BiathlonSportIcon,
  CrossCountrySkiingSportIcon,
  CyclingSportIcon,
  NordicCombinedSportIcon,
  SkiJumpingSportIcon,
  SpeedSkatingSportIcon,
} from '../../../icons';

const LOCALES = {
  nb,
  sv,
  fi,
};

export function getDate(
  fromDate: any,
  toDate: any,
  timestamp: any,
  locale: string,
) {
  if (fromDate && toDate && !isSameDay(fromDate, toDate)) {
    return `${
      isValid(fromDate) &&
      format(fromDate, 'd', {
        locale: LOCALES[locale as keyof typeof LOCALES],
      })
    }.-${format(toDate, 'd. MMMM yyyy', {
      locale: LOCALES[locale as keyof typeof LOCALES],
    })}`;
  } else if (fromDate && toDate && isSameDay(fromDate, toDate)) {
    return `${
      isValid(fromDate) &&
      format(fromDate, 'd. MMMM yyyy', {
        locale: LOCALES[locale as keyof typeof LOCALES],
      })
    }`;
  } else {
    return `${
      isValid(timestamp) &&
      format(timestamp, 'd. MMMM yyyy', {
        locale: LOCALES[locale as keyof typeof LOCALES],
      })
    }`;
  }
}

export function getLocation(locations: any, countries: any) {
  const location = locations?.map((l: { name: string }) => l.name).join(' - ');
  const country = countries?.map((c: { name: string }) => c.name).join(' - ');

  if (location && country) return `${location}, ${country}`;
  else if (!location && country) return country;
  else if (location && !country) return location;
  else return null;
}

export function getSportIcon(sport: any) {
  switch (sport?.uuid) {
    case UUIDs.Sport.ALPINE:
      return <AlpineSportIcon />;
    case UUIDs.Sport.BIATHLON:
      return <BiathlonSportIcon />;
    case UUIDs.Sport.CROSS_COUNTRY_SKIING:
      return <CrossCountrySkiingSportIcon />;
    case UUIDs.Sport.NORDIC_COMBINED:
      return <NordicCombinedSportIcon />;
    case UUIDs.Sport.SKI_JUMPING:
      return <SkiJumpingSportIcon />;
    case UUIDs.Sport.SPEED_SKATING:
      return <SpeedSkatingSportIcon />;
    case UUIDs.Sport.CYCLING:
      return <CyclingSportIcon />;
    case UUIDs.Sport.ATHLETICS:
      return <AthleticsIcon />;
    default:
      return null;
  }
}

export function getBroadcasts(broadcasts: any) {
  return broadcasts
    ?.filter(
      (broadcast: any) =>
        broadcast?.typeId === LabelProperties.BroadcastType.TV,
    )
    ?.map((broadcast: any) => broadcast?.broadcastingChannel?.name)
    .join(', ');
}
