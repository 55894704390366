import { format, isValid, min } from 'date-fns';
import { LabelProperties, UUIDs } from 'neo-common-enums';

const getEventStatusFromAllEventsInRoundType = (
  events: any,
  roundTypeUuid: string,
) => {
  const eventStatusIds = events
    ?.filter((event: any) => event?.roundType?.uuid === roundTypeUuid)
    ?.reduce((acc: number[], event: any) => {
      acc.push(event?.eventStatusId);

      return acc;
    }, []);

  if (
    eventStatusIds.every(
      (val: number) => val === LabelProperties.EventStatus.NOT_STARTED,
    )
  ) {
    return LabelProperties.EventStatus.NOT_STARTED;
  } else if (
    eventStatusIds.every(
      (val: number) => val === LabelProperties.EventStatus.FINISHED,
    )
  ) {
    return LabelProperties.EventStatus.FINISHED;
  } else if (eventStatusIds.includes(LabelProperties.EventStatus.POSTPONED)) {
    return LabelProperties.EventStatus.POSTPONED;
  } else if (eventStatusIds.includes(LabelProperties.EventStatus.CANCELLED)) {
    return LabelProperties.EventStatus.CANCELLED;
  } else if (
    eventStatusIds.includes(LabelProperties.EventStatus.START_DELAYED)
  ) {
    return LabelProperties.EventStatus.START_DELAYED;
  } else if (eventStatusIds.includes(LabelProperties.EventStatus.INTERRUPTED)) {
    return LabelProperties.EventStatus.INTERRUPTED;
  } else if (
    eventStatusIds.includes(LabelProperties.EventStatus.ONGOING) ||
    (eventStatusIds.includes(LabelProperties.EventStatus.NOT_STARTED) &&
      eventStatusIds.includes(LabelProperties.EventStatus.FINISHED))
  ) {
    return LabelProperties.EventStatus.ONGOING;
  } else return null;
};

const translatedName = {
  [UUIDs.RoundType.ATHLETICS_FIRST_ROUND]: 'Forsøk',
  [UUIDs.RoundType.PRELIMINARY]: 'Innledende',
  [UUIDs.RoundType.QUALIFICATION]: 'Kvalifisering',
  [UUIDs.RoundType.ROUND_OF_16]: '8-delsfinale',
  [UUIDs.RoundType.QUARTER_FINAL]: 'Kvartfinale',
  [UUIDs.RoundType.PLACEMENT_SEMI_FINAL]: '9-12. plass',
  [UUIDs.RoundType.SEMI_FINAL]: 'Semifinale',
  [UUIDs.RoundType.SEVENTH_PLACE_DECIDER]: '7./8. plass',
  [UUIDs.RoundType.FIFTH_PLACE_DECIDER]: '5./6. plass',
  [UUIDs.RoundType.BRONZE_FINAL]: 'Bronsefinale',
  [UUIDs.RoundType.FINAL]: 'Finale',
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_1000_METRES]: '1000 m',
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_100_METERS]: '100 m',
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_100_METRES_HURDLES]: '100 m hekk',
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_110_METRES_HURDLES]: '110 m hekk',
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_1500_METRES]: '1500 m',
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_200_METRES]: '200 m',
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_400_METRES]: '400 m',
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_60_METRES_HURDLES]: '60 m hekk',
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_60_METRES]: '60 m',
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_800_METRES]: '800 m',
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_DISCUS_THROW]: 'Diskos',
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_HIGH_JUMP]: 'Høydehopp',
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_JAVELIN_THROW]: 'Spyd',
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_LONG_JUMP]: 'Lengdehopp',
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_POLE_VAULT]: 'Stavsprang',
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_SHOT_PUT]: 'Kulestøt',
  ['round-type-repechage']: 'Oppsamling',
};

interface GetChildEventsGroupedByRoundType {
  childEvents: any;
  date: string;
  eventParticipantsByCountryAndDate: any;
  sport: any;
}

export const getChildEventsGroupedByRoundType = ({
  childEvents,
  date,
  eventParticipantsByCountryAndDate,
  sport,
}: GetChildEventsGroupedByRoundType) => {
  return childEvents
    ?.filter(
      (event: any) =>
        isValid(new Date(event?.timestamp)) &&
        format(new Date(event?.timestamp), 'yyyy-MM-dd') === date,
    )
    ?.reduce((acc: any, event: any) => {
      const roundTypeUUID = event?.roundType?.uuid;

      const allDates = childEvents
        ?.filter((event: any) => event?.roundType?.uuid === roundTypeUUID)
        ?.reduce((events: any, event: any) => {
          events.push(new Date(event.timestamp));
          return events;
        }, []);
      const firstDate = min(allDates);

      const hasAthletesFromCountry = childEvents
        ?.filter((event: any) => event?.roundType?.uuid === roundTypeUUID)
        ?.reduce((events: any, event: any) => {
          if (
            eventParticipantsByCountryAndDate?.find(
              (e: any) => e?.uuid === event?.uuid,
            )?.eventParticipants?.length
          ) {
            events?.push(event);
          }
          return events;
        }, []);

      if (!acc[roundTypeUUID]) {
        acc[roundTypeUUID] = {
          name:
            sport?.uuid === UUIDs.Sport.NORDIC_COMBINED
              ? event?.name
              : translatedName[roundTypeUUID as keyof typeof translatedName] ||
                event?.name,
          roundType: {
            uuid: roundTypeUUID,
          },
          hasAthletesFromCountry: Boolean(hasAthletesFromCountry?.length),
          uuid: event?.uuid,
          timestamp: firstDate,
          broadcasts: event?.broadcasts,
          eventStatusId: getEventStatusFromAllEventsInRoundType(
            childEvents,
            roundTypeUUID,
          ),
          isMedalEvent: event?.isMedalEvent,
        };
      }
      return acc;
    }, {});
};
