import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '@ntb-sport/constants';
import { fetchSportCompetitions, FetchSportCompetitionsType } from './fetch';

interface UseQuerySportCompetitions {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: FetchSportCompetitionsType;
  options?: Record<string, unknown>;
}

export const useQuerySportCompetitions = ({
  queryKey = QUERY_KEYS.SPORT_COMPETITIONS,
  queryKeyParams,
  params,
  options,
}: UseQuerySportCompetitions) => {
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchSportCompetitions(params),
    enabled:
      Boolean(params.customerUUID) &&
      Boolean(params.token) &&
      Boolean(params.sportUUID),
    ...options,
  });

  return { data, isLoading, error };
};
