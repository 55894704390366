import React from 'react';

import { IconContext } from 'react-icons';
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from 'react-icons/md';

export const ExpandButton: React.FC<{ isOpen: boolean }> = ({ isOpen }) => (
  <IconContext.Provider
    value={{
      size: '2rem',
      color: 'var(--colors-open-close-icon-color)',
      style: {
        margin: 'auto',
      },
    }}
  >
    {isOpen ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}
  </IconContext.Provider>
);
