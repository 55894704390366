import { Icon } from '../../chakra';

export const RefereeIcon = (props: any) => (
  <Icon {...props}>
    <g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M22.2,6.3l-4.5-3.6c-0.1-0.1-0.2-0.1-0.3-0.2L15,2l-0.8-0.2c-0.3,0-0.5,0.1-0.6,0.3s-0.1,0.4-0.3,0.6
				c-0.3,0.4-0.5,0.7-0.9,0.9C12.1,3.9,11.7,4,11.2,4c-0.4,0-0.8-0.1-1.3-0.3C9.6,3.4,9.3,3.1,9,2.7C8.9,2.5,8.9,2.3,8.8,2.1
				C8.7,1.9,8.4,1.7,8.2,1.8L7.7,2L5.2,2.5C5.1,2.5,5,2.5,4.9,2.6L0.4,6.3C0.2,6.4,0.2,6.7,0.3,6.9l2.2,3.6c0.1,0.2,0.3,0.3,0.4,0.3
				h1.8v9.7c0,0.3,0.3,0.5,0.5,0.5h12.3c0.3,0,0.5-0.3,0.5-0.5v-9.8h1.8c0.2,0,0.3-0.1,0.4-0.3l2.2-3.6C22.5,6.7,22.5,6.4,22.2,6.3z
				 M4.7,9.8H3.2l-1.9-3l3.4-2.7V9.8z M10.1,20H7.7V3l0.5-0.2C8.3,3,8.3,3.1,8.3,3.2c0.4,0.8,1,1.3,1.8,1.5
				C10.1,4.7,10.1,20,10.1,20z M15,20h-2.4V4.8c0.8-0.3,1.3-0.8,1.8-1.5c0.1-0.2,0.2-0.3,0.2-0.4L15,3C15,3,15,20,15,20z M19.5,9.8
				h-1.5V4.1l3.4,2.7L19.5,9.8z"
          />
        </g>
      </g>
    </g>
  </Icon>
);

export default RefereeIcon;
