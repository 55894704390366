import { isSameDay, min } from 'date-fns';

interface GetFirstChildEventTimestamp {
  childEvents: any;
  date: string;
}

export const getFirstChildEventTimestamp = ({
  childEvents,
  date,
}: GetFirstChildEventTimestamp) => {
  const allDates = childEvents
    ?.filter((event: any) =>
      isSameDay(new Date(event?.timestamp), new Date(date)),
    )
    ?.reduce((events: any, event: any) => {
      events.push(new Date(event.timestamp));
      return events;
    }, []);
  const firstChildEventTimestamp = allDates && min(allDates).toString();

  return firstChildEventTimestamp;
};
