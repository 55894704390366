import * as S from './SportsMenu.styled';
import {
  ENGLISH_SPORT_GROUP_TO_NORWEGIAN_DISPLAY_NAME,
  SPORT_GROUP,
} from '@ntb-sport/constants';
import {
  FootballSportIcon,
  HandballSportIcon,
  IceHockeySportIcon,
  WintersportsIcon,
  AthleticsIcon,
  CyclingSportIcon,
} from '../../icons';

import { ChevronDownIcon } from '@chakra-ui/icons';
import { SporteditCustomerSportsType } from '@ntb-sport/types';
import { COMPONENT_IDS } from '@ntb-sport/constants';
import {
  Button,
  MenuChakra as Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '../../chakra';

interface SportMenuProps {
  sports: SporteditCustomerSportsType[];
  sport: string;
  onChangeSport: (sport: string) => void;
}

const SPORT_ICONS = {
  [SPORT_GROUP.FOOTBALL]: <FootballSportIcon size="small" />,
  [SPORT_GROUP.ICE_HOCKEY]: <IceHockeySportIcon size="small" />,
  [SPORT_GROUP.HANDBALL]: <HandballSportIcon size="small" />,
  [SPORT_GROUP.WINTERSPORTS]: <WintersportsIcon size="small" />,
  [SPORT_GROUP.ATHLETICS]: <AthleticsIcon size="small" />,
  [SPORT_GROUP.CYCLING]: <CyclingSportIcon size="small" />,
};

export const SportsMenu = ({
  sports,
  sport,
  onChangeSport,
}: SportMenuProps) => {
  if (sports?.length <= 1) return null;

  return (
    <nav data-component-id={COMPONENT_IDS.SPORTS_MENU}>
      <Menu>
        <MenuButton
          aria-label="Velg sport"
          as={Button}
          p={2}
          leftIcon={SPORT_ICONS[sport as keyof typeof SPORT_ICONS]}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          textTransform="capitalize"
          bg="var(--background-color)"
          color="var(--font-color)"
          _hover={{
            bg: 'var(--background-color__hover)',
            color: 'var(--font-color__hover)',
          }}
          _active={{
            bg: 'var(--background-color__active)',
            color: 'var(--font-color__active)',
          }}
        >
          {ENGLISH_SPORT_GROUP_TO_NORWEGIAN_DISPLAY_NAME[
            sport as keyof typeof ENGLISH_SPORT_GROUP_TO_NORWEGIAN_DISPLAY_NAME
          ] || 'Velg sport'}
        </MenuButton>
        <MenuList zIndex={9999}>
          {sports
            ?.sort(
              (
                a: SporteditCustomerSportsType,
                b: SporteditCustomerSportsType,
              ) => a.sorting - b.sorting,
            )
            ?.map((sport: SporteditCustomerSportsType) => {
              const sportName = sport?.name?.toLowerCase()?.replace(/\s/g, '');
              const norwegianSportName =
                ENGLISH_SPORT_GROUP_TO_NORWEGIAN_DISPLAY_NAME[
                  sportName as keyof typeof ENGLISH_SPORT_GROUP_TO_NORWEGIAN_DISPLAY_NAME
                ];

              return (
                <MenuItem
                  aria-label={norwegianSportName}
                  key={sportName}
                  onClick={() => onChangeSport(sportName)}
                >
                  <S.MenuItem>
                    {SPORT_ICONS[sportName]}
                    {norwegianSportName}
                  </S.MenuItem>
                </MenuItem>
              );
            })}
        </MenuList>
      </Menu>
    </nav>
  );
};

export default SportsMenu;
