import { API_VERSION, Fetch } from '../../fetch';

export interface FetchSeasonEvents {
  apiBaseUrl: string;
  seasonUUID: string;
  token: string;
  customerUUID: string;
  version?: string;
}

export const fetchSeasonEvents = async ({
  apiBaseUrl,
  seasonUUID,
  token,
  customerUUID,
  version = API_VERSION.V3,
}: FetchSeasonEvents) => {
  const json = await Fetch({
    url: `${apiBaseUrl}/${version}/seasons/${seasonUUID}/events`,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });

  return json?.data;
};
