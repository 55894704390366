import {
  AssistIcon as Assist,
  CardIcon as Card,
  GoalIcon as Goal,
  SecondCardIcon as SecondCard,
  SubstituteIcon as Substitute,
} from '../../../../../icons';
import styled from 'styled-components';
import { MEDIA_QUERY } from '@ntb-sport/constants';

interface StyledProps {
  $position?: any;
  $justify?: any;
  $isClickable?: boolean;
  $isOnPitch?: boolean;
  size?: any;
  fillColor1?: any;
  fillColor2?: any;
  to?: any;
}

export const BenchContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  @container match-lineup ${MEDIA_QUERY.MOBILE_L} {
    width: 400px;
    margin: 0 auto;
  }
`;

export const BenchTitle = styled.h5`
  margin: 0 0 var(--ntb-space-4) 0;
  color: var(--base-colors-blackAlpha-900);
  font-size: var(--ntb-fontSizes-lg);
  font-weight: var(--ntb-fontWeights-medium);
`;

export const Bench = styled.div`
  padding: 0 var(--ntb-space-4);
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: var(--ntb-space-2);
`;

export const Position = styled.div<StyledProps>`
  width: 100%;
  background: none;
  border: none;
  text-decoration: none;
  display: grid;
  grid-template-rows: 55px 18px 22px;
  align-items: flex-start;
  grid-area: ${(props) => props?.$position};
  margin-left: ${(props) => props?.$justify};
  justify-items: center;
  padding: 0;

  > svg {
    margin: 0 auto;
  }

  cursor: ${(props) => props?.$isClickable && 'pointer'};
`;

export const BenchPerson = styled.div<StyledProps>`
  background: none;
  border: none;
  cursor: ${(props) => props?.$isClickable && 'pointer'};
  display: grid;
  grid-template-rows: 55px 18px 22px;
  align-items: center;
  justify-items: center;
  text-decoration: none;
`;

export const ShirtName = styled.span<StyledProps>`
  text-align: center;
  font-size: var(--ntb-fontSizes-xs);
  color: var(--base-colors-blackAlpha-900);
  font-weight: var(--ntb-fontWeights-semibold);
`;

export const Events = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: var(--ntb-space-1);
  padding: 2px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: var(--ntb-radii-md);
`;

export const Event = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const Time = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--font-color);
  font-size: var(--ntb-fontSizes-2xs);
  font-weight: var(--ntb-fontWeights-medium);
`;

export const GoalIcon = styled(Goal)`
  color: #000;
  background: #fff;
  border-radius: 50%;
`;

export const AssistIcon = styled(Assist)`
  color: #000;
  background: #fff;
  border-radius: 50%;
`;

export const YellowCardIcon = styled(Card).withConfig({
  shouldForwardProp: (prop) => ['fillColor'].includes(prop),
})``;

export const SecondYellowCardIcon = styled(SecondCard).withConfig({
  shouldForwardProp: (prop) =>
    ['fillColor1', 'fillColor2'].includes(String(prop)),
})``;

export const RedCardIcon = styled(Card).withConfig({
  shouldForwardProp: (prop) => ['fillColor'].includes(prop),
})``;

export const SubstititeIcon = styled(Substitute).withConfig({
  shouldForwardProp: (prop) =>
    ['fillColor1', 'fillColor2'].includes(String(prop)),
})``;
