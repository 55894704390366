import { Placement, Team, TeamLogo, TeamName } from '../Table.styled';

import React from 'react';
import Table from '../Table';
import { MatchesType } from '@ntb-sport/types';
import ClubLogo from '../../ClubLogo/ClubLogo';

const TeamStandingsHandball = ({
  data,
  isLoading,
  matches,
  hasAveragePoints,
  relegationLineTeamId,
  relegationPlayoffLineTeamId,
  promotionLineTeamId,
  promotionPlayoffLineTeamId,
  teamLink,
}: {
  data: any;
  isLoading: any;
  matches?: MatchesType;
  hasAveragePoints: any;
  relegationLineTeamId: any;
  relegationPlayoffLineTeamId: any;
  promotionLineTeamId: any;
  promotionPlayoffLineTeamId: any;
  teamLink: any;
}) => {
  const tableData = React.useMemo(() => data?.teams, [data]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'place',
        meta: {
          ariaLabel: 'Plassering',
        },
        isSortable: matches?.tablet,
        isVisible: true,
        styles: {
          $textAlign: 'center',
          $collapse: 'all',
        },
        Cell: (props: any) => {
          const rowType =
            data.tableDividingLines &&
            data.tableDividingLines.find(
              (item: any) =>
                props.row.original.place >= item.fromPlace &&
                props.row.original.place <= item.toPlace,
            );
          const rowTypeId = rowType && rowType.typeId;
          return (
            <Placement $rowTypeId={rowTypeId}>
              {props.row.original.place}
            </Placement>
          );
        },
      },
      {
        Header: 'Lag',
        accessor: 'name',
        meta: {
          ariaLabel: 'Lag',
        },
        isSortable: matches?.tablet,
        isVisible: true,
        Cell: (props: any) => (
          <Team>
            {teamLink ? (
              teamLink({
                sportId: 3,
                id: props.row.original.id,
                children: (
                  <ClubLogo
                    src={props?.row?.original?.logo?.url}
                    alt=""
                    size="xs"
                    name={
                      !matches?.tablet
                        ? props.row.original.shortName ||
                          props.row.original.name
                        : props.row.original.name
                    }
                  />
                ),
              })
            ) : (
              <ClubLogo
                src={props?.row?.original?.logo?.url}
                alt=""
                name={
                  !matches?.tablet
                    ? props.row.original.shortName || props.row.original.name
                    : props.row.original.name
                }
              />
            )}
          </Team>
          // <Team>
          //   {props.row.original.logo && (
          //     <TeamLogo src={props.row.original.logo.url} alt="" />
          //   )}
          //   <TeamName>
          //     {teamLink
          //       ? teamLink({
          //           sportId: 3,
          //           id: props.row.original.id,
          //           name: !matches?.tablet
          //             ? props.row.original.shortName || props.row.original.name
          //             : props.row.original.name,
          //         })
          //       : !matches?.tablet
          //       ? props.row.original.shortName || props.row.original.name
          //       : props.row.original.name}
          //   </TeamName>
          // </Team>
        ),
        styles: {
          $justifyContent: 'left',
        },
      },

      {
        Header: 'K',
        accessor: 'played',
        meta: {
          ariaLabel: 'Kamper',
        },
        isVisible: true,
        isSortable: matches?.tablet,
        styles: {
          $textAlign: 'center',
          $divider: true,
        },
      },
      {
        Header: 'S',
        accessor: 'won',
        meta: {
          ariaLabel: 'Seier',
        },
        isSortable: matches?.tablet,
        isVisible: true,
        styles: {
          $textAlign: 'center',
          $divider: true,
        },
      },

      {
        Header: 'U',
        accessor: 'draw',
        meta: {
          ariaLabel: 'Uavgjort',
        },
        isSortable: matches?.tablet,
        isVisible: true,
        styles: {
          $textAlign: 'center',
        },
      },

      {
        Header: 'T',
        accessor: 'lost',
        meta: {
          ariaLabel: 'Tap',
        },
        isSortable: matches?.tablet,
        isVisible: true,
        styles: {
          $textAlign: 'center',
        },
      },
      {
        Header: '+',
        accessor: 'goalsScored',
        meta: {
          ariaLabel: 'Scorede mål',
        },
        isVisible: matches?.tablet,
        isSortable: matches?.tablet,
        styles: {
          $textAlign: 'center',
          $divider: true,
          $collapse: 'right',
        },
      },
      /*       {
        Header: '',
        accessor: 'goalsDivider',
        isVisible: matches?.tablet,
        isSortable: false,
        styles: {
          $textAlign: 'center',
          $collapse: 'all',
        },
        Cell: () => <span>-</span>,
      }, */
      {
        Header: '-',
        accessor: 'goalsConceded',
        meta: {
          ariaLabel: 'Innslupne mål',
        },
        isSortable: matches?.tablet,
        isVisible: matches?.tablet,
        styles: {
          $textAlign: 'center',
          $collapse: 'left',
        },
      },
      {
        Header: '+/-',
        accessor: 'goalDifference',
        meta: {
          ariaLabel: 'Målforskjell',
        },
        isSortable: matches?.tablet,
        isVisible: true,
        styles: {
          $textAlign: 'center',
          $divider: !matches?.tablet,
        },
      },
      {
        Header: 'PS',
        accessor: 'averagePoints',
        meta: {
          ariaLabel: 'Poengsnitt',
        },
        isSortable: matches?.tablet,
        isVisible: hasAveragePoints,
        styles: {
          $textAlign: 'center',
          $divider: true,
          $highlight: true,
        },
      },
      {
        Header: 'P',
        accessor: 'points',
        meta: {
          ariaLabel: 'Poeng',
        },
        isSortable: matches?.tablet,
        isVisible: !hasAveragePoints || matches?.tablet,
        styles: {
          $textAlign: 'center',
          $divider: true,
          $highlight: !hasAveragePoints,
        },
      },
    ];
  }, [matches]);

  return (
    <Table
      columns={columns}
      data={tableData}
      isLoading={isLoading}
      relegationLineTeamId={relegationLineTeamId}
      relegationPlayoffLineTeamId={relegationPlayoffLineTeamId}
      promotionLineTeamId={promotionLineTeamId}
      promotionPlayoffLineTeamId={promotionPlayoffLineTeamId}
    />
  );
};

export default TeamStandingsHandball;
