import { format, isValid } from 'date-fns';
import { LabelProperties } from 'neo-common-enums';

export const getEventStatus = ({
  eventStatusId,
  timestamp,
}: {
  eventStatusId: number;
  timestamp: string;
}) => {
  const EVENT_STATUS = {
    [LabelProperties.EventStatus.NOT_STARTED]: {
      text:
        isValid(new Date(timestamp)) && format(new Date(timestamp), 'HH:mm'),
      type: 'default',
    },

    [LabelProperties.EventStatus.FINISHED]: {
      text: 'Slutt',
      type: 'default',
    },
    [LabelProperties.EventStatus.ONGOING]: {
      text: 'Pågår',
      type: 'success',
    },
    [LabelProperties.EventStatus.ABANDONED]: {
      text: 'Avbrutt',
      type: 'error',
    },
    [LabelProperties.EventStatus.CANCELLED]: {
      text: 'Kansellert',
      type: 'error',
    },
    [LabelProperties.EventStatus.POSTPONED]: {
      text: 'Utsatt',
      type: 'error',
    },
    [LabelProperties.EventStatus.WILL_NOT_BE_HELD]: {
      text: 'Avlyst',
      type: 'error',
    },
    [LabelProperties.EventStatus.INTERRUPTED]: {
      text: 'Midlertidig avbrutt',
      type: 'error',
    },
    [LabelProperties.EventStatus.START_DELAYED]: {
      text: 'Starten er utsatt',
      type: 'error',
    },
  };
  return (
    EVENT_STATUS[eventStatusId as keyof typeof EVENT_STATUS] || {
      text: eventStatusId,
      type: 'default',
    }
  );
};
