import * as S from './Header.styled';

import { ELEMENT_IDS, STAGE_TYPE } from '@ntb-sport/constants';

import { ChevronRightIcon } from '@chakra-ui/icons';
import { Highlight } from '../../../../chakra';

interface HeaderProps {
  stage: any;
  debouncedSearchQuery: string;
  stages: any;
  onClickStandings?: any;
}
export const Header = ({
  stage,
  debouncedSearchQuery,
  stages,
  onClickStandings,
}: HeaderProps) => {
  const isLeague =
    stage.stageTypeId === STAGE_TYPE.LEAGUE ||
    stage?.stageTypeId === STAGE_TYPE.LEAGUE_WITH_PENALTIES;

  if (!stage.groupName) {
    return null;
  }

  return (
    <S.StageHeader
      data-element-id={ELEMENT_IDS.STAGE}
      aria-label={`Underkategori: ${stage.groupName}`}
    >
      {stage.groupName && (
        <S.StageName aria-hidden="true">
          <Highlight query={debouncedSearchQuery} styles={{ bg: 'orange.300' }}>
            {stage.groupName}
          </Highlight>
        </S.StageName>
      )}
      {Object.keys(stages).length > 1 && isLeague && onClickStandings && (
        <S.StageStandings
          data-element-id={ELEMENT_IDS.BUTTON}
          onClick={() =>
            onClickStandings({
              tournamentId: stage.tournament?.id,
              seasonId: stage?.yearEnd,
              stageId: stage?.id,
            })
          }
        >
          <span aria-label="Gå til tabell.">Tabell</span>
          <ChevronRightIcon />
        </S.StageStandings>
      )}
    </S.StageHeader>
  );
};
