import { SearchIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputLeftElement } from '../../chakra';

export interface SearchFieldProps {
  hasIcon?: boolean;
  placeholder?: string;
  value: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchField: React.FC<SearchFieldProps> = ({
  hasIcon,
  placeholder,
  value,
  handleChange,
}) => {
  return (
    <InputGroup>
      {hasIcon && (
        <InputLeftElement
          children={<SearchIcon color="var(--colors-search-icon)" />}
        />
      )}
      <Input
        backgroundColor="var(--colors-search-background)"
        borderColor="var(--colors-search-border)"
        borderRadius="0"
        color="var(--colors-search-text)"
        fontSize="var(--search-font-size)"
        fontFamily="var(--ntb-fonts-body)"
        marginBottom="var(--ntb-space-3)"
        onChange={handleChange}
        placeholder={placeholder || ''}
        value={value}
        _placeholder={{
          textColor: 'var(--colors-search-placeholder-text)',
          fontSize: 'var(--search-placeholder-font-size)',
        }}
        _focus={{
          borderColor: 'var(--colors-search-border__focus)',
          boxShadow: '0 0 0 1px var(--colors-search-border__focus)',
        }}
        _hover={{
          borderColor: 'var(--colors-search-border__hover)',
        }}
      />
    </InputGroup>
  );
};

export default SearchField;
