import { NrkRadioIcon } from '../../icons';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--ntb-space-2);
  background: var(--background-color);
  color: var(--font-color);
`;

export const RadioButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);

  cursor: pointer;
  font-size: var(--ntb-fontSizes-sm);

  border-radius: var(--ntb-radii-md);

  transition: background 300ms;
  transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
`;

export const RadioButtonIcon = styled.div`
  display: flex;
  align-items: center;
  padding: var(--ntb-space-0-5) var(--ntb-space-3);
  background: var(--background-color);
  color: var(--font-color);
  border-radius: var(--ntb-radii-md);
`;

export const RadioLogo = styled(NrkRadioIcon)`
  width: 70px;
  fill: var(--font-color);
`;
