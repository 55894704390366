import { UUIDs } from 'neo-common-enums';
import { MainEvents } from '../MainEvents/MainEvents';
import { BiBroadcast } from 'react-icons/bi';
import { CompetitionType } from '../types';
import { NeoBroadcastType } from '@ntb-sport/types';
import { COMPONENT_IDS } from '@ntb-sport/constants';
import { useBem } from '@ntb-sport/hooks';
import './competition.css';
import classNames from 'classnames';

interface CompetitionProps {
  competition: CompetitionType;

  onClick?: ({
    sportGroup,
    sportIdTranslated,
    sportId,
    date,
    uuid,
  }: {
    sportGroup: string;
    sportIdTranslated: string;
    sportId: string;
    date: string | false;
    uuid: string;
  }) => Promise<boolean> | void;
  date: string;
}

export const Competition = ({
  competition,

  onClick,
  date,
}: CompetitionProps) => {
  const bem = useBem(COMPONENT_IDS.SPORTEDIT_CALENDAR);

  return (
    <>
      <div
        className={bem('competition')}
        data-competition-id={competition?.uuid}
      >
        <div
          className={classNames(bem('competition-name-and-location'), {
            [bem('competition-name-and-location', 'column')]: Boolean(
              competition?.host?.broadcasts,
            ),
          })}
        >
          <div className={bem('competition-name')}>
            {competition.sport.uuid === UUIDs.Sport.CYCLING
              ? competition?.tour?.name || competition.name
              : competition?.host?.name || competition.name}
          </div>
          {competition?.sport?.uuid === UUIDs.Sport.ATHLETICS && (
            <div
              className={classNames(bem('competition-location'), {
                [bem('competition-location--divider')]:
                  !competition?.host?.broadcasts,
              })}
            >
              {`${
                competition?.events?.[0]?.host?.location?.name ||
                competition?.events?.[0]?.arrangedAtLocations?.[0]?.name
              }, ${
                competition?.events?.[0]?.host?.country?.name ||
                competition?.events?.[0]?.arrangedInCountries?.[0]?.name
              }`}
            </div>
          )}
        </div>
        {competition?.host?.broadcasts && (
          <div className={bem('competition-broadcasts')}>
            <BiBroadcast
              size="16px"
              color="var(--base-colors-blackAlpha-900)"
              style={{ marginTop: '-3px' }}
            />
            {competition?.host?.broadcasts
              ?.sort((a: NeoBroadcastType, b: NeoBroadcastType) => {
                if (a?.typeId === 2) {
                  return 1;
                } else if (a?.typeId === 1) {
                  return -1;
                }
                return 0;
              })
              ?.map((broadcast: any) => (
                <a
                  className={bem('competition-broadcast')}
                  href={broadcast?.url}
                  target="_blank"
                  rel="noopener"
                  key={broadcast?.uuid}
                >
                  {broadcast?.name}
                </a>
              ))}
          </div>
        )}
      </div>
      <MainEvents
        events={competition?.events}
        date={date}
        sport={competition?.sport}
        onClick={onClick}
      />
    </>
  );
};
