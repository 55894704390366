import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import { nb, fi, sv } from 'date-fns/locale';
import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';

import { IconButton, Button } from '../../../chakra';
import './header.css';
import { useBem } from '@ntb-sport/hooks';
import classNames from 'classnames';

const LOCALES = {
  nb,
  sv,
  fi,
};

interface HeaderProps {
  date: Date;
  translations: any;
  toggleDatepickerRef: any;
  isOpen: boolean;
  locale?: string;
  onHandlePrevDate: any;
  onToggleDatePicker: any;
  onHandleNextDate: any;
}

export const Header = ({
  date,
  translations,
  toggleDatepickerRef,
  isOpen,
  locale = 'nb',
  onHandlePrevDate,
  onToggleDatePicker,
  onHandleNextDate,
}: HeaderProps) => {
  const bem = useBem(COMPONENT_IDS.DATE_SELECTOR);

  return (
    <nav className={bem('header')}>
      <IconButton
        onClick={onHandlePrevDate}
        aria-label={translations.previousDay}
        className={classNames(bem('button'), bem('previous-day'))}
        size="sm"
        icon={<ChevronLeftIcon />}
      />

      <Button
        size="sm"
        ref={toggleDatepickerRef}
        aria-controls="calendar"
        aria-expanded={isOpen}
        aria-label={`Kalenderknapp: ${new Date(date).toLocaleDateString(
          'nb-NO',
          {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          },
        )}`}
        onClick={onToggleDatePicker}
        className={bem('button')}
      >
        <span className={bem('date')} aria-live="polite">
          {new Date(date).toLocaleDateString('nb-NO', {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </span>
      </Button>

      <IconButton
        data-element-id={ELEMENT_IDS.BUTTON}
        onClick={onHandleNextDate}
        aria-label={translations.nextDay}
        size="sm"
        icon={<ChevronRightIcon />}
        className={classNames(bem('button'), bem('next-day'))}
      />
    </nav>
  );
};
