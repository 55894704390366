import Icon from '../Icon/Icon';

export const NordicCombinedSportIcon = (props: any) => (
  <Icon viewBox="0 0 28.3 28.3" {...props}>
    <g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <path d="M19.4,4.3c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8C18.6,2.5,19.4,3.3,19.4,4.3z" />
              </g>
              <g>
                <path
                  d="M17.8,9.6c0.2,0.3,0.1,0.8-0.3,1L6.3,16.8l-3.9,2.1C2.4,19,2.2,19,2.1,19c-0.2,0-0.5-0.1-0.6-0.4
							c-0.2-0.3-0.1-0.8,0.3-1l3-1.7c0-0.2,0-0.3,0-0.5c0.1-0.2,1.3-4.3,4.8-7.5L7,8.5c-0.1,0-0.1,0-0.2,0C6.5,8.6,6.1,8.3,6,7.8
							c-0.1-0.5,0.2-0.9,0.7-1l6.8-1.2c0.2-0.1,0.5-0.2,0.7-0.3c0.5-0.2,1.2,0.1,1.4,0.6C15.8,6.4,15.5,7,15,7.2
							c-4.2,1.6-6.5,5.2-7.5,7.2l9.3-5.2C17.2,9.1,17.6,9.2,17.8,9.6z"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              d="M26.4,22.8h-4.5c0-0.1,0-0.2,0-0.2c-0.2-1.5-1.2-4.1-3.8-4.8c0.1-0.8,0.4-1.6,0.9-2.3
					c0.8,1,1.9,1.9,3.3,1.9c0.2,0,0.5,0,0.7-0.1v4.9c0,0.1,0.1,0.3,0.3,0.3c0.1,0,0.3-0.1,0.3-0.3v-5.1c0,0,0.1,0,0.1,0
					c0.4-0.2,0.6-0.7,0.4-1.2c-0.2-0.4-0.7-0.6-1.2-0.4c-1.7,0.8-3.1-2-3.1-2c-0.2-0.3-0.5-0.5-0.9-0.5c0,0-0.1,0-0.1,0
					c-2.5,0.2-3.9,1.5-4.7,2.5c-0.4,0.5-0.6,1-0.7,1.4l-2-1.9c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.3,0,0.4l2.2,2
					c0,0.1,0,0.2-0.1,0.3c-0.1,0.5,0.2,0.9,0.7,1c0,0,0.1,0,0.1,0c0.4,0,0.8-0.3,0.9-0.7c0-0.1,0.3-1.5,1.6-2.4
					c-0.7,1.5-0.8,3.1-0.4,4.8c0,0.1,0,0.2,0,0.3c0,0.8-0.7,1.6-1.4,2.2h-1.6c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h0.5
					c-0.1,0.3-0.1,0.6,0.1,0.8h-2.3c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h13.1c0.3,0,0.5-0.2,0.5-0.5
					c0-0.3-0.2-0.5-0.5-0.5h-8.7c0.3-0.2,0.6-0.5,0.9-0.8h9.6c0.3,0,0.5-0.2,0.5-0.5C26.9,23.1,26.7,22.8,26.4,22.8z M17.6,22.8
					c0.5-0.8,0.8-1.6,0.6-2.6c1.2,0.8,1.5,2.4,1.5,2.6H17.6z"
            />
          </g>
          <g>
            <path d="M18.7,11.2c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8c0,1-0.8,1.8-1.8,1.8C19.5,13,18.7,12.2,18.7,11.2z" />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
