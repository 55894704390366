import { LeftArrow, RightArrow } from './Arrows/Arrows';
import { ScrollMenu as ReactHorizontalScrollMenu } from 'react-horizontal-scrolling-menu';

import { useBem, useDrag } from '@ntb-sport/hooks';
import { COMPONENT_IDS } from '@ntb-sport/constants';
import { Skeleton } from '../../chakra';
import './event-menu.css';
import { MenuItem } from './MenuItem/MenuItem';

export const VIEW = {
  RESULTS: 'results',
  TOUR: 'tour',
  BLOG: 'blog',
  QUALIFIER: 'qualifier',
  KNOCKOUT_ROUNDS: 'knockout',
  MEDAL_TABLE: 'medal-table',
  STANDINGS: 'standings',
  OVERALL_STANDINGS: 'overall-standings',
};

interface EventMenuType {
  isLoading?: boolean;
  onClick: ({
    view,
    uuid,
    group,
    roundTypeUuid,
    disciplineUuid,
  }: {
    view: string;
    uuid: string;
    group: string;
    roundTypeUuid: string;
    disciplineUuid: string;
  }) => void;
  menuItems: any;
  activeItemUuid?: any;
  competitionId?: string;
}

export const EventMenu = ({
  isLoading,
  onClick,
  menuItems,
  activeItemUuid,
  competitionId,
}: EventMenuType) => {
  const bem = useBem(COMPONENT_IDS.EVENT_MENU);

  const { dragStart, dragStop, dragMove, dragging, touchStart, touchMove } =
    useDrag();

  const handleDrag =
    ({ scrollContainer }: { scrollContainer: any }) =>
    (ev: any) =>
      dragMove(ev, (posDiff: any) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  const handleTouchDrag =
    ({ scrollContainer }: { scrollContainer: any }) =>
    (ev: any) =>
      touchMove(ev, (posDiff: any) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  const handleItemClick = ({
    view,
    uuid,
    group,
    roundTypeUuid,
    disciplineUuid,
  }: {
    view: any;
    uuid: string;
    group: string;
    roundTypeUuid: string;
    disciplineUuid: string;
  }) => {
    if (dragging) {
      return false;
    }
    onClick({ view, uuid, group, roundTypeUuid, disciplineUuid });
    return;
  };

  const centerOnInit = ({
    getItemById,
    scrollToItem,
  }: {
    getItemById: any;
    scrollToItem: any;
  }) => {
    scrollToItem(getItemById(activeItemUuid), 'auto', 'center');
  };

  if (isLoading) {
    return (
      <Skeleton marginLeft="auto" marginRight="auto" height={30} width={300} />
    );
  }

  return (
    <div className={bem()} data-competition-id={competitionId}>
      <div className={bem('scroll-menu-container')}>
        <ReactHorizontalScrollMenu
          LeftArrow={<LeftArrow />}
          RightArrow={<RightArrow />}
          onMouseDown={() => dragStart}
          onMouseUp={() => dragStop}
          onMouseMove={handleDrag}
          onTouchStart={() => touchStart}
          onTouchEnd={() => dragStop}
          onTouchMove={handleTouchDrag}
          onInit={centerOnInit}
        >
          {menuItems?.map((menuItem: any) => {
            return (
              <MenuItem
                key={menuItem?.uuid}
                itemId={menuItem?.roundTypeUuid || menuItem?.uuid}
                item={menuItem}
                onClick={handleItemClick}
                activeItemUuid={activeItemUuid}
              />
            );
          })}
        </ReactHorizontalScrollMenu>
      </div>
    </div>
  );
};
