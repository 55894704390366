import styled from 'styled-components';

export const Header = styled.div`
  padding: var(--ntb-space-2);
  background: var(--base-colors-brand-500-background-color);
  border-bottom: 1px solid var(--base-colors-border);
  font-weight: var(--ntb-fontWeights-semibold);
  font-size: var(--ntb-fontSizes-sm);
`;

export const Referee = styled.div`
  padding: var(--ntb-space-2);
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--base-colors-border);
`;

export const RefereeName = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-medium);
`;

export const RefereeCountry = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-medium);
`;
