import styled, { css } from 'styled-components';

interface StyledProps {
  $matches?: any;
  isOn?: boolean;
  size?: string;
}

export const Switch = styled.button<StyledProps>`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;

  > span {
    display: flex;
    align-items: center;
    font-size: ${(props) =>
      props?.$matches?.tablet
        ? 'var(--ntb-fontSizes-sm)'
        : 'var(--ntb-fontSizes-xs)'};

    color: ${(props) => props.theme.fontColor};
    margin-right: 3px;
  }
`;

export const Toggle = styled.div<StyledProps>`
  display: inline-block;
  width: 50px;
  height: 25px;
  position: relative;
  cursor: pointer;
  user-select: none;
  background-color: ${(props) =>
    props.isOn ? props.theme.activeColor : props.theme.inactiveColor};
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
  border: 1px solid;
  border-color: ${(props) => props.theme.borderColor};

  ${({ size }) =>
    size === 'small' &&
    css`
      width: 40px;
      height: 20px;
    `}

  &:after {
    left: ${(props) => (props.isOn ? '50%' : 0)};
    position: relative;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    border-radius: 2em;
    background: ${(props) =>
      props.isOn
        ? props.theme.handleColorActive
        : props.theme.handleColorInactive};
    transition:
      all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
      padding 0.3s ease,
      margin 0.3s ease;
    box-shadow:
      0 0 0 1px rgba(0, 0, 0, 0.1),
      0 4px 0 rgba(0, 0, 0, 0.06);
  }
`;
