import {
  AssistIcon,
  Container,
  Event,
  Events,
  Field,
  GoalIcon,
  PitchContainer,
  Position,
  Positions,
  RedCardIcon,
  SecondYellowCardIcon,
  ShirtName,
  SubstititeIcon,
  Time,
  YellowCardIcon,
  OwnGoalIcon,
} from './Pitch.styled';

import FootballField from './Field';
import Bench from './Bench/Bench';
import Kit from '../../../Kit/Kit';
import { ELEMENT_IDS } from '@ntb-sport/constants';

export const Pitch = ({
  persons,
  kit,
  onClickPerson,
}: {
  persons: any;
  kit: any;
  onClickPerson: any;
}) => {
  const startingLineup = Object.values(persons).filter(
    (person: any) => person.startsMatch,
  );
  const bench = Object.values(persons).filter(
    (person: any) => !person.startsMatch,
  );

  return (
    <Container data-element-id={ELEMENT_IDS.PITCH}>
      <PitchContainer>
        <Field aria-hidden="true">
          <FootballField
            backgroundColor="var(--pitch-background-color)"
            stripeColor="var(--pitch-stripe-color)"
            lineColor="var(--pitch-line-color)"
          />
        </Field>

        <Positions>
          {startingLineup.map((person: any) => (
            <Player
              key={person.id}
              person={person}
              kit={kit}
              lineup={startingLineup}
              onClickPerson={onClickPerson}
            />
          ))}
        </Positions>
      </PitchContainer>
      <Bench bench={bench} kit={kit} onClickPerson={onClickPerson} />
    </Container>
  );
};

const Player = ({
  person,
  kit,
  lineup,
  onClickPerson,
}: {
  person: any;
  kit: any;
  lineup: any;
  onClickPerson: any;
}) => {
  //Defence
  const hasDC = Boolean(lineup.find((person: any) => person.position.id === 5));
  const hasLB = Boolean(lineup.find((person: any) => person.position.id === 4));
  const hasRB = Boolean(lineup.find((person: any) => person.position.id === 3));

  //DefensiveMidfield
  const hasDMC = Boolean(
    lineup.find((person: any) => person.position.id === 18),
  );
  const hasDML = Boolean(
    lineup.find((person: any) => person.position.id === 19),
  );
  const hasDMR = Boolean(
    lineup.find((person: any) => person.position.id === 17),
  );

  //Midfield
  const hasMC = Boolean(lineup.find((person: any) => person.position.id === 9));
  const hasML = Boolean(lineup.find((person: any) => person.position.id === 8));
  const hasMR = Boolean(lineup.find((person: any) => person.position.id === 7));

  //OffensiveMidfield
  const hasAMC = Boolean(
    lineup.find((person: any) => person.position.id === 23),
  );
  const hasAML = Boolean(
    lineup.find((person: any) => person.position.id === 24),
  );
  const hasAMR = Boolean(
    lineup.find((person: any) => person.position.id === 22),
  );

  //Forwards
  const hasST = Boolean(
    lineup.find((person: any) => person.position.id === 10),
  );
  const hasLW = Boolean(
    lineup.find((person: any) => person.position.id === 21),
  );
  const hasRW = Boolean(
    lineup.find((person: any) => person.position.id === 20),
  );

  const positions = {
    1: { position: 'K', norwegianText: 'Keeper', justify: '0px' },
    4: { position: 'LB', norwegianText: 'Venstre back', justify: '0px' },
    28: {
      position: 'DCL',
      norwegianText: 'Venstre midtstopper',
      justify: !hasDC && hasLB ? '40px' : hasDC && !hasLB ? '-40px' : '0px',
    },
    5: { position: 'DC', norwegianText: 'Midtstopper', justify: '0px' },

    27: {
      position: 'DCR',
      norwegianText: 'Høyre midtstopper',
      justify: !hasDC && hasRB ? '-40px' : hasDC && !hasRB ? '40px' : '0px',
    },
    3: { position: 'RB', norwegianText: 'Høyre back', justify: '0px' },
    19: {
      position: 'DML',
      norwegianText: 'Defensiv venstre midtbane',
      justify: '0px',
    },
    30: {
      position: 'DMCL',
      norwegianText: 'Defensiv sentral midtbane',
      justify: !hasDMC && hasDML ? '40px' : hasDMC && !hasDML ? '-40px' : '0px',
    },
    18: {
      position: 'DMC',
      norwegianText: 'Defensiv sentral midtbane',
      justify: '0px',
    },
    29: {
      position: 'DMCR',
      norwegianText: 'Defensiv sentral midtbane',
      justify: !hasDMC && hasDMR ? '-40px' : hasDMC && !hasDMR ? '40px' : '0px',
    },
    17: {
      position: 'DMR',
      norwegianText: 'Defensiv høyre midtbane',
      justify: '0px',
    },
    8: { position: 'ML', norwegianText: 'Venstre midtbane', justify: '0px' },
    26: {
      position: 'MCL',
      norwegianText: 'Venstre indreløper',
      justify: !hasMC && hasML ? '40px' : hasMC && !hasMR ? '-40px' : '0px',
    },
    36: {
      position: 'MCL',
      norwegianText: 'Venstre sentral midtbane',
      justify: !hasMC && hasML ? '40px' : hasMC && !hasMR ? '-40px' : '0px',
    },
    9: {
      position: 'MC',
      norwegianText: 'Sentral midtbane',
      justify: 'center',
    },
    35: {
      position: 'MCR',
      norwegianText: 'Høyre sentral midtbane',
      justify: !hasMC && hasMR ? '-40px' : hasMC && !hasMR ? '40px' : '0px',
    },
    25: {
      position: 'MCR',
      norwegianText: 'Høyre indreløper',
      justify: !hasMC && hasMR ? '-40px' : hasMC && !hasMR ? '40px' : '0px',
    },
    7: { position: 'MR', norwegianText: 'Høyre midtbane', justify: '0px' },
    24: {
      position: 'AML',
      norwegianText: 'Venstre offensiv midtbane',
      justify: '0px',
    },
    32: {
      position: 'AMCL',
      norwegianText: 'Venstre offensiv indreløper',
      justify: !hasAMC && hasAML ? '40px' : hasAMC && !hasAML ? '-40px' : '0px',
    },
    23: {
      position: 'AMC',
      norwegianText: 'Offensiv sentral midtbane',
      justify: '0px',
    },
    31: {
      position: 'AMCR',
      norwegianText: 'Høyre offensiv indreløper',
      justify: !hasAMC && hasAMR ? '-40px' : hasAMC && !hasAMR ? '40px' : '0px',
    },
    22: {
      position: 'AMR',
      norwegianText: 'Høyre offensiv midtbane',
      justify: '0px',
    },
    21: { position: 'LW', norwegianText: 'Venstre ving', justify: '0px' },
    34: {
      position: 'LS',
      norwegianText: 'Venstre spiss',
      justify: !hasST && hasLW ? '40px' : hasST && !hasLW ? '-40px' : '0px',
    },
    10: { position: 'ST', norwegianText: 'Spiss', justify: '0px' },
    33: {
      position: 'RS',
      norwegianText: 'Høyre spiss',
      justify: !hasST && hasRW ? '-40px' : hasST && !hasRW ? '40px' : '0px',
    },
    20: { position: 'RW', norwegianText: 'Høyre ving', justify: '0px' },
  };

  const shirtName =
    person.shirtName.length > 12
      ? `${person.shirtName.substr(0, 11)}.`
      : person.shirtName;

  const isKeeper =
    person.position && (person.position.id === 1 || person.position.id === 11);

  if (!positions[person.position.id as keyof typeof positions]) {
    return null;
  }

  const goalCount =
    person.personEvents.goals && person.personEvents.goals.length;
  const assistCount =
    person.personEvents.assists && person.personEvents.assists.length;
  const ownGoalCount =
    person.personEvents.ownGoals && person.personEvents.ownGoals.length;
  const substituteOffTime =
    (person.personEvents.substituteOff &&
      person?.personEvents?.substituteOff?.[0]?.timeRounded) ||
    person?.personEvents?.substituteOff?.[0]?.time;
  const substituteOnTime =
    (person.personEvents.substituteOn &&
      person?.personEvents?.substituteOn?.[0]?.timeRounded) ||
    person?.personEvents?.substituteOn?.[0]?.time;
  const yellowCardTime =
    (person.personEvents.yellowCards &&
      person?.personEvents?.yellowCards?.[0]?.timeRounded) ||
    person?.personEvents?.yellowCards?.[0]?.time;
  const redCardTime =
    (person.personEvents.redCards &&
      person?.personEvents?.redCards?.[0]?.timeRounded) ||
    person?.personEvents?.redCards?.[0]?.time;

  const ariaPosition = `, ${
    positions[person.position.id as keyof typeof positions]?.norwegianText
  }`;
  const ariaShirtNumber = `, draktnummer ${person.shirtNumber}`;
  const ariaGoals = goalCount ? `, antall mål ${goalCount}` : '';
  const ariaOwnGoals = ownGoalCount ? `, antall selvmål ${ownGoalCount}` : '';
  const ariaAssists = assistCount ? `, antall målgivende ${assistCount}` : '';
  const ariaSubstituteOff = substituteOffTime
    ? `, byttet ut i spillerminutt ${substituteOffTime}`
    : '';
  const ariaSubstituteOn = substituteOnTime
    ? `, byttet inn i spillerminutt ${substituteOnTime}`
    : '';
  const ariaYellowCard = yellowCardTime
    ? `, gult kort i spillerminutt ${yellowCardTime}`
    : '';
  const ariaRedCard = redCardTime
    ? `, rødt kort i spillerminutt ${redCardTime}`
    : '';

  return (
    <Position
      aria-label={`${person.shirtName}${ariaPosition}${ariaShirtNumber}${ariaGoals}${ariaAssists}${ariaSubstituteOff}${ariaSubstituteOn}${ariaYellowCard}${ariaRedCard}`}
      $position={
        positions[person.position.id as keyof typeof positions].position
      }
      $justify={positions[person.position.id as keyof typeof positions].justify}
      //to={`/fotball/utovere/${person.personId}`}
      $isClickable={onClickPerson}
      onClick={() =>
        onClickPerson &&
        onClickPerson({ name: person.name, id: person.personId, sportId: 1 })
      }
    >
      <Kit
        type={kit && kit.patternId}
        size={45}
        kitColor1={isKeeper ? 'grey' : kit && kit.colorCodes[0]}
        kitColor2={isKeeper ? 'grey' : kit && kit.colorCodes[1]}
        shirtNumber={person.shirtNumber}
      />
      <ShirtName $isOnPitch={true}>{shirtName}</ShirtName>

      {(person.personEvents.goals ||
        person.personEvents.ownGoals ||
        person.personEvents.assists ||
        person.personEvents.yellowCards ||
        (person.personEvents.yellowCards &&
          person.personEvents.yellowCards[1]) ||
        person.personEvents.redCards ||
        person.personEvents.substituteOff) && (
        <Events>
          {person.personEvents.goals && (
            <Event>
              <GoalIcon size="small" />
              {person.personEvents.goals.length > 1 && (
                <Time>x{person.personEvents.goals.length}</Time>
              )}
            </Event>
          )}

          {person.personEvents.ownGoals && (
            <Event>
              <OwnGoalIcon size="small" />
              {person.personEvents.ownGoals.length > 1 && (
                <Time>x{person.personEvents.ownGoals.length}</Time>
              )}
            </Event>
          )}

          {person.personEvents.assists && (
            <Event>
              <AssistIcon size="small" />
              {person.personEvents.assists.length > 1 && (
                <Time>x{person.personEvents.assists.length}</Time>
              )}
            </Event>
          )}

          {person.personEvents.yellowCards && (
            <Event>
              <YellowCardIcon
                size="small"
                cardId="yellow-card"
                fillColor="var(--base-colors-warning)"
              />
              <Time>
                {person.personEvents.yellowCards?.[0]?.timeRounded ||
                  person.personEvents.yellowCards?.[0]?.time}
                {"'"}
              </Time>
            </Event>
          )}

          {person.personEvents.yellowCards &&
            person.personEvents.yellowCards[1] && (
              <Event>
                <SecondYellowCardIcon
                  cardId="red"
                  size="small"
                  fillColor1="var(--base-colors-warning)"
                  fillColor2="var(--base-colors-error)"
                />
                <Time>
                  {person.personEvents.yellowCards?.[1]?.timeRounded ||
                    person.personEvents.yellowCards?.[1]?.time}
                  {"'"}
                </Time>
              </Event>
            )}

          {person.personEvents.redCards && (
            <Event>
              <RedCardIcon
                fillColor="var(--base-colors-error)"
                cardId="red-card"
                size="small"
              />
              <Time>
                {person.personEvents.redCards?.[0]?.timeRounded ||
                  person.personEvents.redCards?.[0]?.time}
                {"'"}
              </Time>
            </Event>
          )}

          {person.personEvents.substituteOff && (
            <Event>
              <SubstititeIcon
                size="small"
                fillColor1="var(--base-colors-success)"
                fillColor2="var(--base-colors-error)"
              />
              <Time>
                {person?.personEvents?.substituteOff?.[0]?.timeRounded ||
                  person?.personEvents?.substituteOff?.[0]?.time}
                {"'"}
              </Time>
            </Event>
          )}
        </Events>
      )}
    </Position>
  );
};

export default Pitch;
