import { RESULT_IDS } from '@ntb-sport/constants';
import { useBem } from '@ntb-sport/hooks';
import classNames from 'classnames';
import './result-id.css';

interface ResultIdType {
  resultId: number;
}

const ResultId = ({ resultId }: ResultIdType) => {
  const bem = useBem('scope-results');

  if (!RESULT_IDS[resultId as keyof typeof RESULT_IDS]) return null;

  return (
    <div
      className={classNames(bem('result-id'), {
        [bem('result-id', 'success')]:
          RESULT_IDS[resultId as keyof typeof RESULT_IDS]?.type === 'normal',
        [bem('result-id', 'error')]:
          RESULT_IDS[resultId as keyof typeof RESULT_IDS]?.type === 'error',
      })}
    >
      {RESULT_IDS[resultId as keyof typeof RESULT_IDS]?.name}
    </div>
  );
};

export default ResultId;
