import { NifsFairPlayTableClub } from '@ntb-sport/types';

import { Fetch, NIFS_API_BASE_URL, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchNifsFairPlay {
  apiBaseUrl: string;
  districtId: number;
}

export const fetchNifsFairPlay = async ({
  apiBaseUrl = NIFS_API_BASE_URL,
  districtId,
}: FetchNifsFairPlay): Promise<NifsFairPlayTableClub[]> => {
  const url = `${apiBaseUrl}/districts/${districtId}/clubFairPlayTable/`;

  const json = await Fetch({
    url,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });

  return json;
};
