import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '@ntb-sport/constants';
import {
  fetchTransfersTournament,
  fetchTransfersTeam,
  FetchTransfersType,
} from './fetch';

interface UseQueryNifsTransfers {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: FetchTransfersType;
  options?: Record<string, unknown>;
}

export const useQueryNifsTransfersTournament = ({
  queryKey = `${QUERY_KEYS.TRANSFERS}_tournament`,
  queryKeyParams,
  params,
}: UseQueryNifsTransfers) => {
  const { data, isLoading, error, isFetching, isSuccess } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchTransfersTournament(params),
    enabled: Boolean(params.stageId),
  });

  return { data, isLoading, error, isFetching, isSuccess };
};

export const useQueryNifsTransfersTeam = ({
  queryKey = `${QUERY_KEYS.TRANSFERS}_team`,
  queryKeyParams,
  params,
}: UseQueryNifsTransfers) => {
  const { data, isLoading, error, isFetching, isSuccess } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchTransfersTeam(params),
    enabled: Boolean(params.teamId),
  });

  return { data, isLoading, error, isFetching, isSuccess };
};
