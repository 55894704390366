import styled, { css } from 'styled-components';

import Flag from '../../../../../common/CountryFlag/CountryFlag';

interface StyledProps {
  $isClickable?: boolean;
}

export const Player = styled.li<StyledProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--ntb-space-2) 0;
  column-gap: var(--ntb-space-4);
  border-bottom: 1px solid var(--border-color);

  ${(props) =>
    props.$isClickable &&
    css`
      cursor: pointer;

      &:hover {
        background-color: var(--background-color__hover);
      }
    `}
`;

export const Bio = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
`;

export const NameAndCountry = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--ntb-space-1);
`;

export const Country = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
`;

export const CountryName = styled.div<StyledProps>`
  font-size: var(--ntb-fontSizes-sm);
`;

export const CountryFlag = styled(Flag).withConfig({
  shouldForwardProp: (prop) => ['countryName'].includes(prop),
})<StyledProps>`
  min-width: 16px;
  max-width: 16px;
`;

export const PersonName = styled.div<StyledProps>`
  font-size: var(--ntb-fontSizes-md);
  font-weight: var(--ntb-fontWeights-medium);
`;

export const Events = styled.div`
  justify-self: end;
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
`;

export const Event = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: var(--ntb-space-1);
`;

export const EventTime = styled.div<StyledProps>`
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-light);
`;

export const ClubAndCountry = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
`;

export const Club = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
`;

export const ClubLogo = styled.img<StyledProps>`
  max-width: 24px;
`;

export const ClubName = styled.div<StyledProps>`
  font-size: var(--ntb-fontSizes-sm);
  line-height: 24px;
`;
