import { Icon } from '../../chakra';

export const PenaltyMissIcon = ({
  fillColor1 = '#000',
  fillColor2 = 'red',
  ...restProps
}: {
  fillColor1?: string;
  fillColor2?: string;
}) => (
  <Icon {...restProps}>
    <g>
      <g>
        <g>
          <g>
            <g style={{ fill: fillColor1 }}>
              <path
                d="M1.6,5.3c0-0.6,0.3-1.3,0.7-1.7l0.9,0.9L1.6,6.2V5.3z M1.6,6.9l1.7,1.6l-1.6,1.6L1.6,6.9L1.6,6.9z M1.6,14
						v-3.3l1.6,1.6L1.6,14z M16.7,10.6L16,11.2c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.2l0.6-0.6L16.7,10.6z M18.6,12.6l-1,1
						c0.1,0.1,0.1,0.3,0.2,0.4l1.2-1.2L18.6,12.6z M22.1,5.1c0-0.3-0.1-0.6-0.2-0.9l-1,1v0.1v0.9l-0.5-0.5L20.2,6l0.5,0.5l-0.6,0.6
						l0.3,0.3L21,6.9V8l1.2,1.2V5.3C22.2,5.2,22.2,5.2,22.1,5.1z M21,12.7V14l-0.8-0.7c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.1,0-0.2,0
						l1,1h1.4v-2.8L21,12.7z M14.9,1.9L16,3.1h0.2l1.2-1.2H14.9z M9.6,6.5l1.4-1.4l-0.3-0.3L9.3,6.2L7.7,4.6l1.4-1.5h0.3
						c0-0.1,0-0.1,0-0.2c0-0.4,0.1-0.6,0.4-0.9l0.1-0.1H3.8c-0.7,0-1.4,0.3-2,0.7C1.8,2.7,1.7,2.8,1.5,2.9c-0.5,0.5-1,1.4-1,2.3
						c0,0.1,0,0.2,0,0.3v9h1.4l1.7-1.7L5,14c0-0.1,0.1-0.3,0.2-0.4L4,12.5l1.6-1.6l0.8,0.8c0.1-0.1,0.2-0.2,0.2-0.2s0.1,0,0.1-0.1
						l-1-1l1.6-1.6l1.2,1.2c0.1-0.1,0.3-0.1,0.4-0.2L7.7,8.5l1.6-1.7l1.5,1.5L11.2,8L9.6,6.5z M8.6,3.1L7.5,4.3L6.2,3.1H8.6z
						 M5.6,3.1l1.5,1.5L5.5,6.2L3.8,4.6l1.4-1.5H5.6z M3.8,3.1h0.9L3.6,4.3L2.7,3.4C3,3.2,3.5,3.1,3.8,3.1z M3.6,4.9l1.6,1.6
						L3.6,8.1L1.8,6.5L3.6,4.9z M3.6,12.1l-1.6-1.6l1.6-1.6l1.6,1.6L3.6,12.1z M5.5,10.1L3.8,8.5l1.6-1.6l1.7,1.6L5.5,10.1z
						 M7.5,8.2L5.7,6.5l1.7-1.6l1.6,1.6L7.5,8.2z"
              />
            </g>
          </g>
        </g>
        <g style={{ fill: fillColor1 }}>
          <path
            d="M14.4,11.7L14.1,12c0.2,0.1,0.2,0.2,0.3,0.2l0,0l0,0l0,0c0.4,0.3,0.4,0.6,0.3,0.9c-0.3-0.1-0.8-0.2-1.4,0
				c0-0.1-0.1-0.1-0.2-0.2c-0.3,0.2-0.5,0.3-0.8,0.3c-0.4,0-0.6-0.1-0.9-0.4l-0.6-0.6c-0.5,0.2-0.9,0.5-1.1,0.7
				c-0.3-0.1-0.9-0.3-1.5,0c-0.1-0.3,0-0.5,0-0.5l0,0c0.1-0.2,0.2-0.4,0.3-0.5l0,0c0.5-0.5,1.2-0.5,1.4-0.6l-0.1-0.1
				c-0.1-0.1-0.1-0.2-0.2-0.3c-2.2,0.6-3.7,2.6-3.7,5.1c0,3,2.4,5.4,5.4,5.4s5.4-2.4,5.4-5.4C16.7,14.3,15.8,12.7,14.4,11.7z
				 M15.2,12.9c0.6,0.7,0.8,1.6,0.9,2.1H16c-0.1-0.4-0.4-1.2-0.9-1.6C15.2,13.3,15.2,13.1,15.2,12.9z M8.2,13.5
				c0.6-0.3,1.3-0.1,1.4,0c0.1,0.2,0.3,0.6,0.6,1.5c-0.2,0.2-0.4,0.5-0.5,0.9c-0.2,0.4-0.4,0.6-0.5,0.8c-0.5,0-1.3,0-1.4-0.1
				c-0.5-0.5-0.5-1.5-0.5-1.6C7.3,14,8.1,13.6,8.2,13.5L8.2,13.5z M6.5,14.7c0-0.4,0.5-1.2,0.8-1.6c0-0.1,0.1-0.1,0.2-0.2
				c0.1-0.1,0.2-0.2,0.3-0.2c0,0.2,0,0.3,0.1,0.5c-0.4,0.2-0.9,0.7-1.2,1.6C6.5,14.8,6.5,14.7,6.5,14.7L6.5,14.7L6.5,14.7z
				 M6.5,17.3c-0.2-0.7-0.2-1.4-0.2-1.6c0-0.1,0-0.1,0-0.2l0,0l0,0c0-0.1,0.1-0.2,0.1-0.2c0.1,0,0.2,0.1,0.3,0.1
				c0,0.4,0.2,1.2,0.6,1.7c-0.2,0.6-0.1,1.2-0.1,1.5c-0.1,0-0.2,0-0.4,0C6.8,18.5,6.6,18.2,6.5,17.3z M8.9,20.4
				c-0.2-0.1-0.4-0.2-0.6-0.4c-0.6-0.4-0.9-0.8-1.1-1.1c0.1,0,0.2,0,0.2,0c0.5,0.7,1.4,1,1.7,1c0.1,0.2,0.2,0.5,0.5,0.8
				C9.3,20.7,9.2,20.6,8.9,20.4z M12.3,20.7L12.3,20.7L12.3,20.7c-0.7,0.3-1.9,0-2.1,0l0,0l0,0c-0.2-0.1-0.4-0.3-0.5-0.5
				c-0.1-0.1-0.1-0.3-0.2-0.4c0.3-0.2,0.6-0.5,0.8-0.8c0.3,0.1,0.6,0.1,1,0.1c0.5,0,0.9-0.1,1.1-0.1c0.4,0.5,0.5,0.7,0.7,0.9
				C12.9,20.6,12.3,20.7,12.3,20.7z M13.1,17.6L13.1,17.6c-0.3,0.4-0.5,0.7-0.7,0.9l0,0c-0.2,0.1-1.2,0.3-2,0c-0.4-0.5-0.8-1-1-1.6
				c0.2-0.3,0.4-0.6,0.5-1c0.2-0.4,0.4-0.6,0.5-0.7l0,0c0.6,0,1.3-0.1,1.9,0.1c0.4,0.5,0.9,1.3,1,1.6c0,0.1-0.1,0.3-0.1,0.4
				C13.3,17.4,13.2,17.5,13.1,17.6z M13.6,20.4c-0.1,0.1-0.2,0.1-0.3,0.2c0.1-0.1,0.3-0.3,0.4-0.5c0.2,0,0.4,0,0.5-0.1
				c0.1,0,0.2-0.1,0.2-0.1C14.1,20.2,13.9,20.3,13.6,20.4z M15.1,16.8c-0.2,0-0.6,0-1.3,0c-0.2-0.5-0.7-1.4-1-1.6
				c0.2-0.5,0.5-1.3,0.5-1.5c0.6-0.2,1.3,0,1.4,0c0.6,0.5,0.9,1.5,0.9,1.6l0,0C15.8,16.2,15.3,16.7,15.1,16.8z M15.5,18.7
				c0.1-0.3,0.3-1,0-1.6c0.3-0.3,0.6-0.8,0.6-1.6h0.1C16.6,16.8,15.8,18.3,15.5,18.7L15.5,18.7z"
          />
        </g>
      </g>
      <polygon
        style={{ fill: fillColor2 }}
        points="12.4,1.3 16.1,5 19.8,1.3 21.6,2.9 17.8,6.6 21.6,10.4 19.8,12 16.1,8.3 12.4,12 10.7,10.4 14.4,6.6 
		10.7,2.9"
      />
    </g>
  </Icon>
);

export default PenaltyMissIcon;
