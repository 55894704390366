import { Container } from '../../../../chakra';
import * as S from './List.styled';
import { LineUpPositions } from '@ntb-sport/constants';
import Person from './Player/Player';

export const List = ({
  persons,
  displayNationality,
  team,

  onClickPerson,
  isNationalTeam,
}: {
  persons: any;

  displayNationality?: boolean;
  isNationalTeam: boolean;
  team: any;
  
  onClickPerson: any;
}) => {
  const startingLineup = Object.values(persons).filter(
    (person: any) => person.startsMatch,
  );
  const bench = Object.values(persons).filter(
    (person: any) => !person.startsMatch,
  );

  const hasMoreThenStartingLineup =
    Object.values(persons).filter((person: any) => person.startsMatch)?.length >
    11;

  const positionOrder = Object.values(LineUpPositions)
    .filter(value => typeof value === 'number')
    .map(value => value as number);

  const sortByLineUpOrder = (a: any, b: any) => {
    const indexA = positionOrder.indexOf(a.position.id);
    const indexB = positionOrder.indexOf(b.position.id);
    return indexA - indexB;
  };

  return (
    <Container>
      {!hasMoreThenStartingLineup ? (
        <>
          <S.Section>
            <S.SectionTitle>Fra start</S.SectionTitle>
            <S.Players>
              {startingLineup
                .filter((person: any) => person.position.id === 1)
                .map((person: any) => (
                  <Person
                    key={person.id}
                    person={person}
                    kit={team.kit}
                    displayNationality={displayNationality}
                    isCaptain={
                      team.captain && team.captain.id === person.personId
                    }
                    isNationalTeam={isNationalTeam}
                    onClickPerson={onClickPerson}
                  />
                ))}
              {startingLineup
                .filter(
                  (person: any) =>
                    person.position.id !== LineUpPositions.GOALKEEPER,
                )
                .sort(sortByLineUpOrder)
                .map((person: any) => (
                  <Person
                    key={person.id}
                    person={person}
                    kit={team.kit}
                    displayNationality={displayNationality}
                    isCaptain={
                      team.captain && team.captain.id === person.personId
                    }
                    isNationalTeam={isNationalTeam}
                    onClickPerson={onClickPerson}
                  />
                ))}
            </S.Players>
          </S.Section>

          <S.Section>
            <S.SectionTitle>Innbyttere</S.SectionTitle>

            <S.Players>
              {bench
                .filter(
                  (player: any) => player.position && player.position.id === 11,
                )
                .sort((a: any, b: any) => a.shirtNumber - b.shirtNumber)
                .map((person: any) => (
                  <Person
                    key={person.id}
                    kit={{ ...team.kit, colorCodes: ['grey', 'grey'] }}
                    person={person}
                    displayNationality={displayNationality}
                    isNationalTeam={isNationalTeam}
                    onClickPerson={onClickPerson}
                  />
                ))}

              {bench
                .filter((player: any) => !player.position)
                .sort((a: any, b: any) => a.shirtNumber - b.shirtNumber)
                .map((person: any) => (
                  <Person
                    key={person.id}
                    kit={team.kit}
                    displayNationality={displayNationality}
                    person={person}
                    isNationalTeam={isNationalTeam}
                    onClickPerson={onClickPerson}
                  />
                ))}
            </S.Players>
          </S.Section>
        </>
      ) : (
        <S.Section>
          <S.SectionTitle>Kamptropp</S.SectionTitle>

          {Object.values(persons)
            .sort((a: any, b: any) => (a.shirtNumber < b.shirtNumber ? -1 : 0))
            .map((person: any) => (
              <Person
                key={person.id}
                kit={team.kit}
                displayNationality={displayNationality}
                person={person}
                isNationalTeam={isNationalTeam}
                onClickPerson={onClickPerson}
              />
            ))}
        </S.Section>
      )}
    </Container>
  );
};

export default List;
