import { QUERY_KEYS } from '@ntb-sport/constants';
import { updateObjectInArray } from '../utils';

export function onScopeResultsPatch({
  msg,
  apiClient,
}: {
  msg: any;
  apiClient: any;
}) {
  const { entityId, data, additionalData } = msg;

  const scopeResults = apiClient.getQueryData([
    QUERY_KEYS.SCOPE_RESULTS,
    { scopeUUID: additionalData.scope.uuid },
  ]);

  const state = scopeResults?.data;

  if (!state) return; //TODO: Remove this when join scope channel is implemented

  const entityIndex = state?.findIndex(
    (scopeResult: any) => scopeResult?.uuid === entityId,
  );

  const updatedObject = data?.reduce((acc: any, item: any) => {
    const key = item?.path.substr(
      item.path.lastIndexOf('/') + 1,
      item.path.length,
    );

    const value = item.value;

    if (!acc.key) {
      acc[key] = value;
    }

    return acc;
  }, {});

  const newState = updateObjectInArray(state, {
    index: entityIndex,
    item: {
      ...state[entityIndex],
      ...updatedObject,
    },
  });

  apiClient.setQueryData(
    [QUERY_KEYS.SCOPE_RESULTS, { scopeUUID: additionalData.scope.uuid }],
    {
      ...scopeResults,
      data: newState,
    },
  );
}
