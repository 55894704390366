import { QUERY_KEYS } from '@ntb-sport/constants';
import { getUpdatedState, updateObjectInArray } from '../utils';

export function onEventsPatch({
  msg,
  apiClient,
}: {
  msg: any;
  apiClient: any;
}) {
  const { entityId, data, additionalData } = msg;

  const state = apiClient.getQueryData([
    QUERY_KEYS.EVENT,
    { eventUUID: additionalData?.event?.uuid },
  ]);

  const updatedState = getUpdatedState(data);

  //Update childEvent array on parentEvent
  if (state?.labels?.includes('ChildEvent')) {
    const parentEventUUID = additionalData?.event?.parentEvent?.uuid;

    const parentEventState = apiClient.getQueryData([
      QUERY_KEYS.EVENT,
      { eventUUID: parentEventUUID },
    ]);

    const childEventIndex = parentEventState?.childEvents?.findIndex(
      (item: any) => item?.uuid === entityId,
    );

    const newChildEventState = updateObjectInArray(
      parentEventState?.childEvents,
      {
        index: childEventIndex,
        item: {
          ...parentEventState?.childEvents[childEventIndex],
          ...updatedState,
        },
      },
    );

    const newState = {
      ...state,
      childEvents: newChildEventState,
      eventStatusId: updatedState?.eventStatusId,
    };

    apiClient.setQueryData(
      [QUERY_KEYS.EVENT, { eventUUID: additionalData?.event?.uuid }],
      newState,
    );
  } else {
    const newState = {
      ...state,
      ...updatedState,
    };

    apiClient.setQueryData(
      [QUERY_KEYS.EVENT, { eventUUID: additionalData?.event?.uuid }],
      newState,
    );
  }
}
