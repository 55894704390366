import { Fetch } from '../../fetch';

export interface FetchScopesType {
  apiBaseUrl: string;
  version?: string;
  eventUUID: string;
  token: string;
  customerUUID?: string;
}

export const fetchScopes = async ({
  apiBaseUrl,
  eventUUID,
  token,
  customerUUID,
}: FetchScopesType) => {
  const url = `${apiBaseUrl}/events/${eventUUID}/scopes`;

  const json = await Fetch({
    url,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });

  return json.data;
};
