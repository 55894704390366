import {
  COMPONENT_IDS,
  DATA_PICKER_GROUPS,
  DATA_PROVIDERS,
  SPORT_GROUP,
} from '@ntb-sport/constants';
import { WidgetConfig, SportsdataConfig } from '@ntb-sport/types';
import {
  DATA_TYPES,
  WIDGET_IDS,
  WIDGET_OPTIONS,
  WIDGET_VERSION,
} from '../common';

export const config: WidgetConfig = {
  id: WIDGET_IDS.MATCH_RESULT,
  dataType: DATA_TYPES.NIFS,
  version: WIDGET_VERSION,
  displayName: 'Kampfakta (sportgruppe)',
  widgetNamespace: 'NtbWidgetMatchResult',
  iframeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.MATCH_RESULT}/iframe.html`,
  widgetUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.MATCH_RESULT}/${WIDGET_IDS.MATCH_RESULT}.js`,
  themeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/themes/default.css`,
  title: 'Kampfakta',
  summary: 'Kampfakta, referat, lagoppstilling og statistikk',
  sportGroups: [
    SPORT_GROUP.FOOTBALL,
    SPORT_GROUP.HANDBALL,
    SPORT_GROUP.ICE_HOCKEY,
  ],
  description: 'general description to be displayed on the documentation page',
  components: [
    COMPONENT_IDS.TEAM_LINEUP,
    COMPONENT_IDS.TEAM_MATCH_REPORT,
    COMPONENT_IDS.TEAM_MATCH_RESULT,
    COMPONENT_IDS.TEAM_MATCH_STATISTICS,
  ],
  options: {
    [WIDGET_OPTIONS.TARGET_ID]: { isRequired: true },
    [WIDGET_OPTIONS.SPORT_GROUP]: { isRequired: true },
    [WIDGET_OPTIONS.CUSTOMER_ID]: { isRequired: true },
    [WIDGET_OPTIONS.STYLE_ID]: { isRequired: false },
    [WIDGET_OPTIONS.ID]: { isRequired: true },
    [WIDGET_OPTIONS.MATCH_ID]: { isRequired: false },
    [WIDGET_OPTIONS.ON_ERROR]: { isRequired: false },
  },
  uiOptions: {
    [COMPONENT_IDS.TEAM_MATCH_RESULT]: [
      WIDGET_OPTIONS.DEFAULT_TAB,
      WIDGET_OPTIONS.SHOW_BACKGROUND_IMAGE,
      WIDGET_OPTIONS.SHOW_MENU,
      WIDGET_OPTIONS.SHOW_TEAM_COLORS,
    ],
    [COMPONENT_IDS.TEAM_LINEUP]: [WIDGET_OPTIONS.SHOW_NATIONALITY],
  },
};

export const sportsdataConfig: SportsdataConfig = {
  dataPickerGroups: {
    [DATA_PICKER_GROUPS.NIFS_MATCH_GROUP]: {
      title: 'Kamp',
      key: DATA_PICKER_GROUPS.NIFS_MATCH_GROUP,
      primaryId: DATA_PROVIDERS.NIFS_MATCHES,
      isDefault: true,
      pickers: {
        [DATA_PROVIDERS.SPORT_GROUP]: { defaultValue: 'football' },
        [DATA_PROVIDERS.NIFS_COUNTRIES]: { defaultValue: 1 },
        [DATA_PROVIDERS.NIFS_TOURNAMENTS]: {},
        [DATA_PROVIDERS.NIFS_STAGES]: {},
        [DATA_PROVIDERS.NIFS_MATCHES]: { isPrimaryId: true },
      },
    },
    [DATA_PICKER_GROUPS.DATE_GROUP]: {
      title: 'Dato',
      key: DATA_PICKER_GROUPS.DATE_GROUP,
      primaryId: DATA_PROVIDERS.NIFS_MATCHES_BY_DATE,
      pickers: {
        [DATA_PROVIDERS.DATE_SELECTOR]: {},
        [DATA_PROVIDERS.NIFS_MATCHES_BY_DATE]: { isPrimaryId: true },
      },
    },
  },
  optionGroups: {
    [COMPONENT_IDS.TEAM_MATCH_RESULT]: {
      title: 'Kampresultater',
      key: COMPONENT_IDS.TEAM_MATCH_RESULT,
      options: {
        [WIDGET_OPTIONS.DEFAULT_TAB]: {
          key: WIDGET_OPTIONS.DEFAULT_TAB,
          defaultValue: '-1',
          label: 'Default menyvalg',
          type: 'radioGroup',
          options: [
            { label: 'Ingen', value: '-1' },
            { label: 'Kampreferat', value: '0' },
            { label: 'Lagoppstilling', value: '1' },
            { label: 'Kampstatistikk', value: '2' },
          ],
        },
        [WIDGET_OPTIONS.SHOW_BACKGROUND_IMAGE]: {
          key: WIDGET_OPTIONS.SHOW_BACKGROUND_IMAGE,
          defaultValue: false,
          label: 'Vis bakgrunnsbilde',
          type: 'checkbox',
        },
        [WIDGET_OPTIONS.SHOW_MENU]: {
          key: WIDGET_OPTIONS.SHOW_MENU,
          defaultValue: true,
          label: 'Vis meny',
          type: 'checkbox',
        },
        [WIDGET_OPTIONS.SHOW_TEAM_COLORS]: {
          key: WIDGET_OPTIONS.SHOW_TEAM_COLORS,
          defaultValue: false,
          label: 'Vis lagfarger',
          type: 'checkbox',
        },
      },
    },
    [COMPONENT_IDS.TEAM_LINEUP]: {
      title: 'Lagoppstilling',
      key: COMPONENT_IDS.TEAM_LINEUP,
      options: {
        [WIDGET_OPTIONS.SHOW_NATIONALITY]: {
          key: WIDGET_OPTIONS.SHOW_NATIONALITY,
          defaultValue: true,
          label: 'Vis nasjonalitet',
          type: 'checkbox',
        },
      },
    },
  },
};
