import { Fetch, SPORTEDIT_API_BASE_URL } from '../../fetch';

export interface FetchCompetitionSeasons {
  apiBaseUrl: string;
  competitionUUID: string;
  token: string;
  customerUUID: string;
}

export const fetchCompetitionSeasons = async ({
  apiBaseUrl = SPORTEDIT_API_BASE_URL,
  competitionUUID,
  token,
  customerUUID,
}: FetchCompetitionSeasons) => {
  const json = await Fetch({
    url: `${apiBaseUrl}/competitions/${competitionUUID}/seasons`,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });

  return json?.data;
};
