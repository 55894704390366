import nb from './nb.json';

const OVERRIDES = {
  England: 'gb-eng',
  Skottland: 'gb-sct',
  Wales: 'gb-wls',
  'Nord-Irland': 'gb-nir',
  'Kongo-Kinshasa': 'cd',
  'North Macedonia': 'mk',
  ROC: 'ROC',
  'De amerikanske Jomfruøyer': 'vi',
  Luxemburg: 'lu',
  'Dominikanske republikk': 'do',
  'De britiske Jomfruøyer': 'vg',
  Belarus: 'by',
  Myanmar: 'mm',
  Palestina: 'ps',
  Macau: 'mo',
  Tahiti: 'pf',
  'DR Kongo:': 'cd',
} as { [key: string]: string };

export const getAlpha2Code = (name: string, lang: string) => {
  const code =
    OVERRIDES[name] ||
    Object.keys(nb.countries).find(
      (key) => nb.countries[key as keyof typeof nb.countries] === name,
    );

  return code;
};
