import {
  AwayEvents,
  Event,
  EventPerson,
  EventWrapper,
  HomeEvents,
  PersonName,
} from './MatchEventsHandball.styled';

import { MATCH_EVENTS } from '@ntb-sport/constants';

const MatchEventsHandball = ({
  homeTeam,
  awayTeam,
  matchEvents,
  personLink,
}: {
  homeTeam: any;
  awayTeam: any;
  matchEvents: any;
  personLink: any;
}) => {
  const homeEvents =
    matchEvents &&
    matchEvents
      .filter(
        ({ team, matchEventTypeId }: { team: any; matchEventTypeId: any }) =>
          team &&
          team.id === homeTeam.id &&
          matchEventTypeId === MATCH_EVENTS.GOAL,
      )
      .reduce((events: any[], event: any) => {
        const personId = event.person && event.person.id;
        if (!events[personId]) {
          events[personId] = {
            ...event.person,
            sportId: event.sportId,
            goals: 1,
          };
        } else {
          events[personId].goals += 1;
        }
        return events;
      }, {});

  const awayEvents =
    matchEvents &&
    matchEvents
      .filter(
        ({ team, matchEventTypeId }: { team: any; matchEventTypeId: any }) =>
          team &&
          team.id === awayTeam.id &&
          matchEventTypeId === MATCH_EVENTS.GOAL,
      )
      .reduce((events: any[], event: any) => {
        const personId = event.person && event.person.id;
        if (!events[personId]) {
          events[personId] = {
            ...event.person,
            sportId: event.sportId,
            goals: 1,
          };
        } else {
          events[personId].goals += 1;
        }
        return events;
      }, {});

  return (
    <>
      {homeEvents && Object.keys(homeEvents).length ? (
        <HomeEvents>
          <EventWrapper $side="home">
            <Event>
              {Object.values(homeEvents)
                .sort((a: any, b: any) => (a.goals < b.goals ? 1 : -1))
                .map((person: any) => (
                  <EventPerson key={person.id}>
                    <PersonName>
                      {personLink && !person?.hiddenPersonInfo
                        ? personLink({
                            id: person?.id,
                            name: person?.shirtName || person?.name,
                            sportId: person?.sportId,
                          })
                        : person?.shirtName || person?.name}
                    </PersonName>
                    {person?.goals}
                  </EventPerson>
                ))}
            </Event>
          </EventWrapper>
        </HomeEvents>
      ) : (
        <HomeEvents />
      )}

      {awayEvents && Object.keys(awayEvents).length ? (
        <AwayEvents>
          <EventWrapper $side="away">
            <Event>
              {Object.values(awayEvents)
                .sort((a: any, b: any) => (a.goals < b.goals ? 1 : -1))
                .map((person: any) => (
                  <EventPerson key={person.id}>
                    <PersonName>
                      {personLink
                        ? personLink({
                            id: person?.id,
                            name: person?.shirtName || person?.name,
                            sportId: person?.sportId,
                          })
                        : person?.shirtName || person?.name}
                    </PersonName>
                    {person.goals}
                  </EventPerson>
                ))}
            </Event>
          </EventWrapper>
        </AwayEvents>
      ) : (
        <AwayEvents />
      )}
    </>
  );
};

export default MatchEventsHandball;
