import { CardIcon, PenaltyMissIcon, SecondCardIcon } from '../../../icons';
import {
  EventDescription,
  EventPerson,
  EventTime,
  EventType,
  Events,
  PersonName,
  Score,
  StyledEvent,
  Team,
} from './MatchEvents.styled';

import { MATCH_EVENTS } from '@ntb-sport/constants';

function getMatchEvents({
  sportId,
  team,
  matchEvents,
}: {
  sportId: number;
  team: any;
  matchEvents: any[];
}) {
  if (sportId === 2) {
    return matchEvents
      ?.filter(
        (matchEvent) =>
          matchEvent?.team &&
          matchEvent?.team?.id === team?.id &&
          (matchEvent.matchEventTypeId === MATCH_EVENTS.GOAL ||
            matchEvent.matchEventTypeId === MATCH_EVENTS.OWN_GOAL ||
            matchEvent.matchEventTypeId === MATCH_EVENTS.PENALTY_MISS),
      )
      ?.sort((a, b) => {
        if (a.periodId === b.periodId) {
          if (a.timeRounded === b.timeRounded || a.time === b.time) {
            if (
              a?.overtimeRounded === b?.overtimeRounded ||
              a.overtime === b.overtime
            ) {
              return a.sorting > b.sorting ? 1 : -1;
            }

            return a?.overtimeRounded > b?.overtimeRounded ||
              a.overtime > b.overtime
              ? 1
              : -1;
          }
          return a?.timeRounded > b?.timeRounded || a.time > b.time ? 1 : -1;
        }
        return a.periodSorting > b.periodSorting ? 1 : -1;
      });
  } else {
    return matchEvents
      ?.filter(
        (matchEvent) =>
          matchEvent?.team &&
          matchEvent?.team?.id === team?.id &&
          (matchEvent.matchEventTypeId === MATCH_EVENTS.GOAL ||
            matchEvent.matchEventTypeId === MATCH_EVENTS.OWN_GOAL ||
            matchEvent.matchEventTypeId === MATCH_EVENTS.PENALTY_MISS ||
            matchEvent.matchEventTypeId === MATCH_EVENTS.RED_CARD ||
            matchEvent.matchEventTypeId === MATCH_EVENTS.SECOND_YELLOW_CARD),
      )
      ?.sort((a, b) => {
        if (a.periodId === b.periodId) {
          if (a.time === b.time) {
            if (a.overtime === b.overtime) {
              return a.sorting > b.sorting ? 1 : -1;
            }

            return a.overtime > b.overtime ? 1 : -1;
          }
          return a.time > b.time ? 1 : -1;
        }
        return a.periodId > b.periodId ? 1 : -1;
      });
  }
}

const MatchEvents = ({
  sportId,
  homeTeam,
  awayTeam,
  matchEvents,
  personLink,
  showTeamColors,
}: {
  sportId: any;
  homeTeam: any;
  awayTeam: any;
  matchEvents: any[];
  personLink: any;
  showTeamColors?: boolean;
}) => {
  if (!matchEvents) return null;

  const homeTeamBackgroundColor =
    homeTeam?.clubs && homeTeam.clubs[0] && homeTeam.clubs[0].primaryColor;
  const awayTeamBackgroundColor =
    awayTeam?.clubs && awayTeam.clubs[0] && awayTeam.clubs[0].primaryColor;
  const homeTeamTextColor =
    homeTeam?.clubs && homeTeam.clubs[0] && homeTeam.clubs[0].textColor;
  const awayTeamTextColor =
    awayTeam?.clubs && awayTeam.clubs[0] && awayTeam.clubs[0].textColor;

  const homeEvents = getMatchEvents({ sportId, team: homeTeam, matchEvents });
  const awayEvents = getMatchEvents({ sportId, team: awayTeam, matchEvents });

  return (
    <>
      <Team
        $side="hometeam"
        $backgroundColor={
          showTeamColors && homeEvents.length && homeTeamBackgroundColor
        }
        $textColor={showTeamColors && homeTeamTextColor}
      >
        <Events>
          {homeEvents.map(
            ({
              id,
              matchEventTypeId,
              timeRounded,
              time,
              seconds,
              overtimeRounded,
              person,
              score,
              periodId,
              isStaffEvent,
            }) => (
              <Event
                sportId={sportId}
                key={id}
                matchEventTypeId={matchEventTypeId}
                time={timeRounded || time}
                seconds={seconds}
                overtime={overtimeRounded}
                person={person}
                score={score}
                periodId={periodId}
                personLink={personLink}
                isStaffEvent={isStaffEvent}
              />
            ),
          )}
        </Events>
      </Team>
      <Team
        $side="awayteam"
        $backgroundColor={
          showTeamColors && awayEvents.length && awayTeamBackgroundColor
        }
        $textColor={showTeamColors && awayTeamTextColor}
      >
        <Events>
          {awayEvents.map(
            ({
              id,
              matchEventTypeId,
              timeRounded,
              time,
              seconds,
              overtimeRounded,
              person,
              score,
              periodId,
              isStaffEvent,
            }) => (
              <Event
                sportId={sportId}
                key={id}
                matchEventTypeId={matchEventTypeId}
                time={timeRounded || time}
                seconds={seconds}
                overtime={overtimeRounded}
                person={person}
                score={score}
                periodId={periodId}
                personLink={personLink}
                isStaffEvent={isStaffEvent}
              />
            ),
          )}
        </Events>
      </Team>
    </>
  );
};

const Event = ({
  sportId,
  matchEventTypeId,
  time,
  seconds,
  overtime,
  person,
  score,
  periodId,
  personLink,
  isStaffEvent,
}: {
  sportId: any;
  matchEventTypeId: any;
  time: any;
  seconds: any;
  overtime: any;
  person: any;
  score: any;
  periodId: any;
  personLink: any;
  isStaffEvent: boolean;
}) => {
  if (isStaffEvent) return null;

  const normalizeSeconds =
    seconds?.toString().length === 1 ? `0${seconds}` : seconds;
  const eventTime =
    sportId === 2
      ? `${time}:${normalizeSeconds}`
      : overtime
        ? `${time}+${overtime}'`
        : `${time}'`;

  return (
    <StyledEvent>
      <EventType>
        {(matchEventTypeId === MATCH_EVENTS.GOAL ||
          matchEventTypeId === MATCH_EVENTS.OWN_GOAL) && (
          <Score aria-label="Mål:">{score}</Score>
        )}
        {matchEventTypeId === MATCH_EVENTS.PENALTY_MISS && (
          <PenaltyMissIcon
            aria-hidden="true"
            fillColor1="#000"
            fillColor2="#e51c30"
          />
        )}
        {matchEventTypeId === MATCH_EVENTS.RED_CARD && (
          <CardIcon aria-hidden="true" cardId="red" fillColor="#e51c30" />
        )}
        {matchEventTypeId === MATCH_EVENTS.SECOND_YELLOW_CARD && (
          <SecondCardIcon
            aria-hidden="true"
            cardId="second-yellow"
            fillColor1="#e5df1c"
            fillColor2="#e51c30"
          />
        )}
      </EventType>

      <EventPerson>
        {person && (
          <PersonName>
            {personLink && !person?.hiddenPersonInfo
              ? personLink({
                  id: person?.id,
                  name: person?.shirtName || person?.name,
                })
              : person.shirtName || person.name}
          </PersonName>
        )}

        {(matchEventTypeId === MATCH_EVENTS.RED_CARD ||
          matchEventTypeId === MATCH_EVENTS.SECOND_YELLOW_CARD) && (
          <EventDescription>{`(Rødt kort${
            periodId === 1
              ? ' før kamp'
              : periodId === 3
                ? ' i pausen'
                : periodId === 11
                  ? ' etter kamp'
                  : ''
          })`}</EventDescription>
        )}
        {matchEventTypeId === MATCH_EVENTS.PENALTY_MISS && (
          <EventDescription>(Straffebom)</EventDescription>
        )}
        {matchEventTypeId === MATCH_EVENTS.OWN_GOAL && (
          <EventDescription>(Selvmål)</EventDescription>
        )}
      </EventPerson>
      {eventTime && (
        <EventTime aria-label={`Spillerminutt: ${eventTime}`}>
          {eventTime}
        </EventTime>
      )}
    </StyledEvent>
  );
};

export default MatchEvents;
