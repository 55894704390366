import Icon from '../Icon/Icon';

export const PenaltyHandballIcon = (props: any) => (
  <Icon {...props}>
    <path
      d="M22.14,5.44c-0.01-0.73-0.26-1.4-0.65-1.95c-0.08-0.12-0.17-0.23-0.27-0.33c-0.11-0.13-0.23-0.24-0.36-0.35
c-0.07-0.05-0.13-0.1-0.2-0.15C20.08,2.24,19.37,2,18.6,2H4.11C3.28,2,2.51,2.29,1.9,2.78C1.77,2.87,1.64,2.99,1.53,3.11
C0.96,3.7,0.6,4.49,0.56,5.36C0.55,5.42,0.55,5.49,0.55,5.55v8.95h1.5l1.51-1.52l1.49,1.49c0.05-0.18,0.11-0.36,0.17-0.54l-1.3-1.3
l1.6-1.6l0.89,0.89c0.11-0.13,0.22-0.26,0.34-0.38l-0.87-0.87l1.6-1.6l1.17,1.17c0.17-0.08,0.33-0.14,0.51-0.2L7.83,8.71l1.61-1.6
l1.6,1.6L9.95,9.8c0.27-0.06,0.55-0.1,0.83-0.13l0.61-0.61L12,9.67c0.29,0.03,0.58,0.08,0.85,0.15L11.73,8.7l1.6-1.6l1.61,1.6
l-1.35,1.35c0.17,0.06,0.34,0.13,0.5,0.21l1.2-1.2l1.6,1.6l-0.92,0.92c0.12,0.12,0.23,0.25,0.34,0.38l0.93-0.93l1.6,1.6L17.5,14
c0.06,0.16,0.11,0.33,0.15,0.5h0.06l1.52-1.52l1.51,1.52h1.41V5.55C22.15,5.51,22.15,5.48,22.14,5.44z M18.6,3
c0.58,0,1.11,0.2,1.54,0.53l-0.91,0.91L17.78,3H18.6z M20.83,6.75l-1.6,1.61l-1.6-1.6l1.6-1.61L20.83,6.75z M18.87,4.8l-1.6,1.6
l-1.6-1.6l1.6-1.61L18.87,4.8z M16.76,3l-1.45,1.44L13.87,3H16.76z M12.84,3L11.4,4.44L9.95,3H12.84z M8.92,3L7.48,4.44L6.03,3H8.92
z M7.13,4.8L5.52,6.4l-1.6-1.6l1.6-1.61L7.13,4.8z M5.17,6.75L3.56,8.36l-1.6-1.61l1.61-1.6L5.17,6.75z M4.11,3h0.9L3.56,4.44
L2.61,3.49C3.03,3.18,3.55,3,4.11,3z M1.55,5.55c0-0.67,0.26-1.28,0.69-1.73l0.97,0.97L1.61,6.4L1.55,6.35V5.55z M1.55,7.16
l0.06-0.05l1.6,1.6l-1.6,1.61l-0.06-0.06C1.55,10.26,1.55,7.16,1.55,7.16z M1.55,14.29v-3.21l0.06-0.05l1.6,1.6L1.55,14.29z
M3.57,12.28l-1.61-1.61l1.6-1.6l1.61,1.6L3.57,12.28z M5.52,10.32l-1.6-1.61l1.6-1.6l1.61,1.6L5.52,10.32z M7.48,8.36l-1.6-1.6
l1.6-1.61l1.61,1.61L7.48,8.36z M7.83,4.8l1.61-1.61l1.6,1.61l-1.6,1.6L7.83,4.8z M11.4,8.36L9.79,6.75l1.61-1.6l1.6,1.6L11.4,8.36z
M11.75,4.79l1.6-1.6l1.61,1.61l-1.6,1.6L11.75,4.79z M13.71,6.75l1.6-1.6l1.61,1.61l-1.61,1.6L13.71,6.75z M15.67,8.71l1.6-1.6
l1.6,1.6l-1.6,1.6L15.67,8.71z M17.63,10.67l1.6-1.6l1.6,1.6l-1.6,1.6L17.63,10.67z M21.15,14.19l-1.57-1.56l1.57-1.57V14.19z
M21.15,10.28l-1.57-1.57l1.57-1.57V10.28z M21.15,6.37L19.58,4.8l0.93-0.93c0.4,0.45,0.64,1.04,0.64,1.68V6.37z"
    />
    <path
      d="M11.35,10.65c-3.06,0-5.52,2.42-5.52,5.47c0,2.17,1.27,4.04,3.09,4.95c0.74,0.37,1.56,0.58,2.43,0.58
c1.63,0,3.06-0.69,4.06-1.78c0.91-0.99,1.47-2.31,1.47-3.74C16.87,13.12,14.41,10.65,11.35,10.65z M15.69,18.89
c0.1-0.35,0.25-0.99,0-1.63c0.25-0.25,0.69-0.84,0.69-1.68h0.1C16.82,16.91,15.99,18.49,15.69,18.89z M7.26,18.69
c-0.1,0-0.25,0.05-0.35,0.05c-0.15-0.1-0.39-0.39-0.54-1.38c-0.15-0.74-0.2-1.43-0.15-1.68c0-0.02,0-0.06,0.01-0.1
s0.02-0.07,0.04-0.1c0.05-0.1,0.05-0.15,0.1-0.2c0.05,0.05,0.15,0.05,0.25,0.05c0.01,0.07,0.02,0.16,0.04,0.25
c0.06,0.42,0.2,1.04,0.65,1.53C7.11,17.75,7.16,18.34,7.26,18.69z M7.26,13.07c0.05-0.05,0.1-0.1,0.15-0.2
c0.1-0.1,0.2-0.15,0.25-0.2c0,0.15,0,0.35,0.05,0.49c-0.25,0.25-0.89,0.79-1.08,1.73c-0.1-0.05-0.2-0.05-0.25-0.1
C6.42,14.35,6.91,13.51,7.26,13.07z M7.05,15.58c-0.03-0.19-0.04-0.34-0.04-0.39c0.2-1.08,0.99-1.63,1.04-1.68
c0.35-0.15,0.59-0.2,0.84-0.2c0.35,0,0.59,0.1,0.74,0.15c0.05,0.2,0.25,0.64,0.59,1.63c-0.09,0.12-0.2,0.3-0.32,0.49
c-0.28,0.47-0.59,1.05-0.77,1.33c-0.59,0-1.28-0.05-1.53-0.05C7.24,16.51,7.1,15.96,7.05,15.58z M10.12,13.31
c0.25-0.25,1.18-0.84,1.58-0.84s1.18,0.64,1.48,1.04c-0.1,0.25-0.35,0.99-0.54,1.48c-0.64-0.15-1.53-0.1-1.92-0.1
C10.46,14.25,10.17,13.56,10.12,13.31z M7.65,17.31c0.25,0,0.79,0.05,1.18,0.05c0.05,0,0.15,0,0.2,0c0.25,0.49,0.79,1.28,1.04,1.63
c-0.35,0.39-0.69,0.69-0.84,0.74c-0.2-0.05-1.04-0.25-1.58-0.99C7.6,18.59,7.45,17.9,7.65,17.31z M9.53,17.11
c0.27-0.5,0.66-1.15,0.89-1.53c0.06-0.1,0.12-0.19,0.15-0.25c0.3,0,1.38-0.05,1.97,0.1c0.04,0.04,0.08,0.1,0.12,0.15
c0.39,0.49,0.87,1.13,0.92,1.53c-0.15,0.39-0.59,1.28-1.04,1.63c-0.25,0.05-1.23,0.25-2.07,0C10.12,18.3,9.62,17.51,9.53,17.11z
M13.32,15.58c-0.11-0.16-0.22-0.3-0.29-0.39c0.25-0.54,0.49-1.28,0.59-1.58c0.69-0.2,1.28,0,1.38,0.05
c0.64,0.44,0.89,1.58,0.89,1.68c0.01,0.09-0.01,0.16-0.01,0.25c-0.01,0.77-0.4,1.2-0.58,1.33c-0.15,0-0.64,0.05-1.23,0
C13.96,16.49,13.6,15.97,13.32,15.58z M16.38,15.04h-0.05c-0.1-0.44-0.35-1.23-0.99-1.68c0.05-0.15,0.05-0.3,0.05-0.44
C16.04,13.71,16.28,14.6,16.38,15.04z M12.68,11.24c1.18,0.25,1.82,0.94,1.87,0.99c0.35,0.3,0.35,0.69,0.3,0.94
c-0.3-0.1-0.84-0.15-1.38,0c-0.3-0.44-1.04-0.99-1.58-1.08c0-0.25-0.05-0.54-0.05-0.69C12.34,11.14,12.68,11.24,12.68,11.24z
M11.35,11.44c0.05,0.2,0.05,0.49,0.05,0.69c-0.54,0.15-1.33,0.64-1.68,0.99c-0.25-0.25-0.89-0.35-1.58-0.15
c-0.05-0.2,0-0.49,0-0.54c0.39-0.99,1.78-1.09,1.92-1.09h0.05C10.76,11.24,11.15,11.34,11.35,11.44z M8.93,20.66
c-0.2-0.1-0.39-0.25-0.64-0.39c-0.64-0.35-0.99-0.84-1.08-1.09c0.1,0,0.15-0.05,0.25-0.05c0.59,0.74,1.38,0.99,1.73,1.04
c0.05,0.2,0.2,0.54,0.44,0.84C9.38,20.91,9.18,20.81,8.93,20.66z M12.43,20.91c-0.74,0.25-1.97,0.05-2.17,0s-0.39-0.25-0.49-0.49
c-0.1-0.15-0.15-0.3-0.2-0.39c0.3-0.2,0.69-0.59,0.89-0.84c0.35,0.1,0.69,0.1,0.99,0.1c0.49,0,0.94-0.05,1.13-0.1
c0.35,0.54,0.59,0.79,0.74,0.94C12.98,20.81,12.48,20.91,12.43,20.91z M13.47,20.81c0.15-0.15,0.25-0.3,0.35-0.49
c0.15,0,0.35-0.05,0.59-0.1c0.02,0,0.06-0.01,0.1-0.03c-0.29,0.2-0.52,0.34-0.74,0.47C13.67,20.71,13.57,20.76,13.47,20.81z
M15.2,18.89c-0.49,0.99-1.33,0.99-1.48,0.99c-0.1-0.1-0.44-0.49-0.69-0.84c0.49-0.44,0.84-1.23,0.99-1.58c0.59,0.05,1.04,0,1.23,0
C15.49,18.1,15.2,18.84,15.2,18.89z"
    />
  </Icon>
);
