import { COMPONENT_IDS } from '@ntb-sport/constants';
import { useBem } from '@ntb-sport/hooks';
import {
  GIRO_JERSEYS,
  TDF_JERSEYS,
  VUELTA_JERSEYS,
} from '../../ScopeResults/Result/Common/Jerseys/Jerseys';
import './header.css';
import { useTranslation } from 'react-i18next';
import { format, isValid } from 'date-fns';

interface HeaderProps {
  standings: any;
}

export const Header = ({ standings }: HeaderProps) => {
  const bem = useBem(COMPONENT_IDS.SEASON_STANDING);
  const { t } = useTranslation();
  const date = new Date(standings?.metadata?.lastUpdated);
  const isValidDate = isValid(date);

  return (
    <div className={bem('header')}>
      <div className={bem('jerseys')}>
        {standings?.season?.competition?.uuid ===
        'competition-cycling-giro-ditalia'
          ? GIRO_JERSEYS[
              standings.standingInfo.type as keyof typeof GIRO_JERSEYS
            ]
          : standings?.season?.competition?.uuid ===
              'competition-cycling-tour-de-france'
            ? TDF_JERSEYS[
                standings.standingInfo.type as keyof typeof TDF_JERSEYS
              ]
            : standings?.season?.competition?.uuid ===
                'competition-cycling-tour-vuelta-a-espana'
              ? VUELTA_JERSEYS[
                  standings.standingInfo.type as keyof typeof VUELTA_JERSEYS
                ]
              : null}
      </div>
      <div
        className={bem('header-name')}
      >{`${standings?.standingInfo?.name} ${standings?.season?.name}`}</div>
      {isValidDate && (
        <div
          className={bem('header-last-updated')}
        >{`${t('lastUpdated')}: ${format(date, 'dd.MM.yyyy')}`}</div>
      )}
      <div
        className={bem('header-event-count')}
      >{`Etter ${standings?.season?.finishedEvents?.length} av ${standings?.season?.totalEvents?.length} øvelser`}</div>
    </div>
  );
};
