import {
  COMPONENT_IDS,
  ELEMENT_IDS,
  MATCH_STATUS,
  MATCH_STATUS_ID,
} from '@ntb-sport/constants';
import { CSS_VARIABLES } from '../index';

export const TEAM_FIXTURES_MATCH_STYLES = [
  {
    name: 'Root',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_FIXTURES_MATCH}'] `,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Match score (Not started)',
    selector: `[data-style-id='default'] [data-component-id='${
      COMPONENT_IDS.TEAM_FIXTURES_MATCH
    }'] [data-element-id='${
      ELEMENT_IDS.SCORE
    }'] [data-status-id='${MATCH_STATUS_ID[
      MATCH_STATUS.NOT_STARTED
    ]?.toLowerCase()}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Match score (Played)',
    selector: `[data-style-id='default'] [data-component-id='${
      COMPONENT_IDS.TEAM_FIXTURES_MATCH
    }'] [data-element-id='${
      ELEMENT_IDS.SCORE
    }'] [data-status-id='${MATCH_STATUS_ID[
      MATCH_STATUS.PLAYED
    ]?.toLowerCase()}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Match score (In Progress)',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_FIXTURES_MATCH}'] [data-element-id='${ELEMENT_IDS.SCORE}'] [data-status-id='in_progress']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Match score (Cancelled)',
    selector: `[data-style-id='default'] [data-component-id='${
      COMPONENT_IDS.TEAM_FIXTURES_MATCH
    }'] [data-element-id='${
      ELEMENT_IDS.SCORE
    }'] [data-status-id='${MATCH_STATUS_ID[
      MATCH_STATUS.CANCELLED
    ]?.toLowerCase()}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Match score (Will not be played)',
    selector: `[data-style-id='default'] [data-component-id='${
      COMPONENT_IDS.TEAM_FIXTURES_MATCH
    }'] [data-element-id='${
      ELEMENT_IDS.SCORE
    }'] [data-status-id='${MATCH_STATUS_ID[
      MATCH_STATUS.WILL_NOT_BE_PLAYED
    ]?.toLowerCase()}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
  {
    name: 'Match score (Postponed)',
    selector: `[data-style-id='default'] [data-component-id='${
      COMPONENT_IDS.TEAM_FIXTURES_MATCH
    }'] [data-element-id='${
      ELEMENT_IDS.SCORE
    }'] [data-status-id='${MATCH_STATUS_ID[
      MATCH_STATUS.POSTPONED
    ]?.toLowerCase()}']`,
    variables: [
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR,
    ],
  },
];
