import Icon from '../Icon/Icon';

export const TwoMinuteSuspensionIcon = (props: any) => (
  <Icon {...props}>
    <g>
      <path
        d="M18.2,3.2c0.2-0.9-0.5-1.8-1.5-2c-0.9-0.2-1.7,0.5-2,1.3l-1.4,5.6L12,1.9C11.7,0.7,10.3,0,9.2,0.8c-0.6,0.4-1,1.2-0.9,2
l1,5c0,0-0.4-0.1-0.5-0.1c-0.3,0-0.5,0-0.7,0.1C7.5,7.9,7,8.4,6.9,9C6.6,9,6.3,9,6,9.1C5.4,9.5,5,9.8,4.7,10.4
c-0.3,0.5-0.3,1.2-0.1,1.7L4.9,13c-0.1,3.4,0.7,6,2.2,7.5c1.1,1.2,2.6,1.7,4.5,1.7c1.3,0,2.4-0.3,3.3-0.7c0.9-0.5,1.7-1.3,2.4-2.3
c1.1-1.7,0.9-3.2,0.5-5.1c-0.1-0.6-0.3-1.1-0.4-1.5c-0.2-1.4-0.1-3.1-0.1-3.4C17.2,9.4,18.2,3.2,18.2,3.2z M8.4,8.7
c0.1,0,0.3-0.1,0.4-0.1c0.3,0,0.5,0.1,0.7,0.3l0.3,1.4c-0.5,0.5-0.4,1.3-0.3,1.9c0.2,0.5,0.5,1.1,0.9,1.5c0.1,0,0.6,0.5,0.6,0.5
c-0.1,0.2-0.4,0.4-0.5,0.5c-0.4,0.1-0.7,0.1-1-0.1C9.3,14.4,9.1,14.2,9,14l-1.4-3.8C7.6,10,7.6,9.8,7.6,9.7C7.8,9.3,8,8.9,8.4,8.7z
M5.6,10.8c0.2-0.4,0.5-0.5,0.7-0.6c0.1-0.1,0.3-0.1,0.5-0.1c0,0,0.3,0.8,0.3,0.9c0.2,0.4,0.3,0.8,0.5,1.2c0.3,0.7,0.5,1.4,0.7,2.1
l0,0c0.1,0.3,0.3,0.5,0.5,0.7c-0.2,0.1-0.3,0.2-0.5,0.3c-0.7,0.3-1.5-0.1-1.7-0.8l-0.6-1.7l-0.4-1C5.4,11.4,5.4,11.1,5.6,10.8z
M16.4,18.7c-1.1,1.7-2.6,2.5-4.8,2.5c-1.6,0-2.9-0.5-3.8-1.5c-0.9-1-1.5-2.4-1.8-4.3C6.4,16,7,16.2,7.7,16.2
c0.6,0,1.3-0.3,1.7-0.7c0.5,0.2,1,0.2,1.4,0c0.5-0.2,0.9-0.5,1.1-0.9c0.1,0,0.1,0,0.2,0.1c-1.2,1.2-1.4,3.3-1.5,3.4
c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0,0,0.1-0.8,0.4-1.6c0.2-0.5,0.5-1.1,1-1.5c0.1,0.7,0.4,1.5,0.5,1.7
c0.1,0.3,0.4,0.4,0.6,0.3c0.3-0.1,0.4-0.4,0.3-0.6c-0.2-0.6-0.5-1.5-0.4-1.9c0.1-0.3-0.2-0.5-0.5-0.5c-0.6,0-1.3-0.2-1.8-0.5
c-0.5-0.3-0.8-0.6-1-1.1c-0.2-0.4-0.3-0.7-0.3-1.1c0-0.4,0.4-0.4,0.6-0.4c1.3,0.3,4.1,1.1,4.6,1.4c0.4,0.2,0.6,0.4,0.8,0.7l0,0
c0.1,0.3,0.3,0.7,0.4,1.3c0.2,0.8,0.3,1.5,0.3,2.2C17.1,17.2,16.9,17.9,16.4,18.7z M16.3,9.3L16.3,9.3c0,0.1-0.1,1.2,0,2.4
c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c-0.7-0.4-3.6-1.2-4.8-1.5c-0.2,0-0.4-0.1-0.5,0l-0.1-0.5l-0.2-0.8l-1.2-6
c-0.1-0.5,0.3-1,0.7-1.1c0.5-0.1,1,0.3,1.1,0.7l1.5,6.8c0.1,0.4,0.4,0.6,0.7,0.6s0.7-0.3,0.7-0.6l1.5-6.3c0.1-0.4,0.5-0.6,0.9-0.5
c0.5,0.1,0.7,0.5,0.6,0.9L16.3,9.3z"
      />
    </g>
  </Icon>
);
