import {
  Row,
  Cell,
  FlexWrapper,
  HeaderCell,
  SortArrowDown,
  SortArrowUp,
  SortArrows,
  StyledTable,
} from './Table.styled';
import { useSortBy, useTable } from 'react-table';

import { MatchesType, NifsFairPlayTableClub } from '@ntb-sport/types';
import { Skeleton } from '../../chakra';

interface TableProps {
  columns: any;
  data: NifsFairPlayTableClub[];
  isLoading: boolean;
  matches?: MatchesType;
}

const Table: React.FC<TableProps> = ({ columns, data, isLoading, matches }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          hiddenColumns: columns.reduce((hiddenColumns: any, column: any) => {
            if (!column.isVisible) {
              hiddenColumns.push(column.accessor);
            }
            return hiddenColumns;
          }, []),
        },
      },
      useSortBy,
    );

  return (
    <StyledTable {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <HeaderCell
                key={column.id}
                $matches={matches}
                $isSorted={column.isSorted}
                {...column.styles}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <FlexWrapper $matches={matches} {...column.styles}>
                    {column.render('Header')}
                    {column.isSortable && (
                      <SortArrows>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortArrowUp />
                          ) : (
                            <SortArrowDown />
                          )
                        ) : (
                          <>
                            <SortArrowUp />
                            <SortArrowDown />
                          </>
                        )}
                      </SortArrows>
                    )}
                  </FlexWrapper>
                )}
              </HeaderCell>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any) => {
          prepareRow(row);
          return (
            <Row key={row.id} {...row.getRowProps()}>
              {row.cells.map((cell: any) => (
                <Cell
                  key={cell.id}
                  $matches={matches}
                  $isSorted={cell.column.isSorted}
                  {...cell.column.styles}
                  {...cell.getCellProps()}
                >
                  {isLoading ? <Skeleton /> : cell.render('Cell')}
                </Cell>
              ))}
            </Row>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

export default Table;
