import styled, { css, keyframes } from 'styled-components';

import CountryFlag from '../../../../common/CountryFlag/CountryFlag';

interface StyledProps {
  $isGoal?: any;
  $isDissalowedGoal?: any;
  $showDivider?: boolean;
  $isClickable?: boolean;
}

const pulseGoal = keyframes`
 0% {
    background-color: transparent;
  }
  50% {
    background-color:var(--base-colors-success-light);
  }
  100% {
    background-color: transparent;
  }
`;

const pulseDissalowedGoal = keyframes`
 0% {
    background-color: transparent;
  }
  50% {
    background-color: var(--base-colors-error-light);
  }
  100% {
    background-color: transparent;
  }
`;

export const Wrapper = styled.div<StyledProps>`
  display: grid;
  align-items: center;
  grid-template-areas:
    'info info'
    'match settings';
  grid-template-columns: 1fr 24px;
  grid-row-gap: var(--ntb-space-1);
  padding: var(--ntb-space-2);
  background-color: var(--background-color);
  color: var(--font-color);

  border-bottom-width: var(--ntb-space-px);
  border-bottom-style: solid;
  border-bottom-color: var(--border-color);

  &:hover {
    background-color: var(--background-color__hover);
    color: var(--font-color__hover);
  }

  ${(props) =>
    props?.$isGoal &&
    css`
      animation-name: ${pulseGoal};
      animation-duration: 1s;
      animation-iteration-count: infinite;
    `}

  ${(props) =>
    props.$isDissalowedGoal &&
    css`
      animation-name: ${pulseDissalowedGoal};
      animation-duration: 1s;
      animation-iteration-count: infinite;
    `}
`;

export const TeamLogo = styled.img`
  min-width: 24px;
  max-width: 24px;
`;

export const Flag = styled(CountryFlag)`
  min-width: 24px;
  max-width: 24px;
`;

export const Match = styled.div<StyledProps>`
  display: grid;
  align-items: center;
  grid-template-areas:
    'status score homeTeam'
    'status score awayTeam'
    '. eeo additionalScores';
  grid-template-columns: 40px 34px auto;
  grid-column-gap: var(--ntb-space-3);
  grid-row-gap: var(--ntb-space-1);
  cursor: ${(props) => props?.$isClickable && 'pointer'};
  padding-bottom: var(--ntb-space-1);

  transition: all ease-in-out 200ms;
`;

export const MatchStatus = styled.div<StyledProps>`
  grid-area: status;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);
  text-align: center;
  color: var(--font-color);
`;

export const MatchScore = styled.div`
  grid-area: score;
  display: grid;
  grid-template-rows: 1fr;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-semibold);
  background-color: var(--background-color);
  color: var(--font-color);
  border-radius: var(--ntb-radii-md);
  border-width: 1px;
  border-style: solid;
  border-color: var(--border-color, 'transparent');
`;

export const MatchScoreHomeTeam = styled.div`
  width: 100%;
  text-align: center;
  padding: var(--ntb-space-2);
  box-shadow: inset 0 -1px 0 var(--border-color);
`;
export const MatchScoreAwayTeam = styled.div`
  width: 100%;
  text-align: center;
  padding: var(--ntb-space-2);
`;

export const Innings = styled.div`
  grid-area: eeo;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);
  text-align: center;
`;

export const Broadcast = styled.div`
  display: flex;
  align-items: center;
  line-height: 20px;

  span {
    margin-top: 2px;
  }

  svg {
    margin-right: 3px;
  }
`;

export const AdditionalScores = styled.div`
  grid-area: additionalScores;
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--ntb-space-2);
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);
`;

export const AdditionalScore = styled.div``;

export const HomeTeam = styled.div`
  grid-area: homeTeam;
`;

export const AwayTeam = styled.div`
  grid-area: awayTeam;
`;

export const TeamName = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-medium);
`;

export const Info = styled.div`
  grid-area: info;
  display: flex;
  align-items: center;
  justify-self: end;
  min-height: var(--ntb-space-2);
  column-gap: var(--ntb-space-2);
`;

export const Hosted = styled.div<StyledProps>`
  font-size: var(--ntb-fontSizes-2xs);
  font-weight: var(--ntb-fontWeights-light);

  ${(props) =>
    props.$showDivider &&
    css`
      &:before {
        content: '|';
        padding-right: var(--ntb-space-1);
      }
    `}
`;

export const TVChannel = styled.div<StyledProps>`
  font-size: var(--ntb-fontSizes-2xs);
  font-weight: var(--ntb-fontWeights-light);

  ${(props) =>
    props.$showDivider &&
    css`
      &:before {
        content: '|';
        padding-right: var(--ntb-space-1);
      }
    `}
`;

export const Stream = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
  font-size: var(--ntb-fontSizes-2xs);
  font-weight: var(--ntb-fontWeights-light);

  ${(props) =>
    props.$showDivider &&
    css`
      &:before {
        content: '|';
        padding-right: var(--ntb-space-1);
      }
    `}
`;

export const MatchType = styled.div`
  font-size: var(--ntb-fontSizes-2xs);
  font-weight: var(--ntb-fontWeights-light);
`;

export const Settings = styled.div`
  grid-area: settings;
`;

export const LinkBox = styled.div``;
