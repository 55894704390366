import { NifsRefereeType } from '@ntb-sport/types';
import CountryFlag from '../../common/CountryFlag/CountryFlag';
import Gender from '../../common/Gender/Gender';
import * as S from './Referees.styled';

const REFEREE_TYPE_ID_TO_NORWEGIAN_NAME = {
  1: 'Hoveddommer',
  2: 'Assistentdommere',
  4: 'Fjerdedommer',
  5: '',
  6: 'VAR-dommere',
  7: 'Repriseoperatør',
  8: 'VAR-supervisor',
  9: 'Dommerveileder',
  10: 'Kampdelegat',
  11: 'Mentor',
  12: 'Sikkerhetsdelegat',
  13: 'Assistentdommer',
  14: 'Assisterende VAR-dommer',
  15: '',
  16: 'Mentor 2',
};

function groupRefereesByType(referees: NifsRefereeType[]) {
  const refereesByType = referees?.reduce(
    (acc: any, referee: NifsRefereeType) => {
      acc[referee?.refereeTypeId]?.referees?.push(referee);

      return acc;
    },
    {
      1: { name: REFEREE_TYPE_ID_TO_NORWEGIAN_NAME[1], referees: [] },
      2: { name: REFEREE_TYPE_ID_TO_NORWEGIAN_NAME[2], referees: [] },
      4: { name: REFEREE_TYPE_ID_TO_NORWEGIAN_NAME[4], referees: [] },
      6: { name: REFEREE_TYPE_ID_TO_NORWEGIAN_NAME[6], referees: [] },
    },
  );

  return refereesByType;
}

export const Referees = ({ referees }: { referees: NifsRefereeType[] }) => {
  const refereesByType = groupRefereesByType(referees);
  const groupsWithReferees = Object.values(refereesByType).filter((refereeGroup: any) => {
    return refereeGroup?.referees?.length > 0;
  });

  return (
    <>
      {groupsWithReferees.map((refereeGroup: any) => (
        <div key={refereeGroup.id}>
          {refereeGroup.name && (
            <S.Header>{refereeGroup.name}</S.Header>
          )}

          {refereeGroup.referees.map((referee: NifsRefereeType) => (
            <S.Referee key={referee.id}>
              <S.RefereeName>
                {referee.name} <Gender gender={referee?.gender} />
              </S.RefereeName>
              <S.RefereeCountry>
                <CountryFlag countryName={referee?.country?.name} />
                <span>{referee?.country?.name}</span>
              </S.RefereeCountry>
            </S.Referee>
          ))}
        </div>
      ))}
    </>
  );
};
