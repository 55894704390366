import { Icon } from '@chakra-ui/react';
import { TransferIconsInterface } from './utils/TransferIconsInterface';

export const UnknownTeamLogoIcon = (props: TransferIconsInterface) => {
  return (
    <Icon aria-hidden="true" w={props?.w} h={props?.h}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29" fill="none">
        <g mask="url(#mask0_288_438)">
          <path
            d="M14 29C12.069 29 10.2519 28.618 8.54884 27.8541C6.84581 27.0902 5.36398 26.0556 4.10347 24.75C2.8429 23.4444 1.84388 21.9098 1.10635 20.1459C0.368776 18.382 0 16.5 0 14.5C0 12.5 0.368776 10.618 1.10635 8.8542C1.84388 7.09031 2.8429 5.55556 4.10347 4.25C5.36398 2.94444 6.84581 1.90975 8.54884 1.14586C10.2519 0.381945 12.069 0 14 0C15.9311 0 17.7481 0.381945 19.4512 1.14586C21.1543 1.90975 22.636 2.94444 23.8966 4.25C25.1571 5.55556 26.1561 7.09031 26.8937 8.8542C27.6312 10.618 28 12.5 28 14.5C28 16.5 27.6312 18.382 26.8937 20.1459C26.1561 21.9098 25.1571 23.4444 23.8966 24.75C22.636 26.0556 21.1543 27.0902 19.4512 27.8541C17.7481 28.618 15.9311 29 14 29ZM21.3218 11.5833L24.2989 10.5833L25.1034 7.58333C24.2452 6.11111 23.1456 4.86111 21.8046 3.83333C20.4636 2.80556 18.9617 2.05556 17.2988 1.58333L14.5632 3.58333V6.66667L21.3218 11.5833ZM6.67816 11.5833L13.4368 6.66667V3.58333L10.7012 1.58333C9.0383 2.05556 7.54982 2.80556 6.23564 3.83333C4.92146 4.86111 3.83526 6.11111 2.97703 7.58333L3.78159 10.5833L6.67816 11.5833ZM4.42528 23.5L7.16091 23.25L8.85056 20.4167L6.43681 12.8333L3.37931 11.75L1.12644 13.4167C1.12644 15.4167 1.35441 17.2083 1.81036 18.7917C2.26631 20.375 3.13791 21.9444 4.42528 23.5ZM14 27.8333C14.6974 27.8333 15.4081 27.7709 16.1322 27.6459C16.8563 27.5209 17.6207 27.3333 18.4253 27.0833L19.8736 23.8333L18.3448 21.25H9.65519L8.20688 23.8333L9.65519 27.0833C10.3793 27.3333 11.1102 27.5209 11.8477 27.6459C12.5853 27.7709 13.3027 27.8333 14 27.8333ZM9.977 20.0833H18.1035L20.5173 12.4167L14 7.66667L7.48278 12.4167L9.977 20.0833ZM23.5747 23.5C24.8621 21.9444 25.7338 20.375 26.1896 18.7917C26.6456 17.2083 26.8736 15.4167 26.8736 13.4167L24.7012 11.8333L21.5632 12.75L19.1494 20.4167L20.8391 23.25L23.5747 23.5Z"
            fill="currentColor"
          />
          <path
            d="M9.977 20.0833H18.1035L20.5173 12.4167L14 7.66667L7.48278 12.4167L9.977 20.0833Z"
            fill={props?.color ? props.color : 'currentColor'}
          />
        </g>
      </svg>
    </Icon>
  );
};
