import Icon from '../Icon/Icon';

export const AlpineSportIcon = (props: any) => (
  <Icon viewBox="0 0 28.3 28.3" {...props}>
    <g>
      <g>
        <path
          d="M24.9,23.3c-0.2,0.3-0.5,0.5-0.8,0.5c-0.2,0-0.3,0-0.5-0.1l-19.8-11c-0.4-0.3-0.6-0.8-0.4-1.3
			c0.3-0.4,0.8-0.6,1.3-0.4l7,3.9c0.2-0.4,0.5-0.7,1-0.8c0.6-0.1,1.1-0.3,1.7-0.5c0.5-0.2,1-0.4,1.4-0.5c-2.3-1.6-5.5-4.2-4.8-6.5
			L8.3,5.1C8.1,5,8.1,4.8,8.2,4.7c0.1-0.2,0.3-0.2,0.5-0.1l2.6,1.5c1.1-1.6,4-1,6.4-0.4c0.7,0.2,1.2,1,1,1.7c-0.2,0.7-1,1.2-1.7,1
			c-0.2-0.1-0.4-0.1-0.6-0.2c-0.1,0.3-0.2,0.5-0.2,0.8l2.5,1.5c0.5,0.1,1,0.2,1.7,0.2c0.6,0,1.1,0.6,1.1,1.2c0,0.6-0.6,1.1-1.2,1.1
			c0,0-0.1,0-0.1,0c-0.4,0-0.8-0.1-1.2-0.1c0,0.3,0,0.5,0,0.7c-0.3,1.5-2.3,2.4-4.4,3l9.9,5.5C25,22.3,25.1,22.9,24.9,23.3z"
        />
      </g>
      <g>
        <circle cx="21.4" cy="7.4" r="2.4" />
      </g>
    </g>
  </Icon>
);
