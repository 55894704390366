import { useQuery } from '@tanstack/react-query';

import { fetchNifsTeams, FetchNifsTeams } from './fetch';
import { QUERY_KEYS } from '@ntb-sport/constants';

interface UseQueryNifsTeams {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: FetchNifsTeams;
  options?: Record<string, unknown>;
}

export const useQueryNifsTeams = ({
  queryKey = QUERY_KEYS.NIFS_TEAMS,
  queryKeyParams,
  params,
  options,
}: UseQueryNifsTeams) => {
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchNifsTeams(params),
    enabled: Boolean(params.sportGroup) && Boolean(params.query?.length >= 3),
    ...options,
  });

  return { data, isLoading, error, isFetching };
};
