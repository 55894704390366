import Kit from './Kit';
import ShirtNumber from './ShirtNumber';

const TwoColorVariableVerticalStripes = ({
  kitColor1 = '#f79025',
  kitColor2 = '#adadad',
  shirtNumber,
  shirtNumberColor,
  shirtNumberBackgroundColor,
  shirtNumberBackgroundOpacity,
  ...restProps
}: {
  size?: any;
  kitColor1: any;
  kitColor2: any;
  kitColor3: any;
  shirtNumber: any;
  shirtNumberColor: any;
  shirtNumberBackgroundColor: any;
  shirtNumberBackgroundOpacity: any;
}) => {
  const shadow = '#000000';

  return (
    <Kit {...restProps}>
      <g>
        <g>
          <g>
            <path
              style={{ fill: kitColor1 }}
              d="M69.15,2.82c2.77,0,7.29-0.36,8.68-0.53c1.38-0.17,3.61,0.2,4.68,0.53l2.08,3.19
                        c2.26,0.53,11.11,2.66,12.85,3.37c1.74,0.71,4,1.77,5.38,1.94c1.39,0.19,3.47,0.72,4.86,1.24c1.38,0.54,4.69,3.02,5.9,4.8
                        c1.21,1.77,8.24,16.3,9.8,18.62c1.57,2.3,12.42,22.34,12.94,23.23c0.52,0.88,1.03,3.19,0.17,3.36c-0.87,0.19-8.33,3.37-11.46,6.03
                        c-3.13,2.67-9.03,5.68-9.71,6.03c-0.71,0.35-1.14,0.14-1.83-0.58c-0.69-0.7-7.73-12.01-7.73-12.01c-1.21,2.66-1.21,5.67-1.04,7.62
                        c0.18,1.96-0.87,11.8-0.35,14.63c0.52,2.84,0.52,30.33,0.87,32.82c0.35,2.48,0.86,7.44,0.69,9.93c-0.18,2.48,0,5.67,0,7.09
                        c0,1.43-0.18,2.67-1.56,3.2c-1.39,0.53-13.89,3.9-35.24,3.19c-21.35,0.71-33.85-2.66-35.23-3.19c-1.38-0.53-1.56-1.77-1.56-3.2
                        c0-1.41,0.18-4.61,0-7.09c-0.18-2.49,0.35-7.45,0.69-9.93c0.35-2.49,0.35-29.98,0.87-32.82c0.51-2.83-0.52-12.68-0.35-14.63
                        c0.18-1.95,0.18-4.96-1.04-7.62c0,0-7.3,11.53-7.99,12.24c-0.69,0.71-0.87,0.71-1.56,0.35c-0.7-0.35-6.59-3.36-9.73-6.03
                        c-3.12-2.66-10.59-5.84-11.45-6.03c-0.87-0.17-0.35-2.48,0.17-3.36C2.5,58.33,13.43,38.47,15,36.16c1.56-2.32,8.5-17.04,9.72-18.8
                        c1.22-1.78,4.51-4.26,5.91-4.8c1.39-0.53,3.46-1.05,4.86-1.24c1.39-0.17,3.63-1.28,5.38-1.94c1.97-0.74,10.83-2.9,12.84-3.37
                        l2.08-3.19c0.87-0.35,3.3-0.71,4.69-0.53C61.86,2.46,66.38,2.82,69.15,2.82z"
            />
          </g>
          <path
            style={{ fill: kitColor2 }}
            d="M26.24,71.97c0,0-0.79,0.44-1.49,0.09c-0.68-0.36-6.58-3.37-9.71-6.03C11.91,63.37,4.45,60.18,3.58,60
                    c-0.86-0.18-1-1.7-1-1.7s-0.35,0.73-0.46,0.92c-0.51,0.89-1.03,3.19-0.17,3.37c0.87,0.18,8.33,3.37,11.46,6.03
                    c3.13,2.66,9.03,5.68,9.71,6.03c0.7,0.35,0.96,0.42,1.57-0.36C25.28,73.51,26.24,71.97,26.24,71.97z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M112.08,72.07c0,0,0.79,0.44,1.49,0.09c0.68-0.36,6.58-3.37,9.71-6.03c3.13-2.66,10.8-5.97,11.67-6.15
                    c0.86-0.18,1-1.7,1-1.7s0.35,0.73,0.46,0.92c0.51,0.89,1.03,3.19,0.17,3.37c-0.87,0.18-8.64,3.61-11.77,6.27
                    c-3.13,2.66-8.43,5.35-9.11,5.71c-0.7,0.35-1.25,0.52-1.86-0.26C113.25,73.5,112.08,72.07,112.08,72.07z"
          />
          <polygon
            style={{ fill: kitColor2 }}
            points="46.27,139.77 43.69,139.45 43.69,8.54 46.27,7.78 	"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M84.37,140.59H72.32V17.58l5.79-2.85l2.89,4.15c0,0,0.81-0.62,1.61-1.7c1.21-1.63,1.78-3.95,1.78-3.95
                    V140.59z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M54.01,140.59h12.06v-123l-5.42-2.7l-4.27,3.99c0,0-0.57-0.5-1.31-1.61c-0.92-1.38-1.05-2.4-1.05-2.4
                    V140.59z"
          />
          <polygon
            style={{ fill: kitColor2 }}
            points="93.93,139.45 91.44,139.76 91.44,7.64 93.93,8.26 	"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M34.5,67.6c0.12,3.25,0.82,10.19,0.63,13.98c-0.21,4.04-0.35,32.28-0.7,35.77
                    c-0.35,3.5-0.86,10.46-0.59,13.94c0.17,2.21,0.17,4.35,0.17,6.48c-0.56-0.15-0.75-0.17-1.43-1.82c-0.42-1.02-0.08-2.76-0.23-4.83
                    c-0.28-3.48,0.24-10.58,0.6-14.09c0.2-2.12,0.39-14.25,0.66-24.15c0.17-6.4,0.5-11.69,0.47-13.29c-0.03-1.86-0.11-3.04-0.28-5.41
                    c-0.35-4.78,0.01-5.41-0.19-7.69c-0.25-2.91-0.79-4.12-0.92-4.38c0.04,0.04,0.09,0.12,0.17,0.24l0.03-50.53l1.34-0.39L34.2,65.54
                    C34.36,66.15,34.47,66.85,34.5,67.6z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M105,67.07c-0.13,1.79,0.07,2.86-0.01,4.97c-0.12,2.89-0.63,6.86-0.56,8.64c0.01,0.7,0.17,1.08,0.21,1.98
                    c0.17,4.35,0.39,32.1,0.76,35c0.47,3.68,0.94,8.53,0.82,14.02c-0.05,2.09-0.01,3.62-0.56,4.71c-0.54,1.1-0.63,1.38-1.18,1.51
                    c-0.01-2.12,0.08-4.1,0.25-6.31c0.27-3.48-0.24-10.45-0.59-13.94c-0.35-3.5-0.55-32.83-0.91-36.86c-0.24-2.92,0.37-8.65,0.68-12.91
                    c0.09-0.84,0.27-1.59,0.47-2.25l-0.11-54.08l1.34,0.35l0.11,50.53L105,67.07z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M84.92,5.12l-2.45-2.88l-3.46-0.63l-9.78,0.63l-9.64-0.63l-4.23,0.63l-1.63,2.88
                    c-0.88,1.63-0.84,9.7,2.62,13.08c0.13,0.12,4.08-3.83,4.08-3.83s7.85,3.91,8.76,4.18c0.91-0.27,9.09-4.37,9.09-4.37
                    s2.6,3.96,2.75,3.84C84.88,14.91,85.79,6.74,84.92,5.12z M78.78,11.03c-4.03,1.85-9.63,6.13-9.63,6.13s-5.6-4.29-9.63-6.13
                    c-2.52-1.16-3.87-3.46-4.18-5.4c1.06-0.32,3.91-0.64,5.21-0.47c1.39,0.17,5.83,0.54,8.6,0.54c2.79,0,7.21-0.36,8.6-0.54
                    c1.3-0.17,4.15,0.15,5.21,0.5C82.64,7.6,81.29,9.86,78.78,11.03z"
          />
          <g>
            <path
              style={{ fill: shadow }}
              d="M82.96,5.66c-0.32,1.94-1.67,4.21-4.18,5.37c-4.03,1.85-9.63,6.13-9.63,6.13s-5.6-4.29-9.63-6.13
                        c-2.52-1.16-3.87-3.46-4.18-5.4c1.06-0.32,3.91-0.64,5.21-0.47c1.39,0.17,5.83,0.54,8.6,0.54c2.79,0,7.21-0.36,8.6-0.54
                        C79.05,4.99,81.9,5.31,82.96,5.66z"
            />
            <path
              opacity="0.16"
              d="M136.57,62.39c0.86-0.18,0.35-2.48-0.17-3.37c-0.3-0.51-3.93-7.08-7.31-13.12
                        c-6.25,2.94-14.52-0.07-23.84-13.57l0.34,9.94l0.12,19.66c0,0,7.03,11.31,7.73,12.01c0.69,0.71,1.25,0.86,1.95,0.51
                        c0.68-0.36,6.58-3.37,9.71-6.03C128.24,65.76,135.7,62.57,136.57,62.39z"
            />
            <path
              opacity="0.16"
              d="M1.7,62.52c-0.86-0.18-0.34-2.48,0.18-3.37c0.29-0.51,3.92-7.08,7.31-13.12
                        c6.25,2.94,15.6-0.46,24.23-14.08l-0.57,13.53l-0.01,16.45c0,0-7.58,11.94-8.27,12.64c-0.7,0.71-1,0.35-1.69,0.01
                        c-0.69-0.36-6.59-3.37-9.72-6.03C10.04,65.88,2.57,62.69,1.7,62.52z"
            />
            <path
              opacity="0.16"
              d="M104.47,137.8c1.38-0.53,1.56-1.77,1.56-3.19c0-1.42-0.18-4.61,0-7.09c0.16-2.3-0.27-6.7-0.6-9.32
                        c-5.17,4.71-8.97,14.72-10.63,21.6C100.47,138.99,103.79,138.06,104.47,137.8z"
            />
            <path
              opacity="0.16"
              d="M33.74,137.74c-1.38-0.53-1.56-1.77-1.56-3.19c0-1.42,0.18-4.61,0-7.09c-0.15-2.3,0.27-6.7,0.61-9.32
                        c5.16,4.71,8.96,14.72,10.63,21.6C37.75,138.92,34.42,137.99,33.74,137.74z"
            />
            <g>
              <path
                d="M59.63,2.24c0.3,0,0.59,0.01,0.84,0.05c1.39,0.18,5.91,0.53,8.68,0.53s7.29-0.36,8.68-0.53c0.24-0.03,0.5-0.04,0.78-0.04
                            c1.33,0,3.01,0.3,3.9,0.58l2.08,3.19c2.26,0.53,11.11,2.66,12.85,3.37c1.74,0.71,4,1.77,5.38,1.94c1.39,0.19,3.47,0.72,4.86,1.24
                            c1.38,0.54,4.69,3.02,5.9,4.8c1.21,1.77,8.24,16.3,9.8,18.62c1.57,2.3,12.42,22.34,12.94,23.23c0.52,0.88,1.03,3.19,0.17,3.36
                            c-0.87,0.19-8.33,3.37-11.46,6.03c-3.13,2.67-9.03,5.68-9.71,6.03c-0.22,0.11-0.42,0.17-0.61,0.17c-0.4,0-0.75-0.25-1.22-0.74
                            c-0.69-0.7-7.73-12.01-7.73-12.01c-1.21,2.66-1.21,5.67-1.04,7.62c0.18,1.96-0.87,11.8-0.35,14.64
                            c0.52,2.84,0.52,30.33,0.87,32.82c0.35,2.48,0.86,7.44,0.69,9.93c-0.18,2.48,0,5.67,0,7.09c0,1.43-0.18,2.67-1.56,3.2
                            c-1.26,0.48-11.64,3.29-29.44,3.29c-1.85,0-3.78-0.03-5.8-0.1c-2.01,0.07-3.94,0.1-5.8,0.1c-17.81,0-28.18-2.81-29.44-3.29
                            c-1.38-0.53-1.56-1.77-1.56-3.2c0-1.41,0.18-4.61,0-7.09c-0.18-2.49,0.35-7.45,0.69-9.93c0.35-2.49,0.35-29.98,0.87-32.82
                            c0.51-2.83-0.52-12.68-0.35-14.64c0.18-1.95,0.18-4.96-1.04-7.62c0,0-7.3,11.53-7.99,12.24c-0.4,0.42-0.63,0.59-0.89,0.59
                            c-0.18,0-0.38-0.09-0.67-0.23c-0.7-0.35-6.59-3.36-9.73-6.03c-3.12-2.66-10.59-5.84-11.45-6.03c-0.87-0.17-0.35-2.48,0.17-3.36
                            C2.5,58.33,13.43,38.47,15,36.16c1.56-2.32,8.5-17.04,9.72-18.8c1.22-1.78,4.51-4.26,5.91-4.8c1.39-0.53,3.46-1.05,4.86-1.24
                            c1.39-0.17,3.63-1.28,5.38-1.94c1.97-0.74,10.83-2.9,12.84-3.37l2.08-3.19C56.5,2.53,58.26,2.24,59.63,2.24 M59.63,1.24
                            c-1.44,0-3.34,0.29-4.22,0.65c-0.19,0.08-0.35,0.21-0.46,0.38l-1.86,2.86C50.73,5.69,42.49,7.7,40.51,8.44
                            c-0.54,0.2-1.13,0.45-1.7,0.69c-1.22,0.51-2.61,1.09-3.45,1.2c-1.52,0.21-3.66,0.76-5.09,1.3c-1.65,0.64-5.1,3.31-6.38,5.17
                            c-0.62,0.9-2.42,4.52-4.5,8.72c-2.08,4.19-4.44,8.93-5.22,10.09C12.96,37.39,6.48,49.06,3,55.33c-1.03,1.85-1.77,3.18-1.89,3.39
                            c-0.45,0.78-1.11,2.8-0.57,3.97c0.21,0.47,0.6,0.78,1.08,0.87c0.67,0.16,7.95,3.22,10.99,5.81c3.22,2.75,9.25,5.82,9.93,6.17
                            c0.35,0.18,0.7,0.34,1.12,0.34c0.69,0,1.15-0.42,1.61-0.89c0.59-0.6,4.68-6.98,7-10.64c0.46,1.89,0.44,3.81,0.31,5.24
                            c-0.08,0.85,0.04,2.8,0.19,5.27c0.2,3.42,0.46,7.69,0.17,9.28c-0.28,1.54-0.41,8.92-0.55,19.19c-0.09,6.21-0.18,12.63-0.32,13.67
                            c-0.38,2.7-0.88,7.63-0.7,10.13c0.12,1.74,0.07,3.87,0.03,5.44c-0.02,0.63-0.03,1.18-0.03,1.58c0,1.07,0,3.29,2.2,4.13
                            c1.75,0.67,12.53,3.36,29.8,3.36c1.9,0,3.85-0.03,5.8-0.1c1.94,0.06,3.89,0.1,5.8,0.1c17.24,0,28.05-2.69,29.8-3.35
                            c2.2-0.84,2.2-3.07,2.2-4.13c0-0.4-0.01-0.95-0.03-1.58c-0.04-1.56-0.1-3.7,0.03-5.44c0.17-2.52-0.32-7.44-0.7-10.13
                            c-0.15-1.05-0.24-7.48-0.32-13.7c-0.14-10.25-0.27-17.62-0.55-19.16c-0.3-1.6-0.04-5.89,0.17-9.33c0.15-2.44,0.26-4.37,0.19-5.21
                            c-0.13-1.43-0.15-3.34,0.31-5.22c2.25,3.6,6.16,9.8,6.75,10.4c0.52,0.54,1.12,1.04,1.93,1.04c0.34,0,0.69-0.09,1.05-0.27
                            l0.08-0.04c2.02-1.04,7.01-3.72,9.84-6.13c2.95-2.5,10.12-5.6,11.02-5.81c0.46-0.09,0.84-0.4,1.05-0.87
                            c0.51-1.13-0.09-3.15-0.57-3.97c-0.12-0.21-0.94-1.71-1.98-3.61c-3.42-6.26-9.79-17.91-11-19.68c-0.78-1.16-3.16-5.85-5.26-9.99
                            c-2.11-4.15-3.93-7.74-4.54-8.64c-1.36-1.99-4.88-4.58-6.36-5.16c-1.45-0.55-3.58-1.09-5.09-1.3c-0.97-0.12-2.58-0.8-4-1.4
                            c-0.4-0.17-0.78-0.33-1.14-0.48C95.97,7.7,87.19,5.59,85.22,5.13l-1.86-2.86c-0.13-0.2-0.32-0.34-0.54-0.41
                            c-1.01-0.31-2.77-0.62-4.2-0.62c-0.33,0-0.63,0.02-0.9,0.05c-1.45,0.18-5.91,0.53-8.55,0.53c-2.64,0-7.13-0.34-8.55-0.53
                            C60.31,1.25,59.99,1.24,59.63,1.24L59.63,1.24z"
              />
            </g>
          </g>
        </g>
      </g>
      <ShirtNumber
        shirtNumber={shirtNumber}
        shirtNumberColor={shirtNumberColor}
        shirtNumberBackgroundColor={shirtNumberBackgroundColor}
        shirtNumberBackgroundOpacity={shirtNumberBackgroundOpacity}
      />
    </Kit>
  );
};

export default TwoColorVariableVerticalStripes;
