import { EventParticipant } from '../EventParticipant/EventParticipant';
import { useTranslation } from 'react-i18next';

import * as S from './TourStandings.styled';
import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import {
  SporteditTourStandingsType,
  SporteditTourType,
} from '@ntb-sport/types';
import { Container } from '../../chakra';

interface TourStandingsProps {
  standings: SporteditTourStandingsType;
  tour: SporteditTourType;
}

export const TourStandings = ({ tour, standings }: TourStandingsProps) => {
  const { t } = useTranslation();

  return (
    <S.ContainerQuery data-component-id={COMPONENT_IDS.TOUR_STANDINGS}>
      <Container maxW="4xl" pt={4}>
        <S.Header>
          <S.Name>{`${tour?.name} ${t(
            'overallStandings',
          )} ${tour?.startYear}/${tour?.endYear}`}</S.Name>
        </S.Header>
        <S.Standings>
          {!standings?.length && (
            <S.NoResults>Sammenlagtresultater ikke tilgjengelig</S.NoResults>
          )}
          {standings?.map((standing: any) => (
            <S.Standing
              key={
                standing?.participant?.person?.uuid ||
                standing?.participant?.team?.uuid
              }
              data-element-id={ELEMENT_IDS.LIST_ITEM}
            >
              <S.Left>
                <S.Rank
                  data-element-id={
                    standing?.participant?.person?.countries?.[0]?.uuid ===
                    'country-norway'
                      ? ELEMENT_IDS.TYPE_HIGHLIGHT
                      : ELEMENT_IDS.TYPE_DEFAULT
                  }
                >
                  {standing?.rank}
                </S.Rank>
                <EventParticipant
                  person={standing?.participant?.person}
                  team={standing?.participant?.team}
                />
              </S.Left>
              <S.Right>
                {standing.points ? `${standing.points}p` : standing.duration}
                <S.TimeDifference>{standing?.timeDifference}</S.TimeDifference>
              </S.Right>
            </S.Standing>
          ))}
        </S.Standings>
      </Container>
    </S.ContainerQuery>
  );
};

export default TourStandings;
