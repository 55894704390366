import { COMPONENT_IDS } from '@ntb-sport/constants';
import { useBem } from '@ntb-sport/hooks';
import classNames from 'classnames';
import './menu-item.css';

interface MenuItemProps {
  item: any;
  itemId: string;
  onClick: any;
  activeItemUuid: string;
}

export const MenuItem = ({ item, activeItemUuid, onClick }: MenuItemProps) => {
  const bem = useBem(COMPONENT_IDS.EVENT_MENU);

  const onClickMenuItem = (item: any) => {
    onClick({
      view: item.view,
      uuid: item?.uuid,
      group: item?.group,
      roundTypeUuid: item?.roundTypeUuid,
      disciplineUuid: item?.disciplineUuid,
    });
  };

  return (
    <button
      className={classNames(bem('menu-item'), {
        [bem('menu-item', 'active')]:
          activeItemUuid === item?.uuid ||
          item?.roundTypeUuid === activeItemUuid ||
          item?.disciplineUuid === activeItemUuid,
      })}
      onClick={() => onClickMenuItem(item)}
    >
      <span className={bem('menu-item-name')}>{item.name}</span>
    </button>
  );
};
