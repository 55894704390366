import { LabelProperties, UUIDs } from 'neo-common-enums';
import { SubEvents } from '../SubEvents/SubEvents';
import {
  COMPONENT_IDS,
  SPORT_ID_BELONGS_TO_SPORT_GROUP,
  SPORT_ID_NORWEGIAN,
} from '@ntb-sport/constants';
import { format, isValid } from 'date-fns';
import { IoMdMedal } from 'react-icons/io';

import Gender from '../../../common/Gender/Gender';
import { getEventStatus } from '../helpers/getEventStatus';
import { EventType, SportType } from '../types';
import { NeoBroadcastType, NeoEventType } from '@ntb-sport/types';
import { useBem } from '@ntb-sport/hooks';
import './main-event.css';
import classNames from 'classnames';
import CountryFlag from '../../../common/CountryFlag/CountryFlag';

interface MainEventProps {
  event: EventType;
  onClick?: ({
    sportGroup,
    sportIdTranslated,
    date,
    uuid,
  }: {
    sportGroup: string;
    sportIdTranslated: string;
    sportId: string;
    date: string | false;
    uuid: string;
  }) => Promise<boolean> | void;
  date: string;
  sport: SportType;
}

export const MainEvent = ({ event, onClick, date, sport }: MainEventProps) => {
  const bem = useBem(COMPONENT_IDS.SPORTEDIT_CALENDAR);

  const eventStatus = getEventStatus({
    eventStatusId: event?.eventStatusId,
    timestamp: event?.timestamp,
  });

  const childEventDates = event?.childEvents
    ?.reduce((acc: string[], event: NeoEventType) => {
      if (!acc?.find((item: string) => item === event?.date)) {
        acc.push(event?.date);
      }

      return acc;
    }, [])
    ?.sort((a: string, b: string) => (a < b ? -1 : 0));

  return (
    <li
      className={bem('main-event')}
      onClick={() => {
        onClick &&
          onClick({
            sportGroup:
              SPORT_ID_BELONGS_TO_SPORT_GROUP[
                event?.seasons?.[0]?.competition?.sport?.uuid
              ],
            sportIdTranslated:
              SPORT_ID_NORWEGIAN[event?.seasons?.[0]?.competition?.sport?.uuid],
            sportId: event?.seasons?.[0]?.competition?.sport?.uuid,
            date:
              isValid(new Date(date)) && format(new Date(date), 'yyyy-MM-dd'),
            uuid: event?.uuid,
          });
      }}
    >
      <div className={bem('status-container')}>
        {(event?.eventStatusId === LabelProperties.EventStatus.CANCELLED ||
          event?.labels?.includes('NonParentEvent')) && (
          <div
            className={classNames(bem('status'), {
              [bem('status', 'success')]: eventStatus?.type === 'success',
              [bem('status', 'error')]: eventStatus?.type === 'error',
            })}
          >
            {eventStatus?.type === 'default' && eventStatus?.text}
          </div>
        )}
      </div>

      <div className={bem('broadcasts')}>
        {event?.broadcasts
          ?.filter(
            (broadcast: NeoBroadcastType) =>
              broadcast?.typeId === LabelProperties.BroadcastType.TV,
          )
          ?.map((broadcast: NeoBroadcastType) => (
            <div
              className={bem('broadcast')}
              key={broadcast?.broadcastingChannel?.uuid}
            >
              {broadcast?.broadcastingChannel?.name}
            </div>
          ))}
      </div>

      <div className={bem('event-info')}>
        <div className={bem('event-name-and-gender')}>
          <div className={bem('event-name')}>{event?.name}</div>

          {/* {event?.labels?.includes('NonParentEvent') &&
            eventStatus?.type !== 'default' && (
              <div className={bem('event-location')}>
                {' - '}
                <div
                  className={classNames(bem('event-status'), {
                    [bem('event-status', 'success')]:
                      eventStatus?.type === 'success',
                    [bem('event-status', 'error')]:
                      eventStatus?.type === 'error',
                  })}
                >
                  {eventStatus?.text}
                </div>
              </div>
            )} */}
          {childEventDates?.length > 1 && (
            <div className={bem('event-days')}>
              {`(dag `}
              {childEventDates?.findIndex((d: string) => d === date) + 1}
              {' av '}
              {childEventDates?.length}
              {')'}
            </div>
          )}

          <Gender gender={event?.gender?.uuid} />
          {event?.hasAthletesFromCountry && <CountryFlag countryCode="no" />}
          {event?.isMedalEvent && <IoMdMedal />}
        </div>

        {(event?.arrangedAtLocations?.[0]?.name ||
          event?.arrangedInCountries?.[0]?.name) &&
          sport?.uuid !== UUIDs.Sport.ATHLETICS && (
            <div className={bem('event-location')}>
              {`${
                event?.arrangedAtLocations?.[0]?.name
                  ? event?.arrangedAtLocations?.[0]?.name + ', '
                  : ''
              } ${event?.arrangedInCountries?.[0]?.name || ''}`}

              {(event?.eventStatusId ===
                LabelProperties.EventStatus.CANCELLED ||
                event?.labels?.includes('NonParentEvent')) &&
                eventStatus?.type !== 'default' && (
                  <>
                    {' - '}
                    <span
                      className={classNames(bem('event-status'), {
                        [bem('event-status', 'success')]:
                          eventStatus?.type === 'success',
                        [bem('event-status', 'error')]:
                          eventStatus?.type === 'error',
                      })}
                    >
                      {eventStatus?.text}
                    </span>
                  </>
                )}
            </div>
          )}
      </div>

      {event?.eventStatusId !== LabelProperties.EventStatus.CANCELLED &&
        (event?.childEventsGroupedByRoundType || event?.childEvents) && (
          <SubEvents events={event?.childEventsGroupedByRoundType} />
        )}
    </li>
  );
};
