import Icon from '../Icon/Icon';

export const SpeedSkatingSportIcon = (props: any) => (
  <Icon viewBox="0 0 28.3 28.3" {...props}>
    <g>
      <g>
        <g>
          <g>
            <path
              d="M20.2,10.6c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c-0.1,0.7-0.7,1.3-1.4,1.3c-1,0-1.8,0.1-2.3,0.3
					c0.7,0.6,1,1.3,1.1,1.9c0.2,1.3-0.4,2.8-1.9,4.4c-0.5,0.5-0.9,0.9-1.3,1.2h1c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7h-4.9
					c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7h1.2c-0.3-0.6-0.1-1.3,0.4-1.7c1.7-1.2,2.8-2.8,2.7-3.4c0-0.1-0.1-0.3-0.4-0.4
					c-2.6,2.8-5.6,3.3-7.3,3.3c-0.8,0-1.3-0.1-1.4-0.1c-0.2,0-0.4-0.1-0.6-0.3l-1,1.2c-0.1,0.2-0.3,0.3-0.5,0.3
					c-0.2,0-0.3-0.1-0.4-0.2c-0.3-0.2-0.3-0.7-0.1-1l1.8-2.1C4.9,15.3,5,15.1,5.1,15l1.1-1.3c0.2-0.3,0.7-0.3,1-0.1
					c0.3,0.2,0.3,0.7,0.1,1l-0.1,0.1c1.3,0,3.7-0.4,5.6-2.9c0.2-0.5,0.8-1.2,2-1.8c-1.3-0.3-2.8-0.6-3.9-0.8c-0.6-0.1-1.1-0.7-1-1.3
					c0.1-0.6,0.7-1.1,1.3-1c1,0.1,5.8,0.8,8.2,2.4C19.8,9.6,20.2,10.1,20.2,10.6z"
            />
            <path
              d="M25.4,9.8c-0.1,1.3-1.3,2.2-2.6,2.1c-1.3-0.1-2.2-1.3-2.1-2.6c0.1-1.3,1.3-2.2,2.6-2.1
					C24.6,7.4,25.5,8.5,25.4,9.8z"
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
