import styled from 'styled-components';

export const Container = styled.div`
  backdrop-filter: blur(5px);
  background-color: rgba(92, 20, 125, 0.4);

  padding: var(--ntb-space-8);
  color: var(--base-colors-whiteAlpha-800);

  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-8);

  /* border-bottom: 1px solid #5c147d; */
`;
