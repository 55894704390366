import CountryFlag from '../../../common/CountryFlag/CountryFlag';
import styled from 'styled-components';

export const Section = styled.div``;
export const SectionTitle = styled.h2``;
export const Persons = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: var(--ntb-space-2);
`;
export const Person = styled.li`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: var(--ntb-space-8);
`;
export const Bio = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
`;
export const ShirtNumber = styled.div`
  width: 30px;
  text-align: center;
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-normal);
`;
export const NameAndCountry = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Name = styled.div`
  font-size: var(--ntb-fontSizes-md);
  font-weight: var(--ntb-fontWeights-normal);
`;
export const Country = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
`;
export const Flag = styled(CountryFlag).withConfig({
  shouldForwardProp: (prop) => ['countryName'].includes(prop),
})`
  min-width: 20px;
  max-width: 20px;
`;
export const CountryName = styled.div`
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-light);
`;
export const Stats = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
`;
export const Stat = styled.div`
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-light);
`;

export const StatsHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
`;
export const StatTitle = styled.h2`
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-medium);
`;
export const StatHeader = styled.div`
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-medium);
`;

export const Line = styled.div`
  margin-bottom: var(--ntb-space-8);
`;
