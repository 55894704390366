import Icon from '../Icon/Icon';

export const CardIcon = ({
  cardId,
  fillColor,
  size,
  count,
  ...restProps
}: {
  cardId: any;
  fillColor?: string;
  size?: any;
  count?: number;
}) => {
  return (
    <Icon {...restProps} size={size} className="card">
      <style type="text/css">{`.card-fill-${cardId} { fill:${fillColor};  }`}</style>
      <g>
        <path
          className={`card-fill-${cardId}`}
          d="M16.3,21.6H6.4c-1.7,0-3-1.3-3-3V4.1c0-1.7,1.3-3,3-3h9.9c1.7,0,3,1.3,3,3v14.5C19.3,20.2,18,21.6,16.3,21.6z"
        />
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dy=".05em"
          alignmentBaseline="central"
          style={{
            fontSize: 'var(--ntb-fontSizes-xs)',
            fontWeight: 'var(--ntb-fontWeights-medium)',
            fill: 'var(--stat-cards-font-color)',
          }}
        >
          {count}
        </text>
      </g>
    </Icon>
  );
};

export default CardIcon;
