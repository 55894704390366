import { SPORT_GROUP } from '@ntb-sport/constants';
import { Fetch, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchStadiumType {
  apiBaseUrl: string;
  sportGroup: string;
  stadiumId: string;
}

export const fetchStadium = async ({
  apiBaseUrl,
  sportGroup,
  stadiumId,
}: FetchStadiumType) => {
  const path =
    sportGroup === SPORT_GROUP.ICE_HOCKEY || sportGroup === SPORT_GROUP.HANDBALL
      ? 'nifStadiums'
      : 'stadiums';

  const json = await Fetch({
    url: `${apiBaseUrl}/${path}/${stadiumId}/`,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });

  return json;
};
