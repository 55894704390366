import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import { CSS_VARIABLES } from '../index';

export const TEAM_PLAYER_STATS_STYLES = [
  {
    name: 'Root',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_PLAYER_STATS}']`,
    variables: [CSS_VARIABLES.BACKGROUND_COLOR, CSS_VARIABLES.FONT_COLOR],
  },
  {
    name: 'Highlight',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_PLAYER_STATS}'] [data-element-id='${ELEMENT_IDS.TYPE_HIGHLIGHT}']`,
    variables: [CSS_VARIABLES.BACKGROUND_COLOR],
  },
  {
    name: 'Highlight Overlay',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_PLAYER_STATS}'] [data-element-id='${ELEMENT_IDS.TYPE_HIGHLIGHT}'] [data-element-id='${ELEMENT_IDS.OVERLAY}']`,
    variables: [CSS_VARIABLES.BACKGROUND_COLOR, CSS_VARIABLES.FONT_COLOR],
  },

  {
    name: 'Row',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_PLAYER_STATS}'] [data-element-id='${ELEMENT_IDS.ROW}']`,
    variables: [CSS_VARIABLES.BORDER_COLOR],
  },
  {
    name: 'Button',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_PLAYER_STATS}'] [data-element-id='${ELEMENT_IDS.BUTTON}']`,
    variables: [
      CSS_VARIABLES.BACKGROUND_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR__HOVER,
      CSS_VARIABLES.BACKGROUND_COLOR__ACTIVE,
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.FONT_COLOR__HOVER,
      CSS_VARIABLES.FONT_COLOR__ACTIVE,
      CSS_VARIABLES.BORDER_COLOR,
      CSS_VARIABLES.BORDER_COLOR__HOVER,
      CSS_VARIABLES.BORDER_COLOR__ACTIVE,
    ],
  },
];
