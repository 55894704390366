import { MatchesType } from '@ntb-sport/types';

/* eslint-disable-next-line */
export interface NrkVideoPlayerProps {
  videoId: string;
  muted?: boolean;
  autoplay?: boolean;
  sourceId?: string;
  ga?: string;
  matches?: MatchesType;
}

export function NrkVideoPlayer({
  videoId,
  sourceId,
  ga,
  muted = false,
  autoplay = false,
  matches,
}: NrkVideoPlayerProps) {
  const className = matches?.desktopS
    ? '-player aspect-ratio aspect-ratio--16x9'
    : '-player aspect-ratio aspect-ratio--1x1';

  if (muted && !autoplay) {
    return (
      <nrkno-player
        style={{ width: '100%' }}
        class="-player aspect-ratio aspect-ratio--16x9"
        type="player"
        muted
        sourceId={sourceId}
        ga={ga}
        src={videoId}
      ></nrkno-player>
    );
  }

  if (muted && autoplay) {
    return (
      <nrkno-player
        style={{ width: '100%' }}
        class={className}
        type="player"
        autoplay
        muted
        sourceId={sourceId}
        ga={ga}
        src={videoId}
      ></nrkno-player>
    );
  }

  if (!muted && autoplay) {
    return (
      <nrkno-player
        style={{ width: '100%' }}
        class={className}
        type="player"
        autoplay
        sourceId={sourceId}
        ga={ga}
        src={videoId}
      ></nrkno-player>
    );
  }

  return (
    <nrkno-player
      style={{ width: '100%' }}
      class="-player aspect-ratio aspect-ratio--16x9"
      type="player"
      sourceId={sourceId}
      ga={ga}
      src={videoId}
    ></nrkno-player>
  );
}

export default NrkVideoPlayer;
