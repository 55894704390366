import { Fetch } from '../fetch';

export interface FetchPsApi {
  url: string;
}

export const fetchPsApi = async ({ url }: FetchPsApi) => {
  const json = await Fetch({ url });

  return json;
};
