import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '@ntb-sport/constants';
import {
  fetchDaysWithEventsInMonth,
  FetchDaysWithEventsInMonthType,
} from './fetch';
import { getIsNeoSportGroup } from '@ntb-sport/helpers';

interface UseQueryDaysWithEventsInMonth {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: FetchDaysWithEventsInMonthType;
  options?: Record<string, unknown>;
}

export const useQueryDaysWithEventsInMonth = ({
  queryKey = QUERY_KEYS.DAYS_WITH_EVENTS_IN_MONTH,
  queryKeyParams,
  params,
  options,
}: UseQueryDaysWithEventsInMonth) => {
  const isNeoSportGroup = getIsNeoSportGroup(params.sportGroup);

  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchDaysWithEventsInMonth(params),
    enabled:
      isNeoSportGroup && Boolean(params.customerUUID) && Boolean(params.token),
    ...options,
  });

  return { data, isLoading, error };
};
