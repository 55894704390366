import Icon from '../Icon/Icon';

export const SkiJumpingSportIcon = (props: any) => (
  <Icon viewBox="0 0 28.3 28.3" {...props}>
    <g>
      <g>
        <path
          d="M23.9,12.6c0.2,0.5,0.1,1-0.4,1.3L8.8,22.1l-5.1,2.8C3.6,25,3.5,25,3.3,25c-0.3,0-0.6-0.2-0.8-0.5c-0.2-0.5-0.1-1,0.4-1.3
			l4-2.2c0-0.2,0-0.4,0-0.6c0.1-0.3,1.6-5.6,6.3-9.8l-3.4,0.6c-0.1,0-0.1,0-0.2,0c-0.6,0-1-0.4-1.1-1C8.3,9.7,8.7,9.1,9.3,8.9
			l8.9-1.6c0.3-0.1,0.6-0.3,0.9-0.4C19.9,6.6,20.8,7,21,7.7c0.3,0.7-0.1,1.5-0.8,1.8c-5.6,2.1-8.5,6.8-9.8,9.5l12.3-6.9
			C23.1,12,23.7,12.1,23.9,12.6z"
        />
        <path d="M26,5.7c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4C24.9,3.3,26,4.4,26,5.7z" />
      </g>
    </g>
  </Icon>
);
