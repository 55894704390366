import { Fetch } from '../../fetch';

export interface FetchEventStandingsProps {
  apiBaseUrl: string;
  eventUUID: string;
  token: string;
  customerUUID: string;
}

export const fetchEventStandings = async ({
  apiBaseUrl,
  eventUUID,
  token,
  customerUUID,
}: FetchEventStandingsProps) => {
  const json = await Fetch({
    url: `${apiBaseUrl}/events/${eventUUID}/standings`,
    headers: {
      Authorization: `Bearer ${token}`,
      CustomerUUID: customerUUID,
    },
  });

  return json.standings;
};
