import { MdContacts, MdDensitySmall, MdMultipleStop } from 'react-icons/md';

import * as S from '../TransferMenuStyled.styled';
import { LegacyRef } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { SearchIcon } from '@chakra-ui/icons';
import { TRANSFER_TYPE_FILTER_OPTIONS } from '../../utils/TransferHelpers';

export const TransferViewToggle = (
  isTableView: boolean,
  setIsTableView: { on?: () => void; off?: () => void; toggle: any },
) => {
  return (
    <S.SelectViewToggle>
      <S.SelectViewBtn
        aria-pressed={isTableView}
        onClick={() => setIsTableView.toggle()}
        $isTableView={isTableView}
      >
        <MdDensitySmall />
      </S.SelectViewBtn>
      <span>|</span>
      <S.SelectViewBtn
        aria-pressed={!isTableView}
        onClick={() => setIsTableView.toggle()}
        $isTableView={!isTableView}
      >
        <MdContacts />
      </S.SelectViewBtn>
    </S.SelectViewToggle>
  );
};

export const FilterTransferTypes = (
  selectedTypeFilterOption: string,
  isTransferTypeOpen: boolean,
  typesBtnRef: LegacyRef<HTMLButtonElement> | undefined,
  setIsTransferTypeOpen: { on?: () => void; off?: () => void; toggle: any },
  typesFormRef: LegacyRef<HTMLFieldSetElement> | undefined,
  setSelectedTypeFilterOption: (arg0: string) => void,
) => {
  return (
    <>
      <button ref={typesBtnRef} onClick={setIsTransferTypeOpen.toggle}>
        <MdMultipleStop />
        Type
      </button>
      {isTransferTypeOpen && (
        <fieldset ref={typesFormRef}>
          {Object.values(TRANSFER_TYPE_FILTER_OPTIONS).map((type: string) => (
            <div>
              <input
                type="radio"
                id={type}
                name="transferType"
                aria-labelledby={type}
                value={type}
                defaultChecked={selectedTypeFilterOption === type}
                onClick={() => setSelectedTypeFilterOption(type)}
              />
              <label htmlFor={type}>{type}</label>
            </div>
          ))}
        </fieldset>
      )}
    </>
  );
};

export const TransferSearch = (
  setSearchValue: (arg0: string) => void,
  searchValue = '',
) => {
  return (
    <S.Search>
      <input
        type="text"
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        placeholder="Søk"
        aria-label="Søkefelt"
      ></input>

      <S.RemoveSearchBtn
        aria-label="Fjern søk."
        aria-hidden={searchValue.length > 0}
        disabled={searchValue.length === 0}
        onClick={() => setSearchValue('')}
        $isSearchValue={searchValue.length > 0}
      >
        <IoIosCloseCircleOutline aria-hidden="true" />
      </S.RemoveSearchBtn>

      <span>
        <SearchIcon aria-hidden="true" />
      </span>
    </S.Search>
  );
};

export const Results = (totalResults: number, visibleCount: number) => {
  const numberOfResults =
    totalResults <= visibleCount
      ? totalResults
      : totalResults === 0
        ? 0
        : visibleCount;
  return <S.Results>{`Viser ${numberOfResults} / ${totalResults}`}</S.Results>;
};
