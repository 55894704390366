import ResultId from '../ResultId/ResultId';
import './distance.css';
import { useBem } from '@ntb-sport/hooks';

interface DistanceProps {
  distance: string;
  resultId: number;
}

export const Distance = ({ distance, resultId }: DistanceProps) => {
  const bem = useBem('scope-results');
  return (
    <div className={bem('distance')}>
      <ResultId resultId={resultId} />
      {distance && `${distance}m`}
    </div>
  );
};
