import {
  COMPONENT_IDS,
  DATA_PICKER_GROUPS,
  DATA_PROVIDERS,
  SPORT_GROUP,
} from '@ntb-sport/constants';
import { WidgetConfig, SportsdataConfig } from '@ntb-sport/types';
import {
  DATA_TYPES,
  WIDGET_IDS,
  WIDGET_OPTIONS,
  WIDGET_VERSION,
} from '../common';

export const config: WidgetConfig = {
  id: WIDGET_IDS.EVENT_RESULT,
  dataType: DATA_TYPES.SPORTEDIT,
  version: WIDGET_VERSION,
  displayName: 'Resultater (Vinterport, Friidrett, Sykling)',
  widgetNamespace: 'NtbWidgetEventResult',
  iframeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.EVENT_RESULT}/iframe.html`,
  widgetUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.EVENT_RESULT}/${WIDGET_IDS.EVENT_RESULT}.js`,
  themeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/themes/default.css`,
  title: 'Resultater',
  summary:
    'Startlister, resultater og sammenlagtlister for renn eller konkurranser',
  sportGroups: [
    SPORT_GROUP.WINTERSPORTS,
    SPORT_GROUP.ATHLETICS,
    SPORT_GROUP.CYCLING,
  ],
  description: 'general description to be displayed on the documentation page',
  components: [
    COMPONENT_IDS.COMBINED_EVENTS_STANDINGS,
    COMPONENT_IDS.EVENT_HERO,
    COMPONENT_IDS.EVENT_MAIN_MENU,
    COMPONENT_IDS.EVENT_MENU,
    COMPONENT_IDS.EVENT_PARTICIPANTS,
    COMPONENT_IDS.MEDAL_TABLE,
    COMPONENT_IDS.SCOPE_MENU,
    COMPONENT_IDS.SCOPE_RESULTS,
    COMPONENT_IDS.SEASON_STANDING,
    COMPONENT_IDS.TOUR_STANDINGS,
  ],
  options: {
    [WIDGET_OPTIONS.CUSTOMER_ID]: { isRequired: true },
    [WIDGET_OPTIONS.ERROR_MESSAGE]: { isRequired: false },
    [WIDGET_OPTIONS.ID]: { isRequired: true },
    [WIDGET_OPTIONS.ON_ERROR]: { isRequired: false },
    [WIDGET_OPTIONS.SPORT_GROUP]: { isRequired: true },
    [WIDGET_OPTIONS.STYLE_ID]: { isRequired: false },
    [WIDGET_OPTIONS.TARGET_ID]: { isRequired: true },
  },
  uiOptions: {},
};

export const sportsdataConfig: SportsdataConfig = {
  dataPickerGroups: {
    [DATA_PICKER_GROUPS.NEO_EVENT_GROUP]: {
      title: 'Event',
      key: DATA_PICKER_GROUPS.NEO_EVENT_GROUP,
      primaryId: DATA_PROVIDERS.NEO_SEASON_EVENTS,
      isDefault: true,
      pickers: {
        [DATA_PROVIDERS.SPORT_GROUP]: { defaultValue: 'wintersports' },
        [DATA_PROVIDERS.NEO_SPORTS]: { defaultValue: 'sport-biathlon' },
        [DATA_PROVIDERS.NEO_COMPETITIONS]: {},
        [DATA_PROVIDERS.NEO_SEASONS]: {},
        [DATA_PROVIDERS.NEO_SEASON_EVENTS]: { isPrimaryId: true },
      },
    },
    [DATA_PICKER_GROUPS.DATE_GROUP]: {
      title: 'Dato',
      key: DATA_PICKER_GROUPS.DATE_GROUP,
      primaryId: DATA_PROVIDERS.NEO_EVENTS,
      pickers: {
        [DATA_PROVIDERS.SPORT_GROUP]: { defaultValue: 'wintersports' },
        [DATA_PROVIDERS.DATE_SELECTOR]: {},
        [DATA_PROVIDERS.NEO_EVENTS]: { isPrimaryId: true },
      },
    },
  },
  optionGroups: {},
};
