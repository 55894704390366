import { COMPONENT_IDS } from '@ntb-sport/constants';
import * as S from './ExampleComponentOne.styled';
import { Heading, Image } from '../../chakra';

interface ExampleComponentOneProps {
  title: string;
  round?: string | number;
  showStageName?: boolean;
}

export const ExampleComponentOne: React.FC<ExampleComponentOneProps> = ({
  title,
  round,
  showStageName,
}) => {
  return (
    <div data-component-id={COMPONENT_IDS.EXAMPLE_COMPONENT_ONE}>
      <S.Container>
        {showStageName && (
          <Image
            width={70}
            src={
              'https://www.premierleague.com/resources/rebrand/v7.129.4/i/elements/pl-main-logo.png'
            }
          />
        )}
        <div>
          <Heading>{title}</Heading>
          <Heading fontSize="lg">Round {round}</Heading>
        </div>
      </S.Container>
    </div>
  );
};

export default ExampleComponentOne;
