import * as S from './TransferPlayerCardStyled.styled';
import {
  calculateYearsBetween,
  CountrySelect,
  displayTransferType,
  formatTransferDate,
  handlePositionType,
  handleTransferType,
  LinkWrapper,
  playerNationality,
  TO_FROM_TEAM,
  ToFromTeamType,
  TRANSFER_TYPES,
  transferPlayerImg,
} from '../utils/TransferHelpers';

import { TransferForwardIcon } from '../../../icons/TransferIcons/TransferForwardIcon';
import { UnknownTeamLogoIcon } from '../../../icons/TransferIcons/UnknownTeamLogoIcon';
import { MdOutlineEventAvailable } from 'react-icons/md';
import { PlayerTransferInterface } from '../TransferMenu/libs/TransferMenuContext';

interface TransferPlayerCardTypes {
  transfer: PlayerTransferInterface;
  gender?: string;
  playersUrl?: string | null;
  linkToTeamUrl?: string;
  transferCountry?: string | null;
  LinkComponent: React.ComponentType<any> | null | undefined;
}

export const TransferPlayerCard = ({
  transfer,
  gender,
  playersUrl,
  linkToTeamUrl,
  transferCountry,
  LinkComponent,
}: TransferPlayerCardTypes) => {
  const checkLogo = (
    transfer: PlayerTransferInterface,
    toFrom: ToFromTeamType,
  ) => {
    return transfer?.[toFrom]?.logo?.url &&
      (transfer[toFrom]?.logo.url !== '' || undefined) ? (
      <S.TeamLogo as={'img'} src={transfer?.[toFrom]?.logo?.url} />
    ) : (
      <S.TeamLogo>
        <UnknownTeamLogoIcon />
      </S.TeamLogo>
    );
  };

  return (
    <S.PlayerCard>
      <S.PlayerCardImg
        as={'img'}
        alt={`Bilde av ${transfer?.person?.name}`}
        src={transferPlayerImg(transfer, gender)}
      />
      <S.PlayerCardInfo>
        <S.Bio>
          <S.Name>
            {playersUrl && (
              <LinkWrapper LinkComponent={LinkComponent} href={playersUrl}>
                {transfer?.person?.name}
              </LinkWrapper>
            )}
          </S.Name>
          <S.AdditionalInfo>
            <div>
              <span>Nasjon</span>

              {transfer?.person?.country ? (
                <div>
                  <S.Flag countryName={playerNationality(transfer)} />
                  <p>{playerNationality(transfer)}</p>
                </div>
              ) : (
                <p>-</p>
              )}
            </div>
            <div>
              <span>Posisjon</span>
              <p>
                {transfer?.person?.position
                  ? handlePositionType(transfer.person.position.position)
                  : '-'}
              </p>
            </div>
            <div>
              <span>Alder</span>
              {transfer?.person?.birthDate
                ? calculateYearsBetween(
                    new Date(transfer?.person?.birthDate),
                    new Date(),
                  ) + ' år'
                : '-'}
            </div>
          </S.AdditionalInfo>
        </S.Bio>

        <S.TransferContainer>
          <S.TransferTeamDetails tabIndex={0}>
            <S.TransferBackground />
            {transfer?.fromTeam?.id ? (
              <LinkWrapper
                LinkComponent={LinkComponent}
                href={`${linkToTeamUrl}${transfer.fromTeam.id}/kamper`}
              >
                <div>
                  {checkLogo(transfer, TO_FROM_TEAM.FROM)}

                  <p>
                    {transfer?.fromTeam?.shortName
                      ? transfer.fromTeam.shortName
                      : transfer?.fromTeam?.name}
                  </p>
                  {CountrySelect(
                    transfer,
                    TO_FROM_TEAM.FROM,
                    transferCountry,
                  ) && (
                    <S.TeamNationalFlag
                      countryName={transfer?.fromTeam?.country?.name}
                    />
                  )}
                </div>
              </LinkWrapper>
            ) : (
              <div>
                <S.TeamLogo>
                  <UnknownTeamLogoIcon />
                </S.TeamLogo>
                Ukjent
              </div>
            )}

            <span aria-label="Til">
              <TransferForwardIcon w="6" h="6" />
            </span>

            {handleTransferType(transfer.type) ===
            TRANSFER_TYPES.TransferHungUpBoots ? (
              ''
            ) : transfer?.toTeam?.id ? (
              <LinkWrapper
                LinkComponent={LinkComponent}
                href={`${linkToTeamUrl}${transfer.toTeam.id}/kamper`}
              >
                <div>
                  {checkLogo(transfer, TO_FROM_TEAM.TO)}
                  <p>
                    {transfer?.toTeam?.shortName
                      ? transfer?.toTeam?.shortName
                      : transfer?.toTeam?.name}
                  </p>
                  {CountrySelect(
                    transfer,
                    TO_FROM_TEAM.TO,
                    transferCountry,
                  ) && (
                    <S.TeamNationalFlag
                      countryName={transfer?.toTeam?.country?.name}
                    />
                  )}
                </div>
              </LinkWrapper>
            ) : (
              <div>
                <S.TeamLogo>
                  <UnknownTeamLogoIcon />
                </S.TeamLogo>
                Ukjent
              </div>
            )}
          </S.TransferTeamDetails>

          <S.TransferDetails>
            {displayTransferType(transfer)}
            <p aria-labelledby={`Dato-${formatTransferDate(transfer?.date)}`}>
              <MdOutlineEventAvailable aria-hidden="true" />
              {transfer?.date && formatTransferDate(transfer.date)}
            </p>
          </S.TransferDetails>
        </S.TransferContainer>
      </S.PlayerCardInfo>
    </S.PlayerCard>
  );
};

export default TransferPlayerCard;
