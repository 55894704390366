import styled, { keyframes } from 'styled-components';

const animationOne = keyframes`
 0% {
    transform: scale(1, 0.1);
  }
  20% {
    transform: scale(1, 0.3);
  }
  40% {
    transform: scale(1, 0.2);
  }
  50% {
    transform: scale(1, 0.4);
  }
  70% {
    transform: scale(1, 0.1);
  }
  90% {
    transform: scale(1, 0.3);
  }
  100% {
    transform: scale(1, 0.1);
  }
`;

const animationTwo = keyframes`
 0% {
    transform: scale(1, 0.4);
  }
  10% {
    transform: scale(1, 0.1);
  }
  30% {
    transform: scale(1, 0.3);
  }
  40% {
    transform: scale(1, 0.2);
  }
  
  60% {
    transform: scale(1, 0.6);
  }
  80% {
    transform: scale(1, 0.2);
  }
  100% {
    transform: scale(1, 0.4);
  }
`;

const animationThree = keyframes`
 0% {
    transform: scale(1, 0.7);
  }
  10% {
    transform: scale(1, 0.4);
  }
  20% {
    transform: scale(1, 0.8);
  }
  45% {
    transform: scale(1, 0.4);
  }
  
  60% {
    transform: scale(1, 0.7);
  }
  70% {
    transform: scale(1, 0.4);
  }
  100% {
    transform: scale(1, 0.7);
  }
`;

const animationFour = keyframes`
 0% {
    transform: scale(1, 0.3);
  }
  30% {
    transform: scale(1, 0.5);
  }
  40% {
    transform: scale(1, 0.2);
  }
  60% {
    transform: scale(1, 0.6);
  }
  
  80% {
    transform: scale(1, 0.4);
  }

  100% {
    transform: scale(1, 0.3);
  }
`;

const animationFive = keyframes`
 0% {
    transform: scale(1, 0.5);
  }
  30% {
    transform: scale(1, 0.3);
  }
  40% {
    transform: scale(1, 0.7);
  }
  60% {
    transform: scale(1, 0.4);
  }
  
  80% {
    transform: scale(1, 0.2);
  }

  100% {
    transform: scale(1, 0.5);
  }
`;

const animationSix = keyframes`
 0% {
    transform: scale(1, 0.4);
  }
  20% {
    transform: scale(1, 0.1);
  }
  50% {
    transform: scale(1, 0.4);
  }
  60% {
    transform: scale(1, 0.2);
  }
  
  70% {
    transform: scale(1, 0.5);
  }
  90% {
    transform: scale(1, 0.1);
  }
  100% {
    transform: scale(1, 0.4);
  }
`;

export const AudioAnimation = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  display: flex;
  justify-content: space-around;
  margin-right: 0.5rem;
`;

export const AudioAnimationBar = styled.div`
  width: calc(9.09091%);
  height: 100%;
  background-color: currentcolor;
  transform-origin: center center;
`;

export const AudioAnimationBarOne = styled(AudioAnimationBar)`
  animation: 2s ease 0s infinite normal none running ${animationOne};
  transform: scale(1, 0.2);
`;
export const AudioAnimationBarTwo = styled(AudioAnimationBar)`
  animation: 1.8s ease 0s infinite normal none running ${animationTwo};
  transform: scale(1, 0.4);
`;
export const AudioAnimationBarThree = styled(AudioAnimationBar)`
  animation: 1.6s ease 0s infinite normal none running ${animationThree};
  transform: scale(1, 0.6);
`;
export const AudioAnimationBarFour = styled(AudioAnimationBar)`
  animation: 1.5s ease 0s infinite normal none running ${animationFour};
  transform: scale(1, 0.7);
`;
export const AudioAnimationBarFive = styled(AudioAnimationBar)`
  animation: 1.7s ease 0s infinite normal none running ${animationFive};
  transform: scale(1, 0.5);
`;
export const AudioAnimationBarSix = styled(AudioAnimationBar)`
  animation: 1.8s ease 0s infinite normal none running ${animationSix};
  transform: scale(1, 0.3);
`;
