import * as S from './TransferMenuStyled.styled';
import { useBoolean } from '@ntb-sport/hooks';
import { useContext, useEffect, useRef, useState } from 'react';
import { Skeleton } from '@chakra-ui/react';
import {
  ALL_IN_OUT,
  TRANSFER_TYPE_FILTER_OPTIONS,
} from '../utils/TransferHelpers';
import { MdClose, MdFilterListAlt } from 'react-icons/md';
import { ELEMENT_IDS } from '@ntb-sport/constants';

import {
  FilterTransferTypes,
  Results,
  TransferSearch,
  TransferViewToggle,
} from './libs/TransferMenuComponents';
import TransferContext from './libs/TransferMenuContext';

interface TransferMenuProps {
  isTournamentPage: boolean;
  allTournamentTeams?: string[];
  isLoading: boolean;
}
export const TransferTournamentMenu = ({
  isTournamentPage,
  allTournamentTeams,
  isLoading,
}: TransferMenuProps) => {
  const {
    searchValue,
    setSearchValue,
    resultsAmount,
    isTableView,
    setIsTableView,
    visibleCount,
    selectedTypeFilterOption,
    setSelectedTypeFilterOption,
    selectedTeam,
    setSelectedTeam,
    setTournamentPageStatus,
  } = useContext(TransferContext);

  const [isTransferTypeOpen, setIsTransferTypeOpen] = useBoolean(false);
  const [showTeamFilter, setShowTeamFilter] = useBoolean(false);

  const selectTeamFormRef = useRef<HTMLSelectElement>(null);
  const typesFormRef = useRef<HTMLFieldSetElement>(null);
  const typesBtnRef = useRef<HTMLButtonElement>(null);
  const typeFilterRef = useRef<HTMLDivElement>(null);
  const teamFilterRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        typesFormRef.current &&
        !typesFormRef.current.contains(event?.target as Node) &&
        typesBtnRef.current &&
        !typesBtnRef?.current?.contains(event?.target as Node)
      ) {
        setIsTransferTypeOpen.off();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isTransferTypeOpen]);

  useEffect(() => {
    showTeamFilter
      ? setSearchValue('')
      : (setSelectedTeam(''), setTournamentPageStatus(ALL_IN_OUT.ALLE));
  }, [showTeamFilter]);

  useEffect(() => {
    selectTeamFormRef?.current ? selectTeamFormRef.current?.focus() : null;
  }, [showTeamFilter]);

  return (
    <S.TransferMenu
      data-element-id={ELEMENT_IDS.MENU}
      aria-label="Overgangsmeny"
    >
      <S.TournamentMenuContent>
        <S.TopLeft>
          {!showTeamFilter ? (
            <>
              <S.ActivateTeamFilterBtn
                disabled={isLoading}
                onClick={() => setShowTeamFilter.on()}
                aria-label="Velg lag"
                ref={teamFilterRef}
              >
                <MdFilterListAlt aria-hidden={true} />
                {isLoading ? (
                  <Skeleton
                    speed={0.8}
                    width={6}
                    height={'30%'}
                    borderRadius={2}
                    fadeDuration={0.4}
                  />
                ) : (
                  <span>Lag</span>
                )}
              </S.ActivateTeamFilterBtn>
              {TransferSearch(setSearchValue, searchValue)}
            </>
          ) : (
            <S.TeamFilterActive>
              <button
                aria-label="Lukk lagfilter"
                onClick={() => {
                  setShowTeamFilter.off();
                  setSelectedTeam('');
                }}
              >
                <MdClose />
              </button>
              <S.SelectTeamForm>
                <select
                  tabIndex={-1}
                  aria-label={selectedTeam !== '' ? selectedTeam : 'Velg lag'}
                  ref={selectTeamFormRef}
                  onChange={(e) => setSelectedTeam(e.target.value)}
                >
                  <option
                    aria-hidden="true"
                    disabled={true}
                    defaultChecked={true}
                    selected
                  >
                    Velg lag
                  </option>
                  {allTournamentTeams?.length &&
                    allTournamentTeams.map((team: string) => (
                      <option aria-hidden="true" key={team} value={team}>
                        {team}
                      </option>
                    ))}
                </select>
              </S.SelectTeamForm>
              {selectedTeam !== '' && (
                <S.ViewTeamMenu>
                  {Object.values(ALL_IN_OUT).map((status: string) => (
                    <div>
                      <label htmlFor={status}>{status}</label>
                      <input
                        type="radio"
                        id={status}
                        name="lag"
                        value={status}
                        defaultChecked={ALL_IN_OUT.ALLE === status}
                        onClick={() => setTournamentPageStatus(status)}
                      />
                    </div>
                  ))}
                </S.ViewTeamMenu>
              )}
            </S.TeamFilterActive>
          )}
        </S.TopLeft>
        <S.BottomRight>
          <S.TypeFilter
            $isTournamentPage={!isTournamentPage}
            $isTransferTypeOpen={isTransferTypeOpen}
            $isNotAll={
              selectedTypeFilterOption !== TRANSFER_TYPE_FILTER_OPTIONS.ALLE
            }
            ref={typeFilterRef}
          >
            {FilterTransferTypes(
              selectedTypeFilterOption,
              isTransferTypeOpen,
              typesBtnRef,
              setIsTransferTypeOpen,
              typesFormRef,
              setSelectedTypeFilterOption,
            )}
          </S.TypeFilter>
          <S.SortingAndResults>
            {TransferViewToggle(isTableView, setIsTableView)}
            {Results(resultsAmount, visibleCount)}
          </S.SortingAndResults>
        </S.BottomRight>
      </S.TournamentMenuContent>
    </S.TransferMenu>
  );
};
