import Kit from './Kit';
import ShirtNumber from './ShirtNumber';

const TwoColorHorizontalStripesGradient = ({
  kitColor1 = '#f79025',
  kitColor2 = '#adadad',
  shirtNumber,
  shirtNumberColor,
  shirtNumberBackgroundColor,
  shirtNumberBackgroundOpacity,
  ...restProps
}: {
  size?: any;
  kitColor1: any;
  kitColor2: any;
  kitColor3: any;
  shirtNumber: any;
  shirtNumberColor: any;
  shirtNumberBackgroundColor: any;
  shirtNumberBackgroundOpacity: any;
}) => {
  const shadow = '#000000';
  const LineGradientDiv = shirtNumber + kitColor1 + kitColor2;
  const LineGradientDiv_URL =
    'url(#' + shirtNumber + kitColor1 + kitColor2 + ')';

  return (
    <Kit {...restProps}>
      <g>
        <g>
          <path
            style={{ fill: kitColor1 }}
            d="M69.15,2.82c2.77,0,7.29-0.36,8.68-0.53c1.38-0.17,3.61,0.2,4.68,0.53l2.08,3.19
                    c2.26,0.53,11.11,2.66,12.85,3.37c1.74,0.71,4,1.77,5.38,1.94c1.39,0.19,3.47,0.72,4.86,1.24c1.38,0.54,4.69,3.02,5.9,4.8
                    c1.21,1.77,8.24,16.3,9.8,18.62c1.57,2.3,12.42,22.34,12.94,23.23c0.52,0.88,1.03,3.19,0.17,3.36c-0.87,0.19-8.33,3.37-11.46,6.03
                    c-3.13,2.67-9.03,5.68-9.71,6.03c-0.71,0.35-1.14,0.14-1.83-0.58c-0.69-0.7-7.73-12.01-7.73-12.01c-1.21,2.66-1.21,5.67-1.04,7.62
                    c0.18,1.96-0.87,11.8-0.35,14.63c0.52,2.84,0.52,30.33,0.87,32.82c0.35,2.48,0.86,7.44,0.69,9.93c-0.18,2.48,0,5.67,0,7.09
                    c0,1.43-0.18,2.67-1.56,3.2c-1.39,0.53-13.89,3.9-35.24,3.19c-21.35,0.71-33.85-2.66-35.23-3.19c-1.38-0.53-1.56-1.77-1.56-3.2
                    c0-1.41,0.18-4.61,0-7.09c-0.18-2.49,0.35-7.45,0.69-9.93c0.35-2.49,0.35-29.98,0.87-32.82c0.51-2.83-0.52-12.68-0.35-14.63
                    c0.18-1.95,0.18-4.96-1.04-7.62c0,0-7.3,11.53-7.99,12.24c-0.69,0.71-0.87,0.71-1.56,0.35c-0.7-0.35-6.59-3.36-9.73-6.03
                    c-3.12-2.66-10.59-5.84-11.45-6.03c-0.87-0.17-0.35-2.48,0.17-3.36C2.5,58.33,13.43,38.47,15,36.16c1.56-2.32,8.5-17.04,9.72-18.8
                    c1.22-1.78,4.51-4.26,5.91-4.8c1.39-0.53,3.46-1.05,4.86-1.24c1.39-0.17,3.63-1.28,5.38-1.94c1.97-0.74,10.83-2.9,12.84-3.37
                    l2.08-3.19c0.87-0.35,3.3-0.71,4.69-0.53C61.86,2.46,66.38,2.82,69.15,2.82z"
          />
          <linearGradient
            id={LineGradientDiv}
            gradientUnits="userSpaceOnUse"
            x1="69.1518"
            y1="121.3188"
            x2="69.1518"
            y2="37.7617"
          >
            <stop offset="0" style={{ stopColor: kitColor2, stopOpacity: 0 }} />
            <stop offset="0.9914" style={{ stopColor: kitColor2 }} />
          </linearGradient>
          <path
            fill={LineGradientDiv_URL}
            d="M105.65,120.37c0.03,0.31,0.05,0.63,0.08,0.95H32.62c0.03-0.32,0.05-0.64,0.08-0.95h-0.04
                    c0.13-1.23,0.27-2.38,0.39-3.24c0.07-0.52,0.13-2.13,0.17-4.38h0.04c0.01-0.48,0.03-1,0.04-1.55h-0.05
                    c0.05-2.06,0.08-4.49,0.12-7.02h0.04c0.01-0.72,0.03-1.45,0.04-2.17H33.4c0.04-2.16,0.07-4.35,0.11-6.42h0.04
                    c0.01-0.95,0.04-1.87,0.05-2.76h-0.04c0.04-2.26,0.09-4.29,0.16-5.81h0.05c0.05-1.34,0.11-2.3,0.19-2.73
                    c0.04-0.19,0.07-0.39,0.08-0.63H34c0.11-1.25,0.07-3.17-0.03-5.21h0.04c-0.07-1.33-0.15-2.71-0.23-3.96h-0.04
                    c-0.12-2.04-0.23-3.79-0.17-4.62h0.04c-0.01-0.08,0-0.15,0-0.2c0.11-1.18,0.16-2.73-0.09-4.37h-0.05
                    c-0.16-1.07-0.46-2.18-0.94-3.24l0.2-9.33V37.76h72.63l0.15,6.37l0.07,2.81l0.09,9.17l0.11,5.93c-0.48,1.04-0.76,2.16-0.92,3.24
                    c-0.25,1.63-0.21,3.2-0.11,4.38c0,0.05,0.01,0.12,0,0.19c0.05,0.84-0.05,2.6-0.17,4.62c-0.08,1.26-0.16,2.64-0.23,3.96
                    c-0.09,2.04-0.15,3.96-0.03,5.21c0.01,0.24,0.04,0.46,0.08,0.64c0.08,0.42,0.15,1.38,0.2,2.72c0.07,1.53,0.12,3.55,0.16,5.81
                    c0.03,0.88,0.04,1.81,0.05,2.76c0.04,2.06,0.07,4.25,0.09,6.42c0.01,0.72,0.03,1.45,0.04,2.17c0.04,2.53,0.07,4.96,0.11,7.02
                    c0.01,0.55,0.03,1.06,0.04,1.55c0.04,2.25,0.11,3.86,0.17,4.38C105.39,117.98,105.52,119.14,105.65,120.37z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M26.5,72.03c-0.13,0.05-1.16,0.34-1.79,0.02c-0.7-0.35-6.59-3.37-9.72-6.03
                    c-3.12-2.67-10.58-5.85-11.45-6.03c-0.8-0.17-1.2-2.16-1.22-2.32l-1.46,2.71L1,62.72l10.14,5.05l6.66,4.87l6.11,3L26.5,72.03z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M135.91,58.36c-0.04,0.33-0.27,1.46-0.99,1.61c-0.87,0.19-8.54,3.5-11.68,6.15
                    c-3.12,2.67-9.03,5.68-9.71,6.04c-0.42,0.21-1.14,0.09-1.43,0l1.38,2.7l2.14,0.29l11.29-7.18l10.31-5.07l0.16-2.81L135.91,58.36z"
          />
          <path
            style={{ fill: kitColor2 }}
            d="M84.88,5.12l-1.97-2.88L78.69,1.4L68.48,2.24l-8.42-0.58l-4.77,0.58l-1.78,2.88
                    c-0.79,1.93-0.56,9.79,2.8,13.07c0.12,0.12,4.07-3.83,4.07-3.83s7.86,3.91,8.76,4.18c0.91-0.27,9.11-4.37,9.11-4.37
                    s2.58,3.96,2.75,3.84C84.82,14.9,85.75,6.74,84.88,5.12z M78.74,11.01c-4.03,1.85-9.63,6.13-9.63,6.13s-5.6-4.29-9.64-6.13
                    c-2.49-1.17-3.86-3.44-4.18-5.38c1.04-0.32,3.91-0.66,5.22-0.48c1.39,0.17,5.83,0.54,8.6,0.54s7.21-0.36,8.58-0.54
                    c1.33-0.17,4.17,0.15,5.22,0.5C82.6,7.58,81.25,9.85,78.74,11.01z"
          />
          <path
            style={{ fill: kitColor1 }}
            d="M106,127.04c-0.17,2.48,0,5.68,0,7.08c0,1.43-0.17,2.68-1.57,3.2c-1.38,0.54-13.89,3.91-35.24,3.2
                    c-21.35,0.71-33.84-2.67-35.24-3.2c-1.38-0.52-1.55-1.77-1.55-3.2c0-1.41,0.17-4.61,0-7.08c-0.09-1.41,0.03-3.62,0.21-5.72h73.16
                    C105.98,123.42,106.1,125.63,106,127.04z"
          />
          <path
            style={{ fill: kitColor1 }}
            d="M105.69,120.37H32.7c0.13-1.25,0.27-2.4,0.39-3.25c0.07-0.52,0.13-2.13,0.17-4.37h71.87
                    c0.04,2.24,0.11,3.84,0.17,4.37C105.43,117.97,105.56,119.12,105.69,120.37z"
          />
          <path
            style={{ fill: kitColor1 }}
            d="M105.09,111.19H33.31c0.04-2.06,0.08-4.49,0.11-7.02h71.56C105.01,106.72,105.05,109.14,105.09,111.19z"
          />
          <path
            style={{ fill: kitColor1 }}
            d="M104.94,102.01H33.45c0.03-2.16,0.05-4.35,0.09-6.42h71.3C104.89,97.65,104.92,99.85,104.94,102.01z"
          />
          <path
            style={{ fill: kitColor1 }}
            d="M104.8,92.83H33.6c0.04-2.28,0.11-4.29,0.17-5.81h70.85C104.69,88.55,104.76,90.56,104.8,92.83z"
          />
          <path
            style={{ fill: kitColor1 }}
            d="M104.35,83.66H34.04c0.12-1.23,0.07-3.16-0.03-5.21h70.37C104.29,80.5,104.23,82.43,104.35,83.66z"
          />
          <path
            style={{ fill: kitColor1 }}
            d="M104.61,74.48H33.79c-0.12-2.02-0.23-3.78-0.17-4.62h71.17C104.84,70.71,104.73,72.46,104.61,74.48z"
          />
          <path
            style={{ fill: kitColor1 }}
            d="M123.36,36.15c-1.55-2.32-8.5-17.04-9.72-18.8c-1.21-1.78-4.51-4.26-5.89-4.79
                    c-1.39-0.54-3.48-1.06-4.87-1.25c-1.38-0.17-3.63-1.25-5.37-1.94C95.76,8.66,86.9,6.53,84.64,5.99l-0.12-0.17
                    c0.87,1.62,0.31,9.08-3.52,12.2c-0.16,0.12-2.75-3.84-2.75-3.84s-8.2,4.1-9.11,4.37c-0.9-0.27-8.76-4.18-8.76-4.18
                    s-3.95,3.95-4.07,3.83c-3.36-3.28-3.4-10.26-2.61-12.19c-2.34,0.55-11.08,2.65-12.79,3.36c-1.74,0.7-3.99,1.77-5.38,1.94
                    c-1.39,0.19-3.47,0.71-4.86,1.25c-1.39,0.54-4.69,3.01-5.91,4.79c-1.22,1.77-8.16,16.49-9.71,18.8
                    C13.66,38.2,4.82,54.19,2.53,58.28c0,0,0,0,0,0.01c0.01,0.16,0.2,1.53,1,1.7c0.87,0.17,8.33,3.36,11.45,6.03
                    c3.13,2.65,9.03,5.68,9.72,6.03c0.63,0.32,1.34-0.03,1.47-0.08c0.01-0.01,0.01-0.01,0.01-0.01c2.37-3.56,6.39-9.91,6.39-9.91
                    c0.48,1.06,0.78,2.17,0.94,3.25h71.37c0.16-1.07,0.46-2.2,0.94-3.25c0,0,4.18,6.63,6.52,10.14v0.01c0.29,0.09,0.76,0.17,1.18-0.04
                    c0.68-0.36,6.59-3.37,9.71-6.04c3.13-2.65,10.81-5.96,11.68-6.15c0.72-0.15,0.95-1.27,0.99-1.61c0-0.01,0-0.03,0-0.03
                    C133.67,54.33,124.77,38.22,123.36,36.15z M32.73,37.76h72.63l0.21,6.37H32.73V37.76z M32.73,46.95h72.84v5.77H32.73V46.95z
                    M105.67,61.29H32.73v-5.17h72.94V61.29z"
          />
          <g>
            <path
              style={{ fill: shadow }}
              d="M82.92,5.64c-0.32,1.94-1.67,4.21-4.18,5.37c-4.03,1.85-9.63,6.13-9.63,6.13s-5.6-4.29-9.64-6.13
                        c-2.49-1.17-3.86-3.44-4.18-5.38c1.04-0.32,3.91-0.66,5.22-0.48c1.39,0.17,5.83,0.54,8.6,0.54s7.21-0.36,8.58-0.54
                        C79.02,4.97,81.86,5.29,82.92,5.64z"
            />
            <path
              opacity="0.16"
              d="M136.63,62.51c0.86-0.18,0.35-2.48-0.17-3.37c-0.3-0.51-3.93-7.08-7.31-13.12
                        c-6.25,2.94-14.52-0.07-23.84-13.57l0.34,9.94l0.12,19.66c0,0,7.03,11.31,7.73,12.01c0.69,0.71,1.25,0.86,1.95,0.51
                        c0.68-0.36,6.58-3.37,9.71-6.03C128.3,65.88,135.76,62.69,136.63,62.51z"
            />
            <path
              opacity="0.16"
              d="M1.64,62.52c-0.86-0.18-0.34-2.48,0.18-3.37c0.29-0.51,3.92-7.08,7.31-13.12
                        c6.25,2.94,15.6-0.46,24.23-14.08l-0.57,13.53l-0.01,16.45c0,0-7.58,11.94-8.27,12.64c-0.7,0.71-1,0.35-1.69,0.01
                        c-0.69-0.36-6.59-3.37-9.72-6.03C9.98,65.88,2.51,62.69,1.64,62.52z"
            />
            <path
              opacity="0.16"
              d="M104.41,137.8c1.38-0.53,1.56-1.77,1.56-3.19c0-1.42-0.18-4.61,0-7.09c0.16-2.3-0.27-6.7-0.6-9.32
                        c-5.17,4.71-8.97,14.72-10.63,21.6C100.41,138.99,103.73,138.06,104.41,137.8z"
            />
            <path
              opacity="0.16"
              d="M33.68,137.74c-1.38-0.53-1.56-1.77-1.56-3.19c0-1.42,0.18-4.61,0-7.09c-0.15-2.3,0.27-6.7,0.61-9.32
                        c5.16,4.71,8.96,14.72,10.63,21.6C37.69,138.92,34.36,138,33.68,137.74z"
            />
            <g>
              <path
                d="M59.63,2.24c0.3,0,0.59,0.01,0.84,0.05c1.39,0.18,5.91,0.53,8.68,0.53s7.29-0.36,8.68-0.53c0.24-0.03,0.5-0.04,0.78-0.04
                            c1.33,0,3.01,0.3,3.9,0.58l2.08,3.19c2.26,0.53,11.11,2.66,12.85,3.37c1.74,0.71,4,1.77,5.38,1.94c1.39,0.19,3.47,0.72,4.86,1.24
                            c1.38,0.54,4.69,3.02,5.9,4.8c1.21,1.77,8.24,16.3,9.8,18.62c1.57,2.3,12.42,22.34,12.94,23.23c0.52,0.88,1.03,3.19,0.17,3.36
                            c-0.87,0.19-8.33,3.37-11.46,6.03c-3.13,2.67-9.03,5.68-9.71,6.03c-0.22,0.11-0.42,0.17-0.61,0.17c-0.4,0-0.75-0.25-1.22-0.74
                            c-0.69-0.7-7.73-12.01-7.73-12.01c-1.21,2.66-1.21,5.67-1.04,7.62c0.18,1.96-0.87,11.8-0.35,14.63
                            c0.52,2.84,0.52,30.33,0.87,32.82c0.35,2.48,0.86,7.44,0.69,9.93c-0.18,2.48,0,5.67,0,7.09c0,1.43-0.18,2.67-1.56,3.2
                            c-1.26,0.48-11.64,3.29-29.44,3.29c-1.85,0-3.78-0.03-5.8-0.1c-2.01,0.07-3.94,0.1-5.8,0.1c-17.81,0-28.18-2.81-29.44-3.29
                            c-1.38-0.53-1.56-1.77-1.56-3.2c0-1.41,0.18-4.61,0-7.09c-0.18-2.49,0.35-7.45,0.69-9.93c0.35-2.49,0.35-29.98,0.87-32.82
                            c0.51-2.83-0.52-12.68-0.35-14.63c0.18-1.95,0.18-4.96-1.04-7.62c0,0-7.3,11.53-7.99,12.24c-0.4,0.42-0.63,0.59-0.89,0.59
                            c-0.18,0-0.38-0.09-0.67-0.23c-0.7-0.35-6.59-3.36-9.73-6.03c-3.12-2.66-10.59-5.84-11.45-6.03c-0.87-0.17-0.35-2.48,0.17-3.36
                            C2.5,58.33,13.43,38.47,15,36.16c1.56-2.32,8.5-17.04,9.72-18.8c1.22-1.78,4.51-4.26,5.91-4.8c1.39-0.53,3.46-1.05,4.86-1.24
                            c1.39-0.17,3.63-1.28,5.38-1.94c1.97-0.74,10.83-2.9,12.84-3.37l2.08-3.19C56.5,2.53,58.26,2.24,59.63,2.24 M59.63,1.24
                            c-1.44,0-3.34,0.29-4.22,0.65c-0.19,0.08-0.35,0.21-0.46,0.38l-1.86,2.86C50.73,5.69,42.49,7.7,40.51,8.44
                            c-0.54,0.2-1.13,0.45-1.7,0.69c-1.22,0.51-2.61,1.09-3.45,1.2c-1.52,0.21-3.66,0.76-5.09,1.3c-1.65,0.64-5.1,3.31-6.38,5.17
                            c-0.62,0.9-2.42,4.52-4.5,8.72c-2.08,4.19-4.44,8.93-5.22,10.09C12.96,37.39,6.48,49.06,3,55.33c-1.03,1.85-1.77,3.18-1.89,3.39
                            c-0.45,0.78-1.11,2.8-0.57,3.97c0.21,0.47,0.6,0.78,1.08,0.87c0.67,0.16,7.95,3.22,10.99,5.81c3.22,2.75,9.25,5.82,9.93,6.17
                            c0.35,0.18,0.7,0.34,1.12,0.34c0.69,0,1.15-0.42,1.61-0.89c0.59-0.6,4.68-6.98,7-10.64c0.46,1.89,0.44,3.81,0.31,5.24
                            c-0.08,0.85,0.04,2.8,0.19,5.27c0.2,3.42,0.46,7.69,0.17,9.28c-0.28,1.54-0.41,8.92-0.55,19.19c-0.09,6.21-0.18,12.63-0.32,13.67
                            c-0.38,2.7-0.88,7.63-0.7,10.13c0.12,1.74,0.07,3.87,0.03,5.44c-0.02,0.63-0.03,1.18-0.03,1.58c0,1.07,0,3.29,2.2,4.13
                            c1.75,0.67,12.53,3.36,29.8,3.36c1.9,0,3.85-0.03,5.8-0.1c1.94,0.06,3.89,0.1,5.8,0.1c17.24,0,28.05-2.69,29.8-3.35
                            c2.2-0.84,2.2-3.07,2.2-4.13c0-0.4-0.01-0.95-0.03-1.58c-0.04-1.56-0.1-3.7,0.03-5.44c0.17-2.52-0.32-7.44-0.7-10.13
                            c-0.15-1.05-0.24-7.48-0.32-13.7c-0.14-10.25-0.27-17.62-0.55-19.16c-0.3-1.6-0.04-5.89,0.17-9.33c0.15-2.44,0.26-4.37,0.19-5.21
                            c-0.13-1.43-0.15-3.34,0.31-5.22c2.25,3.6,6.16,9.8,6.75,10.4c0.52,0.54,1.12,1.04,1.93,1.04c0.34,0,0.69-0.09,1.05-0.27
                            l0.08-0.04c2.02-1.04,7.01-3.72,9.84-6.13c2.95-2.5,10.12-5.6,11.02-5.81c0.46-0.09,0.84-0.4,1.05-0.87
                            c0.51-1.13-0.09-3.15-0.57-3.97c-0.12-0.21-0.94-1.71-1.98-3.61c-3.42-6.26-9.79-17.91-11-19.68c-0.78-1.16-3.16-5.85-5.26-9.99
                            c-2.11-4.15-3.93-7.74-4.54-8.64c-1.36-1.99-4.88-4.58-6.36-5.16c-1.45-0.55-3.58-1.09-5.09-1.3c-0.97-0.12-2.58-0.8-4-1.4
                            c-0.4-0.17-0.78-0.33-1.14-0.48C95.98,7.7,87.19,5.59,85.22,5.13l-1.86-2.86c-0.13-0.2-0.32-0.34-0.54-0.41
                            c-1.01-0.31-2.77-0.62-4.2-0.62c-0.33,0-0.63,0.02-0.9,0.05c-1.45,0.18-5.91,0.53-8.55,0.53c-2.64,0-7.13-0.34-8.55-0.53
                            C60.31,1.25,59.99,1.24,59.63,1.24L59.63,1.24z"
              />
            </g>
          </g>
        </g>
      </g>
      <ShirtNumber
        shirtNumber={shirtNumber}
        shirtNumberColor={shirtNumberColor}
        shirtNumberBackgroundColor={shirtNumberBackgroundColor}
        shirtNumberBackgroundOpacity={shirtNumberBackgroundOpacity}
      />
    </Kit>
  );
};

export default TwoColorHorizontalStripesGradient;
