import TwoColorCheckeredDiagonal from './kits/TwoColorCheckeredDiagonal';
import TwoColorCheckers from './kits/TwoColorCheckers';
import TwoColorCollar from './kits/TwoColorCollar';
import TwoColorCollarAndEdges from './kits/TwoColorCollarAndEdges';
import TwoColorCollarAndSides from './kits/TwoColorCollarAndSides';
import TwoColorCollarArmsAndEdges from './kits/TwoColorCollarArmsAndEdges';
import TwoColorCollarArmsAndSides from './kits/TwoColorCollarArmsAndSides';
import TwoColorCollarArmsBottom from './kits/TwoColorCollarArmsBottom';
import TwoColorCollarInvertedArmsAndEdges from './kits/TwoColorCollarInvertedArmsAndEdges';
import TwoColorCollarShouldersEdgesAndSides from './kits/TwoColorCollarShouldersEdgesAndSides';
import TwoColorCollarShouldersEdgesAndThickSides from './kits/TwoColorCollarShouldersEdgesAndThickSides';
import TwoColorDiagonalFullColor from './kits/TwoColorDiagonalFullColor';
import TwoColorDiagonalStripeTopLeftToRight from './kits/TwoColorDiagonalStripeTopLeftToRight';
import TwoColorDiagonalStripeTopRightToLeft from './kits/TwoColorDiagonalStripeTopRightToLeft';
import TwoColorFullVerticalStripes from './kits/TwoColorFullVerticalStripes';
import TwoColorGradient from './kits/TwoColorGradient';
import TwoColorGradientEdges from './kits/TwoColorGradientEdges';
import TwoColorHorizontalSplit from './kits/TwoColorHorizontalSplit';
import TwoColorHorizontalStripes from './kits/TwoColorHorizontalStripes';
import TwoColorHorizontalStripesArms from './kits/TwoColorHorizontalStripesArms';
import TwoColorHorizontalStripesGradient from './kits/TwoColorHorizontalStripesGradient';
import TwoColorHorizontalStripesShoulders from './kits/TwoColorHorizontalStripesShoulders';
import TwoColorOneHorizontalStripe from './kits/TwoColorOneHorizontalStripe';
import TwoColorOneHorizontalStripeArms from './kits/TwoColorOneHorizontalStripeArms';
import TwoColorOneVerticalStripe from './kits/TwoColorOneVerticalStripe';
import TwoColorThinHorizontalStripes from './kits/TwoColorThinHorizontalStripes';
import TwoColorThinHorizontalStripesArms from './kits/TwoColorThinHorizontalStripesArms';
import TwoColorThinVerticalStripes from './kits/TwoColorThinVerticalStripes';
import TwoColorThinVerticalStripesArms from './kits/TwoColorThinVerticalStripesArms';
import TwoColorTwoDiagonaStripesTopRightToLeft from './kits/TwoColorTwoDiagonaStripesTopRightToLeft';
import TwoColorTwoDiagonalStripesTopLeftToRight from './kits/TwoColorTwoDiagonalStripesTopLeftToRight';
import TwoColorVariableVerticalStripes from './kits/TwoColorVariableVerticalStripes';
import TwoColorVerticalStripes from './kits/TwoColorVerticalStripes';
import TwoColorVerticalStripesFullLength from './kits/TwoColorVerticalStripesFullLength';
import TwoColorWithArmsCollarArmsBottom from './kits/TwoColorWithArmsCollarArmsBottom';

export const kitTypes = {
  8: TwoColorCollarAndSides,
  9: TwoColorCollarAndEdges,
  10: TwoColorCollarShouldersEdgesAndSides,
  11: TwoColorCollarShouldersEdgesAndThickSides,
  12: TwoColorCollarArmsAndEdges,
  13: TwoColorCollarArmsAndSides,
  14: TwoColorCollarInvertedArmsAndEdges,
  // 15: undefined,
  16: TwoColorOneVerticalStripe,
  17: TwoColorThinVerticalStripes,
  18: TwoColorFullVerticalStripes,
  19: TwoColorThinVerticalStripesArms,
  20: TwoColorVerticalStripes,
  21: TwoColorVerticalStripesFullLength,
  22: TwoColorVariableVerticalStripes,
  23: TwoColorHorizontalSplit,
  24: TwoColorOneHorizontalStripe,
  25: TwoColorOneHorizontalStripeArms,
  26: TwoColorHorizontalStripesArms,
  27: TwoColorHorizontalStripes,
  28: TwoColorHorizontalStripesShoulders,
  29: TwoColorThinHorizontalStripesArms,
  30: TwoColorThinHorizontalStripes,
  31: TwoColorHorizontalStripesGradient,
  32: TwoColorGradient,
  33: TwoColorGradientEdges,
  34: TwoColorDiagonalFullColor,
  35: TwoColorDiagonalStripeTopLeftToRight,
  36: TwoColorDiagonalStripeTopRightToLeft,
  37: TwoColorTwoDiagonalStripesTopLeftToRight,
  38: TwoColorTwoDiagonaStripesTopRightToLeft,
  39: TwoColorCheckers,
  40: TwoColorCheckeredDiagonal,
  41: TwoColorCollarArmsBottom,
  42: TwoColorWithArmsCollarArmsBottom,
  43: TwoColorCollar,
};

export const Kit = ({
  type,
  size,
  kitColor1,
  kitColor2,
  kitColor3,
  shirtNumber,
  shirtNumberColor,
  shirtNumberBackgroundColor,
  shirtNumberBackgroundOpacity,
}: {
  type: number;
  size: number;
  kitColor1?: string;
  kitColor2?: string;
  kitColor3?: string;
  shirtNumber?: number;
  shirtNumberColor?: string;
  shirtNumberBackgroundColor?: string;
  shirtNumberBackgroundOpacity?: number;
}) => {
  const KitComponent = kitTypes[type as keyof typeof kitTypes] || kitTypes[43];

  return (
    <KitComponent
      size={size}
      kitColor1={kitColor1}
      kitColor2={kitColor2}
      kitColor3={kitColor3}
      shirtNumber={shirtNumber}
      shirtNumberColor={shirtNumberColor}
      shirtNumberBackgroundColor={shirtNumberBackgroundColor}
      shirtNumberBackgroundOpacity={shirtNumberBackgroundOpacity}
    />
  );
};

export default Kit;
