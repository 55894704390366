import Icon from '../Icon/Icon';

export const BulletIcon = (props: any) => (
  <Icon viewbox="0 0 48 48" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.625 48L18.625 12.9152L29.374 12.9152L29.374 48L18.625 48Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.4766 48L31.5252 48Z" />
    <path
      d="M16.4766 48L31.5252 48"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.374 12.9141C29.374 6.64626 26.9684 0.53118 23.9995 0.53118C21.0306 0.531181 18.625 6.64626 18.625 12.9141"
    />
    <path
      d="M29.374 12.9141C29.374 6.64626 26.9684 0.53118 23.9995 0.53118C21.0306 0.531181 18.625 6.64626 18.625 12.9141"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default BulletIcon;
