export const SPORTEDIT_API_BASE_URL = 'https://api.sportedit.ntb.no';
export const NIFS_API_BASE_URL = 'https://v3api.nifs.no';
export const NIFS_IDENTIFICATOR = '353a5332-1c77-4420-81c4-28aecdb8cad0';

export const API_VERSION = {
  V2: 'v2',
  V3: 'v3',
  V4: 'v4',
  V5: 'v5',
  V6: 'v6',
  V7: 'v7',
};

interface FetchType {
  url: string;
  method?: 'GET' | 'POST' | 'DELETE';
  body?: string;
  headers?:
    | false
    | {
        'Nifs-Identificator': string;
      }
    | {
        Authorization?: string | undefined;
        CustomerUUID?: string | undefined;
        Origin?: string | undefined;
      };
}

export const Fetch = async ({
  url,
  method = 'GET',
  headers,
  body,
}: FetchType) => {
  const response = await fetch(url, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': '*',
      ...headers,
    },
    body,
  });
  if (!response.ok) {
    const json = await response.json();

    if (response.status === 404 || response.status === 204) {
      return [];
    } else {
      throw new Error(json.error);
    }
  }

  return await response.json();
};
