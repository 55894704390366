import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '@ntb-sport/constants';
import {
  fetchEventParticipantsByCountryAndDate,
  FetchEventParticipantsByCountryAndDateProps,
} from './fetch';
import { getIsNeoSportGroup } from '@ntb-sport/helpers';

interface UseQueryEventParticipantsByCountryAndDateParams
  extends FetchEventParticipantsByCountryAndDateProps {
  sportGroup: string;
}

interface UseQueryEventParticipantsByCountryAndDate {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: UseQueryEventParticipantsByCountryAndDateParams;
  options?: Record<string, unknown>;
}

export const useQueryEventParticipantsByCountryAndDate = ({
  queryKey = QUERY_KEYS.EVENT_PARTICIPANTS_BY_COUNTRY_AND_DATE,
  queryKeyParams,
  params,
  options,
}: UseQueryEventParticipantsByCountryAndDate) => {
  const isNeoSportGroup = getIsNeoSportGroup(params.sportGroup);

  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchEventParticipantsByCountryAndDate(params),
    enabled:
      isNeoSportGroup &&
      Boolean(params.customerUUID) &&
      Boolean(params.token) &&
      Boolean(params.countryUUID) &&
      Boolean(params.date),
    ...options,
  });

  return { data, isLoading, error };
};
