import { EventType, SportType } from '../types';
import { MainEvent } from '../MainEvent/MainEvent';
import { useBem } from '@ntb-sport/hooks';
import { COMPONENT_IDS } from '@ntb-sport/constants';
import './main-events.css';

interface MainEventsProps {
  events: EventType[];
  date: string;
  sport: SportType;
  onClick?: ({
    sportGroup,
    sportIdTranslated,
    sportId,
    date,
    uuid,
  }: {
    sportGroup: string;
    sportIdTranslated: string;
    sportId: string;
    date: string | false;
    uuid: string;
  }) => Promise<boolean> | void;
}

export const MainEvents = ({
  events,
  date,
  sport,
  onClick,
}: MainEventsProps) => {
  const bem = useBem(COMPONENT_IDS.SPORTEDIT_CALENDAR);

  return (
    <ul className={bem('main-events')}>
      {Object?.values(events)
        ?.sort((a: EventType, b: EventType) =>
          new Date(a?.timestamp) < new Date(b?.timestamp) ? -1 : 0,
        )
        ?.map((event: EventType) => (
          <MainEvent
            key={event?.uuid}
            event={event}
            date={date}
            sport={sport}
            onClick={onClick}
          />
        ))}
    </ul>
  );
};
