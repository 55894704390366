import { Icon } from '../../chakra';

export const SecondCardIcon = ({
  fillColor1,
  fillColor2,
  cardId,
  ...rest
}: any) => (
  <Icon {...rest}>
    <g>
      <path
        fill={fillColor1 || 'currentColor'}
        d="M14.1,2.5h-3.2c-2.3,0-4.2,1.9-4.2,4.2v12.4c0,0.4,0.1,0.8,0.2,1.2c-1.4,0.2-2.8-0.7-3.2-2.2L0.4,6.4
		C0,4.8,0.9,3.1,2.5,2.7l7.8-2.3C12,0.1,13.6,1,14.1,2.5z"
      />
      <path
        fill={fillColor2 || 'currentColor'}
        d="M22.4,6.8v12.4c0,1.7-1.4,3.1-3.1,3.1H11c-1.4,0-2.5-0.9-2.9-2.2C8,19.9,8,19.6,8,19.2V6.8
		c0-1.7,1.4-3.1,3.1-3.1h8.3C21,3.8,22.4,5.2,22.4,6.8z"
      />
    </g>
  </Icon>
);

export default SecondCardIcon;
