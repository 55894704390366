import { useContext, useEffect, useState } from 'react';

import { ArrowIcon } from '../../../icons';

import { VisibilityContext } from 'react-horizontal-scrolling-menu';

import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import IconButton from '../../../chakra/IconButton/IconButton';
import './arrows.css';
import { useBem } from '@ntb-sport/hooks';
import classNames from 'classnames';

export const LeftArrow = () => {
  const bem = useBem(COMPONENT_IDS.SCOPE_MENU);

  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete,
  } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !initComplete || (initComplete && isFirstItemVisible),
  );
  useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <IconButton
      data-element-id={ELEMENT_IDS.BUTTON}
      disabled={disabled}
      size="xs"
      aria-label=""
      icon={<ArrowIcon className={bem('prev-arrow-icon')} />}
      onClick={() => scrollPrev()}
      className={classNames(bem('arrow-button', 'prev'), {
        [bem('arrow-button', 'disabled')]: disabled,
      })}
    />
  );
};

export const RightArrow = () => {
  const bem = useBem(COMPONENT_IDS.EVENT_MENU);

  const { isLastItemVisible, scrollNext, visibleItemsWithoutSeparators } =
    useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible,
  );
  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <IconButton
      data-element-id={ELEMENT_IDS.BUTTON}
      disabled={disabled}
      size="xs"
      aria-label=""
      icon={<ArrowIcon className={bem('next-arrow-icon')} />}
      onClick={() => scrollNext()}
      className={classNames(bem('arrow-button', 'next'), {
        [bem('arrow-button', 'disabled')]: disabled,
      })}
    />
  );
};
