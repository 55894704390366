import * as S from './Stages.styled';

import { useMemo } from 'react';
import { Matches } from '../Matches/Matches';
import { Header } from './Header/Header';
import stageIncludesSearchQuery from '../helpers/stageIncludesSearchQuery';

interface StagesProps {
  stages: any;
  debouncedSearchQuery: string;
  onClickStandings?: any;
  onMatchClick: any;
  onClickSubscribeMatch: any;
  subscribedMatches?: number[];
  customerConcernId: any;
  filterSubscribedMatches?: boolean;
}

export const Stages = ({
  stages,
  debouncedSearchQuery,
  onClickStandings,
  onMatchClick,
  onClickSubscribeMatch,
  subscribedMatches,
  customerConcernId,
  filterSubscribedMatches,
}: StagesProps) => {
  const cachedStageIncludesSearchQuery = useMemo(
    () => stageIncludesSearchQuery,
    [debouncedSearchQuery],
  );

  return (
    <S.Stages>
      {Object.values(stages)
        ?.filter((stage: any) =>
          filterSubscribedMatches
            ? subscribedMatches?.some(
                (subscribedMatch: any) => subscribedMatch.stageId === stage.id,
              )
            : cachedStageIncludesSearchQuery(stage, debouncedSearchQuery),
        )
        ?.sort((a: any, b: any) => (a.priority < b.priority ? -1 : 0))
        .map((stage: any) => (
          <S.Stage key={stage.id}>
            <Header
              stages={stages}
              stage={stage}
              debouncedSearchQuery={debouncedSearchQuery}
              onClickStandings={onClickStandings}
            />

            <Matches
              matches={stage.matches}
              debouncedSearchQuery={debouncedSearchQuery}
              onClickStandings={onClickStandings}
              onMatchClick={onMatchClick}
              onClickSubscribeMatch={onClickSubscribeMatch}
              subscribedMatches={subscribedMatches}
              customerConcernId={customerConcernId}
              filterSubscribedMatches={filterSubscribedMatches}
            />
          </S.Stage>
        ))}
    </S.Stages>
  );
};
