import { WIDGET_IDS } from './lib/common';
import * as widgetCustomerFeedback from './lib/configs/widget-customer-feedback';
import * as widgetEventResult from './lib/configs/widget-event-result';
import * as widgetExample from './lib/configs/widget-example';
import * as widgetFairPlayTable from './lib/configs/widget-fair-play-table';
import * as widgetMatchLineup from './lib/configs/widget-match-lineup';
import * as widgetMatchResult from './lib/configs/widget-match-result';
import * as widgetMedalTable from './lib/configs/widget-medal-table';
import * as widgetNextMatch from './lib/configs/widget-next-match';
import * as widgetSeasonFixtures from './lib/configs/widget-season-fixtures';
//import * as widgetSeasonStandings from './lib/configs/widget-season-standings';
import * as widgetSportCalendar from './lib/configs/widget-sport-calendar';
import * as widgetTable from './lib/configs/widget-table';
import * as widgetTeamFixtures from './lib/configs/widget-team-fixtures';
import * as widgetTopList from './lib/configs/widget-top-list';
import * as widgetLiveFeed from './lib/configs/widget-live-feed';
import * as widgetEventCalendar from './lib/configs/widget-event-calendar';
import * as widgetMultisportGeneric from './lib/configs/widget-multisport-generic';
import * as widgetMedalTableOlympics from './lib/configs/widget-medal-table-olympics';

export const WIDGET_CONFIG = {
  [WIDGET_IDS.SPORT_CALENDAR]: widgetSportCalendar.config,
  [WIDGET_IDS.TABLE]: widgetTable.config,
  [WIDGET_IDS.MATCH_RESULT]: widgetMatchResult.config,
  [WIDGET_IDS.MATCH_LINEUP]: widgetMatchLineup.config,
  [WIDGET_IDS.EVENT_RESULT]: widgetEventResult.config,
  [WIDGET_IDS.TEAM_FIXTURES]: widgetTeamFixtures.config,
  [WIDGET_IDS.SEASON_FIXTURES]: widgetSeasonFixtures.config,
  [WIDGET_IDS.MEDAL_TABLE]: widgetMedalTable.config,
  [WIDGET_IDS.TOP_LIST]: widgetTopList.config,
  [WIDGET_IDS.NEXT_MATCH]: widgetNextMatch.config,
  [WIDGET_IDS.FAIR_PLAY_TABLE]: widgetFairPlayTable.config,
  [WIDGET_IDS.CUSTOMER_FEEDBACK]: widgetCustomerFeedback.config,
  //[WIDGET_IDS.SEASON_STANDINGS]: widgetSeasonStandings.config,
  [WIDGET_IDS.EXAMPLE]: widgetExample.config,
  [WIDGET_IDS.LIVE_FEED]: widgetLiveFeed.config,
  [WIDGET_IDS.EVENT_CALENDAR]: widgetEventCalendar.config,
  [WIDGET_IDS.MULTISPORT_GENERIC]: widgetMultisportGeneric.config,
  [WIDGET_IDS.MEDAL_TABLE_OLYMPICS]: widgetMedalTableOlympics.config,
};

export const WIDGET_SPORTSDATA_CONFIG = {
  [WIDGET_IDS.CUSTOMER_FEEDBACK]: widgetCustomerFeedback.sportsdataConfig,
  [WIDGET_IDS.EVENT_RESULT]: widgetEventResult.sportsdataConfig,
  [WIDGET_IDS.FAIR_PLAY_TABLE]: widgetFairPlayTable.sportsdataConfig,
  [WIDGET_IDS.MATCH_LINEUP]: widgetMatchLineup.sportsdataConfig,
  [WIDGET_IDS.MATCH_RESULT]: widgetMatchResult.sportsdataConfig,
  [WIDGET_IDS.MEDAL_TABLE]: widgetMedalTable.sportsdataConfig,
  [WIDGET_IDS.NEXT_MATCH]: widgetNextMatch.sportsdataConfig,
  [WIDGET_IDS.SEASON_FIXTURES]: widgetSeasonFixtures.sportsdataConfig,
  [WIDGET_IDS.SPORT_CALENDAR]: widgetSportCalendar.sportsdataConfig,
  [WIDGET_IDS.TABLE]: widgetTable.sportsdataConfig,
  [WIDGET_IDS.TEAM_FIXTURES]: widgetTeamFixtures.sportsdataConfig,
  [WIDGET_IDS.TOP_LIST]: widgetTopList.sportsdataConfig,
  //[WIDGET_IDS.SEASON_STANDINGS]: widgetSeasonStandings.sportsdataConfig,
  [WIDGET_IDS.EXAMPLE]: widgetExample.sportsdataConfig,
  [WIDGET_IDS.LIVE_FEED]: widgetLiveFeed.sportsdataConfig,
  [WIDGET_IDS.EVENT_CALENDAR]: widgetEventCalendar.sportsdataConfig,
  [WIDGET_IDS.MULTISPORT_GENERIC]: widgetMultisportGeneric.sportsdataConfig,
  [WIDGET_IDS.MEDAL_TABLE_OLYMPICS]: widgetMedalTableOlympics.sportsdataConfig,
};

export * from './lib/common';
export * from './lib/styles';
export * from './lib/styles/index';
