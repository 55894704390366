import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import { CSS_VARIABLES } from '../index';

export const TEAM_STANDINGS_STYLES = [
  {
    name: 'Root',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_STANDINGS}']`,
    variables: [CSS_VARIABLES.BACKGROUND_COLOR],
  },
  {
    name: 'Header',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_STANDINGS}'] [data-element-id='${ELEMENT_IDS.HEADER}']`,
    variables: [CSS_VARIABLES.BORDER_COLOR, CSS_VARIABLES.FONT_COLOR],
  },
  {
    name: 'Header Button',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_STANDINGS}'] [data-element-id='${ELEMENT_IDS.HEADER}'] [data-element-id='${ELEMENT_IDS.BUTTON}']`,
    variables: [
      CSS_VARIABLES.BACKGROUND_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR__HOVER,
      CSS_VARIABLES.BACKGROUND_COLOR__ACTIVE,
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.FONT_COLOR__HOVER,
      CSS_VARIABLES.FONT_COLOR__ACTIVE,
    ],
  },
  {
    name: 'Row',
    selector: `[data-style-id='default'] [data-component-id='${COMPONENT_IDS.TEAM_STANDINGS}'] [data-element-id='${ELEMENT_IDS.ROW}']`,
    variables: [
      CSS_VARIABLES.BACKGROUND_COLOR,
      CSS_VARIABLES.BACKGROUND_COLOR__HOVER,
      CSS_VARIABLES.BACKGROUND_COLOR__ACTIVE,
      CSS_VARIABLES.FONT_COLOR,
      CSS_VARIABLES.FONT_COLOR__ACTIVE,
      CSS_VARIABLES.BORDER_COLOR,
    ],
  },
];
