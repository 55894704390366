import { SPORT_GROUP } from '@ntb-sport/constants';
import { Fetch, NIFS_IDENTIFICATOR } from '../../fetch';

export interface FetchTeamStagesType {
  apiBaseUrl: string;
  sportGroup: string;
  teamId: string | number;
}

export const fetchTeamStages = async ({
  apiBaseUrl,
  sportGroup,
  teamId,
}: FetchTeamStagesType) => {
  const teamPath =
    sportGroup === SPORT_GROUP.ICE_HOCKEY || sportGroup === SPORT_GROUP.HANDBALL
      ? 'nifTeams'
      : 'teams';

  const stagePath =
    sportGroup === SPORT_GROUP.ICE_HOCKEY || sportGroup === SPORT_GROUP.HANDBALL
      ? 'nifStages'
      : 'stages';

  const json = await Fetch({
    url: `${apiBaseUrl}/${teamPath}/${teamId}/${stagePath}/?active=1`,
    headers: {
      'Nifs-Identificator': NIFS_IDENTIFICATOR,
    },
  });
  return json;
};
