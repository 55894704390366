import TransferPlayerCard from '../TransferPlayerCard/TransferPlayerCard';
import TransferPlayerListItem from '../TransferListItem/TransferPlayerListItem';
import { useContext, useEffect } from 'react';
import * as S from '../../../../../../apps/livecenter/components/Transfers/Transfers.styled';
import {
  ALL_IN_OUT,
  handleTransferType,
  initialViewCount,
  TRANSFER_TYPE_FILTER_OPTIONS,
} from './TransferHelpers';
import TransferContext, {
  PlayerTransferInterface,
} from '../TransferMenu/libs/TransferMenuContext';

interface TransferListProps {
  gender?: string;
  toPlayerUrl?: string;
  linkToTeamUrl?: string;
  transferCountry?: string | null;
  isTournamentPage?: boolean;
  teamId?: string;
  LinkComponent?: React.ComponentType<any> | null | undefined;
}

export const TransferList = ({
  gender,
  toPlayerUrl,
  linkToTeamUrl,
  transferCountry,
  isTournamentPage,
  teamId,
  LinkComponent,
}: TransferListProps) => {
  const {
    itemList,
    filteredList,
    setFilteredList,
    isTableView,
    searchValue,
    visibleCount,
    setVisibleCount,
    resultsAmount,
    setResultsAmount,
    selectedTypeFilterOption,
    currentTeamTransferStatus,
    selectedTeam,
    tournamentPageStatus,
  } = useContext(TransferContext);
  let allDataLoaded = false;

  const handleTeamTransferStatuses = (array: PlayerTransferInterface[]) => {
    return array.filter((transfer: PlayerTransferInterface) => {
      if (currentTeamTransferStatus === ALL_IN_OUT.ALLE) {
        return transfer;
      }
      if (currentTeamTransferStatus === ALL_IN_OUT.INN) {
        return transfer.toTeam.id === Number(teamId);
      }
      if (currentTeamTransferStatus === ALL_IN_OUT.UT) {
        return transfer.fromTeam.id === Number(teamId);
      }
    });
  };

  /**
   * Filters list if transfer type filter is active, updates results amount and allDataLoaded state
   * @param array - array of transfers
   * @returns sorted array of transfers
   */
  function handleSorting(array: PlayerTransferInterface[]) {
    array = array.filter((transfer: PlayerTransferInterface) => {
      let type = handleTransferType(transfer?.type);

      switch (selectedTypeFilterOption) {
        case TRANSFER_TYPE_FILTER_OPTIONS.OVERGANG:
          return type === TRANSFER_TYPE_FILTER_OPTIONS.OVERGANG;
        case TRANSFER_TYPE_FILTER_OPTIONS.LÅN:
          return type === TRANSFER_TYPE_FILTER_OPTIONS.LÅN;
        case TRANSFER_TYPE_FILTER_OPTIONS.ALLE:
        default:
          return transfer;
      }
    });

    allDataLoaded = array.length <= visibleCount;

    if (array?.length !== resultsAmount) {
      setResultsAmount(array.length);
    }

    return array.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB.getTime() - dateA.getTime();
    });
  }

  const handleList = (array: PlayerTransferInterface[]) => {
    array = isTournamentPage
      ? handleSorting(array)
      : handleSorting(handleTeamTransferStatuses(array));

    return array.slice(0, visibleCount);
  };

  const handleTeamFilter = () => {
    if (selectedTeam === '') {
      setResultsAmount(itemList.length);
      setFilteredList([]);
      return;
    }
    if (selectedTeam !== '') {
      let filteredlist = itemList.filter(
        (transfer: PlayerTransferInterface) => {
          const toTeamMatch = transfer?.toTeam?.name
            .toLowerCase()
            .includes(selectedTeam.toLowerCase());
          const fromTeamMatch = transfer?.fromTeam?.name
            .toLowerCase()
            .includes(selectedTeam.toLowerCase());

          switch (tournamentPageStatus) {
            case ALL_IN_OUT.INN:
              return toTeamMatch;
            case ALL_IN_OUT.UT:
              return fromTeamMatch;
            case ALL_IN_OUT.ALLE:
            default:
              return toTeamMatch || fromTeamMatch;
          }
        },
      );

      if (filteredlist.length !== 0 && selectedTeam !== '') {
        setResultsAmount(filteredlist.length);
        setFilteredList(filteredlist);
      } else {
        setResultsAmount(0);
        setFilteredList([]);
      }
    }
  };

  useEffect(() => {
    handleTeamFilter();
  }, [tournamentPageStatus, selectedTeam]);

  return (
    <S.TransferList $isTableView={isTableView}>
      {filteredList.length < 1 && searchValue !== '' ? (
        <S.ResultsInfo>
          <p>Ingen resultat på søket</p>
        </S.ResultsInfo>
      ) : (
        <>
          {(filteredList.length !== 0
            ? handleList(filteredList)
            : handleList(itemList)
          ).map((transfer: PlayerTransferInterface) =>
            isTableView ? (
              <TransferPlayerListItem
                LinkComponent={LinkComponent}
                key={Math.random().toString(36).substring(2, 9)}
                gender={gender}
                transfer={transfer}
                linkToTeamUrl={linkToTeamUrl}
                transferCountry={transferCountry}
                playersUrl={
                  transfer?.person?.id
                    ? `${toPlayerUrl}${transfer.person.id}`
                    : null
                }
              ></TransferPlayerListItem>
            ) : (
              <TransferPlayerCard
                LinkComponent={LinkComponent}
                key={Math.random().toString(36).substring(2, 9)}
                gender={gender}
                transfer={transfer}
                linkToTeamUrl={linkToTeamUrl}
                transferCountry={transferCountry}
                playersUrl={
                  transfer?.person?.id
                    ? `${toPlayerUrl}${transfer.person.id}`
                    : null
                }
              ></TransferPlayerCard>
            ),
          )}
        </>
      )}
      <S.ResultsInfo>
        {!allDataLoaded && resultsAmount > 0 ? (
          <S.LoadResults>
            <S.LoadMoreButton
              onClick={() =>
                setVisibleCount(
                  (prevCount: number) => prevCount + initialViewCount,
                )
              }
            >
              Vis flere (
              {resultsAmount - visibleCount > initialViewCount
                ? initialViewCount
                : resultsAmount - visibleCount}
              )
            </S.LoadMoreButton>
            <S.LoadAllButton onClick={() => setVisibleCount(resultsAmount)}>
              Vis alle
            </S.LoadAllButton>
          </S.LoadResults>
        ) : (
          allDataLoaded && <p>Alle resultater hentet</p>
        )}
      </S.ResultsInfo>
    </S.TransferList>
  );
};
