import { ELEMENT_IDS, MATCH_EVENTS } from '@ntb-sport/constants';
import * as S from './Event.styled';

import getMatchEvent from './getMatchEvent';

export interface EventTypeProps {
  event?: any;
  personLink?: any;
  teamLink?: any;
  showComments: boolean;
  type?: any;
  text?: string;
  connectedText?: string;
}

export const Event = ({
  event,
  showComments,
  personLink,
  teamLink,
  onClickHighlightVideo,
  customerConcernId,
}: {
  event: any;
  showComments: boolean;
  personLink?: any;
  teamLink?: any;
  onClickHighlightVideo?: any;
  customerConcernId?: any;
}) => {
  const matchEvent = getMatchEvent({ event });
  const EventType = matchEvent?.component;

  if (!matchEvent) {
    console.warn('Missing event handler: ', event);
    return null;
  }

  const highlightVideo =
    event?.videos &&
    event?.videos.find(
      (video: any) => video.customerConcernId === customerConcernId,
    );

  if (
    !showComments &&
    (event?.matchEventTypeId === MATCH_EVENTS.COMMENT ||
      event?.matchEventTypeId === MATCH_EVENTS.VAR)
  ) {
    return null;
  }

  return (
    <S.Event
      $type={matchEvent?.type}
      data-element-id={
        'type-' +
        ELEMENT_IDS[
          ('TYPE_' +
            matchEvent?.type?.toUpperCase()) as keyof typeof ELEMENT_IDS
        ]
      }
    >
      <S.EventWrapper $type={matchEvent?.type}>
        <S.Icon $type={matchEvent?.type} data-element-id={ELEMENT_IDS.ICON}>
          {matchEvent?.icon}
        </S.Icon>

        <S.Commentary>
          <EventType
            text={matchEvent?.text}
            connectedText={matchEvent?.connectedText} //TODO: Fix this
            event={event}
            showComments={showComments}
            type={matchEvent?.type}
            personLink={personLink}
            teamLink={teamLink}
          />

          {highlightVideo && (
            <S.PlayerPreview
              src={highlightVideo?.imageURL}
              onClick={() =>
                onClickHighlightVideo({ src: highlightVideo?.url })
              }
            >
              <S.PlayIcon>
                <svg
                  viewBox="0 0 24 24"
                  width="1.500em"
                  height="1.500em"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path
                    fill="currentColor"
                    d="M6 2h8l5.5 10L14 22H6l5.5-10L6 2z"
                  ></path>
                </svg>
              </S.PlayIcon>
            </S.PlayerPreview>
          )}
        </S.Commentary>
      </S.EventWrapper>
    </S.Event>
  );
};

export default Event;
