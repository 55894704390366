export const ENTITIES = {
  EVENTS: 'events',
  SCOPES: 'scopes',
  EVENT_PARTICIPANTS: 'eventParticipants',
  SCOPE_RESULTS: 'scopeResults',
};

export function updateObjectInArray(array: any, action: any) {
  return array?.map((item: any, index: any) => {
    if (index !== action?.index) {
      return item;
    }
    return {
      ...item,
      ...action?.item,
    };
  });
}

export function getUpdatedState(data: any) {
  return data?.reduce((acc: any, item: any) => {
    const key = item?.path?.substring(1, data?.[0]?.path?.length)?.split('/');
    const value = item?.value;

    if (key?.includes('broadcasts')) return acc; //TODO: https://ntbnorge.atlassian.net/browse/SPORT-4085

    const updatedObject = key.reduceRight(
      (obj: any, next: any) => ({ [next]: obj }),
      value,
    );

    if (!acc[Object.keys(updatedObject)[0]]) {
      acc[Object.keys(updatedObject)[0]] = Object.values(updatedObject)[0];
    }

    return acc;
  }, {});
}
