import { MEDIA_QUERY } from '@ntb-sport/constants';
import styled from 'styled-components';
import { CountryFlag } from '../../../common/CountryFlag/CountryFlag';

export const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  width: 100%;
  padding-top: var(--ntb-space-1);
  font-size: var(--ntb-fontSizes-sm);
  border-top: 1px solid var(--light-border);
  border-bottom: 1px solid var(--light-border);
  gap: var(--ntb-space-1);

  @media ${MEDIA_QUERY.TABLET_S} {
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1) 10%,
      rgba(0, 0, 0, 1) 90%,
      rgba(0, 0, 0, 0)
    );
  }
`;

export const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--ntb-space-2);
  padding: var(--ntb-space-2) var(--ntb-space-6);
  width: 100%;
`;
export const PlayerDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--ntb-space-3);
  width: 100%;

  @media ${MEDIA_QUERY.TABLET} {
    padding-left: var(--ntb-space-8);
    gap: var(--ntb-space-4);
  }

  @media ${MEDIA_QUERY.LAPTOP} {
    padding-left: var(--ntb-space-10);
  }
`;

export const PlayerBio = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: flex-start;
  gap: var(--ntb-space-1);
`;

export const PlayerImage = styled.div`
  width: var(--ntb-space-12);
  height: var(--ntb-space-12);
  max-width: var(--ntb-space-12);
  max-height: var(--ntb-space-12);
  border-radius: 100%;
  background: linear-gradient(
    93deg,
    var(--base-colors-whiteAlpha-900) 31.08%,
    var(--base-colors-blackAlpha-500) 172.96%
  );
  border: solid 1px var(--base-colors-brand-600-background-color);
  box-shadow: var(--box-shadow-card);
  object-fit: cover;
  object-position: top center;
  aspect-ratio: 1/1;
`;

export const PlayerName = styled.p`
  font-size: var(--ntb-fontSizes-md);
  font-weight: var(--ntb-fontWeights-semibold);
  line-height: 1.2;

  @media ${MEDIA_QUERY.LAPTOP} {
    transition: all 0.2s;
    font-size: var(--ntb-fontSizes-lg);
  }
`;

export const PlayerNationality = styled.div`
  display: flex;
  flex-direction: row;
  font-size: var(--ntb-fontSizes-xs);
  width: fit-content;
  gap: var(--ntb-space-1);
  align-items: center;
  font-weight: var(--ntb-fontWeights-regular);
  color: var(--base-colors-blackAlpha-800);

  p {
    width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const TransferInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: var(--ntb-space-1);
  font-size: var(--ntb-fontSizes-xs);

  p {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--ntb-space-1);
    white-space: nowrap;
  }

  p:first-child {
    font-weight: var(--ntb-fontWeights-light);
    font-size: var(--ntb-fontSizes-sm);
    letter-spacing: 0.3px;
  }

  @media ${MEDIA_QUERY.TABLET} {
    padding-right: var(--ntb-space-8);
    font-size: var(--ntb-fontSizes-sm);

    p:first-child {
      font-size: var(--ntb-fontSizes-md);
    }
  }

  @media ${MEDIA_QUERY.LAPTOP} {
    padding-left: var(--ntb-space-10);
  }
`;

export const TeamLogo = styled.div`
  width: var(--ntb-space-5);
  height: var(--ntb-space-5);
  max-height: var(--ntb-space-5);
  max-width: var(--ntb-space-5);
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TransferClubInfo = styled.div`
  position: relative;
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-base);
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  justify-content: center;
  gap: var(--ntb-space-5);
  width: 100%;
  padding: var(--ntb-space-2);
  padding-top: var(--ntb-space-3);
  z-index: 1;

  span {
    min-width: fit-content;
  }

  a,
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--ntb-space-2);

    &:first-child {
      justify-self: flex-end;
    }

    p {
      max-width: 120px;
      display: inline;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--list-item-background);
    opacity: 0.4;
    z-index: -1;
  }

  @media ${MEDIA_QUERY.TABLET} {
    padding: var(--ntb-space-2);
    font-size: var(--ntb-fontSizes-lg);
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1) 5%,
      rgba(0, 0, 0, 1) 95%,
      rgba(0, 0, 0, 0)
    );

    a,
    div {
      p {
        max-width: 300px;
      }
    }
  }
`;

export const Flag = styled(CountryFlag).withConfig({
  shouldForwardProp: (prop) => ['countryName'].includes(prop),
})``;

export const TeamNationalFlag = styled(CountryFlag).withConfig({
  shouldForwardProp: (prop) => ['countryName'].includes(prop),
})`
  max-width: 14px;
  margin-top: 1px;
  margin-left: -1px;
  object-fit: contain;

  @media ${MEDIA_QUERY.TABLET} {
    max-width: 100%;
  }
`;
